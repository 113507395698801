import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThirdPartyCustomSystemField } from 'src/app/call-interface/entities/third-party-custom-system-field';
import { UrlApiService } from 'src/app/services/url-api.service';

@Injectable({
    providedIn: 'root'
})
export class ThirdPartyCustomSystemFieldService
{

    constructor(private http: HttpClient) { }

    createCustomSystemField(csf: ThirdPartyCustomSystemField)
    {
        return this.http.post<ThirdPartyCustomSystemField>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/custom-system-field`, csf);
    }

    updateCustomSystemField(csf: ThirdPartyCustomSystemField)
    {
        return this.http.put<ThirdPartyCustomSystemField>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/custom-system-field`, csf);
    }

    archiveCustomSystemField(id: number)
    {
        return this.http.put<ThirdPartyCustomSystemField>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/custom-system-field/${id}/archive`, null);
    }

    restoreCustomSystemField(id: number)
    {
        return this.http.put<ThirdPartyCustomSystemField>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/custom-system-field/${id}/restore`, null);
    }

    getCustomSystemField()
    {
        return this.http.get<ThirdPartyCustomSystemField[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/custom-system-field`);
    }

    getArchivedCustomSystemField()
    {
        return this.http.get<ThirdPartyCustomSystemField[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/custom-system-field/archived`);
    }

}
