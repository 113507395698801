import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iif, isObservable } from 'rxjs';
import { MailPersonInfo } from '../../../../src/app/entities/mail-person-info';
import { GroupUser } from '../../../../src/app/group-user/entities/group-user';
import { GroupUserDataService } from '../../../../src/app/group-user/services/data/group-user-data.service';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { TextCompareService } from '../../../../src/app/services/text-compare.service';
import { UrlApiService } from '../../../../src/app/services/url-api.service';
import { UserSourceService } from '../../../../src/app/user/services/source/user-source.service';
import { MessagerieAccountSendGroup } from '../entities/messagerie-send-group';
import { MessagerieSendgroupService } from '../messagerie-sendgroup.service';
import { MessagerieService } from '../messagerie.service';

@Component({
  selector: 'app-mail-selector-input',
  templateUrl: './mail-selector-input.component.html',
  styleUrls: ['./mail-selector-input.component.scss']
})
export class MailSelectorInputComponent implements OnInit
{

  constructor(private tcs: TextCompareService,
    public cds: CacheDataService,
    private groupuserservice: GroupUserDataService,
    private userService: UserSourceService,
    private el: ElementRef,
    private mss: MessagerieService,
    public msgs: MessagerieSendgroupService,
    private http: HttpClient) { }

  listOfEmailOptions = [];
  filteredOptions = [];
  selectedIndex = 0;
  tempmail = "";
  maillist = [];
  idPersonInfo = 0;
  selectedMailingList: MessagerieAccountSendGroup[] = [];
  @Input() initmaillist: string[] = [];
  @Input() preText = "";
  @Input() showInputBorder = true;
  @Output() maillistChange = new EventEmitter<string[]>();
  @Output() mailinglistChange = new EventEmitter<MessagerieAccountSendGroup[]>();

  ngOnInit()
  {
    this.addPpToPersonList();
    this.addMpToPersonList();
    this.addCustomRecipientToList();
    this.msgs.getSendGroup(this.mss.selectedProfiles).subscribe(mllist =>
    {
      this.msgs.mailingList = mllist;
      this.msgs.mailingList.sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)
    })

    if (this.cds.teamsCollection.length == 0)
    {
      this.groupuserservice.getAllGroupUsers(false).subscribe(grps => this.cds.teamsCollection = grps.map(x => x.grpuser));
    }

    if (this.cds.userCollection.length < 1)
    {
      this.userService.getAllUserWithAvatar().subscribe(x =>
      {
        this.cds.userCollection = x.sort((a, b) => (a.name + a.surname).toUpperCase() < (b.name + b.surname).toUpperCase() ? -1 : 1);
        this.addUserToPersonList();
      })
    }
    else
    {
      this.addUserToPersonList();
    }

    if (this.initmaillist)
    {
      for (let mail of this.initmaillist)
      {
        this.maillist.push({ label: this.cutMail(mail), value: mail });
        this.maillist.sort((a, b) => a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1)
      }
    }
  }

  addMailingListMailsTolist(ml: MessagerieAccountSendGroup)
  {
    for (let mail of ml.mailList)
    {
      if (this.maillist.findIndex(x => x.value == mail) < 0)
        this.maillist.push({ label: this.cutMail(mail), value: mail });
    }
    let index = this.selectedMailingList.findIndex(x => x.id == ml.id);
    if (index >= 0)
      this.selectedMailingList.splice(index, 1);
    this.mailinglistChange.emit(this.selectedMailingList);
  }

  addMailingList(ml: MessagerieAccountSendGroup)
  {
    this.selectedMailingList.push(ml);
    this.mailinglistChange.emit(this.selectedMailingList);
  }

  removeMailingList(ml: MessagerieAccountSendGroup)
  {
    let index = this.selectedMailingList.findIndex(x => x.id == ml.id);
    if (index >= 0)
      this.selectedMailingList.splice(index, 1);
    this.mailinglistChange.emit(this.selectedMailingList);
  }
  blurAddMail()
  {
    setTimeout(() =>
    {
      if (this.isAddingMail)
        return;
      console.log("blur");

      this.addMail(null);
    }, 1000);

  }

  isAddingMail = false;
  addMail(mail)
  {
    this.isAddingMail = true;
    console.log("allo");

    console.log(mail);

    let mailCut = this.tempmail
    if (mail != null)
    {
      this.tempmail = mail;
      mailCut = this.tempmail.slice(0, this.tempmail.indexOf("@"));
    } else if (this.tempmail)
    {
      let filtered = this.getFilteredOptions();
      if (filtered.length > 0 && this.tempmail)
      {
        this.tempmail = filtered[this.selectedIndex].emails;
        mailCut = this.tempmail.slice(0, this.tempmail.indexOf("@"));
      }
    }
    else return;

    if (this.maillist.findIndex(x => x.value == this.tempmail) >= 0)
      return;
    if (this.listOfEmailOptions.find(find => find.emails == this.tempmail) == null)
    {
      this.AddMailToCustomRecipient(this.tempmail).subscribe(() =>
      {
        let personInfo: MailPersonInfo = new MailPersonInfo();
        personInfo.id = this.idPersonInfo;
        personInfo.emails = this.tempmail;
        personInfo.emailsCut = this.cutMail(this.tempmail);
        personInfo.name = this.tempmail;
        this.listOfEmailOptions.push(personInfo);
        this.idPersonInfo++;
      })
    }
    this.maillist.push({ label: mailCut, value: this.tempmail });
    this.maillist.sort((a, b) => a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1)
    this.maillistChange.emit(this.maillist.map(x => x.value));
    this.tempmail = "";
    setTimeout(() =>
    {
      this.isAddingMail = false;
    }, 1200);

  }

  AddMailToCustomRecipient(mail: string)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/custom-recipient/${mail}`, null)
  }

  checkMail(receiver: string): boolean
  {
    let regex: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!regex.test(receiver))
      return false;
    else
    {
      return true;
    }
  }

  getFilteredOptions()
  {
    return this.listOfEmailOptions.filter(option => this.tcs.contains(option.emails, this.tempmail) && !this.isSelected(option.emails));
  }

  onChange(value: string): void
  {
    this.filteredOptions = this.listOfEmailOptions.filter(option => this.tcs.contains(option.emails, value));
    this.selectedIndex = 0;
  }

  deleteMail(id: number)
  {
    this.maillist.splice(id, 1)
    this.maillistChange.emit(this.maillist.map(x => x.value));
  }

  cutMail(mail: string): string
  {
    return mail.slice(0, mail.indexOf("@"))
  }

  isSelected(value: string): boolean
  {
    for (let elem of this.maillist)
    {
      if (elem.value === value)
        return true;
    }
    return false;
  }

  addTeamMails(team: GroupUser)
  {
    this.groupuserservice.getManagers(team.id).subscribe(users =>
    {
      for (let member of users)
      {
        let user = this.cds.userCollection.find(x => x.id == member.id);
        if (!user)
          continue;
        if (this.maillist.findIndex(x => x.value == user.mail) >= 0)
          continue;
        this.maillist.push({ label: this.cutMail(user.mail), value: user.mail });
      }
      this.maillist.sort((a, b) => a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1)
      this.maillistChange.emit(this.maillist.map(x => x.value));
    })
  }

  addPpToPersonList()
  {
    this.cds.physicalPersonCollection.forEach(x =>
    {
      if (x.archived)
        return;
      x.mails.forEach(m =>
      {
        if (!m.mail)
          return;
        let personInfo: MailPersonInfo = new MailPersonInfo();
        personInfo.id = this.idPersonInfo;
        personInfo.avatar = x.image;
        personInfo.emails = m.mail;
        personInfo.emailsCut = this.cutMail(m.mail);
        personInfo.name = x.firstName + x.lastName;
        if (this.listOfEmailOptions.find(find => find.emails == m.mail) == null)
        {
          this.listOfEmailOptions.push(personInfo);
          this.idPersonInfo++;
        }
      })
    });
  }

  addCustomRecipientToList()
  {
    this.GetMailForCustomRecipient().subscribe(mails =>
    {
      mails.forEach(mail =>
      {
        let personInfo: MailPersonInfo = new MailPersonInfo();
        personInfo.id = this.idPersonInfo;
        personInfo.emails = mail;
        personInfo.emailsCut = this.cutMail(mail);
        personInfo.name = mail;
        if (this.listOfEmailOptions.find(find => find.emails == mail) == null)
        {
          this.listOfEmailOptions.push(personInfo);
          this.idPersonInfo++;
        }
      })
    })
  }

  addMpToPersonList()
  {
    this.cds.moralPersonCollection.forEach(x =>
    {
      if (x.archived)
        return;
      x.mails.forEach(m =>
      {
        if (!m.mail)
          return;
        let personInfo: MailPersonInfo = new MailPersonInfo();
        personInfo.id = this.idPersonInfo;
        personInfo.avatar = x.image;
        personInfo.emails = m.mail;
        personInfo.emailsCut = this.cutMail(m.mail);
        personInfo.name = x.name;
        if (this.listOfEmailOptions.find(find => find.emails == m.mail) == null)
        {
          this.listOfEmailOptions.push(personInfo);
          this.idPersonInfo++;
        }
      })
    });
  }

  addUserToPersonList()
  {
    this.cds.userCollection.forEach(x =>
    {
      if (x.archived || !x.mail)
        return;
      let personInfo: MailPersonInfo = new MailPersonInfo();
      personInfo.id = this.idPersonInfo;
      personInfo.avatar = x.avatar;
      personInfo.emails = x.mail;
      personInfo.emailsCut = this.cutMail(x.mail);
      personInfo.name = x.name;
      if (this.listOfEmailOptions.find(find => find.emails == x.mail) == null)
      {
        this.listOfEmailOptions.push(personInfo);
        this.idPersonInfo++;
      }
    });
  }

  updateSelectedIndex(direction)
  {
    let length = this.getFilteredOptions().length;

    if (direction == "down")
    {
      this.selectedIndex++;
      if (this.selectedIndex >= length)
        this.selectedIndex = 0;
    }
    else
    {
      this.selectedIndex--;
      if (this.selectedIndex < 0)
        this.selectedIndex = length - 1;
    }
    let selectedElement = this.el.nativeElement.querySelectorAll('.autocomplete-options-container > .autocomplete-option')[this.selectedIndex];
    selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  getMenuHeight()
  {
    //HACK : Pour compenser pour la taille de la navbar
    return (window.innerHeight - 200) + "px";
  }

  GetMailForCustomRecipient()
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/custom-recipient`)
  }
}
