import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../services/url-api.service';
import { KanbanBoard } from '../entities/Board';
import { KanbanCard } from '../entities/Card';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { TodoSearchParams } from '../../entities/todo-search-params';

@Injectable({
  providedIn: 'root'
})
export class TodoService
{

  constructor(private http: HttpClient,
    private authService: AuthService) { }
  todoList: KanbanBoard[] = [];
  taskList: KanbanCard[] = [];
  lateTaskList: KanbanCard[] = [];
  taskListChange: Subject<KanbanCard[]> = new Subject<KanbanCard[]>();
  updateChange: Subject<boolean> = new Subject<boolean>();

  getTodoList()
  {
    return this.http.get<KanbanBoard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/lists`)
  }

  getFloatingTask()
  {
    return this.http.get<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/floating`)
  }

  getAffectedTask(searchparams: TodoSearchParams)
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/affected`, searchparams)
  }

  getPlanifiedTask(searchparams: TodoSearchParams)
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/planified`, searchparams)
  }

  getDuedateTasks(searchparams: TodoSearchParams)
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/duedate`, searchparams)
  }

  getLatedateTasks(searchparams: TodoSearchParams)
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/latedate`, searchparams)
  }

  getstartdateTasks(searchparams: TodoSearchParams)
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/startdate`, searchparams)
  }

  getongoingTasks(searchparams: TodoSearchParams)
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/ongoing`, searchparams)
  }

  createTask(card: KanbanCard)
  {
    card.creationDate = new Date();
    card.creatorId = this.authService.Access.idUser;
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/task`, card)
  }

  changeTodoListToBoard(idboard: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/${idboard}/change-to-board`, null)
  }

}
