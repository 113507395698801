import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanMoveCopyCardComponent } from './kanban-move-copy-card.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KanbanActionsManagerModule } from '../kanban-actions-manager/kanban-actions-manager.module';
import { KanbanBoardTreeModule } from '../kanban-board-tree/kanban-board-tree.module';
import { NameUserPipeModule } from '../../../../../src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    NzInputModule,
    NzTreeModule,
    FormsModule,
    NzDividerModule,
    NzButtonModule,
    NzCheckboxModule,
    TranslateModule,
    NzIconModule,
    KanbanActionsManagerModule,
    KanbanBoardTreeModule,
    NameUserPipeModule
  ],
  declarations: [KanbanMoveCopyCardComponent],
  exports: [KanbanMoveCopyCardComponent]
})
export class KanbanMoveCopyCardModule { }
