export enum Module
{
    common,
    contract,
    credentials,
    changelog,
    log,
    user,
    userGroup,
    credentialsGroup,
    extensions,
    site,
    speedDial,
    license,
    Kanban,
    Calls,
    Interface,
    Server,
    ThirdParty,
    Glossary,
    Reservation,
    Country,
    Todo,
    IpAddress,
    PhoneProfile,
    Messagerie,
    Chat,
    Planning,
    Prospection,
    Zoom
}