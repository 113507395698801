import { Inject, Injectable } from '@angular/core';
import { KanbanCard } from '../entities/Card';
import { GlobalLabel } from '../entities/Label';
import { UrlApiService } from '../../services/url-api.service';
import { KanbanBoard, KanbanBoardVisibility } from '../entities/Board';
import { HttpClient } from '@angular/common/http';
import { KanbanList } from '../entities/List';
import { KanbanAttachment } from '../entities/Attachment';
import { KanbanComment } from '../entities/Comment';
import { KanbanChecklist } from '../entities/Checklist';
import { KanbanChecklistItem } from '../entities/ChecklistItem';
import { User } from '../../user/model/user';
import { firstValueFrom, Subject } from 'rxjs';
import { KanbanMoveCard } from '../entities/CardMove';
import { MoveCardSpecific } from '../entities/move-card-specific';
import { KanbanCategory } from '../entities/category';
import { KanbanMoveCategory } from '../entities/move-category';
import { KanbanPotentialRelation } from '../entities/kanban-potential-relation-infos';
import { SetRelationKanban } from '../entities/set-relation';
import { tap } from 'rxjs/operators';
import { TypeRelation } from '../../third-party/entities/type-relation';
import { RelationForListDTO } from '../entities/list-relation';
import { AuthService } from '../../services/auth.service';
import { SettingsService } from '../../settings/services/settings.service';
import { ThirdPartyForCredential } from '../../credentials/entities/third-party-for-credentials';
import { ListAction } from '../entities/list-actions-control';
import { TranslateService } from '@ngx-translate/core';
import { TodoService } from './todo.service';
import { PhysicalPerson } from '../../third-party/physical-person/entities/physical-person';
import { MoralPerson } from '../../third-party/moral-person/entities/moral-person';
import { NotifyUserForAction } from '../entities/notify-user';
import { FilterList } from '../entities/filter-list';
import { ExportBoardParameters } from '../entities/export-parameters';
import { CacheDataService } from '../../services/cache-data.service';
import { NotifyUsersForCard } from '../entities/Notify/notify-users-for-card';
import { KanbanHomeInfos } from '../entities/home-infos';
import { MoveListAnotherBoard } from '../entities/move-list-another-board';
import { BoardArchives } from '../entities/board-archives';
import { RestorationParams } from '../entities/restoration-params';
import { BoardSettings } from '../entities/board-settings';
import { ReaffectCardLabel } from '../entities/reaffect-card-label';
import { MessagerieService } from '../../messagerie/messagerie.service';
import { EmailToSend } from '../../entities/email-to-send';
import { ChecklistDisplayInfos } from '../entities/checklist-display-infos';
import { CustomEvent } from '../../SDICalendar/calendar/entities/event'
import { BoardListForCard } from '../entities/BoardListForCard';
import { TextCompareService } from '../../services/text-compare.service';
import { KanbanBoardMemberDTO } from '../entities/board-member-dto';
import { NameUserPipe } from '../../pipes/name-user-pipe/name-user.pipe';
import { KanbanBoardGuestDTO } from '../entities/board-guests';
import { GroupUser } from '../../group-user/entities/group-user';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KanbanEditCardService } from './kanban-edit-card.service';
import { KANBAN_ACTION_MANAGER } from '../../call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { ComponentType } from '@angular/cdk/portal';
import { GlobalLabelService } from '../../global-label/global-label.service';
import { ModuleLabel } from '../../global-label/entities/modules-label';
import { KanbanActivity } from '../entities/Activity';
import { KanbanBoardActivitiesSearchParameters } from '../entities/kanban-board-activities-search-parameters';
import { FilterBoardActivities } from '../entities/filter-board-activities';
import { PlannedAction } from '../../entities/date-scheduling-parameter';
import { PlannedEventsService } from '../../services/planned-events.service';
import { FilterBoardLists } from '../entities/filter-board-list';
import { KanbanCardVisibility } from '../entities/kanban-card-visibility';
import { MessagerieAccountAlias } from '../../messagerie/entities/messagerie-account-alias';
import { KanbanCardTemplateParameters } from '../entities/card-template-parameters';
import { KanbanCardCreationFromTemplate } from '../entities/kanban-card-creation-from-template';
import { Receivedmail } from '../../messagerie/entities/receivedmail';
import { KanbanChecklistTemplateParams } from '../entities/kanban-checklist-template-params';
import { KanbanGlobalSearchParameters } from '../entities/kanban-global-search-parameters';
import { PresenceInfos } from '../entities/presence-infos';

@Injectable({
  providedIn: 'root'
})
export class KanbanService
{
  editedEvent: CustomEvent = null;
  editMode = "wide";
  currentListChange = false;
  currentEditPositionCard = 0;
  showLabelText = true;
  isAddingCardToList = 0;
  filterText = "";
  isEditionListName = 0;
  listsLoaded = false;
  hideListOnSearch = false;
  filterBoardActivities: FilterBoardActivities[] =
    [
      {
        category: "BASIC-OPERATIONS",
        actions: ["NOTIFICATION.ACTIVITY.CREATE-CARD", "NOTIFICATION.ACTIVITY.MODIFIED-OBJECT", "NOTIFICATION.ACTIVITY.MOVE-CARD-TO-LIST", "NOTIFICATION.ACTIVITY.UPDATE-DESCRIPTION-CARD", "NOTIFICATION.ACTIVITY.CARTE-NOT-COMPLETE", "NOTIFICATION.ACTIVITY.CARTE-COMPLETE", "NOTIFICATION.ACTIVITY.ARCHIVE-CARD", "NOTIFICATION.ACTIVITY.UPDATE-ACTIONS-CARD", "NOTIFICATION.ACTIVITY.RESTORE-CARD"]
      },
      {
        category: "MEMBERSHIP",
        actions: ["NOTIFICATION.ACTIVITY.ADD-MEMBER-CARD", "NOTIFICATION.ACTIVITY.REMOVE-MEMBER-CARD", "NOTIFICATION.ACTIVITY.ADD-TEAM-CARD", "NOTIFICATION.ACTIVITY.REMOVE-TEAM-CARD"]
      },
      {
        category: "DATE",
        actions: ["NOTIFICATION.ACTIVITY.CHANGE-CARD-START-DATE", "NOTIFICATION.ACTIVITY.REMOVE-CARD-START-DATE", "NOTIFICATION.ACTIVITY.CHANGE-CARD-END-DATE", "NOTIFICATION.ACTIVITY.REMOVE-CARD-END-DATE", "NOTIFICATION.ACTIVITY.UPDATE-RECCURENCE-CARD"]
      },
      {
        category: "COMMENT",
        actions: ["NOTIFICATION.ACTIVITY.ADD-COMMENT-CARD", "NOTIFICATION.ACTIVITY.UPDATE-COMMENT-CARD", "NOTIFICATION.ACTIVITY.DELETE-COMMENT-CARD"]
      },
      {
        category: "CHECKLIST",
        actions: ["NOTIFICATION.ACTIVITY.ADD-CHECKLIST-CARD", "NOTIFICATION.ACTIVITY.RENAME-CHECKLIST-CARD", "NOTIFICATION.ACTIVITY.REMOVE-CHECKLIST-CARD", "NOTIFICATION.ACTIVITY.ADD-ITEM-CHECKLIST", "NOTIFICATION.ACTIVITY.REMOVE-ITEM-CHECKLIST", "NOTIFICATION.ACTIVITY.RENAME-ITEM-CHECKLIST", "NOTIFICATION.ACTIVITY.UPDATE-ITEM-CHECKLIST", "NOTIFICATION.ACTIVITY.SET-ITEM-DONE", "NOTIFICATION.ACTIVITY.SET-ITEM-NOTDONE"]
      },
      {
        category: "ATTACHMENTS",
        actions: ["NOTIFICATION.ACTIVITY.ADD-ATTACHMENT-TO-CARD", "NOTIFICATION.ACTIVITY.REMOVE-ATTACHMENT-FROM-CARD"]
      },
      {
        category: "THIRD-PARTIES",
        actions: ["NOTIFICATION.ACTIVITY.ADD-MORAL-PERSON-CARD", "NOTIFICATION.ACTIVITY.ADD-PHYSICAL-PERSON-CARD", "NOTIFICATION.ACTIVITY.REMOVE-MORAL-PERSON-CARD", "NOTIFICATION.ACTIVITY.REMOVE-PHYSICAL-PERSON-CARD", "NOTIFICATION.ACTIVITY.UPDATE-MORAL-PERSON-CARD-DESCRIPTION", "NOTIFICATION.ACTIVITY.UPDATE-PHYSICAL-PERSON-CARD-DESCRIPTION"]
      }
    ]
  allCategoryList: KanbanCategory[] = [];
  get allUnarchivedCategories(): KanbanCategory[]
  {
    return this.allCategoryList.filter(x => !x.archived);
  }
  actionsControls: ListAction[] = [];
  allBoardList: KanbanBoard[] = [];
  allStarredBoardList: number[] = [];
  allStarredSegmentList: number[][] = [];
  allPersonalBoardList: number[] = [];
  allPersonalSegmentList: number[][] = [];
  allPublicBoardList: number[] = [];
  allPublicSegmentList: number[][] = [];
  allTemplateList: KanbanBoard[] = [];
  allCardTemplate: KanbanCard[] = [];
  copiedCard: KanbanCard = null;
  positionCardDrag = 0;
  homeInfos: KanbanHomeInfos = new KanbanHomeInfos();
  // potentialBoardMembers: User[] = [];
  disableAllDrag = false;
  typeRelationCollection: TypeRelation[] = [];
  allowMoveForNonRestrictedList = true;
  allowCopyForNonRestrictedList = true;
  openNoteOnCreation = false;
  sortLabelsAlphabetically = true;
  dragOngoing = false;
  loadingPack = 1;
  showLoadingBoard = false;
  loadingBoardProgress = 0;
  intervalPack: any;
  loadingNumber = 25;
  doRedirectionGet = true;
  maxCardLength = 0;
  modalEdit: NzModalRef;
  modalAction: NzModalRef;
  filterBoardLists: FilterBoardLists = new FilterBoardLists();
  modalMove: NzModalRef;
  modalPaste: any;
  workflowActionList: string[] = [
    "ASK-COMMENT-BEFORE-DEPLACEMENT",
    "ASK-DESCRIPTION-BEFORE-DEPLACEMENT",
    "MOVE-CARD",
    "COPY-CARD",
    "SET-START-DATE",
    "SET-DUE-DATE",
    "MARK-CARD-COMPLETE",
    "REMOVE-CARD-COMPLETE",
    "SEND-MAIL-TEAM-MEMBER",
    "NOTIFY-CARD-CREATOR",
    "NOTIFY-USER-GENERAL-AUTO",
    "NOTIFY-USER-GENERAL-MANUAL",
    "NOTIFY-CARD-MEMBERS",
    "SEND-MAIL-TEAM-AUTO",
    "SEND-MAIL-TEAM-MANUAL",
    "AFFECT-CARD-MEMBERS-MANUAL",
    "AFFECT-CARD-MEMBERS-AUTO",
    "AFFECT-CARD-TEAMS-MANUAL",
    "AFFECT-CARD-TEAMS-AUTO",
    "AFFECT-LABELS-MANUAL",
    "AFFECT-LABELS-AUTO",
    "AFFECT-THIRD-PARTY-MANUAL",
    "AFFECT-THIRD-PARTY-AUTO",
    "OPEN-EDIT-CARD",
    "SHOW-INFORMATION-TEXT",
    "ASK-CONFIRMATION",
    "PLAN-CARD-ARCHIVING-AUTO"
  ]
  removeCardChange: Subject<number> = new Subject<number>();
  kanbanBoardChange: Subject<number> = new Subject<number>();
  reloadBoardList: Subject<any> = new Subject<any>();
  updateBoardChange: Subject<number> = new Subject<number>();
  updateCardDate: Subject<KanbanCard> = new Subject<KanbanCard>();
  updateCardTitle: Subject<KanbanCard> = new Subject<KanbanCard>();
  updateBoardRelations: Subject<number> = new Subject<number>();
  openEditCardSub: Subject<number> = new Subject<number>();
  reloadThirdPartyCards: Subject<number> = new Subject<number>();
  openEditCardOnStartSub: Subject<number> = new Subject<number>();
  reloadBoardArchivesSub: Subject<number> = new Subject<number>();
  colorList: any[] = [
    { value: "#f39c12" },
    { value: "#d35400" },
    { value: "#e74c3c" },
    { value: "#8e44ad" },
    { value: "#2c3e50" },
    { value: "#2980b9" },
    { value: "#27ae60" },
    { value: "#44bd32" },
    { value: "#2d3436" },
  ]

  private _nonArchivedList: User[] = [];
  get nonArchivedList(): User[]
  {
    return this.cds.userCollection.filter(x => !x.archived);
  }

  filterString = "";
  editedCard: KanbanCard = null;
  draggedCardOriginList: KanbanList = new KanbanList();

  createdCard: KanbanCard = new KanbanCard();
  ppList: ThirdPartyForCredential[] = [];
  mpList: ThirdPartyForCredential[] = [];
  showCardAsTasks = true;
  visualizedUrl = "";
  constructor(@Inject(KANBAN_ACTION_MANAGER) private actionManagerComponent: ComponentType<any>,
    private pas: PlannedEventsService,
    private http: HttpClient, private authService: AuthService,
    private tds: TodoService,
    private cds: CacheDataService,
    private gls: GlobalLabelService,
    private translateService: TranslateService,
    private mss: MessagerieService,
    public settingsService: SettingsService,
    public tcs: TextCompareService,
    private kecs: KanbanEditCardService,
    private modalService: NzModalService,
    private nameuserpipe: NameUserPipe,
    private msgs: NzMessageService) { }

  setFocusToElement(id)
  {
    setTimeout(() =>
    {
      document.getElementById(id).focus();
    }, 100)
  }

  updateEditMode()
  {
    this.kecs.showValue = 0;
    this.kecs.typeMove = "";
    if (this.editMode == "wide")
      this.editMode = "normal"
    else this.editMode = "wide";
    this.settingsService.setSettingsByName("edit-card-display", this.editMode);
  }

  getLabelList(idboard)
  {
    return this.gls.getAllBoardLabels(idboard).pipe(tap(x => this.cds.labelList = x));
  }

  getPublicLabelList()
  {
    return this.gls.getAllLabels().pipe(tap(x => this.cds.publicLabelList = x.filter(y => !y.boardId && ((y.modules & ModuleLabel.Kanban) != 0 || y.modules == 0))));
  }

  updateLabel(label: GlobalLabel)
  {
    return this.gls.updateLabel(label);
  }

  deleteLabel(idlabel: number)
  {
    return this.gls.deleteLabel(idlabel, false);
  }

  createLabel(label: GlobalLabel, idboard: number)
  {
    label.boardId = idboard;
    label.modules = ModuleLabel.Kanban;
    return this.gls.createLabel(label);
  }

  createPublicLabel(label: GlobalLabel)
  {
    label.modules = ModuleLabel.Kanban;
    return this.gls.createLabel(label);
  }

  reallocateDeleteLabel(idlabel: number, idnewlabel)
  {
    return this.gls.reallocateLabel(idlabel, idnewlabel, ModuleLabel.Kanban)
  }

  forceDeleteLabel(idlabel)
  {
    return this.gls.deleteLabel(idlabel, true)
  }

  moveCardsFromLabel(idlabel: number, moveparams: ReaffectCardLabel)
  {
    return this.gls.moveElementBetweenLabel(idlabel, moveparams.idNewLabel, moveparams)
  }

  getCardsAssociatedToLabel(idlabel)
  {
    return this.gls.getCardsAssociatedWithLabel(idlabel, false);
  }

  getArchivedCardsAssociatedToLabel(idlabel)
  {
    return this.gls.getCardsAssociatedWithLabel(idlabel, true);
  }

  async filterBoard(board: KanbanBoard)
  {
    let cardvisibility = await firstValueFrom(this.GetAllCardVisibilityForBoard(board.id, this.filterBoardLists));
    for (let list of board.lists)
    {
      for (let card of list.cards)
      {
        let visicard = cardvisibility.find(x => card.id == x.id);
        if (visicard)
          card.visible = visicard.visible;
        else card.visible = false
      }
      if (this.hideListOnSearch)
        list.visible = !this.filterBoardLists.searchText || list.cards.filter(x => x.visible).length > 0;
      else list.visible = true;
    }
    this.startLoadingInterval();
  }

  async initBoard(board: KanbanBoard)
  {
    if (!board)
      return;
    this.listsLoaded = false;
    // setTimeout(() =>
    // {
    //   this.cds.selectedBoard = board;
    // }, 200);

    this.loadingPack = 0;
    clearInterval(this.intervalPack);
    await firstValueFrom(this.getLabelList(board.id));
    await this.getFilterPreferences(board.id);
    board.lists = await firstValueFrom(this.GetAllListForBoard(board.id, this.filterBoardLists));
    await this.filterBoard(board);
    if (!this.cds.selectedBoard)
      this.cds.selectedBoard = board;


    this.startLoadingInterval();

    let members = await firstValueFrom(this.getMembersForBoard(board.id));
    board.members = members.filter(t => t.userId).map(t => t.userId).sort((a, b) => this.nameuserpipe.transform(a).toUpperCase() < this.nameuserpipe.transform(b).toUpperCase() ? -1 : 1);
    board.teams = members.filter(t => t.teamId).map(t => t.teamId);
    // this.potentialBoardMembers = this.nonArchivedList.filter(user => board.members.findIndex(mem => mem == user.id) < 0);

    this.settingsService.getSystemSettingsByName("kanban_move_restricted_list" + board.id).subscribe(x =>
    {
      if (x && x == 'false')
        this.allowMoveForNonRestrictedList = false;
      else
        this.allowMoveForNonRestrictedList = true;
    });

    this.settingsService.getSystemSettingsByName("kanban_copy_restricted_list" + board.id).subscribe(x =>
    {
      if (x && x == 'false')
        this.allowCopyForNonRestrictedList = false;
      else
        this.allowCopyForNonRestrictedList = true;
    });

    this.settingsService.getSettingsByName("kanban_sort_labels_alphatically" + board.id).subscribe(x =>
    {
      if (x)
      {
        if (x == 'false')
          this.sortLabelsAlphabetically = false;
        else
          this.sortLabelsAlphabetically = true;
      }
      else
      {
        this.settingsService.getSettingsByName("kanban_sort_labels_alphatically").subscribe(y =>
        {
          if (y && y == 'false')
            this.sortLabelsAlphabetically = false;
          else
            this.sortLabelsAlphabetically = true;
        });
      }

    });

    setTimeout(() =>
    {
      this.ScrollBoard();
    }, 1);



  }

  ScrollBoard()
  {
    let ls = document.getElementById("left-side");
    let rs = document.getElementById("right-side");
    ls.style.left = ls.parentElement.scrollLeft + "px";
    rs.style.right = -ls.parentElement.scrollLeft + "px";

    ls.style.display = (ls.parentElement.scrollLeft == 0) ? "none" : "block";
    rs.style.display = (rs.parentElement.scrollLeft + rs.parentElement.clientWidth + 1 >= rs.parentElement.scrollWidth) ? "none" : "block";
  }

  initCardMail(idboard: number, idcard: number)
  {

    this.mss.cardMailCodeParams = [];
    let textBoardLink = "";
    let textCardLink = "";
    let linkToCard = "";
    let linkToBoard = "";
    let mail = new EmailToSend();
    let routelink = window.location.origin + '/kanban'

    if (idcard)
    {

      this.translateService.get("MESSAGERIE.EDITOR.PARAM.CARD-LINK").subscribe(x =>
      {
        textCardLink = x;
        let link = "<a href='" + routelink + '/' + idboard + '/' + idcard + "'>" + textCardLink + "</a><hr /><div></div>";
        if (idboard == 0)
          link = "<a href='" + routelink + '/todo/tasks/' + idcard + "'>" + textCardLink + "</a><hr /><div></div>"
        this.translateService.get("KANBAN.SEND-MAIL-USER.LINK-TO-THE-CARD", { v: link }).subscribe(x => linkToCard = "<p>" + x + "</p>")
      })

      this.translateService.get("MESSAGERIE.EDITOR.PARAM.BOARD-LINK").subscribe(x => textBoardLink = x)
      if (idboard == 0)
      {
        this.mss.cardMailCodeParams.push({ label: textCardLink, value: routelink + '/todo/tasks/' + idcard })
      }
      else
      {
        this.mss.cardMailCodeParams.push({ label: textBoardLink, value: routelink + '/' + idboard })
        this.mss.cardMailCodeParams.push({ label: textCardLink, value: routelink + '/' + idboard + '/' + idcard })
      }
      mail.content = linkToCard;

    } else
    {

      this.translateService.get("MESSAGERIE.EDITOR.PARAM.BOARD-LINK").subscribe(x => textBoardLink = x)
      if (idboard == 0)
      {
        this.mss.cardMailCodeParams.push({ label: textBoardLink, value: routelink + '/todo/tasks/' + idboard })
      }
      else
      {
        this.mss.cardMailCodeParams.push({ label: textBoardLink, value: routelink + '/' + idboard })
      }

      this.translateService.get("MESSAGERIE.EDITOR.PARAM.BOARD-LINK").subscribe(x =>
      {
        textBoardLink = x;
        let link = "<a href='" + routelink + '/' + idboard + '/' + "'>" + textBoardLink + "</a><hr /><div></div>";
        if (idboard == 0)
          link = "<a href='" + routelink + '/todo/tasks/' + idboard + "'>" + textBoardLink + "</a><hr /><div></div>"
        this.translateService.get("KANBAN.SEND-MAIL-USER.LINK-TO-THE-BOARD", { v: link }).subscribe(x => linkToBoard = "<p>" + x + "</p>")
      })
      mail.content = linkToBoard;
    }
    let alias = new MessagerieAccountAlias();
    alias.displayName = this.authService.connectedUser.mail;
    alias.mail = this.authService.connectedUser.mail;
    mail.from = alias;
    this.mss.emailToInit = mail;
  }

  startLoadingInterval()
  {
    this.loadingPack = 1;
    this.listsLoaded = true;
    this.loadingBoardProgress = 0;

    this.intervalPack = setInterval(() =>
    {
      this.showLoadingBoard = true;
      this.loadingPack++;
      let progress = !this.maxCardLength ? 100 : Math.round((this.loadingPack * this.loadingNumber / this.maxCardLength) * 100);
      this.loadingBoardProgress = Math.min(progress, 100);

      if (this.loadingPack * this.loadingNumber > this.maxCardLength)
      {
        clearInterval(this.intervalPack);
        this.openEditCardOnStartSub.next(null);
        this.showLoadingBoard = false;
      }

    }, 1);
  }

  getUsersForBoard(board: KanbanBoard, upperFilter?: string)
  {
    if (!upperFilter)
      upperFilter = "";
    if (board)
    {
      let userList = board.members.filter(x => this.tcs.contains(this.nameuserpipe.transform(x), upperFilter));


      for (let idteam of board.teams)
      {
        let team = this.cds.teamsCollection.find(x => x.id == idteam);
        if (team)
          userList = userList.concat(team.members.filter(x => this.tcs.contains(this.nameuserpipe.transform(x), upperFilter) && userList.findIndex(t => t == x) < 0))
      }
      return userList;
    }
    else
      return this.nonArchivedList.filter(x => this.tcs.contains((x.name + x.surname), upperFilter)).map(x => x.id);
  }

  getUsersForCard(card: KanbanCard)
  {
    if (card)
    {
      let userList = card.members;

      for (let idteam of card.teams)
      {
        let team = this.cds.teamsCollection.find(x => x.id == idteam);
        if (team)
          userList = userList.concat(team.members.filter(x => userList.findIndex(t => t == x) < 0))
      }
      return userList;
    }
    else
      return this.nonArchivedList.map(x => x.id);
  }

  async getFilterPreferences(idboard: number)
  {
    this.filterBoardLists = new FilterBoardLists();
    let x = await firstValueFrom(this.getBoardFilterSettings(idboard));
    for (let l of x.labelsFiltered)
    {
      let label = this.cds.labelList.find(x => x.id == l);
      if (label)
        this.filterBoardLists.labelFiltered.push(label.id);
    }

    for (let l of x.labelsExiled)
    {
      let label = this.cds.labelList.find(x => x.id == l);
      if (label)
        this.filterBoardLists.labelExiled.push(label.id);
    }

    for (let l of x.ppFiltered)
    {
      this.filterBoardLists.physicalPersonFiltered.push(l);
    }

    for (let l of x.ppExiled)
    {
      this.filterBoardLists.physicalPersonExiled.push(l);
    }

    for (let l of x.mpFiltered)
    {
      this.filterBoardLists.moralPersonFiltered.push(l);
    }

    for (let l of x.mpExiled)
    {
      this.filterBoardLists.moralPersonExiled.push(l);
    }

    for (let u of x.usersFiltered)
    {
      let user = this.cds.userCollection.find(x => x.id == u);
      if (user)
        this.filterBoardLists.userFiltered.push(user.id);
    }

    for (let u of x.usersExiled)
    {
      let user = this.cds.userCollection.find(x => x.id == u);
      if (user)
        this.filterBoardLists.userExiled.push(user.id);
    }
    //if ((this.filterBoardLists.userFiltered.length > 0 || this.filterBoardLists.userExiled.length > 0 || this.allLabelFiltered.length > 0 || this.allLabelExiled.length > 0) && this.listsLoaded)
    // this.kanbanBoardChange.next(null);
  }


  private getTime(date?: Date)
  {
    return date != null ? date.getTime() : 0;
  }


  public sortByDueDate(arraryDate: KanbanCard[], direction: string): void
  {
    if (direction == "desc")
      arraryDate.sort((a, b) => new Date(b.dueDate) < new Date(a.dueDate) ? -1 : 1);
    else arraryDate.sort((a, b) => new Date(a.dueDate) < new Date(b.dueDate) ? -1 : 1);
  }

  public sortByStartDate(arraryDate: KanbanCard[], direction: string): void
  {
    if (direction == "desc")
      arraryDate.sort((a, b) => new Date(b.startDate) < new Date(a.startDate) ? -1 : 1);
    else arraryDate.sort((a, b) => new Date(a.startDate) < new Date(b.startDate) ? -1 : 1);
  }

  public sortByEndDate(arraryDate: KanbanCard[], direction: string): void
  {
    if (direction == "desc")
      arraryDate.sort((a, b) => new Date(b.endDate) < new Date(a.endDate) ? -1 : 1);
    else arraryDate.sort((a, b) => new Date(a.endDate) < new Date(b.endDate) ? -1 : 1);
  }

  sortCartManually(list: KanbanList)
  {
    if (list.cards.length == 0)
      return;
    if (list.autofilterType)
    {
      switch (list.autofilterType)
      {
        case "title":
          if (list.autofilterDirection && list.autofilterDirection == "desc")
            list.cards.sort((a, b) => a.title.toUpperCase() > b.title.toUpperCase() ? -1 : 1);
          else list.cards.sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
          break;
        case "datecreation":
          if (list.autofilterDirection && list.autofilterDirection == "desc")
            list.cards.sort((a, b) => a.id > b.id ? -1 : 1);
          else list.cards.sort((a, b) => a.id < b.id ? -1 : 1);
          break;
        case "datestart":
          this.sortByStartDate(list.cards, list.autofilterDirection)
          break;
        case "datedue":
          this.sortByDueDate(list.cards, list.autofilterDirection)
          break;
        case "dateend":
          this.sortByEndDate(list.cards, list.autofilterDirection)
          break;
      }
    }
    else list.cards.sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);

    let movecard = new KanbanMoveCard();
    movecard.idBoard = 0;
    movecard.idListFrom = 0;
    movecard.idListTo = 0;
    movecard.idCard = list.cards[0].id;
    movecard.listCardDestination = list.cards.map(({ id }) => id);
    movecard.listCardOrigin = [];
    return this.moveCard(movecard, 0);

  }

  // getFilteredList(board: KanbanBoard, filter: string)
  // {
  //   let nbcardvisible = 0;
  //   this.maxCardLength = 0;
  //   board.lists.forEach(list =>
  //   {
  //     let nbcardshown = 0;
  //     list.cards.forEach(x =>
  //     {
  //       if (x.visible)
  //       {
  //         nbcardshown++;
  //         nbcardvisible++;
  //       }
  //     })
  //     if (nbcardshown > this.maxCardLength)
  //       this.maxCardLength = nbcardshown;
  //     if (nbcardshown > 0)
  //       list.visible = true;
  //     else
  //     {
  //       list.visible = false;
  //     }
  //   })

  //   if (nbcardvisible > 20 || !filter)
  //   {
  //     this.startLoadingInterval();
  //   }


  // }

  getBoardForCustomCategory(category: KanbanCategory)
  {
    return this.allBoardList.filter(x => !x.archived && category.boardids.findIndex(y => y == x.id) >= 0).sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
  }

  getAttachmentUri()
  {
    return UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/attachment`;
  }

  deepCopy(obj)
  {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date)
    {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array)
    {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++)
      {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object)
    {
      copy = {};
      for (var attr in obj)
      {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  CATEGORY
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////



  getAllCategories()
  {
    return this.http.get<KanbanCategory[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category`)
  }

  createCategory(categoryTitle: string)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category`, categoryTitle)
  }

  updateCategory(idcategory: number, newCategoryTitle: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}`, newCategoryTitle)
  }

  archiveCategory(idcategory: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}/archive`, null)
  }

  restoreCategory(idcategory: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}/restore`, null)
  }

  collapseCategory(idcategory: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}/collapse`, null)
  }

  openCategory(idcategory: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}/open`, null)
  }

  sortBoardForCategory(idcategory: number, idboardlist: number[])
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}/board/sort`, idboardlist)
  }

  addBoardToCategory(idcategory: number, idboard: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}/board/${idboard}`, null)
  }

  removeBoardFromCategory(idcategory: number, idboard: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/${idcategory}/board/${idboard}`)
  }

  moveCategory(categories: KanbanMoveCategory)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/category/move`, categories)
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  CARD TEMPLATE
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  getAllCardTemplates(FilterBoardLists: FilterBoardLists)
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/template/search`, FilterBoardLists)
  }

  createCardFromTemplate(templateParameters: KanbanCardCreationFromTemplate)
  {
    return this.http.post<KanbanCard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/template/apply`, templateParameters)
  }

  renameCardTemplate(idcard: number, params: KanbanCardTemplateParameters)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/template/${idcard}/rename`, params)
  }

  updateTemplateStartDateVariable(idcard: number, variable: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/template/${idcard}/update-start-date-template`, variable)
  }

  updateTemplateDueDateVariable(idcard: number, variable: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/template/${idcard}/update-due-date-template`, variable)
  }

  createCardTemplate(templateParameters: KanbanCardTemplateParameters)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/template`, templateParameters)
  }

  udpdateCardTemplateVisibility(templateParameters: KanbanCardTemplateParameters)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/template`, templateParameters)
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  BOARD
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  getAllHomeInfos()
  {
    return this.http.get<KanbanHomeInfos>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/home`).pipe(tap(x => this.homeInfos = x))
  }

  updateBoardPersonalPosition(boardids: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/personal/position`, boardids)
  }

  updateBoardPublicPosition(boardids: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/public/position`, boardids)
  }

  updateBoardStarredPosition(boardids: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/starred/position`, boardids)
  }

  getBoardFilterSettings(idboard: number)
  {
    return this.http.get<BoardSettings>(UrlApiService.settings.apiConfig.uriAPI + `/api/settings/board/${idboard}`)
  }

  getAllBoardForConnectedUser()
  {
    return this.http.get<KanbanBoard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board`)
  }

  getBoardArchives(idboard: number)
  {
    return this.http.get<BoardArchives>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/archives`)
  }

  restoreBoardContent(idboard: number, restoreParams: RestorationParams)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/restore-content`, restoreParams)
  }

  getAllArchivedBoardForConnectedUser()
  {
    return this.http.get<KanbanBoard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/archive`)
  }

  getAllTemplateForConnectedUser()
  {
    return this.http.get<KanbanBoard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/template`)
  }

  copyBoard(idboard: number)
  {
    return this.http.post<KanbanBoard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/copy`, null)
  }

  getMembersForBoard(idboard: number)
  {
    return this.http.get<KanbanBoardMemberDTO[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/members`)
  }

  getAllBoardAndListForUser()
  {
    return this.http.get<KanbanBoard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/list`)
  }

  getFilteredBoardAndListForUser(filtertext: KanbanGlobalSearchParameters)
  {
    return this.http.post<KanbanBoard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/filtered-list`, filtertext)
  }

  createTemplateFromBoard(board: KanbanBoard)
  {
    return this.http.post<KanbanBoard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/template`, board);
  }

  GetAllListForBoard(idboard: number, FilterBoardLists: FilterBoardLists)
  {
    return this.http.post<KanbanList[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/list/search`, FilterBoardLists)
  }

  GetAllCardVisibilityForBoard(idboard: number, FilterBoardLists: FilterBoardLists)
  {
    return this.http.post<KanbanCardVisibility[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/cards/visibility`, FilterBoardLists)
  }

  GetCardVisibilityForBoard(idboard: number, idcard: number, FilterBoardLists: FilterBoardLists)
  {
    return this.http.post<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/card/${idcard}/visibility`, FilterBoardLists)
  }

  GetBoardById(idboard: number)
  {
    return this.allBoardList.filter(x => x.id === idboard)[0];
  }

  GetTemplateById(idboard: number)
  {
    return this.allTemplateList.filter(x => x.id === idboard)[0];
  }

  updateListCreationAuthorization(idboard: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/list-creation-authorization`, null)
  }

  archiveBoard(idboard: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/archive`, null)
  }

  restoreBoard(idboard: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/restore`, null)
  }

  createBoard(board: KanbanBoard)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board`, board)
  }

  changeBoardName(idboard: number, name: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/name`, name);
  }

  updateBoard(idboard: number, board: KanbanBoard)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}`, board)
  }

  removeMemberFromBoard(iduser: number, idboard: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/members/${iduser}`);
  }

  removeTeamFromBoard(idteam: number, idboard: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/teams/${idteam}`);
  }

  removeAllMembersFromBoard(board: KanbanBoard)
  {
    for (let user of board.members)
    {
      this.removeMemberFromBoard(user, board.id).subscribe(() =>
      {
        let index = board.members.findIndex(x => x == user);
        board.members.splice(index, 1);
      });
    }
  }

  getBoardGuests(idboard: number)
  {
    return this.http.get<KanbanBoardGuestDTO>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/guests`);
  }

  addGuestToBoard(iduser: number, idboard: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/guests/${iduser}`, null);
  }

  removeGuestFromBoard(iduser: number, idboard: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/guests/${iduser}`);
  }

  addTeamGuestToBoard(iduser: number, idboard: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/guests/teams/${iduser}`, null);
  }

  removeTeamGuestFromBoard(iduser: number, idboard: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/guests/teams/${iduser}`);
  }

  addMemberToBoard(iduser: number, idboard: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/members/${iduser}`, null);
  }

  addTeamToBoard(idteam: number, idboard: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/teams/${idteam}`, null);
  }

  getUserListToAddToBoard(idboard: number)
  {
    return this.http.get<User[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/potentials-members`);
  }

  updateBoardVisibility(idboard: number, visibility: KanbanBoardVisibility)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/visibility`, visibility);
  }

  updateBoardStarred(idboard: number, starred: boolean)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/starred`, starred);
  }

  createListIntoBoard(idboard: number, list: KanbanList)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/list`, list);
  }

  addAttachmentToBoard(idboard: number, attachment: KanbanAttachment)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/attachment`, attachment);
  }

  removeAttachmentFromBoard(idboard: number, idfile: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/attachment/${idfile}`);
  }

  downloadBoardAttachment(idboard: number, idattachment: number)
  {
    return this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/attachment/${idattachment}`);
  }

  exportBoard(idboard: number, exportParams: ExportBoardParameters)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/export`, exportParams);
  }

  importBoard(boardcontent: any)
  {
    return this.http.post<KanbanBoard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/import`, boardcontent);
  }

  getBoardCardActivities(idboard: number, searchparameters: KanbanBoardActivitiesSearchParameters)
  {
    return this.http.post<KanbanActivity[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${idboard}/card-activities`, searchparameters);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  LIST
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  addCardToList(idlist: number, card: KanbanCard)
  {
    card.creationDate = new Date();
    card.creatorId = this.authService.Access.idUser;
    return this.http.post<KanbanCard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/card`, card);
  }

  getListInfos(idlist: number)
  {
    return this.http.get<KanbanList>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/full-infos`);
  }

  getListBoardInfos(idlist: number)
  {
    return this.http.get<KanbanBoard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/board-infos`);
  }

  getListAndBoardInfos(idlist: number)
  {
    return this.http.get<BoardListForCard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/board-list`);
  }

  getListWorkflowInfos(idlist: number)
  {
    return this.http.get<RelationForListDTO[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/workflow-infos`);
  }

  archiveCardInList(idlist: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/card`);
  }

  moveListToAnotherBoard(moveinfos: MoveListAnotherBoard)
  {
    return this.http.put<KanbanList>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${moveinfos.listIdSource}/move-other-board`, moveinfos);
  }

  updateListAutofilter(idlist: number, autofilter: boolean)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/autofilter`, autofilter);
  }

  updateListFilters(idlist: number, filters: FilterList)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/filters`, filters);
  }

  updateListStyle(list: KanbanList)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${list.id}/style`, list);
  }

  updateListSort(list: KanbanList)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${list.id}/sort-options`, list);
  }

  updateListActionsAtCardCreation(idlist: number, actions: ListAction[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/actions`, actions);
  }

  archiveList(idlist: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/archive`, null);
  }

  updateListVisibility(idlist: number, visible: boolean)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/visibility`, visible);
  }

  restoreList(idlist: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/restore`, null);
  }

  moveList(boardid: number, idlist: number, positions: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/move/${boardid}`, positions)
  }

  updateListName(idlist: number, newname: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/name`, newname);
  }

  updateCardCreationAuthorization(idlist: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/change-card-creation-authorization`, null);
  }

  updateListComment(idlist: number, newcomment: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/comment`, newcomment);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  CARD
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  chekIfYouStayOnTheSameList(card): boolean
  {
    if (card.listId != this.currentEditPositionCard)
      return this.currentListChange = true;
    else
      return this.currentListChange = false;
  }

  updateListActionsOfCard(idcard: number, actions: ListAction[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/actions`, actions);
  }

  createChecklistTemplate(checklisttemplate: KanbanChecklistTemplateParams)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/checklist-template`, checklisttemplate);
  }

  getChecklistTemplates()
  {
    return this.http.get<KanbanChecklistTemplateParams[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/checklist-template`);
  }

  getCardInfos(idcard: number)
  {
    return this.http.get<KanbanCard>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/full-infos`);
  }

  getMailsForCard(idcard: number)
  {
    return this.http.get<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/mails`);
  }

  getCardActivities(idcard: number)
  {
    return this.http.get<KanbanActivity[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/activities`);
  }

  checklistsDisplayInfos: ChecklistDisplayInfos[] = [];
  getCardChecklistDisplayInfos(idcard: number)
  {
    return this.http.get<ChecklistDisplayInfos[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist-display-infos`).pipe(tap(x => this.checklistsDisplayInfos = x));
  }

  updateCardChecklistDisplayInfos(idcard: number, infos: ChecklistDisplayInfos)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist-display-infos`, infos);
  }

  getThirdPartyForCard(idcard: number)
  {
    return this.http.get<ThirdPartyForCredential[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/third-party`);
  }

  notifyUsersForCard(notifyParams: NotifyUsersForCard)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${notifyParams.cardId}/notify-users`, notifyParams);
  }

  addThirdPartyToCard(idcard: number, idpp: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/third-party/${idpp}`, null);
  }

  updateCardThirdPartyLinkDescription(idcard: number, idpp: number, description: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/third-party/${idpp}/description`, description);
  }

  removeThirdPartyFromCard(idcard: number, idpp: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/third-party/${idpp}`);
  }

  updateCardObject(idcard: number, obj: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/object`, obj);
  }

  moveCard(movecard: KanbanMoveCard, idcard: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/move`, movecard)
  }

  updateCardStartDate(idcard: number, start: Date)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/start-date`, start);
  }

  updateCardEndDate(card: KanbanCard, end: Date)
  {
    if (new Date(end) < new Date())
    {
      let index = this.tds.lateTaskList.findIndex(x => x.id == card.id);
      if (index < 0)
        this.tds.lateTaskList.push(card)
    }
    else 
    {
      let index = this.tds.lateTaskList.findIndex(x => x.id == card.id);
      if (index >= 0)
        this.tds.lateTaskList.splice(index, 1)
    }

    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${card.id}/end-date`, end);
  }

  updatePresence(infos: PresenceInfos)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/presence`, infos);
  }

  addAttachment(idcard: number, attachment: KanbanAttachment)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/attachment`, attachment);
  }

  updateCardDescription(idcard: number, description: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/description`, description);
  }

  updateCardLocation(idcard: number, location: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/location`, location);
  }

  addCommentToCard(idcard: number, comment: KanbanComment)
  {
    console.log('add comment !');
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/comment`, comment);
  }

  updateComment(idcard: number, idcomment: number, comment: string)
  {
    console.log('update comment !');
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/comment/${idcomment}`, comment);
  }

  deleteComment(idcard: number, idcomment: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/comment/${idcomment}`);
  }

  addMemberToCard(idcard: number, iduser: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/member`, iduser);
  }

  addTeamToCard(idcard: number, idteam: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/team`, idteam);
  }

  removeMemberFromCard(idcard: number, iduser: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/member/${iduser}`);
  }

  removeTeamFromCard(idcard: number, idteam: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/team/${idteam}`);
  }

  addLabelToCard(idcard: number, idlabel: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/label`, idlabel);
  }

  removeLabelFromCard(idcard: number, idlabel: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/label/${idlabel}`);
  }

  addChecklistToCard(idcard: number, checklist: KanbanChecklist)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist`, checklist);
  }

  renameChecklist(idcard: number, idchecklist: number, newname: string)
  {
    return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}`, newname);
  }

  removeChecklistFromCard(idcard: number, idchecklist: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}`);
  }

  addItemToChecklist(idcard: number, idchecklist: number, item: KanbanChecklistItem)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}/item`, item);
  }

  updateChecklistItemName(idcard: number, idchecklist: number, iditem: number, newname: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}/item/${iditem}/name`, newname);
  }

  updateChecklistItem(idcard: number, idchecklist: number, item: KanbanChecklistItem)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}/item/${item.id}`, item);
  }

  moveChecklistItem(idchecklist: number, iditem: number, idcard: number, positions: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}/item/${iditem}/move`, positions)
  }

  moveChecklist(idcard: number, positions: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/move`, positions)
  }

  updateChecklistItemDone(idcard: number, idchecklist: number, iditem: number, done: boolean)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}/item/${iditem}/done`, done);
  }

  deleteChecklistItem(idcard: number, idchecklist: number, iditem: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/checklist/${idchecklist}/item/${iditem}`);
  }

  archiveCard(idcard: number)
  {
    let index = this.tds.lateTaskList.findIndex(x => x.id == idcard);
    if (index >= 0)
      this.tds.lateTaskList[index].archived = true;
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/archive`, null);
  }

  restoreCard(idcard: number)
  {
    let index = this.tds.lateTaskList.findIndex(x => x.id == idcard);
    if (index >= 0)
      this.tds.lateTaskList[index].archived = false;

    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/restore`, null);
  }

  unlinkCard(idcard: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/unlink`, null);
  }

  deleteCard(idcard: number)
  {
    if (this.removeCardChange)
      this.removeCardChange.next(idcard);
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}`);
  }

  setCardAsCompleted(idcard: number)
  {
    let index = this.tds.lateTaskList.findIndex(x => x.id == idcard);
    if (index >= 0)
      this.tds.lateTaskList[index].completed = true;

    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/completed`, null);
  }

  setCardAsOngoing(idcard: number)
  {
    let index = this.tds.lateTaskList.findIndex(x => x.id == idcard);
    if (index >= 0)
      this.tds.lateTaskList[index].completed = false;
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/ongoing`, null);
  }

  moveCardToListStart(movecard: MoveCardSpecific)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/move-list-start`, movecard);
  }

  moveCardToListEnd(movecard: MoveCardSpecific)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/move-list-end`, movecard);
  }

  moveCardToBeforeCard(movecard: MoveCardSpecific)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/move-card-before-card`, movecard);
  }

  moveCardToAfterCard(movecard: MoveCardSpecific)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/move-card-after-card`, movecard);
  }

  async applyCompletedAction(idlist: number, card: KanbanCard)
  {
    let list = await firstValueFrom(this.getListInfos(idlist));
    let board = await firstValueFrom(this.getListBoardInfos(idlist));

    let filteredActionList = list.actionsAtCardCreation.filter(x => x.triggerAction == "6");
    let filteredActionCard = card.actions.filter(x => x.triggerAction == "6");
    filteredActionList = filteredActionList.concat(filteredActionCard);

    if (filteredActionList.findIndex(x => x.codeAction == "SEND-MAIL-TEAM-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-LABELS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-TEAMS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-CONFIRMATION") >= 0)
    {
      let title = "";
      this.translateService.get("KANBAN.WORKFLOW-MANAGE-TITLE").subscribe(x => title = x);
      let confirm = "";
      this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
      let cancel = "";
      this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
      let modal = this.modalService.create({
        nzTitle: title,
        nzContent: this.actionManagerComponent,
        nzWidth: '90%',
        nzClosable: false,
        nzMaskClosable: false,
        nzFooter: [{
          label: confirm,
          type: 'primary',
          disabled: (result) =>
          {
            if (result.comment.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
              return true;
            if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
              return true;
            if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
              return true;
            if (!result.dueDate && filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
              return true;
            if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
              return true;
            return false;
          },
          onClick: (result) =>
          {
            if (result.comment.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
              return true;
            if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
              return;
            if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
              return;
            if (!result.dueDate && filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
              return;
            if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
              return;

            this.applyListActions(card, list, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, true, true, true, true, result.dueDate);
            card.completed = true;
            card.endDate = new Date();
            this.setCardAsCompleted(card.id).subscribe(() => 
            {
              modal.close();
              this.updateCardDate.next(card)
            })

          }
        },
        {
          label: cancel,
          onClick: () =>
          {
            modal.close();
          }
        }
        ]
      });
      modal.componentInstance.board = board;
      modal.componentInstance.card = card;
      modal.componentInstance.list = list;
      modal.componentInstance.relation = null;
      modal.componentInstance.trigger = "6";
    }
    else
    {

      this.applyListActions(card, list, filteredActionList, "", null, null, null, null, "");
      card.completed = true;
      //card.endDate = new Date();
      this.setCardAsCompleted(card.id).subscribe(() => 
      {
        //this.updateCardDate.next(card)
      })
    }

  }

  async applyMoveCopyAction(idlist: number, typeaction: string, card: KanbanCard, originList: KanbanList)
  {
    let list = await firstValueFrom(this.getListInfos(idlist));
    let board = await firstValueFrom(this.getListBoardInfos(idlist));
    let rel = originList.allowedDeplacementListIds.find(x => x.targetListId == list.id);

    let filteredActionList = list.actionsAtCardCreation.filter(x => x.triggerAction == "2");
    let filteredActionCard = card.actions.filter(x => x.triggerAction == "2");
    filteredActionList = filteredActionList.concat(filteredActionCard);

    if ((rel && rel.actionsRelation) || filteredActionList.length > 0)
    {
      if ((rel && (rel.actionsRelation.indexOf("SEND-MAIL-TEAM-MANUAL") >= 0 || rel.actionsRelation.indexOf("NOTIFY-USER-GENERAL-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-LABELS-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-CARD-TEAMS-MANUAL") >= 0 || rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || rel.actionsRelation.indexOf("ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0 || rel.actionsRelation.indexOf("ASK-CONFIRMATION") >= 0))
        || (filteredActionList.findIndex(x => x.codeAction == "SEND-MAIL-TEAM-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-LABELS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-TEAMS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-CONFIRMATION") >= 0))
      {
        let title = "";
        this.translateService.get("KANBAN.WORKFLOW-MANAGE-TITLE").subscribe(x => title = x);
        let confirm = "";
        this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
        let cancel = "";
        this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
        let modal = this.modalService.create({
          nzTitle: title,
          nzContent: this.actionManagerComponent,
          nzWidth: '90%',
          nzClosable: false,
          nzMaskClosable: false,
          nzFooter: [{
            label: confirm,
            type: 'primary',
            disabled: (result) =>
            {
              if (result.comment.length == 0 && rel && rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
                return true;
              if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
                return true;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return true;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return true;
              if (!result.dueDate && rel && rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0)
                return true;
              if (!result.dueDate && filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
                return true;
              if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return true;
              if (result.listThirdPartyToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return true;
              return false;
            },
            onClick: (result) =>
            {
              if (result.comment.length == 0 && rel && rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
                return;
              if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
                return;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return;
              if (!result.dueDate && rel && rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0)
                return;
              if (!result.dueDate && filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
                return;
              if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return;
              if (result.listThirdPartyToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return;

              this.applyWorkflowActions(card, list, rel, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.dueDate, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, originList.id == list.id);
              if (typeaction == "move")
              {
                let movecard = new MoveCardSpecific();
                movecard.idcard = card.id;
                movecard.idlistto = list.id;
                movecard.idBoard = board.id;
                this.moveCardToListEnd(movecard).subscribe(x =>
                {
                  if (this.cds.selectedBoard && this.cds.selectedBoard.id == board.id)
                  {
                    this.GetAllListForBoard(board.id, this.filterBoardLists).subscribe(x =>
                    {
                      this.cds.selectedBoard.lists = x;
                      this.kanbanBoardChange.next(null);
                    });
                  }
                  modal.close();
                });
              }
              else 
              {
                let copyCard = new KanbanCard();
                copyCard.title = card.title;
                copyCard.description = card.description;
                copyCard.startDate = card.startDate;
                copyCard.dueDate = card.dueDate;
                copyCard.archived = card.archived;
                copyCard.checklists = [...card.checklists];
                copyCard.labels = [...card.labels];
                copyCard.members = [...card.members];
                copyCard.attachments = [...card.attachments];
                copyCard.comments = [...card.comments];
                copyCard.physicalPersons = [...card.physicalPersons];
                copyCard.moralPersons = [...card.moralPersons];

                this.addCardToList(list.id, copyCard).subscribe(x =>
                {
                  copyCard.id = x.id;
                  list.cards.push(copyCard);
                  this.applyWorkflowActions(card, list, rel, list.actionsAtCardCreation.filter(x => x.triggerAction == "0"), result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.dueDate, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, originList.id == list.id);
                  if (this.cds.selectedBoard && this.cds.selectedBoard.id == board.id)
                  {
                    this.GetAllListForBoard(board.id, this.filterBoardLists).subscribe(x =>
                    {
                      this.cds.selectedBoard.lists = x;
                      this.kanbanBoardChange.next(null);
                    });
                  }
                });
              }

            }
          },
          {
            label: cancel,
            onClick: () =>
            {
              modal.close();
            }
          }
          ]
        });
        modal.componentInstance.board = board;
        modal.componentInstance.card = card;
        modal.componentInstance.list = list;
        modal.componentInstance.relation = rel;
        modal.componentInstance.trigger = "2";
      }
      else
      {

        if (typeaction == "move")
        {
          let movecard = new MoveCardSpecific();
          movecard.idcard = card.id;
          movecard.idlistto = list.id;
          movecard.idBoard = board.id;
          this.moveCardToListEnd(movecard).subscribe(x =>
          {
            this.applyWorkflowActions(card, list, rel, list.actionsAtCardCreation.filter(x => x.triggerAction == "2"), null, null, null, null, null, null, "", originList.id == list.id);
            if (this.cds.selectedBoard && this.cds.selectedBoard.id == board.id)
            {
              this.GetAllListForBoard(board.id, this.filterBoardLists).subscribe(x =>
              {
                this.cds.selectedBoard.lists = x;
                this.kanbanBoardChange.next(null);
              });
            }
          });
        }
        else 
        {
          let copyCard = new KanbanCard();
          copyCard.title = card.title;
          copyCard.description = card.description;
          copyCard.startDate = card.startDate;
          copyCard.dueDate = card.dueDate;
          copyCard.archived = card.archived;
          copyCard.checklists = [...card.checklists];
          copyCard.labels = [...card.labels];
          copyCard.members = [...card.members];
          copyCard.attachments = [...card.attachments];
          copyCard.comments = [...card.comments];
          copyCard.physicalPersons = [...card.physicalPersons];
          copyCard.moralPersons = [...card.moralPersons];

          this.addCardToList(list.id, copyCard).subscribe(x =>
          {
            copyCard.id = x.id;
            list.cards.push(copyCard);
            this.applyWorkflowActions(card, list, rel, list.actionsAtCardCreation.filter(x => x.triggerAction == "0"), null, null, null, null, null, null, "", originList.id == list.id);
            if (this.cds.selectedBoard && this.cds.selectedBoard.id == board.id)
            {
              this.GetAllListForBoard(board.id, this.filterBoardLists).subscribe(x =>
              {
                this.cds.selectedBoard.lists = x;
                this.kanbanBoardChange.next(null);
              });
            }
          });
        }
      }
    }
    else
    {
      if (typeaction == "move")
      {
        let movecard = new MoveCardSpecific();
        movecard.idcard = card.id;
        movecard.idlistto = list.id;
        movecard.idBoard = board.id;
        this.moveCardToListEnd(movecard).subscribe(x =>
        {
          this.applyWorkflowActions(card, list, rel, filteredActionList, null, null, null, null, null, null, "", originList.id == list.id);
          if (this.cds.selectedBoard && this.cds.selectedBoard.id == board.id)
          {
            this.GetAllListForBoard(board.id, this.filterBoardLists).subscribe(x =>
            {
              this.cds.selectedBoard.lists = x;
              this.kanbanBoardChange.next(null);
            });
          }
        });
      }
      else 
      {
        let copyCard = new KanbanCard();
        copyCard.title = card.title;
        copyCard.description = card.description;
        copyCard.startDate = card.startDate;
        copyCard.dueDate = card.dueDate;
        copyCard.archived = card.archived;
        copyCard.checklists = [...card.checklists];
        copyCard.labels = [...card.labels];
        copyCard.members = [...card.members];
        copyCard.attachments = [...card.attachments];
        copyCard.comments = [...card.comments];
        copyCard.physicalPersons = [...card.physicalPersons];
        copyCard.moralPersons = [...card.moralPersons];

        this.addCardToList(list.id, copyCard).subscribe(x =>
        {
          copyCard.id = x.id;
          list.cards.push(copyCard);
          this.applyWorkflowActions(card, list, rel, list.actionsAtCardCreation.filter(x => x.triggerAction == "0"), null, null, null, null, null, null, "", originList.id == list.id);
          if (this.cds.selectedBoard && this.cds.selectedBoard.id == board.id)
          {
            this.GetAllListForBoard(board.id, this.filterBoardLists).subscribe(x =>
            {
              this.cds.selectedBoard.lists = x;
              this.kanbanBoardChange.next(null);
            });
          }
        });
      }
    }
  }

  applyListActions(card: KanbanCard, originList: KanbanList, actions: ListAction[], com: string, memberIdsToAffect: any[], teamIdsToAffect: any[], labelIdsToAffect: any[], thirdPartyIdsToAffect: any[], description: string, canAddComment = true, canShowInfo = true, canAddManualMember = true, canAddDueDate = true, dueDate = new Date(), openEditCard = false, isSelfMove = false)
  {


    for (let action of actions)
    {
      if (action.codeAction == "MOVE-CARD" || action.codeAction == "COPY-CARD")
      {
        let typeaction = action.codeAction == "MOVE-CARD" ? "move" : "copy";
        let idlist = action.codeAction == "MOVE-CARD" ? action.listIdMoveCard : action.listIdCopyCard;
        this.applyMoveCopyAction(idlist, typeaction, card, originList)
      }

      if (action.codeAction == "AFFECT-CARD-MEMBERS-AUTO")
      {
        for (let iduser of action.memberAutoParam)
        {
          let id = parseInt(iduser);
          if (id == 0 && card.members.findIndex(m => m == this.authService.connectedUser.id) < 0)
          {
            let user = this.cds.userCollection.find(usr => usr.id == this.authService.connectedUser.id);
            if (user)
            {
              card.members.push(user.id)
              this.addMemberToCard(card.id, user.id).subscribe(() =>
              {

              });
            }
          }
          else
          {
            let user = this.cds.userCollection.find(usr => usr.id == id);
            if (user && card.members.find(x => x == id) == null)
            {
              card.members.push(user.id)
              this.addMemberToCard(card.id, user.id).subscribe(() =>
              {

              });
            }
          }
        }
      }

      if (action.codeAction == "AFFECT-CARD-TEAMS-AUTO")
      {
        for (let idteam of action.teamAutoParam)
        {
          let id = parseInt(idteam);
          if (card.teams.find(x => x == id) == null)
          {
            card.teams.push(id)
            this.addTeamToCard(card.id, id).subscribe(() =>
            {

            });
          }
        }
      }

      if (action.codeAction == "PLAN-CARD-ARCHIVING-AUTO")
      {
        let actionPlanned = new PlannedAction();
        let d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var hour = d.getHours();
        var minutes = d.getMinutes();
        var c = new Date(year + action.dateSchedulingParameter.years, month + action.dateSchedulingParameter.months, day + action.dateSchedulingParameter.days, hour + action.dateSchedulingParameter.hours, minutes + action.dateSchedulingParameter.minutes, 0, 0);
        actionPlanned.executionDate = c;
        actionPlanned.codeAction = "ARCHIVE-CARD";
        actionPlanned.parametersAction = card.id + "";
        this.pas.createPlannedAction(actionPlanned).subscribe();
      }

      if (action.codeAction == "AFFECT-LABELS-AUTO")
      {
        for (let idlabel of action.labelAutoParam)
        {
          let id = parseInt(idlabel);
          let label = this.cds.labelList.find(lb => lb.id == id);
          if (!label)
            label = this.cds.publicLabelList.find(lb => lb.id == id);

          if (label && card.labels.find(x => x.id == id) == null)
          {
            card.labels.push(label)
            card.labels = [...card.labels];
            this.addLabelToCard(card.id, label.id).subscribe(() =>
            {

            });
          }
        }
      }

      if (action.codeAction == "AFFECT-LABELS-MANUAL")
      {
        for (let idlabel of labelIdsToAffect)
        {
          let id = parseInt(idlabel);
          let label = this.cds.labelList.find(lb => lb.id == id);
          if (!label)
            label = this.cds.publicLabelList.find(lb => lb.id == id);
          if (label && card.labels.find(x => x.id == id) == null)
          {
            card.labels.push(label)
            card.labels = [...card.labels];
            this.addLabelToCard(card.id, label.id).subscribe(() =>
            {

            });
          }
        }
      }

      if (action.codeAction == "AFFECT-THIRD-PARTY-AUTO")
      {
        for (let idtp of action.thirdPartyAutoParam)
        {
          let id = parseInt(idtp);
          let tp = this.ppList.find(x => x.id == id);
          if (tp)
          {
            let pp = new PhysicalPerson();
            pp.id = id;
            pp.firstName = tp.firstName;
            pp.lastName = tp.lastName;
            pp.phoneNumbers = tp.phoneNumbers;
            if (card.physicalPersons.find(x => x.id == id) == null)
            {
              this.addThirdPartyToCard(card.id, id).subscribe();
              card.physicalPersons.push(pp);
            }

          }
          else 
          {
            tp = this.mpList.find(x => x.id == id);
            let pp = new MoralPerson();
            pp.id = id;
            pp.name = tp.name;
            pp.phoneNumbers = tp.phoneNumbers;
            if (card.moralPersons.find(x => x.id == id) == null)
            {
              this.addThirdPartyToCard(card.id, id).subscribe();
              card.moralPersons.push(pp);
            }

          }

        }
      }

      if (action.codeAction == "AFFECT-THIRD-PARTY-MANUAL")
      {
        for (let idtp of thirdPartyIdsToAffect)
        {
          let id = parseInt(idtp);
          let tp = this.ppList.find(x => x.id == id);
          if (tp)
          {
            let pp = new PhysicalPerson();
            pp.id = id;
            pp.firstName = tp.firstName;
            pp.lastName = tp.lastName;
            pp.phoneNumbers = tp.phoneNumbers;
            if (card.physicalPersons.find(x => x.id == id) == null)
            {
              this.addThirdPartyToCard(card.id, id).subscribe();
              card.physicalPersons.push(pp);
            }

          }
          else 
          {
            tp = this.mpList.find(x => x.id == id);
            let pp = new MoralPerson();
            pp.id = id;
            pp.name = tp.name;
            pp.phoneNumbers = tp.phoneNumbers;
            if (card.moralPersons.find(x => x.id == id) == null)
            {
              this.addThirdPartyToCard(card.id, id).subscribe();
              card.moralPersons.push(pp);
            }

          }
        }
      }


      if (canAddComment && action.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT")
      {
        let comment = new KanbanComment();
        comment.comment = com;
        comment.date = new Date();
        comment.user = this.authService.connectedUser;
        card.comments.splice(0, 0, comment);
        this.addCommentToCard(card.id, comment).subscribe(x =>
        {
          comment.id = x;
        });
      }

      if (action.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT")
      {
        this.updateCardDescription(card.id, description).subscribe(x =>
        {
          card.description = description;
        });
      }

      if (canAddManualMember && action.codeAction == "AFFECT-CARD-MEMBERS-MANUAL")
      {
        memberIdsToAffect.forEach(member =>
        {
          let user = this.cds.userCollection.find(usr => usr.id == member);
          if (user && card.members.findIndex(m => m == member) < 0)
          {
            card.members.push(user.id)
            this.addMemberToCard(card.id, user.id).subscribe(() =>
            {

            });
          }
        });
      }

      if (action.codeAction == "AFFECT-CARD-TEAMS-MANUAL")
      {
        teamIdsToAffect.forEach(member =>
        {
          let id = parseInt(member);
          if (card.teams.findIndex(m => m == member) < 0)
          {
            card.teams.push(id)
            this.addTeamToCard(card.id, id).subscribe(() =>
            {

            });
          }
        });
      }

      if (canShowInfo && action.codeAction == "SHOW-INFORMATION-TEXT")
      {
        this.msgs.info(action.info);
      }

      if (action.codeAction == "SET-START-DATE")
      {
        if (!isSelfMove)
        {
          let date = new Date()
          card.startDate = date;
          this.updateCardStartDate(card.id, date).subscribe(() => 
          {

          })
        }
      }

      if (canAddDueDate && action.codeAction == "SET-DUE-DATE")
      {
        if (!isSelfMove)
        {
          card.dueDate = dueDate;
          this.updateCardEndDate(card, dueDate).subscribe(() => 
          {

          })
        }
      }

      if (action.codeAction == "MARK-CARD-COMPLETE")
      {
        if (!card.completed)
        {
          this.applyCompletedAction(card.listId, card);
        }
      }

      if (action.codeAction == "REMOVE-CARD-COMPLETE")
      {
        card.completed = false;
        card.endDate = null;
        this.setCardAsOngoing(card.id).subscribe(() =>
        {

        })
      }

      if (action.codeAction == "OPEN-EDIT-CARD")
      {
        openEditCard = false;
        this.editCard(card.id);
      }
    }
    if (openEditCard)
      this.editCard(card.id);

  }

  editCard(idcard: number)
  {
    setTimeout(() =>
    {
      this.openEditCardSub.next(idcard);
    }, 500);
  }



  notifyUser(idcard: number, relation: RelationForListDTO, actions: ListAction[], notify: NotifyUserForAction)
  {
    if ((relation && relation.actionsRelation.indexOf("NOTIFY-USER-GENERAL-MANUAL") >= 0) || (actions.findIndex(x => x.triggerAction == notify.trigger && x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0) ||
      (relation && relation.actionsRelation.indexOf("SEND-MAIL-TEAM-MANUAL") >= 0) || (actions.findIndex(x => x.triggerAction == notify.trigger && x.codeAction == "SEND-MAIL-TEAM-MANUAL") >= 0))
    {
      this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${idcard}/notify-user`, notify).subscribe();
    }
  }

  applyWorkflowActions(card: KanbanCard, originList: KanbanList, relation: RelationForListDTO, actions: ListAction[], com: string, memberIdsToAffect: any[], teamIdsToAffect: any[], dueDate: Date, labelIdsToAffect: any[], thirdPartyIdsToAffect: any[], description: string, isSelfMove = false)
  {
    let canAddComment = true;
    let canShowInfo = true;
    let canAddManualMember = true;
    let canAddDueDate = true;

    //Affect members
    if (relation && relation.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-AUTO") >= 0)
    {
      let temp1 = relation.actionsRelation.split(";");
      for (let t of temp1)
      {
        if (t.startsWith("AFFECT-CARD-MEMBERS-AUTO"))
        {
          let params = t.split(",");
          for (var y = 1; y < params.length; y++)
          {
            let id = parseInt(params[y]);
            if (id == 0 && card.members.findIndex(m => m == this.authService.connectedUser.id) < 0)
            {
              let user = this.cds.userCollection.find(usr => usr.id == this.authService.connectedUser.id);
              if (user)
              {
                card.members.push(user.id)
                this.addMemberToCard(card.id, user.id).subscribe(() =>
                {
                });
              }
            }
            else
            {
              let user = this.cds.userCollection.find(usr => usr.id == id);
              if (user && card.members.find(x => x == id) == null)
              {
                card.members.push(user.id)
                this.addMemberToCard(card.id, user.id).subscribe(() =>
                {

                });
              }
            }
          }
        }
      }
    }

    if (relation && (relation.actionsRelation.indexOf("MOVE-CARD") >= 0 || relation.actionsRelation.indexOf("COPY-CARD") >= 0))
    {
      let temp1 = relation.actionsRelation.split(";");

      for (let t of temp1)
      {
        if (t.startsWith("MOVE-CARD") || t.startsWith("COPY-CARD"))
        {
          let typeaction = t.startsWith("MOVE-CARD") ? "move" : "copy";
          let params = t.split(",");
          for (var y = 1; y < params.length; y++)
          {
            let idlist = parseInt(params[y]);
            this.applyMoveCopyAction(idlist, typeaction, card, originList)
          }
        }
      }
    }

    if (relation && (relation.actionsRelation.indexOf("PLAN-CARD-ARCHIVING-AUTO") >= 0))
    {
      let temp1 = relation.actionsRelation.split(";");

      for (let t of temp1)
      {
        if (t.startsWith("PLAN-CARD-ARCHIVING-AUTO"))
        {
          let params = t.split(",");

          let actionPlanned = new PlannedAction();
          let d = new Date();
          var year = d.getFullYear();
          var month = d.getMonth();
          var day = d.getDate();
          var hour = d.getHours();
          var minutes = d.getMinutes();
          var c = new Date(year + parseInt(params[0]), month + parseInt(params[1]), day + parseInt(params[2]), hour + parseInt(params[3]), minutes + parseInt(params[4]), 0, 0);
          actionPlanned.executionDate = c;
          actionPlanned.parametersAction = card.id + "";
          actionPlanned.codeAction = "ARCHIVE-CARD";
          this.pas.createPlannedAction(actionPlanned).subscribe();
        }
      }
    }

    if (relation && relation.actionsRelation.indexOf("AFFECT-CARD-TEAMS-AUTO") >= 0)
    {
      let temp1 = relation.actionsRelation.split(";");
      for (let t of temp1)
      {
        if (t.startsWith("AFFECT-CARD-TEAMS-AUTO"))
        {
          let params = t.split(",");
          for (var y = 1; y < params.length; y++)
          {
            let id = parseInt(params[y]);
            if (card.teams.find(x => x == id) == null)
            {
              card.teams.push(id)
              this.addTeamToCard(card.id, id).subscribe(() =>
              {
              });
            }
          }
        }
      }
    }

    if (relation && relation.actionsRelation.indexOf("AFFECT-LABELS-AUTO") >= 0)
    {
      let temp1 = relation.actionsRelation.split(";");
      for (let t of temp1)
      {
        if (!t.startsWith("AFFECT-LABELS-AUTO"))
          continue;
        let params = t.split(",");
        for (let idlabel of params)
        {
          let id = parseInt(idlabel);
          let label = this.cds.labelList.find(lb => lb.id == id);
          if (!label)
            label = this.cds.publicLabelList.find(lb => lb.id == id);

          if (label && card.labels.find(x => x.id == id) == null)
          {
            card.labels.push(label)
            card.labels = [...card.labels];
            this.addLabelToCard(card.id, label.id).subscribe(() =>
            {

            });
          }
        }
      }

    }

    if (relation && relation.actionsRelation.indexOf("AFFECT-LABELS-MANUAL") >= 0)
    {

      for (let idlabel of labelIdsToAffect)
      {
        let id = parseInt(idlabel);
        let label = this.cds.labelList.find(lb => lb.id == id);
        if (!label)
          label = this.cds.publicLabelList.find(lb => lb.id == id);

        if (label && card.labels.find(x => x.id == id) == null)
        {
          card.labels.push(label)
          card.labels = [...card.labels];
          this.addLabelToCard(card.id, label.id).subscribe(() =>
          {

          });
        }
      }
    }

    if (relation && relation.actionsRelation.indexOf("AFFECT-THIRD-PARTY-AUTO") >= 0)
    {
      let temp1 = relation.actionsRelation.split(";");
      for (let t of temp1)
      {
        if (!t.startsWith("AFFECT-THIRD-PARTY-AUTO"))
          continue;
        let params = t.split(",");
        for (let idlabel of params)
        {
          let id = parseInt(idlabel);
          if (!id)
            continue;
          let tp = this.ppList.find(x => x.id == id);
          if (tp)
          {
            let pp = new PhysicalPerson();
            pp.id = id;
            pp.firstName = tp.firstName;
            pp.lastName = tp.lastName;
            pp.phoneNumbers = tp.phoneNumbers;
            if (card.physicalPersons.find(x => x.id == id) == null)
            {
              this.addThirdPartyToCard(card.id, id).subscribe();
              card.physicalPersons.push(pp);
            }

          }
          else 
          {
            tp = this.mpList.find(x => x.id == id);
            let pp = new MoralPerson();
            pp.id = id;
            pp.name = tp.name;
            pp.phoneNumbers = tp.phoneNumbers;
            if (card.moralPersons.find(x => x.id == id) == null)
            {
              this.addThirdPartyToCard(card.id, id).subscribe();
              card.moralPersons.push(pp);
            }

          }
        }
      }
    }

    if (relation && relation.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0)
    {
      for (let idtp of thirdPartyIdsToAffect)
      {
        let id = parseInt(idtp);
        let tp = this.ppList.find(x => x.id == id);
        if (tp)
        {
          let pp = new PhysicalPerson();
          pp.id = id;
          pp.firstName = tp.firstName;
          pp.lastName = tp.lastName;
          pp.phoneNumbers = tp.phoneNumbers;
          if (card.physicalPersons.find(x => x.id == id) == null)
          {
            this.addThirdPartyToCard(card.id, id).subscribe();
            card.physicalPersons.push(pp);
          }

        }
        else 
        {
          tp = this.mpList.find(x => x.id == id);
          let pp = new MoralPerson();
          pp.id = id;
          pp.name = tp.name;
          pp.phoneNumbers = tp.phoneNumbers;
          if (card.moralPersons.find(x => x.id == id) == null)
          {
            this.addThirdPartyToCard(card.id, id).subscribe();
            card.moralPersons.push(pp);
          }
        }
      }
    }

    if (relation && relation.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
    {
      let comment = new KanbanComment();
      comment.comment = com;
      comment.date = new Date();
      comment.user = this.authService.connectedUser;
      card.comments.splice(0, 0, comment);
      this.addCommentToCard(card.id, comment).subscribe(x =>
      {
        comment.id = x;

      });
      canAddComment = false;
    }

    if (relation && relation.actionsRelation.indexOf("ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
    {
      this.updateCardDescription(card.id, description).subscribe(x =>
      {
        card.description = description;
      });
    }

    if (relation && relation.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0)
    {
      memberIdsToAffect.forEach(member =>
      {
        let user = this.cds.userCollection.find(usr => usr.id == member);
        if (user && card.members.findIndex(m => m == member) < 0)
        {
          card.members.push(user.id)
          this.addMemberToCard(card.id, user.id).subscribe(() =>
          {

          });
        }
      });
      canAddManualMember = false;
    }

    if (relation && relation.actionsRelation.indexOf("AFFECT-CARD-TEAMS-MANUAL") >= 0)
    {
      teamIdsToAffect.forEach(member =>
      {
        let id = parseInt(member);
        if (card.teams.findIndex(m => m == member) < 0)
        {
          card.teams.push(id)
          this.addTeamToCard(card.id, id).subscribe(() =>
          {

          });
        }
      });
    }

    if (relation && relation.actionsRelation.indexOf("SHOW-INFORMATION-TEXT") >= 0)
    {
      let temp = relation.actionsRelation.split(";");
      for (let t of temp)
      {
        if (t.startsWith("SHOW-INFORMATION-TEXT"))
        {
          let params = t.split(",");
          this.msgs.info(params[1].replace("%%alt666%%", ","))
        }
      }
      canShowInfo = false;
    }

    if (relation && relation.actionsRelation.indexOf("SET-START-DATE") >= 0)
    {
      if (!isSelfMove)
      {
        let date = new Date()
        card.startDate = date;
        this.updateCardStartDate(card.id, date).subscribe(() => 
        {

        })
      }
    }

    if (relation && relation.actionsRelation.indexOf("SET-DUE-DATE") >= 0)
    {
      if (!isSelfMove)
      {
        card.dueDate = dueDate;
        this.updateCardEndDate(card, dueDate).subscribe(() => 
        {

        })
      }
    }

    if (relation && relation.actionsRelation.indexOf("MARK-CARD-COMPLETE") >= 0)
    {
      if (!card.completed)
      {
        this.applyCompletedAction(card.listId, card);
      }
    }

    if (relation && relation.actionsRelation.indexOf("REMOVE-CARD-COMPLETE") >= 0)
    {
      card.completed = false;
      card.endDate = null;
      this.setCardAsOngoing(card.id).subscribe(() =>
      {

      })
    }

    let openEditCard = false
    if (relation && relation.actionsRelation.indexOf("OPEN-EDIT-CARD") >= 0)
    {
      openEditCard = true;
    }

    this.applyListActions(card, originList, actions, com, memberIdsToAffect, teamIdsToAffect, labelIdsToAffect, thirdPartyIdsToAffect, description, canAddComment, canShowInfo, canAddManualMember, canAddDueDate, dueDate, openEditCard, isSelfMove);

  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  WORKFLOW
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  listRelationInfos: KanbanPotentialRelation = new KanbanPotentialRelation();

  createListRelation(setRelation: SetRelationKanban)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/relation`, setRelation);
  }

  updateListRelation(setRelation: SetRelationKanban)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/relation`, setRelation);
  }

  deleteListRelation(idrelation: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/relation/${idrelation}`);
  }

  getListRelation(idlist: number)
  {
    return this.http.get<KanbanPotentialRelation>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/relation/list/${idlist}`).pipe(tap(x => this.listRelationInfos = x));
  }

  getBoardRelation(idboard: number)
  {
    return this.http.get<KanbanPotentialRelation>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/relation/board/${idboard}`).pipe(tap(x => this.listRelationInfos = x));
  }

  createTypeRelation(typrelation: TypeRelation)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/type-relation`, typrelation);
  }

  updateTypeRelation(typrelation: TypeRelation)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/type-relation`, typrelation);
  }

  archiveTypeRelation(idtyperelation: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/type-relation/${idtyperelation}/archive`, null);
  }

  restoreTypeRelation(idtyperelation: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/type-relation/${idtyperelation}/restore`, null);
  }

  getAllGlobalTypeRelation()
  {
    return this.http.get<TypeRelation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/type-relation/global`).pipe(tap(x => this.typeRelationCollection = x));
  }

  getAllBoardTypeRelation(idboard: number)
  {
    return this.http.get<TypeRelation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/type-relation/board/${idboard}`).pipe(tap(x => this.typeRelationCollection = x));
  }



}
