import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlApiService } from '../services/url-api.service';
import { MessagerieRule } from './entities/messagerie-rule';
import { ApplyRuleResults } from './entities/apply-rule-results';

@Injectable({
  providedIn: 'root'
})
export class MessagerieRuleService
{

  constructor(private http: HttpClient) { }
  rules: MessagerieRule[] = [];
  selectedRule: MessagerieRule = null;

  isAdvancedRule(rule: MessagerieRule)
  {
    if (rule.actions.length == 1 && rule.conditions.subblocs.length == 1 && rule.conditions.subblocs[0].subblocs.length == 1 && rule.conditions.subblocs[0].subblocs[0].subblocs.length == 0)
      return false;
    return true;
  }

  getRules(accountidlist: number[])
  {
    return this.http.post<MessagerieRule[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/account/list`, accountidlist)
  }

  getManualRules(accountidlist: number[])
  {
    return this.http.post<MessagerieRule[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/account/list/manual`, accountidlist)
  }

  createRule(rule: MessagerieRule, accountid: number)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/account/${accountid}`, rule)
  }

  disableRule(idrule: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/${idrule}/disable`, null)
  }

  enableRule(idrule: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/${idrule}/enable`, null)
  }

  updateRule(rule: MessagerieRule)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/${rule.id}`, rule)
  }

  applyRule(idrule: number, idfolder: number, accountidlist: number[])
  {
    return this.http.put<ApplyRuleResults>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/${idrule}/apply/folder/${idfolder}/account/${0}`, accountidlist)
  }

  applyRuleToMail(idrule: number, idfolder: number, idmail: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/${idrule}/apply/folder/${idfolder}/mail/${idmail}`, null)
  }

  deleteRule(idrule)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/rule/${idrule}`)
  }

}
