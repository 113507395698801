import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditListSortComponent } from './edit-list-sort.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  imports: [
    CommonModule,
    NzFormModule,
    NzRadioModule,
    TranslateModule,
    FormsModule,
    NzSelectModule
  ],
  declarations: [EditListSortComponent],
  exports: [EditListSortComponent]
})
export class EditListSortModule { }
