import { KanbanList } from "./List";
import { User } from "../../../app/user/model/user";
import { KanbanAttachment } from "./Attachment";
import { GlobalLabel } from "./Label";

export class KanbanBoard  
{
    id: number;
    title: string;
    visibility: KanbanBoardVisibility;
    starred: boolean;
    bgColor: string;
    owner: User;
    archived: boolean;
    lists: KanbanList[] = [];
    members: number[] = [];
    teams: number[] = [];
    type: KanbanBoardType;
    idTemplate: number;
    comment: string;
    canCreateList = true;
    attachments: KanbanAttachment[] = [];
    guestIds: number[] = [];
    guestTeamsIds: number[] = [];
    creationDate: Date;
    nbAttachments: number;
    labels: GlobalLabel[] = [];
}

export enum KanbanBoardVisibility
{
    Private = "Private",
    Shared = "Shared",
    Public = "Public"
}

export enum KanbanBoardType
{
    Template = 1 << 31,
    Kanban = 1,
    Workflow = 2,
    Todo = 4
}