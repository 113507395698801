export class MeetingSearchParameters
{
    searchtext = "";
    searchmeeting = true;
    searchclosed = false;
    start = 0;
    number = 50;
    meetingstart_min: Date = null;
    meetingstart_max: Date = null;
    meetingend_min: Date = null;
    meetingend_max: Date = null;
    duration_min: number = null;
    duration_max: number = null;
}