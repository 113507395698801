import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlossaryEntry } from '../entities/entry';
import { UrlApiService } from '../../../../src/app/services/url-api.service';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlossaryEntrySearchParams } from '../entities/glossary-entry-search-params';

@Injectable({
  providedIn: 'root'
})
export class GlossaryEntryService
{

  glossaryEntries: GlossaryEntry[] = [];
  glossaryEntriesChange: Subject<GlossaryEntry[]> = new Subject<GlossaryEntry[]>();
  constructor(private http: HttpClient) { }

  createGlossaryEntry(entry: GlossaryEntry)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/entry`, entry);
  }

  updateGlossaryEntry(entry: GlossaryEntry)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/entry`, entry);
  }

  archiveGlossaryEntry(identry: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/entry/${identry}/archive`, null);
  }

  restoreGlossaryEntry(identry: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/entry/${identry}/restore`, null);
  }

  getGlossaryEntry(searchparams: GlossaryEntrySearchParams)
  {
    return this.http.post<GlossaryEntry[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/entry/search`, searchparams).pipe(tap(x => this.glossaryEntries = x));
  }

  addCategoryToEntry(identry: number, idcategory: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/entry/${identry}/categories/${idcategory}`, null);
  }

  removeCategoryFromEntry(identry: number, idcategory: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/entry/${identry}/categories/${idcategory}`, null);
  }
}
