import { PhoneNumber } from "../../../../src/app/third-party/entities/phone-number";
import { Mail } from "../../../../src/app/third-party/entities/mail";
import { CustomField } from "../../../../src/app/third-party/entities/custom-field";
import { GlobalLabel } from "../../../../src/app/kanban/entities/Label";
import { ThirdPartyCustomSystemField } from "./third-party-custom-system-field";

export class ThirdPartySheetUpdateInfos
{
    idtp: number;
    comment: string;
    name: string;
    firstName: string;
    lastName: string;
    lastName2: string;
    phoneNumbers: PhoneNumber[] = [];
    brands: string[] = [];
    tags: string[] = [];
    websites: string[] = [];
    secondaryNames: string[] = [];
    image: string;
    labels: GlobalLabel[] = [];
    mails: Mail[] = [];
    customFields: CustomField[] = [];
    nationality: string;
    anniversaryDate: Date;
    gender: string;
    siret: string;
    siren: string;
    sigle: string;
    enseigne: string;
    codeTVA: string;
    codeAPE: string;
    mainCurrency: string;
    spokenLanguages: string[] = [];
    customeSystemFields: ThirdPartyCustomSystemField[] = [];
    companyid: number = null;
    department = "";
    job = "";
}