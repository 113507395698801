import { Component, OnInit, Input } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';
import { SearchResultContainer } from '../../entities/SearchResultContainer';
import { Playlist } from '../../entities/Playlist';
import { Track } from '../../entities/Track';
import { PlaylistViewerParams } from '../../entities/PlaylistVIsualisorParams';

@Component({
  selector: 'app-search-spotify',
  templateUrl: './search-spotify.component.html',
  styleUrls: ['./search-spotify.component.scss']
})
export class SearchSpotifyComponent implements OnInit {

  @Input() searchResult:SearchResultContainer;

  constructor(public spotifyService: SpotifyService) { }

  ngOnInit() {
    

  }

  getImagePlaylist(playlist: Playlist)
  {
    if(playlist.images.length > 0)
      return playlist.images[0].url
      else return "";
  }

  getImageSong(track: Track)
  {
    if(track.album.images.length > 0)
    return track.album.images[0].url;
    else return "";
  }

  setPlaylist(playlist: Playlist)
  {
    this.spotifyService.selectedPlaylist = new PlaylistViewerParams(playlist, false);
    this.spotifyService.selectedCategory = null;
    this.spotifyService.selectedMode = "playlist";
    this.spotifyService.selectedPlaylistChange.next(this.spotifyService.selectedPlaylist.playlist);
    this.spotifyService.searchResult = null;
  }

}
