import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanComponent } from './kanban.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KanbanRoutingModule } from './kanban.routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from '../components/color-picker/color-picker.module';
import { LoadingModule } from '../components/loading/loading.module';
import { KanbanNotificationModule } from './components/kanban-notification/kanban-notification.module';
import { KanbanCardModule } from './components/kanban-card/kanban-card.module';
import { KanbanBoardModule } from './components/kanban-board/kanban-board.module';
import { KanbanBoardArchivesModule } from './components/kanban-board-archives/kanban-board-archives.module';
import { ExportBoardModule } from './components/export-board/export-board.module';
import { NameUserPipeModule } from '../pipes/name-user-pipe/name-user-pipe.module';
import { NameTeamPipeModule } from '../pipes/name-team-pipe/name-team-pipe.module';
import { PipesModule } from '../pipes/pipes/pipes.module';
import { AttachmentManagerModule } from '../components/attachment-manager/attachment-manager.module';
import { KanbanWorkflowSettingsModule } from './components/kanban-workflow-settings/kanban-workflow-settings.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { KanbanBoardActivitiesModule } from './components/kanban-board-activities/kanban-board-activities.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ColorPickerModule,
        LoadingModule,
        ReactiveFormsModule,
        KanbanRoutingModule,
        NzLayoutModule,
        NzPopoverModule,
        NzPopconfirmModule,
        NzButtonModule,
        NzGridModule,
        NzModalModule,
        NzInputModule,
        NzDropDownModule,
        NzTreeModule,
        DragDropModule,
        NzDividerModule,
        NzSelectModule,
        NzUploadModule,
        KanbanBoardActivitiesModule,
        NzTabsModule,
        NzAvatarModule,
        NzIconModule,
        NzToolTipModule,
        KanbanNotificationModule,
        KanbanCardModule,
        KanbanBoardModule,
        NzCheckboxModule,
        KanbanBoardArchivesModule,
        ExportBoardModule,
        NameUserPipeModule,
        NameTeamPipeModule,
        PipesModule,
        NzBadgeModule,
        AttachmentManagerModule,
        KanbanWorkflowSettingsModule
    ],
    declarations: [
        KanbanComponent
    ],
    exports: [
        KanbanComponent
    ]
})
export class KanbanModule { }
