import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderPlaylistSpotifyComponent } from './header-playlist-spotify.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentModule } from 'src/app/components/content/content.module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NzPopconfirmModule,
    ContentModule
  ],
  declarations: [HeaderPlaylistSpotifyComponent],
  exports: [HeaderPlaylistSpotifyComponent]
})
export class HeaderPlaylistSpotifyModule { }
