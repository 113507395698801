import { PhoneCardButton } from "./phone-card-button";
import { PhoneRelationButton } from "./phone-relation-button";

export class PhoneProfileConfig
{
    openCardOnCreation: boolean = true;
    linkPhoneWhenCreatingThirdParty: boolean = true;
    addCardCreatorAsMember: boolean = true;

    showPhoneNumber: boolean = true;
    showBrands: boolean = true;
    showCards: boolean = true;
    showAvatar: boolean = true;
    showDescription: boolean = true;
    showAttachmentsMenu: boolean = true;
    showPicturesMenu: boolean = true;
    showRelationMenu: boolean = true;
    showPasswordMenu: boolean = true;
    showMails: boolean = true;
    showCustomFields: boolean = true;
    showWebsites: boolean = true;
    showSecondaryNames: boolean = true;

    cardButton1: PhoneCardButton = new PhoneCardButton();
    cardButton2: PhoneCardButton = new PhoneCardButton();
    cardButton3: PhoneCardButton = new PhoneCardButton();
    cardButton4: PhoneCardButton = new PhoneCardButton();

    relationButtonPP1: PhoneRelationButton = new PhoneRelationButton();
    relationButtonPP2: PhoneRelationButton = new PhoneRelationButton();
    relationButtonPP3: PhoneRelationButton = new PhoneRelationButton();
    relationButtonPP4: PhoneRelationButton = new PhoneRelationButton();

    relationButtonMP1: PhoneRelationButton = new PhoneRelationButton();
    relationButtonMP2: PhoneRelationButton = new PhoneRelationButton();
    relationButtonMP3: PhoneRelationButton = new PhoneRelationButton();
    relationButtonMP4: PhoneRelationButton = new PhoneRelationButton();

}
