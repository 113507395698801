import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageFromClipboardComponent } from './image-from-clipboard.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [ImageFromClipboardComponent],
  exports: [ImageFromClipboardComponent]
})
export class ImageFromClipboardModule { }
