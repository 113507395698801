import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ModalManagerService } from '../../../../../../src/app/services/modal-manager-service/modal-manager.service';
import { MoralPerson } from '../../entities/moral-person';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { THIRD_PARTY_SHEET } from '../../../../../../src/app/call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { ComponentType } from '@angular/cdk/portal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-open-edit-moral-person',
  templateUrl: './open-edit-moral-person.component.html',
  styleUrls: ['./open-edit-moral-person.component.scss']
})
export class OpenEditMoralPersonComponent implements OnInit
{

  constructor(private mms: ModalManagerService,
    private translate: TranslateService,
    private modalService: NzModalService,
    private message: NzMessageService,
    @Inject(THIRD_PARTY_SHEET) private component: ComponentType<any>
  ) { }

  @Input() moralPerson: MoralPerson;

  ngOnInit()
  {
  }

  editThirdParty()
  {
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: this.component,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: close,
        danger: true,
        onClick: (result) =>
        {
          if (result.infosHasChanged())
          {
            this.translate.get("THIRD-PARTY.CALL.THIRD-PARTY-SHEET.ERROR-ONGOING-MODIFICATION").subscribe(x => this.message.error(x, { nzDuration: 4000 }))
            return;
          }
          modal.close()
        }
      }]
    });
    modal.componentInstance.id = this.moralPerson.id.toString();
  }



}
