import { KanbanAttachment } from "../../../../src/app/kanban/entities/Attachment";
import { PhoneNumber } from "./phone-number";
import { Mail } from "./mail";

export class GetRelation
{
    idRelation: number;
    idSource: number;
    titleSource: string;
    idTarget: number;
    titleTarget: string;
    idTypeRelation: number;
    titleTypeRelationSourceTarget: string;
    titleTypeRelationTargetSource: string;
    direction: number;
    creatorId: number;
    creationDate: Date;
    comment: string;
    actionsRelation: string;
    attachments: KanbanAttachment[] = [];
    phoneNumbers: PhoneNumber[] = [];
    mails: Mail[] = [];
    nbAttachments = 0;
    nbMemos = 0;
}