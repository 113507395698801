import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reccurence } from '../entities/reccurence';
import RRule from 'rrule';

@Component({
  selector: 'app-edit-reccurence',
  templateUrl: './edit-reccurence.component.html',
  styleUrls: ['./edit-reccurence.component.scss']
})
export class EditReccurenceComponent implements OnInit
{

  constructor() { }

  @Input() start: Date = new Date();
  @Input() end: Date = new Date();
  @Input() reccurence: Reccurence;
  @Output() onRuleChange = new EventEmitter<Reccurence>();

  rules: {
    freq: number,
    daily: {
      choice: {
        withInterval: boolean,
        all: boolean
      },
      value: boolean,
      interval: number
    },
    weekly: {
      value: boolean,
      interval: number,
      byweekday: any,
      weekdays: {
        monday: boolean,
        tuesday: boolean,
        wednesday: boolean,
        thursday: boolean,
        friday: boolean,
        saturday: boolean,
        sunday: boolean
      }
    },
    monthly: {
      choice: {
        withoutSetpos: boolean,
        withSetpos: boolean
      },
      value: boolean,
      interval: number,
      bymonthday: number,
      byweekday: any,
      bysetpos: number,
    },
    yearly: {
      choice: {
        withoutSetpos: boolean,
        withSetpos: boolean
      },
      value: boolean,
      interval: number,
      bymonthday: number,
      bymonth: number,
      bysetpos: number,
      byweekday: any
    }
  } = {
      freq: null,
      daily: {
        choice: {
          withInterval: true,
          all: false
        },
        value: false,
        interval: 1
      },
      weekly: {
        value: false,
        interval: 1,
        byweekday: [],
        weekdays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        }
      },
      monthly: {
        choice: {
          withoutSetpos: true,
          withSetpos: false
        },
        value: false,
        interval: 1,
        bymonthday: null,
        byweekday: [],
        bysetpos: null,
      },
      yearly: {
        choice: {
          withoutSetpos: true,
          withSetpos: false
        },
        value: false,
        interval: 1,
        bymonthday: null,
        bymonth: null,
        bysetpos: null,
        byweekday: []
      }
    }

  until: Date;

  ngOnInit()
  {
    this.getRules();
  }

  setDefaultRules()
  {
    this.rules.daily.value = true;
    this.rules.weekly.value = false;
    this.rules.monthly.value = false;
    this.rules.yearly.value = false;

    this.rules.daily.interval = 1;
    this.rules.daily.choice = { withInterval: true, all: false };

    this.rules.weekly.interval = 1;
    this.rules.weekly.byweekday = [this.start.getDay() - 1];
    this.rules.weekly.weekdays = {
      monday: this.start.getDay() == 1 ? true : false,
      tuesday: this.start.getDay() == 2 ? true : false,
      wednesday: this.start.getDay() == 3 ? true : false,
      thursday: this.start.getDay() == 4 ? true : false,
      friday: this.start.getDay() == 5 ? true : false,
      saturday: this.start.getDay() == 6 ? true : false,
      sunday: this.start.getDay() == 7 ? true : false
    };

    this.rules.monthly.interval = 1;
    this.rules.monthly.byweekday = [this.start.getDay() - 1];
    this.rules.monthly.bymonthday = this.start.getDate();
    this.rules.monthly.bysetpos = 1;
    this.rules.monthly.choice = { withoutSetpos: true, withSetpos: false };

    this.rules.yearly.interval = 1;
    this.rules.yearly.byweekday = [this.start.getDay() - 1];
    this.rules.yearly.bymonthday = this.start.getDate();
    this.rules.yearly.bysetpos = 1;
    this.rules.yearly.bymonth = this.start.getMonth() + 1;
    this.rules.yearly.choice = { withoutSetpos: true, withSetpos: false };
  }

  getRules()
  {
    if (!this.reccurence)
    {
      this.setDefaultRules();
      return;
    }

    switch (this.reccurence.freq)
    {
      case RRule.DAILY:
        this.rules.daily.value = true;
        this.rules.weekly.value = false;
        this.rules.monthly.value = false;
        this.rules.yearly.value = false;
        this.rules.daily.interval = this.reccurence.interval ? this.reccurence.interval : 1;
        this.rules.daily.choice = { withInterval: true, all: false };
        break;
      case RRule.WEEKLY:
        this.rules.daily.value = false;
        this.rules.weekly.value = true;
        this.rules.monthly.value = false;
        this.rules.yearly.value = false;
        this.rules.weekly.interval = this.reccurence.interval ? this.reccurence.interval : 1;
        this.rules.weekly.byweekday = this.reccurence.byweekday ? this.reccurence.byweekday : [this.start.getDay()];

        if (this.rules.weekly.byweekday)
        {
          this.rules.weekly.byweekday.forEach(day =>
          {
            switch (day)
            {
              case 0:
                this.rules.weekly.weekdays.monday = true;
                break;
              case 1:
                this.rules.weekly.weekdays.tuesday = true;
                break;
              case 2:
                this.rules.weekly.weekdays.wednesday = true;
                break;
              case 3:
                this.rules.weekly.weekdays.thursday = true;
                break;
              case 4:
                this.rules.weekly.weekdays.friday = true;
                break;
              case 5:
                this.rules.weekly.weekdays.saturday = true;
                break;
              case 6:
                this.rules.weekly.weekdays.sunday = true;
                break;
            }
          });
        }

        break;
      case RRule.MONTHLY:
        this.rules.daily.value = false;
        this.rules.weekly.value = false;
        this.rules.monthly.value = true;
        this.rules.yearly.value = false;
        this.rules.monthly.interval = this.reccurence.interval ? this.reccurence.interval : 1;
        this.rules.monthly.byweekday = this.reccurence.byweekday ? this.reccurence.byweekday : [this.start.getDay()];
        this.rules.monthly.bymonthday = this.reccurence.bymonthday ? this.reccurence.bymonthday : this.start.getDate();
        this.rules.monthly.bysetpos = this.reccurence.bysetpos ? this.reccurence.bysetpos : 1;
        if (this.reccurence.bysetpos)
          this.rules.monthly.choice = { withoutSetpos: false, withSetpos: true };
        else
          this.rules.monthly.choice = { withoutSetpos: true, withSetpos: false };
        break;
      case RRule.YEARLY:
        this.rules.daily.value = false;
        this.rules.weekly.value = false;
        this.rules.monthly.value = false;
        this.rules.yearly.value = true;
        this.rules.yearly.interval = this.reccurence.interval ? this.reccurence.interval : 1;
        this.rules.yearly.byweekday = this.reccurence.byweekday ? this.reccurence.byweekday : [this.start.getDay() - 1];
        this.rules.yearly.bymonthday = this.reccurence.bymonthday ? this.reccurence.bymonthday : this.start.getDate();
        this.rules.yearly.bysetpos = this.reccurence.bysetpos ? this.reccurence.bysetpos : 1;
        this.rules.yearly.bymonth = this.reccurence.bymonth ? this.reccurence.bymonth : this.start.getMonth() + 1;
        if (this.reccurence.bymonth)
          this.rules.yearly.choice = { withoutSetpos: false, withSetpos: true };
        else
          this.rules.yearly.choice = { withoutSetpos: true, withSetpos: false };
        break;
      default:
        this.rules.daily.value = false;
        this.rules.weekly.value = false;
        this.rules.monthly.value = false;
        this.rules.yearly.value = false;
    }

  }

  setRules(value?: number)
  {
    this.reccurence = new Reccurence();
    this.reccurence.freq = RRule.DAILY;
    this.reccurence.interval = 1;

    if (value)
    {
      this.rules.yearly.interval = value;
    }


    if (this.rules.daily.value)
    {
      this.reccurence.freq = RRule.DAILY;
      this.reccurence.interval = this.rules.daily.interval;
    }
    else if (this.rules.weekly.value)
    {
      this.reccurence.freq = RRule.WEEKLY;
      this.reccurence.interval = this.rules.weekly.interval;
      this.reccurence.byweekday = this.rules.weekly.byweekday;
    }
    else if (this.rules.monthly.value)
    {
      this.reccurence.freq = RRule.MONTHLY;
      this.reccurence.interval = this.rules.monthly.interval;
      this.reccurence.bymonthday = this.rules.monthly.choice.withoutSetpos ? this.rules.monthly.bymonthday : null;
      this.reccurence.byweekday = this.rules.monthly.choice.withSetpos ? [this.rules.monthly.byweekday] : null;
      this.reccurence.bysetpos = this.rules.monthly.choice.withSetpos ? this.rules.monthly.bysetpos : null;
    }
    else if (this.rules.yearly.value)
    {
      this.reccurence.freq = RRule.YEARLY;
      this.reccurence.interval = this.rules.yearly.interval;
      this.reccurence.bymonth = this.rules.yearly.bymonth;
      this.reccurence.bymonthday = this.rules.yearly.choice.withoutSetpos ? this.rules.yearly.bymonthday : null;
      this.reccurence.byweekday = this.rules.yearly.choice.withSetpos ? [this.rules.yearly.byweekday] : null;
      this.reccurence.bysetpos = this.rules.yearly.choice.withSetpos ? this.rules.yearly.bysetpos : null;
    }
    else
    {
      this.reccurence = null; // Only one occurrence to prevent the reccurence
    }
    this.onRuleChange.emit(this.reccurence);
  }

  updateCheckBox(rule: number)
  {
    this.rules.daily.value = false;
    this.rules.weekly.value = false;
    this.rules.monthly.value = false;
    this.rules.yearly.value = false;
    switch (rule)
    {
      case 1:
        setTimeout(() =>
        {
          this.rules.daily.value = true;
          this.setRules();
        }, 1);
        break;
      case 2:
        setTimeout(() =>
        {
          this.rules.weekly.value = true;
          this.setRules();
        }, 1);
        break;
      case 3:
        setTimeout(() =>
        {
          this.rules.monthly.value = true;
          this.setRules();
        }, 1);
        break;
      case 4:
        setTimeout(() =>
        {
          this.rules.yearly.value = true;
          this.setRules();
        }, 1);
        break;
      default:
        break;
    }

  }

  updateDailyCheckbox(choice: number, value?: number)
  {
    switch (choice)
    {
      case 1:
        if (value)
          this.rules.daily.interval = value;
        setTimeout(() =>
        {
          this.rules.daily.choice = { withInterval: true, all: false };
          this.setRules();
        }, 1);
        break;
      case 2:
        setTimeout(() =>
        {
          this.rules.daily.choice = { withInterval: false, all: true };
          this.setRules();
        }, 1);
        break;
    }
  }

  updateWeeklyCheckbox()
  {
    this.rules.weekly.byweekday = [];
    if (this.rules.weekly.weekdays.monday)
      this.rules.weekly.byweekday.push(0);
    if (this.rules.weekly.weekdays.tuesday)
      this.rules.weekly.byweekday.push(1);
    if (this.rules.weekly.weekdays.wednesday)
      this.rules.weekly.byweekday.push(2);
    if (this.rules.weekly.weekdays.thursday)
      this.rules.weekly.byweekday.push(3);
    if (this.rules.weekly.weekdays.friday)
      this.rules.weekly.byweekday.push(4);
    if (this.rules.weekly.weekdays.saturday)
      this.rules.weekly.byweekday.push(5);
    if (this.rules.weekly.weekdays.sunday)
      this.rules.weekly.byweekday.push(6);
    setTimeout(() =>
    {
      //this.rules.weekly.weekdays.monday = true;
      this.setRules();
    }, 1);

  }

  updateMonthlyCheckbox(choice: number, value?: number, which?: number)
  {
    switch (choice)
    {
      case 1:
        switch (which)
        {
          case 1:
            if (value)
              this.rules.monthly.bymonthday = value;
            break;
          case 2:
            if (value)
              this.rules.monthly.interval = value;
            break;
        }

        setTimeout(() =>
        {
          this.rules.monthly.choice = { withoutSetpos: true, withSetpos: false };
          this.setRules();
        }, 1);
        break;
      case 2:
        if (value)
          this.rules.monthly.interval = value;
        setTimeout(() =>
        {
          this.rules.monthly.choice = { withoutSetpos: false, withSetpos: true };
          this.setRules();
        }, 1);
        break;
    }
  }

  updateYearlyCheckbox(choice: number, value?: number)
  {
    switch (choice)
    {
      case 1:
        setTimeout(() =>
        {
          if (value)
            this.rules.yearly.bymonthday = value;
          this.rules.yearly.choice = { withoutSetpos: true, withSetpos: false };
          this.setRules();
        }, 1);
        break;
      case 2:
        setTimeout(() =>
        {
          this.rules.yearly.choice = { withoutSetpos: false, withSetpos: true };
          this.setRules();
        }, 1);
        break;
    }
  }

}
