import { Injectable } from '@angular/core';
import { KanbanService } from '../../kanban/services/kanban.service';
import { TranslateService } from '@ngx-translate/core';
import { HubConnection } from '@microsoft/signalr';
import { KanbanBoard, KanbanBoardVisibility } from '../../kanban/entities/Board';
import { AuthService } from '../auth.service';
import { NotifyKanbanListDTO } from '../../kanban/entities/Notify/notify-list';
import { NotifyKanbanCard } from '../../kanban/entities/Notify/notify-card';
import { NotifyMoveKanbanList } from '../../kanban/entities/Notify/notify-move-list';
import { KanbanList } from '../../kanban/entities/List';
import { NotifyChecklistItemMove } from '../../kanban/entities/Notify/notify-checklistitem-move';
import { KanbanChecklistItem } from '../../kanban/entities/ChecklistItem';
import { NotifyKanbanCardDate } from '../../kanban/entities/Notify/notify-card-date';
import { NotifyKanbanCardAttachment } from '../../kanban/entities/Notify/notify-card-attachment';
import { NotifyKanbanCardString } from '../../kanban/entities/Notify/notify-card-text';
import { NotifyCardComment } from '../../kanban/entities/Notify/notify-card-comment';
import { NotifyCardMember } from '../../kanban/entities/Notify/notify-card-member';
import { NotifyCardLabel } from '../../kanban/entities/Notify/notify-card-label';
import { NotifyCardChecklist } from '../../kanban/entities/Notify/notify-card-checklist';
import { NotifyCardChecklistItem } from '../../kanban/entities/Notify/notify-card-checklist-item';
import { GlobalLabel } from '../../kanban/entities/Label';
import { NotifyKanbanLabel } from '../../kanban/entities/Notify/notify-kanban-label';
import { KanbanMoveCard } from '../../kanban/entities/CardMove';
import { KanbanCard } from '../../kanban/entities/Card';
import { NotifyKanbanCardActivity } from '../../kanban/entities/Notify/notify-card-activity';
import { TodoService } from '../../kanban/services/todo.service';
import { NotifyLateTasks } from '../../kanban/entities/Notify/notify-late-tasks';
import { NotifyRightClickCardMove } from '../../kanban/entities/Notify/notify-right-click-card-move';
import { CacheDataService } from '../cache-data.service';
import { NotifyBoardGuest } from '../../kanban/entities/Notify/notify-board-guest';
import { NotifyCardThirdParty } from '../../kanban/entities/Notify/notify-card-third-party';
import { NotifyCardActions } from '../../kanban/entities/Notify/notify-card-actions';
import { KanbanChecklist } from '../../kanban/entities/Checklist';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TypeRelation } from '../../third-party/entities/type-relation';
import { firstValueFrom } from 'rxjs';
import { listenerCount } from 'process';

@Injectable({
  providedIn: 'root'
})
export class KanbanNotificationService
{

  constructor(private modalService: NzModalService,
    private translateService: TranslateService,
    private kanbanService: KanbanService,
    private todoService: TodoService,
    private cds: CacheDataService,
    private authService: AuthService) { }

  public enumVis = KanbanBoardVisibility;

  notifyRightClickCardMove(notiftyRCCard: NotifyRightClickCardMove, typemove: string)
  {
    for (let list of this.cds.selectedBoard.lists)
    {
      let cardindex = list.cards.findIndex(x => x.id == notiftyRCCard.card.id);
      if (cardindex >= 0)
        list.cards.splice(cardindex, 1);
      if (list.id == notiftyRCCard.idlist)
      {
        switch (typemove)
        {
          case "startlist":
            list.cards.splice(0, 0, notiftyRCCard.card)
            break;
          case "endlist":
            list.cards.push(notiftyRCCard.card)
            break;
          case "beforecard":
            let indexcardto = list.cards.findIndex(x => x.id == notiftyRCCard.idcard);
            if (indexcardto >= 0)
              list.cards.splice(indexcardto, 0, notiftyRCCard.card)
            break;
          case "aftercard":
            let indexcardto2 = list.cards.findIndex(x => x.id == notiftyRCCard.idcard);
            if (indexcardto2 >= 0)
              list.cards.splice(indexcardto2 + 1, 0, notiftyRCCard.card)
            break;
        }
      }
    }
    this.kanbanService.kanbanBoardChange.next(null);
  }

  initRoutes(_hubConnection: HubConnection)
  {

    _hubConnection.on('UpdateStateCard', (idboard: number, card: KanbanCard) =>
    {
      console.log(card);

      if (!this.cds.selectedBoard)
        return;

      for (let list of this.cds.selectedBoard.lists)
      {
        let cardindex = list.cards.findIndex(x => x.id == card.id);
        if (list.id == card.listId)
        {
          if (cardindex >= 0)
            list.cards[cardindex] = card;
          else
          {
            list.cards.push(card);
            list.cards.sort((a, b) => a.position > b.position ? 1 : -1);
            list.cards = [...list.cards];
          }
        }
        else if (cardindex >= 0)
        {
          list.cards.splice(cardindex, 1);
          list.cards = [...list.cards];
        }
      }
    });

    _hubConnection.on('UpdateBoardListCreationAuthorization', (idboard: number) =>
    {
      let index = this.kanbanService.allBoardList.findIndex(x => x.id == idboard);
      if (index == -1)
        return;
      this.kanbanService.allBoardList[index].canCreateList = !this.kanbanService.allBoardList[index].canCreateList;
    });

    _hubConnection.on('ArchiveKanbanBoard', (idboard: number) =>
    {
      let index = this.kanbanService.allBoardList.findIndex(x => x.id == idboard);
      if (index == -1)
        return;
      this.kanbanService.allBoardList[index].archived = true;
    });

    _hubConnection.on('NbAttachments', (idboard: number, nbattachments: number) =>
    {
      setTimeout(() =>
      {
        if (this.cds.selectedBoard && this.cds.selectedBoard.id == idboard)
          this.cds.selectedBoard.nbAttachments = nbattachments;
      }, 1000);
    });

    _hubConnection.on('KanbanAddTypeRelation', (idboard: number, typerelation: TypeRelation) =>
    {
      if (this.cds.selectedBoard && this.cds.selectedBoard.id == idboard)
      {
        this.kanbanService.listRelationInfos.typeRelationList.push(typerelation);
        this.kanbanService.updateBoardRelations.next(null);
      }
    });

    _hubConnection.on('RestoreKanbanBoard', (idboard: number) =>
    {
      let index = this.kanbanService.allBoardList.findIndex(x => x.id == idboard);
      if (index == -1)
        return;
      this.kanbanService.allBoardList[index].archived = false;
    });

    _hubConnection.on('RestoreKanbanBoardContent', (idboard: number) =>
    {
      if (this.cds.selectedBoard && this.cds.selectedBoard.id == idboard)
      {
        let title = "";
        let content = "";
        let oktext = "";
        let canceltext = "";
        this.translateService.get("KANBAN.ARCHIVES.TITLE-UPDATE-INTERFACE").subscribe(x => title = x);
        this.translateService.get("KANBAN.ARCHIVES.CONTENT-UPDATE-INTERFACE").subscribe(x => content = x);
        this.translateService.get("KANBAN.ARCHIVES.OK-UPDATE-INTERFACE").subscribe(x => oktext = x);
        this.translateService.get("KANBAN.ARCHIVES.CANCEL-UPDATE-INTERFACE").subscribe(x => canceltext = x);
        this.modalService.warning({
          nzTitle: title,
          nzContent: content,
          nzOnOk: () =>
          {
            this.kanbanService.kanbanBoardChange.next(null);
          },
          nzOkText: oktext,
          nzOnCancel: () => { },
          nzCancelText: canceltext
        })
      }
    });

    _hubConnection.on('CreateKanbanBoard', (board: KanbanBoard) =>
    {
      this.kanbanService.allBoardList.push(board);
    });

    _hubConnection.on('ChangeKanbanBoardName', (board: KanbanBoard) =>
    {
      let index = this.kanbanService.allBoardList.findIndex(x => x.id == board.id);
      if (index == -1)
        return;
      this.kanbanService.allBoardList[index].title = board.title;
    });

    _hubConnection.on('UpdateKanbanBoard', (board: KanbanBoard) =>
    {
      let index = this.kanbanService.allBoardList.findIndex(x => x.id == board.id);
      if (index == -1)
        return;
      this.kanbanService.allBoardList[index].title = board.title;
      this.kanbanService.allBoardList[index].bgColor = board.bgColor;
    });

    _hubConnection.on('AddMemberToKanbanBoard', (idboard: number, iduser: number) =>
    {
      if (!this.cds.selectedBoard || this.cds.selectedBoard.id != idboard)
        return;
      let index = this.cds.selectedBoard.members.findIndex(x => x == iduser)
      if (index == -1)
        this.cds.selectedBoard.members.push(iduser);
      //else if(this.kanbanService.allBoardList.findIndex(x => x.id == board.id) == -1)
      // this.kanbanService.allBoardList[index].members.push(this.authService.Access.idUser);
    });

    _hubConnection.on('AddTeamToKanbanBoard', (board: KanbanBoard, idteam: number) =>
    {
      if (!this.cds.selectedBoard || this.cds.selectedBoard.id != board.id)
        return;
      if (this.cds.selectedBoard && this.cds.selectedBoard.id == board.id && this.cds.selectedBoard.teams.findIndex(x => x == idteam) < 0)
        this.cds.selectedBoard.teams.push(idteam);

    });

    _hubConnection.on('RemoveMemberFromKanbanBoard', (idboard: number, iduser: number) =>
    {

      if (!this.cds.selectedBoard || this.cds.selectedBoard.id != idboard)
        return;
      let indexmember = this.cds.selectedBoard.members.findIndex(x => x == iduser);
      if (indexmember > -1)
        this.cds.selectedBoard.members.splice(indexmember, 1);

    });

    _hubConnection.on('RefreshBoardList', () =>
    {
      this.kanbanService.reloadBoardList.next(null);
    });

    _hubConnection.on('RemoveMemberFromKanbanBoard', (idboard: number, iduser: number) =>
    {

      if (!this.cds.selectedBoard || this.cds.selectedBoard.id != idboard)
        return;
      let indexmember = this.cds.selectedBoard.members.findIndex(x => x == iduser);
      if (indexmember > -1)
        this.cds.selectedBoard.members.splice(indexmember, 1);

    });

    _hubConnection.on('RemoveTeamFromKanbanBoard', (idboard: number, idteam: number, deleteboard: boolean) =>
    {
      let index = this.kanbanService.allBoardList.findIndex(x => x.id == idboard);
      if (index == -1)
        return;
      if (deleteboard)
      {
        this.kanbanService.allBoardList.splice(index, 1);
        if (this.cds.selectedBoard && this.cds.selectedBoard.id == idboard)
          this.cds.selectedBoard = null;
      }
      else 
      {
        let indexmember = this.kanbanService.allBoardList[index].teams.findIndex(x => x == idteam);
        if (indexmember > -1)
          this.kanbanService.allBoardList[index].teams.splice(indexmember, 1);
      }
    });

    _hubConnection.on('AddGuestToKanbanBoard', (notifyBoardGuest: NotifyBoardGuest, idteam: number) =>
    {
      if (this.kanbanService.allBoardList.findIndex(x => x.id == notifyBoardGuest.board.id) < 0)
      {
        this.kanbanService.allBoardList.push(notifyBoardGuest.board);
      }

      if (!this.cds.selectedBoard || this.cds.selectedBoard.id != notifyBoardGuest.board.id)
        return;
      if (this.cds.selectedBoard.guestIds.findIndex(x => x == notifyBoardGuest.userId) >= 0)
        return;
      this.cds.selectedBoard.guestIds.push(notifyBoardGuest.userId);
    });

    _hubConnection.on('RemoveGuestFromKanbanBoard', (notifyBoardGuest: NotifyBoardGuest) =>
    {
      if (!this.cds.selectedBoard || this.cds.selectedBoard.id != notifyBoardGuest.board.id)
        return;
      let index = this.cds.selectedBoard.guestIds.findIndex(x => x == notifyBoardGuest.userId);
      if (index < 0)
        return;
      this.cds.selectedBoard.guestIds.splice(index, 1);
    });

    _hubConnection.on('ChangeKanbanBoardVisibility', (board: KanbanBoard) =>
    {
      let index = this.kanbanService.allBoardList.findIndex(x => x.id == board.id);
      if (index == -1)
      {
        if (board.visibility == this.enumVis.Public)
          this.kanbanService.allBoardList.push(board);
      }
      else
      {
        if (board.members.findIndex(x => x == this.authService.Access.idUser) == -1 && board.owner.id != this.authService.Access.idUser)
        {

          this.kanbanService.allBoardList.splice(index, 1);
        }
        else this.kanbanService.allBoardList[index].visibility = board.visibility;
      }
    });

    _hubConnection.on('AddListToBoard', (notiftyList: NotifyKanbanListDTO) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;
      notiftyList.list.canCreateCard = true;
      this.cds.selectedBoard.lists.push(notiftyList.list)
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('ArchiveCardInList', (notiftyList: NotifyKanbanListDTO) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      this.cds.selectedBoard.lists[indexList].cards.forEach(x =>
      {
        x.archived = true;
        x.visible = false;
      });
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('AddCardToList', async (notifyCard: NotifyKanbanCard) =>
    {
      if (!notifyCard.affected && (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard))
        return;
      if (this.cds.selectedBoard != null && this.cds.selectedBoard.id == notifyCard.idBoard)
      {
        let index = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
        notifyCard.card.visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.card.id, this.kanbanService.filterBoardLists));
        this.cds.selectedBoard.lists[index].cards.push(notifyCard.card);
        this.kanbanService.kanbanBoardChange.next(null);
      }
    });

    _hubConnection.on('ArchiveKanbanList', (notiftyList: NotifyKanbanListDTO) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;
      let index2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      if (index2 == -1)
        return;
      this.cds.selectedBoard.lists[index2].archived = true;

      let indexLocal2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      this.cds.selectedBoard.lists[indexLocal2].archived = true;
      //this.kanbanService.kanbanBoardChange.next(null);

    });

    _hubConnection.on('RestoreKanbanList', (notiftyList: NotifyKanbanListDTO) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;
      let index2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      if (index2 == -1)
        return;
      this.cds.selectedBoard.lists[index2].archived = false;

      let indexLocal2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      this.cds.selectedBoard.lists[indexLocal2].archived = false;
      //this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('MoveKanbanList', (notiftyList: NotifyMoveKanbanList) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;

      let index2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.idMovedList);
      if (index2 == -1)
        return;
      let listMoved: KanbanList[] = [];
      notiftyList.list.forEach(x =>
      {
        let index3 = this.cds.selectedBoard.lists.findIndex(y => y.id == x.id);
        if (index3 >= 0)
          listMoved.push(this.cds.selectedBoard.lists[index3]);
      });
      this.cds.selectedBoard.lists = listMoved;
      this.kanbanService.kanbanBoardChange.next(null);


    });

    _hubConnection.on('RenameKanbanList', (notiftyList: NotifyKanbanListDTO) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;

      let index2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      if (index2 == -1)
        return;

      if (this.cds.selectedBoard.lists[index2].title == notiftyList.list.title)
        return;

      this.cds.selectedBoard.lists[index2].title = notiftyList.list.title;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateCanCreateCardKanbanList', (notiftyList: NotifyKanbanListDTO) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;

      let index2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      if (index2 == -1)
        return;

      this.cds.selectedBoard.lists[index2].canCreateCard = notiftyList.list.canCreateCard;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateKanbanListComment', (notiftyList: NotifyKanbanListDTO) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyList.idBoard)
        return;

      let index2 = this.cds.selectedBoard.lists.findIndex(x => x.id == notiftyList.list.id);
      if (index2 == -1)
        return;

      if (this.cds.selectedBoard.lists[index2].comment == notiftyList.list.comment)
        return;

      this.cds.selectedBoard.lists[index2].comment = notiftyList.list.comment;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('ChangeKanbanCardObject', async (notifyCard: NotifyKanbanCard) =>
    {
      if (!notifyCard.affected && (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard))
        return;

      if (this.cds.selectedBoard != null && this.cds.selectedBoard.id == notifyCard.idBoard)
      {
        let index = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
        let index2 = this.cds.selectedBoard.lists[index].cards.findIndex(x => x.id == notifyCard.card.id);
        this.cds.selectedBoard.lists[index].cards[index2].title = notifyCard.card.title;
        this.cds.selectedBoard.lists[index].cards[index2].activities.splice(0, 0, notifyCard.activity);
        this.cds.selectedBoard.lists[index].cards[index2].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.card.id, this.kanbanService.filterBoardLists));
        this.kanbanService.kanbanBoardChange.next(null);
      }
    });

    _hubConnection.on('AddKanbanCardActivity', (notifyactivity: NotifyKanbanCardActivity) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyactivity.idBoard)
        return;

      let index = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyactivity.idList);
      let index2 = this.cds.selectedBoard.lists[index].cards.findIndex(x => x.id == notifyactivity.activity.card.id);
      this.cds.selectedBoard.lists[index].cards[index2].activities.splice(0, 0, notifyactivity.activity);
      //this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('MoveKanbanCard', (notifyCard: KanbanMoveCard) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;

      let indexListFrom = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idListFrom);
      let indexListTo = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idListTo);
      let newListFrom: KanbanCard[] = [];
      let newListTo: KanbanCard[] = [];
      notifyCard.listCardDestination.forEach(x =>
      {
        let indexCard = this.cds.selectedBoard.lists[indexListTo].cards.findIndex(y => y.id == x);
        if (indexCard >= 0)
          newListTo.push(this.cds.selectedBoard.lists[indexListTo].cards[indexCard]);
        else
        {
          indexCard = this.cds.selectedBoard.lists[indexListFrom].cards.findIndex(y => y.id == x);
          if (indexCard >= 0)
            newListTo.push(this.cds.selectedBoard.lists[indexListFrom].cards[indexCard]);
        }
      });

      if (notifyCard.idListFrom != notifyCard.idListTo)
      {
        notifyCard.listCardOrigin.forEach(x =>
        {
          let indexCard = this.cds.selectedBoard.lists[indexListFrom].cards.findIndex(y => y.id == x);
          if (indexCard >= 0)
            newListFrom.push(this.cds.selectedBoard.lists[indexListFrom].cards[indexCard]);
        });
        this.cds.selectedBoard.lists[indexListFrom].cards = newListFrom;
      }
      this.cds.selectedBoard.lists[indexListTo].cards = newListTo;
      this.kanbanService.kanbanBoardChange.next(null);
      if (this.kanbanService.editedCard && this.kanbanService.editedCard.id == notifyCard.idCard)
      {
        this.kanbanService.editedCard.listId = notifyCard.idListTo;
        let modalContent = this.kanbanService.modalEdit.getContentComponent();
        modalContent.list = this.cds.selectedBoard.lists[indexListTo];
        let modalConfig;
        this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x =>
        {
          modalConfig = x + " " + modalContent.list.title;
          this.kanbanService.modalEdit.updateConfig({ nzTitle: x + " " + modalContent.list.title });
        });
      }
    });



    _hubConnection.on('KanbanMoveStartList', (notiftyRCCard: NotifyRightClickCardMove) =>
    {
      if (this.cds.selectedBoard == null)
        return;
      this.notifyRightClickCardMove(notiftyRCCard, "startlist");
    });

    _hubConnection.on('KanbanMoveEndList', (notiftyRCCard: NotifyRightClickCardMove) =>
    {
      if (this.cds.selectedBoard == null)
        return;
      this.notifyRightClickCardMove(notiftyRCCard, "endlist");
    });

    _hubConnection.on('KanbanMoveBeforeCard', (notiftyRCCard: NotifyRightClickCardMove) =>
    {
      if (this.cds.selectedBoard == null)
        return;
      this.notifyRightClickCardMove(notiftyRCCard, "beforecard");
    });

    _hubConnection.on('KanbanMoveAfterCard', (notiftyRCCard: NotifyRightClickCardMove) =>
    {
      if (this.cds.selectedBoard == null)
        return;
      this.notifyRightClickCardMove(notiftyRCCard, "aftercard");
    });

    _hubConnection.on('MoveKanbanChecklistItem', (notifyCard: NotifyChecklistItemMove) =>
    {
      //TODO
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexChecklist = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.findIndex(x => x.id == notifyCard.idChecklist);
      let newItemList: KanbanChecklistItem[] = [];
      notifyCard.positions.forEach(x =>
      {
        let item = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items.find(y => y.id == x);
        newItemList.push(item);
      });
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items = newItemList;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('ChangeCardStartDate', (notifyCard: NotifyKanbanCardDate) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].startDate = notifyCard.date;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('ChangeCardEndDate', (notifyCard: NotifyKanbanCardDate) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].dueDate = notifyCard.date;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('NotifyLateTasks', (notifyCard: NotifyLateTasks) =>
    {
      this.todoService.lateTaskList = notifyCard.lateTasks.sort((a, b) => new Date(a.dueDate) > new Date(b.dueDate) ? 1 : -1);
    });

    _hubConnection.on('AddAttachmentToCard', (notifyCard: NotifyKanbanCardAttachment) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].attachments.push(notifyCard.attachment);
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('RemoveAttachmentFromCard', (notifyCard: NotifyKanbanCardAttachment) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexAttachment = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].attachments.findIndex(x => x.id == notifyCard.attachment.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].attachments.splice(indexAttachment, 1);
      this.kanbanService.visualizedUrl = "";
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateCardDescription', async (notifyCard: NotifyKanbanCardString) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].description = notifyCard.text;
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateCardLocation', async (notifyCard: NotifyKanbanCardString) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].location = notifyCard.text;
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('AddCommentToCard', (notifyCard: NotifyCardComment) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].comments.splice(0, 0, notifyCard.comment);
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateCommentOfCard', (notifyCard: NotifyCardComment) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexComment = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].comments.findIndex(x => x.id == notifyCard.comment.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].comments[indexComment].comment = notifyCard.comment.comment;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('DeleteCommentOfCard', (notifyCard: NotifyCardComment) =>
    {
      console.log("tototiti");

      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexComment = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].comments.findIndex(x => x.id == notifyCard.comment.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].comments.splice(indexComment, 1);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].comments = [...this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].comments];
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('AddMemberToCard', async (notifyCard: NotifyCardMember) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      if (this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].members.findIndex(x => x == notifyCard.id) == -1)
        this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].members.push(notifyCard.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('AddTeamToCard', async (notifyCard: NotifyCardMember) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      if (this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].teams.findIndex(x => x == notifyCard.id) == -1)
      {
        this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].teams.push(notifyCard.id);
        this.kanbanService.kanbanBoardChange.next(null);
      }
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
    });

    _hubConnection.on('RemoveMemberFromCard', async (notifyCard: NotifyCardMember) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexComment = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].members.findIndex(x => x == notifyCard.id);
      if (indexComment >= 0)
      {
        this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].members.splice(indexComment, 1);
        this.kanbanService.kanbanBoardChange.next(null);
      }
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
    });

    _hubConnection.on('RemoveTeamFromCard', async (notifyCard: NotifyCardMember) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexComment = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].teams.findIndex(x => x == notifyCard.id);
      if (indexComment >= 0)
      {
        this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].teams.splice(indexComment, 1);
        this.kanbanService.kanbanBoardChange.next(null);
      }
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
    });

    _hubConnection.on('AddLabelToCard', async (notifyCard: NotifyCardLabel) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexLabel = this.cds.labelList.findIndex(x => x.id == notifyCard.idLabel);
      if (indexLabel == -1)
      {
        indexLabel = this.cds.publicLabelList.findIndex(x => x.id == notifyCard.idLabel);
        if (this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels.findIndex(x => x.id == notifyCard.idLabel) < 0)
          this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels.push(this.cds.publicLabelList[indexLabel]);
      }
      else
      {
        if (this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels.findIndex(x => x.id == notifyCard.idLabel) < 0)
          this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels.push(this.cds.labelList[indexLabel]);
      }
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels = [...this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels];
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
      //this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('RemoveLabelFromCard', async (notifyCard: NotifyCardLabel) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexLabel = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels.findIndex(x => x.id == notifyCard.idLabel);
      if (indexLabel >= 0)
        this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].labels.splice(indexLabel, 1);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.idBoard, notifyCard.idCard, this.kanbanService.filterBoardLists));
      //this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('AddThirdPartyToCard', async (notifyCard: NotifyCardThirdParty) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.boardId)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.listId);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.cardId);
      if (notifyCard.moralPerson)
        this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].moralPersons.push(notifyCard.moralPerson);
      else if (notifyCard.physicalPerson)
        this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].physicalPersons.push(notifyCard.physicalPerson);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.boardId, notifyCard.cardId, this.kanbanService.filterBoardLists));
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('removeThirdPartyFromCard', async (notifyCard: NotifyCardThirdParty) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.boardId)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.listId);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.cardId);
      if (notifyCard.moralPerson)
      {
        let index = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].moralPersons.findIndex(x => x.id == notifyCard.moralPerson.id);
        if (index >= 0)
          this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].moralPersons.splice(index, 1);
      }
      else if (notifyCard.physicalPerson)
      {
        let index = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].physicalPersons.findIndex(x => x.id == notifyCard.physicalPerson.id);
        if (index >= 0)
          this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].physicalPersons.splice(index, 1);
      }
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].visible = await firstValueFrom(this.kanbanService.GetCardVisibilityForBoard(notifyCard.boardId, notifyCard.cardId, this.kanbanService.filterBoardLists));
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateCardThirdPartyComment', (notifyCard: NotifyCardThirdParty) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.boardId)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.listId);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.cardId);
      if (notifyCard.moralPerson)
      {
        let index = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].moralPersons.findIndex(x => x.id == notifyCard.moralPerson.id);
        if (index >= 0)
          this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].moralPersons[index].comment = notifyCard.moralPerson.comment;
      }
      else if (notifyCard.physicalPerson)
      {
        let index = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].physicalPersons.findIndex(x => x.id == notifyCard.physicalPerson.id);
        if (index >= 0)
          this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].physicalPersons[index].comment = notifyCard.physicalPerson.comment;
      }

      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateCardActions', (notifyCard: NotifyCardActions) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.boardId)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.listId);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.cardId);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].actions = notifyCard.actions;

      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('AddChecklistToCard', (notifyCard: NotifyCardChecklist) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.push(notifyCard.checklist);
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('RenameChecklistOfCard', (notifyCard: NotifyCardChecklist) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexchecklist = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.findIndex(x => x.id == notifyCard.checklist.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexchecklist].title = notifyCard.checklist.title;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('RemoveChecklistFromCard', (notifyCard: NotifyCardChecklist) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexChecklist = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.findIndex(x => x.id == notifyCard.checklist.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.splice(indexChecklist, 1);
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('AddItemToCardChecklist', (notifyCard: NotifyCardChecklistItem) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexChecklist = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.findIndex(x => x.id == notifyCard.idChecklist);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items.push(notifyCard.item);
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('RemoveItemFromChecklist', (notifyCard: NotifyCardChecklistItem) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexChecklist = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.findIndex(x => x.id == notifyCard.idChecklist);
      let indexItem = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items.findIndex(x => x.id == notifyCard.item.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items.splice(indexItem, 1);
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('ChangeCardChecklistItemName', (notifyCard: NotifyCardChecklistItem) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexChecklist = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.findIndex(x => x.id == notifyCard.idChecklist);
      let indexItem = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items.findIndex(x => x.id == notifyCard.item.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items[indexItem].title = notifyCard.item.title;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('ChangeCardChecklistItemProgression', (notifyCard: NotifyCardChecklistItem) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.idCard);
      let indexChecklist = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists.findIndex(x => x.id == notifyCard.idChecklist);
      let indexItem = this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items.findIndex(x => x.id == notifyCard.item.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].checklists[indexChecklist].items[indexItem].done = notifyCard.item.done;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('ArchiveCard', (notifyCard: NotifyKanbanCard) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.card.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].archived = true;
      this.kanbanService.kanbanBoardChange.next(null);

      let task = this.todoService.taskList.find(x => x.id == notifyCard.card.id);
      if (task)
      {
        task.archived = true;
        task.visible = false;
        this.todoService.taskListChange.next(null);
      }
    });

    _hubConnection.on('RestoreCard', (notifyCard: NotifyKanbanCard) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      let indexList = this.cds.selectedBoard.lists.findIndex(x => x.id == notifyCard.idList);
      let indexCardFrom = this.cds.selectedBoard.lists[indexList].cards.findIndex(x => x.id == notifyCard.card.id);
      this.cds.selectedBoard.lists[indexList].cards[indexCardFrom].archived = false;
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('DeleteCard', (notifyCard: NotifyKanbanCard) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notifyCard.idBoard)
        return;
      this.cds.selectedBoard.lists.forEach(x =>
      {
        let index = x.cards.findIndex(y => y.id == notifyCard.card.id);
        if (index >= 0)
          x.cards.splice(index, 1);
      });
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('CompletedCard', (notifyCard: NotifyKanbanCard) =>
    {
      if (this.cds.selectedBoard == null)
        return;


      this.cds.selectedBoard.lists.forEach(x =>
      {
        let index = x.cards.findIndex(y => y.id == notifyCard.card.id);
        if (index >= 0)
        {
          x.cards[index].completed = notifyCard.card.completed;
          x.cards[index].endDate = new Date(notifyCard.card.endDate);


          this.kanbanService.kanbanBoardChange.next(null);
        }
      });

    });

    _hubConnection.on('CreatePublicLabel', (label: GlobalLabel) =>
    {
      this.cds.publicLabelList.push(label);
      this.cds.publicLabelList = this.cds.publicLabelList.sort((x, y) => (x.title.toUpperCase() > (y.title.toUpperCase()) ? 1 : -1));
    });

    _hubConnection.on('CreateBoardLabel', (notiftyLabel: NotifyKanbanLabel) =>
    {
      if (this.cds.selectedBoard == null || this.cds.selectedBoard.id != notiftyLabel.idBoard)
        return;
      this.cds.labelList.push(notiftyLabel.label);
      this.cds.labelList = this.cds.labelList.sort((x, y) => (x.title.toUpperCase() > (y.title.toUpperCase()) ? 1 : -1));
      this.kanbanService.kanbanBoardChange.next(null);
    });

    _hubConnection.on('UpdateLabel', (label: GlobalLabel) =>
    {
      let index = this.cds.publicLabelList.findIndex(x => x.id == label.id);
      if (index == -1)
      {
        index = this.cds.labelList.findIndex(x => x.id == label.id);
        if (index >= 0)
        {
          this.cds.labelList[index].title = label.title;
          this.cds.labelList[index].color = label.color;
        }
      }
      else
      {
        this.cds.publicLabelList[index].title = label.title;
        this.cds.publicLabelList[index].color = label.color;
      }
      if (this.cds.selectedBoard != null)
      {
        this.cds.selectedBoard.lists.forEach(x =>
        {
          x.cards.forEach(y =>
          {
            y.labels.forEach(z =>
            {
              if (z.id == label.id)
              {
                z.title = label.title;
                z.color = label.color;
              }

            })
          })
        });
        this.kanbanService.kanbanBoardChange.next(null);
      }
    });

    _hubConnection.on('DeleteLabel', (idlabel: number) =>
    {
      let index = this.cds.publicLabelList.findIndex(x => x.id == idlabel);
      if (index == -1)
        index = this.cds.labelList.findIndex(x => x.id == idlabel);
      if (index != -1)
        this.cds.labelList.splice(index, 1);
      if (this.cds.selectedBoard != null)
      {
        this.cds.selectedBoard.lists.forEach(x =>
        {
          x.cards.forEach(y =>
          {
            let index2 = y.labels.findIndex(z => z.id == idlabel);
            if (index2 >= 0)
              y.labels.splice(index2, 1);
          })
        });
        this.kanbanService.kanbanBoardChange.next(null);
      }
    });
  }
}
