import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChangePasswordDTO } from '../../entities/change-password';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../../src/app/settings/services/settings.service';
import { PasswordStrategyService } from '../../../../src/app/services/password-strategy.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit
{
  token: string;
  iduser: number;
  uri: string;
  validateForm: FormGroup;
  login = "";
  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder, public passwordStrategyService: PasswordStrategyService,
    private authService: AuthService, private modalService: NzModalService, private translateService: TranslateService, public settingsService: SettingsService,
    private notification: NzNotificationService) { }

  ngOnInit()
  {
    console.log("tata")
    this.authService.setLastVisitedPage();
    //this.authService.logout(false);
    this.validateForm = this.fb.group({
      password: ["", [Validators.required]],
      checkPassword: ["", [Validators.required]],
    });
    this.route.queryParams.subscribe(params =>
    {
      this.token = params["token"];
      this.uri = params["uri"];
      let lang = params["lang"];
      if (lang)
        this.translateService.use(lang);
      this.iduser = parseInt(params["iduser"]);
    });
    this.passwordStrategyService.getParameter();

  }
  changePassword()
  {
    let changePassword: ChangePasswordDTO = new ChangePasswordDTO();
    changePassword.idUser = this.iduser;
    changePassword.token = this.token;
    let verifyPassword = this.validateForm.value.password;
    let error: boolean = this.passwordStrategyService.checkSecurity(verifyPassword);
    if (error)
    {
      const modal = this.modalService.create({
        nzContent: "Error Password",
        nzWidth: '500px',
        nzFooter: [{
          label: 'Ok',
          onClick: () =>
          {
            modal.close();
          }
        }
        ]
      });
      error = false;
      return;
    }
    changePassword.newPassword = this.validateForm.value.password;
    let title = "";
    this.translateService.get("PASSWORD.CHANGE").subscribe(x => title = x);
    let content = "";
    this.translateService.get("PASSWORD.CHANGED-CONFIRMATION-MESSAGE").subscribe(x => content = x);
    this.authService.changePassword(changePassword).subscribe(x =>
    {
      if (x == 0)
      {
        const modal = this.modalService.create({
          nzTitle: title,
          nzContent: content,
          nzWidth: '500px',
          nzFooter: [{
            label: 'Ok',
            onClick: () =>
            {
              modal.close();
              this.router.navigate(['/']);
            }
          }
          ]
        });
      }
      else 
      {
        let titleerror = "";
        this.translateService.get("USER.CHANGE-PASSWORD-TITLE").subscribe(x => titleerror = x);
        let contenterror = "";
        if (x == 1)
          this.translateService.get("USER.CHANGE-PASSWORD-TOKEN").subscribe(x => contenterror = x);
        else this.translateService.get("USER.CHANGE-PASSWORD-ALREADY-USED").subscribe(x => contenterror = x);
        this.notification.error(titleerror, contenterror);
      }

    });

  }
  inputNotValid()
  {
    if (!this.validateForm.value.checkPassword || this.validateForm.value.checkPassword != this.validateForm.value.password)
      return true
    if (this.passwordStrategyService.checkSecurity(this.validateForm.value.password))
      return true
  }
}
