import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanBoardTreeComponent } from './kanban-board-tree.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  imports: [
    CommonModule,
    NzInputModule,
    FormsModule,
    NzTreeModule,
    NzDividerModule,
    TranslateModule,
    NzButtonModule,
    NzIconModule,
    NzCheckboxModule,
    NzSwitchModule
  ],
  declarations: [KanbanBoardTreeComponent],
  exports: [KanbanBoardTreeComponent]
})
export class KanbanBoardTreeModule { }
