import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSecondarySiteComponent } from './add-secondary-site.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzModalModule
  ],
  declarations: [AddSecondarySiteComponent],
  exports: [AddSecondarySiteComponent]
})
export class AddSecondarySiteModule { }
