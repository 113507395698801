import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kanban-card-template-input-manager',
  templateUrl: './kanban-card-template-input-manager.component.html',
  styleUrls: ['./kanban-card-template-input-manager.component.scss']
})
export class KanbanCardTemplateInputManagerComponent implements OnInit
{

  constructor() { }

  cardTitle = "";

  ngOnInit()
  {
    setTimeout(() =>
    {
      document.getElementById("cardfromtemplateinput").focus();
    }, 300);
  }

}
