import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/settings/services/settings.service';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-notification',
  templateUrl: './kanban-notification.component.html',
  styleUrls: ['./kanban-notification.component.scss']
})
export class KanbanNotificationComponent implements OnInit
{

  constructor(public settingsService: SettingsService, public kbs: KanbanService, private authService: AuthService) { }

  @Input() idboard: number;
  startDateNotification = false;
  dueDateNotification = false;
  timeNotification: Date;
  allowMoveForNonRestrictedList = true;
  allowCopyForNonRestrictedList = true;
  title = "";

  ngOnInit()
  {
    this.authService.setCamelineTitle("kanban");
    this.settingsService.getSettingsByName(this.idboard ? "kanban_general_duedate" + this.idboard : "kanban_general_duedate").subscribe(x => { this.dueDateNotification = x == 'true' });
    this.settingsService.getSettingsByName(this.idboard ? "kanban_general_startdate" + this.idboard : "kanban_general_startdate").subscribe(x => { this.startDateNotification = x == 'true' });

    this.settingsService.getSettingsByName(this.idboard ? "kanban_general_time" + this.idboard : "kanban_general_time").subscribe(x =>
    {
      if (x)
        this.timeNotification = new Date(x);
      else
        this.timeNotification = new Date(this.timeNotification.setHours(0, 0, 0, 0));
    });

    this.settingsService.getSystemSettingsByName(this.idboard ? "kanban_move_restricted_list" + this.idboard : "kanban_move_restricted_list").subscribe(x =>
    {
      if (x && x == 'false')
        this.kbs.allowMoveForNonRestrictedList = false;
      else
        this.kbs.allowMoveForNonRestrictedList = true;
    });

    this.settingsService.getSystemSettingsByName(this.idboard ? "kanban_copy_restricted_list" + this.idboard : "kanban_copy_restricted_list").subscribe(x =>
    {
      if (x && x == 'false')
        this.kbs.allowCopyForNonRestrictedList = false;
      else
        this.kbs.allowCopyForNonRestrictedList = true;
    });
  }

  updatePreference(key: string, value: string)
  {
    let realkey = key;
    if (this.idboard)
      realkey += this.idboard;
    this.settingsService.setSettingsByName(realkey, value);
  }

  updateSystemPreference(key: string, value: string)
  {
    let realkey = key;
    if (this.idboard)
      realkey += this.idboard;
    this.settingsService.setSystemSettingsByName(realkey, value);
    if (key.startsWith("kanban_move_restricted_list"))
      this.kbs.allowMoveForNonRestrictedList = value == "true";

    else if (key.startsWith("kanban_copy_restricted_list"))
      this.kbs.allowCopyForNonRestrictedList = value == "true";
  }

}
