import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { KanbanService } from '../../../../src/app/kanban/services/kanban.service';
import { TodoService } from '../../../../src/app/kanban/services/todo.service';
import { KanbanCard } from '../../../../src/app/kanban/entities/Card';
import { Router } from '@angular/router';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { MessagerieService } from '../../../../src/app/messagerie/messagerie.service';
import { GlobalNotificationService } from '../../../../src/app/services/notification/global-notification.service';
import { ChatService } from '../../../../src/app/chat/chat.service';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { ChatConsultationHistoryInfo } from '../../chat/entities/chat-consultation-history-info';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit
{

  constructor(
    public authService: AuthService,
    public kbsService: KanbanService,
    public tdservice: TodoService,
    private router: Router,
    public cds: CacheDataService,
    private nzContextMenuService: NzContextMenuService,
    public mss: MessagerieService,
    public gbs: GlobalNotificationService,
    public chatS: ChatService,
  )
  {

  }

  roomConsultations: ChatConsultationHistoryInfo[] = [];
  privateConsultations: ChatConsultationHistoryInfo[] = [];

  ngOnInit()
  {

  }

  getLasteTask()
  {
    return this.tdservice.lateTaskList.filter(x => !x.completed && !x.archived).sort((a, b) => new Date(a.dueDate) > new Date(b.dueDate) ? 1 : -1);
  }

  change(value: boolean): void
  {
  }

  getMenuHeight()
  {
    //HACK : Pour compenser pour la taille de la navbar
    return (window.innerHeight - 84) + "px";
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void
  {
    this.chatS.getConsultationHistory().subscribe(consultations =>
    {
      this.roomConsultations = consultations.filter(x => x.roomId);
      this.privateConsultations = consultations.filter(x => x.partnerId);

    })
    this.nzContextMenuService.create($event, menu);

  }

  gotoRoom(idroom)
  {
    this.nzContextMenuService.close();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/chat/groups/' + idroom]));
  }

  gotoDiscussion(iddiscussion)
  {
    this.nzContextMenuService.close();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/chat/discussion/' + iddiscussion]));
  }

  gotoTask(task: KanbanCard)
  {
    this.router.navigate(['/kanban/todo/retard/' + task.id])
  }

  goToTemplate()
  {
    this.cds.selectedBoard = null;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/kanban/template']));
  }

  goToBoards()
  {
    this.cds.selectedBoard = null;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/kanban']));
  }

  checkDueDate(task: KanbanCard)
  {
    if (task.dueDate)
    {
      let due = new Date(task.dueDate);
      return due.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    }
  }

  checkDueDateYear(task: KanbanCard)
  {
    let now = new Date();
    let due = new Date(task.dueDate);
    return now.getFullYear() == due.getFullYear()
  }

  isSelected(menuroute: string)
  {
    if (menuroute == this.router.url)
      return true;
    return false;
  }

  chatIsSelected(menuroute: string)
  {
    if (this.router.url.startsWith(menuroute))
      return true;
    return false;
  }

  chatSelected()
  {
    // this.param.paramId = null
    // this.param.displayEditor = false
    // this.param.displayReply = false
    // this.param.idnewmessage = 0
    // this.param.messageToReply = new ChatMessage()
  }

  todoIsSelected()
  {
    if (this.router.url.startsWith("/kanban/todo"))
      return true;
    return false;
  }

  kanbanHomeIsSelected()
  {
    if (this.router.url.startsWith("/kanban") && !this.router.url.startsWith("/kanban/template") && !this.router.url.startsWith("/kanban/settings") && !this.router.url.startsWith("/kanban/todo"))
      return true;
    return false;
  }

  isPhoneHome(menuroute: string)
  {
    if (this.router.url.startsWith("/call-interface") && !this.router.url.startsWith("/call-interface/third-party-sheet") && !this.router.url.startsWith("/call-interface/setting") && !this.router.url.startsWith("/call-interface/profile"))
      return true;
    return false;
  }

  isSelected2(menuroute: string)
  {
    if (this.router.url.startsWith(menuroute))
      return true;
    return false;
  }

  isOpen(menuroute: string)
  {
    if (this.router.url.startsWith(menuroute))
      return true;
    return false;
  }
}
