// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spin {
  text-align: center;
}

.white {
  color: white;
}

.whiteSize {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".loading-spin {\r\n  text-align: center;\r\n}\r\n\r\n.white {\r\n  color: white;\r\n}\r\n\r\n.whiteSize {\r\n  font-size: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
