import { PhysicalPerson } from "../physical-person/entities/physical-person";
import { MoralPerson } from "../moral-person/entities/moral-person";
import { TypeRelation } from "./type-relation";
import { GetRelation } from "./get-relation";

export class ThirdPartyPotentialRelation
{
    physicalPersonList: PhysicalPerson[] = [];
    moralPersonList: MoralPerson[] = [];
    typeRelationList: TypeRelation[] = [];
    relationList: GetRelation[] = [];
}