import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UrlApiService } from '../../../src/app/services/url-api.service';
import { CacheDataService } from '../services/cache-data.service';
import { GlobalNotificationService } from '../services/notification/global-notification.service';
import { User } from '../user/model/user';
import { ChatConsultationHistoryInfo } from './entities/chat-consultation-history-info';
import { ChatDiscussion } from './entities/chat-discussion';
import { ChatLabel } from './entities/chat-label';
import { ChatMember } from './entities/chat-member';
import { ChatMessage } from './entities/chat-message';
import { ChatMessageRead } from './entities/chat-message-read';
import { ChatParam } from './entities/chat-param';
import { ChatPrivateDiscussion } from './entities/chat-private-discussion';

@Injectable({
  providedIn: 'root'
})
export class ChatService
{

  constructor(
    private http: HttpClient,
    public cds: CacheDataService,
    private gbs: GlobalNotificationService,
  ) { }

  ChatNotification: Subject<ChatMessage> = new Subject<ChatMessage>();
  sendMessageSub: Subject<ChatMessage> = new Subject<ChatMessage>();
  readConfirmSub: Subject<any> = new Subject<any>();
  ReadMessageSub: Subject<ChatMessage> = new Subject<ChatMessage>();
  reloadDiscussionSub: Subject<any> = new Subject<any>();
  newRoomSub: Subject<ChatDiscussion[]> = new Subject<ChatDiscussion[]>();
  messageToReply: ChatMessage = null
  discussionIsOpen = false
  readIdmessages = false


  getNumberNotificationsGroup(discussion: ChatDiscussion)
  {
    let nb = 0
    this.gbs.chatNotifications.forEach(x =>
    {
      if (x.message.discussionId == discussion.id)
      {
        nb++
      }
    })
    return nb
  }

  getNumberNotificationsDiscussion(member: User)
  {
    let nb = 0
    this.gbs.chatNotifications.forEach(x =>
    {
      if (x.message.creatorId == member.id && !x.message.discussionId)
      {
        nb++
      }
    })
    return nb
  }

  getNumberNotificationsAllGroup()
  {
    let nb = 0
    this.gbs.chatNotifications.forEach(x =>
    {
      if (x.message.discussionId)
      {
        nb++
      }
    })
    return nb
  }

  getNumberNotificationsAllDiscussions()
  {
    let nb = 0
    this.gbs.chatNotifications.forEach(x =>
    {

      if (!x.message.discussionId)
      {
        nb++
      }
    })
    return nb
  }

  /*
  // update interface to scroll to bottom page
  // display the interface after scrolling
  //
  */
  updateScroll(scrollId: string, param: ChatParam)
  {
    console.log("lol");

    var element = document.getElementById(scrollId);
    if (!element)
      return
    setTimeout(() =>
    {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'auto',
      });
    }, 100);

    setTimeout(() =>
    {
      console.log("this is it");

      element.style.opacity = '1'
      param.dataLoaded = true
    }, 200);
  }

  /*
  // get avatar of user
  // param:
  // creatorId => id of user i want
  //
  */
  getMessageAvatar(creatorId)
  {
    let user = this.cds.userCollection.find(x => x.id == creatorId)
    if (user)
      return user.avatar
    return ""
  }

  /*
  // know if the last message is read
  // if true, var readIdMessage is true
  // param:
  // messageId => id of the message i want
  //
  */
  lastMessageIsRead(messageId: number, param: ChatParam)
  {
    // this.getReadIdmessages(param.paramId, messageId).subscribe(x =>
    // {
    //   this.readIdmessages = x
    // })
  }

  /*
  // know if the list of messages has change
  // param:
  // messageId => id of the last message of list 
  //
  */
  messageListChanges(messageId: number, param: ChatParam)
  {
    param.oldLength = param.messageList.length
    // this.lastMessageIsRead(messageId, param)
  }

  /*
  // get the user name
  // param:
  // creatorId => id of user i want
  //
  */
  getMessageName(creatorId)
  {
    let user = this.cds.userCollection.find(x => x.id == creatorId)
    if (user)
      return user.name + ' ' + user.surname
    return ""
  }

  /*
  // find the name of discussion in discussionList
  // param:
  // discussionId => id of discussion i want
  //
  */
  discussionName(discussionId, param: ChatParam)
  {
    let discussion = param.roomList.find(x => x.id == discussionId)

    if (discussion)
      return discussion.name
    return ""
  }

  /*
  // get the list of discussion
  // param:
  // discussionId => id of discussion i want
  //
  */
  getDiscussionName(discussionId, param: ChatParam)
  {
    if (!param.roomList || param.roomList.length == 0)
    {
      this.getDiscussions().subscribe(x =>
      {
        param.roomList = x
        return this.discussionName(discussionId, param)
      })
    }
    else
      return this.discussionName(discussionId, param)
  }


  /*
   * Chat Api 
   */

  getConsultationHistory()
  {
    return this.http.get<ChatConsultationHistoryInfo[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/consultation`)
  }

  createDiscussion(chatDisussion: ChatDiscussion)
  {
    return this.http.post<ChatDiscussion>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/create/discussion`, chatDisussion)
  }

  createLabel(chatLabel: ChatLabel)
  {
    return this.http.post<ChatLabel>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/create/label`, chatLabel)
  }

  sendMessage(ChatMessage: ChatMessage)
  {
    return this.http.post<ChatMessage>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/send/message`, ChatMessage)
  }

  sendPrivateMessage(ChatMessage: ChatMessage)
  {
    return this.http.post<ChatMessage>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/send/private/message`, ChatMessage)
  }

  editMessage(ChatMessage: ChatMessage)
  {
    return this.http.put<ChatMessage>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/edit/message`, ChatMessage)
  }

  changeDiscussionIsHideSettings(memberId: number)
  {
    return this.http.put<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/set/discussion/hide/settings/${memberId}`, null)
  }

  getAllDiscussionIsHideSettings()
  {
    return this.http.get<ChatPrivateDiscussion[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/get/all/discussion/hide/settings`)
  }

  getRoomsMembers(roomId: number[])
  {
    return this.http.put<ChatMember[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/get/rooms/members`, roomId)
  }

  changeNotificationsSettings(memberId: number)
  {
    return this.http.put<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/set/notif/settings/${memberId}`, null)
  }

  getNotificationsSettings(memberId: number)
  {
    return this.http.get<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/get/notif/settings/${memberId}`)
  }

  markMessage(messageId: number)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/mark/message/${messageId}`, null)
  }

  linkDiscussionToLabel(discussionIds: number[], labelId: number)
  {
    return this.http.post<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/link/label/${labelId}`, discussionIds)
  }

  getMessages(start: number, number: number, discussionId: number)
  {
    return this.http.get<ChatMessage[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/position/${start}/number/${number}/discussion/${discussionId}`)
  }

  getMessagesToMark(id: number, markMessageId: number)
  {
    return this.http.get<ChatMessage[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/room/${id}/mark/${markMessageId}`)
  }

  getPrivateMessagesToMark(id: number, markMessageId: number)
  {
    return this.http.get<ChatMessage[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/discussion/${id}/mark/${markMessageId}`)
  }

  getUnreadMessages(discussionId: number)
  {
    return this.http.get<ChatMessage[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/discussion/${discussionId}`)
  }

  getPrivateMessages(start: number, number: number, ReceiverId: number)
  {
    return this.http.get<ChatMessage[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/position/${start}/number/${number}/receiver/${ReceiverId}`)
  }

  getUnreadPrivateMessages(ReceiverId: number)
  {
    return this.http.get<ChatMessage[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/receiver/${ReceiverId}`)
  }

  // getReadIdmessages(ReceiverId: number, MessageId: number)
  // {
  //   return this.http.get<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/distribued/read/${ReceiverId}/message/${MessageId}`)
  // }

  getActivePrivateDiscussions()
  {
    return this.http.get<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/user/discussion`)
  }

  getDiscussions()
  {
    return this.http.get<ChatDiscussion[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/discussion`)
  }

  getMembersOfDiscussion(discussionId: number)
  {
    return this.http.get<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/members/discussion/${discussionId}`)
  }

  getRoomsIdOfLabel(labelId: number)
  {
    return this.http.get<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/discussion/label/${labelId}`)
  }

  getRoomsOfLabel(labelId: number)
  {
    return this.http.get<ChatDiscussion[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/room/label/${labelId}`)
  }

  getLastMessageReadList(discussionId: number)
  {
    return this.http.get<ChatMessageRead[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/lastRead/${discussionId}`)
  }

  getLastPrivateMessageReadList(discussionId: number)
  {
    return this.http.get<ChatMessageRead[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/lastRead/private/${discussionId}`)
  }

  getLabels()
  {
    return this.http.get<ChatLabel[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/label`)
  }

  setNotifRoom(discussionId: number, value: boolean)
  {
    return this.http.put<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/settings/notifications/room/${discussionId}/value/${value}`, null)
  }

  updateDiscussion(discussion: ChatDiscussion)
  {
    return this.http.put<ChatDiscussion>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/update/discussion`, discussion)
  }

  getNotifRoom(discussionId: number)
  {
    return this.http.get<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/settings/notifications/room/${discussionId}`)
  }

  addMember(userIds: number[], discussionId: number)
  {
    return this.http.post<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/add-user/discussion/${discussionId}`, userIds)
  }

  removeMember(userId: number, discussionId: number)
  {
    return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/remove/user/${userId}/discussion/${discussionId}`)
  }

  readMessages(messages: ChatMessageRead[])
  {
    return this.http.post<ChatMessageRead[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/read`, messages)
  }

  removeDiscussion(discussionId: number)
  {
    return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/remove/discussion/${discussionId}`)
  }

  removeLinkDiscussionToLabel(discussionId: number, labelId: number)
  {
    return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/remove-link/discussion/${discussionId}/label/${labelId}`)
  }

  RemoveLabel(labelId: number)
  {
    return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/remove/label/${labelId}`)
  }

  getMarkMessages(id, type)
  {
    return this.http.get<ChatMessage[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/type/${type}/id/${id}`)
  }

  setNewConnexionStatus(statusType: string)
  {
    return this.http.put<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/user/set/status/${statusType}`, null)
  }

  getConnexionStatus()
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/user/connexion/status`)
  }
}