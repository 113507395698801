import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { DatelocalePipe } from 'src/app/pipes/pipes/datelocale.pipe';
import { ProspectionService } from 'src/app/prospection/prospection.service';

@Injectable({
  providedIn: 'root'
})
export class ProspectionNotificationService
{

  constructor(private prospectionService: ProspectionService, private translateService: TranslateService, private datepipe: DatelocalePipe) { }

  initRoutes(_hubConnection: HubConnection)
  {

    _hubConnection.on('UpdateProgressProspectImport', (progress: number, start: Date, end: Date) =>
    {
      this.prospectionService.cancreate = false;
      if (!start || !end)
      {
        this.prospectionService.imporProgressState = "info";
        this.translateService.get("PROSPECTION.IMPORT.PROGRESS-ONGOING", { v: progress }).subscribe(x => this.prospectionService.importProgressText = x)
      }
      else 
      {
        this.translateService.get("PROSPECTION.IMPORT.PROGRESS-FINISHED", { v: progress, v2: this.datepipe.transform(start, 'medium'), v3: this.datepipe.transform(end, 'medium') }).subscribe(x => this.prospectionService.importProgressText = x)
        this.prospectionService.imporProgressState = "success";

      }
    });

    _hubConnection.on('FinishedProspectionFileMemoryLoad', () =>
    {
      this.prospectionService.cancreate = true;
    });

  }

}
