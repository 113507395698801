import { LocalizedMessage } from "../../../entities/localized-message";

export const EN_ERROR_PAYLOAD: LocalizedMessage[] = 
[
    { id: "API_User_ErrorSeeUser", message: 'Error getting the list of active users' },
    { id: "API_User_ForbiddenSeeUser", message: 'You are not allowed to see the list of users' },
    { id: "API_User_ForbiddenChangeUserRights", message: 'You are not allowed to change a user rights in this group' },
    { id: "API_User_ForbiddenSeeUserArchived", message: 'You are not allowed to see the archived users' },
    { id: "API_User_ForbiddenCreateUser", message: 'You are not allowed to create a new user' },
    { id: "API_User_ForbiddenRestoreUser", message: 'You are not allowed to restore a user' },
    { id: "API_User_ForbiddenUpdateUser", message: 'You are not allowed to update a user' },
    { id: "API_User_ForbiddenArchiveUser", message: 'You are not allowed to archive a user' },
    { id: "API_Rights_ForbiddenSuperAdmin", message: 'You need to be super administrator to do this action' },
    { id: "API_Log_Forbiddenseelistlog", message: 'You are not allowed to see logs' },
    { id: "API_GroupUser_ForbiddenArchiveGroupUser", message: 'You are not allowed to archive this group of user' },
    { id: "API_GroupUser_ForbiddenUpdateGroupUser", message: 'You are not allowed to update this group of user' },
    { id: "API_GroupUser_ForbiddenRemoveUserFromGroupUser", message: 'You are not allowed to remove a user from this group of user' },
    { id: "API_GroupUser_ForbiddenAddUserToGroupUser", message: 'You are not allowed to add a user to this group of user' },
    { id: "API_GroupUser_ForbiddenCreateGroupUser", message: 'You are not allowed to create a group of user' },
    { id: "API_GroupUser_ForbiddenGetManagersGroupUser", message: 'You are not allowed to see the managers of this group of user' },
    { id: "API_GroupUser_ForbiddenChangeRightsGroupUser", message: 'You are not allowed to change the rights for this group of user' },
    { id: "API_GroupCredentials_ForbiddenArchiveGroupCredentials", message: 'You are not allowed to archive this group of credentials' },
    { id: "API_GroupCredentials_ForbiddenUpdateGroupCredentials", message: 'You are not allowed to update this group of credentials' },
    { id: "API_GroupCredentials_ForbiddenAddManagerGroupCredentials", message: 'You are not allowed to add a manager to this group of credentials' },
    { id: "API_GroupCredentials_ForbiddenRemoveManagerGroupCredentials", message: 'You are not allowed to remove a manager from this group of credentials' },
    { id: "API_GroupCredentials_ForbiddenChangeRightsGroupCredentials", message: 'You are not allowed to change a manager right in this group of credentials' },
    { id: "API_GroupCredentials_ForbiddenManageAdminGroupCredentials", message: 'You are not allowed to manage administrators of this group of credentials' },
    { id: "API_GroupCredentials_ForbiddenRemoveCredentialsGroupCredentials", message: 'You are not allowed to remove credentials from this group of credentials' },
    { id: "API_GroupCredentials_ForbiddenAddCredentialsGroupCredentials", message: 'You are not allowed to add credentials to this group of credentials' },
    { id: "API_GroupUser_ForbiddenRemoveGroupCredentialsGroupUser", message: 'You are not allowed to remove a group of credentials from this group of user' },
    { id: "API_GroupUser_ForbiddenAddGroupCredentialsGroupUser", message: 'You are not allowed to add a group of credentials to this group of user' },
    { id: "API_GroupUser_ForbiddenManageGroupCredentialsGroupUser", message: 'You are not allowed to remove a group of credentials from this group of user' },
    { id: "API_GroupCredentials_ForbiddenCreateGroupCredentials", message: 'You are not allowed to create a group of credentials' },
    { id: "API_GroupCredentials_ForbiddenManageCredentialsGroupCredentials", message: 'You are not allowed to manage credential for this group of credentials' },
    { id: "API_Credentials_ForbiddenAccessCredentials", message: 'You are not allowed to use the password manager' },
    { id: "API_Credentials_ForbiddenDeleteCredentials", message: 'You are not allowed to delete a credentials' },
    { id: "API_Credentials_ForbiddenUpdateCredentials", message: 'You are not allowed to update a credentials' },
    { id: "API_Credentials_ForbiddenCreateCredentials", message: 'You are not allowed to create this credentials' },
    { id: "API_Credentials_ForbiddenGetPasswordCredentials", message: 'You are not allowed to see this credentials' },
    { id: "API_Kanban_FailedReachRecipientNotification", message: 'Failed to reach the recipient. The mail could not be sent.' },
]