import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MoralPerson } from '../../entities/moral-person';
import { ModalManagerService } from 'src/app/services/modal-manager-service/modal-manager.service';
import { MoralPersonEditComponent } from '../moral-person-edit/moral-person-edit.component';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-add-secondary-site',
  templateUrl: './add-secondary-site.component.html',
  styleUrls: ['./add-secondary-site.component.scss']
})
export class AddSecondarySiteComponent implements OnInit
{

  constructor(private mms: ModalManagerService,
    private modal: NzModalRef<MoralPersonEditComponent>) { }

  @Input() moralPerson: MoralPerson;
  @Input() listSecondarySites: MoralPerson[];
  @Output() listSecondarySitesChanges: EventEmitter<MoralPerson[]> = new EventEmitter(true);

  ngOnInit()
  {
  }

  createSecondarySite()
  {
    this.modal = this.mms.createMPFull(this.moralPerson, null);
    this.modal.afterClose.subscribe((result) =>
    {
      //this.listSecondarySites.push(this.modal.getContentComponent().moralPerson)
      this.listSecondarySitesChanges.emit(this.listSecondarySites)
    });
  }

}
