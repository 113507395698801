import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialUserPipe } from './initial-user.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InitialUserPipe],
  exports: [InitialUserPipe]
})
export class InitialUserModule { }
