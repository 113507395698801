// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-input-mini {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px;
}

#F1rst {
  background-color: #2ecc71;
}

#Thi3d {
  background-color: #e7973c;
}

#S2cond {
  background-color: #bb2424;
}

#Fourt4 {
  background-color: #e74c3c;
}

#Fi5e {
  background-color: #696969;
}

#pers0 {
  background-color: #3498db;
}`, "",{"version":3,"sources":["webpack://./src/app/components/floating-chat/floating-chat-title/floating-chat-title/floating-chat-title.component.scss"],"names":[],"mappings":"AASA;EACE,qBAAA;EACA,UAAA;EACA,WAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;EACA,2BAAA;EACA,kBAAA;EACA,iBAAA;AARF;;AAWA;EACE,yBAtBQ;AAcV;;AAWA;EACE,yBAzBQ;AAiBV;;AAWA;EACE,yBA5BQ;AAoBV;;AAWA;EACE,yBA/BQ;AAuBV;;AAWA;EACE,yBAlCQ;AA0BV;;AAWA;EACE,yBArCQ;AA6BV","sourcesContent":["// Colors\r\n$color-1: #2ecc71;\r\n$color-2: #e7973c;\r\n$color-3: #bb2424;\r\n$color-4: #e74c3c;\r\n$color-5: #696969;\r\n$color-6: #3498db;\r\n\r\n\r\n.round-input-mini {\r\n  display: inline-block;\r\n  width: 8px;\r\n  height: 8px;\r\n  margin-right: 5px;\r\n  vertical-align: middle;\r\n  border-radius: 50%;\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n  text-align: center;\r\n  line-height: 44px;\r\n}\r\n\r\n#F1rst {\r\n  background-color: $color-1;\r\n}\r\n\r\n#Thi3d {\r\n  background-color: $color-2;\r\n}\r\n\r\n#S2cond {\r\n  background-color: $color-3;\r\n}\r\n\r\n#Fourt4 {\r\n  background-color: $color-4;\r\n}\r\n\r\n#Fi5e {\r\n  background-color: $color-5;\r\n}\r\n\r\n#pers0 {\r\n  background-color: $color-6;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
