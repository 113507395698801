import { FilterList } from "./filter-list";

export class FilterBoardLists
{
    searchText = "";
    filterList: FilterList = new FilterList();
    labelFiltered: number[] = [];
    labelExiled: number[] = [];
    physicalPersonFiltered: number[] = [];
    physicalPersonExiled: number[] = [];
    moralPersonFiltered: number[] = [];
    moralPersonExiled: number[] = [];
    userFiltered: number[] = [];
    userExiled: number[] = [];
    teamFiltered: number[] = [];
    teamExiled: number[] = [];
}