import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kanban_user_exiled',
  pure: false
})
export class KanbanUserExiledPipe implements PipeTransform
{

  transform(value: number, exiledUsers: number[]): boolean
  {
    return exiledUsers.findIndex(x => x == value) >= 0;
  }

}
