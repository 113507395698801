import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { GroupUserDataService } from 'src/app/group-user/services/data/group-user-data.service';
import { GroupUserWithRights } from 'src/app/group-user/entities/group-user-with-rights';
import { NotifyGroupUserWithRightsDTO } from 'src/app/group-user/entities/notify-group-user-with-rights';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class GroupUserNotificationService
{

  constructor(private groupUserService: GroupUserDataService,
    private notification: NzNotificationService,
    private translateService: TranslateService) { }


  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('CreateGroupUser', (groupUserWithRights: GroupUserWithRights) =>
    {
      this.groupUserService.listGroupUserWithRights.push(groupUserWithRights);
      this.groupUserService.listGroupUserWithRightsChange.next(this.groupUserService.listGroupUserWithRights);
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-USER.CREATE", { v: groupUserWithRights.grpuser.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('AddOrUpdateGroupUser', (groupUserWithRights: GroupUserWithRights) =>
    {
      let index = this.groupUserService.listGroupUserWithRights.findIndex(x => x.grpuser.id == groupUserWithRights.grpuser.id);
      if (index == -1)
      {
        this.groupUserService.listGroupUserWithRights.push(groupUserWithRights);
        let title = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.TITLE").subscribe(x => title = x);
        let content = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.ADD-USER", { v: groupUserWithRights.grpuser.name }).subscribe(x => content = x);
        this.notification.create(
          'info',
          title,
          content
        );
      }
      else this.groupUserService.listGroupUserWithRights[index] = groupUserWithRights;
      this.groupUserService.listGroupUserWithRightsChange.next(this.groupUserService.listGroupUserWithRights);
    });

    _hubConnection.on('RemoveOrUpdateGroupUser', (groupUserWithRights: NotifyGroupUserWithRightsDTO) =>
    {
      let index = this.groupUserService.listGroupUserWithRights.findIndex(x => x.grpuser.id == groupUserWithRights.groupUserWithRights.grpuser.id);
      if (index == -1)
        return;
      if (groupUserWithRights.delete)
      {
        this.groupUserService.listGroupUserWithRights.splice(index, 1);
        let title = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.TITLE").subscribe(x => title = x);
        let content = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.REMOVE-USER", { v: groupUserWithRights.groupUserWithRights.grpuser.name }).subscribe(x => content = x);
        this.notification.create(
          'info',
          title,
          content
        );
      }
      else this.groupUserService.listGroupUserWithRights[index] = groupUserWithRights.groupUserWithRights;
      this.groupUserService.listGroupUserWithRightsChange.next(this.groupUserService.listGroupUserWithRights);
    });

    _hubConnection.on('UpdateGroupUser', (groupUserWithRights: GroupUserWithRights) =>
    {
      let index = this.groupUserService.listGroupUserWithRights.findIndex(x => x.grpuser.id == groupUserWithRights.grpuser.id);
      if (index == -1)
      {
        this.groupUserService.listGroupUserWithRights.push(groupUserWithRights);
        let title = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.TITLE").subscribe(x => title = x);
        let content = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.ADD-USER", { v: groupUserWithRights.grpuser.name }).subscribe(x => content = x);
        this.notification.create(
          'info',
          title,
          content
        );
      }
      else
      {
        this.groupUserService.listGroupUserWithRights[index] = groupUserWithRights;
        let title = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.TITLE").subscribe(x => title = x);
        let content = "";
        this.translateService.get("NOTIFICATION.GROUP-USER.UPDATE", { v: groupUserWithRights.grpuser.name }).subscribe(x => content = x);
        this.notification.create(
          'info',
          title,
          content
        );
      }
      this.groupUserService.listGroupUserWithRightsChange.next(this.groupUserService.listGroupUserWithRights);
    });

    _hubConnection.on('ArchiveGroupUser', (groupUserWithRights: GroupUserWithRights) =>
    {
      let index = this.groupUserService.listGroupUserWithRights.findIndex(x => x.grpuser.id == groupUserWithRights.grpuser.id);
      if (index == -1)
        return;
      this.groupUserService.listGroupUserWithRights[index] = groupUserWithRights;
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-USER.ARCHIVE", { v: groupUserWithRights.grpuser.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
      this.groupUserService.listGroupUserWithRightsChange.next(this.groupUserService.listGroupUserWithRights);
    });

    _hubConnection.on('RestoreGroupUser', (groupUserWithRights: GroupUserWithRights) =>
    {
      let index = this.groupUserService.listGroupUserWithRights.findIndex(x => x.grpuser.id == groupUserWithRights.grpuser.id);
      if (index == -1)
        return;
      this.groupUserService.listGroupUserWithRights[index] = groupUserWithRights;
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-USER.RESTORE", { v: groupUserWithRights.grpuser.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
      this.groupUserService.listGroupUserWithRightsChange.next(this.groupUserService.listGroupUserWithRights);
    });
  }
}
