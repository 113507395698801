import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { KanbanService } from '../../services/kanban.service';
import { KanbanBoard } from '../../entities/Board';
import { BoardArchives } from '../../entities/board-archives';
import { RestorationParams } from '../../entities/restoration-params';
import { KanbanCard } from '../../entities/Card';
import { KanbanList } from '../../entities/List';
import { Subscription } from 'rxjs';
import { TextCompareService } from 'src/app/services/text-compare.service';
import { NzTreeComponent } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-kanban-board-archives',
  templateUrl: './kanban-board-archives.component.html',
  styleUrls: ['./kanban-board-archives.component.scss']
})
export class KanbanBoardArchivesComponent implements OnInit
{

  constructor(public tcs: TextCompareService, public kbs: KanbanService) { }

  @Input() board: KanbanBoard;

  archives: BoardArchives = new BoardArchives();
  archivesNodes: any[] = [];
  selectedId = 0;
  selectedType = "";
  filterValue = "";
  previewedList: KanbanList = null;
  previewedCard: KanbanCard = null;
  previewedCardList: KanbanList = null;
  loadingPreview = false;
  subscription: Subscription;
  @ViewChild('nzTreeComponent', { static: false }) nzTreeComponent!: NzTreeComponent;
  ngOnInit()
  {
    this.subscription = this.kbs.reloadBoardArchivesSub.subscribe(() =>
    {
      this.initArchives();
    })
    this.initArchives();
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  initArchives()
  {
    this.previewedCard = null;
    this.previewedList = null;
    this.selectedType = "";
    this.kbs.getBoardArchives(this.board.id).subscribe(x =>
    {
      this.archives = x;
      this.archives.listWithArchivedCards.sort((a, b) => this.board.lists.findIndex(x => x.id == a.id) < this.board.lists.findIndex(x => x.id == b.id) ? -1 : 1);
      this.initArchivesTree();
    })
  }

  initArchivesTree()
  {
    this.archivesNodes = [];
    let filterValueUpper = this.filterValue.toUpperCase();
    this.archives.listWithArchivedCards.forEach(list =>
    {
      let templist = this.board.lists.find(x => x.id == list.id);
      list.title = templist.title;
      let addToList = this.tcs.contains(list.title, filterValueUpper)
      let listNode = {
        title: list.title,
        key: "list_" + list.id,
        bgcolor: list.color,
        expanded: false,
        disableCheckbox: true,
        checked: false,
        children: []
      };
      list.cards.forEach(card =>
      {
        let addCardToList = addToList;
        if (!addToList)
          addCardToList = this.tcs.contains(card.title, filterValueUpper)
        let cardNode =
        {
          title: card.title,
          key: "card_" + card.id,
          checked: false,
          isLeaf: true
        };
        if (addCardToList)
        {
          listNode.children.push(cardNode);
        }

      });
      if (addToList || listNode.children.length > 0)
      {
        if (listNode.children.length > 0 && this.filterValue)
          listNode.expanded = true;
        this.archivesNodes.push(listNode);
      }


    });

    this.archives.archivedLists.forEach(list =>
    {
      let addToList = this.tcs.contains(list.title, filterValueUpper)
      let listNode = {
        title: list.title,
        key: "listarchived_" + list.id,
        bgcolor: list.color,
        expanded: false,
        checked: false,
        isLeaf: true
      };
      if (addToList)
        this.archivesNodes.push(listNode);

    });
    this.archivesNodes = [...this.archivesNodes];
  }

  selectElementInTree(event: any)
  {
    if (!event.node.isLeaf)
      event.node.isExpanded = !event.node.isExpanded
    if (event.node.key.startsWith("listarchived"))
    {
      this.previewedCard = null;
      this.previewedList = null;
      this.loadingPreview = true;
      this.selectedId = parseInt(event.node.key.split('_')[1]);
      this.kbs.getListInfos(this.selectedId).subscribe(x =>
      {
        this.previewedList = x;
        this.previewedList.visible = true;
        this.selectedType = "list";
        this.loadingPreview = false;
      })

    }
    else if (event.node.key.startsWith("card"))
    {
      this.previewedCard = null;
      this.previewedList = null;
      this.selectedId = parseInt(event.node.key.split('_')[1]);
      this.loadingPreview = true;
      let idlist = this.archives.listWithArchivedCards.find(x => x.cards.findIndex(y => y.id == this.selectedId) >= 0).id;
      for (let list of this.board.lists)
      {
        if (list.id == idlist)
        {
          this.previewedCardList = list;
          break;
        }
      }
      this.kbs.getCardInfos(this.selectedId).subscribe(card =>
      {
        this.kbs.editedCard = card;
        this.previewedCard = card;
        this.previewedCard.visible = true;
        this.selectedType = "card";
        this.loadingPreview = false;
      })

    }
  }

  getListColor(key: string)
  {
    let s_id = key.split('_')[1];
    let id = parseInt(s_id);
    if (key.startsWith("listarchived"))
    {
      let list = this.archives.archivedLists.find(x => x.id == id);
      if (list && list.color)
        return list.color;
    }
    else 
    {
      let list = this.board.lists.find(x => x.id == id);
      if (list && list.color)
        return list.color;
    }
    return "white";
  }

  restoreButtonIsDisabled()
  {
    if (this.nzTreeComponent)
      return this.nzTreeComponent.getCheckedNodeList().length == 0;
    return true;
  }

  restoreSelectedElements()
  {
    let restorParams = new RestorationParams();
    let checkedNode = this.nzTreeComponent.getCheckedNodeList();
    checkedNode.forEach(node =>
    {
      let s_id = node.key.split("_")[1];
      let id = parseInt(s_id);
      if (node.key.startsWith("listarchived") && node.isChecked)
        restorParams.listIds.push(id);
      else if (node.key.startsWith("card") && node.isChecked)
        restorParams.cardIds.push(id);
    });
    this.kbs.restoreBoardContent(this.board.id, restorParams).subscribe(() =>
    {
      this.initArchives();
      this.kbs.updateBoardChange.next(null);
    })
  }
}
