// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-monthview-container {
  position: relative;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/planning/day-events/day-events.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACF","sourcesContent":[".event-monthview-container {\r\n  position: relative;\r\n  padding: 5px;\r\n  margin: 5px;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
