import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { KanbanCard } from '../../entities/Card';
import { KanbanAttachment } from '../../entities/Attachment';
import { GlobalLabel } from '../../entities/Label';
import { DropEffect } from 'ngx-drag-drop';
import { KanbanList } from '../../entities/List';
import { KanbanService } from '../../services/kanban.service';
import { TranslateService } from '@ngx-translate/core';
import { KanbanBoard } from '../../entities/Board';
import { User } from '../../../user/model/user';
import { ActivatedRoute } from '@angular/router';
import { MoveCardSpecific } from '../../entities/move-card-specific';
import { KanbanMoveCopyCardComponent } from '../kanban-move-copy-card/kanban-move-copy-card.component';
import { KanbanToolsService } from '../../services/kanban-tools.service';
import { ColorService } from '../../../services/color.service';
import { CacheDataService } from '../../../services/cache-data.service';
import { SendMailUserComponent } from '../send-mail-user/send-mail-user.component';
import { ImageFromClipboardComponent } from '../image-from-clipboard/image-from-clipboard.component';
import { MessagerieService } from '../../../messagerie/messagerie.service';
import { KanbanLabelDrawerComponent } from '../kanban-label-drawer/kanban-label-drawer.component';
import { TextCompareService } from '../../../services/text-compare.service';
import { NameUserPipe } from '../../../pipes/name-user-pipe/name-user.pipe';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { KanbanCardEditModalComponent } from '../kanban-card-edit-modal/kanban-card-edit-modal.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KanbanActionParameters } from '../../entities/kanban-action-parameters';
import { KanbanActionsService } from '../../services/kanban-actions.service';
import { firstValueFrom } from 'rxjs';
import { HomeComponent } from '../../../../../src/app/components/home/home.component';

@Component({
  selector: 'app-kanban-card',
  templateUrl: './kanban-card.component.html',
  styleUrls: ['./kanban-card.component.scss']
})
export class KanbanCardComponent implements OnInit
{

  constructor(public kbs: KanbanService,
    private translateService: TranslateService,
    private modalService: NzModalService, private route: ActivatedRoute,
    public mss: MessagerieService,
    private nzDropdownService: NzContextMenuService,
    private kts: KanbanToolsService,
    private kas: KanbanActionsService,
    public cs: ColorService,
    private messageService: NzMessageService,
    private drawerService: NzDrawerService,
    private nameuserpipe: NameUserPipe,
    public tcs: TextCompareService,
    public cds: CacheDataService,
    public hcomp: HomeComponent) { }

  @Input() card: KanbanCard;
  @Input() list: KanbanList;
  @Input() board: KanbanBoard = new KanbanBoard();
  @Input() isCreation: boolean;
  @Input() showArchived: boolean;
  @Output() cardValueChange = new EventEmitter<KanbanCard>();

  labelsCollapsed = false;
  publicLabelsCollapsed = true;
  potentialMembers: User[] = [];
  modalPaste: any;
  showLabels = false;
  showMembers = false;
  idcardPaste: number = 0;
  filterDrawer = "";

  @ViewChild('memberSelector', { static: false }) templatemember?: any;

  ngOnInit()
  {
    // Deep URL redirection
    this.kbs.openEditCardSub.subscribe((id: number) =>
    {
      if (id == this.card.id)
        this.editCard();
    });
  }

  dragDisable()
  {
    if (!this.card.id)
      return true;
    if (this.kbs.isAddingCardToList)
      return true;
  }

  editLabels()
  {
    this.filterDrawer = "";
    let title = "";
    this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.LABELS").subscribe(x => title = x);
    this.drawerService.create({
      nzTitle: title,
      nzContent: KanbanLabelDrawerComponent,
      nzContentParams: {
        card: this.card,
        board: this.board
      },
      nzWidth: '512px'
    });
  }

  pasteCard()
  {
    let copyCard = new KanbanCard();
    copyCard.title = this.kbs.copiedCard.title;
    copyCard.description = this.kbs.copiedCard.description;
    copyCard.startDate = this.kbs.copiedCard.startDate;
    copyCard.dueDate = this.kbs.copiedCard.dueDate;
    copyCard.archived = this.kbs.copiedCard.archived;
    copyCard.checklists = [...this.kbs.copiedCard.checklists];
    copyCard.labels = [...this.kbs.copiedCard.labels];
    copyCard.members = [...this.kbs.copiedCard.members];
    copyCard.attachments = [...this.kbs.copiedCard.attachments];
    copyCard.comments = [...this.kbs.copiedCard.comments];
    this.kbs.addCardToList(this.list.id, copyCard).subscribe(x =>
    {
      copyCard = x;
      let movecard = new MoveCardSpecific();
      movecard.bypass = true;
      movecard.idBoard = this.board.id;
      movecard.idcard = copyCard.id;
      movecard.idcardto = this.card.id;
      movecard.idlistfrom = this.list.id;
      movecard.idlistto = this.list.id;
      for (let i = 0; i < this.list.cards.length; ++i)
      {
        if (this.list.cards[i].id == this.card.id)
        {
          this.list.cards.splice(i + 1, 0, copyCard);
          break;
        }
      }
      this.kbs.moveCardToAfterCard(movecard).subscribe();
    });
  }

  editMembers()
  {
    this.filterDrawer = "";
    let title = "";
    this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.MEMBERS").subscribe(x => title = x);
    this.drawerService.create({
      nzTitle: title,
      nzContent: this.templatemember,
      nzWidth: '700px'
    });
  }

  getFilteredPublicLabels()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    return this.cds.publicLabelList.filter(x => this.tcs.contains(x.title, upperFilter))
  }

  getFilteredBoardLabels()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    return this.cds.labelList.filter(x => this.tcs.contains(x.title, upperFilter))
  }

  getPotentialMembers()
  {
    return this.kbs.getUsersForBoard(this.board, this.filterDrawer)
  }

  labeIsSelected(label: GlobalLabel)
  {
    return this.card.labels.find(x => x.title == label.title) != null;
  }

  changeLabelSelection(label)
  {
    event.preventDefault();
    event.stopImmediatePropagation();
    let labelInListIndex = this.card.labels.findIndex(x => x.id == label.id);
    if (labelInListIndex == -1)
    {
      this.kbs.addLabelToCard(this.card.id, label.id).subscribe(x =>
      {
        if (this.card.labels.findIndex(w => w.id == label.id) < 0)
        {
          this.card.labels.push(label);
          this.card.labels = [...this.card.labels];
        }
      });
    }
    else
    {
      this.kbs.removeLabelFromCard(this.card.id, label.id).subscribe(x =>
      {
        this.card.labels.splice(labelInListIndex, 1);
        this.card.labels = [...this.card.labels];
      });
    }
  }

  teamIsSelected(idteam: number)
  {
    return this.card.teams.find(x => x == idteam) != null;
  }

  getTeamsToAdd()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    if (this.board)
      return this.board.teams.filter(x => this.tcs.contains(this.nameuserpipe.transform(x), upperFilter));
    else
      return this.cds.unarchivedTeams.filter(x => this.tcs.contains((x.name), upperFilter)).map(x => x.id);
  }

  changeTeamSelection(idteam: number)
  {
    let index = this.card.teams.findIndex(x => x == idteam);
    if (index < 0)
    {
      this.kbs.addTeamToCard(this.card.id, idteam).subscribe(() =>
      {
        this.card.teams.push(idteam)
      })
    }
    else 
    {
      this.kbs.removeTeamFromCard(this.card.id, idteam).subscribe(() =>
      {
        this.card.teams.splice(index, 1);
      })
    }
  }

  getContainerHeight(padding: number)
  {
    return (window.innerHeight - padding) * 0.8 + "px";
  }


  userIsSelected(user: number)
  {
    return this.card.members.find(x => x == user) != null;
  }

  changeUserSelection(user: number)
  {
    event.stopImmediatePropagation();
    let labelInListIndex = this.card.members.findIndex(x => x == user);
    if (labelInListIndex == -1)
    {
      this.kbs.addMemberToCard(this.card.id, user).subscribe(x =>
      {
        if (this.card.members.findIndex(x => x == user) < 0)
          this.card.members.push(user);
      });
    }
    else
    {
      this.kbs.removeMemberFromCard(this.card.id, user).subscribe(x =>
      {
        this.card.members.splice(labelInListIndex, 1);
      });
    }
  }

  changeCollapseLocal()
  {
    event.stopImmediatePropagation();
    this.labelsCollapsed ? this.labelsCollapsed = false : this.labelsCollapsed = true
  }
  changeCollapsePusblic()
  {
    event.stopImmediatePropagation();
    this.publicLabelsCollapsed ? this.publicLabelsCollapsed = false : this.publicLabelsCollapsed = true
  }

  preventPropag()
  {

  }

  moveCardToStartList()
  {
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list.id;
    movecard.idlistto = this.list.id;
    this.kbs.moveCardToListStart(movecard).subscribe(x =>
    {
      this.kbs.GetAllListForBoard(this.board.id, this.kbs.filterBoardLists).subscribe(x =>
      {
        this.board.lists = x;
        this.kbs.kanbanBoardChange.next(null);
        this.nzDropdownService.close()
      });
    });
  }

  moveCardToEndList()
  {
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list.id;
    movecard.idlistto = this.list.id;
    this.kbs.moveCardToListEnd(movecard).subscribe(x =>
    {
      this.kbs.GetAllListForBoard(this.board.id, this.kbs.filterBoardLists).subscribe(x =>
      {
        this.board.lists = x;
        this.kbs.kanbanBoardChange.next(null);
        this.nzDropdownService.close()
      });
    });
  }

  cancel()
  {

  }

  archiveCard()
  {
    this.kbs.archiveCard(this.card.id).subscribe(() =>
    {
      let board = this.kbs.allBoardList.find(x => x.id == this.board.id)
      if (board)
      {
        let list = board.lists.find(x => x.id == this.list.id)
        if (list)
        {
          let card = list.cards.findIndex(x => x.id == this.card.id)
          if (card)
            list.cards.splice(card, 1);
        }
      }
      this.nzDropdownService.close()

    });
  }

  showDueDate()
  {
    return this.card.dueDate && !(this.card.completed && this.card.endDate);
  }

  showEndDateGood()
  {
    return this.card.completed && this.card.endDate && (!this.card.dueDate || new Date(this.card.endDate) <= new Date(this.card.dueDate));
  }

  showEndDateBad()
  {
    return this.card.completed && this.card.endDate && this.card.dueDate && new Date(this.card.endDate) > new Date(this.card.dueDate);
  }

  restoreCard()
  {
    this.kbs.restoreCard(this.card.id).subscribe(() =>
    {
      this.card.archived = true;
      let board = this.kbs.allBoardList.find(x => x.id == this.board.id)
      if (board)
      {
        let list = board.lists.find(x => x.id == this.list.id)
        if (list)
        {
          let card = list.cards.find(x => x.id == this.card.id)
          if (card)
          {
            card.archived = false;
            this.kbs.kanbanBoardChange.next(null);
          }
        }
      }
      this.nzDropdownService.close()

    });
  }

  contextMenu($event: MouseEvent, template: NzDropdownMenuComponent): void
  {
    this.kbs.isAddingCardToList = 0;
    this.nzDropdownService.create($event, template);
  }

  close(e: any): void
  {
    this.nzDropdownService.close()
  }

  onDragStart()
  {
    this.kbs.draggedCardOriginList = this.list;

    this.kbs.positionCardDrag = this.list.cards.findIndex(x => x.id == this.card.id);
    this.kbs.dragOngoing = true;
    let nonArchivedAllowedList = [];
    for (let idlist of this.list.allowedDeplacementListIds)
    {
      let list = this.board.lists.find(x => x.id == idlist.targetListId && !x.archived);
      if (list)
        nonArchivedAllowedList.push(idlist)
    }
    if (nonArchivedAllowedList.length > 0)
    {
      for (let list of this.board.lists)
      {
        if (this.list.id != list.id && nonArchivedAllowedList.findIndex(x => x.targetListId == list.id) < 0)
          list.undroppable = true;
      }
    }
    else if (!this.kbs.allowMoveForNonRestrictedList)
    {
      for (let list of this.board.lists)
      {
        if (this.list.id != list.id)
          list.undroppable = true;
      }
    }
  }

  getTooltip()
  {
    let tooltip = "";
    if (this.card.completed)
      this.translateService.get("KANBAN.MARK-CARD-NOT-COMPLETED").subscribe(x => tooltip = x);
    else this.translateService.get("KANBAN.MARK-CARD-COMPLETED").subscribe(x => tooltip = x);
    return tooltip;
  }

  changeTaskCompletion(task: KanbanCard)
  {
    event.stopImmediatePropagation();
    if (task.completed)
      this.kbs.setCardAsOngoing(task.id).subscribe(() =>
      {
        task.completed = false;
        task.endDate = null;
      })
    else
      this.kas.applyActions(this.card, this.list.id, this.board, "6", this.addCardCompletedPostProcessing, this.cancelCompletion, this);
  }

  addCardCompletedPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanCardComponent)
  {
    comp.kbs.setCardAsCompleted(comp.card.id).subscribe(async () =>
    {
      comp.card.completed = true;
      //comp.card.endDate = new Date();

      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
    })
  }

  cancelCompletion(card: KanbanCard, comp: KanbanCardComponent) { }

  copyMoveCard(typeMove: string)
  {
    let title = "";
    if (typeMove == "move")
      this.translateService.get("KANBAN.MOVE-CARD-TO").subscribe(x => title = x);
    else this.translateService.get("KANBAN.COPY-CARD-TO").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalMove = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanMoveCopyCardComponent,
      nzBodyStyle: { height: '90vh' },
      nzWidth: '90%',
      nzFooter: null
    });
    this.kbs.modalMove.componentInstance.card = this.card;
    this.kbs.modalMove.componentInstance.list = this.list;
    this.kbs.modalMove.componentInstance.board = this.board;
    this.kbs.modalMove.componentInstance.typeaction = typeMove;
  }

  updateAttachments({ file, fileList }: { [key: string]: any }, card: KanbanCard)
  {
    const status = file.status;
    if (status === 'done')
    {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
      {
        let att = new KanbanAttachment();
        att.name = file.name;
        att.url = reader.result.toString();
        this.kbs.addAttachment(card.id, att).subscribe(x =>
        {
          att.id = x;
          card.attachments.push(att);
        });
      });
      reader.readAsDataURL(file.originFileObj);
    } else if (status === 'error')
    {
      console.log(`${file.name} file upload failed.`);
    }
  }

  onDragged(item: any, list: any[], effect: DropEffect)
  {
    if (effect === "move")
    {
      const index = list.indexOf(item);
      if (index >= 0)
        list.splice(index, 1);
    }
  }

  onDragEnd(event: DragEvent)
  {
    //if(this.board.lists.findIndex(x => x.cards.findIndex(y => y.id == this.card.id) >=0) < 0)
    //this.kbs.draggedCardOriginList.cards.splice(this.kbs.positionCardDrag, 0, this.card);

    for (let list of this.board.lists)
    {
      list.undroppable = false;
    }
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.avatar;
  }

  getUserName(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.name + ' ' + user.surname;
  }

  createCard(event?)
  {
    if (event)
      event.preventDefault();
    if (!this.card.title.replace(/\n|\r|(\n\r)/g, '').trim())
    {
      this.card.title = this.card.title.replace(/\n|\r|(\n\r)/g, '');
      return false;
    }

    this.card.title = this.card.title.trim();
    let card = this.kbs.deepCopy(this.card) as KanbanCard;
    card.position = this.list.cards.length + 1;
    this.kas.applyActions(card, this.list.id, this.board, "0", this.addCardPostProcessing, this.cancelAction, this);
  }

  cancelAction(card: KanbanCard, comp: KanbanCardComponent) { }

  addCardPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanCardComponent)
  {
    comp.kbs.addCardToList(comp.list.id, card).subscribe(async x =>
    {
      card.id = x.id;
      actionparam.cardId = card.id;
      comp.list.cards.push(card);
      card.visible = true;
      comp.kbs.createdCard = new KanbanCard();
      comp.kbs.isAddingCardToList = 0;
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
      var objDiv = document.getElementById("kanbanlist_" + comp.list.id);
      objDiv.scrollTop = objDiv.scrollHeight;
      if (comp.list.autofilter)
        comp.kbs.sortCartManually(comp.list).subscribe();
    });
  }

  getMemberInitial(user: User)
  {
    return user.name.slice(0, 1).toUpperCase() + user.surname.slice(0, 1).toUpperCase()
  }

  openEditCard()
  {
    window.history.replaceState({}, '', `/kanban/${this.board.id}/${this.card.id}`);
    event.stopPropagation();
    this.editCard()

  }

  getSortedLabels(sort: boolean)
  {
    if (sort)
      return [...this.card.labels].sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
    else return [...this.card.labels];
  }

  shareUrl()
  {
    let copiedTXT
    this.translateService.get("KANBAN.EDIT-CARD.COPIED", {}).subscribe(x => copiedTXT = x);

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    if (window.location.href.endsWith("/"))
      selBox.value = window.location.href + this.card.id;
    else selBox.value = window.location.href + "/" + this.card.id;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.messageService.create('info', copiedTXT);
    // TODO TRANSLATE
  }

  editCard()
  {


    if (this.card.id == 0 || (this.kbs.editedCard && this.kbs.editedCard.id == this.card.id))
      return;

    this.kbs.editedCard = this.card;
    let title = "";
    this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create<KanbanCardEditModalComponent>({
      nzTitle: title + " " + this.list.title,
      nzContent: KanbanCardEditModalComponent,
      nzWidth: '100%',
      nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
      nzMaskClosable: false,
      nzAutofocus: null,
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalEdit.close(); }
      }
      ]
    });

    this.kbs.modalEdit.afterClose.subscribe((result) =>
    {
      this.verifFunction(this.card);
      this.kbs.editedCard = null;
      this.kts.resetEditModalField();
      window.history.replaceState({}, '', `/kanban/${this.board.id}`);
    });

    //let instance = this.kbs.modalEdit.getContentComponent();
    this.kbs.modalEdit.componentInstance.card = this.card;
    this.kbs.modalEdit.componentInstance.list = this.list;
    this.kbs.modalEdit.componentInstance.board = this.board;

  }

  verifFunction(card)
  {
    if (this.hcomp.actualWidget)
    {
      this.hcomp.sendCardToRefresh(card).subscribe(x =>
      {
        console.log('x : ', x);
        if (x != "nothing change")
        {
          this.hcomp.actualWidget.allCards = [];
          this.hcomp.actualWidget.allCards = x;
          this.hcomp.makeLineForCard();
          setTimeout(() =>
          {
            this.hcomp.calculateTallestElementHeight(); // fonction pour recalculer la hauteur des cartes selon la hauteur de la plus haute carte de la ligne
          }, 50);
        }
        else
          console.log('nothing change !');
      });
    }

    else
    {
      this.hcomp.sendCardToRefresh(card).subscribe(x =>
      {
        console.log('x : ', x);
        if (x != "nothing change")
        {
          let idex = 0;
          while (idex < this.hcomp.WidgetFilterCard.length)
          {
            if (this.hcomp.WidgetFilterCard[idex].type == 0)
              this.hcomp.WidgetFilterCard[idex].isReloading = true;
            idex += 1;
          }
          this.hcomp.getAllWidgetCards().subscribe(x =>
          {
            let idx = 0;
            let idx2 = 0;
            while (idx < this.hcomp.WidgetFilterCard.length)
            {
              while (idx2 < x.length)
              {
                if (this.hcomp.WidgetFilterCard[idx].type == 0)
                {
                  if (this.hcomp.WidgetFilterCard[idx].id == x[idx2].id)
                  {
                    let stringFiltre = x[idx2].filtre;
                    let BooleanList: boolean[] = this.hcomp.makeBoolListFromStr(stringFiltre);

                    let stringFilterOrder = x[idx2].filterOrder;
                    let BooleanListOrder: boolean[] = this.hcomp.makeBoolListFromStr(stringFilterOrder);

                    let i = 0;
                    let label: any[] = [];
                    if (this.hcomp.listLabelsIds.length > 0)
                    {

                      while (i < this.hcomp.allLabelsForUser.length)
                      {
                        let y = 0;
                        while (y < this.hcomp.listLabelsIds.length)
                        {
                          if (this.hcomp.allLabelsForUser[i].id == this.hcomp.listLabelsIds[y])
                          {
                            label.push(this.hcomp.allLabelsForUser[i]);
                          }
                          y += 1;
                        }
                        i += 1;
                      }
                    }
                    let tmp = new WidgetCarteFiltre(x[idx2].id, x[idx2].title, x[idx2].type, x[idx2].userId, BooleanList, x[idx2].dateTosearch, x[idx2].commentary, 3, false, x[idx2].allCards, label, x[idx2].tiersid, x[idx2].memberid, x[idx2].complicate_filter, BooleanListOrder, x[idx2].sortMethode, x[idx2].current_date, x[idx2].titleToSearch, x[idx2].descToSearch, undefined, undefined, undefined, undefined, undefined, x[idx2].mostVisitedBoardIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, x[idx2].dateListTab, true, x[idx2].boardList);
                    this.hcomp.WidgetFilterCard.splice(idx, 1);
                    this.hcomp.WidgetFilterCard.splice(idx, 0, tmp);
                    this.hcomp.WidgetFilterCard[idx].isReloading = false;
                    idx += 1;
                  }
                }
                else
                {
                  idx += 1;
                  idx2 -= 1;
                }
                idx2 += 1;
              }
              idx += 1;
            }
            this.hcomp.WidgetFilterCard.join();
            this.hcomp.WidgetFilterCard = [...this.hcomp.WidgetFilterCard];
          });
        }
        else
          console.log('nothing change !');
      });
    }
  }

  getCheckListString()
  {
    let total = 0;
    let checked = 0;
    this.card.checklists.forEach(x =>
    {
      if (x)
      {
        total += x.items.length;
        checked += x.items.filter(y => y.done).length;
      }

    });
    return checked + "/" + total;
  }

  getCheckListTotalItems()
  {
    let total = 0;
    this.card.checklists.forEach(x =>
    {
      if (x)
        total += x.items.length;
    });
    return total;
  }

  toogleLabelsFull(label: GlobalLabel)
  {
    this.kbs.showLabelText = !this.kbs.showLabelText
    event.stopPropagation();
  }

  addLabelToFiltered(label: GlobalLabel)
  {
    let index = this.kbs.filterBoardLists.labelFiltered.findIndex(x => x == label.id);
    if (index == -1)
    {
      index = this.kbs.filterBoardLists.labelExiled.findIndex(x => x == label.id);
      if (index == -1)
        this.kbs.filterBoardLists.labelFiltered.push(label.id);
      else this.kbs.filterBoardLists.labelExiled.splice(index, 1);
    }
    else
    {
      this.kbs.filterBoardLists.labelFiltered.splice(index, 1);
      this.kbs.filterBoardLists.labelExiled.push(label.id);
    }
    this.kbs.filterBoard(this.board);
    event.stopPropagation();
  }

  checkDueDate()
  {
    if (this.card.dueDate)
    {
      let due = new Date(this.card.dueDate);
      return due.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    }
  }

  checkDueDateYear()
  {
    let now = new Date();
    let due = new Date(this.card.dueDate);
    return now.getFullYear() == due.getFullYear()
  }

  checkEndDateYear()
  {
    let now = new Date();
    let due = new Date(this.card.endDate);
    return now.getFullYear() == due.getFullYear()
  }

  getCheckListIsCompleted()
  {
    let total = 0;
    let checked = 0;
    this.card.checklists.forEach(x =>
    {
      total += x.items.length;
      checked += x.items.filter(y => y.done).length;
    });
    return checked == total;
  }

  addAttachmentImageFromClipboard()
  {
    this.idcardPaste = this.card.id;
    let title = "";
    this.translateService.get("KANBAN.ADD-IMAGE-FROM-CLIPBOARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalPaste = this.modalService.create({
      nzTitle: title,
      nzContent: ImageFromClipboardComponent,
      nzWidth: '500px',
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalPaste.close(); }
      }
      ]
    });
    this.kbs.modalPaste.componentInstance.card = this.card;
    this.kbs.modalPaste.afterClose.subscribe(() =>
    {
      this.idcardPaste = 0;
    })
  }

  hasThirdPartyTocall()
  {
    for (let pp of this.card.physicalPersons)
    {
      if (pp && pp.phoneNumbers.length > 0)
        return true;
    }

    for (let mp of this.card.moralPersons)
    {
      if (mp && mp.phoneNumbers.length > 0)
        return true;
    }
    return false;
  }

  initUserNotification()
  {
    let title = "";
    this.translateService.get("KANBAN.ADD-IMAGE-FROM-CLIPBOARD").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: SendMailUserComponent,
      nzWidth: '80%',
      nzOnOk: (result) =>
      {
        result.notifyUsers();
      },
      nzOkText: confirm,
      nzOnCancel: () => { },
      nzCancelText: close,
    });
    modal.componentInstance.card = this.card;
    modal.componentInstance.list = this.list;
    modal.componentInstance.board = this.board;
    return;
  }

  initMailSender()
  {
    this.kbs.initCardMail(this.board.id, this.card.id);
    this.mss.editMail();
  }


}

class WidgetCarteFiltre
{
  title: string;
  type: number;
  userId: number;
  filtre: boolean[];
  allCard: KanbanCard[] = [];
  id: number;
  dateTosearch: Date;
  commentary: string;
  nbShow: number;
  showMore: boolean;
  LabelIds: number[];
  currentlabel: any[];
  tiersid: number[];
  memberid: number[];
  complicate_filter: boolean;
  FilterOrder: boolean[];
  sortMethode: boolean;
  current_date: number;
  titleToSearch: string;
  descToSearch: string;
  city: string;
  humidity: any;
  wind: any;
  degres: any;
  image_src: any;
  mostVisitedBoardIds: any[];
  emailObject: string;
  emailSender: string;
  emailRecipient: string;
  emailContent: string;
  emailAccount: string[];
  listMail: any[];
  folderName: string[];
  dateListTab: String[];
  isReloading: boolean;
  boardList: KanbanBoard[];

  public constructor(_ID: number, _title: string, _type: number, _userID: number, _filtre: boolean[], _dateTosearch: Date, _commentary: string, _nbShow: number, _showMore: boolean, _AllCard: [], _currentlabel: any[], _tiersid: number[], _memberid: number[], _complicate_filter: boolean, _FilterOrder, _sortMethode: boolean, _current_date: number, _titleToSearch: string, _descToSearch, _city, _humidity, _wind, _degres, _image_src, _mostVisitedBoardIds: any[], _emailObject: string, _emailSender: string, _emailRecipient: string, _emailContent: string, _emailAccount: string[], _listMail: any[], _folderName: string[], _dateListTab: String[], _isReloading: boolean, _boardList)
  {
    this.id = _ID;
    this.title = _title;
    this.type = _type;
    this.userId = _userID;
    this.filtre = _filtre;
    this.dateTosearch = _dateTosearch;
    this.commentary = _commentary;
    this.nbShow = _nbShow;
    this.showMore = _showMore;
    this.allCard = _AllCard;
    this.currentlabel = _currentlabel;
    this.tiersid = _tiersid;
    this.memberid = _memberid;
    this.complicate_filter = _complicate_filter;
    this.FilterOrder = _FilterOrder;
    this.sortMethode = _sortMethode;
    this.current_date = _current_date;
    this.titleToSearch = _titleToSearch;
    this.descToSearch = _descToSearch;
    this.city = _city;
    this.humidity = _humidity;
    this.wind = _wind;
    this.image_src = _image_src;
    this.mostVisitedBoardIds = _mostVisitedBoardIds;
    this.emailObject = _emailObject;
    this.emailSender = _emailSender;
    this.emailRecipient = _emailRecipient;
    this.emailContent = _emailContent;
    this.emailAccount = _emailAccount;
    this.listMail = _listMail;
    this.folderName = _folderName;
    this.dateListTab = _dateListTab;
    this.isReloading = _isReloading;
    this.boardList = _boardList;
  }
}