export class SearchCredentials
{
    searchText = "";
    sortValue = "name";
    sortDirection = "ascend";
    ownerIds: number[] = [];
    creatorIds: number[] = [];
    types: string[] = [];
    thirdPartyIds: number[] = [];
    groupCredentialIds: number[] = [];
    archiveMode = false;
    state = 0;
    start = 0;
    count = 50;
}