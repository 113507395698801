import { Injectable, isDevMode } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { UrlApiService } from './url-api.service';
import { AuthService } from './auth.service';
import { DTOLog } from '../monitoring/entities/Log';
import { LogsDataService } from '../monitoring/services/data/logs-data.service';
import { SiteBlockerService } from './site-blocker.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialNotificationService } from './notification/credential-notification.service';
import { GroupCredentialNotificationService } from './notification/group-credential-notification.service';
import { GroupUserNotificationService } from './notification/group-user-notification.service';
import { UserNotificationService } from './notification/user-notification.service';
import { AdminNotificationService } from './notification/admin-notification.service';
import { KanbanNotificationService } from './notification/kanban-notification.service';
import { UserNotification } from '../entities/user-notification';
import { GlobalNotificationService } from './notification/global-notification.service';
import { ResourceNotificationService } from './notification/resource-notification.service';
import { MoralPersonNotificationService } from './notification/moral-person-notification.service';
import { PhysicalPersonNotificationService } from './notification/physical-person-notification.service';
import { GlossaryNotificationService } from './notification/glossary-notification.service';
import { MessagerieNotificationService } from './notification/messagerie-notification.service';
import { ThirdPartyNotificationService } from './notification/third-party-notification.service';
import { ChatNotificationService } from './notification/chat-notification.service';
import { ProspectionNotificationService } from './notification/prospection-notification.service';
import { VideoNotificationService } from './notification/video-notification.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService
{

  private connected: Boolean = false;

  /**
   * Status de la connexion SignalR
   */
  public get IsConnected()
  {
    return this.connected;
  }


  constructor(private authService: AuthService, private logService: LogsDataService, private blockService: SiteBlockerService, private http: HttpClient,
    private CredentialNotificationService: CredentialNotificationService,
    private GroupCredentialNotificationService: GroupCredentialNotificationService,
    private GroupUserNotificationService: GroupUserNotificationService,
    private UserNotificationService: UserNotificationService,
    private AdminNotificationService: AdminNotificationService,
    private KanbanNotificationService: KanbanNotificationService,
    private ResourceNotificationService: ResourceNotificationService,
    private MoralPersonNotificationService: MoralPersonNotificationService,
    private PhysicalPersonNotificationService: PhysicalPersonNotificationService,
    private GlossaryNotificationService: GlossaryNotificationService,
    private MessagerieNotificationService: MessagerieNotificationService,
    private ThirdPartyNotificationService: ThirdPartyNotificationService,
    private ChatNotificationService: ChatNotificationService,
    private VideoNotificationService: VideoNotificationService,
    private prospectionNotificationService: ProspectionNotificationService,
    private GlobalNotificationService: GlobalNotificationService)
  {
  }

  private _hubConnection: HubConnection | undefined;

  private async connect()
  {
    if (this.connected)
    {
      this._hubConnection.invoke('KeepAlive');
      return;
    }


    console.log("Connecting to signalr server...")
    // init hub connection
    if (!this.authService.Token)
      return;
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(UrlApiService.settings.apiConfig.uriAPI + "/ws/Client?token=" + this.authService.Token.access_token,
        {
          transport: signalR.HttpTransportType.WebSockets,
          skipNegotiation: true,
        })
      .configureLogging(isDevMode() ? signalR.LogLevel.Error : signalR.LogLevel.None)
      .build();


    // close event : auto reconnect
    this._hubConnection.onclose(() =>
    {
      this.connected = false;

    });

    // défininitions des remote calls du server
    this._hubConnection.on('Disconnect', () => this.disconnect());
    this._hubConnection.on('DisconnectClient', (msg: string) =>
    {
      console.log("disconnect message received");
      this.authService.logout(false);
    });

    this.CredentialNotificationService.initRoutes(this._hubConnection);
    this.GroupCredentialNotificationService.initRoutes(this._hubConnection);
    this.GroupUserNotificationService.initRoutes(this._hubConnection);
    this.UserNotificationService.initRoutes(this._hubConnection);
    this.AdminNotificationService.initRoutes(this._hubConnection);
    this.KanbanNotificationService.initRoutes(this._hubConnection);
    this.MessagerieNotificationService.initRoutes(this._hubConnection);
    this.ResourceNotificationService.initRoutes(this._hubConnection);
    this.VideoNotificationService.initRoutes(this._hubConnection);
    this.MoralPersonNotificationService.initRoutes(this._hubConnection);
    this.PhysicalPersonNotificationService.initRoutes(this._hubConnection);
    this.GlossaryNotificationService.initRoutes(this._hubConnection);
    this.ThirdPartyNotificationService.initRoutes(this._hubConnection);
    this.ChatNotificationService.initRoutes(this._hubConnection);
    this.prospectionNotificationService.initRoutes(this._hubConnection);
    this.GlobalNotificationService.initRoutes(this._hubConnection);

    this._hubConnection.on('AddNotification', (notif: UserNotification) =>
    {
      if (notif)
        this.GlobalNotificationService.userNotifications.notifications.splice(0, 0, notif);
    });

    this._hubConnection.on('TestMessage', (msg: string) =>
    {
    });
    this._hubConnection.on('UpdateLog', (msg: DTOLog) =>
    {
      this.logService.logs.push(msg);
    });

    // attempt de connexion
    try
    {
      await this._hubConnection.start();
      this.connected = true;
      console.log("Connected to signalr");
      this._hubConnection.send("Login", this.authService.Token.access_token);

    } catch (err)
    {
      //this.blockService.serverIsJoinable = false;
    }
  }

  private async disconnect()
  {
    if (this.connected)
      await this._hubConnection.stop();
  }

  /**
   * Initialise le lien SignalR s'il n'est pas connecté
   */
  public async Initialize()
  {
    setInterval(() =>
    {
      try
      {
        this.connect();
      }
      catch (e) { }

      try
      {
        this.blockService.pingServer();
      }
      catch (e) { }
    }, 10000)
    this.blockService.pingServer();
    await this.connect();
  }

  /**
   * Stoppe le lien SignalR s'il est connecté
   */
  public async Stop()
  {
    this.connected = true;
    await this.disconnect();
  }

}
