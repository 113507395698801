import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenEditMoralPersonComponent } from './open-edit-moral-person.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    TranslateModule
  ],
  declarations: [OpenEditMoralPersonComponent],
  exports: [OpenEditMoralPersonComponent]
})
export class OpenEditMoralPersonModule { }
