import { Pipe, PipeTransform } from '@angular/core';
import { CacheDataService } from 'src/app/services/cache-data.service';

@Pipe({
  name: 'third_party_name',
  pure: false
})
export class ThirdPartyNamePipe implements PipeTransform
{

  constructor(public cds: CacheDataService) { }

  transform(idtp: number): string
  {
    let name = this.cds.getPPName(idtp);
    if (!name)
      name = this.cds.getMPName(idtp);
    return name;
  }

}
