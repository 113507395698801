import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormManagerComponent } from './form-manager.component';
import { FormManagerRoutingModule } from './form-manager.routing.module';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzGridModule,
    NzFormModule,
    NzInputModule,
    FormManagerRoutingModule
  ],
  exports: [
    FormManagerComponent
  ],
  declarations: [FormManagerComponent],
  bootstrap: [

  ]
})
export class FormManagerModule { }
