import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardChecklistComponent } from './kanban-card-checklist.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PipesModule } from '../../../../../src/app/pipes/pipes/pipes.module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@NgModule({
  imports: [
    CommonModule,
    NzPopconfirmModule,
    FormsModule,
    TranslateModule,
    NzInputModule,
    NzIconModule,
    NzButtonModule,
    NzGridModule,
    NzCheckboxModule,
    NzPopoverModule,
    NzDatePickerModule,
    DragDropModule,
    PipesModule
  ],
  declarations: [KanbanCardChecklistComponent],
  exports: [KanbanCardChecklistComponent],
})
export class KanbanCardChecklistModule { }
