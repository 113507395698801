import { Component, OnInit, Input } from '@angular/core';
import { NotifyUsersForCard } from '../../entities/Notify/notify-users-for-card';
import { KanbanCard } from '../../entities/Card';
import { KanbanList } from '../../entities/List';
import { KanbanBoard } from '../../entities/Board';
import { TranslateService } from '@ngx-translate/core';
import { KanbanService } from '../../services/kanban.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-send-mail-user',
  templateUrl: './send-mail-user.component.html',
  styleUrls: ['./send-mail-user.component.scss']
})
export class SendMailUserComponent implements OnInit
{

  constructor(private translateService: TranslateService, private messageService: NzMessageService, public kbs: KanbanService) { }

  @Input() card: KanbanCard;
  @Input() list: KanbanList;
  @Input() board: KanbanBoard = new KanbanBoard();

  notifiationParams: NotifyUsersForCard = new NotifyUsersForCard();
  cardMemberId = null;
  boardMemberId = null;
  guestUserId = null;

  ngOnInit()
  {
  }

  userIsNotInNotifiedList(userid: number)
  {
    return this.notifiationParams.userIds.findIndex(x => x == userid) < 0;
  }

  addUserToNotifyList(userid: number)
  {
    if (!userid)
      return;
    this.cardMemberId = null;
    this.boardMemberId = null;
    this.guestUserId = null;
    this.notifiationParams.userIds.push(userid);
  }

  removeUserFromNotifyList(userid: number)
  {
    let index = this.notifiationParams.userIds.findIndex(x => x == userid);
    if (index >= 0)
      this.notifiationParams.userIds.splice(index, 1);
  }

  notifyUsers()
  {
    this.notifiationParams.cardId = this.card.id;
    this.notifiationParams.cardTitle = this.card.title;
    this.notifiationParams.boardId = this.board.id;
    this.notifiationParams.boardTitle = this.board.title;
    this.notifiationParams.listTitle = this.list.title;
    let text = "";
    this.translateService.get("KANBAN.SEND-MAIL-USER.SEND-SUCCESS").subscribe(x => text = x);
    this.kbs.notifyUsersForCard(this.notifiationParams).subscribe(x =>
    {
      this.messageService.create("success", text);
    })
  }

  userNotificationIsDisabled()
  {
    return this.notifiationParams.userIds.length == 0;
  }

}
