import { Injectable } from '@angular/core';
import { ReservationResource } from '../entities/resource';
import { tap } from 'rxjs/operators';
import { UrlApiService } from 'src/app/services/url-api.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { HEXColor } from 'src/app/SDICalendar/calendar/entities/color';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

constructor(private http: HttpClient) { }
resources: ReservationResource[] = [];
resourcesChange: Subject<ReservationResource[]> = new  Subject<ReservationResource[]>();

getAllResources()
{
  return this.http.get<ReservationResource[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/resources').pipe(tap(x => {
    this.resources = x;
    this.resources.forEach(y => {
      y.color.rgb =  new HEXColor(y.color.hex.hex).toRGB();
    })
  }));
}

createResource(resource: ReservationResource)
{
  return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + '/api/resources', resource);
}

updateResource(resource: ReservationResource)
{
  return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/resources', resource);
}

deleteResource(idresource: number)
{
  return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + '/api/resources/' + idresource);
}

}
