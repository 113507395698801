import { Component, ViewChild, ElementRef, AfterViewInit, Input, Output, SimpleChanges, OnChanges, EventEmitter, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { ColorService } from '../../services/color.service';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss']
})
export class ColorPaletteComponent implements AfterViewInit, OnChanges
{
  @Input()
  hue: string;

  @Input('startColor')
  startColor: string;

  @Output()
  color: EventEmitter<string> = new EventEmitter(true);

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  constructor(private cs: ColorService) { }

  private ctx: CanvasRenderingContext2D;

  private mousedown: boolean = false;
  subscription: Subscription;

  public selectedPosition: { x: number; y: number };

  ngAfterViewInit()
  {
    this.initColor(this.startColor);
    this.draw();
    this.emitColor(this.selectedPosition.x, this.selectedPosition.y)
  }

  initColor(startColor: string)
  {
    let tempStartColor = startColor;
    let tmpX, tmpY, Lum, Sat, rgbForHue, rD, gD, bD, delta

    if (!tempStartColor)
    {
      this.selectedPosition = { x: 215, y: 215 };
    }
    else
    {
      if (tempStartColor.charAt(0) == '#')
        tempStartColor = tempStartColor.substr(1)
      rgbForHue = tempStartColor.match(/.{2}/g);
      rD = this.hexToRgb(rgbForHue[0]) / 255
      gD = this.hexToRgb(rgbForHue[1]) / 255
      bD = this.hexToRgb(rgbForHue[2]) / 255
      Lum = (Math.max(rD, gD, bD) + Math.min(rD, gD, bD)) / 2

      delta = Math.max(rD, gD, bD) - Math.min(rD, gD, bD)
      Sat = delta == 0 ? 0 : delta / (1 - Math.abs(2 * Lum - 1))

      // TODO : Algorithm works only if canvas is squared !
      tmpX = Sat * (this.canvas.nativeElement.width - 1)
      tmpY = (this.canvas.nativeElement.height - 1) - ((Lum / (1 - Sat / 2)) * (this.canvas.nativeElement.height - 1))

      this.selectedPosition = { x: tmpX, y: tmpY }
    }
  }

  draw()
  {
    if (!this.ctx)
    {
      this.ctx = this.canvas.nativeElement.getContext('2d');
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx.fillStyle = this.hue || '#FFFFFF';
    this.ctx.fillRect(0, 0, width, height);

    const whiteGrad = this.ctx.createLinearGradient(0, 0, width, 0);
    whiteGrad.addColorStop(0, 'rgba(255,255,255,1)');
    whiteGrad.addColorStop(1, 'rgba(255,255,255,0)');

    this.ctx.fillStyle = whiteGrad;
    this.ctx.fillRect(0, 0, width, height);

    const blackGrad = this.ctx.createLinearGradient(0, 0, 0, height);
    blackGrad.addColorStop(0, 'rgba(0,0,0,0)');
    blackGrad.addColorStop(1, 'rgba(0,0,0,1)');

    this.ctx.fillStyle = blackGrad;
    this.ctx.fillRect(0, 0, width, height);

    if (this.selectedPosition)
    {
      this.ctx.strokeStyle = 'white';
      this.ctx.fillStyle = 'white';
      this.ctx.beginPath();
      this.ctx.arc(this.selectedPosition.x, this.selectedPosition.y, 10, 0, 2 * Math.PI);
      this.ctx.lineWidth = 5;
      this.ctx.stroke();
    }
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes['hue'])
    {
      this.draw();
      const pos = this.selectedPosition;
      if (pos)
      {
        this.color.emit(this.getColorAtPosition(pos.x, pos.y));
      }
    }
    else if (changes['startColor']) 
    {
      if (changes['startColor'].currentValue)
      {
        this.initColor(changes['startColor'].currentValue);
        this.draw();
        this.emitColor(this.selectedPosition.x, this.selectedPosition.y)
      }
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(evt: MouseEvent)
  {
    this.mousedown = false;
  }

  onMouseDown(evt: MouseEvent)
  {
    this.mousedown = true;
    this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
    this.draw();
    this.emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseMove(evt: MouseEvent)
  {
    if (this.mousedown)
    {
      this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
      this.draw();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number)
  {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.color.emit(rgbaColor);
  }

  rgbToHex(rgb)
  {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2)
    {
      hex = "0" + hex;
    }
    return hex;
  };

  hexToRgb(hex)
  {
    var rgb = parseInt(hex, 16);
    return rgb;
  };

  hslToRgb(h, s, l)
  {
    var r, g, b;

    if (s == 0)
    {
      r = g = b = l; // achromatic
    }
    else
    {
      const hue2rgb = (p, q, t) =>
      {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [
      Math.max(0, Math.min(Math.round(r * 255), 255))
      , Math.max(0, Math.min(Math.round(g * 255), 255))
      , Math.max(0, Math.min(Math.round(b * 255), 255))
    ];
  }

  getColorAtPosition(x: number, y: number)
  {

    let outLum, outSat

    outLum = ((this.canvas.nativeElement.height - 1) - y) / (this.canvas.nativeElement.height - 1)
    outSat = x / (this.canvas.nativeElement.width - 1)

    let rgbForHue, startHue, tmpHue, rD, gD, bD
    if (this.hue)
    {
      tmpHue = this.hue
      if (this.hue.charAt(0) == '#')
        tmpHue = this.hue.substr(1)
      rgbForHue = tmpHue.match(/.{2}/g);
      rD = this.hexToRgb(rgbForHue[0]) / 255
      gD = this.hexToRgb(rgbForHue[1]) / 255
      bD = this.hexToRgb(rgbForHue[2]) / 255
      if (Math.max(rD, gD, bD) == rD)
        startHue = (gD - bD) / (rD - Math.min(gD, bD))
      else if (Math.max(rD, gD, bD) == gD)
        startHue = 2.0 + (bD - rD) / (gD - Math.min(rD, bD))
      else
        startHue = 4.0 + (rD - gD) / (bD - Math.min(gD, rD))

      startHue *= 60
      if (startHue < 0)
        startHue += 360

      let hvs = this.hslToRgb(startHue / 360, outSat, outLum * (1 - outSat / 2))
      return '#' + this.rgbToHex(hvs[0]) + this.rgbToHex(hvs[1]) + this.rgbToHex(hvs[2])
    }
  }
}
