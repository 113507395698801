import { Component, OnInit, Input, Injector, Inject } from '@angular/core';
import { ThirdPartyService } from '../../../third-party/services/third-party.service';
import { TranslateService } from '@ngx-translate/core';
import { GetRelation } from '../../../third-party/entities/get-relation';
import { FilterRelation } from '../../../third-party/entities/filter-relation';
import { SetRelation } from '../../../third-party/entities/set-relation';
import { PhysicalPersonService } from '../../../third-party/physical-person/services/physical-person.service';
import { MoralPersonService } from '../../../third-party/moral-person/services/moral-person.service';
import { ModalManagerService } from '../../../services/modal-manager-service/modal-manager.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CacheDataService } from '../../../services/cache-data.service';
import { Subscription } from 'rxjs';
import { SearchClientInfoComponent } from '../../moral-person/components/search-client-info/search-client-info.component';
import { MoralPerson } from '../../moral-person/entities/moral-person';
import { Site } from '../../entities/site';
import { FormManagerService } from '../../../form-manager/services/form-manager.service';
import { SiteService } from '../../site/services/site.service';
import { PhysicalPerson } from '../../physical-person/entities/physical-person';
import { TypeRelation } from '../../entities/type-relation';
import { Router } from '@angular/router';
import { THIRD_PARTY_SHEET } from '../../../call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { ComponentType } from '@angular/cdk/portal';
import { TextCompareService } from '../../../services/text-compare.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RelationMemo } from '../../entities/relation-memo';
import { HtmlEditorComponent } from '../../../components/html-editor/html-editor.component';
import { KanbanAttachment } from '../../../kanban/entities/Attachment';

@Component({
  selector: 'app-third-party-filtered-relation',
  templateUrl: './third-party-filtered-relation.component.html',
  styleUrls: ['./third-party-filtered-relation.component.scss']
})
export class ThirdPartyFilteredRelationComponent implements OnInit
{

  constructor(public thirdPartyService: ThirdPartyService,
    public pps: PhysicalPersonService,
    public mps: MoralPersonService,
    public mms: ModalManagerService,
    private sanitizer: DomSanitizer,
    private cds: CacheDataService,
    private modalService: NzModalService,
    private fms: FormManagerService,
    private router: Router,
    private siteService: SiteService,
    public tcs: TextCompareService,
    private message: NzMessageService,
    @Inject(THIRD_PARTY_SHEET) private component: ComponentType<any>,
    private translateService: TranslateService) { }

  @Input() idtp: number;
  @Input() filter: FilterRelation;

  datas: GetRelation[] = [];
  editedRelation: GetRelation = new GetRelation();
  showType = true;
  hue: string;
  showColor = false;
  titleHasFocus = false;
  title = "";
  oldColor = "";
  textSource = "";
  textType = "";
  textTarget = "";
  searchText = "";
  idtptoadd = null;
  filteredPPList = [];
  filteredMPList = [];
  idfiltertype = 0;
  filterdirection = "";
  subscriptionPP: Subscription;
  subscriptionMP: Subscription;
  typeRelation: TypeRelation = new TypeRelation();
  directionRelation = "";

  createdTPName = "";
  createdTPSurname = "";
  showCreatePP = false;
  showCreateMP = false;
  idtype;
  direction;

  ngOnInit()
  {
    this.initData();
    this.thirdPartyService.potentialRelationsInfosChange.subscribe(() =>
    {
      this.initData();
    })

    this.subscriptionPP = this.pps.physicalPersonListChange.subscribe(() =>
    {
      let filterParams = this.filter.filterCode.split(",");
      this.idtype = parseInt(filterParams[2]);
      this.direction = filterParams.length >= 4 ? filterParams[3] : "base";
      this.initFilter(this.idtype, this.direction, "");
    })

    this.subscriptionMP = this.mps.moralPersonListChange.subscribe(() =>
    {
      let filterParams = this.filter.filterCode.split(",");
      this.idtype = parseInt(filterParams[2]);
      this.direction = filterParams.length >= 4 ? filterParams[3] : "base";
      this.initFilter(this.idtype, this.direction, "");
    })
  }

  openEditTier(id: number)
  {
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: this.component,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: close,
        danger: true,
        onClick: (result) =>
        {
          if (result.infosHasChanged())
          {
            this.translateService.get("THIRD-PARTY.CALL.THIRD-PARTY-SHEET.ERROR-ONGOING-MODIFICATION").subscribe(x => this.message.error(x, { nzDuration: 4000 }))
            return;
          }
          modal.close()
        }
      }]
    });
    modal.componentInstance.id = id.toString();
  }

  redirectTo(uri: string)
  {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  deleteRelation(id: number)
  {
    this.thirdPartyService.deleteRelation(id).subscribe(() =>
    {
      let index = this.thirdPartyService.potentialRelationsInfos.relationList.findIndex(x => x.idRelation == id);
      this.thirdPartyService.potentialRelationsInfos.relationList.splice(index, 1)
      this.initData();
    })
  }

  canCreatePP()
  {
    if ((this.directionRelation == "source" && this.typeRelation.typeTarget != 2) || (this.directionRelation == "target" && this.typeRelation.typeSource != 2))
      return true;
    return false;
  }

  canCreateMP()
  {
    if ((this.directionRelation == "source" && this.typeRelation.typeTarget != 1) || (this.directionRelation == "target" && this.typeRelation.typeSource != 1))
      return true;
    return false;
  }

  onCancel()
  {
    this.showCreatePP = false;
    this.showCreateMP = false;
  }

  initFilter(idtype: number, direction: string, searchText: string)
  {
    this.typeRelation = this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(x => x.id == idtype);
    this.directionRelation = direction;
    let tptype = 0;
    if (this.cds.physicalPersonCollection.findIndex(x => x.id == this.idtp) >= 0)
      tptype = 1;
    else tptype = 2;

    if (this.directionRelation == "source")
    {
      if (this.typeRelation.typeTarget == 1)
      {
        this.filteredPPList = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
        this.filteredMPList = [];
      }
      else if (this.typeRelation.typeTarget == 2)
      {
        this.filteredPPList = []
        this.filteredMPList = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
      }
      else 
      {
        this.filteredPPList = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
        this.filteredMPList = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
      }
    }
    else if (this.directionRelation == "target")
    {
      if (this.typeRelation.typeSource == 1)
      {
        this.filteredPPList = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
        this.filteredMPList = [];
      }
      else if (this.typeRelation.typeSource == 2)
      {
        this.filteredPPList = []
        this.filteredMPList = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
      }
      else 
      {
        this.filteredPPList = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
        this.filteredMPList = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => !x.archived && x.id != this.idtp && this.datas.findIndex(i => i.idTarget == x.id) < 0);
      }
    }



  }

  initCreatePP()
  {
    this.createdTPName = "";
    this.createdTPSurname = "";
    this.showCreatePP = true;
  }

  initCreateMP()
  {
    this.createdTPName = "";
    this.showCreateMP = true;
  }

  createPhysicalPerson()
  {
    let pp = new PhysicalPerson();
    pp.firstName = this.createdTPName;
    pp.lastName = this.createdTPSurname;
    pp.nationality = "";
    this.pps.createPhysicalPerson(pp, null).subscribe(idpp =>
    {
      pp.id = idpp;
      if (this.cds.physicalPersonCollection.findIndex(x => x.id == idpp) < 0)
        this.cds.physicalPersonCollection.push(pp);
      this.addRelation(idpp);
      this.showCreatePP = false;
    })

  }

  createMoralPerson()
  {
    let mp = new MoralPerson();
    mp.name = this.createdTPName;
    this.mps.createMoralPerson(mp, null).subscribe(idmp =>
    {
      mp.id = idmp;
      if (this.cds.moralPersonCollection.findIndex(x => x.id == idmp) < 0)
        this.cds.moralPersonCollection.push(mp);
      this.addRelation(idmp);
      this.showCreateMP = false;
    });
  }

  openClientSearch()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.MORAL-PERSON.SEARCH-CLIENT-TITLE").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("THIRD-PARTY.CALL.THIRD-PARTY-SHEET.CREATE-AND-OPEN-SHEET").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: SearchClientInfoComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzFooter: [
        {
          label: close,
          onClick: () => { modal.close(); }
        },
        {
          label: confirm,
          type: 'primary',
          disabled(result): boolean { return result.selectedResult == null && result.resultsList.length != 1 },
          onClick: (result) =>
          {
            if (result.selectedResult || result.resultsList.length == 1)
            {
              if (!result.selectedResult)
                result.selectedResult = result.resultsList[0];
              let searchResult = result.selectedResult;


              let mp = new MoralPerson();
              mp.name = result.selectedResult.raisonSociale;
              mp.siren = result.selectedResult.numeroSIREN;
              mp.siret = result.selectedResult.numeroSIRET;
              mp.codeAPE = result.selectedResult.codeNAF;
              mp.codeTVA = result.selectedResult.numeroTVAIntracom;
              mp.anniversaryDate = result.selectedResult.creationDate;
              mp.nationality = "fr";
              mp.mainCurrency = "Euro";

              let siteTemp = new Site();
              siteTemp.name = searchResult.raisonSociale;
              siteTemp.address = this.fms.initFieldWithSearch(searchResult);
              siteTemp.countryCode = "FR";
              siteTemp.origin = "auto";
              this.siteService.createSite(siteTemp).subscribe(idsite =>
              {
                siteTemp.id = idsite;
                mp.sites.push(siteTemp);
                this.mps.createMoralPerson(mp, null).subscribe(idmp =>
                {
                  mp.id = idmp;
                  if (this.cds.moralPersonCollection.findIndex(x => x.id == idmp) < 0)
                    this.cds.moralPersonCollection.push(mp);
                  this.addRelation(idmp);
                  modal.close();
                });

              });
            }
          }
        }
      ]
    });
    modal.componentInstance.prefilter = null;
  }


  initEditComment(rel: GetRelation)
  {
    this.editedRelation = new GetRelation();
    this.editedRelation.idRelation = rel.idRelation;
    this.editedRelation.idSource = rel.idSource;
    this.editedRelation.idTarget = rel.idTarget;
    this.editedRelation.comment = rel.comment;
    this.editedRelation.idTypeRelation = rel.idTypeRelation;
  }

  saveRelationComment()
  {
    let setRel = new SetRelation();
    setRel.idRelation = this.editedRelation.idRelation;
    setRel.idThirdPartySource = 0;
    setRel.idThirdPartyTarget = 0;
    setRel.idTypeRelation = 0;
    setRel.comment = this.editedRelation.comment;
    this.thirdPartyService.updateRelation(setRel).subscribe(x =>
    {
      let rel = this.thirdPartyService.potentialRelationsInfos.relationList.find(relation => relation.idRelation == this.editedRelation.idRelation);
      rel.comment = this.editedRelation.comment;
      this.thirdPartyService.potentialRelationsInfosChange.next(null);
      this.editedRelation = new GetRelation();
    })
  }

  addRelation(idthirdparty: number)
  {
    if (!idthirdparty)
      return;
    let setRelation = new SetRelation();
    setRelation.idThirdPartySource = this.filterdirection == "source" ? this.idtp : idthirdparty;
    setRelation.idThirdPartyTarget = this.filterdirection == "target" ? this.idtp : idthirdparty;
    setRelation.idTypeRelation = this.idfiltertype;
    setRelation.comment = "";
    let titleTarget = "";
    let pp = this.cds.physicalPersonCollection.find(x => x.id == idthirdparty);
    if (!pp)
    {
      let mp = this.cds.allmoralperson.find(x => x.id == idthirdparty);
      titleTarget = mp.name;
    }
    else 
    {
      titleTarget = pp.firstName + " " + pp.lastName;
    }

    let titleType = "";
    let type = this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(x => x.id == this.idfiltertype);
    if (type)
    {
      titleType = this.filterdirection == "source" ? type.nameSourceTarget : type.nameTargetSource;
    }

    this.thirdPartyService.createRelation(setRelation).subscribe(x =>
    {
      let relation = new GetRelation();
      relation.idRelation = x;
      relation.idSource = setRelation.idThirdPartySource;
      relation.idTarget = setRelation.idThirdPartyTarget;
      relation.titleTypeRelationSourceTarget = type.nameSourceTarget;
      relation.titleTypeRelationTargetSource = type.nameTargetSource;
      relation.idTypeRelation = setRelation.idTypeRelation;
      relation.titleSource = relation.idSource == idthirdparty ? titleTarget : "";
      relation.titleTarget = relation.idTarget == idthirdparty ? titleTarget : "";
      relation.comment = setRelation.comment;
      this.thirdPartyService.potentialRelationsInfos.relationList.push(relation);
      let index = this.filteredPPList.findIndex(pp => pp.id == idthirdparty);
      if (index >= 0)
        this.filteredPPList.splice(index, 1);
      else
      {
        index = this.filteredMPList.findIndex(pp => pp.id == idthirdparty);
        this.filteredMPList.splice(index, 1);
      }
      this.idtptoadd = null;
      this.initData();
    });
  }

  getCommentText(comment: string)
  {
    let text = "";
    if (comment)
      this.translateService.get("THIRD-PARTY.GLOBAL-FILTER.UPDATE-COMMENT-RELATION").subscribe(x => text = x);
    else this.translateService.get("THIRD-PARTY.GLOBAL-FILTER.ADD-COMMENT-RELATION").subscribe(x => text = x);
    return text;
  }

  initData()
  {
    let filterParams = this.filter.filterCode.split(",");
    let idtype = parseInt(filterParams[2]);
    this.idtype = idtype;
    let direction = filterParams.length >= 4 ? filterParams[3] : "base";
    this.direction = direction;
    this.idfiltertype = idtype;
    this.filterdirection = direction;
    this.title = this.filter.title;
    this.oldColor = this.filter.color;
    let tempdata: GetRelation[] = [];

    if (idtype && direction)
    {
      let type = this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(tp => tp.id == idtype);
      if (type)
      {
        if (direction == "target")
          this.textType = type.nameTargetSource;
        else if (direction == "source")
          this.textType = type.nameSourceTarget;
        else this.textType = type.nameSourceTarget + ", " + type.nameTargetSource;
      }
    }

    for (let rel of this.thirdPartyService.potentialRelationsInfos.relationList)
    {
      let typeIsGood = !idtype || idtype == rel.idTypeRelation;
      let directionIsGood = direction == "base" || (direction == "source" && this.idtp == rel.idSource) || (direction == "target" && this.idtp == rel.idTarget)
      if (typeIsGood && directionIsGood)
      {
        let data = new GetRelation();
        data.idRelation = rel.idRelation;
        data.idTypeRelation = rel.idTypeRelation;
        data.idSource = rel.idSource == this.idtp ? rel.idSource : rel.idTarget;
        data.idTarget = rel.idSource == this.idtp ? rel.idTarget : rel.idSource;
        data.titleTarget = rel.idSource == this.idtp ? rel.titleTarget : rel.titleSource;
        data.comment = rel.comment;
        data.nbAttachments = rel.nbAttachments;
        data.nbMemos = rel.nbMemos;
        let pp = this.cds.physicalPersonCollection.find(x => x.id == data.idTarget);
        if (pp)
        {
          data.phoneNumbers = pp.phoneNumbers;
          data.mails = pp.mails;
        }
        else
        {
          let mp = this.cds.allmoralperson.find(x => x.id == data.idTarget);
          if (mp)
          {
            data.phoneNumbers = mp.phoneNumbers;
            data.mails = mp.mails;
            if (mp.initials)
              data.titleTarget += " (" + mp.initials + ")";
            if (mp.sign)
              data.titleTarget += " (" + mp.sign + ")";
          }
        }


        data.titleTypeRelationSourceTarget = rel.idSource == this.idtp ? rel.titleTypeRelationSourceTarget : rel.titleTypeRelationTargetSource;
        if (this.tcs.contains(data.titleTarget, this.searchText) || this.tcs.contains(data.titleTypeRelationSourceTarget, this.searchText))
          tempdata.push(data);
      }
    }
    tempdata = tempdata.sort((a, b) => a.titleTarget < b.titleTarget ? -1 : 1)
    this.datas = tempdata;
    this.initFilter(idtype, direction, "");
  }

  getPhoneNumbers(idtp: number)
  {
    let pp = this.cds.physicalPersonCollection.find(x => x.id == idtp);
    if (pp)
      return pp.phoneNumbers;
    else
    {
      let mp = this.cds.allmoralperson.find(x => x.id == idtp);
      if (mp)
        return mp.phoneNumbers;
      return [];
    }
  }

  getMails(idtp: number)
  {
    let pp = this.cds.physicalPersonCollection.find(x => x.id == idtp);
    if (pp)
      return pp.mails;
    else
    {
      let mp = this.cds.allmoralperson.find(x => x.id == idtp);
      if (mp)
        return mp.mails;
      return [];
    }
  }

  getComment(idtp: number)
  {
    let pp = this.cds.physicalPersonCollection.find(x => x.id == idtp);
    if (pp)
      return pp.comment;
    else
    {
      let mp = this.cds.allmoralperson.find(x => x.id == idtp);
      if (mp)
        return mp.comment;
      return "";
    }
  }

  getTitle(idtp)
  {
    let pp = this.cds.physicalPersonCollection.find(x => x.id == idtp);
    if (pp)
      return pp.firstName + ' ' + pp.lastName;
    else
    {
      let mp = this.cds.allmoralperson.find(x => x.id == idtp);
      if (mp)
      {
        let title = mp.name;
        if (mp.initials)
          title += " (" + mp.initials + ")";
        if (mp.sign)
          title += " (" + mp.sign + ")";
        return title;
      }
      return "";

    }
  }

  getSipUrl(number: string)
  {
    return this.sanitizer.bypassSecurityTrustUrl("sip:" + number);
  }

  getMailtoUrl(mail: string)
  {
    return this.sanitizer.bypassSecurityTrustUrl("mailto:" + mail);
  }

  setFilterAsGlobal()
  {
    this.thirdPartyService.setFilteredRelationTabAsGlobal(this.filter.id).subscribe(() =>
    {
      this.filter.isGlobal = true;
      let ispp = false;
      for (let pp of this.cds.physicalPersonCollection)
      {
        if (pp.id != this.idtp)
          pp.filteredRelations.push(this.filter);
        else ispp = true;
      }

      for (let mp of this.cds.allmoralperson)
      {
        if (mp.id != this.idtp)
          mp.filteredRelations.push(this.filter)
      }
      if (ispp)
        this.pps.physicalPersonListChange.next(null);
      else this.mps.moralPersonListChange.next(null);

    })
  }

  setFilterAsSystem()
  {
    this.thirdPartyService.setFilteredRelationTabAsSystem(this.filter.id).subscribe(() =>
    {
      this.filter.isSystem = true;
      this.filter.isGlobal = true;
      let ispp = false;
      for (let pp of this.cds.physicalPersonCollection)
      {
        if (pp.id != this.idtp && pp.filteredRelations.findIndex(x => x.id == this.filter.id) < 0)
          pp.filteredRelations.push(this.filter);
        else ispp = true;
      }

      for (let mp of this.cds.allmoralperson)
      {
        if (mp.id != this.idtp && mp.filteredRelations.findIndex(x => x.id == this.filter.id) < 0)
          mp.filteredRelations.push(this.filter)
      }
      if (ispp)
        this.pps.physicalPersonListChange.next(null);
      else this.mps.moralPersonListChange.next(null);

    })
  }

  setFocus()
  {
    var elem = document.getElementById("kanban-card-title");
    elem.style.display = "inline-block";
    elem.focus();
    this.titleHasFocus = true;
  }

  removeFocus()
  {
    var elem = document.getElementById("kanban-card-title");
    elem.style.display = "none";
    this.titleHasFocus = false;
  }

  saveModification()
  {
    this.filter.title = this.title;
    this.thirdPartyService.updateFilteredRelationTab(this.filter.id, this.filter).subscribe(() =>
    {
      this.titleHasFocus = false;
      var elem = document.getElementById("kanban-card-title");
      elem.style.display = "none";
      this.oldColor = this.filter.color;
    })
  }

  canSave()
  {
    if (this.filter.title != this.title || this.filter.color != this.oldColor)
      return true;
    return false;
  }

  toogleWhite(hex)
  {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result)
    {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      if ((r + g + b) > 620)
        return true;
    }
    return false
  }

  showAttachments = false;
  updatedIdRelation = 0;
  initRelationAttachments(idrelation: number)
  {
    this.updatedIdRelation = idrelation;
    this.showAttachments = true;
  }

  updateAttachments(att: KanbanAttachment[])
  {
    let rel = this.datas.find(x => x.idRelation == this.updatedIdRelation);
    if (rel)
      rel.nbAttachments = att.length;
  }

  showMemos = false;
  updatedRelationMemos: RelationMemo[] = []
  initRelationMemos(idrelation: number)
  {
    this.updatedIdRelation = idrelation;
    this.thirdPartyService.getMemoFromThirdPartyRelation(idrelation).subscribe(memos =>
    {
      this.updatedRelationMemos = memos;
      for (let memo of this.updatedRelationMemos)
      {
        if (!memo.title)
          memo.title = this.getInnerText(memo.content);
      }
    })
    this.showMemos = true;
  }

  openMemo(memo: RelationMemo)
  {
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let confirmmessage = "";
    this.translateService.get("THIRD-PARTY.CONFIRM-DELETE-MEMO").subscribe(x => confirmmessage = x);

    let del = "";
    this.translateService.get("GENERIC-ACTIONS.DELETE").subscribe(x => del = x);

    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);

    let modal = this.modalService.create({
      nzTitle: "",
      nzContent: HtmlEditorComponent,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: confirm,
        type: 'primary',
        onClick: (result) =>
        {
          if (memo)
          {
            memo.content = result.getEditorContent();
            memo.title = result.title;
            this.thirdPartyService.updateMemo(memo).subscribe();
            let rel = this.datas.find(x => x.idRelation == this.updatedIdRelation);
            if (rel)
              rel.nbMemos++;
          }
          else
          {
            memo = new RelationMemo();
            memo.content = result.getEditorContent();
            memo.title = result.title;
            this.thirdPartyService.addMemoToThirdPartyRelation(this.updatedIdRelation, memo).subscribe(id =>
            {
              memo.id = id;
              this.updatedRelationMemos.push(memo);
              let rel = this.datas.find(x => x.idRelation == this.updatedIdRelation);
              if (rel)
                rel.nbMemos++;
            })
          }
          modal.close()
        }
      },
      {
        label: cancel,
        onClick: (result) =>
        {
          modal.close()
        }
      },
      {
        label: del,
        danger: true,
        show: memo != null,
        onClick: (result) =>
        {
          this.modalService.confirm({
            nzTitle: "",
            nzContent: confirmmessage,
            nzOnOk: () =>
            {
              this.thirdPartyService.removeMemoFromThirdPartyRelation(memo.id).subscribe(() =>
              {
                let index = this.updatedRelationMemos.findIndex(x => x.id == memo.id);
                if (index >= 0)
                  this.updatedRelationMemos.splice(index, 1);
                let rel = this.datas.find(x => x.idRelation == this.updatedIdRelation);
                if (rel)
                  rel.nbMemos--;
              })
              modal.close()
            },
            nzOnCancel: () => { },
            nzCancelText: cancel,
            nzOkText: confirm
          })

        }
      }]
    });
    modal.componentInstance.editContent = memo ? memo.content : null;
    modal.componentInstance.editTitle = memo ? memo.title : null;
  }

  getInnerText(html: string)
  {
    var span = document.createElement('span');
    span.innerHTML = html;
    return span.textContent || span.innerText;
  };

}
