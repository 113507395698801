import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatorDisplayComponent } from './creator-display.component';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { AvatarUserModule } from 'src/app/pipes/avatar-user/avatar-user.module';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    AvatarUserModule,
    NameUserPipeModule,
    NzAvatarModule
  ],
  declarations: [CreatorDisplayComponent],
  exports: [CreatorDisplayComponent]
})
export class CreatorDisplayModule { }
