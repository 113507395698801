// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-container {
  height: 80% !important;
}

.you-message {
  flex-direction: row-reverse;
  padding-left: 15%;
}

.his-message {
  padding-right: 15%;
}

.message {
  display: flex;
  align-items: baseline;
  margin: 10px;
}

.message-bubble {
  background-color: #35667d;
  padding: 10px;
  border-radius: 10px;
  color: white;
  word-break: break-all;
  min-width: 50px;
  min-height: 50px;
  display: table;
}

.filter {
  filter: blur(0);
}

.ant-divider-horizontal.ant-divider-with-text-center::before {
  border-color: black !important;
}

.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-color: black !important;
}

.mark-message {
  position: absolute;
  right: 0;
  top: 0;
  color: rgb(168, 0, 0);
  font-size: 20px;
}

.avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}

.avatar-container {
  width: 100%;
  display: inline-block;
  padding: 0 5px;
  margin-top: -10px;
}

.more-avatar-read {
  background-color: #fde3cf;
  color: #f56a00;
}

.mess > * {
  word-break: break-word;
  width: 100%;
  overflow: hidden;
  white-space: initial;
}`, "",{"version":3,"sources":["webpack://./src/app/chat/discussion/discussion.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,2BAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;EACA,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,MAAA;EACA,qBAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,qBAAA;EACA,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,oBAAA;AACF","sourcesContent":[".ql-container {\r\n  height: 80% !important;\r\n}\r\n\r\n.you-message {\r\n  flex-direction: row-reverse;\r\n  padding-left: 15%;\r\n}\r\n\r\n.his-message {\r\n  padding-right: 15%;\r\n}\r\n\r\n.message {\r\n  display: flex;\r\n  align-items: baseline;\r\n  margin: 10px;\r\n}\r\n\r\n.message-bubble {\r\n  background-color: #35667d;\r\n  padding: 10px;\r\n  border-radius: 10px;\r\n  color: white;\r\n  word-break: break-all;\r\n  min-width: 50px;\r\n  min-height: 50px;\r\n  display: table;\r\n}\r\n\r\n.filter {\r\n  filter: blur(0);\r\n}\r\n\r\n.ant-divider-horizontal.ant-divider-with-text-center::before {\r\n  border-color: black !important;\r\n}\r\n\r\n.ant-divider-horizontal.ant-divider-with-text-center::after {\r\n  border-color: black !important;\r\n}\r\n\r\n.mark-message {\r\n  position: absolute;\r\n  right: 0;\r\n  top: 0;\r\n  color: rgb(168, 0, 0);\r\n  font-size: 20px;\r\n}\r\n\r\n.avatar-group .ant-avatar:not(:first-child) {\r\n  margin-left: -8px;\r\n}\r\n\r\n.avatar-container {\r\n  width: 100%;\r\n  display: inline-block;\r\n  padding: 0 5px;\r\n  margin-top: -10px;\r\n}\r\n\r\n.more-avatar-read {\r\n  background-color: #fde3cf;\r\n  color: #f56a00\r\n}\r\n\r\n.mess>* {\r\n  word-break: break-word;\r\n  width: 100%;\r\n  overflow: hidden;\r\n  white-space: initial;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
