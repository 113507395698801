import { Injectable } from '@angular/core';
import { UserSourceService } from '../source/user-source.service';
import { User } from '../../model/user';
import { UserRight } from '../../model/user-right';
import { tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { GroupUser } from '../../../group-user/entities/group-user';
import { UserSharedGroupDTO } from '../../model/user-shared-group';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HistoryUser } from '../../model/history-user';
import { UrlApiService } from 'src/app/services/url-api.service';
import { UserIp } from '../../model/userIp';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  //ListUser: User[] = [];
  HistoryForAnUser : HistoryUser[] = [];
  UserIps : UserIp[] = [];
  ListUserChange: Subject<User[]> = new  Subject<User[]>();
  ListUserRights: UserRight[] = [];
  userGroups: GroupUser[] = [];

  constructor(public userserv: UserSourceService, private errorHandler: ErrorHandlerService, private http: HttpClient) { }

  public getAllUsers(){
    return this.userserv.getAllUsers().pipe(tap(c => { this.ListUserRights = c.listUserRights; }));
  }

  public getAllArchivedUsers(){
    return this.userserv.getAllArchivedUsers().pipe(tap(c => {this.ListUserRights = c.listUserRights; }));
  }

  public createUser(user: User, avatar: File){
    return this.userserv.createUser(user, avatar);
  }

  public archiveUser(user: User){
    return this.userserv.archiveUser(user);
  }

  public restoreUser(user: User){
    return this.userserv.restoreUser(user);
  }

  public updateUser(user: User, avatar: File){
    return this.userserv.updateUser(user, avatar);
  }

  public getUsergroups(iduser: number){
    return this.userserv.getUsergroups(iduser).pipe(tap(c => this.userGroups= c));
  }

  public getAvatar(iduser: number){
    return this.userserv.getAvatar(iduser);
  }

  public manualUserActivation(iduser: number){
    return this.userserv.manualUserActivation(iduser);
  }

  public desactivateUser(iduser: number){
    return this.userserv.desactivateUser(iduser);
  }

  public getAllGroupsOfUser(iduser: number){
    return this.userserv.getAllGroupsOfUser(iduser);
  }

  public updateGroupsOfUser(groups: UserSharedGroupDTO[]){
    return this.userserv.updateGroupsOfUser(groups);
  }
  public sendActivationMail(iduser: number){
    return this.userserv.sendActivationMail(iduser);
  }
  public sendPassword(mail: string, password: string){
    return this.userserv.sendPassword(mail, password);
  }

  public GetColumnVisibilityPreference()
  {
    return this.userserv.GetColumnVisibilityPreference();
  }

  public GetHistoryForAnUser(user : User)
  {
    return this.http.get<HistoryUser[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/history/"+ user.id);
  }

  public GetIpsForAnUser(user : User)
  {
    return this.http.get<UserIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/ipAddress/"+ user.id);
  }

  public SetIfAuthorizedUser(userIp : UserIp)
  {
    return this.http.put<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/ipAddress/one", userIp);
  }

  public SetIfAuthorizedAll(userIp : UserIp)
  {
    return this.http.put<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/ipAddress/all", userIp);
  }
}
