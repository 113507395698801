import { Injectable } from '@angular/core';
import { FormLine } from '../entities/form-line';
import { FormData } from '../entities/form-data';
import { FormField } from '../entities/form-field';
import { TranslateService } from '@ngx-translate/core';
import { SearchClientResult } from '../../../../src/app/third-party/moral-person/entities/search-client-result';
import { CountryManagerService } from '../../../../src/app/country-manager/services/country-manager.service';
@Injectable({
  providedIn: 'root'
})
export class FormManagerService
{

  constructor(private translateService: TranslateService, private cms: CountryManagerService) { }


  transformDataIntoLines(datas: FormData[])
  {
    let lines: FormLine[] = [];
    datas.forEach(data =>
    {
      let line = lines.find(x => x.position == data.line);
      if (!line)
      {
        line = new FormLine();
        line.position = data.line;
        lines.push(line);
      }
      let field = new FormField();
      field.key = data.key;
      field.position = data.column;
      field.type = data.type;
      field.value = data.value;
      field.label = data.label;
      field.width = data.width;
      //this.translateService.get(data.label).subscribe(x => field.label = x);
      line.fields.push(field);
    });
    lines = lines.sort((a, b) => a.position < b.position ? -1 : 1);
    lines.forEach(x =>
    {
      x.fields = x.fields.sort((a, b) => a.position < b.position ? -1 : 1);
    });
    return lines;
  }

  initFieldWithSearch(searchResult: SearchClientResult)
  {
    let country = this.cms.countryList.find(x => x.code == "FR");
    let lines = this.transformDataIntoLines(country.addressFormat);

    for (let line of lines)
    {
      for (let field of line.fields)
      {
        switch (field.label)
        {
          case "ENTREE-TOUR-BATIMENT-IMMEUBLE-RESIDENCE":
            field.value = searchResult.complementAdresse;
            break;
          case "NUMERO-LIBELLE-VOIE":
            field.value = searchResult.adresse;
            break;
          case "BOITE-POSTALE":
            field.value = searchResult.boitePostale;
            break;
          case "CODE-POSTALE":
            field.value = searchResult.codePostal;
            break;
          case "VILLE":
            field.value = searchResult.ville;
            break;
        }
      }
    }
    let addressData = this.transformLineIntoData(lines);
    return JSON.stringify(addressData);
  }

  transformLineIntoData(lines: FormLine[])
  {
    let datas: FormData[] = [];
    lines.forEach(line =>
    {
      line.fields.forEach(field =>
      {
        let data = new FormData();
        data.key = field.key;
        data.label = field.label;
        data.type = field.type;
        data.value = field.value;
        data.column = field.position;
        data.line = line.position;
        data.width = field.width;
        datas.push(data);
      });
    });
    return datas;
  }

}
