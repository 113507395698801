import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Injectable, Input, TemplateRef, ViewChild } from '@angular/core';
import { CalendarView, CalendarDateFormatter, CalendarEventTitleFormatter, CalendarEventTimesChangedEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, CalendarDayViewBeforeRenderEvent, DateFormatterParams } from 'angular-calendar';
import { WeekViewHourSegment, ViewPeriod } from 'calendar-utils';
import { firstValueFrom, fromEvent, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { addDays, addMinutes, endOfWeek } from 'date-fns';
import { CustomEvent, BORDER_DARKENING, WRITTEN_DARKENING } from './entities/event';
import { TranslateService } from '@ngx-translate/core';
import { EditEventComponent } from './edit/edit.component';
import { EventService } from './services/event.service';
import RRule from 'rrule';
import moment from 'moment-timezone';
import { Color, colors } from './entities/color';
import { OverlapComponent } from './overlap/overlap.component';
import { DatePipe } from '@angular/common';
import { NzTreeComponent } from 'ng-zorro-antd/tree';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ColorService } from '../../services/color.service';
import { AuthService } from '../../services/auth.service';
import { KanbanTreeSelectedInfos } from '../../kanban/entities/kanban-tree-selected-infos';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { OtherPlanningRights, PlanningRights } from '../../planning/entities/other-planning-rights';
import { PlanningFilterParameters } from '../../planning/entities/planning-filter-parameters';
import { PlanningService } from '../../planning/planning.service';
import { SettingsService } from '../../settings/services/settings.service';
import { CacheDataService } from '../../services/cache-data.service';
import { TextCompareService } from '../../services/text-compare.service';
import { User } from '../../user/model/user';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DatelocalePipe } from '../../pipes/pipes/datelocale.pipe';
import { DayEventsComponent } from '../../planning/day-events/day-events.component';
import { PresenceInfos } from '../../kanban/entities/presence-infos';
import { start } from 'repl';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter
{
  // TODO: add explicit constructor

  public weekViewHour({ date, locale }: DateFormatterParams): string
  {
    return new DatePipe(locale).transform(date, 'HH:mm', locale);
  }
}

function floorToNearest(amount: number, precision: number)
{
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number)
{
  return Math.ceil(amount / precision) * precision;
}

export class CustomEventTitleFormatter extends CalendarEventTitleFormatter
{
  weekTooltip(event: CustomEvent, title: string)
  {
    if (!event.meta.tmpEvent)
    {
      return super.weekTooltip(event, title);
    }
  }

  dayTooltip(event: CustomEvent, title: string)
  {
    if (!event.meta.tmpEvent)
    {
      return super.dayTooltip(event, title);
    }
  }
}


moment.tz.setDefault('Utc'); // Init the moment library (used for time)

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CalendarEventTitleFormatter
    }
  ]
})

export class CalendarComponent implements OnInit
{

  /*=========
    VARIABLES
    =========*/


  //PROD
  event;
  customTemplate;
  defaultTemplate;
  weekEvent;
  tooltipPlacement;
  eventClicked;
  tooltipTemplate;
  tooltipAppendToBody;
  tooltipDisabled;
  newEventTitle = "";
  @ViewChild('titleEvent', { static: false }) newEventTemplate!: TemplateRef<{}>;
  @ViewChild('userPlanningFilter', { static: false }) templatemember?: any;


  //CalendarView
  @Input() weekHourSement = 1;
  hoursegchecked = this.weekHourSement == 2;
  @Input() view: CalendarView = CalendarView.Month;
  @Input() showCardDetails: boolean = true;
  @Input() showFilters: boolean = true;
  @Input() _eventlist: CustomEvent[] = [];
  viewMonth = CalendarView.Month;
  viewWeek = CalendarView.Week;
  viewDay = CalendarView.Day;
  @Input() viewDate: Date = new Date();
  label = "Profils"; //TODO : Traduction

  //filter parameters
  selectedObjectName: String;
  selectedObjectId: number;
  selectedObjectPrimaryColor: Color;
  selectedObjectSecondaryColor: string;
  selectedObjectWrittenColor: string;
  selectedObjectIterator: number = 0;
  listOptions: string[];
  listValues: number[];
  date: Date;
  nodes: any[] = [];
  freecardIsFiltered = false;
  selectResourceNodes: any[] = [];

  //Events
  events = []; //events affichés
  type: number;
  recurringEvents: CustomEvent[] = []; //events recurrents
  momentEnd;
  filterDrawer = "";
  viewPeriod: ViewPeriod;

  //Object passed by parameter
  @Input() description: string = "Description";
  @Input() titleDescription: string = "Title";
  @Input() showSuperpositionCheck: boolean = true;
  @Input() useReccurence: boolean = true;
  @Input() agendaMode = false;
  @Input() rights: PlanningRights = PlanningRights.None;
  @Input() ownerId: number;
  @Input() filters: PlanningFilterParameters = new PlanningFilterParameters();
  @Input() hideHeader = false;

  //Event Creation, Edition and Refresh
  dragToCreateActive = false;
  refresh: Subject<any> = new Subject();
  showCreatorColor = false;
  selectedEvent: CustomEvent;
  activeDayIsOpen: boolean = true;
  needToRefresh: boolean = true;
  hoveredEvent = 0;
  beforeDrag: {
    start: Date,
    end: Date,
    until: Date
  } = null;

  //Time Variable
  private DAYTIME = 86400000;
  timerHandler;
  timer: number = 0;

  //Design
  specialColor = colors.special;
  serverAdd: boolean = false;


  categoriesFilterOpened = false;
  @ViewChild('nzTreeComponent', { static: false }) nzTreeComponent!: NzTreeComponent;
  @ViewChild('selectResourceTree', { static: false }) selectResourceTree!: NzTreeComponent;


  getDayColor(date: Date)
  {
    if (date.getMonth() == this.viewDate.getMonth())
      return "black;"
    return "lightgray";
  }

  selectWeekEvent(event: CustomEvent)
  {
    this.selectedEvent = event;
  }

  showEvent(event: CustomEvent)
  {

    if (!event.rrule || event.customRule)
      return true;
    for (let evt of this._eventlist)
    {
      if (evt.id != event.id && evt.reccurenceId == event.reccurenceId && evt.customRule && evt.start.getDate() == event.start.getDate())
        return false;

    }

    return true;
  }

  getDayEventsForMonth(events: CustomEvent[])
  {

    let temp: CustomEvent[] = [];
    for (let evt of events)
    {
      if (this.showEvent(evt))
        temp.push(evt);
    }

    return temp;
  }

  updateHourSegmentPref()
  {
    this.weekHourSement = this.hoursegchecked ? 2 : 1;
    this.service.updateWeekHourSegmentPreference(this.weekHourSement, this.ownerId);
    this.refreshView();
  }

  updateHoveredID(event: CustomEvent, day: Date)
  {
    let showStart = this.getStartDayEventsTimeText(event, day);
    let showEnd = this.getEndDayEventsTimeText(event, day);
    if (showStart && showEnd)
      return this.hoveredEvent = 0;
    else if (showStart)
      return this.hoveredEvent = event.id;
    return this.hoveredEvent = event.id;
  }

  initFilterTreeNodes()
  {
    if (!this.service.categories || this.service.categories.length == 0)
      return;

    let tempNode = [];

    for (let cat of this.service.categories)
    {
      let catNode = {
        title: cat.name,
        key: "cat_" + cat.id,
        bgcolor: cat.color,
        expanded: false,
        checked: false,
        children: []
      };
      tempNode.push(catNode)

      let allchecked = true;
      for (let res of cat.resources)
      {
        let resNode = {
          title: res.name,
          key: "res_" + res.id,
          checked: this.filters.listIds.findIndex(x => x == res.id) >= 0,
          isLeaf: true
        };
        catNode.children.push(resNode);
        if (!resNode.checked)
          allchecked = false;
      }
      if (allchecked)
        catNode.checked = true;
    }

    this.nodes = [...tempNode];
  }

  initSelectResourceTreeNodes()
  {
    if (!this.service.categories || this.service.categories.length == 0)
      return;

    let tempNode = [];

    for (let cat of this.service.categories)
    {
      let catNode = {
        title: cat.name,
        key: "cat_" + cat.id,
        bgcolor: cat.color,
        selectable: false,
        expanded: false,
        children: []
      };
      tempNode.push(catNode)

      for (let res of cat.resources)
      {
        let resNode = {
          title: res.name,
          key: "res_" + res.id,
          isLeaf: true
        };
        catNode.children.push(resNode);
      }
    }

    this.selectResourceNodes = [...tempNode];
  }

  dateIsToday(date: Date)
  {
    if (new Date().getDay() == date.getDay())
      return true;
    return false;
  }

  getNumberOfEventForDay(date: Date)
  {
    let dayAfter = new Date(new Date(date).setTime(date.getTime() + 24 * 60 * 60 * 1000));
    let nbevent = 0;
    for (let event of this.events)
    {
      if (event.start > date && event.end < dayAfter && this.showEvent(event))
        nbevent++;
    }
    return nbevent;
  }

  dayEvents: CustomEvent[] = [];
  getAllEventForDay(date: Date, tplate: TemplateRef<{}>)
  {
    let dayAfter = new Date(new Date(date).setTime(date.getTime() + 24 * 60 * 60 * 1000));
    this.dayEvents.length = 0;
    for (let event of this.events)
    {
      if (event.start < date)
      {
        let evt = this._eventlist.find(x => x.id == event.id && x.end > dayAfter);
        if (evt)
        {
          let fusionEvent = new CustomEvent();

          fusionEvent.id = evt.id;
          fusionEvent.id_obj = evt.id_obj;
          fusionEvent.start = event.start;
          fusionEvent.end = evt.end;
          fusionEvent.title = event.title;
          fusionEvent.longEventStatus = event.longEventStatus;
          fusionEvent.completed = event.completed;
          fusionEvent.temporary = event.temporary;

          fusionEvent.originColor = evt.originColor;
          fusionEvent.draggable = true;
          fusionEvent.resizable = event.resizable;
          fusionEvent.color = {
            primary: new Color(event.originColor).darken(BORDER_DARKENING).toString(),
            secondary: event.originColor.toString()
          }
          this.dayEvents.push(fusionEvent);
        }
      }
      else if (event.start > date && event.end < dayAfter)
      {
        this.dayEvents.push(event);
      }
    }
    this.showDayEvents(date)

  }

  showDayEvents(date: Date)
  {
    let title = "";
    this.translate.get("PLANNING.TITLE-DAY-EVENTS", { v: this.datepipe.transform(date, 'shortDate') }).subscribe(x => title = x);
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);

    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: DayEventsComponent,
      nzBodyStyle: { height: '75vh' },
      nzWidth: "90%",
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      }]
    });
    modal.componentInstance.day = date;
    modal.componentInstance.obs = this.createEventObs;
    modal.componentInstance.dayevents = this.dayEvents;
    modal.componentInstance.boards = this.service.boards;
    modal.componentInstance.agendaMode = this.agendaMode;
    modal.componentInstance.canCreateAppointment = this.canCreateAppointment();
    modal.componentInstance.resources = this.service.resources;
  }

  updateFilteredResources(truc: any)
  {
    let checkednodes = this.nzTreeComponent.getCheckedNodeList();
    let tempfilter = [];
    for (let node of checkednodes)
    {

      if (node.key.startsWith("cat"))
      {
        for (let child of node.children)
        {
          tempfilter.push(parseInt(child.key.split("_")[1]));
        }
      }
      else 
      {
        tempfilter.push(parseInt(node.key.split("_")[1]));
      }

    }

    this.needToRefresh = true;
    this.eventService.ressourceFilter = tempfilter;
    this.refreshView();
  }
  autoRefresh = null;

  constructor(private cdr: ChangeDetectorRef, public modalService: NzModalService, public translate: TranslateService,
    public drawerService: NzDrawerService, public datepipe: DatelocalePipe,
    public service: PlanningService, public settingsService: SettingsService, public cds: CacheDataService, public tcs: TextCompareService,
    private nzContextMenuService: NzContextMenuService, public eventService: EventService, public cs: ColorService, public auth: AuthService) { }

  /*====
    INIT
    ====*/

  ngOnDestroy()
  {
    if (this.autoRefresh)
      clearInterval(this.autoRefresh);
    if (this.service.closeCalendar)
      this.service.closeCalendar();
    if (this.tempsub)
      this.tempsub.unsubscribe();
    if (this.tempsub2)
      this.tempsub2.unsubscribe();
    if (this.createEventObs)
      this.createEventObs.unsubscribe();
  }

  createEventObs;
  createEventSub: Subject<{ date: Date, event: CustomEvent }> = new Subject<{ date: Date, event: CustomEvent }>();

  async ngOnInit()
  {
    if (!this.hideHeader)
    {
      if (!this.titleDescription)
        this.translate.get("GENERIC-FORM.ALL").subscribe(x => this.titleDescription = x);

      let x = await firstValueFrom(this.settingsService.getSettingsByName("calendar_view_type_" + this.ownerId));
      if (x)
        this.view = x as CalendarView;



      let a = await firstValueFrom(this.settingsService.getSettingsByName("planning_showcreatorcolor"));
      if (a && this.ownerId == 0)
        this.showCreatorColor = a == "true";
      let y = await firstValueFrom(this.settingsService.getSettingsByName("calendar_monthdisplay_cardetails_" + this.ownerId));
      if (y == "false")
        this.showCardDetails = false;

      let z = await firstValueFrom(this.settingsService.getSettingsByName("calendar_view_weekhoursegment_" + this.ownerId));
      if (z)
        this.weekHourSement = parseInt(z);

      try
      {
        this.filters = await firstValueFrom(this.service.getFilterPreferences(this.ownerId))
        this.filters.idUser = this.ownerId;
        if (this.ownerId == 0)
        {
          this.filters.othersPlanning = [];
          this.filters.othersPlanning.push({ id: this.auth.connectedUser.id, rights: this.service.allRight() });

          for (let other of this.service.allowedPlannings)
          {
            this.filters.othersPlanning.push({ id: other.id, rights: other.rights });
          }
        }
        else if (this.filters.othersPlanning.findIndex(x => x.id == this.ownerId) < 0)
          this.filters.othersPlanning.push({ id: this.ownerId, rights: PlanningRights.None });
      }
      catch (e) { }

      let amode = await firstValueFrom(this.settingsService.getSettingsByName("planning_agendamode_" + this.ownerId));
      this.agendaMode = !amode || amode == "1";
      this.date = this.viewDate;
      this.setViewPref(this.date ? this.date : new Date(), this.view);
    }
    else
    {
      this.agendaMode = true;
      this.filters.start = this.viewDate;
      console.log(this.viewDate);

      this.filters.end = new Date(this.filters.start.getTime() + (24 * 60 * 60 * 1000))
      this.filters.idUser = this.ownerId;
      this.date = this.viewDate;
      let plan = new OtherPlanningRights();
      plan.id = this.ownerId;
      plan.rights = this.rights;
      this.date = this.viewDate;
      this.filters.othersPlanning.push(plan);
    }

    await this.toggleAgendaMode()

    this.initFilterTreeNodes();
    this.initSelectResourceTreeNodes();
    this.autoRefresh = setInterval(() =>
    {
      this.refreshView();
    }, 300000);
    this.service.getOverlapSettings().subscribe(x =>
    {
      if (x)
        this.eventService.overlapGlobal = JSON.parse(x);
    })
    this.eventService.updateEventSubject.subscribe(() =>
    {
      this.refreshView()
    })

    // Define the object service
    this.eventService.service = this.service;
    this.selectedObjectIterator = 0;
    this.selectedObjectId = this.service.getListForCalendar()[this.selectedObjectIterator].id;
    this.selectedObjectName = this.service.getListForCalendar()[this.selectedObjectIterator].name;
    this.selectedObjectPrimaryColor = this.service.getListForCalendar()[this.selectedObjectIterator].color as Color;

    this.selectedObjectSecondaryColor = new Color(this.selectedObjectPrimaryColor).darken(BORDER_DARKENING).toString();


    this.selectedObjectWrittenColor = new Color(this.selectedObjectPrimaryColor).darken(WRITTEN_DARKENING).toString()


    //Get Object list
    this.listOptions = this.service.getListForCalendar().map(a => a.name);
    this.listValues = this.service.getListForCalendar().map(a => a.id);


    //Get events to display from service
    this.events = this._eventlist;



    this.hoursegchecked = this.weekHourSement == 2;
    //Get recurringEvents
    this.refreshView()

    this.tempsub = this.service.refreshCalendars.subscribe(async (iduser: number) =>
    {
      if (this.ownerId == 0 || iduser == this.ownerId)
      {
        await this.toggleAgendaMode();
        this.refreshView()
      }

    })

    this.createEventObs = this.createEventSub.subscribe(({ date, event }) =>
    {
      this.createComponentModal(date, new Date(date.getTime() + (60 * 60 * 1000)), event);
    })

    this.tempsub2 = this.service.refreshRights.subscribe((datas) =>
    {
      if (datas.id == this.ownerId)
        this.rights = datas.r;
      this.cdr.detectChanges();
    })
  }
  tempsub;
  tempsub2;

  showSelectedEvent(day: any)
  {
    if (!this.selectedEvent)
      return false;
    if (day.events.findIndex(x => x.id == this.selectedEvent.id))
      return true;
    return false;
  }

  stopPropagation()
  {
    event.stopPropagation();
  }

  /*========
    METHODES
    ========*/

  //View functions
  onDateChange(date: Date)
  { //Allow user to choose a date to see in the planning
    if (date)
      this.viewDate = new Date(date);
    else
      this.viewDate = new Date(Date.now());
  }

  async resetDate()
  {
    this.date = null;
    this.setViewPref(new Date(), this.view);
    await this.toggleAgendaMode();
    this.refreshView();
  }

  confirmEvent(event)
  {
    this.service.confirmEvent(event);
  }

  async setDate()
  {
    this.date = new Date(this.viewDate);
    this.setViewPref(this.date, this.view);
    await this.toggleAgendaMode();
    this.refreshView();
  }

  setViewDate(action: string)
  {
    if (action == 'backward')
    {
      switch (this.view)
      {
        case "month":
          this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() - 1, 0, 0, 0, 0, 0)
          break;
        case "week":
          this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), (this.viewDate.getDate() - this.viewDate.getDay()) - 7, 0, 0, 0, 0)
          break;
        case "day":
          this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate() - 1, 0, 0, 0, 0)
          break;
      }
    }
    else if (action == "foreward")
    {
      switch (this.view)
      {
        case "month":
          this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0, 0, 0, 0, 0)
          break;
        case "week":
          this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), (this.viewDate.getDate() - this.viewDate.getDay()) + 7, 0, 0, 0, 0)
          break;
        case "day":
          this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), this.viewDate.getDate() + 1, 0, 0, 0, 0)
          break;
      }
    }

  }

  getStartDayEventsTimeText(event: CustomEvent, day: Date)
  {
    let events = this._eventlist.filter(x => x.id == event.id);
    if (events.length > 1)
    {
      if (event.start == events[0].start)
        return true;
      return false;
    }
    else
    {
      if (day.getDate() == event.end.getDate() && day.getDate() != event.start.getDate())
        return false;
    }
    return true;
  }

  getEndDayEventsTimeText(event: CustomEvent, day: Date)
  {
    let events = this._eventlist.filter(x => x.id == event.id);
    if (events.length > 1)
    {
      if (event.end == events[1].end)
        return true;
      return false;
    }
    else
    {
      if (day.getDate() == event.start.getDate() && day.getDate() != event.end.getDate())
        return false;
    }
    return true;
  }

  getIconType(event: CustomEvent, day: Date)
  {
    let showStart = this.getStartDayEventsTimeText(event, day);
    let showEnd = this.getEndDayEventsTimeText(event, day);
    if (showStart && showEnd)
      return "minus";
    else if (showStart)
      return "arrow-right";
    return "arrow-left";
  }

  updateObject()
  {
    this.selectedObjectIterator = this.selectedObjectIterator < this.service.getListForCalendar().length - 1 ? this.selectedObjectIterator + 1 : 0;
    this.selectedObjectId = this.service.getListForCalendar()[this.selectedObjectIterator].id;
    this.selectedObjectName = this.service.getListForCalendar()[this.selectedObjectIterator].name;
    this.selectedObjectPrimaryColor = this.service.getListForCalendar()[this.selectedObjectIterator].color as Color;
    this.selectedObjectSecondaryColor = new Color(this.selectedObjectPrimaryColor).darken(BORDER_DARKENING).toString();
    this.selectedObjectWrittenColor = new Color(this.selectedObjectPrimaryColor).darken(WRITTEN_DARKENING).toString()
  }

  //Event functions
  private extractEvents()
  { //Extract recurring events from the event service
    this.recurringEvents = [];
    for (var i = 0; i < this._eventlist.length; i++)
    {
      if (this._eventlist[i].isRecurrent)
      {
        this.recurringEvents.push(this._eventlist[i]);
      }
    }
  }

  saveUpdateOverlapSettings(newOverlapSettingsValue: boolean)
  {
    //this.service.saveOverlapSettings(newOverlapSettingsValue.toString());
  }

  startDragToCreate( //Create a recurrent event each week by default based on a drag movement
    segment: WeekViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement,
    type: number
  )
  {
    if (!this.canCreateAppointment())
      return;

    const weekday = segment.date.getDay() == 0 ? 6 : segment.date.getDay() - 1; // getting the weekday we have to be recurrent (sunday==0 so if it is sunday, we want it to display it on the last case number 6. else we want the event to be displayed on the bprevious day)
    const endSegment = new Date(segment.date);
    endSegment.setHours(segment.date.getHours() + 1); // an event has to last one hour on click only
    let originColor_ = new Color(this.service.getListForCalendar().find(obj => { return obj.id === this.selectedObjectId }).color);
    let color_ = {
      primary: new Color(originColor_).darken(BORDER_DARKENING).toString(),
      secondary: originColor_.toString()
    }



    let dragToSelectEvent: CustomEvent = {
      id: 0,
      id_obj: this.selectedObjectId,
      title: this.service.getListForCalendar().find(obj => { return obj.id === this.selectedObjectId }).name.toString(),
      start: segment.date,
      end: endSegment,
      completed: false,
      temporary: false,
      location: "",
      meta: {
        tmpEvent: true
      },
      originColor: originColor_,
      color: color_,
      creatorId: this.ownerId == 0 ? this.auth.connectedUser.id : this.ownerId,
      resizable: { beforeStart: true, afterEnd: true },
      draggable: true,
      type_obj: type + '',
      isRecurrent: false,
      isDragged: false, // can't modify the event by modal, only by drag an resize it
      hasToBeDuplicated: true,
      rrule: null,
      longEventStatus: 0,
      presence: new PresenceInfos()
    };
    this.events = [...this.events, dragToSelectEvent];
    this._eventlist = [...this._eventlist, dragToSelectEvent];

    const segmentPosition = segmentElement.getBoundingClientRect();
    this.dragToCreateActive = true;
    const endOfView = endOfWeek(this.viewDate, { weekStartsOn: 1 });

    fromEvent(document, 'mousemove')
      .pipe(
        finalize(() =>
        {
          delete dragToSelectEvent.meta.tmpEvent;
          this.createComponentModal(dragToSelectEvent.start, dragToSelectEvent.end)
          // delete dragToSelectEvent.meta.tmpEvent;
          // this.dragToCreateActive = false;
          // this.serverAdd = true;
          // let title = "";
          // this.newEventTitle = "";
          // this.translate.get("GENERIC-FORM.TITLE").subscribe(t => title = t);
          // let cancel = "";
          // this.translate.get("GENERIC-ACTIONS.CANCEL").subscribe(t => cancel = t);
          // let createTask = "";
          // this.translate.get("PLANNING.CREATE-TASK").subscribe(t => createTask = t);
          // let createAppointment = "";
          // this.translate.get("PLANNING.CREATE-APPOINTMENT").subscribe(t => createAppointment = t);

          // let modal = this.modalService.create({
          //   nzTitle: title,
          //   nzContent: this.newEventTemplate,
          //   nzMaskClosable: false,
          //   nzClosable: false,
          //   nzFooter: [{
          //     label: cancel,
          //     onClick: () =>
          //     {
          //       this.events.splice(this.events.length - 1, 1);
          //       this._eventlist.splice(this._eventlist.length - 1, 1);
          //       this.eventService.updateEventSubject.next(null);
          //       modal.close();
          //     }
          //   },
          //   {
          //     label: createTask,
          //     show: !this.agendaMode,
          //     type: "primary",
          //     onClick: () =>
          //     {
          //       dragToSelectEvent.title = this.newEventTitle;
          //       this.service.createEvent(dragToSelectEvent, this.filters, false);
          //       let diff = Math.abs(new Date(dragToSelectEvent.end).getTime() - new Date(dragToSelectEvent.start).getTime());
          //       let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          //       if (diffDays > 2)
          //       {
          //         let firstEventStart = new Date(dragToSelectEvent.start);
          //         let firstEventEnd = new Date(new Date(dragToSelectEvent.start).setTime(firstEventStart.getTime() + 2 * 60 * 60 * 1000));


          //         let secondEventEnd = new Date(dragToSelectEvent.end);
          //         let secondEventStart = new Date(new Date(dragToSelectEvent.end).setTime(secondEventEnd.getTime() - 2 * 60 * 60 * 1000));

          //         dragToSelectEvent.start = firstEventStart;
          //         dragToSelectEvent.longEventStatus = 1
          //         dragToSelectEvent.end = firstEventEnd;
          //         let secondEvent: CustomEvent = {
          //           id: 0,
          //           id_obj: this.selectedObjectId,
          //           title: this.newEventTitle,
          //           start: secondEventStart,
          //           end: secondEventEnd,
          //           completed: false,
          //           location: "",
          //           temporary: false,
          //           originColor: originColor_,
          //           color: color_,
          //           resizable: { beforeStart: true, afterEnd: true },
          //           draggable: true,
          //           type_obj: type + '',
          //           isRecurrent: false,
          //           isDragged: false,
          //           rrule: dragToSelectEvent.rrule,
          //           longEventStatus: 2
          //         };
          //         setTimeout(() =>
          //         {
          //           secondEvent.id = dragToSelectEvent.id;
          //         }, 3000);
          //         this._eventlist.push(secondEvent)

          //       }
          //       modal.close();
          //       this.eventService.updateEventSubject.next(null);
          //     }
          //   },
          //   {
          //     label: createAppointment,
          //     type: "primary",
          //     onClick: () =>
          //     {
          //       dragToSelectEvent.title = this.newEventTitle;
          //       dragToSelectEvent.id_obj = null;
          //       dragToSelectEvent.color = null;
          //       this.service.createEvent(dragToSelectEvent, this.filters, true);
          //       let diff = Math.abs(new Date(dragToSelectEvent.end).getTime() - new Date(dragToSelectEvent.start).getTime());
          //       let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          //       if (diffDays > 2)
          //       {
          //         let firstEventStart = new Date(dragToSelectEvent.start);
          //         let firstEventEnd = new Date(new Date(dragToSelectEvent.start).setTime(firstEventStart.getTime() + 2 * 60 * 60 * 1000));


          //         let secondEventEnd = new Date(dragToSelectEvent.end);
          //         let secondEventStart = new Date(new Date(dragToSelectEvent.end).setTime(secondEventEnd.getTime() - 2 * 60 * 60 * 1000));

          //         dragToSelectEvent.start = firstEventStart;
          //         dragToSelectEvent.longEventStatus = 1
          //         dragToSelectEvent.end = firstEventEnd;
          //         let secondEvent: CustomEvent = {
          //           id: 0,
          //           id_obj: null,
          //           title: this.newEventTitle,
          //           start: secondEventStart,
          //           end: secondEventEnd,
          //           completed: false,
          //           location: "",
          //           temporary: false,
          //           originColor: originColor_,
          //           color: color_,
          //           resizable: { beforeStart: true, afterEnd: true },
          //           draggable: true,
          //           type_obj: type + '',
          //           isRecurrent: false,
          //           isDragged: false,
          //           rrule: dragToSelectEvent.rrule,
          //           longEventStatus: 2
          //         };
          //         setTimeout(() =>
          //         {
          //           secondEvent.id = dragToSelectEvent.id;
          //         }, 3000);
          //         this._eventlist.push(secondEvent)

          //       }
          //       modal.close();
          //       this.eventService.updateEventSubject.next(null);
          //     }
          //   }]
          // });
        }),
        takeUntil(fromEvent(document, 'mouseup'))
      )
      .subscribe((mouseMoveEvent: any) =>
      {
        const minutesDiff = ceilToNearest(
          2 * (mouseMoveEvent.clientY - segmentPosition.top),
          15
        );

        const daysDiff =
          floorToNearest(
            mouseMoveEvent.clientX - segmentPosition.left,
            segmentPosition.width
          ) / segmentPosition.width;

        let divider = this.view == 'day' ? 2 : this.weekHourSement;
        const newEnd = addDays(addMinutes(segment.date, minutesDiff / divider), daysDiff);
        if (newEnd > segment.date && newEnd < endOfView)
        {
          dragToSelectEvent.end = newEnd;
        }
        this.serverAdd = false;
        this.newEvent(this.serverAdd, dragToSelectEvent);

      });
    //this.refreshView(); //Refresh the view to call updateCalendarEvents()
  }

  selectEvent(event: any)
  { //Store the last clicked event
    this.selectedEvent = event.event;
  }

  removeEventFromUIFromStatus(event, status: number)
  {
    this._eventlist = this._eventlist.filter(iEvent => iEvent.id !== event.id || iEvent.longEventStatus != status || iEvent.type_obj != event.type_obj)
    this.events = this.events.filter(iEvent => iEvent.id !== event.id || iEvent.longEventStatus != status || iEvent.type_obj != event.type_obj)
  }

  removeEventFromUI(event: CustomEvent)
  {
    this._eventlist = this._eventlist.filter(iEvent => iEvent.id !== event.id); // We delete the origin event
    this.events = this.events.filter(iEvent => iEvent.id !== event.id); // We delete the actual event for visual purpose
  }

  deleteEvent()
  { // Delete the laste selected event
    this.removeEventFromUI(this.selectedEvent);
    // this._eventlist.forEach(event_ =>
    // {
    //   if (event_.id == this.selectedEvent.id)
    //   {
    //     

    //   }
    // })

    this.service.deleteEvent(this.selectedEvent);

    this.refreshView(); //Refresh the view to call updateCalendarEvents()
  }

  changeViewType(newview: CalendarView)
  {
    this.view = newview;
    this.service.updateViewType(newview, this.ownerId);
    this.setViewPref(this.date ? this.date : new Date(), newview);

    setTimeout(() =>
    {
      if (this.agendaMode)
        this.service.getAgendaEvents(this.filters);
      else this.service.getEvents(this.filters);
    }, 500);

  }

  changeMonthDisplayType()
  {
    this.service.updateMonthDisplay(this.showCardDetails, this.ownerId);
  }

  //eventTimesChanged({ event, newStart, newEnd }): void
  eventTimesChanged(param: CalendarEventTimesChangedEvent<any>): void
  { // Update an event when it's being resizing or dragging.     
    let event = param.event as any;
    let newStart = param.newStart;
    let newEnd = param.newEnd;
    let oldId = event.id;
    let statusDelete = -1;

    for (let event_ of this._eventlist)
    {
      if (event_.id == event.id && event_.type_obj == event.type_obj && event_.longEventStatus == event.longEventStatus)
      {
        this.beforeDrag = {
          start: new Date(event.start),
          end: new Date(event.end),
          until: event_.until
        };


        let newEvent = new CustomEvent()
        newEvent.id = event_.id;
        newEvent.start = new Date(newStart);
        newEvent.end = new Date(newEnd);
        newEvent.title = event.title;
        newEvent.originColor = event_.originColor;
        newEvent.color = event.color;
        newEvent.until = event_.until;
        newEvent.longEventStatus = event_.longEventStatus;
        newEvent.resizable.afterEnd = event.resizable.afterEnd;
        newEvent.resizable.beforeStart = event.resizable.beforeStart;
        newEvent.draggable = event.draggable;
        newEvent.reccurenceId = event.reccurenceId;
        newEvent.customRule = event.customRule;
        newEvent.completed = event.completed;
        newEvent.temporary = event.temporary;
        newEvent.creatorId = event.creatorId;

        if (event_.rrule)
        {
          newEvent.rrule = Object.assign({}, event_.rrule);
        }
        else newEvent.rrule = null;

        newEvent.type_obj = event_.type_obj;
        newEvent.id_obj = event_.id_obj;
        newEvent.isRecurrent = event_.isRecurrent;
        newEvent.isDragged = event_.isDragged;

        if (event_.rrule)
        {
          if (event_.rrule.freq == RRule.WEEKLY)
          {
            newEvent.rrule.byweekday = [newEvent.start.getDay() == 0 ? 6 : newEvent.start.getDay() - 1];
            newEvent.rrule.bymonthday = null;
            newEvent.rrule.bymonth = null;
          }

          else if (event_.rrule.freq == RRule.MONTHLY)
          {
            newEvent.rrule.byweekday = null;
            newEvent.rrule.bymonthday = newEvent.start.getDate();
            newEvent.rrule.bymonth = null;
          }
        }


        let [result, collisionedEvents] = this.eventService.checkEvents(newEvent, this._eventlist, oldId)
        if (!result)
        { // If collision
          // this.eventService.deleteEvent(dragToSelectEvent.id);
          //this.refreshView();


          let result = this.createOverlapModal(newEvent, collisionedEvents as any[]);

          event_.until = newStart.getTime() <= event.start.getTime() ? new Date(newStart) : new Date(event.start);
          this.removeEventFromUI(event)
          this.service.deleteEvent(event);

        }
        else
        {
          this.beforeDrag = null;
          //event_.until = new Date(newEvent.start);
          //event_.until.setHours(0);
          // if (this.getRealDay(newStart) > this.getRealDay(event_.start)) // To prevent two recurrnces from overlapping each other, we need to take off 7 days to the end of the reccurence
          // {
          //   event_.until.setTime(event_.until.getTime() - 7 * this.DAYTIME);
          // }
          if (event_.until)
          {
            newEvent.until.setDate((newEvent.start.getDate() + (event_.until.getDate() - event_.start.getDate())) + 1)
            newEvent.until.setHours(23, 59, 59);
          }
          if (!event_.until)
          {
            event_.until = newStart.getTime() <= event.start.getTime() ? new Date(newStart) : new Date(event.start);
          }

          if (newEvent.longEventStatus > 0)
          {
            if (newEvent.longEventStatus == 1)
            {
              let otherEvent = this._eventlist.find(x => x.id == newEvent.id && x.longEventStatus == 2);

              let realEnd = otherEvent.end;
              newEvent.start = newStart;
              newEvent.end = realEnd;
              this.service.updateEvent(newEvent);
              let diff = Math.abs(new Date(realEnd).getTime() - new Date(newStart).getTime());
              let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
              if (diffDays > 2)
              {
                let firstEventStart = new Date(newStart);
                let firstEventEnd = new Date(new Date(newStart).setTime(firstEventStart.getTime() + 2 * 60 * 60 * 1000));
                newEvent.start = firstEventStart;
                newEvent.end = firstEventEnd;
              }
              else
              {
                newEvent.longEventStatus = 0;
                this.removeEventFromUIFromStatus(event, 2);
              }
              this.removeEventFromUIFromStatus(event, 1);
            }
            else
            {
              let otherEvent = this._eventlist.find(x => x.id == newEvent.id && x.longEventStatus == 1);
              let secondEventEnd = new Date(newEnd);
              let realStart = otherEvent.start;
              newEvent.start = realStart;
              newEvent.end = secondEventEnd;
              this.service.updateEvent(newEvent);
              let diff = Math.abs(new Date(secondEventEnd).getTime() - new Date(realStart).getTime());
              let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
              if (diffDays > 2)
              {
                let secondEventStart = new Date(new Date(newEnd).setTime(secondEventEnd.getTime() - 2 * 60 * 60 * 1000));
                newEvent.start = secondEventStart;
                newEvent.end = secondEventEnd;
              }
              else
              {
                newEvent.longEventStatus = 0;
                this.removeEventFromUIFromStatus(event, 1);
              }
              this.removeEventFromUIFromStatus(event, 2);
            }


          }
          else 
          {
            this.removeEventFromUI(event)
            this.service.updateEvent(newEvent);
          }

          this._eventlist.push(newEvent);
          this.events.push(newEvent)
          //this.eventService.updateEventSubject.next(null);
        }
        break;
      }
    }
    //this.refreshView();
  }

  getEventTooltipTime(event: CustomEvent)
  {
    return this.datepipe.transform(event.start, 'shortTime') + "-" + this.datepipe.transform(event.end, 'shortTime')
  }

  getResourceTitle(event: CustomEvent)
  {
    let res = this.service.resources.find(x => x.id == event.id_obj);
    if (res)
      return res.name;
    return "";
  }

  getResourceBGColor(event: CustomEvent)
  {
    if (this.showCreatorColor)
    {
      let owner = this.cds.userCollection.find(x => x.id == event.creatorId)
      if (owner && owner.color)
        return owner.color;
    }
    if (event.color)
      return event.color.secondary;
    let res = this.service.resources.find(x => x.id == event.id_obj);
    if (res)
      return res.color;
    return "black";
  }

  getResourceFontColor(event: CustomEvent)
  {
    if (event.color)
      return this.cs.getFontColor(event.color.secondary);
    let res = this.service.resources.find(x => x.id == event.id_obj);
    if (res)
      return this.cs.getFontColor(res.color.toString());
    return "white";
  }

  getLongEventStartDate(event: CustomEvent)
  {
    let startEvent = this._eventlist.find(x => x.id == event.id && x.longEventStatus == 1);
    if (startEvent)
    {
      let text = "";
      this.translate.get("PLANNING.LONG-EVENT-START", { v: startEvent.start.toLocaleString() }).subscribe(x => text = x);
      return text;
    }
    return "";
  }

  getLongEventEndDate(event: CustomEvent)
  {
    let endEvent = this._eventlist.find(x => x.id == event.id && x.longEventStatus == 2);
    if (endEvent)
    {
      let text = "";
      this.translate.get("PLANNING.LONG-EVENT-END", { v: endEvent.end.toLocaleString() }).subscribe(x => text = x);
      return text;
    }
    return "";
  }

  private newEvent(serverAdd: boolean, dragToSelectEvent)
  { //Create an event
    this.createEvent(serverAdd, dragToSelectEvent);
    this.refreshView();
  }

  private createEvent(serverAdd: boolean, dragToSelectEvent)
  { //Create an update an event in both service and display events in real time during the draaging creation movement
    this.events = [...this.events];
    if (serverAdd)
    {
      let [result, collisionedEvents] = this.eventService.checkEvents(dragToSelectEvent, this._eventlist)
      if (!result)
      { // If collision
        //this.refreshView();

        this.createOverlapModal(dragToSelectEvent, collisionedEvents as any[]);
      }
      else
      {
        this._eventlist = [...this._eventlist];
      }
    }
    //this.refreshView();
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void
  {

    if (!this.canDeleteAppointment())
      return;
    this.nzContextMenuService.create($event, menu);
    event.stopPropagation();
  }

  public editEvent(event, createException = false)
  { //Called when the user want to edit an event

    setTimeout(() =>
    {
      if (this.service.editEvent)
      {
        if (!createException)
        {
          let theevent = event ? event : this.selectedEvent
          let recevent = theevent.reccurenceId ? this._eventlist.find(x => x.rrule && x.reccurenceId == theevent.reccurenceId) : theevent;
          this.service.editEvent(recevent, createException);
        }
        else this.service.editEvent(event ? event : this.selectedEvent, createException);
      }
      else this.createComponentModal(null, null, event ? event : this.selectedEvent);
    }, 1);
  }

  public updateSelectedEvent(event)
  {
    this.selectedEvent = event ? event : this.selectedEvent;
  }

  public createOverlapModal(dragToSelectEvent: CustomEvent = null, collisionedEvents = [])
  {
    var titleTXT, cancelTXT, saveTXT, editTXT, saveConfirmTXT;

    this.translate.get("GLOBAL.CALENDAR.OVERLAP.CANCEL").subscribe(x => cancelTXT = x);
    this.translate.get("GLOBAL.CALENDAR.OVERLAP.SAVE").subscribe(x => saveTXT = x);
    this.translate.get("GLOBAL.CALENDAR.OVERLAP.EDIT").subscribe(x => editTXT = x);
    this.translate.get("GLOBAL.CALENDAR.OVERLAP.SAVE-CONFIRM").subscribe(x => saveConfirmTXT = x);
    this.translate.get("GLOBAL.CALENDAR.OVERLAP.TITLE").subscribe(x => titleTXT = x);

    let result: boolean = false;
    const modal = this.modalService.create({
      nzTitle: titleTXT,
      nzContent: OverlapComponent,
      nzMaskClosable: false,
      nzWidth: '900px',
      nzFooter: [
        {
          label: cancelTXT,
          onClick: () =>
          {
            if (this.beforeDrag != null)
            {
              let tempEvent = new CustomEvent(this.service.getListForCalendar(), dragToSelectEvent);
              tempEvent.start = new Date(this.beforeDrag.start);
              tempEvent.end = new Date(this.beforeDrag.end);
              tempEvent.creatorId = this.ownerId == 0 ? this.auth.connectedUser.id : this.ownerId;
              if (this.beforeDrag.until)
              {
                tempEvent.until = new Date();
                tempEvent.until.setDate(this.beforeDrag.until.getDate() + 1);
              }


              this.service.createEvent(tempEvent, this.filters, this.agendaMode);
              // this.eventService.newEvent(tempEvent);
              this.beforeDrag = null;
            }

            //this.service.deleteEvent(dragToSelectEvent);

            //this.refreshView();
            modal.destroy();
          }

        },
        {
          label: saveTXT,
          type: 'primary',
          onClick: (modalContent) =>
          {
            this.modalService.confirm({
              nzOnOk: () =>
              {
                this.eventService.overlapManagement(collisionedEvents, modalContent.resultEvents)
                if (modalContent.keepOld)
                {
                  result = true;
                }
                //this.refreshView();
                modal.destroy();
              }, nzTitle: editTXT, nzContent: saveConfirmTXT
            })
          }
        }
      ]
    });
    modal.componentInstance.dragToSelectEvent = dragToSelectEvent;
    modal.componentInstance.collisionedEvents = collisionedEvents;
    modal.componentInstance.service = this.service;

    return result;
  }

  public createDeleteConfirmModal()
  {
    var titleTXT, cancelTXT, deleteTXT
    this.translate.get("GLOBAL.CALENDAR.DELETE-CONFIRM").subscribe(x => titleTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.DELETE").subscribe(x => deleteTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.CANCEL").subscribe(x => cancelTXT = x);

    const modal = this.modalService.create({
      nzTitle: titleTXT,
      nzMaskClosable: false,
      nzWidth: '900px',
      nzFooter: [
        {
          label: cancelTXT,
          onClick: () => modal.destroy()
        },
        {
          label: deleteTXT,
          danger: true,
          onClick: () =>
          {
            this.deleteEvent();
            modal.destroy();
          }
        }
      ]
    });
  }


  public createComponentModal(startDate: Date, endDate: Date, event: CustomEvent = null)
  {
    var titleTXT, cancelTXT, saveTXT, createTXT, editTXT, saveConfirmTXT, addTXT, addConfirmTXT;

    this.translate.get("GLOBAL.CALENDAR.OVERLAP.CANCEL").subscribe(x => cancelTXT = x);
    this.translate.get("GLOBAL.CALENDAR.OVERLAP.SAVE").subscribe(x => saveTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.CREATE").subscribe(x => createTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.EDIT").subscribe(x => editTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.SAVE-CONFIRM").subscribe(x => saveConfirmTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.ADD").subscribe(x => addTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.ADD-CONFIRM").subscribe(x => addConfirmTXT = x);
    let createTask = "";
    this.translate.get("PLANNING.CREATE-TASK").subscribe(t => createTask = t);
    let createAppointment = "";
    this.translate.get("PLANNING.CREATE-APPOINTMENT").subscribe(t => createAppointment = t);

    if (event)
      this.translate.get("GLOBAL.CALENDAR.MODAL.EDIT-TITLE").subscribe(x => titleTXT = x);
    else if (this.agendaMode)
      this.translate.get("PLANNING.CREATE-APPOINTMENT").subscribe(x => titleTXT = x);
    else this.translate.get("GLOBAL.CALENDAR.MODAL.CREATE-TITLE").subscribe(x => titleTXT = x);

    let oldevent = event ? new CustomEvent() : null;
    let oldStart = null;
    let oldEnd = null;
    if (event)
    {
      let temp = this._eventlist.find(x => x.id == event.id);
      oldevent.id = temp.id;
      oldevent.id_obj = temp.id_obj;
      oldevent.start = temp.start;
      oldevent.end = temp.end;
      oldevent.rrule = temp.rrule;
      oldevent.completed = temp.completed;
      oldevent.temporary = temp.temporary;
      oldevent.allDay = temp.allDay;
      oldevent.creatorId = temp.creatorId;
      oldStart = event.start;
      oldEnd = event.end;
    }

    const modal = this.modalService.create({
      nzTitle: titleTXT,
      nzContent: EditEventComponent,
      nzMaskClosable: false,
      nzWidth: '90%',
      nzFooter: [
        {
          label: cancelTXT,
          onClick: () => 
          {
            this.events = this.events.filter(x => x.id != 0);
            this._eventlist = this._eventlist.filter(x => x.id != 0);
            this.eventService.updateEventSubject.next(null);
            modal.destroy()
          }
        },
        {
          label: event ? saveTXT : createTask,
          show: !this.agendaMode,
          type: 'primary',
          onClick: (modalContent) =>
          {
            this.createEventFunction(modalContent, event, oldevent, oldStart, oldEnd, modal, false);

          }
        },
        {
          label: event ? saveTXT : createAppointment,
          type: 'primary',
          onClick: (modalContent) =>
          {
            modalContent.event.id_obj = null;
            this.createEventFunction(modalContent, event, oldevent, oldStart, oldEnd, modal, true);

          }
        }
      ]
    });
    modal.componentInstance.event = event;
    modal.componentInstance.creatorId = this.ownerId == 0 ? this.auth.connectedUser.id : this.ownerId,
      modal.componentInstance.boards = this.service.boards;
    modal.componentInstance.startDate = startDate;
    modal.componentInstance.endDate = endDate;
    modal.componentInstance.list = this.service.getListForCalendar();
    modal.componentInstance.showRecurrence = this.useReccurence;
    modal.componentInstance.service = this.service;
    modal.componentInstance.events = this.events;
    modal.componentInstance.agendaMode = this.agendaMode;
    modal.componentInstance.selectedObjectId = this.selectedObjectId;
  }

  createEventFunction(modalContent: EditEventComponent, event: CustomEvent, oldevent: CustomEvent, oldStart: Date, oldEnd: Date, modal: any, agendaMode: boolean)
  {

    if (!modalContent.event.start || !modalContent.event.end || !modalContent.event.title)
      return;
    modalContent.event.until = modalContent.until;
    var editTXT, saveConfirmTXT, addTXT, addConfirmTXT;

    this.translate.get("GLOBAL.CALENDAR.MODAL.EDIT").subscribe(x => editTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.SAVE-CONFIRM").subscribe(x => saveConfirmTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.ADD").subscribe(x => addTXT = x);
    this.translate.get("GLOBAL.CALENDAR.MODAL.ADD-CONFIRM").subscribe(x => addConfirmTXT = x);

    if (!modalContent.event.isAdded)
    {
      if (!modalContent.event.isRecurrent)
        modalContent.event.rrule = null;
      this.modalService.confirm({
        nzOnOk: () =>
        {

          let result;
          let collisionedEvents;
          if (modalContent.event.isDragged)
          {
            [result, collisionedEvents] = this.eventService.checkEvents(modalContent.event, this._eventlist)
          }
          else
          {
            [result, collisionedEvents] = this.eventService.checkEvents(modalContent.event, this._eventlist)
          }
          if (modalContent.until)
            modalContent.event.until.setDate(modalContent.event.until.getDate() + 1)
          if (!result && this.showSuperpositionCheck)
          {

            this.createOverlapModal(modalContent.event, collisionedEvents);

          }
          else 
          {
            if (modalContent.event.start != oldStart || modalContent.event.end != oldEnd)
            {
              if (oldevent.until)
              {
                modalContent.event.until.setDate((modalContent.event.start.getDate() + (oldevent.until.getDate() - oldevent.start.getDate())) + 1)
              }
              this.service.createEvent(modalContent.event, this.filters, agendaMode);
              this.dayEvents.push(modalContent.event);
              if (!oldevent.until)
              {
                oldevent.until = modalContent.event.start.getTime() <= event.start.getTime() ? new Date(modalContent.event.start) : new Date(event.start);
                this.service.createEvent(oldevent, this.filters, agendaMode);
              }

              this.service.deleteEvent(oldevent);
            }
            else
            {
              if (modalContent.event.hasToBeDuplicated || oldevent.rrule)
              {
                oldevent.until = new Date(modalContent.event.start);
                oldevent.until.setDate(oldevent.until.getDate())
                this.service.createEvent(oldevent, this.filters, agendaMode);
              }
              this.service.deleteEvent(oldevent);
              this.service.createEvent(modalContent.event, this.filters, agendaMode);
              this.dayEvents.push(modalContent.event);
            }
          }
          this.eventService.updateEventSubject.next(null);
          modal.destroy();
        }, nzTitle: editTXT, nzContent: saveConfirmTXT,
        nzOnCancel: () =>
        {

          this.events = this.events.filter(x => x.id != 0);
          this._eventlist = this._eventlist.filter(x => x.id != 0);
          this.eventService.updateEventSubject.next(null);
        }
      })
    }
    else
    {

      if (!modalContent.event.isRecurrent)
        modalContent.event.rrule = null;
      if (modalContent.until)
        modalContent.event.until.setDate(modalContent.event.until.getDate() + 1)
      this.modalService.confirm({
        nzOnOk: async () =>
        {
          let [result, collisionedEvents] = this.eventService.checkEvents(modalContent.event, this._eventlist)
          if (!result && this.showSuperpositionCheck)
          {

            this.createOverlapModal(modalContent.event, collisionedEvents as any[]);
          }
          else
          {

            await this.service.createEvent(modalContent.event, this.filters, agendaMode);
            this.dayEvents.push(modalContent.event);
            this._eventlist.push(modalContent.event);
            let diff = Math.abs(new Date(modalContent.event.end).getTime() - new Date(modalContent.event.start).getTime());
            let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
            if (diffDays > 2)
            {
              let firstEventStart = new Date(modalContent.event.start);
              let firstEventEnd = new Date(new Date(modalContent.event.start).setTime(firstEventStart.getTime() + 2 * 60 * 60 * 1000));


              let secondEventEnd = new Date(modalContent.event.end);
              let secondEventStart = new Date(new Date(modalContent.event.end).setTime(secondEventEnd.getTime() - 2 * 60 * 60 * 1000));

              modalContent.event.start = firstEventStart;
              modalContent.event.longEventStatus = 1
              modalContent.event.end = firstEventEnd;
              let secondEvent: CustomEvent = {
                id: 0,
                id_obj: modalContent.event.id_obj,
                title: modalContent.event.title,
                start: secondEventStart,
                location: modalContent.event.location,
                end: secondEventEnd,
                completed: modalContent.event.completed,
                temporary: modalContent.event.temporary,
                originColor: modalContent.event.originColor,
                color: modalContent.event.color,
                resizable: { beforeStart: true, afterEnd: true },
                draggable: true,
                type_obj: modalContent.event.type_obj,
                isRecurrent: false,
                isDragged: false,
                rrule: modalContent.event.rrule,
                longEventStatus: 2,
                presence: modalContent.event.presence
              };
              setTimeout(() =>
              {
                secondEvent.id = modalContent.event.id;
              }, 3000);
              this._eventlist.push(secondEvent)
            }

          }

          this.eventService.updateEventSubject.next(null);
          //this.refreshView();
          modal.destroy();
        }, nzTitle: addTXT, nzContent: addConfirmTXT,
        nzOnCancel: () =>
        {


          this.events = this.events.filter(x => x.id != 0);
          this._eventlist = this._eventlist.filter(x => x.id != 0);
          this.eventService.updateEventSubject.next(null);
        }
      })
    }
  }

  updateCalendarEvents( // Update the list of events that are displayed, especially to get all the recurrences of a recuring event. Called everytime a the view is refreshed.
    viewRender:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ): void
  {

    if (
      !this.viewPeriod ||
      !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
      !moment(this.viewPeriod.end).isSame(viewRender.period.end) || this.needToRefresh
    )
    {

      this.needToRefresh = false;
      this.viewPeriod = viewRender.period;
      if (this.eventService.ressourceFilter.length > 0)
      {
        this.events = this._eventlist.filter(event => this.eventService.ressourceFilter.findIndex(x => x == event.id_obj) >= 0).filter(iEvent => iEvent.isRecurrent !== true); // We keep only non recurring events
        this.recurringEvents = this._eventlist.filter(event => this.eventService.ressourceFilter.findIndex(x => x == event.id_obj) >= 0).filter(iEvent => iEvent.isRecurrent == true); // We keep only recurring events
      }
      else
      {
        this.events = this._eventlist.filter(iEvent => iEvent.isRecurrent !== true); // We keep only non recurring events
        this.extractEvents();
      }
      this.recurringEvents.forEach(event =>
      { // Generate the new dates
        const rule: RRule = new RRule({
          ...event.rrule,
          dtstart: event.start,
          until: event.until != null ? new Date(event.until) : viewRender.period.end
        });
        const { id, title, color, originColor, isRecurrent, rrule, start, end, id_obj, resizable, draggable, isDragged, until, customRule } = event;
        rule.all().forEach(date =>
        { // For each new date, create an event with the same properties and the same id
          date.setHours(start.getHours());
          date.setMinutes(start.getMinutes());
          this.momentEnd = new Date(date);
          this.momentEnd.setDate(date.getDate() + (end.getDate() - start.getDate()))
          this.momentEnd.setHours(end.getHours());
          this.momentEnd.setMinutes(end.getMinutes());
          this.events.push({
            id,
            title,
            color,
            originColor,
            start: moment(date).toDate(),
            end: this.momentEnd,
            isRecurrent,
            rrule,
            id_obj,
            resizable,
            draggable,
            isDragged,
            until,
            customRule,
            reccurenceId: event.reccurenceId
          });
        });
      });

      this.events = this.events.filter(x => this.showEvent(x));

      this.cdr.detectChanges();
    }
  }

  // la fonction "updateCalendarEvents" est appeler quand on refresh l'interface
  public refreshView()
  {
    this.extractEvents();
    this.needToRefresh = true;

    this.refresh.next(null);
    this.cdr.detectChanges();
  }

  resetSelectedResource()
  {
    this.selectedObjectIterator = 0;
    this.selectedObjectId = 0;
    this.selectedObjectName = this.service.getListForCalendar()[this.selectedObjectIterator].name;
    this.selectedObjectPrimaryColor = this.service.getListForCalendar()[this.selectedObjectIterator].color as Color;
    this.selectedObjectSecondaryColor = new Color(this.selectedObjectPrimaryColor).darken(BORDER_DARKENING).toString();
    this.selectedObjectWrittenColor = new Color(this.selectedObjectPrimaryColor).darken(WRITTEN_DARKENING).toString()
  }

  onSelection(data: KanbanTreeSelectedInfos)
  {
    if (!data.list)
      return;
    this.selectedObjectIterator = this.service.getListForCalendar().findIndex(x => x.id == data.list.id);
    this.selectedObjectId = data.list.id;
    this.selectedObjectName = data.board.title == data.list.title ? data.board.title : data.board.title + " / " + data.list.title;
    this.selectedObjectPrimaryColor = this.service.getListForCalendar()[this.selectedObjectIterator].color as Color;
    this.selectedObjectSecondaryColor = new Color(this.selectedObjectPrimaryColor).darken(BORDER_DARKENING).toString();
    this.selectedObjectWrittenColor = new Color(this.selectedObjectPrimaryColor).darken(WRITTEN_DARKENING).toString()
  }

  selectResourceFromTree(originNode: any)
  {
    let idlist = parseInt(originNode.key.split("_")[1]);
    let tempIterator = -1;
    let objects = this.service.getListForCalendar();
    let object = null;
    for (let i = 0; i < objects.length; i++)
    {
      if (objects[i].id == idlist)
      {
        object = objects[i];
        tempIterator = i
      }
    }

    this.selectedObjectIterator = tempIterator;
    this.selectedObjectId = object.id;
    this.selectedObjectName = object.name;
    this.selectedObjectPrimaryColor = this.service.getListForCalendar()[this.selectedObjectIterator].color as Color;
    this.selectedObjectSecondaryColor = new Color(this.selectedObjectPrimaryColor).darken(BORDER_DARKENING).toString();
    this.selectedObjectWrittenColor = new Color(this.selectedObjectPrimaryColor).darken(WRITTEN_DARKENING).toString()
  }

  public chooseObject(object: any)
  {
    let tempIterator = -1;
    let objects = this.service.getListForCalendar();
    for (let i = 0; i < objects.length; i++)
    {
      if (objects[i].id == object.id)
      {
        tempIterator = i
      }
    }

    this.selectedObjectIterator = tempIterator;
    this.selectedObjectId = object.id;
    this.selectedObjectName = object.name;
    this.selectedObjectPrimaryColor = this.service.getListForCalendar()[this.selectedObjectIterator].color as Color;
    this.selectedObjectSecondaryColor = new Color(this.selectedObjectPrimaryColor).darken(BORDER_DARKENING).toString();
    this.selectedObjectWrittenColor = new Color(this.selectedObjectPrimaryColor).darken(WRITTEN_DARKENING).toString()
  }

  setViewPref(date: Date, view: any)
  {
    switch (view)
    {
      case "month":
        this.filters.start = new Date(date.getFullYear(), date.getMonth(), 0, 0, 0, 0, 0)
        this.filters.end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0, 0)
        break;
      case "week":
        this.filters.start = new Date(date.getFullYear(), date.getMonth(), (date.getDate() - date.getDay() + 1), 0, 0, 0, 0)
        this.filters.end = new Date(date.getFullYear(), date.getMonth(), (date.getDate() - date.getDay() + 1) + 7, 0, 0, 0, 0)
        break;
      case "day":
        this.filters.start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
        this.filters.end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0)
        break;
    }
  }

  async toggleAgendaMode()
  {
    if (this.agendaMode)
    {
      //this.planningService.eventsAreLoaded = false;
      this._eventlist = await this.service.getAgendaEvents(this.filters);
    }
    else
    {
      this._eventlist = await this.service.getEvents(this.filters);
      //this.eventService.updateEventSubject.next(null);
    }

    //this.service.updateFilterPreferences(this.filters).subscribe();

  }

  async updateAgendaMode()
  {
    await this.toggleAgendaMode();
    this.settingsService.setSettingsByName("planning_agendamode_" + this.ownerId, this.agendaMode ? "1" : "0");
    this.refreshView();
  }

  public manageRessources()
  {
    this.service.goToRessources();
  }

  hasRight(r: PlanningRights)
  {
    return (this.rights & r) > 0;
  }

  canCreateAppointment()
  {
    return (this.rights & PlanningRights.CreateAppointment) > 0;
  }

  canUpdateAppointment()
  {
    return (this.rights & PlanningRights.UpdateAppointment) > 0;
  }

  canDeleteAppointment()
  {
    return (this.rights & PlanningRights.DeleteAppointment) > 0;
  }

  async updateShowFinishedCards()
  {
    await this.toggleAgendaMode();
    this.service.updateFilterPreferences(this.filters).subscribe();
    this.refreshView();
  }

  showMemberFilter()
  {
    this.filterDrawer = "";
    let title = "";
    this.translate.get("KANBAN.ADD-CARD-OPTIONS-TITLE.MEMBERS").subscribe(x => title = x);
    this.drawerService.create({
      nzTitle: title,
      nzContent: this.templatemember,
      nzWidth: '300px'
    });
  }

  getUserForFilter()
  {
    let upper = this.filterDrawer.toUpperCase();
    return this.cds.userCollection.filter(x => !x.archived && this.tcs.contains((x.name + x.surname), upper));
  }

  async changeUserSelection(user: User)
  {
    event.stopImmediatePropagation();
    let labelInListIndex = this.filters.cardMembersIds.findIndex(x => x == user.id);
    if (labelInListIndex == -1)
    {
      this.filters.cardMembersIds.push(user.id);
    }
    else
    {
      this.filters.cardMembersIds.splice(labelInListIndex, 1);
    }
    await this.toggleAgendaMode();
    this.refreshView();
    this.service.updateFilterPreferences(this.filters).subscribe();
  }

  userIsSelected(user: User)
  {
    return this.filters.cardMembersIds.find(x => x == user.id) != null;
  }

  updateFilterFreeCards()
  {
    if (this.freecardIsFiltered)
      this.filters.listIds.push(0);
    else
    {
      let index = this.filters.listIds.findIndex(x => x == 0);
      if (index >= 0)
        this.filters.listIds.splice(index, 1);
    }
    this.eventService.ressourceFilter = this.filters.listIds;
    this.service.updateFilterPreferences(this.filters).subscribe();
    this.eventService.updateEventSubject.next(null);
  }

  onCheck(idcardList: number[])
  {
    this.filters.listIds = [...idcardList];
    this.eventService.ressourceFilter = this.filters.listIds;
    this.service.updateFilterPreferences(this.filters).subscribe();
    this.eventService.updateEventSubject.next(null);
  }

  updateShowCretorCards()
  {
    if (this.agendaMode)
      this.service.getAgendaEvents(this.filters);
    else
      this.service.getEvents(this.filters);
    this.settingsService.setSettingsByName("planning_showcreatorcolor", this.showCreatorColor ? "true" : "false");
  }

  initAppointmentInvitation(event: CustomEvent)
  {
    this.service.initAppointmentInvitation(event);
  }

}