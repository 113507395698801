import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MeetingInfos } from '../entities/meeting-infos';

@Component({
  selector: 'app-zoom-meeting',
  templateUrl: './zoom-meeting.component.html',
  styleUrls: ['./zoom-meeting.component.scss']
})
export class ZoomMeetingComponent implements OnInit
{

  constructor(private auth: AuthService) { }
  @Input() meeting: MeetingInfos;
  passwordVisible = false;
  name = "";
  password = "";

  ngOnInit()
  {
    if (this.meeting)
    {
      this.name = this.meeting.name;
      this.password = this.password;
    }
  }

  generatePassword()
  {
    let password = this.auth.generatePassword();
    this.password = password.slice(0, 10);
  }

}
