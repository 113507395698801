import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartySheetComponent } from './third-party-sheet.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KanbanCardEditModule } from '../../../../src/app/kanban/components/kanban-card-edit/kanban-card-edit.module';
import { AttachmentManagerModule } from '../../../../src/app/components/attachment-manager/attachment-manager.module';
import { PhysicalPersonRelationsModule } from '../../../../src/app/third-party/physical-person/components/physical-person-relations/physical-person-relations.module';
import { AssociateThirdPartyCredentialsModule } from '../../../../src/app/third-party/components/associate-third-party-credentials/associate-third-party-credentials.module';
import { LoadingModule } from '../../../../src/app/components/loading/loading.module';
import { ThirdPartyFilteredRelationModule } from '../../../../src/app/third-party/components/third-party-filtered-relation/third-party-filtered-relation.module';
import { ThirdPartySheetRoutingModule } from './third-party-sheet.routing.module';
import { InputFocusDirectiveModule } from '../../../../src/app/directives/input-focus-directive/input-focus-directive.module';
import { ThirdPartySheetEditIdentityModule } from '../third-party-sheet-edit-identity/third-party-sheet-edit-identity.module';
import { SiteEditModule } from '../../../../src/app/third-party/site/components/site-edit/site-edit.module';
import { KanbanBoardTreeModule } from '../../../../src/app/kanban/components/kanban-board-tree/kanban-board-tree.module';
import { PipesModule } from '../../../../src/app/pipes/pipes/pipes.module';
import { AddSecondarySiteModule } from '../../../../src/app/third-party/moral-person/components/add-secondary-site/add-secondary-site.module';
import { OpenEditMoralPersonModule } from '../../../../src/app/third-party/moral-person/components/open-edit-moral-person/open-edit-moral-person.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { KanbanCardEditModalModule } from '../../../../src/app/kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
@NgModule({
    imports: [
        CommonModule,
        ThirdPartySheetRoutingModule,
        FormsModule,
        NzGridModule,
        TranslateModule,
        NzFormModule,
        NzCardModule,
        NzAvatarModule,
        NzInputModule,
        NzIconModule,
        NzAutocompleteModule,
        NzUploadModule,
        NzToolTipModule,
        NzButtonModule,
        NzMenuModule,
        NzTreeModule,
        NzSelectModule,
        NzSpinModule,
        SiteEditModule,
        NzBadgeModule,
        KanbanBoardTreeModule,
        InputFocusDirectiveModule,
        KanbanCardEditModule,
        KanbanCardEditModalModule,
        AttachmentManagerModule,
        PhysicalPersonRelationsModule,
        AssociateThirdPartyCredentialsModule,
        ThirdPartySheetEditIdentityModule,
        LoadingModule,
        NzSwitchModule,
        ThirdPartyFilteredRelationModule,
        PipesModule,
        NzPopconfirmModule,
        AddSecondarySiteModule,
        OpenEditMoralPersonModule
    ],
    declarations: [ThirdPartySheetComponent],
    exports: [ThirdPartySheetComponent]
})
export class ThirdPartySheetModule { }
