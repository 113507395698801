import { Injectable } from '@angular/core';
import { IAppConfig } from '../entities/IAppConfig';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UrlApiService
{

  public _url: string;
  static settings: IAppConfig;
  constructor(private http: HttpClient) { }
  load()
  {
    UrlApiService.settings = { version: "1.1.1.1", apiConfig: { uriAPI: "fk u" } }
    const jsonfile = "./assets/settings.json?rand=" + Math.random();

    return new Promise<void>((resolve, reject) =>
    {
      firstValueFrom(this.http.get(jsonfile)).then((response: any) =>
      {
        UrlApiService.settings = response;
        resolve();

      }).catch((response: any) =>
      {
        reject("Could not load file " + jsonfile + " : " + response.message);
      });

    });
  }
}
