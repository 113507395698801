import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingChatComponent } from './floating-chat.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { DiscussionModule } from '../../../../src/app/chat/discussion/discussion.module';
import { HtmlSafeModule } from '../../../../src/app/pipes/html-safe/html-safe.module';
import { PipesModule } from '../../../../src/app/pipes/pipes/pipes.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatListModule,
    DiscussionModule,
    NzAvatarModule,
    NzDividerModule,
    NzIconModule,
    HtmlSafeModule,
    NzBadgeModule,
    NzEmptyModule,
    NzToolTipModule,
    NzDropDownModule,
    NzSelectModule,
    NzModalModule,
    NzButtonModule,
    NzInputModule,
    PipesModule
  ],
  declarations: [FloatingChatComponent],
  exports: [FloatingChatComponent]
})
export class FloatingChatModule { }