import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanLabelDrawerComponent } from './kanban-label-drawer.component';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'src/app/components/color-picker/color-picker.module';
import { TranslateModule } from '@ngx-translate/core';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { CreatorDisplayModule } from 'src/app/components/creator-display/creator-display.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    NzButtonModule,
    TranslateModule,
    NzIconModule,
    NzInputModule,
    NzCheckboxModule,
    NameUserPipeModule,
    CreatorDisplayModule
  ],
  declarations: [KanbanLabelDrawerComponent]
})
export class KanbanLabelDrawerModule { }
