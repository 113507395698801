import { Injectable } from '@angular/core';
import { UnbanParameter } from '../entities/UnbanParameter';
import { HttpClient } from '@angular/common/http';
import { CacheDataService } from 'src/app/services/cache-data.service';
import { UrlApiService } from 'src/app/services/url-api.service';
import { Scenario } from '../entities/Scenario/Scenario';
import { LabelValue } from 'src/app/entities/keyvalue';
import { ReccurenceParameter } from '../entities/Recurrence/ReccurenceParameter';

@Injectable()
export class IpSettingsService
{
    ipBlackListNbRequest;
    ipBlackListNbSec;
    ipUnbanAutoNb: UnbanParameter[];
    mailSecurity : string[];
    AllActions : string[];
    ScenarioActual : Scenario = new Scenario ();
    recurrence : ReccurenceParameter = new ReccurenceParameter();

    constructor(private http: HttpClient) { }

    getIPParameter()
    {
        let eachParameter: string[] = [];
        this.getBanParameterIp().subscribe(x =>
        {
            if (x)
            {
                if (x == "desactivated")
                {
                    this.ipBlackListNbRequest = 0;
                    this.ipBlackListNbSec = 0;
                }
                else
                {
                    eachParameter = x.split(",");
                    this.ipBlackListNbRequest = eachParameter[0];
                    this.ipBlackListNbSec = eachParameter[1];
                }
            }
            else
            {
                this.ipBlackListNbRequest = 50;
                this.ipBlackListNbSec = 500;
            }
        })
    }

    updateIpParameter()
    {
        if (this.ipBlackListNbRequest > 0 && this.ipBlackListNbRequest > 0)
            this.setBanParameterIp(this.ipBlackListNbRequest + "," + this.ipBlackListNbSec).subscribe();
        else
            this.setBanParameterIp("desactivated").subscribe();
    }

    getScenario()
    {
        return this.http.get<Scenario>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/scenario/");
    }

    setScenario(scenario : Scenario)
    {
        return this.http.put<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/scenario/", scenario)
    }

    initAllActions()
    {
        return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/scenario/actions/")
    }

    testAuto()
    {
        return this.http.get<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/test/")
    }

    getBanParameterIp()
    {
        return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/banParameter/");
    }

    setBanParameterIp(parameter : string)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/banParameter", parameter)
    }

    getMailParameter()
    {
        return this.http.get<LabelValue[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/unbanParameter/"); 
    }

    getMailSecurity()
    {
        return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/mailSecurity/");
    }

    setMailSecurity(parameter : string[])
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/mailSecurity/", parameter)
    }

    getReccurenceParameter()
    {
        return this.http.get<ReccurenceParameter>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/reccurenceParameter/");
    }

    setReccurenceParameter(reccurence : ReccurenceParameter)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/reccurenceParameter/", reccurence)
    }

    getMailDefault()
    {
        return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + "/api/scenario/defaultMail/");
    }


}
