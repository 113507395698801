import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from 'src/app/services/url-api.service';
import { KanbanCardWithThirdParties } from '../entities/card-with-third-party';
import { ThirdPartyWithRelations } from '../entities/third-party-with-relations';
import { ThirdPartySheetInfos } from '../entities/third-party-sheet-infos';
import { ThirdPartySheetUpdateInfos } from '../entities/update-third-party-sheet-infos';
import { KanbanCard } from 'src/app/kanban/entities/Card';
import { PhoneNumber } from 'src/app/third-party/entities/phone-number';

@Injectable({
    providedIn: 'root'
})
export class CallService
{

    constructor(private http: HttpClient,) { }

    creatCardWithThirdParty(cardWithThirdParty: KanbanCardWithThirdParties)
    {
        return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/with-third-party`, cardWithThirdParty)
    }

    createThirdPartyWithRelations(ThirdPartyWithRelations: ThirdPartyWithRelations)
    {
        return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/with-relations`, ThirdPartyWithRelations)
    }

    getThirdPartySheetInfo(idTp: string)
    {
        return this.http.get<ThirdPartySheetInfos>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idTp}/infos-sheet`)
    }

    getThirdPartyCards(idTp: number)
    {
        return this.http.get<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idTp}/cards/archived`)
    }

    updateThirdPartySheetInfo(idTp: number, tpinfo: ThirdPartySheetUpdateInfos)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idTp}/infos-sheet`, tpinfo)
    }

    updateThirdPartySheetInfoIdentity(idTp: number, tpinfo: ThirdPartySheetUpdateInfos)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idTp}/infos-sheet/identity`, tpinfo)
    }

    removeThirdPartyAvatar(idTp: number)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idTp}/remove-image`, null)
    }

    addPhonenumberToThirdParty(idtp: number, phoneNumber: PhoneNumber)
    {
        return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idtp}/phone-number`, phoneNumber)
    }
}
