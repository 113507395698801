// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep nz-upload {
  display: block;
}

:host ::ng-deep .ant-upload.ant-upload-drag {
  height: 180px;
}

.row {
  margin-top: 20px;
}

.col {
  text-align: left;
}

.italique {
  font-style: italic;
}

.warning {
  size: 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/SDICalendar/calendar/overlap/overlap.component.scss"],"names":[],"mappings":"AAAA;EAA4B,cAAA;AAE5B;;AADA;EAA8C,aAAA;AAK9C;;AAHA;EACI,gBAAA;AAMJ;;AAHA;EACI,gBAAA;AAMJ;;AAHA;EACI,kBAAA;AAMJ;;AAHA;EACI,UAAA;AAMJ","sourcesContent":[":host ::ng-deep nz-upload { display: block; }\r\n:host ::ng-deep .ant-upload.ant-upload-drag { height: 180px; }\r\n\r\n.row {\r\n    margin-top: 20px;\r\n}\r\n\r\n.col {\r\n    text-align: left;\r\n}\r\n\r\n.italique {\r\n    font-style: italic;\r\n}\r\n\r\n.warning {\r\n    size: 60px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
