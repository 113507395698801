import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanActionsManagerComponent } from './kanban-actions-manager.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ThirdPartySheetEditIdentityModule } from 'src/app/call-interface/third-party-sheet-edit-identity/third-party-sheet-edit-identity.module';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NameTeamPipeModule } from 'src/app/pipes/name-team-pipe/name-team-pipe.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NzFormModule,
    TranslateModule,
    NzInputModule,
    FormsModule,
    NzSelectModule,
    NzDatePickerModule,
    NzButtonModule,
    ThirdPartySheetEditIdentityModule,
    NzIconModule,
    NzToolTipModule,
    NameUserPipeModule,
    NameTeamPipeModule
  ],
  declarations: [KanbanActionsManagerComponent],
  exports: [KanbanActionsManagerComponent],
})
export class KanbanActionsManagerModule { }
