import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscussionComponent } from './discussion.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HtmlSafeModule } from 'src/app/pipes/html-safe/html-safe.module';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzDividerModule,
    TranslateModule,
    FormsModule,
    HtmlSafeModule,
    NzSpinModule,
    NzDropDownModule,
    NzPopoverModule,
    NzAvatarModule,
    PipesModule
  ],
  exports: [DiscussionComponent],
  declarations: [DiscussionComponent]
})
export class DiscussionModule { }
