export class AttachmentsEntities {
    id: number;
    fileName: string;
    crc: string;
    folderPath: string;
    size: number;
    createdUtc: Date;
    idCredentials: number;
    cred: boolean;
    thirdParty: boolean;
    board: boolean;
    card: boolean;
    mail: boolean;
    deleteTemplate: boolean = false;
}

