import { Pipe, PipeTransform } from '@angular/core';
import { KanbanService } from 'src/app/kanban/services/kanban.service';

@Pipe({
  name: 'kanban_user_filtered',
  pure: false
})
export class KanbanUserFilteredPipe implements PipeTransform
{

  constructor(public kbs: KanbanService) { }

  transform(value: number, filteredUser: number[]): boolean
  {
    return filteredUser.findIndex(x => x == value) >= 0;
  }

}
