import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { AuthService } from "../auth.service";
import { catchError, delay, timeout } from "rxjs/operators";
import { ErrorHandlerService } from "../error-handler/error-handler.service";
import { of, Observable } from "rxjs";
import { Router } from "@angular/router";
import { SiteBlockerService } from "../site-blocker.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{

  constructor(private auth: AuthService, private errorHandler: ErrorHandlerService, private siteBlockService: SiteBlockerService) { }

  /**
   * intercept all XHR request
   * @param request
   * @param next
   * @returns {Observable<A>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if (request.url.indexOf("/api/kanban") > -1 || request.url.indexOf("/attachment") > -1 || request.url.indexOf("/api/ipAddress") > -1 || request.url.indexOf("/api/logout") > -1 || request.url.indexOf("/api/messagerie/folder") > -1)
    {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + this.auth.Token.access_token),
        body: JSON.stringify(request.body)
      });
    }
    else if (request.url.indexOf("/api/user/keepalive") > -1)
    {
      return next.handle(request).pipe(timeout(30000), catchError((error, caught) =>
      {
        this.siteBlockService.serverIsJoinable = false;
        return of(error);
      }) as any).pipe(catchError((error, caught) =>
      {
        this.siteBlockService.serverIsJoinable = false;
        return of(error);
      }) as any)
    }
    else if (this.auth.Token != null)
    {
      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      if (request.url.indexOf("api.spotify.com") > -1)
      {
        // if (this.spotifyService.Token)
        // {
        //   request = request.clone({
        //     headers: request.headers.set('Authorization', 'Bearer ' + this.spotifyService.Token.access_token)
        //   });
        // }
        return next.handle(request).pipe(catchError((error, caught) =>
        {
          //intercept the respons error and displace it to the console

          this.errorHandler.handleErrorSpotify(error);
          return of(error);
        }) as any);
      }
      else
      {
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + this.auth.Token.access_token)
        });
      }

    }
    // if (request.url.indexOf("api/messagerie/account") >= 0)
    // {
    //   console.log("isthatit?");

    //   return next.handle(request);
    // }
    return next.handle(request).pipe(catchError((error, caught) =>
    {
      //intercept the respons error and displace it to the console
      this.errorHandler.handleError(error);
      return of(error);
    }) as any);
  }
}