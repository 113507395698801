import { User } from "../../user/model/user";
import { KanbanAttachment } from "src/app/kanban/entities/Attachment";
import { ThirdPartyInfos } from "./third-party-infos";
import { GroupCredentials } from "src/app/group-credentials/entities/group-credentials";

export class Credentials
{
    id: number = 0;
    name: string = "";
    description: string = "";
    dateCreation: Date;
    owner: User = new User();
    creator: User = new User();
    enabled: boolean;
    type: string = "";
    login: string = "";
    password: string = "";
    url: string = "";
    shared: boolean;
    dateRefresh: Date;
    isInGroup = false;
    notifyOnConsultation: boolean;
    notifyOnVisualisation: boolean;
    notifyOnModification: boolean;
    nbAttachments: number;
    attachments: KanbanAttachment[] = [];
    thirdPartyInfos: ThirdPartyInfos[] = [];
    groupCredentials: GroupCredentials[] = [];
}