// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep nz-upload {
  display: block;
}

:host ::ng-deep .ant-upload.ant-upload-drag {
  height: 180px;
}

.row {
  margin-top: 20px;
}

.col {
  text-align: left;
}

.italique {
  font-style: italic;
}

.recurrenceCheckbox {
  margin: 10%;
}`, "",{"version":3,"sources":["webpack://./src/app/SDICalendar/calendar/edit-reccurence/edit-reccurence.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[":host ::ng-deep nz-upload {\r\n  display: block;\r\n}\r\n\r\n:host ::ng-deep .ant-upload.ant-upload-drag {\r\n  height: 180px;\r\n}\r\n\r\n.row {\r\n  margin-top: 20px;\r\n}\r\n\r\n.col {\r\n  text-align: left;\r\n}\r\n\r\n.italique {\r\n  font-style: italic;\r\n}\r\n\r\n.recurrenceCheckbox {\r\n  margin: 10%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
