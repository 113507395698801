// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-title {
  font-size: 16px;
  font-weight: bold;
}

.example-handle {
  position: absolute;
  top: 0;
  right: 0;
  color: rgb(110, 110, 110);
  cursor: move;
  width: 24px;
  height: 24px;
}

.checklist-dragger {
  position: relative;
  background-color: rgb(240, 242, 245);
}`, "",{"version":3,"sources":["webpack://./src/app/kanban/components/kanban-card-planning/kanban-card-planning.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,oCAAA;AACF","sourcesContent":[".action-title {\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n}\r\n\r\n.example-handle {\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  color: rgb(110, 110, 110);\r\n  cursor: move;\r\n  width: 24px;\r\n  height: 24px;\r\n}\r\n\r\n.checklist-dragger {\r\n  position: relative;\r\n  background-color: rgb(240, 242, 245);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
