import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanningComponent } from './planning.component';
import { PlanningRoutingModule } from './planning.routing.module';
import { SDICalendarModule } from '../SDICalendar/SDICalendar.module';
import { ContentModule } from '../components/content/content.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { KanbanBoardTreeModule } from '../kanban/components/kanban-board-tree/kanban-board-tree.module';
import { KanbanModule } from '../kanban/kanban.module';
import { NameUserPipeModule } from '../pipes/name-user-pipe/name-user-pipe.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NameTeamPipeModule } from '../pipes/name-team-pipe/name-team-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    PlanningRoutingModule,
    SDICalendarModule,
    ContentModule,
    NzSpinModule,
    TranslateModule,
    FormsModule,
    NzAvatarModule,
    NzTabsModule,
    NzIconModule,
    NzPopoverModule,
    NzUploadModule,
    NzButtonModule,
    NzInputModule,
    NzSelectModule,
    NzFormModule,
    KanbanBoardTreeModule,
    NzCheckboxModule,
    NameTeamPipeModule,
    NameUserPipeModule
  ],
  declarations: [PlanningComponent],
  exports: [PlanningComponent]
})
export class PlanningModule { }
