import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KanbanManageTypeRelationComponent } from './kanban-manage-type-relation.component';

const appChildrenRoutes: Routes = [
  { path: '', component: KanbanManageTypeRelationComponent },
  
];

@NgModule({
    imports: [
        RouterModule.forChild(appChildrenRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class KanbanManageTypeRelationRoutingModule { }
