import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardEditModalComponent } from './kanban-card-edit-modal.component';
import { KanbanCardEditModule } from '../kanban-card-edit/kanban-card-edit.module';
import { KanbanCardPlanningModule } from '../kanban-card-planning/kanban-card-planning.module';

@NgModule({
  imports: [
    CommonModule,
    KanbanCardEditModule,
    KanbanCardPlanningModule
  ],
  declarations: [KanbanCardEditModalComponent],
  exports: [KanbanCardEditModalComponent]
})
export class KanbanCardEditModalModule { }
