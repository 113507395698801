// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-form-item {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.ant-form-item-label {
  white-space: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/app/form-manager/form-manager.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,kCAAA;EACA,gCAAA;AACJ;;AAEA;EACI,+BAAA;AACJ","sourcesContent":[".ant-form-item{\r\n    display: flex!important;\r\n    justify-content: center!important;\r\n    align-items: flex-end!important;\r\n}\r\n\r\n.ant-form-item-label{\r\n    white-space: inherit!important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
