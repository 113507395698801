// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-volume {
  display: inline-block;
  vertical-align: -0.8em;
  width: 75px;
}

.img-speaker {
  margin-left: 5px;
  cursor: pointer;
}

.muted-img-speaker {
  margin-left: 5px;
  cursor: pointer;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/player-sound-ajuster/player-sound-ajuster.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,sBAAA;EACA,WAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,UAAA;AACJ","sourcesContent":[".slider-volume{\r\n    display:inline-block;\r\n    vertical-align: -0.8em;\r\n    width: 75px;\r\n}\r\n\r\n.img-speaker{\r\n    margin-left: 5px;\r\n    cursor: pointer;\r\n}\r\n\r\n.muted-img-speaker{\r\n    margin-left: 5px;\r\n    cursor: pointer;\r\n    color:red;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
