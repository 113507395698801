import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysicalPersonRelationsComponent } from './physical-person-relations.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { ColorPickerModule } from '../../../../../../src/app/components/color-picker/color-picker.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CreatorDisplayModule } from '../../../../../../src/app/components/creator-display/creator-display.module';
import { AttachmentManagerModule } from '../../../../components/attachment-manager/attachment-manager.module';
import { HtmlEditorModule } from '../../../../components/html-editor/html-editor.module';
import { HtmlSafeModule } from '../../../../pipes/html-safe/html-safe.module';

@NgModule({
  imports: [
    CommonModule,
    NgxGraphModule,
    TranslateModule,
    AttachmentManagerModule,
    FormsModule,
    NzGridModule,
    NzSpinModule,
    NzSelectModule,
    NzInputModule,
    NzToolTipModule,
    HtmlSafeModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzPopoverModule,
    HtmlEditorModule,
    NzIconModule,
    ColorPickerModule,
    CreatorDisplayModule
  ],
  declarations: [PhysicalPersonRelationsComponent],
  exports: [PhysicalPersonRelationsComponent]
})
export class PhysicalPersonRelationsModule { }
