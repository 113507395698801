import { Injectable } from '@angular/core';
import { ChatService } from '../chat/chat.service';
import { ChatMessage } from '../chat/entities/chat-message';
import { ChatMessageRead } from '../chat/entities/chat-message-read';
import { ChatParam } from '../chat/entities/chat-param';
import { EmojiButton } from '@joeattardi/emoji-button';


@Injectable({
  providedIn: 'root'
})
export class QuillEditorService
{

  constructor(
    public chatS: ChatService,
  ) { }



  hasLoadedEmoji = false;

  picker = new EmojiButton({
    position: {
      right: '0',
      top: '0'
    },
    zIndex: 10010,
  });

  hasLoadedEmoji2 = false;
  picker2 = new EmojiButton({
    position: {
      right: '0',
      top: '0'
    },
    zIndex: 10010,
  });

  readMessage(chatMessage: ChatMessage)
  {
    let messageToRead: ChatMessageRead[] = []
    let message: ChatMessageRead = new ChatMessageRead()
    message.messageId = chatMessage.id
    message.userId = chatMessage.creatorId
    messageToRead.push(message)

    this.chatS.readMessages(messageToRead).subscribe()
  }

  sendMessage(message: string, param: ChatParam, scrollId: string)
  {
    if (!message || message == "<p><br/></p>")
      return
    param.readIdmessages = false
    if ((window.location.href.includes('/chat/discussion') && !param.isFloatingMessage) || (param.isFloatingMessage && param.isPrivateDiscussion))
    {
      this.sendPrivateMessage(message, param, scrollId);
      return
    }
    let chatMessage: ChatMessage = new ChatMessage
    chatMessage.message = message;
    chatMessage.remove = false;
    chatMessage.discussionId = param.paramId;

    if (this.chatS.messageToReply && this.chatS.messageToReply.id)
      chatMessage.replyMessage = this.chatS.messageToReply
    else
      chatMessage.replyMessage = null
    this.chatS.sendMessage(chatMessage).subscribe(x =>
    {
      this.chatS.messageToReply = new ChatMessage()
      this.chatS.sendMessageSub.next(x)

      // this.chatS.messageListChanges(x.id, param)
      setTimeout(() =>
      {
        this.chatS.updateScroll(scrollId, param)
      }, 100);
    })
  }

  sendPrivateMessage(messageS: string, param: ChatParam, scrollId: string)
  {
    let chatMessage: ChatMessage = new ChatMessage()
    chatMessage.message = messageS;
    chatMessage.remove = false;
    if (this.chatS.messageToReply && this.chatS.messageToReply.id)
      chatMessage.replyMessage = this.chatS.messageToReply
    else
      chatMessage.replyMessage = null
    chatMessage.receiverId = param.paramId

    this.chatS.sendPrivateMessage(chatMessage).subscribe(x =>
    {

      this.chatS.messageToReply = new ChatMessage()
      this.chatS.sendMessageSub.next(x)
      // this.chatS.messageListChanges(x.id, param)
      setTimeout(() =>
      {
        this.chatS.updateScroll(scrollId, param)
      }, 100);
    })
  }

  getDatas(quill)
  {
    let QuillDeltaToHtmlConvert = require('quill-delta-to-html').QuillDeltaToHtmlConverter;
    let deltaOps = quill.getContents();
    let cfg = { inlineStyles: true };

    let converter = new QuillDeltaToHtmlConvert(deltaOps["ops"], cfg);

    let htmll = converter.convert();

    quill.setText('');
    return htmll
  }

}


