import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendMailUserComponent } from './send-mail-user.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AvatarUserModule } from 'src/app/pipes/avatar-user/avatar-user.module';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    TranslateModule,
    FormsModule,
    NzSelectModule,
    NzAvatarModule,
    NzInputModule,
    NzIconModule,
    AvatarUserModule,
    NameUserPipeModule
  ],
  declarations: [SendMailUserComponent],
  exports: [SendMailUserComponent]
})
export class SendMailUserModule { }
