import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardTemplateInputManagerComponent } from './kanban-card-template-input-manager.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [
    CommonModule,
    NzFormModule,
    FormsModule,
    TranslateModule,
    NzInputModule
  ],
  declarations: [KanbanCardTemplateInputManagerComponent],
  exports: [KanbanCardTemplateInputManagerComponent]
})
export class KanbanCardTemplateInputManagerModule { }
