import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThirdPartyService } from '../../services/third-party.service';
import { CredentialsEdit } from '../../entities/credentials-edit';
import { TranslateService } from '@ngx-translate/core';
import { SeePasswordComponent } from '../../../../../src/app/credentials/components/see-password/see-password.component';
import { TextCompareService } from '../../../../../src/app/services/text-compare.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-associate-third-party-credentials',
  templateUrl: './associate-third-party-credentials.component.html',
  styleUrls: ['./associate-third-party-credentials.component.scss']
})
export class AssociateThirdPartyCredentialsComponent implements OnInit
{

  @Input() credentialsAssociated: CredentialsEdit[];
  @Output() credentialsAssociatedValueChange = new EventEmitter();

  @Input() credentialsNotAssociated: CredentialsEdit[];

  idselected: number;
  filterCredential: string = "";
  constructor(public tpService: ThirdPartyService, public translateService: TranslateService,
    public tcs: TextCompareService,
    private modalService: NzModalService) { }

  ngOnInit()
  {
    this.credentialsAssociated = this.credentialsAssociated.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
    this.credentialsNotAssociated = this.credentialsNotAssociated.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
  }

  getCredentialsAssociated()
  {
    let upperfilter = this.filterCredential.toUpperCase();
    return this.credentialsAssociated.filter(x => this.tcs.contains(x.name, upperfilter));
  }

  seePassword(cred: CredentialsEdit)
  {
    let title = "";
    this.translateService.get("CREDENTIALS.FORM.TITLES.SEE-PASSWORD", { v: cred.name }).subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: SeePasswordComponent,
      nzWidth: '600px',
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.idcred = cred.id;
    setTimeout(() =>
    {
      if (modal)
        modal.close();
    }, 120000);
  }

  associateCredential(id: number)
  {
    let index = this.credentialsNotAssociated.findIndex(x => x.id == id);
    if (index >= 0)
    {
      this.credentialsAssociated.push(this.credentialsNotAssociated[index]);
      this.credentialsNotAssociated.splice(index, 1);
      this.credentialsAssociated = this.credentialsAssociated.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
      this.credentialsNotAssociated = this.credentialsNotAssociated.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
      this.credentialsAssociatedValueChange.emit(this.credentialsAssociated);
    }
    setTimeout(() =>
    {
      this.idselected = null;
    }, 1);
  }

  dissociateCredential(id: number)
  {
    let index = this.credentialsAssociated.findIndex(x => x.id == id);
    if (index >= 0)
    {
      this.credentialsNotAssociated.push(this.credentialsAssociated[index]);
      this.credentialsAssociated.splice(index, 1);
      this.credentialsAssociated = this.credentialsAssociated.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
      this.credentialsNotAssociated = this.credentialsNotAssociated.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
      this.credentialsAssociatedValueChange.emit(this.credentialsAssociated);
    }
  }

}
