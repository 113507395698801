// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spotify-content-container {
  max-height: 600px;
  overflow: auto;
  background-color: white;
}

.spotify-menu-container {
  max-height: 500px;
  overflow: auto;
}

.image-playlist-menu {
  height: 30px;
  margin-right: 10px;
}

.fake-image-playlist-menu {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  display: inline-block;
}

.li-menu-spotify {
  padding: 0;
}

.search-bar-spotify {
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/spotify-content-interface/spotify-content-interface.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,cAAA;EACA,uBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,cAAA;AACJ;;AACA;EACI,YAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;AAGJ;;AADA;EACI,UAAA;AAIJ;;AAFA;EACI,mBAAA;AAKJ","sourcesContent":[".spotify-content-container{\r\n    max-height:600px;\r\n    overflow: auto;\r\n    background-color: white;\r\n}\r\n\r\n.spotify-menu-container{\r\n    max-height:500px;\r\n    overflow: auto;\r\n}\r\n.image-playlist-menu{\r\n    height:30px;\r\n    margin-right:10px;\r\n}\r\n.fake-image-playlist-menu{\r\n    height:30px;\r\n    width: 30px;\r\n    margin-right:10px;\r\n    display: inline-block;\r\n}\r\n.li-menu-spotify{\r\n    padding:0;\r\n}\r\n.search-bar-spotify{\r\n    margin-bottom:15px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
