import { Component, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';

import { ZoomMtg } from '@zoomus/websdk';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ZoomService } from './zoom.service';
import { MeetingInfos } from './entities/meeting-infos';
import { ActivatedRoute } from '@angular/router';
import { CacheDataService } from '../services/cache-data.service';
import { TextCompareService } from '../services/text-compare.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ZoomMeetingComponent } from './zoom-meeting/zoom-meeting.component';
import { MeetingMembers } from './entities/meeting-members';
import { ZoomMeetingSchedulerComponent } from './zoom-meeting-scheduler/zoom-meeting-scheduler.component';
import { Subscription } from 'rxjs';
import { ZoomMeetingParticipantsComponent } from './zoom-meeting-participants/zoom-meeting-participants.component';
import { cpuUsage } from 'process';
import { MeetingJoinedInfos } from './entities/meetng-joined-infos';
import { MeetingSearchParameters } from './entities/search-meeting-parameters';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { MeetingJoinInfos } from './entities/meeting-join-infos';
import { Category } from '../spotify/entities/Category';
import { TranslateService } from '@ngx-translate/core'

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
//ZoomMtg.prepareJssdk();
@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit
{

  signatureEndpoint = ''
  // apiKey = 'mT-nVjNzQEKY5CdmoTU9iA'
  // meetingNumber = '93310903497'
  // role = 1
  leaveUrl = 'http://localhost:4200/video'
  // userName = 'Angular'
  // userEmail = ''
  // passWord = 'toto'
  filterText = "";
  dataLoaded = false;
  isloading = "";
  filteredMeetings: MeetingInfos[] = [];
  filteredScheduledMeetings: MeetingInfos[] = [];
  @Input() idmeeting: number;
  inviteename = "";
  inviteemail = "";
  token = "";
  searchParameters: MeetingSearchParameters = new MeetingSearchParameters();
  showsearch = false;
  updatemeetings: Subscription;
  showClosedMeetings = false;
  @ViewChild('startMeetingEndDatePicker') startDatePicker!: NzDatePickerComponent;
  @ViewChild('endMeetingEndDatePicker') endDatePicker!: NzDatePickerComponent;

  constructor(public httpClient: HttpClient,
    private route: ActivatedRoute,
    public auth: AuthService,
    public zm: ZoomService,
    private translateService: TranslateService,
    private modalService: NzModalService,
    private tcs: TextCompareService,
    private cds: CacheDataService,
    @Inject(DOCUMENT) document)
  {

  }

  ngOnInit()
  {
    this.updatemeetings = this.zm.updateMeetingObs.subscribe(() =>
    {
      this.searchByText();
    })
    this.leaveUrl = window.location.origin + "/video";
    // var rootZoom = document.getElementById('zmmtg-root');
    // var container = document.getElementById('visio-container');
    // container.appendChild(rootZoom);
    const id = + this.route.snapshot.params.idMeeting;
    console.log("id = " + id)
    console.log("idmeeting = " + this.idmeeting)
    console.log(window.location.href)
    if (this.idmeeting)
    {
      this.token = window.location.href.split("=")[1];
    }

    if (id)
    {

      this.getSignature(id);
    }
    else
    {
      this.zm.searchMeetings(this.searchParameters).subscribe(meetings =>
      {
        if (this.searchParameters.searchmeeting)
          this.cds.scheduledMeetingCollection = meetings;
        else this.cds.meetingCollection = meetings;
        this.searchByText();
        this.dataLoaded = true;
        this.searchParameters.start += this.searchParameters.number;
      })
    }
  }

  loadMeetings(ismeeting: boolean)
  {
    this.searchParameters.searchmeeting = ismeeting;
    this.dataLoaded = false;
    this.searchParameters.start = 0;
    this.zm.searchMeetings(this.searchParameters).subscribe(meetings =>
    {
      if (this.searchParameters.searchmeeting)
        this.cds.scheduledMeetingCollection = meetings;
      else this.cds.meetingCollection = meetings;
      this.searchByText();
      this.dataLoaded = true;
      this.searchParameters.start += this.searchParameters.number;
    })
  }

  isFinish = false;
  onScrollDown(ev)
  {
    if (this.isFinish)
      return;

    this.zm.searchMeetings(this.searchParameters).subscribe(meetings =>
    {
      if (meetings.length < this.searchParameters.number)
        this.isFinish = true;
      meetings.forEach(y =>
      {
        if (this.searchParameters.searchmeeting)
          this.cds.scheduledMeetingCollection.push(y);
        else this.cds.meetingCollection.push(y);
      })

      this.searchByText();
      this.dataLoaded = true;
    })

    this.searchParameters.start += this.searchParameters.number;
  }

  changeClosedMeetingView()
  {
    this.dataLoaded = false;
    if (this.showClosedMeetings)
    {
      this.zm.getAllMeetings().subscribe(meetings =>
      {
        this.cds.meetingCollection = meetings.filter(x => !x.startDate && !x.duration);
        this.cds.scheduledMeetingCollection = meetings.filter(x => x.startDate && x.duration);
        this.searchByText();
        this.dataLoaded = true;
      })
    }
    else 
    {
      this.zm.getMeetings().subscribe(meetings =>
      {
        this.cds.meetingCollection = meetings.filter(x => !x.startDate && !x.duration);
        this.cds.scheduledMeetingCollection = meetings.filter(x => x.startDate && x.duration);
        this.searchByText();
        this.dataLoaded = true;
      })
    }
  }

  meetingCanStart(meetingInfos: MeetingInfos)
  {
    if (!meetingInfos.startDate || meetingInfos.status == 1)
      return true;
    if (meetingInfos.status == 3 || meetingInfos.status == 2)
      return false;
    var time = new Date(meetingInfos.startDate).getTime() - Date.now();
    if (time < 900000)
    {
      return true;
    }

    return false;
  }

  searchByText()
  {
    this.filteredMeetings = [...this.cds.meetingCollection];
    this.filteredScheduledMeetings = [...this.cds.scheduledMeetingCollection];
  }

  @HostListener('window:beforeunload')
  doSomething()
  {
    this.ngOnDestroy();
  }

  isAllowedToParticipate(meetingInfos: MeetingInfos)
  {
    if (!meetingInfos.startDate && this.auth.Token)
      return true;
    console.log(this.token)
    if (this.token)
    {
      for (let pp of meetingInfos.participants.camelinePhysicalPersonIds)
      {
        if (pp.token == this.token)
        {
          this.inviteemail = pp.mail;
          return true;
        }

      }

      for (let pp of meetingInfos.participants.externalPersonMails)
      {
        if (pp.token == this.token)
        {
          this.inviteemail = pp.mail;
          return true;
        }
      }
    }
    else if (this.auth.connectedUser)
    {
      for (let iduser of meetingInfos.participants.camelineUserIds)
      {
        if (iduser == this.auth.connectedUser.id)
          return true;
      }

      for (let idteam of meetingInfos.participants.camelineTeamIds)
      {
        let team = this.cds.teamsCollection.find(x => x.id == idteam);
        if (team && team.members.indexOf(this.auth.connectedUser.id) >= 0)
          return true;
      }
    }
    return false;
  }

  getSignature(meetingId)
  {
    console.log("get signature")
    this.isloading = meetingId;
    this.zm.getMeetingInfos(meetingId).subscribe(meetingInfos =>
    {
      if (this.isAllowedToParticipate(meetingInfos))
      {
        console.log("isallowed")
        let role = "1";
        if (meetingInfos.hostId)
          role = ((!this.auth.Token || meetingInfos.hostId != this.auth.connectedUser.id) ? 0 : 1) + ""
        this.zm.getSignature(meetingInfos.id, role).subscribe(sign =>
        {
          console.log(sign)
          console.log(meetingInfos.id)
          this.startMeeting(meetingInfos, sign)
        })
        // meetingInfos.signature = ZoomMtg.generateSignature({
        //   meetingNumber: meetingInfos.id,
        //   apiKey: meetingInfos.apiKey,
        //   apiSecret: meetingInfos.apiSecret,
        //   role: role,
        //   //role: "1",
        //   success: function (res)
        //   {
        //     console.log(res.result);

        //   },
        // });

      }
    })
  }

  createMeeting()
  {
    let title = "";
    this.translateService.get("ZOOM.ROOM.CREATE").subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CREATE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ZoomMeetingComponent,
      nzWidth: '800px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          if (!result.name)
            return;
          let meetingInfos = new MeetingInfos();
          meetingInfos.name = result.name;
          meetingInfos.password = this.auth.generatePassword().slice(0, 10);
          this.zm.createMeeting(meetingInfos).subscribe(mi =>
          {
            this.cds.meetingCollection.push(mi);
            this.searchByText();
          })
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
  }

  createScheduledMeeting()
  {
    let title = "";
    this.translateService.get("ZOOM.MEETING.CREATE").subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CREATE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ZoomMeetingSchedulerComponent,
      nzWidth: '90%',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          if (!result.name
            || !result.startDate
            || (result.participants.camelineUserIds.length == 0 && result.participants.camelineTeamIds.length == 0 && result.participants.externalPersonMails.length == 0 && result.participants.camelinePhysicalPersonIds.length == 0)
            || result.duration <= 0)
            return;
          let meetingInfos = new MeetingInfos();
          meetingInfos.name = result.name;
          meetingInfos.password = this.auth.generatePassword().slice(0, 10);
          meetingInfos.startDate = result.startDate;
          meetingInfos.duration = result.duration;
          meetingInfos.participants = result.participants;
          meetingInfos.reminders = result.reminders;
          meetingInfos.hostId = result.hostid;
          this.zm.createMeeting(meetingInfos).subscribe(mi =>
          {
          })
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
  }

  getParticipantsInfos(meetingid: string)
  {
    let title = "";
    this.translateService.get("ZOOM.MEETING.SEE-PARTICIPANTS").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ZoomMeetingParticipantsComponent,
      nzWidth: '90%',
      nzMaskClosable: false,
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.meetingid = meetingid;
  }

  deleteMeeting(meetingId: string)
  {
    this.zm.deleteMeeting(meetingId).subscribe(() =>
    {
      let index = this.cds.meetingCollection.findIndex(x => x.id == meetingId);
      if (index >= 0)
        this.cds.meetingCollection.splice(index, 1);
      else
      {
        index = this.cds.scheduledMeetingCollection.findIndex(x => x.id == meetingId);
        if (index >= 0)
          this.cds.scheduledMeetingCollection.splice(index, 1);
      }
      this.searchByText();

    })
  }

  cancel() { }

  openMettingAnotherWindow(meetingId)
  {
    window.open(window.location.origin + "/video/meetings/" + meetingId);
  }

  startMeeting(meetingInfos: MeetingInfos, sign: MeetingJoinInfos)
  {
    document.getElementById('zmmtg-root').style.display = 'block'
    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      isSupportChat: false,
      disableRecord: false,
      disablePreview: true,
      inviteUrlFormat: window.location.origin + "/video/meetings/" + meetingInfos.id,
      success: (success) =>
      {
        console.log(success)
        let name = this.auth.connectedUser ? this.auth.connectedUser.name + " " + this.auth.connectedUser.surname : this.inviteename;
        console.log(this.auth.connectedUser)
        ZoomMtg.join({
          signature: sign.signature,
          meetingNumber: meetingInfos.id,
          userName: name,
          customerKey: this.auth.connectedUser ? this.auth.connectedUser.mail : this.inviteemail,
          sdkKey: sign.apiKey,
          userEmail: this.auth.connectedUser ? this.auth.connectedUser.mail : this.inviteemail,
          passWord: meetingInfos.password,
          success: (success2) =>
          {
            this.zm.activeMeeting = meetingInfos;
            if (meetingInfos.status == 0)
              this.zm.startMeeting(meetingInfos.id);
            meetingInfos.status = 1;
            this.isloading = "";
            console.log("RESULT")
            console.log(success2)

            ZoomMtg.getCurrentUser({
              success: (toto) =>
              {
                let joininfos = new MeetingJoinedInfos();
                joininfos.userid = toto.result.currentUser.userId;
                joininfos.displayname = toto.result.currentUser.userName;
                joininfos.mail = this.auth.connectedUser ? this.auth.connectedUser.mail : this.inviteemail;
                this.zm.joinMeeting(meetingInfos.id, joininfos).subscribe();
                console.log(toto)
              }
            })
          },
          error: (error) =>
          {
            console.log(error)
          }
        })

      },
      error: (error) =>
      {
        console.log(error)
      }
    })

    ZoomMtg.inMeetingServiceListener("onUserJoin", function (data)
    {
      console.log("User Joined : ")
      console.log(data)
    })
    // ZoomMtg.inMeetingServiceListener("onUserLeave", function (data)
    // {
    //   this.zm.getMeetingInfos(meetingInfos.id).subscribe();
    //   console.log(data)
    // })
  }

  ngOnDestroy()
  {
    if (this.updatemeetings)
      this.updatemeetings.unsubscribe();
    if (this.zm.activeMeeting)
    {
      if (this.zm.activeMeeting.hostId == this.auth.connectedUser.id)
      {
        ZoomMtg.endMeeting({
          success: (success) =>
          {
            this.zm.stopMeeting(this.zm.activeMeeting.id)
            console.log("left the room");
          },
          error: (error) =>
          {
            console.log(error)
          }
        })
      }
      else
      {
        ZoomMtg.leaveMeeting({
          success: (success) =>
          {
            console.log("left the room");
          },
          error: (error) =>
          {
            console.log(error)
          }
        })
      }

      try
      {
        document.getElementById('zmmtg-root').style.display = 'none'
      }
      catch (e) { }
      this.zm.activeMeeting = null;
    }

  }

  handleStartOpenChange(open: boolean, start: boolean): void
  {
    if (!open)
    {
      if (start)
        this.startDatePicker.open();
      else this.endDatePicker.open();
    }
  }

}
