// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gutter-example {
  margin: 10px;
}

.label-style {
  font-weight: bold;
  text-align: right;
}

.clipboard-button {
  cursor: pointer;
  margin-left: 15px;
}

.value-style {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/credentials/components/see-password/see-password.component.scss"],"names":[],"mappings":"AAAA;EACA,YAAA;AACA;;AACA;EACA,iBAAA;EACA,iBAAA;AAEA;;AACA;EACI,eAAA;EACA,iBAAA;AAEJ;;AAAA;EACI,qBAAA;AAGJ","sourcesContent":[".gutter-example{\r\nmargin:10px;\r\n}\r\n.label-style{\r\nfont-weight: bold;\r\ntext-align: right;\r\n}\r\n\r\n.clipboard-button{\r\n    cursor: pointer;\r\n    margin-left: 15px;\r\n}\r\n.value-style{\r\n    display: inline-block;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
