import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagePlaylistTracksSpotifyComponent } from './manage-playlist-tracks-spotify.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { HeaderPlaylistSpotifyModule } from '../header-playlist-spotify/header-playlist-spotify.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCardModule } from 'ng-zorro-antd/card';

@NgModule({
  imports: [
    CommonModule,
    NzTableModule,
    NzButtonModule,
    TranslateModule,
    NzToolTipModule,
    NzCardModule,
    FormsModule,
    HeaderPlaylistSpotifyModule
  ],
  declarations: [ManagePlaylistTracksSpotifyComponent],
  exports: [ManagePlaylistTracksSpotifyComponent]
})
export class ManagePlaylistTracksSpotifyModule { }
