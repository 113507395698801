import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HubConnection } from '@microsoft/signalr';
import { AuthService } from '../auth.service';
import { CacheDataService } from '../cache-data.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ZoomService } from 'src/app/zoom/zoom.service';
import { MeetingInfos } from 'src/app/zoom/entities/meeting-infos';

@Injectable({
  providedIn: 'root'
})
export class VideoNotificationService
{

  constructor(private authService: AuthService,
    private translateService: TranslateService,
    private message: NzMessageService,
    private zms: ZoomService,
    private cds: CacheDataService
  ) { }

  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('WarningCloseMeeting', (meetingid: string) =>
    {
      console.log(`meeting : ${meetingid} status 2 !!`)
      let meeting = this.cds.scheduledMeetingCollection.find(x => x.id == meetingid);
      if (meeting)
      {
        meeting.status = 2;

        this.translateService.get("ZOOM.MEETING.MEETING-END-OVER", { v: new Date(new Date(meeting.startDate).getTime() + meeting.duration * 60 * 1000) }).subscribe(text =>
        {
          this.message.success(text, {
            nzDuration: 10000
          });
        })
        this.zms.updateMeetingObs.next(null);
      }

      //appeler une modal pour changer la date de fin du meeting
    });

    _hubConnection.on('VideoMeetingStarted', (meetingid: string) =>
    {
      let index = this.cds.scheduledMeetingCollection.findIndex(x => x.id == meetingid);
      if (index >= 0)
        this.cds.scheduledMeetingCollection[index].status = 1;
      else 
      {
        index = this.cds.meetingCollection.findIndex(x => x.id == meetingid);
        if (index >= 0)
          this.cds.meetingCollection[index].status = 1;
      }
      this.zms.updateMeetingObs.next(null);

    });

    _hubConnection.on('VideoMeetingEnded', (meetingid: string) =>
    {
      let index = this.cds.scheduledMeetingCollection.findIndex(x => x.id == meetingid);
      if (index >= 0)
        this.cds.scheduledMeetingCollection.splice(index, 1);
      else 
      {
        index = this.cds.meetingCollection.findIndex(x => x.id == meetingid);
        if (index >= 0)
          this.cds.meetingCollection.splice(index, 1);
      }
      this.zms.updateMeetingObs.next(null);

    });

    _hubConnection.on('VideoMeetingCreated', (meeting: MeetingInfos) =>
    {
      if (meeting.startDate)
      {
        let index = this.cds.scheduledMeetingCollection.findIndex(x => x.id == meeting.id);
        if (index < 0)
          this.cds.scheduledMeetingCollection.push(meeting)
      }
      else 
      {
        let index = this.cds.meetingCollection.findIndex(x => x.id == meeting.id);
        if (index < 0)
          this.cds.meetingCollection.push(meeting)
      }

      this.zms.updateMeetingObs.next(null);

    });

    _hubConnection.on('VideoMeetingDeleted', (meetingid: string) =>
    {
      let index = this.cds.scheduledMeetingCollection.findIndex(x => x.id == meetingid);
      if (index >= 0)
        this.cds.scheduledMeetingCollection.splice(index, 1);
      else 
      {
        index = this.cds.meetingCollection.findIndex(x => x.id == meetingid);
        if (index >= 0)
          this.cds.meetingCollection.splice(index, 1);
      }
      this.zms.updateMeetingObs.next(null);

    });

    _hubConnection.on('CloseMeeting', (meetingid: string) =>
    {
      let index = this.cds.scheduledMeetingCollection.findIndex(x => x.id == meetingid);
      if (index >= 0)
        this.cds.scheduledMeetingCollection.splice(index, 1);
      else 
      {
        index = this.cds.meetingCollection.findIndex(x => x.id == meetingid);
        if (index >= 0)
          this.cds.meetingCollection.splice(index, 1);
      }
      this.zms.updateMeetingObs.next(null);

    });
  }
}
