// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-header {
  margin-right: 15px;
}

.flag-lang-home {
  margin: 5px;
  width: 30px;
}

.color-sample {
  width: 50px;
  height: 30px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/reservation/components/resources/resources.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACE;EACE,WAAA;EACA,WAAA;AAEJ;;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAEJ","sourcesContent":[".button-header {\r\n    margin-right: 15px;\r\n  }\r\n  .flag-lang-home{\r\n    margin: 5px;\r\n    width: 30px;\r\n  }\r\n\r\n  .color-sample{\r\n    width: 50px;\r\n    height: 30px;\r\n    border-radius: 5px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
