import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../../src/app/pipes/pipes/pipes.module';
import { NameUserPipeModule } from '../../../../src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
  imports: [
    CommonModule,
    NzToolTipModule,
    TranslateModule,
    NzMenuModule,
    RouterModule,
    NzBadgeModule,
    NzPopoverModule,
    PipesModule,
    NameUserPipeModule,
    NzDropDownModule
  ],
  declarations: [MenuComponent],
  exports: [MenuComponent]
})
export class MenuModule { }
