import { Component, Input, OnInit } from '@angular/core';
import { CalendarOccurence } from '../../../SDICalendar/calendar/entities/occurence';
import { KanbanBoard } from '../../entities/Board';
import { KanbanCard } from '../../entities/Card';
import { KanbanList } from '../../entities/List';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-card-edit-modal',
  templateUrl: './kanban-card-edit-modal.component.html',
  styleUrls: ['./kanban-card-edit-modal.component.scss']
})
export class KanbanCardEditModalComponent implements OnInit
{

  constructor(public kbs: KanbanService) { }
  @Input() card: KanbanCard = new KanbanCard();
  @Input() board: KanbanBoard = new KanbanBoard();
  @Input() list: KanbanList = new KanbanList();
  @Input() loaddata = false;

  ngOnInit()
  {

  }

}
