import { Component, OnInit } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';
import { Track } from '../../entities/Track';
import { Device } from '../../entities/Device';
declare var spotifyPlayerID;
declare var playerPaused: boolean;
@Component({
  selector: 'app-spotify-player',
  templateUrl: './spotify-player.component.html',
  styleUrls: ['./spotify-player.component.scss']
})
export class SpotifyPlayerComponent implements OnInit
{

  shuffleState = false;
  repeatState = "off";
  progress = 0;
  progress_ms = 0;
  currentlyPlayingTrack: Track = new Track();
  availableDevices: Device[] = [];
  selectedDeviceId = "";
  constructor(public spotifyService: SpotifyService) { }

  ngOnInit()
  {
    this.scanForDevices();
    this.spotifyService.getUserDevices().subscribe(x =>
    {
      this.availableDevices = x.devices;
      let deviceid = localStorage.getItem("spotify_activedevice");
      if (this.availableDevices.find(x => x.id == deviceid) != null)
      {
        spotifyPlayerID = deviceid;
        this.selectedDeviceId = deviceid;
      }
      else this.selectedDeviceId = spotifyPlayerID;

      this.spotifyService.setDeviceAsActive(spotifyPlayerID).subscribe(z =>
      {
        setTimeout(() =>
        {
          this.spotifyService.getCurrentPlaybackInfo().subscribe(x =>
          {
            this.currentlyPlayingTrack = x.item;
            this.spotifyService.runningTrack = x.item;
            this.progress_ms = x.progress_ms;
            playerPaused = !x.is_playing;
            this.progress = (this.progress_ms / this.currentlyPlayingTrack.duration_ms) * 100;
            window.setInterval(() => { this.estimateSongProgress() }, 1000);
            window.setInterval(() => { this.calculateSongProgress() }, 3000);
          });
        }, 200);
      });
    });
  }

  nextSong()
  {
    this.spotifyService.nextSong().subscribe(x =>
    {
      setTimeout(() =>
      {
        this.calculateSongProgress()
      }, 200);
    });
  }

  previousSong()
  {
    this.spotifyService.previousSong().subscribe(x =>
    {
      setTimeout(() =>
      {
        this.calculateSongProgress()
      }, 200);
    });
  }

  scanForDevices()
  {
    this.spotifyService.getUserDevices().subscribe(x => this.availableDevices = x.devices);
  }

  setDevice(device: Device)
  {
    this.spotifyService.setDeviceAsActive(device.id).subscribe(x =>
    {
      spotifyPlayerID = device.id;
      this.selectedDeviceId = device.id;
      localStorage.setItem("spotify_activedevice", device.id);
    });
  }

  isCurrentlyPlaying()
  {
    return !playerPaused;
  }

  calculateSongProgress()
  {
    this.spotifyService.getCurrentPlaybackInfo().subscribe(x =>
    {
      if (x != null)
      {
        this.spotifyService.runningTrack = x.item;
        this.currentlyPlayingTrack = x.item;
        this.progress_ms = x.progress_ms;
        playerPaused = !x.is_playing;
        this.progress = (this.progress_ms / this.currentlyPlayingTrack.duration_ms) * 100;
      }
    });
  }

  estimateSongProgress()
  {
    if (playerPaused)
      return;
    this.progress_ms += 1000;
    if (this.progress_ms > this.currentlyPlayingTrack.duration_ms)
      this.calculateSongProgress();
    else this.progress = (this.progress_ms / this.currentlyPlayingTrack.duration_ms) * 100;
  }

  ChangeShuffleState()
  {
    this.shuffleState = !this.shuffleState;
    this.spotifyService.setShuffle(this.shuffleState).subscribe()
  }

  ChangeRepeatState()
  {
    switch(this.repeatState)
    {
      case "off":
      this.repeatState = "context";
      break;
      case "context":
      this.repeatState = "track";
      break;
      case "track":
      this.repeatState = "off";
      break;
   }
    this.spotifyService.setRepeatState(this.repeatState).subscribe()
  }

  ChangePlayState()
  {
    if (!playerPaused)
    {
      this.spotifyService.pauseSong(this.currentlyPlayingTrack);
    }
    else 
    {
      this.spotifyService.playSong(this.currentlyPlayingTrack);
    }
    playerPaused = !playerPaused;
  }
}
