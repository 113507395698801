import { Component, OnInit, Input } from '@angular/core';
import { Track } from '../../entities/Track';
import { SpotifyService } from '../../services/spotify.service';
import { PlaylistTrack } from '../../entities/PlaylistTrack';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-playlist-tracks-spotify',
  templateUrl: './manage-playlist-tracks-spotify.component.html',
  styleUrls: ['./manage-playlist-tracks-spotify.component.scss']
})
export class ManagePlaylistTracksSpotifyComponent implements OnInit
{
  myTemplate: any = "";
  playlistTracks: PlaylistTrack[] = [];
  searchedTracks: Track[] = [];
  searchText: string = "";
  titleCard = "";
  
  constructor(public spotifyService: SpotifyService, private translateService: TranslateService)
  {
    this.spotifyService.selectedPlaylistChange.subscribe(x => {
      this.initPlaylistInterface()
    });
  }

  getImagePlaylist()
  {
    if (this.spotifyService.selectedPlaylist.playlist.images.length > 0)
      return this.spotifyService.selectedPlaylist.playlist.images[0].url;
    if (this.playlistTracks.length == 0)
      return "";
    if (this.playlistTracks[0].track.album.images.length == 0)
      return "";
    return this.playlistTracks[0].track.album.images[0].url;
  }

  ngOnInit()
  {
    this.initPlaylistInterface();
  }
  ngOnChanges()
  {
    this.initPlaylistInterface();
  }

  

  initPlaylistInterface()
  {
    this.translateService.get("SPOTIFY.TITLE-PLAYLIST", { v: this.spotifyService.selectedPlaylist.playlist.name }).subscribe(x => this.titleCard = x);
    this.spotifyService.getPlaylistTracks(this.spotifyService.selectedPlaylist.playlist).subscribe(x =>
    {
      this.playlistTracks = x.items;
    });
  }


  addTrackToPlaylist(track: Track)
  {
    this.spotifyService.addTrackToPlaylist(this.spotifyService.selectedPlaylist.playlist.id, track).subscribe(x => 
    {
      this.playlistTracks.push(new PlaylistTrack(track));
      let templist = [...this.playlistTracks];
      this.playlistTracks = [...templist];
    });
  }

  removeTrackFromPlaylist(track: PlaylistTrack)
  {
    this.spotifyService.removeTrackFromPlaylist(this.spotifyService.selectedPlaylist.playlist.id, track).subscribe(x =>
    {
      let index = this.playlistTracks.indexOf(track);
      this.playlistTracks.splice(index, 1);
      let templist = [...this.playlistTracks];
      this.playlistTracks = [...templist];
    });
  }

  startPlaylist()
  {
    this.spotifyService.playPlaylist(this.spotifyService.selectedPlaylist.playlist).subscribe();
  }

  startTrackSearch()
  {
    if (this.searchText.length == 0)
      return;
    this.spotifyService.searchTracks(this.searchText).subscribe(x =>
    {
      this.searchedTracks = x.tracks.items;
    });
  }



}
