// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding: 15px;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/content/content.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACF","sourcesContent":[":host{\r\n  padding: 15px;\r\n  max-width: 100%;\r\n  height: 100%;\r\n  overflow: auto;\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex-grow: 1;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
