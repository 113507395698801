import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChecklistTemplateManagerComponent } from './checklist-template-manager.component';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { TranslateModule } from '@ngx-translate/core';
import { NameUserPipeModule } from '../../../pipes/name-user-pipe/name-user-pipe.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { KanbanCardChecklistModule } from '../kanban-card-checklist/kanban-card-checklist.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NameUserPipeModule,
    NzIconModule,
    NzButtonModule,
    NzPopconfirmModule,
    TranslateModule,
    KanbanCardChecklistModule,
    NzDividerModule,
    NzModalModule,
    NzInputModule,
    NzSelectModule,
    NzPopoverModule,
    NzFormModule
  ],
  declarations: [ChecklistTemplateManagerComponent]
})
export class ChecklistTemplateManagerModule { }
