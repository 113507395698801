// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-options-container {
  position: absolute;
  top: 30px;
  width: auto;
  left: 0;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  z-index: 10000;
  max-height: 500px;
  overflow: auto;
}

.autocomplete-option {
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
}

.autocomplete-option:hover {
  background-color: lightgray;
}

.avatar {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.avatar-placeholder {
  display: inline-block;
  vertical-align: bottom;
}

.span-mail-selected {
  white-space: nowrap;
}

.selected-elem {
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 0 5px;
  margin: 0 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.deleteCross {
  cursor: pointer;
  float: right;
}

.team-selector-div {
  margin: 5px;
  padding: 5px;
  cursor: pointer;
  background-color: white;
}

.team-selector-div:hover {
  margin: 5px;
  background-color: lightgray;
}

.input-editor {
  border: none;
  width: 100%;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/messagerie/mail-selector-input/mail-selector-input.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,OAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,cAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,qBAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,2BAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".autocomplete-options-container {\r\n  position: absolute;\r\n  top: 30px;\r\n  width: auto;\r\n  left: 0;\r\n  padding: 5px;\r\n  border-radius: 5px;\r\n  background-color: white;\r\n  border: 1px solid black;\r\n  z-index: 10000;\r\n  max-height: 500px;\r\n  overflow: auto;\r\n}\r\n\r\n.autocomplete-option {\r\n  margin-bottom: 5px;\r\n  padding: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.autocomplete-option:hover {\r\n  background-color: lightgray;\r\n}\r\n\r\n.avatar {\r\n  width: 25px;\r\n  height: 25px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.avatar-placeholder {\r\n  display: inline-block;\r\n  vertical-align: bottom;\r\n}\r\n\r\n.span-mail-selected {\r\n  white-space: nowrap;\r\n}\r\n\r\n.selected-elem {\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 20px;\r\n  padding: 0 5px;\r\n  margin: 0 5px;\r\n  margin-bottom: 5px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.deleteCross {\r\n  cursor: pointer;\r\n  float: right;\r\n}\r\n\r\n.team-selector-div {\r\n  margin: 5px;\r\n  padding: 5px;\r\n  cursor: pointer;\r\n  background-color: white;\r\n}\r\n\r\n.team-selector-div:hover {\r\n  margin: 5px;\r\n  background-color: lightgray;\r\n}\r\n\r\n.input-editor {\r\n  border: none;\r\n  width: 100%;\r\n  color: black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
