import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Pipe({
  name: 'datelocale',
  pure: true
})
export class DatelocalePipe implements PipeTransform
{

  constructor(private datepipe: DatePipe, private auth: AuthService) { }

  transform(date: any, format?: any, timezone?: any, locale?: any): any
  {
    if (!date)
      return ""
    return this.datepipe.transform(date, format, timezone, locale ? locale : this.auth.Access.locale);
  }

}
