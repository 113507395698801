import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Scenario } from '../entities/Scenario/Scenario';
import { UrlApiService } from 'src/app/services/url-api.service';
import { User } from 'src/app/user/model/user';
import { LabelValue } from 'src/app/entities/keyvalue';
import { SettingsService } from 'src/app/settings/services/settings.service';

@Injectable()
export class UserSettingsService
{
    AllActions: string[];
    ScenarioActual: Scenario = new Scenario();
    constructor(private http: HttpClient) { }
    getScenario()
    {
        return this.http.get<Scenario>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/scenario/");
    }

    setScenario(scenario: Scenario)
    {
        return this.http.put<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/scenario/", scenario);
    }

    initAllActions()
    {
        return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/login/actions/");
    }

    getMailParameter()
    {
        return this.http.get<LabelValue[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/mailParam/");
    }

    public getAttemptAuthorized()
    {
        return this.http.get<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/login/attempt/");
    }

    public setAttemptAuthorized(name : number)
    {
        return this.http.put<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/login/attempt/", name);
    }

    public getIpAuthorized()
    {
        return this.http.get<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/login/ip/");
    }

    public setIpAuthorized(name : number)
    {
        return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/login/ip/", name);
    }

    getMailDefault()
    {
        return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + "/api/scenario/defaultMail/");
    }
}
