import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanBoardArchivesComponent } from './kanban-board-archives.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KanbanCardEditModule } from '../kanban-card-edit/kanban-card-edit.module';
import { KanbanListModule } from '../kanban-list/kanban-list.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { KanbanCardEditModalModule } from '../kanban-card-edit-modal/kanban-card-edit-modal.module';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzTreeModule,
    FormsModule,
    TranslateModule,
    KanbanListModule,
    NzButtonModule,
    NzInputModule,
    KanbanCardEditModalModule,
  ],
  declarations: [KanbanBoardArchivesComponent],
  exports: [KanbanBoardArchivesComponent]
})
export class KanbanBoardArchivesModule { }
