import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SpotifyPlayerModule } from 'src/app/spotify/component/spotify-player/spotify-player.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { KanbanCardEditModalModule } from 'src/app/kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.module';

@NgModule({
  imports: [
    CommonModule,
    NzMenuModule,
    NzPopoverModule,
    NzSelectModule,
    TranslateModule,
    NzAvatarModule,
    NzCheckboxModule,
    NzButtonModule,
    KanbanCardEditModalModule,
    NzBadgeModule,
    NzInputModule,
    FormsModule,
    NzIconModule,
    RouterModule,
    NzToolTipModule,
    SpotifyPlayerModule,
    PipesModule
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent]
})
export class NavbarModule { }
