import { KanbanAttachment } from "../kanban/entities/Attachment";
import { MessagerieAccountAlias } from "../messagerie/entities/messagerie-account-alias";
import { MessagerieMailAction } from "../messagerie/entities/messagerie-mail-action";
import { MessagerieAccountSendGroup } from "../messagerie/entities/messagerie-send-group";

export class EmailToSend
{
    idMail = 0;
    from: MessagerieAccountAlias = new MessagerieAccountAlias();
    to: string[] = [];
    cc: string[] = [];
    cci: string[] = [];
    toML: MessagerieAccountSendGroup[] = [];
    ccML: MessagerieAccountSendGroup[] = [];
    cciML: MessagerieAccountSendGroup[] = [];
    object: string = "";
    content: string = "";
    messageId = "";
    cardId = 0;
    readReceipt = false;
    receptionReceipt = false;
    sendDate: Date = null;
    action: MessagerieMailAction = null;
    attachments: KanbanAttachment[] = [];
}