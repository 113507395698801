import { Component, ElementRef, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../src/app/services/auth.service';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { MoralPersonService } from '../../../../src/app/third-party/moral-person/services/moral-person.service';
import { PhysicalPersonService } from '../../../../src/app/third-party/physical-person/services/physical-person.service';
import { ThirdPartyService } from '../../../../src/app/third-party/services/third-party.service';
import { UserSourceService } from '../../../../src/app/user/services/source/user-source.service';
import * as Quill from 'quill';
import { Email } from '../../../../src/app/entities/email';
import { EmailToSend } from '../../../../src/app/entities/email-to-send';
import { MailPersonInfo } from '../../../../src/app/entities/mail-person-info';
import { User } from '../../../../src/app/user/model/user';
import { LabelValue } from '../../../../src/app/entities/keyvalue';
import { TextCompareService } from '../../../../src/app/services/text-compare.service';
import { GroupUserDataService } from '../../../../src/app/group-user/services/data/group-user-data.service';
import { GroupUser } from '../../../../src/app/group-user/entities/group-user';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UrlApiService } from '../../../../src/app/services/url-api.service';
import { finalize, firstValueFrom, fromEvent, Subscription } from 'rxjs';
import { KanbanEditCardService } from '../../../../src/app/kanban/services/kanban-edit-card.service';
import { HttpClient } from '@angular/common/http';
import { MailAccount } from '../../../../src/app/messagerie/entities/mail-account';
import { SettingsService } from '../../../../src/app/settings/services/settings.service';
import { MessagerieSignature } from '../../../../src/app/messagerie/entities/messagerie-signature';
import { Signature } from '../../../../src/app/entities/Signature';
import { MessagerieAccountSendGroup } from '../../../../src/app/messagerie/entities/messagerie-send-group';
import { MessagerieAccountAlias } from '../../../../src/app/messagerie/entities/messagerie-account-alias';
import { DomSanitizer } from '@angular/platform-browser';
import { KanbanAttachment } from '../../kanban/entities/Attachment';
import { AttachmentsModuleService } from '../../services/AttachmentsModule.service';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { MessagerieFolder } from '../../messagerie/entities/messagerie-folder';
declare function getMD5(blob): any;
declare function getCaretPosition(): number;
declare var indexCursorJS: number;
declare var signatureContent: string;
var Delta = Quill.import('delta');
Quill.register(Signature);
//var Delta = Quill.import('delta');
@Component({
  selector: 'app-mail-editor',
  templateUrl: './mail-editor.component.html',
  styleUrls: ['./mail-editor.component.scss']
})
export class MailEditorComponent implements OnInit
{

  constructor(
    public translateService: TranslateService,
    public cds: CacheDataService,
    private auth: AuthService,
    private userService: UserSourceService,
    private message: NzMessageService,
    public tpservice: ThirdPartyService,
    private pps: PhysicalPersonService,
    private groupuserservice: GroupUserDataService,
    private el: ElementRef,
    private ssserv: SettingsService,
    public ams: AttachmentsModuleService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private kecs: KanbanEditCardService,
    public tcs: TextCompareService
  ) { }

  email: Email = new Email();
  @Output() close = new EventEmitter<boolean>();
  @Output() checkCanSend = new EventEmitter<boolean>();
  @Output() send = new EventEmitter<EmailToSend>();
  listOfEmailOptions: MailPersonInfo[] = [];
  filteredOptions: MailPersonInfo[] = [];
  filteredOptions2: MailPersonInfo[] = [];
  filteredOptions3: MailPersonInfo[] = [];
  @Input() listMailTo: string[] = [];
  @Input() codeParams: string[] = [];
  @Input() emailToInit: EmailToSend = null;
  @Input() cardMailCodeParams: LabelValue[] = [];
  @Input() fromImportant: boolean = true;
  @Input() selectedFolder: MessagerieFolder = null;
  mailTo: string = "";
  mailCc: string = "";
  pasteSubscription: Subscription;
  mailCci: string = "";
  sendMail: Subscription;
  fromList = [];
  you: User = new User;
  idPersonInfo: number = 0;
  placeHolder: string = "";
  selectedIndex = 0;
  from = "";
  quill;
  createdAlias: MessagerieAccountAlias = new MessagerieAccountAlias();
  createAliasVisible = false;
  mailAccountList: MailAccount[] = [];
  selectedAccount: MailAccount = new MailAccount();
  url = UrlApiService.settings.apiConfig.uriAPI + '/api/user/donothing';

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],                     //code-block => class style
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],         //class style
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link', 'image', 'video']
  ];

  ngOnDestroy()
  {
    if (this.sendMail)
      this.sendMail.unsubscribe();
    if (this.draftInterval)
      clearInterval(this.draftInterval);
  }

  setfrom(aliasname: string)
  {
    for (let account of this.mailAccountList)
    {
      let alias = account.aliases.find(x => x.displayName == aliasname);
      if (!alias && account.mailAdress == aliasname)
      {
        alias = new MessagerieAccountAlias();
        alias.displayName = aliasname;
        alias.mail = aliasname;
      }
      if (alias)
      {
        this.email.from = alias;
        this.selectedAccount = account;
        this.selectAccount(account.id)
        return;
      }
    }
  }



  ngOnInit()
  {
    pdfDefaultOptions.assetsFolder = "assets/scripts/ngx-pdf-viewer"
    this.signatureDivId = new Date().getTime().toString();
    this.http.get<MailAccount[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account`).subscribe(x =>
    {
      this.mailAccountList = x.filter(a => !a.archived);
      this.initAccount();
    });
    this.sendMail = this.kecs.sendMail.subscribe(x =>
    {
      let mail = this.getDatas();
      let formData: FormData = new FormData();
      for (let i = 0; i < this.attachments.length; i++)
      {
        formData.append("Attachments" + i, this.attachments[i], this.attachments[i].name);
      }

      formData.append("Mail", JSON.stringify(mail));
      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail", formData).subscribe(x =>
      {
        let success = "";
        this.translateService.get("MESSAGERIE.EDITOR.SUCCESS-SEND").subscribe(x => success = x)
        this.message.success(success)
        this.initField(this.emailToInit);
      })
    })
    this.translateService.get("MESSAGERIE.EDITOR.PLACEHOLDER").subscribe(x => this.placeHolder = x);

    if (this.listMailTo.length > 0)
    {
      this.listMailTo.forEach(element =>
      {
        this.email.to.push({ label: this.cutMail(element), value: element });
      });
    }

    this.addPpToPersonList();
    this.addMpToPersonList();
    this.addCustomRecipientToList();

    if (this.cds.teamsCollection.length == 0)
    {
      this.groupuserservice.getAllGroupUsers(false).subscribe(grps => this.cds.teamsCollection = grps.map(x => x.grpuser));
    }

    if (this.cds.userCollection.length < 1)
    {
      this.userService.getAllUserWithAvatar().subscribe(x =>
      {
        this.cds.userCollection = x.sort((a, b) => (a.name + a.surname).toUpperCase() < (b.name + b.surname).toUpperCase() ? -1 : 1);
        this.addUserToPersonList();
      })
    }
    else
    {
      this.addUserToPersonList();
    }

    if (this.emailToInit != null)
    {
      this.initField(this.emailToInit);
    }
    this.initEditor();
    this.pasteSubscription = fromEvent(document, "paste").subscribe((pasteEvent: any) => { this.pasteFunction(); })

    this.draftInterval = setInterval(() =>
    {
      let mail = this.getDatas();
      this.saveOrMailAsDraft(mail, this.attachments);
    }, 60000);
  }

  initAccount()
  {
    if (this.emailToInit)
    {
      for (let acc of this.mailAccountList)
      {
        if (acc.mailAdress == this.emailToInit.from.mail || acc.mailAdress == this.emailToInit.from.displayName)
        {
          this.selectedAccount = acc;
          this.cds.idAccountMailSend = acc.id;
          this.defaultAlias.displayName = acc.mailAdress;
          this.defaultAlias.mail = acc.mailAdress;
          break;
        }
        for (let alias of acc.aliases)
        {
          if (alias.mail == this.emailToInit.from.mail || alias.mail == this.emailToInit.from.displayName ||
            alias.displayName == this.emailToInit.from.mail || alias.displayName == this.emailToInit.from.displayName)
          {
            this.selectedAccount = acc;
            this.cds.idAccountMailSend = acc.id;
            this.defaultAlias.displayName = alias.displayName;
            this.defaultAlias.mail = alias.mail;
            break;
          }
        }
      }
      if (this.selectedAccount.id == 0)
      {
        this.selectedAccount = this.mailAccountList[0];
        this.cds.idAccountMailSend = this.selectedAccount.id;
      }
    }
    else if (this.selectedFolder)
    {
      let acc = this.mailAccountList.find(f => f.id == this.selectedFolder.accountId);
      if (acc)
      {
        this.selectedAccount = acc;
        this.cds.idAccountMailSend = acc.id;
        this.defaultAlias.displayName = this.selectedAccount.mailAdress;
        this.defaultAlias.mail = this.selectedAccount.mailAdress;
      }
    }
    else
    {
      this.selectedAccount = this.mailAccountList[0];
      this.cds.idAccountMailSend = this.selectedAccount.id;
    }

    this.from = this.defaultAlias.displayName;
    this.setfrom(this.from)
  }

  pasteFunction()
  {
    if (!this.cds.copiedMailId)
      return;
    this.http.get<KanbanAttachment>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${this.cds.copiedMailId}/generate-msg`).subscribe(att =>
    {
      this.emailToInit.attachments.push(att);
      this.cds.copiedMailId = 0;
    })
  }

  draftInterval;

  nzFilterOption = (): boolean => true;

  currentlySearching = "";
  freeAccount = [];
  search(oge)
  {
    if (oge)
      this.currentlySearching = oge;
  }

  selectAccount(accountid: number)
  {
    this.cds.idAccountMailSend = accountid;

  }

  updateAccountAliases(account: MailAccount)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/alias`, account)
  }

  addMailAsPJ(idmail)
  {
    this.http.get<KanbanAttachment>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${idmail}/generate-msg`).subscribe(att =>
    {
      this.emailToInit.attachments.push(att);
    })
  }

  addToList()
  {
    this.email.from = this.createdAlias;
    this.from = this.createdAlias.displayName;
    this.selectedAccount.aliases.push(this.createdAlias);
    this.updateAccountAliases(this.selectedAccount).subscribe(() =>
    {
      this.createdAlias = new MessagerieAccountAlias();
      this.createAliasVisible = false;
    });
  }

  deleteAlias(alias: MessagerieAccountAlias)
  {
    for (let acc of this.mailAccountList)
    {
      let index = acc.aliases.findIndex(x => x.mail == alias.mail && x.displayName == alias.displayName);
      if (index >= 0)
        acc.aliases.splice(index, 1);
      this.updateAccountAliases(acc).subscribe();
    }

  }

  updateMailingList(ml: MessagerieAccountSendGroup[], selected)
  {
    switch (selected)
    {
      case "to":
        this.email.toML = ml;
        break;
      case "cc":
        this.email.ccML = ml;
        break;
      case "cci":
        this.email.cciML = ml;
        break;
    }
  }

  updateListChange(listmail: string[], selected)
  {
    let temp = listmail.map(x => ({ label: this.cutMail(x), value: x }));
    switch (selected)
    {
      case "to":
        this.email.to = temp;
        break;
      case "cc":
        this.email.cc = temp;
        break;
      case "cci":
        this.email.cci = temp;
        break;
    }

    this.checkCanSend.emit(this.checkToSend())
  }

  getListForInput(selected)
  {
    return selected.map(x => x.value);
  }

  doNothin() { }

  stopPropagation()
  {
    event.stopPropagation();
  }

  signatureDivId = "";
  insertSignature(signature: MessagerieSignature)
  {
    let signDiv = document.getElementById(this.signatureDivId);
    signatureContent = "<div id='" + this.signatureDivId + "'>" + signature.content + "</div>";
    if (signDiv)
    {
      signDiv.innerHTML = signature.content;
    }
    else this.quill.clipboard.dangerouslyPasteHTML(indexCursorJS, "<div id='" + this.signatureDivId + "'>" + signature.content + "</div>");
    console.log("kek 1");

    // else
    // {
    //   this.quill.updateContents([
    //     { insert: { signature: "<div id='" + this.signatureDivId + "'>" + signature.content + "</div>" } },
    //   ]);
    // }
    //else this.quill.insertText(indexCursorJS, "<div id='" + this.signatureDivId + "'>" + signature.content + "</div>");
    this.showSignatureSelector = false;
  }

  indexCursor = -1;
  showSignatureSelector = false;
  updateSendAccount()
  {
    this.ssserv.setSettingsByName("prefered_mailaccount_send", this.cds.idAccountMailSend + "");
    this.selectedAccount = this.mailAccountList.find(x => x.id == this.cds.idAccountMailSend);
    this.defaultAlias.displayName = this.selectedAccount.mailAdress;
    this.defaultAlias.mail = this.selectedAccount.mailAdress;
  }

  attachments: File[] = [];
  addAttachment(info)
  {
    if (info.file.status === 'uploading')
    {
      return;
    }
    if (info.file.status === 'done')
    {
      if (info.file.size > 10000000)
      {
        this.createBasicNotification();
        return;
      }
      // Get this url from response in real world.
      this.attachments.push(info.file.originFileObj);
    }
  }

  removeAttachment(file: File)
  {
    let index = this.attachments.findIndex(x => x.name == file.name);
    if (index >= 0)
      this.attachments.splice(index, 1);
  }

  removeCamelineAttachment(idatt: number)
  {
    let index = this.emailToInit.attachments.findIndex(x => x.id == idatt);
    if (index >= 0)
      this.emailToInit.attachments.splice(index, 1);
  }

  getFileSize(file: File)
  {
    var length = file.size;

    if (length < 1000)
    {
      return length + " octets";
    }
    else if (length < 1000000)
    {
      let size = (length / 1000) + "";
      return (size.length >= 3 ? size.substring(0, 3) : size) + " Ko";
    }
    else
    {
      let size = (length / 1000000) + "";
      return (size.length >= 3 ? size.substring(0, 3) : size) + " Mo";
    }
  }

  createBasicNotification(): void
  {
    let errorMessage;
    this.translateService.get("USER.ERROR.INCORRECT-AVATAR-SIZE").subscribe(x => errorMessage = x)
    this.message
      .create("error",
        errorMessage
      )
  }

  selectedInput = "";
  addTeamMails(team: GroupUser)
  {
    this.groupuserservice.getManagers(team.id).subscribe(users =>
    {
      for (let member of users)
      {
        let user = this.cds.userCollection.find(x => x.id == member.id);
        if (!user)
          continue;
        switch (this.selectedInput)
        {
          case "to":
            this.addMail(user.mail, this.email.to, null);
            break;
          case "cc":
            this.addMail(user.mail, this.email.cc, null);
            break;
          case "cci":
            this.addMail(user.mail, this.email.cci, null);
            break;
        }
      }
    })
  }

  initEditor()
  {
    this.quill = new Quill('#editorq', {
      modules: {
        toolbar: this.toolbarOptions
      },
      placeholder: this.translater('MESSAGERIE.EDITOR.PLACEHOLDER'),
      theme: 'snow',
    });
    this.quill.root.setAttribute('spellcheck', true)
    if (this.emailToInit.content)
      this.quill.clipboard.dangerouslyPasteHTML(0, this.emailToInit.content);

    setTimeout(() =>
    {
      // this.quill.clipboard.addMatcher("div", function (node, delta)
      // {
      //   console.log("kek 2");
      //   return new Delta().insert({ signature: signatureContent });
      // });
      this.quill.on('editor-change', function (eventName, range, oldRange, source)
      {
        if (eventName == "selection-change")
        {
          if (range)
          {
            if (range.length == 0)
            {
              indexCursorJS = range.index;

              //console.log('User cursor is on', range.index);
            } else
            {
              //var text = this.quill.getText(range.index, range.length);
              //console.log('User has highlighted', text);
            }
          } else
          {
            //console.log('Cursor not in the editor');
          }
        }
        else if (eventName == "text-change")
        {
          //console.log(range)
        }

      });
      // document.getElementById('editorq').addEventListener('keyup', e =>
      // {
      //   this.indexCursor = cursor_position();
      //   console.log(this.indexCursor)
      // })
    }, 50);
  }

  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }

  defaultAlias: MessagerieAccountAlias = new MessagerieAccountAlias();
  initField(x: EmailToSend)
  {

    this.email.to = [];
    this.email.cc = [];
    this.email.cci = [];
    this.email.readReceipt = x.readReceipt;
    this.email.sendDate = x.sendDate;

    if (x != null)
    {
      this.email.from = x.from;
      this.from = x.from.displayName;

      x.to.forEach(to =>
      {
        this.email.to.push({ label: to, value: to })
      });
      x.cc.forEach(cc =>
      {
        this.email.cc.push({ label: cc, value: cc })
      });
      x.cci.forEach(cci =>
      {
        this.email.cci.push({ label: cci, value: cci })
      });
      this.email.object = x.object;
    }
    else
    {
      this.from = this.defaultAlias.displayName;
      this.email.object = "";
    }
    this.setfrom(this.from)


  }

  addPpToPersonList()
  {
    this.cds.physicalPersonCollection.forEach(x =>
    {
      if (x.archived)
        return;
      x.mails.forEach(m =>
      {
        if (!m.mail)
          return;
        let personInfo: MailPersonInfo = new MailPersonInfo();
        personInfo.id = this.idPersonInfo;
        personInfo.avatar = x.image;
        personInfo.emails = m.mail;
        personInfo.emailsCut = this.cutMail(m.mail);
        personInfo.name = x.firstName + x.lastName;
        if (this.listOfEmailOptions.find(find => find.emails == m.mail) == null)
        {
          this.listOfEmailOptions.push(personInfo);
          this.idPersonInfo++;
        }
      })
    });
  }

  addCustomRecipientToList()
  {
    this.GetMailForCustomRecipient().subscribe(mails =>
    {
      mails.forEach(mail =>
      {
        let personInfo: MailPersonInfo = new MailPersonInfo();
        personInfo.id = this.idPersonInfo;
        personInfo.emails = mail;
        personInfo.emailsCut = this.cutMail(mail);
        personInfo.name = mail;
        if (this.listOfEmailOptions.find(find => find.emails == mail) == null)
        {
          this.listOfEmailOptions.push(personInfo);
          this.idPersonInfo++;
        }
      })
    })
  }

  addMpToPersonList()
  {
    this.cds.moralPersonCollection.forEach(x =>
    {
      if (x.archived)
        return;
      x.mails.forEach(m =>
      {
        if (!m.mail)
          return;
        let personInfo: MailPersonInfo = new MailPersonInfo();
        personInfo.id = this.idPersonInfo;
        personInfo.avatar = x.image;
        personInfo.emails = m.mail;
        personInfo.emailsCut = this.cutMail(m.mail);
        personInfo.name = x.name;
        if (this.listOfEmailOptions.find(find => find.emails == m.mail) == null)
        {
          this.listOfEmailOptions.push(personInfo);
          this.idPersonInfo++;
        }
      })
    });
  }

  addUserToPersonList()
  {
    this.cds.userCollection.forEach(x =>
    {
      if (x.archived || !x.mail)
        return;
      let personInfo: MailPersonInfo = new MailPersonInfo();
      personInfo.id = this.idPersonInfo;
      personInfo.avatar = x.avatar;
      personInfo.emails = x.mail;
      personInfo.emailsCut = this.cutMail(x.mail);
      personInfo.name = x.name;
      if (this.listOfEmailOptions.find(find => find.emails == x.mail) == null)
      {
        this.listOfEmailOptions.push(personInfo);
        this.idPersonInfo++;
      }
    });
    this.you = this.cds.userCollection.find(usr => usr.id == this.auth.Access.idUser);

    this.email.from = this.defaultAlias;
  }

  cutMail(mail: string): string
  {
    return mail.slice(0, mail.indexOf("@"))
  }

  deleteMail(id: number, selected)
  {
    selected.splice(id, 1)
  }

  getFilteredOptions(value: string)
  {
    return this.listOfEmailOptions.filter(option => this.tcs.contains(option.emails, value));
  }

  updateSelectedIndex(mail, direction)
  {
    let length = this.getFilteredOptions(mail).length;


    if (direction == "down")
    {
      this.selectedIndex++;
      if (this.selectedIndex >= length)
        this.selectedIndex = 0;
    }
    else
    {
      this.selectedIndex--;
      if (this.selectedIndex < 0)
        this.selectedIndex = length - 1;
    }
    let selectedElement = this.el.nativeElement.querySelectorAll('.autocomplete-options-container > .autocomplete-option')[this.selectedIndex];
    selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  onChange(value: string, opt: number): void
  {
    if (opt === 1)
      this.filteredOptions = this.listOfEmailOptions.filter(option => this.tcs.contains(option.emails, value));
    else if (opt === 2)
      this.filteredOptions2 = this.listOfEmailOptions.filter(option => this.tcs.contains(option.emails, value));
    else if (opt === 3)
      this.filteredOptions3 = this.listOfEmailOptions.filter(option => this.tcs.contains(option.emails, value));
    this.selectedIndex = 0;
  }

  addMail(param, selected, mail)
  {
    let mailCut = mail
    if (param != null)
    {
      mail = param
      mailCut = mail.slice(0, mail.indexOf("@"));
    } else
    {
      let filtered = this.getFilteredOptions(mail);
      if (filtered.length > 0 && mail)
      {
        mail = filtered[this.selectedIndex].emails;
        mailCut = mail.slice(0, mail.indexOf("@"));
      }
    }

    if (this.checkMail(mail))
    {
      if (selected.findIndex(x => x.value == mail) >= 0)
        return;
      this.checkCanSend.emit(this.checkToSend())
      if (this.listOfEmailOptions.find(find => find.emails == mail) == null)
      {
        this.AddMailToCustomRecipient(mail).subscribe(() =>
        {
          let personInfo: MailPersonInfo = new MailPersonInfo();
          personInfo.id = this.idPersonInfo;
          personInfo.emails = mail;
          personInfo.emailsCut = this.cutMail(mail);
          personInfo.name = mail;
          this.listOfEmailOptions.push(personInfo);
          this.idPersonInfo++;
        })
      }
      selected.push({ label: mailCut, value: mail });
      this.mailTo = "";
      this.mailCc = "";
      this.mailCci = "";
    }

  }

  checkMail(receiver: string): boolean
  {
    let regex: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!regex.test(receiver))
      return false;
    else
    {
      return true;
    }
  }

  isSelected(value: string, selected): boolean
  {
    for (let elem of selected)
    {
      if (elem.value === value)
        return true;
    }
    return false;
  }

  copyToClipboard(val)
  {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let text = "";
    this.translateService.get("GENERIC-ACTIONS.COPIED-TO-CLIPBOARD").subscribe(x => text = x)
    this.message.success(text);
  }

  checkToSend(fromEnabled: boolean = true, toEnabled: boolean = true, objectEnabled: boolean = true, ccEnabled: boolean = true, cciEnabled: boolean = true): boolean 
  {
    if ((((this.email.from.mail.length > 0 && this.checkMail(this.email.from.mail)) || !fromEnabled))
      && (this.mailHasRecipient(toEnabled))
      && ((this.checkMailAdress(this.email.to.map(x => x.value)) || !toEnabled))
      && ((this.checkMailAdress(this.email.cc.map(x => x.value)) || !ccEnabled))
      && ((this.checkMailAdress(this.email.cci.map(x => x.value)) || !cciEnabled))
      && (this.email.object.length > 0 || !objectEnabled))
      return true;
    return false;
  }

  mailHasRecipient(toEnabled: boolean)
  {
    if (!toEnabled)
      return true;
    if (this.email.to.length > 0 && this.checkMailAdress(this.email.to.map(x => x.value)))
      return true;
    if (this.email.toML.findIndex(x => x.mailList.length > 0) >= 0)
      return true;
    return false;
  }

  checkMailAdress(emails: any): boolean
  {
    if (emails.length < 1)
      return true
    emails.forEach(element =>
    {
      if (!this.checkMail(element))
        return false
    });
    return true
  }

  getDatas()
  {
    // let QuillDeltaToHtmlConvert = require('quill-delta-to-html').QuillDeltaToHtmlConverter;

    // let deltaOps = this.quill.getContents();

    // let cfg = { inlineStyles: true };

    // let converter = new QuillDeltaToHtmlConvert(deltaOps["ops"], cfg);

    // let htmll = converter.convert();
    let htmll = this.quill.root.innerHTML;

    this.email.content = htmll;
    let mailToSend = new EmailToSend();

    mailToSend.from = this.email.from;
    mailToSend.object = this.email.object;
    mailToSend.content = htmll;
    mailToSend.cc = this.email.cc.map(x => x.value);
    mailToSend.cci = this.email.cci.map(x => x.value);
    mailToSend.to = this.email.to.map(x => x.value);
    mailToSend.toML = this.email.toML;
    mailToSend.ccML = this.email.ccML;
    mailToSend.cciML = this.email.cciML;
    mailToSend.readReceipt = this.email.readReceipt;
    mailToSend.receptionReceipt = this.email.receptionReceipt;
    mailToSend.sendDate = this.email.sendDate;
    mailToSend.attachments = this.emailToInit.attachments;
    mailToSend.idMail = this.emailToInit.idMail;

    mailToSend.action = this.emailToInit.action;
    return (mailToSend);
  }

  quillGetHTML(inputDelta)
  {
    var tempCont = document.createElement("div");
    (new Quill(tempCont)).setContents(inputDelta);
    return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
  }

  AddMailToCustomRecipient(mail: string)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/custom-recipient/${mail}`, null)
  }

  RemoveMailFromCustomRecipient(mail: string)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/custom-recipient/${mail}`)
  }

  GetMailForCustomRecipient()
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/custom-recipient`)
  }

  showLoading = false;
  visubyte;
  visualizedUrl = "";
  filetype = "";
  closeVisu()
  {
    this.showLoading = false;
    this.visubyte = null;
    this.visualizedUrl = "";
  }

  getUrl(url: string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setTypeForPreview(filename: string)
  {
    if (filename.toLowerCase().includes('.png') || filename.toLowerCase().includes('.jpg') || filename.toLowerCase().includes('.jpeg') || filename.toLowerCase().includes('.jfif'))
      return 'img';
    else if (filename.toLowerCase().includes('.pdf'))
      return 'pdf'
    else
      return 'nothing'
  }

  setVisuLocal(file: File)
  {
    var reader = new FileReader();
    reader.addEventListener('load', () =>
    {
      if (this.setTypeForPreview(file.name) == "pdf")
      {
        this.visubyte = this.ams.base64ToArrayBuffer(reader.result.toString());
        this.visuHeight = "800px";
        this.visuWidth = "100%";
        this.showLoading = true;
      }
      else
      {
        this.visuWidth = "";
        this.visuHeight = "";

        this.visualizedUrl = reader.result.toString();
        this.showLoading = true;
      }
    });
    reader.readAsDataURL(file);
  }

  setVisualization(att: KanbanAttachment) 
  {
    this.filetype = this.setTypeForPreview(att.name)

    if (this.filetype != 'pdf')
    {
      this.ams.getPreviewAttachments(att.id, 0).subscribe((x) => 
      {
        att.url = x;
        this.visubyte = null;
        this.updatePreview(att);
        this.showLoading = true;
      })
    }
    else
    {
      this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/Download/${att.id}`).subscribe(x => 
      {
        this.visubyte = this.ams.base64ToArrayBuffer(x);
        this.visualizedUrl = "";
        this.showLoading = true;
      });
    }

  }

  visuHeight = "800px";
  visuWidth = "100%";

  updatePreview(att: KanbanAttachment) 
  {
    if (this.visualizedUrl == att.url)
      this.visualizedUrl = "";
    else
    {
      this.visualizedUrl = "";
      setTimeout(() =>
      {
        this.visualizedUrl = att.url;
      }, 100);

    }
    if (att.url.startsWith("data:image/"))
    {
      this.visuWidth = "";
      this.visuHeight = "";
    }
    else if (att.url.startsWith("data:application/pdf"))
    {
      this.visuHeight = "800px";
      this.visuWidth = "100%";
    }

  }

  idDraftMail = 0;

  async saveOrMailAsDraft(mail: EmailToSend, attachments: File[])
  {
    let formData: FormData = new FormData();
    for (let i = 0; i < attachments.length; i++)
    {
      formData.append("Attachments" + i, attachments[i], attachments[i].name);
    }

    formData.append("Mail", JSON.stringify(mail));
    formData.append("AccountId", this.cds.idAccountMailSend + '');
    if (!this.emailToInit || !this.emailToInit.idMail)
      this.emailToInit.idMail = await firstValueFrom(this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail/draft", formData));
    else
    {
      mail.idMail = this.idDraftMail;
      this.emailToInit.idMail = await firstValueFrom(this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail/draft", formData));
    }
  }
}


