import { Injectable } from '@angular/core';
import { MoralPerson } from '../entities/moral-person';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { UrlApiService } from '../../../services/url-api.service';
import { KanbanAttachment } from '../../../kanban/entities/Attachment';
import { SearchClientResult } from '../entities/search-client-result';
import { AuthService } from '../../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MoralPersonService
{

  constructor(private http: HttpClient, private authService: AuthService) { }

  isLoading = false;
  isdoingit2 = false;
  modal: any;
  moralPersonListChange: Subject<MoralPerson[]> = new Subject<MoralPerson[]>();

  copyMp(mp: MoralPerson)
  {
    let newmp = new MoralPerson();
    newmp.anniversaryDate = mp.anniversaryDate;
    newmp.archived = mp.archived;
    newmp.attachments = mp.attachments;
    newmp.brands = mp.brands;
    newmp.cards = mp.cards;
    newmp.codeAPE = mp.codeAPE;
    newmp.codeTVA = mp.codeTVA;
    newmp.comment = mp.comment;
    newmp.customFields = mp.customFields;
    newmp.expand = mp.expand;
    newmp.filteredRelations = mp.filteredRelations;
    newmp.id = mp.id;
    newmp.image = mp.image;
    newmp.initials = mp.initials;
    newmp.labels = mp.labels;
    newmp.mails = mp.mails;
    newmp.mainCurrency = mp.mainCurrency;
    newmp.name = mp.name;
    newmp.nationality = mp.nationality;
    newmp.parentSiteId = mp.parentSiteId;
    newmp.phoneNumbers = mp.phoneNumbers;
    newmp.secondaryNames = mp.secondaryNames;
    newmp.secondarySites = [...mp.secondarySites];
    newmp.sign = mp.sign;
    newmp.siren = mp.siren;
    newmp.siret = mp.siret;
    newmp.sites = mp.sites;
    newmp.tags = mp.tags;
    newmp.websites = mp.websites;
    newmp.nbAttachments = mp.nbAttachments;
    newmp.creatorId = mp.creatorId;
    newmp.creationDate = mp.creationDate;

    return newmp;
  }

  searchClient(searchtext: string)
  {
    return this.http.get<SearchClientResult[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/search`, { params: { searchtext: searchtext } })
    //return this.http.get<SearchClientResult[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/search`)
  }

  getAllMoralPersons()
  {
    return this.http.get<MoralPerson[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person`)
  }

  getMoralPerson(idmp: number)
  {
    return this.http.get<MoralPerson>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${idmp}`)
  }

  createMoralPerson(mp: MoralPerson, avatar: File)
  {
    let formData: FormData = new FormData();
    if (avatar)
      formData.append("Image", avatar, "avatar.png");
    formData.append("MoralPerson", JSON.stringify(mp));
    mp.creationDate = new Date();
    mp.creatorId = this.authService.Access.idUser;
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person`, formData)
  }
  updateMoralPerson(mp: MoralPerson, avatar: File)
  {
    let formData: FormData = new FormData();
    if (avatar)
      formData.append("Image", avatar, "avatar.png");
    formData.append("MoralPerson", JSON.stringify(mp));
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person`, formData)
  }

  updateMoralPersonSecondarySites(mp: MoralPerson)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/secondary-sites`, mp)
  }

  archiveMoralPerson(idmp: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${idmp}/archive`, null)
  }
  restoreMoralPerson(idmp: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${idmp}/restore`, null)
  }

  getMoralPersonColumnPreferences()
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/third-party/moral-person/column-preferences');
  }

  getMoralPersonAttachments(idmp: number)
  {
    return this.http.get<KanbanAttachment[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${idmp}/attachment`);
  }

  addAttachmentToMoralPerson(idmp: number, attachment: KanbanAttachment)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${idmp}/attachment`, attachment);
  }

  removeAttachmentFromMoralPerson(idmp: number, idfile: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${idmp}/attachment/${idfile}`);
  }

}
