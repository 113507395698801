import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlossaryCategory } from '../entities/category';
import { UrlApiService } from 'src/app/services/url-api.service';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlossaryCategoryService {

glossaryCategories: GlossaryCategory[] = [];
glossaryCategoriesChange: Subject<GlossaryCategory[]> = new  Subject<GlossaryCategory[]>();
constructor(private http: HttpClient) { }

createGlossaryCategory(category: GlossaryCategory)
{
  return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/category`, category);
}

updateGlossaryCategory(category: GlossaryCategory)
{
  return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/category`, category);
}

archiveGlossaryCategory(idcategory: number)
{
  return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/category/${idcategory}/archive`, null);
}

restoreGlossaryCategory(idcategory: number)
{
  return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/category/${idcategory}/restore`, null);
}

getGlossaryCategory()
{
  return this.http.get<GlossaryCategory[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/glossary/category`).pipe(tap(x => this.glossaryCategories = x));
}
}
