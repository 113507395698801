export class ThirdPartyCustomSystemField
{
    id: number = 0;
    createdUtc: Date;
    creatorId: number = 0;
    name = "";
    description = "";
    thirdPartyType = 0;
    mandatory = false;
    archived = false;
    value = "";
}