import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeePasswordComponent } from './see-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { HelpModule } from 'src/app/components/help/help.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzIconModule,
    TranslateModule,
    HelpModule,
    NzToolTipModule
  ],
  declarations: [SeePasswordComponent],
  exports: [SeePasswordComponent]
})
export class SeePasswordModule { }
