import { KanbanCard } from "../../../../src/app/kanban/entities/Card";
import { PhoneNumber } from "../../../../src/app/third-party/entities/phone-number";
import { Mail } from "../../../../src/app/third-party/entities/mail";
import { CustomField } from "../../../../src/app/third-party/entities/custom-field";
import { FilterRelation } from "../../../../src/app/third-party/entities/filter-relation";
import { Site } from "../../../../src/app/third-party/entities/site";
import { GlobalLabel } from "../../../../src/app/kanban/entities/Label";
import { ThirdPartyCustomSystemField } from "./third-party-custom-system-field";

export class ThirdPartySheetInfos
{
    id: number;
    firstName: string;
    lastName: string;
    lastName2: string;
    name: string;
    comment: string;
    cards: KanbanCard[] = [];
    phoneNumbers: PhoneNumber[] = [];
    brands: string[] = [];
    nationality: string;
    siren: string;
    siret: string;
    tags: string[] = [];
    websites: string[] = [];
    secondaryNames: string[] = [];
    nbAttachments: number;
    image: string;
    nbRelationships: string[] = [];
    anniversaryDate: Date;
    spokenLanguages: string[] = [];
    labels: GlobalLabel[] = [];
    type: number;
    gender: string;
    sigle: string;
    enseigne: string;
    codeTVA: string;
    codeAPE: string;
    mainCurrency: string;
    mails: Mail[] = [];
    customFields: CustomField[] = [];
    filteredRelations: FilterRelation[] = [];
    customeSystemFields: ThirdPartyCustomSystemField[] = [];
    sites: Site[] = [];
    creatorId: number;
    creationDate: Date;
    companyId: number;
    department = "";
    job = "";
}