import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { THIRD_PARTY_SHEET } from '../../../call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { MessagerieService } from '../../../messagerie/messagerie.service';
import { CacheDataService } from '../../../services/cache-data.service';
import { InterModuleService } from '../../../services/inter-module.service';
import { TextCompareService } from '../../../services/text-compare.service';
import { ThirdPartyRelationInfos } from '../../../third-party/entities/third-party-relation-info-display';
import { MoralPerson } from '../../../third-party/moral-person/entities/moral-person';
import { MoralPersonService } from '../../../third-party/moral-person/services/moral-person.service';
import { PhysicalPerson } from '../../../third-party/physical-person/entities/physical-person';
import { PhysicalPersonService } from '../../../third-party/physical-person/services/physical-person.service';
import { KanbanBoard } from '../../entities/Board';
import { KanbanCard } from '../../entities/Card';
import { KanbanList } from '../../entities/List';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-card-third-party',
  templateUrl: './kanban-card-third-party.component.html',
  styleUrls: ['./kanban-card-third-party.component.scss']
})
export class KanbanCardThirdPartyComponent implements OnInit
{

  constructor(private kbs: KanbanService, private cds: CacheDataService,
    @Inject(THIRD_PARTY_SHEET) private component: ComponentType<any>,
    private pps: PhysicalPersonService,
    private translate: TranslateService,
    public ims: InterModuleService,
    private mps: MoralPersonService,
    public mss: MessagerieService,
    private tcs: TextCompareService,
    private messageService: NzMessageService,
    private modalService: NzModalService) { }
  @Input() card: KanbanCard;
  @Input() list: KanbanList;
  @Input() board: KanbanBoard;
  idselected = null;
  isCreatingPP = false;
  creatingPPFirstName = "";
  creatingPPLastName = "";
  isCreatingMP = false;
  creatingMPName = "";
  tpfocus = 0;
  selectedTP = 0;
  filterTPRelationText = "";
  ppNotAssociated: PhysicalPerson[] = [];
  mpNotAssociated: MoralPerson[] = [];


  ngOnInit()
  {
    console.log(this.card);
    for (let i = 0; i < this.card.physicalPersons.length; i++)
    {
      this.card.physicalPersons[i] = this.cds.physicalPersonCollection.find(x => x.id == this.card.physicalPersons[i].id);
    }

    for (let i = 0; i < this.card.moralPersons.length; i++)
    {
      this.card.moralPersons[i] = this.cds.allmoralperson.find(x => x.id == this.card.moralPersons[i].id);
    }
  }

  searchTpText = "";
  onSearch(text)
  {
    this.searchTpText = text;
  }


  startTheCall(number: string)
  {
    var link = document.createElement('a');
    link.href = "sip:" + number;
    link.click();
  }

  canAssociateTpToCard(tp: ThirdPartyRelationInfos)
  {
    if (this.card.physicalPersons.findIndex(x => x.id == tp.idtp) >= 0)
      return false;
    if (this.card.moralPersons.findIndex(x => x.id == tp.idtp) >= 0)
      return false;
    return true;
  }

  getFilteredTpRelations()
  {
    return this.ims.tpRelationsInfos.filter(x => this.tcs.contains(x.name, this.filterTPRelationText) || this.tcs.contains(x.relationTypeText, this.filterTPRelationText))
  }

  initSendMail(mailTo)
  {
    this.mss.listMailTo.push(mailTo);
    this.mss.editMail()
  }

  associateThirdParty(idpp: number)
  {
    this.kbs.addThirdPartyToCard(this.card.id, idpp).subscribe(() =>
    {
      this.card.listId = this.list ? this.list.id : 0;
      this.card.listName = this.list ? this.list.title : "";
      this.card.boardId = this.board ? this.board.id : 0;
      this.card.boardName = this.board ? this.board.title : "";
      this.card.bgColor = this.board ? this.board.bgColor : "";
      let index = this.cds.physicalPersonCollection.findIndex(x => x.id == idpp);
      if (index >= 0)
      {
        let physicalPerson = this.cds.physicalPersonCollection.find(x => x.id == idpp);
        this.card.physicalPersons.push(physicalPerson);
        physicalPerson.cards.push(this.card);
      }
      else 
      {
        index = this.cds.allmoralperson.findIndex(x => x.id == idpp);
        if (index >= 0)
        {
          let moralperson = this.cds.allmoralperson.find(x => x.id == idpp);
          this.card.moralPersons.push(moralperson);
          moralperson.cards.push(this.card);
        }
      }
      setTimeout(() =>
      {
        this.idselected = null;
      }, 100);
    })
  }

  getMpNotAssociated()
  {
    return this.cds.allmoralperson.filter(x => !x.archived && this.card.moralPersons.find(y => y.id == x.id) == null && this.tcs.contains(x.name, this.searchTpText));
  }

  getPpNotAssociated()
  {
    return this.cds.physicalPersonCollection.filter(x => !x.archived && this.card.physicalPersons.find(y => y.id == x.id) == null && this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, this.searchTpText));
  }

  removePPFocus(pp: PhysicalPerson)
  {
    this.kbs.updateCardThirdPartyLinkDescription(this.card.id, pp.id, pp.comment).subscribe(x =>
    {
      document.getElementById("third-party-description-" + pp.id).style.display = "none";
      this.tpfocus = 0;
    });
  }

  setTPFocus(idpp)
  {
    var elem = document.getElementById("third-party-description-" + idpp);
    elem.style.display = "inline-block";
    elem.focus();
    this.tpfocus = idpp;
  }

  initCreatePP()
  {
    this.isCreatingPP = true;
    setTimeout(() =>
    {
      //document.getElementById("createppfirstnamebutton").focus();  
    }, 500);

  }

  cancelCreation()
  {
    this.isCreatingMP = false;
    this.isCreatingPP = false;
    this.creatingPPLastName = "";
    this.creatingPPFirstName = "";
    this.creatingMPName = "";
  }

  createPhysicalPerson(openSheet: boolean)
  {
    let pp = new PhysicalPerson();
    pp.firstName = this.creatingPPFirstName;
    pp.lastName = this.creatingPPLastName;

    this.pps.createPhysicalPerson(pp, null).subscribe(x =>
    {
      pp.id = x;
      //this.cds.physicalPersonCollection.push(pp);
      this.associateThirdParty(x);
      this.cancelCreation();
      if (openSheet)
        this.editThirdParty(x);
    })
  }

  ppAlreadyExist()
  {
    return this.cds.physicalPersonCollection.find(x => !x.archived && this.tcs.equals(x.firstName, this.creatingPPFirstName) && this.tcs.equals(x.lastName, this.creatingPPLastName)) == null;
  }

  createMoralPerson(openSheet: boolean)
  {
    let mp = new MoralPerson();
    mp.name = this.creatingMPName;
    this.mps.createMoralPerson(mp, null).subscribe(x =>
    {
      mp.id = x;
      //this.cds.moralPersonCollection.push(mp);
      this.associateThirdParty(x);
      this.cancelCreation();
      if (openSheet)
        this.editThirdParty(x);
    })
  }

  mpAlreadyExist()
  {
    return this.cds.moralPersonCollection.findIndex(x => !x.archived && this.tcs.contains(x.name, this.creatingMPName)) < 0;
  }

  removeMPFocus(mp: MoralPerson)
  {
    this.kbs.updateCardThirdPartyLinkDescription(this.card.id, mp.id, mp.comment).subscribe(x =>
    {
      document.getElementById("third-party-description-" + mp.id).style.display = "none";
      this.tpfocus = 0;
    });
  }

  editThirdParty(idtp: number)
  {
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: this.component,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: close,
        danger: true,
        onClick: (result) =>
        {
          if (result.infosHasChanged())
          {
            this.translate.get("THIRD-PARTY.CALL.THIRD-PARTY-SHEET.ERROR-ONGOING-MODIFICATION").subscribe(x => this.messageService.error(x, { nzDuration: 4000 }))
            return;
          }
          modal.close()
        }
      }]
    });
    modal.componentInstance.id = idtp.toString();
  }

  dissociateThirdParty(idpp: number, typetp: number)
  {
    this.kbs.removeThirdPartyFromCard(this.card.id, idpp).subscribe(() =>
    {
      if (typetp == 1)
      {
        let index = this.card.physicalPersons.findIndex(x => x.id == idpp);
        if (index >= 0)
          this.card.physicalPersons.splice(index, 1);
      }
      else
      {
        let index = this.card.moralPersons.findIndex(x => x.id == idpp);
        if (index >= 0)
          this.card.moralPersons.splice(index, 1);
      }
    })
  }

  getPhoneNumberForSelectedTP(idtp: number)
  {
    if (idtp == 0)
      return [];
    let tp = this.cds.physicalPersonCollection.find(x => x.id == idtp);
    if (tp)
    {
      return tp.phoneNumbers;
    }
    else
    {
      let mp = this.cds.allmoralperson.find(x => x.id == idtp);
      if (mp)
        return mp.phoneNumbers;
    }
  }

  getMailForSelectedTP(idtp: number)
  {
    if (idtp == 0)
      return [];
    let tp = this.cds.physicalPersonCollection.find(x => x.id == idtp);
    if (tp)
    {
      return tp.mails;
    }
    else
    {
      let mp = this.cds.allmoralperson.find(x => x.id == idtp);
      if (mp)
        return mp.mails;
    }
  }

}
