import { Component, OnInit } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';
import { Category } from '../../entities/Category';

@Component({
  selector: 'app-browse-spotify',
  templateUrl: './browse-spotify.component.html',
  styleUrls: ['./browse-spotify.component.scss']
})
export class BrowseSpotifyComponent implements OnInit {

  constructor(private spotifyService: SpotifyService) { }

  categories: Category[] = [];

  ngOnInit() {
    this.spotifyService.getCategories().subscribe(x => this.categories = x.categories.items);
  }

  setCategory(category: Category)
  {
this.spotifyService.selectedCategory = category;
  }
}
