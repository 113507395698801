import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, Inject } from '@angular/core';
import { KanbanAttachment } from '../../../../src/app/kanban/entities/Attachment';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../../../src/app/services/url-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Subscription, fromEvent } from 'rxjs';
import { CheckFileExist } from '../../../../src/app/entities/check-file-exist';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AttachmentService } from '../../../../src/app/services/attachment.service';
import { AttachmentsModuleService } from '../../../../src/app/services/AttachmentsModule.service';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { Receivedmail } from '../../messagerie/entities/receivedmail';
import { MessagerieService } from '../../messagerie/messagerie.service';
import { KanbanCard } from '../../kanban/entities/Card';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PlanningComponent } from '../../planning/planning.component';
declare function getMD5(blob): any;

@Component({
  selector: 'app-attachment-manager',
  templateUrl: './attachment-manager.component.html',
  styleUrls: ['./attachment-manager.component.scss']
})
export class AttachmentManagerComponent implements OnInit
{

  @Input() origin: string;
  @Input() id: number;
  @Input() readonly: boolean = false;
  @Input() accept: string;
  @Input() uploadImmediately: boolean;
  @Input() initAttachments: KanbanAttachment[] = [];
  @Output() attachmentsChange = new EventEmitter();

  attachments: KanbanAttachment[] = [];
  typemime = "image/jpeg";
  visualizedUrl = "";
  visubyte;
  visuHeight = "800px";
  visuWidth = "100%";
  editedExtension = "";
  showLoading = false;
  editedAttachment: KanbanAttachment = new KanbanAttachment();
  pasteSubscription: Subscription;
  isAdding = false;
  @ViewChild('icalpreview', { static: false }) icalPreview?: TemplateRef<{}>;
  constructor(private http: HttpClient,
    private message: NzMessageService,
    private datepipe: DatePipe,
    private modal: NzModalService,
    public translate: TranslateService,
    public modalService: NzModalService,
    public mss: MessagerieService,
    public ams: AttachmentsModuleService,
    public attService: AttachmentService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer) { }

  ngOnInit()
  {
    pdfDefaultOptions.assetsFolder = "assets/scripts/ngx-pdf-viewer"
    if (this.initAttachments.length > 0)
      this.attachments = this.initAttachments;
    else 
    {
      this.http.get<KanbanAttachment[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/${this.origin}/${this.id}/attachment`).subscribe(x =>
      {
        this.attachments = x;
        this.attachmentsChange.emit(this.filterAttachment());
      })
    }

    if (this.accept != "*")
      this.pasteSubscription = fromEvent(document, "paste").subscribe((pasteEvent: any) => { this.pasteFunction(pasteEvent); })
  }

  ngOnDestroy()
  {
    if (this.pasteSubscription)
      this.pasteSubscription.unsubscribe();
  }

  base64ToBlob(base64): Blob
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return new Blob([bytes], { type: "image/png" });
  }

  clipData(att: KanbanAttachment)
  {
    this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/Download/${att.id}`).subscribe(x => 
    {
      let type = "image/png";
      console.log(x);

      let blob = this.base64ToBlob(x);
      let data = [new ClipboardItem({ [type]: blob })];
      navigator.clipboard.write(data).then(
        () =>
        {
          console.log("success");

        },
        (e) =>
        {
          console.log(e);

        }
      );
    })

  }

  pasteFunction(pasteEvent: ClipboardEvent)
  {
    var items = pasteEvent.clipboardData.items;
    if (!items || items.length == 0)
      return;

    for (var i = 0; i < items.length; i++)
    {
      // Skip content if not image
      if (items[i].type.indexOf("image") == -1) continue;
      // Retrieve image on clipboard as blob
      var blob = items[i].getAsFile();

      var reader = new FileReader();
      let length = blob.size;
      reader.addEventListener('load', () =>
      {
        let date = new Date();
        let att = new KanbanAttachment();
        att.name = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss") + "_clipboard.png";
        att.url = reader.result.toString();
        att.id = 0;
        att.size = length;

        if (this.uploadImmediately)
        {
          if (this.isAdding)
            return;
          this.isAdding = true;
          this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/${this.origin}/${this.id}/attachment`, att).subscribe(x =>
          {
            this.isAdding = false;
            att.id = x;
            this.attachments.push(att);
            this.attachmentsChange.emit(this.attachments);
          });
        }
        else
        {
          this.attachments.push(att);
          this.attachmentsChange.emit(this.attachments);
        }
      });
      reader.readAsDataURL(blob);
    }
  }

  downloadAttachment(att: KanbanAttachment)
  {
    this.stopPropagation();
    this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/Download/${att.id}`).subscribe(x => 
    {
      var byte = this.base64ToArrayBuffer(x);
      this.saveByteArray(att.name, byte);
    });
  }

  initFileRename(att: KanbanAttachment)
  {
    this.editedAttachment.id = att.id;
    this.editedExtension = att.name.split('.').pop();
    this.editedAttachment.name = att.name.substring(0, att.name.length - this.editedExtension.length - 1);

  }

  renameAttachment(att: KanbanAttachment)
  {
    let newname = this.editedAttachment.name + "." + this.editedExtension;
    this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/rename/${this.editedAttachment.id}`, newname).subscribe(x =>
    {
      this.editedAttachment.id = 0;
      att.name = newname;
    });
  }

  getThumbnail(att: KanbanAttachment)
  {
    if (att.url)
      return att.url;
    if (att.thumbnail)
      return att.thumbnail;
    return "";
  }

  filetype = "";
  setTypeForPreview(filename: string)
  {
    if (filename.toLowerCase().includes('.png') || filename.toLowerCase().includes('.jpg') || filename.toLowerCase().includes('.jpeg') || filename.toLowerCase().includes('.jfif'))
      return 'img';
    else if (filename.toLowerCase().includes('.pdf'))
      return 'pdf'
    else if (filename.toLowerCase().includes('.msg') || filename.toLowerCase().includes('.eml'))
      return 'msg';
    else if (filename.toLowerCase().includes('.ics'))
      return 'ical';
    else
      return 'nothing'
  }
  iframeHeight = "";

  setVisualization(att: KanbanAttachment) 
  {
    this.filetype = this.setTypeForPreview(att.name)


    // let route = UrlApiService.settings.apiConfig.uriAPI + "/api/attachment/" + att.id;
    // if (this.origin == "credentials")
    //   route = UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${this.id}/attachment/${att.id}/preview`;
    // this.ams.getPreviewAttachments(att.id, this.origin == "credentials" ? this.id : 0).subscribe((x) => 
    // {
    //   att.url = x;
    //   this.updatePreview(att);
    // })
    if (this.filetype == 'ical')
    {
      this.iframeHeight = this.getIframeHeight();
      this.http.get<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/Download/ical/${att.id}`).subscribe(x => 
      {
        this.events = x;
        //this.initIcalInfos();
        this.openCardPlanning();
        this.visubyte = null;
        this.visualizedUrl = "";
        this.showLoading = false;
      })
    }
    else if (this.filetype == 'msg' || this.filetype == 'eml')
    {
      this.iframeHeight = this.getIframeHeight();
      this.http.get<Receivedmail>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/Download/msg/${att.id}`).subscribe(x => 
      {
        this.mail = x;
        this.initmailinfo();

        this.visubyte = null;
        this.visualizedUrl = "";
        this.showLoading = true;
      })
    }
    else if (this.filetype != 'pdf')
    {
      this.ams.getPreviewAttachments(att.id, this.origin == "credentials" ? this.id : 0).subscribe((x) => 
      {
        att.url = x;
        this.visubyte = null;
        this.updatePreview(att);
        this.showLoading = true;
      })
    }
    else
    {
      this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/Download/${att.id}`).subscribe(x => 
      {
        this.visubyte = this.ams.base64ToArrayBuffer(x);
        this.visualizedUrl = "";
        this.showLoading = true;
      });
    }

  }
  mail: Receivedmail;
  events: KanbanCard[] = [];

  closeVisu()
  {
    this.showLoading = false;
    this.visubyte = null;
    this.visualizedUrl = "";
  }

  updatePreview(att: KanbanAttachment) 
  {
    if (this.visualizedUrl == att.url)
      this.visualizedUrl = "";
    else
    {
      this.visualizedUrl = "";
      setTimeout(() =>
      {
        this.visualizedUrl = att.url;
      }, 100);

    }
    if (att.url.startsWith("data:image/"))
    {
      this.visuWidth = "";
      this.visuHeight = "";
    }
    else if (att.url.startsWith("data:application/pdf"))
    {
      this.visuHeight = "800px";
      this.visuWidth = "100%";
    }

  }

  filterAttachment()
  {
    if (this.accept == "*/*")
      return this.attachments;
    else if (this.accept == "*")
      return this.attachments.filter(x => !x.thumbnail.startsWith("data:image/"))
    else return this.attachments.filter(x => x.thumbnail.startsWith("data:image/"))
  }

  showVisuIcon(att: KanbanAttachment)
  {
    if (att.name.trim().toUpperCase().endsWith(".ICS") || att.name.toUpperCase().endsWith(".MSG") || att.name.toUpperCase().endsWith(".EML") || att.name.toUpperCase().endsWith(".PNG") || att.name.toUpperCase().endsWith(".JPG") || att.name.toUpperCase().endsWith(".JPEG") || att.name.toUpperCase().endsWith(".PDF") || att.name.toUpperCase().endsWith(".JFIF"))
      return true;
    return false;
  }

  saveByteArray(reportName, byte)
  {
    var blob = new Blob([byte], { type: "application/jpg" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  getUrl(url: string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  base64ToArrayBuffer(base64)
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  deleteAttachment(att: KanbanAttachment)
  {
    if (this.uploadImmediately)
    {
      this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/${this.origin}/${this.id}/attachment/${att.id}`).subscribe(x =>
      {
        this.attachments.splice(this.attachments.findIndex(x => x.id == att.id), 1);
        this.attachmentsChange.emit(this.attachments);
      });
    }
    else 
    {
      this.attachments.splice(this.attachments.findIndex(x => x.id == att.id), 1);
      this.attachmentsChange.emit(this.attachments);
    }
    this.visualizedUrl = "";
  }

  doNothin() { }

  stopPropagation()
  {
    event.stopPropagation();
  }

  updateAttachments({ file, fileList }: { [key: string]: any })
  {
    const status = file.status;
    if (status === 'done')
    {
      getMD5(
        file.originFileObj
      ).then(
        res =>
        {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
          {
            let att = new KanbanAttachment();
            att.name = file.name;
            att.url = reader.result.toString();
            att.thumbnail = att.url;
            att.id = 0;
            att.size = file.size;
            let CheckFileExistdto = new CheckFileExist();
            CheckFileExistdto.attachment = att;
            CheckFileExistdto.id = this.id;
            CheckFileExistdto.type = this.origin;
            CheckFileExistdto.crc = res;
            this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/check-file-exist`, CheckFileExistdto).subscribe(ret =>
            {
              switch (ret)
              {
                case -1:
                  this.translateService.get("GENERIC-ERROR.FILE-ASSOCIATED").subscribe(text => { this.message.create('error', text); })
                  break;
                case 0:
                  if (this.uploadImmediately)
                  {
                    this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/${this.origin}/${this.id}/attachment`, att).subscribe(x =>
                    {
                      if (x > 0)
                      {
                        att.id = x;
                        this.attachments.push(att);
                        this.attachmentsChange.emit(this.attachments);
                      }
                      else this.translateService.get("GENERIC-ERROR.FILE-ASSOCIATED").subscribe(text => { this.message.create('error', text); })
                    });
                  }
                  else
                  {
                    this.attachmentsChange.emit(this.attachments);
                    this.attachments.push(att);
                  }

                  break;
                default:
                  att.id = ret;
                  this.attachments.push(att);
                  this.attachmentsChange.emit(this.attachments);
                  break;
              }
            });
          });
          reader.readAsDataURL(file.originFileObj);
        },
        err => console.error(err)
      );
    } else if (status === 'error')
    {
      console.log(`${file.name} file upload failed.`);
    }
  }

  // updateAttachments({ file, fileList }: { [key: string]: any })
  // {
  //   const status = file.status;
  //   if (status === 'done')
  //   {
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () =>
  //     {
  //       let att = new KanbanAttachment();
  //       att.name = file.name;
  //       let blob = new Blob([reader.result]);
  //       att.url = reader.result.toString();
  //       getMD5(
  //         blob
  //       ).then(
  //         res =>
  //         {
  //           console.log(res)
  //         },
  //         err => console.error(err)
  //       );


  //     });
  //     reader.readAsArrayBuffer(file.originFileObj);
  //   } else if (status === 'error')
  //   {
  //     console.log(`${file.name} file upload failed.`);
  //   }
  // }

  mailfrom = "";
  mailto = "";
  mailcc = "";
  mailcci = "";
  canSeeCci = false;
  initmailinfo()
  {
    if (this.mail.pseudo)
      this.mailfrom = this.mail.pseudo + " <" + this.mail.from + ">"
    else this.mailfrom = this.mail.from;

    this.mailto = "";
    for (let to of this.mail.to)
    {
      this.mailto += to + ", ";
    }
    if (this.mail.to.length > 0)
      this.mailto = this.mailto.slice(0, this.mailto.length - 2)

    this.mailcc = "";
    for (let to of this.mail.cc)
    {
      this.mailcc += to + ", ";
    }
    if (this.mail.cc.length > 0)
      this.mailcc = this.mailcc.slice(0, this.mailcc.length - 2)

    this.mailcci = "";
    for (let to of this.mail.bcc)
    {
      this.mailcci += to + ", ";
    }
    if (this.mail.bcc.length > 0)
    {
      this.mailcci = this.mailcci.slice(0, this.mailcci.length - 2)
      if (this.mss.selectedFolder && this.mss.selectedFolder.isSent)
        this.canSeeCci = true;
    }

  }

  initIcalInfos()
  {
    let title = "";
    this.translateService.get("GENERIC-ACTIONS.PREVIEW").subscribe(t => title = t);
    this.modal.create({
      nzTitle: title,
      nzContent: this.icalPreview,
      nzFooter: null,
      nzMaskClosable: false
    });
  }

  getIframeHeight()
  {
    var iframes = document.querySelectorAll("iframe");
    if (!iframes)
      return "50px";

    console.log(iframes);

    for (var i = 0; i < iframes.length; i++)
    {
      return (iframes[i].contentWindow.document.body.scrollHeight + 50) + 'px';
    }
  }

  getAttachmentUri()
  {
    return UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/attachment`;
  }

  openCardPlanning()
  {
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: PlanningComponent,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: close,
        danger: true,
        onClick: (result) =>
        {
          modal.close()
        }
      }]
    });
    modal.componentInstance.readonly = true;
    modal.componentInstance.date = new Date(this.events[0].startDate);
  }

}
