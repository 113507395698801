import { CustomEvent } from "../../SDICalendar/calendar/entities/event";
import { PlanningRights } from "./other-planning-rights";

export class EventsPerUser
{
    iduser: number;
    events: CustomEvent[] = [];
    rights: PlanningRights;
    visible = true;
    view: any;
    size = "49%";
}