export class TypeRelation
{
    id: number;
    nameSourceTarget: string;
    nameTargetSource: string;
    description: string;
    typeSource: number;
    typeTarget: number;
    actionsRelation: string;
    archived: boolean;
    boardId: number;
    visible = true;
    filterId: string;
    direction: number;
    creatorId: number;
    creationDate: Date;
}