import { Component, OnInit, Input } from '@angular/core';
import { KanbanBoard } from '../../entities/Board';
import { TranslateService } from '@ngx-translate/core';
import { ExportBoardParameters, ExportListParameters, ExportCardParameters } from '../../entities/export-parameters';

@Component({
  selector: 'app-export-board',
  templateUrl: './export-board.component.html',
  styleUrls: ['./export-board.component.scss']
})
export class ExportBoardComponent implements OnInit {

  constructor(private translateService: TranslateService) { }
  @Input() board: KanbanBoard;
  nodes = [];
  selectedNodes = [];
  expandedKeys = [];
  checkedKeys = [];
  basicParamChosen = false;
  getBoardInfos = true;
  getListInfos = true;
  getCardInfos = false;
  searchValue = "";

  titleBackgroundColor = "";
  titleComment = "";
  titleVisibility = "";
  titleLabels = "";
  titleMembers = "";
  titleInternalRelations = "";
  titleListCreationAuthorization = "";
  titleActions = "";
  titleStyle = "";
  titleSort = "";
  titleFilters = "";
  titleDescription = "";
  titleAttachments = "";

  ngOnInit() {
    this.initLangText();
  }

  initLangText()
  {
    this.translateService.get("KANBAN.LIST-STYLE.BGCOLOR").subscribe(x => this.titleBackgroundColor = x);
    this.translateService.get("GENERIC-FORM.COMMENT").subscribe(x => this.titleComment = x);
    this.translateService.get("CREDENTIALS.FORM.VISIBILITY").subscribe(x => this.titleVisibility = x);
    this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.LABELS").subscribe(x => this.titleLabels = x);
    this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.MEMBERS").subscribe(x => this.titleMembers = x);
    this.translateService.get("KANBAN.EXPORT.INTERNAL-RELATION").subscribe(x => this.titleInternalRelations = x);
    this.translateService.get("GENERIC-ACTIONS.ACTION").subscribe(x => this.titleActions = x);
    this.translateService.get("KANBAN.EXPORT.STYLE").subscribe(x => this.titleStyle = x);
    this.translateService.get("KANBAN.EXPORT.SORT").subscribe(x => this.titleSort = x);
    this.translateService.get("KANBAN.FILTERS.TITLE").subscribe(x => this.titleFilters = x);
    this.translateService.get("GENERIC-FORM.DESCRIPTION").subscribe(x => this.titleDescription = x);
    this.translateService.get("KANBAN.ATTACHMENTS-HOVER").subscribe(x => this.titleAttachments = x);
    this.translateService.get("KANBAN.PREFERENCES.ALLOW-LIST-CREATION").subscribe(x => this.titleListCreationAuthorization = x);
  }

  initNodes()
  {
    if(this.getBoardInfos)
    {
      this.nodes.push(
        {
          title: this.titleBackgroundColor,
          key: "b_bgColor",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleComment,
          key: "b_comment",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleVisibility,
          key: "b_visibility",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleLabels,
          key: "b_labels",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleMembers,
          key: "b_members",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleInternalRelations,
          key: "b_internalRelation",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleListCreationAuthorization,
          key: "b_listCreation",
          checked: true,
          isLeaf: true
        })
    }

    for(let list of this.board.lists.filter(x => !x.archived))
    {
      let listnode = {
        title: list.title,
        key: "l_title" + list.id,
        checked: true,
        children: []
      };
      if(this.getListInfos)
      {
        listnode.children.push({
          title: this.titleActions,
          key: "l_actions",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleStyle,
          key: "l_style",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleSort,
          key: "l_sort",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleComment,
          key: "l_comment",
          checked: true,
          isLeaf: true
        },
        {
          title: this.titleFilters,
          key: "l_filters",
          checked: true,
          isLeaf: true
        });
      }

        for(let card of list.cards.filter(x => !x.archived))
        {
          let cardnode = {
              title: card.title,
              checked: true,
              key: "c_title" + card.id,
              children: []
          };
          
          if(this.getCardInfos)
          {
            cardnode.children.push({
              title: this.titleLabels,
              key: "c_labels" + card.id,
              checked: true,
              isLeaf: true
            },
            {
              title: this.titleMembers,
              key: "c_members" + card.id,
              checked: true,
              isLeaf: true
            },
            {
              title: this.titleDescription,
              key: "c_description" + card.id,
              checked: true,
              isLeaf: true
            },
            {
              title: this.titleComment,
              key: "c_comment" + card.id,
              checked: true,
              isLeaf: true
            },
            {
              title: this.titleAttachments,
              key: "c_attachments" + card.id,
              checked: true,
              isLeaf: true
            },
            {
              title: this.titleActions,
              key: "c_actions" + card.id,
              checked: true,
              isLeaf: true
            })
          }
          
          listnode.children.push(cardnode);
        }
      
      this.nodes.push(listnode);
    }
    this.basicParamChosen = true;
  }

  submitExportParamObject()
  {
    let exportParam = new ExportBoardParameters();
    exportParam.boardId = this.board.id;
    for(let b_node of this.nodes)
    {
        switch(b_node.key)
        {
          case "b_bgColor":
            exportParam.boardColor = b_node.checked;
          break;
          case "b_comment":
            exportParam.boardComment = b_node.checked;
          break;
          case "b_visibility":
            exportParam.boardVisibility = b_node.checked;
          break;
          case "b_labels":
            exportParam.boardLabels = b_node.checked;
          break;
          case "b_members":
            exportParam.boardMembers = b_node.checked;
          break;
          case "b_internalRelation":
            exportParam.boardListInternalRelations = b_node.checked;
          break;
          case "b_listCreation":
            exportParam.boardListCreationAuthorization = b_node.checked;
          break;
        }

      if(b_node.key.startsWith("l_title"))
      {
        let idlist = parseInt(b_node.key.substring("l_title".length));
        let listExportParam = exportParam.lists.find(x => x.listId == idlist);
        if(!listExportParam)
        {
          listExportParam = new ExportListParameters();
          exportParam.lists.push(listExportParam);
          listExportParam.listId = idlist;
        }

        for(let childlistnode of b_node.children)
        {
            switch(childlistnode.key)
            {
              case "l_actions":
                listExportParam.listActions = childlistnode.checked;
              break;
              case "l_style":
                listExportParam.listStyle = childlistnode.checked;
              break;
              case "l_sort":
                listExportParam.listSort = childlistnode.checked;
              break;
              case "l_comment":
                listExportParam.listComment = childlistnode.checked;
              break;
              case "l_filters":
                listExportParam.listFilters = childlistnode.checked;
              break;
            }

          if(childlistnode.key.startsWith("c_title"))
          {
            let idcard = parseInt(childlistnode.key.substring("c_title".length));
            let cardExportParam = listExportParam.cards.find(x => x.cardId == idcard);
            if(!cardExportParam)
            {
              cardExportParam = new ExportCardParameters();
              listExportParam.cards.push(cardExportParam);
              cardExportParam.cardId = idcard;
            }
            for(let childcardnode of childlistnode.children)
            {
              switch(childcardnode.key)
              {
                case "c_labels":
                  cardExportParam.cardLabels = childcardnode.checked;
                break;
                case "c_members":
                  cardExportParam.cardMembers = childcardnode.checked;
                break;
                case "c_description":
                  cardExportParam.cardDescription = childcardnode.checked;
                break;
                case "c_comment":
                  cardExportParam.cardComment = childcardnode.checked;
                break;
                case "c_attachments":
                  cardExportParam.cardAttachments = childcardnode.checked;
                break;
                case "c_actions":
                  cardExportParam.cardActions = childcardnode.checked;
                break;
              }
            }
          }
        }
      }
    }
    return exportParam;
  }

}
