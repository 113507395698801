import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatelocalePipe } from './datelocale.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [DatelocalePipe
  ],
  exports: [DatelocalePipe],
  providers: [DatelocalePipe]
})
export class PipesModule { }
