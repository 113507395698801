import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartyFilteredRelationComponent } from './third-party-filtered-relation.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from '../../../../../src/app/components/color-picker/color-picker.module';
import { SearchClientInfoModule } from '../../moral-person/components/search-client-info/search-client-info.module';
import { InputFocusDirectiveModule } from '../../../../../src/app/directives/input-focus-directive/input-focus-directive.module';
import { ThirdPartySheetComponent } from '../../../../../src/app/call-interface/third-party-sheet/third-party-sheet.component';
import { THIRD_PARTY_SHEET } from '../../../../../src/app/call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { CreatorDisplayModule } from '../../../../../src/app/components/creator-display/creator-display.module';
import { AttachmentManagerModule } from '../../../../../src/app/components/attachment-manager/attachment-manager.module';
import { HtmlEditorModule } from '../../../components/html-editor/html-editor.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NzInputModule,
    FormsModule,
    NzIconModule,
    NzSelectModule,
    NzDividerModule,
    NzTableModule,
    ColorPickerModule,
    NzButtonModule,
    NzToolTipModule,
    AttachmentManagerModule,
    HtmlEditorModule,
    NzPopconfirmModule,
    SearchClientInfoModule,
    NzModalModule,
    NzFormModule,
    InputFocusDirectiveModule,
    CreatorDisplayModule
  ],
  declarations: [ThirdPartyFilteredRelationComponent],
  exports: [ThirdPartyFilteredRelationComponent],
  providers: [{
    provide: THIRD_PARTY_SHEET, useValue: ThirdPartySheetComponent
  }]
})
export class ThirdPartyFilteredRelationModule { }
