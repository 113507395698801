// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-category-spotify {
  width: 200px;
  height: 200px;
}

.container-category-spotify {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 10px;
  cursor: pointer;
}

.text-category-spotify {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  padding-top: 140px;
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/browse-spotify/browse-spotify.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;AACF","sourcesContent":[".icon-category-spotify {\r\n  width: 200px;\r\n  height: 200px;\r\n}\r\n\r\n.container-category-spotify {\r\n  display: inline-block;\r\n  position: relative;\r\n  width: 200px;\r\n  height: 200px;\r\n  margin: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.text-category-spotify {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  text-align: center;\r\n  color:white;\r\n  padding-top:140px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
