import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardComponent } from './kanban-card.component';
import { FormsModule } from '@angular/forms';
import { CallThirdPartyModule } from '../call-third-party/call-third-party.module';
import { TranslateModule } from '@ngx-translate/core';
import { KanbanCardCheckDueDatePipeModule } from 'src/app/pipes/kanban/kanban-card-check-due-date-pipe/kanban-card-check-due-date-pipe.module';
import { KanbanCardShowDueDatePipeModule } from 'src/app/pipes/kanban/kanban-card-show-due-date-pipe/kanban-card-show-due-date-pipe.module';
import { KanbanCardShowEndDateBadPipeModule } from 'src/app/pipes/kanban/kanban-card-show-end-date-bad-pipe/kanban-card-show-end-date-bad-pipe.module';
import { KanbanCardShowEndDateGoodPipeModule } from 'src/app/pipes/kanban/kanban-card-show-end-date-good-pipe/kanban-card-show-end-date-good-pipe.module';
import { KanbanCardSortedLabelsPipeModule } from 'src/app/pipes/kanban/kanban-card-sorted-labels-pipe/kanban-card-sorted-labels-pipe.module';
import { CheckDateYearModule } from 'src/app/pipes/check-date-year/check-date-year.module';
import { AvatarUserModule } from 'src/app/pipes/avatar-user/avatar-user.module';
import { InitialUserModule } from 'src/app/pipes/initial-user/initial-user.module';
import { KanbanCardCompletedTooltipPipeModule } from 'src/app/pipes/kanban/kanban-card-completed-tooltip-pipe/kanban-card-completed-tooltip-pipe.module';
import { ImageFromClipboardModule } from '../image-from-clipboard/image-from-clipboard.module';
import { SendMailUserModule } from '../send-mail-user/send-mail-user.module';
import { KanbanMoveCopyCardModule } from '../kanban-move-copy-card/kanban-move-copy-card.module';
import { KanbanLabelDrawerModule } from '../kanban-label-drawer/kanban-label-drawer.module';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NameTeamPipeModule } from 'src/app/pipes/name-team-pipe/name-team-pipe.module';
import { InitialTeamPipeModule } from 'src/app/pipes/initial-team-pipe/initial-team-pipe.module';
import { AvatarTeamPipeModule } from 'src/app/pipes/avatar-team-pipe/avatar-team-pipe.module';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HomeComponent } from 'src/app/components/home/home.component';

@NgModule({
  imports: [
    CommonModule,
    NzInputModule,
    NzUploadModule,
    NzIconModule,
    NzToolTipModule,
    KanbanCardCheckDueDatePipeModule,
    KanbanCardSortedLabelsPipeModule,
    KanbanCardShowDueDatePipeModule,
    KanbanCardShowEndDateBadPipeModule,
    KanbanCardShowEndDateGoodPipeModule,
    KanbanCardCompletedTooltipPipeModule,
    CheckDateYearModule,
    NzMenuModule,
    DragDropModule,
    NzPopconfirmModule,
    NzDrawerModule,
    NzPopoverModule,
    KanbanLabelDrawerModule,
    NzDropDownModule,
    FormsModule,
    CallThirdPartyModule,
    TranslateModule,
    NzAvatarModule,
    AvatarUserModule,
    InitialUserModule,
    ImageFromClipboardModule,
    SendMailUserModule,
    KanbanMoveCopyCardModule,
    NameUserPipeModule,
    NameTeamPipeModule,
    InitialTeamPipeModule,
    PipesModule,
    AvatarTeamPipeModule
  ],
  declarations: [KanbanCardComponent],
  exports: [KanbanCardComponent],
  providers: [HomeComponent]
})
export class KanbanCardModule { }
