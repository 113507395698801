import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../services/url-api.service';
import { Settings } from '../entities/settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService
{

  constructor(private http: HttpClient) { }
  settings: Settings[] = [];
  setting: Settings = new Settings();

  public getSystemSettingsByName(name: string)
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + '/api/settings/system/name/' + name);
  }
  public setSystemSettingsByName(name: string, value: string)
  {
    let sett: Settings = new Settings();
    sett.key = name;
    sett.value = value;
    this.http.post<Settings>(UrlApiService.settings.apiConfig.uriAPI + '/api/settings/system/name', sett).subscribe();
  }
  public getSettingsByName(name: string)
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + '/api/settings/name/' + name);
  }

  public setSettingsByName(name: string, value: string)
  {
    let sett: Settings = new Settings();
    sett.key = name;
    sett.value = value;
    this.http.post<Settings>(UrlApiService.settings.apiConfig.uriAPI + '/api/settings/name', sett).subscribe();
  }

  public setSettingsByNameManual(name: string, value: string)
  {
    let sett: Settings = new Settings();
    sett.key = name;
    sett.value = value;
    return this.http.post<Settings>(UrlApiService.settings.apiConfig.uriAPI + '/api/settings/name', sett);
  }
}
