import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardPlanningComponent } from './kanban-card-planning.component';
import { KanbanCardEditModule } from '../kanban-card-edit/kanban-card-edit.module';
import { PlanningModule } from '../../../../../src/app/planning/planning.module';
import { AttachmentManagerModule } from '../../../../../src/app/components/attachment-manager/attachment-manager.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KanbanCardChecklistModule } from '../kanban-card-checklist/kanban-card-checklist.module';
import { KanbanCardHistoryModule } from '../kanban-card-history/kanban-card-history.module';
import { KanbanCardThirdPartyModule } from '../kanban-card-third-party/kanban-card-third-party.module';
import { KanbanActionsCreationCardModule } from '../kanban-actions-creation-card/kanban-actions-creation-card.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { KanbanMoveCopyCardModule } from '../kanban-move-copy-card/kanban-move-copy-card.module';
import { MailEditorModule } from '../../../../../src/app/components/mail-editor/mail-editor.module';
import { CallThirdPartyModule } from '../call-third-party/call-third-party.module';
import { KanbanCardCommentModule } from '../kanban-card-comment/kanban-card-comment.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { KanbanCardMailsModule } from '../kanban-card-mails/kanban-card-mails.module';
import { ChecklistTemplateManagerModule } from '../checklist-template-manager/checklist-template-manager.module';

@NgModule({
    imports: [
        CommonModule,
        PlanningModule,
        KanbanCardEditModule,
        AttachmentManagerModule,
        DragDropModule,
        KanbanCardChecklistModule,
        KanbanCardHistoryModule,
        KanbanCardThirdPartyModule,
        KanbanActionsCreationCardModule,
        NzButtonModule,
        TranslateModule,
        NzToolTipModule,
        ChecklistTemplateManagerModule,
        KanbanMoveCopyCardModule,
        MailEditorModule,
        CallThirdPartyModule,
        NzPopoverModule,
        FormsModule,
        NzInputModule,
        NzCheckboxModule,
        KanbanCardCommentModule,
        KanbanCardMailsModule,
        NzIconModule
    ],
    declarations: [KanbanCardPlanningComponent],
    exports: [KanbanCardPlanningComponent]
})
export class KanbanCardPlanningModule { }
