export class PresenceInfos
{
    cardId: number;
    userId: number;
    teamId: number;
    state: PresenceState = PresenceState.Away;
    description: string;
}

export enum PresenceState
{
    Available,
    WorkAway,
    Temporary,
    Busy,
    Away,
    OnSite
}