import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from './url-api.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, timeout } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SiteBlockerService
{

  public serverIsJoinable: boolean = true;
  constructor(private http: HttpClient, private authService: AuthService) { }

  pingServer()
  {
    this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/keepalive', null).subscribe(x =>
    {
      if (x == "NOTYETLOADED" || (x.status && x.status == 201))
      {
        this.serverIsJoinable = false;
      }
      else
      {
        if (UrlApiService.settings.version && x != UrlApiService.settings.version)
        {
          this.authService.needUpdateReload = true;
        }
        else this.authService.needUpdateReload = false;
        this.serverIsJoinable = true;
      }

    });;
  }

  makeRequest(timeToDelay)
  {
    return of('Request Complete!').pipe(delay(timeToDelay));
  }

}
