import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoomComponent } from './zoom.component';
import { ZoomMeetingModule } from './zoom-meeting/zoom-meeting.module';
import { ZoomRoutingModule } from './zoom.routing.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ContentModule } from '../components/content/content.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { LoadingModule } from '../components/loading/loading.module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PipesModule } from '../pipes/pipes/pipes.module';
import { ZoomMeetingSchedulerModule } from './zoom-meeting-scheduler/zoom-meeting-scheduler.module';
import { ZoomMeetingParticipantsModule } from './zoom-meeting-participants/zoom-meeting-participants.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    ZoomMeetingModule,
    ZoomRoutingModule,
    ZoomMeetingSchedulerModule,
    ZoomMeetingParticipantsModule,
    NzButtonModule,
    ContentModule,
    NzTableModule,
    NzIconModule,
    NzInputNumberModule,
    InfiniteScrollModule,
    NzCardModule,
    NzFormModule,
    FormsModule,
    TranslateModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzToolTipModule,
    LoadingModule,
    NzPopconfirmModule,
    NzInputModule,
    NzTabsModule,
    PipesModule
  ],
  declarations: [ZoomComponent],
  exports: [ZoomComponent]
})
export class ZoomModule { }
