import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardMailsComponent } from './kanban-card-mails.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { FormsModule } from '@angular/forms';
import { MessagerieMailViewerModule } from '../../../messagerie/messagerie-mail-viewer/messagerie-mail-viewer.module';
import { PipesModule } from '../../../../../src/app/pipes/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NzIconModule,
    NzGridModule,
    MessagerieMailViewerModule,
    NzButtonModule,
    NzToolTipModule,
    FormsModule,
    PipesModule
  ],
  declarations: [KanbanCardMailsComponent],
  exports: [KanbanCardMailsComponent]
})
export class KanbanCardMailsModule { }
