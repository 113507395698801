import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorLabelPipe } from './label-color-pipe.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ColorLabelPipe],
  exports: [ColorLabelPipe],
  providers: [ColorLabelPipe],
})
export class LabelColorPipeModule { }
