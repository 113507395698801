import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CacheDataService } from 'src/app/services/cache-data.service';
import { KanbanActivity } from '../../entities/Activity';
import { KanbanCard } from '../../entities/Card';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-card-history',
  templateUrl: './kanban-card-history.component.html',
  styleUrls: ['./kanban-card-history.component.scss']
})
export class KanbanCardHistoryComponent implements OnInit
{

  constructor(private cds: CacheDataService,
    private kbs: KanbanService,
    private translate: TranslateService) { }
  @Input() card: KanbanCard;

  ngOnInit()
  {
    this.kbs.getCardActivities(this.card.id).subscribe(activities =>
    {
      for (let i = 0; i < activities.length; i++)
      {
        activities[i].user = this.cds.userCollection.find(x => x.id == activities[i].user.id)
      }
      this.card.activities = activities;
    })
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (user)
      return user.avatar;
    return ""
  }

  getNotificationText(activity: KanbanActivity)
  {
    let text = activity.user.name + " " + activity.user.surname + " ";
    switch (activity.parameters.length)
    {
      case 0:
        this.translate.get(activity.codeText).subscribe(x => text += x);
        break;
      case 1:
        this.translate.get(activity.codeText, { v: activity.parameters[0] }).subscribe(x => text += x);
        break;
      case 2:
        this.translate.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1] }).subscribe(x => text += x);
        break;
      case 3:
        this.translate.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1], v3: activity.parameters[2] }).subscribe(x => text += x);
        break;
    }

    return text;
  }

}
