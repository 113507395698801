export class ChatMessage
{
    id: number
    createdUtc: Date
    creatorId: number
    discussionId: number
    receiverId: number
    replyMessage: ChatMessage
    message: string
    mark: boolean
    remove: boolean
}
