import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanListComponent } from './kanban-list.component';
import { FormsModule } from '@angular/forms';
import { KanbanCardModule } from '../kanban-card/kanban-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { KanbanMoveListModule } from '../kanban-move-list/kanban-move-list.module';
import { EditListSortModule } from '../edit-list-sort/edit-list-sort.module';
import { EditListStyleModule } from '../edit-list-style/edit-list-style.module';
import { KanbanActionsCreationCardModule } from '../kanban-actions-creation-card/kanban-actions-creation-card.module';
import { KanbanActionsManagerModule } from '../kanban-actions-manager/kanban-actions-manager.module';
import { AvatarUserModule } from '../../../../../src/app/pipes/avatar-user/avatar-user.module';
import { NameUserPipeModule } from '../../../../../src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { KanbanListActionsEditorModule } from '../kanban-list-actions-editor/kanban-list-actions-editor.module';
import { KanbanCardTemplateInputManagerModule } from '../kanban-card-template-input-manager/kanban-card-template-input-manager.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzInputModule,
    NzPopoverModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    KanbanCardModule,
    TranslateModule,
    KanbanCardTemplateInputManagerModule,
    DragDropModule,
    NzAvatarModule,
    NzSelectModule,
    NzDividerModule,
    NzPopconfirmModule,
    KanbanMoveListModule,
    EditListSortModule,
    EditListStyleModule,
    KanbanActionsCreationCardModule,
    KanbanActionsManagerModule,
    AvatarUserModule,
    NameUserPipeModule,
    KanbanListActionsEditorModule
  ],
  declarations: [KanbanListComponent],
  exports: [KanbanListComponent]
})
export class KanbanListModule { }
