import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextCompareService
{

  constructor() { }

  contains(text1: string, text2: string)
  {
    if (!text2)
      return true;
    if (!text1)
      return false;
    let formattedText1 = text1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let formattedText2 = text2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    if (formattedText1.includes(formattedText2))
      return true;
    return false;
  }

  equals(text1: string, text2: string)
  {
    if (!text2)
      return true;
    if (!text1)
      return false;
    let formattedText1 = text1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let formattedText2 = text2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    if (formattedText1.includes(formattedText2) && formattedText2.includes(formattedText1))
      return true;
    return false;
  }

}
