import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanListActionsEditorComponent } from './kanban-list-actions-editor.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NameTeamPipeModule } from 'src/app/pipes/name-team-pipe/name-team-pipe.module';
import { KanbanBoardTreeModule } from '../kanban-board-tree/kanban-board-tree.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

@NgModule({
  imports: [
    CommonModule,
    NzTabsModule,
    TranslateModule,
    DragDropModule,
    NzToolTipModule,
    NzGridModule,
    FormsModule,
    NzSelectModule,
    NameUserPipeModule,
    NameTeamPipeModule,
    NzGridModule,
    NzBadgeModule,
    KanbanBoardTreeModule,
    NzDividerModule,
    NzButtonModule,
    NzIconModule
  ],
  declarations: [KanbanListActionsEditorComponent],
  exports: [KanbanListActionsEditorComponent]
})
export class KanbanListActionsEditorModule { }
