import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { KanbanService } from '../../services/kanban.service';
import { KanbanCard } from '../../entities/Card';
import { Subscription } from 'rxjs';
import { KanbanBoard } from '../../entities/Board';
import { KanbanList } from '../../entities/List';
import { XAxisComponent } from '@swimlane/ngx-charts';
import { ReaffectCardLabel } from '../../entities/reaffect-card-label';
import { ColorService } from 'src/app/services/color.service';
import { KanbanTreeSelectedInfos } from '../../entities/kanban-tree-selected-infos';
import { GlobalLabel } from '../../entities/Label';
import { CacheDataService } from 'src/app/services/cache-data.service';

@Component({
  selector: 'app-kanban-label-cards',
  templateUrl: './kanban-label-cards.component.html',
  styleUrls: ['./kanban-label-cards.component.scss']
})
export class KanbanLabelCardsComponent implements OnInit
{

  constructor(public kbs: KanbanService,
    public cds: CacheDataService,
    public cs: ColorService) { }

  @Input() label: GlobalLabel;

  archivesNodes: any[] = [];
  selectedId = 0;
  selectedType = "";
  filterValue = "";
  previewedBoard: KanbanBoard = null;
  previewedList: KanbanList = null;
  previewedCard: KanbanCard = null;
  dataIsLoading = false;
  allchecked = false;
  labelSelectorVisible = false;
  dataHasLoaded = false;
  boards: KanbanBoard[] = [];
  allCards: KanbanCard[] = [];
  freeCards: KanbanCard[] = [];
  subscription: Subscription;
  selectedIdCard: number[] = [];
  showArchived = false;
  ngOnInit()
  {
    this.subscription = this.kbs.reloadBoardArchivesSub.subscribe(() =>
    {
      this.initArchives();
    })
    this.initArchives();
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  getContainerHeight(padding: number)
  {
    return (window.innerHeight - padding) * 0.8 + "px";
  }

  getFontColor(color: string)
  {
    return this.cs.getFontColor(color);
  }

  changeArchiveMode(archived)
  {
    this.initArchives();
  }

  initArchives()
  {
    this.previewedCard = null;
    this.selectedType = "";
    this.dataHasLoaded = false;
    if (this.showArchived)
    {
      this.kbs.getArchivedCardsAssociatedToLabel(this.label.id).subscribe(x =>
      {
        this.allCards = x;
        this.setData();
      })
    }
    else
    {
      this.kbs.getCardsAssociatedToLabel(this.label.id).subscribe(x =>
      {
        this.allCards = x;
        this.setData();
      })
    }

  }

  setData()
  {
    this.boards = [];
    this.freeCards = [];
    for (let card of this.allCards)
    {
      let board = this.boards.find(b => b.id == card.boardId);
      if (board)
      {
        let list = board.lists.find(l => l.id == card.listId);
        if (list)
        {
          list.cards.push(card);
        }
        else if (card.listId)
        {
          list = new KanbanList();
          board.lists.push(list);
          list.id = card.listId;
          list.title = card.listName;
          list.cards.push(card);
        }
      }
      else if (card.boardId)
      {
        board = new KanbanBoard();
        board.id = card.boardId;
        board.title = card.boardName;
        board.bgColor = card.bgColor;
        this.boards.push(board);

        let list = new KanbanList();
        board.lists.push(list);
        list.id = card.listId;
        list.title = card.listName;
        list.cards.push(card);
      }
      else this.freeCards.push(card);
    }
    this.dataHasLoaded = true;
  }

  onSelection(data: KanbanTreeSelectedInfos)
  {
    if (!data.card)
      return;
    this.previewedCard = null;
    this.dataIsLoading = true;
    this.selectedId = data.card.id;
    this.kbs.getCardInfos(this.selectedId).subscribe(card =>
    {
      this.kbs.editedCard = card;
      this.dataIsLoading = false;
      this.previewedCard = card;
      this.previewedCard.visible = true;
      this.selectedType = "card";
    })
  }

  onCheck(idcardList: number[])
  {
    this.selectedIdCard = [...idcardList];
  }

  moveButtonIsDisabled()
  {
    return this.selectedIdCard.length == 0;
  }

  moveCardsToAnotherLabel(newlabelid: number)
  {
    this.labelSelectorVisible = true;
    let moveParams = new ReaffectCardLabel();
    moveParams.idNewLabel = newlabelid;
    moveParams.idCardList = this.selectedIdCard;

    this.kbs.moveCardsFromLabel(this.label.id, moveParams).subscribe(() =>
    {
      this.initArchives();
      this.labelSelectorVisible = false;
      this.allchecked = false;
      this.selectedIdCard = [];
    })
  }
}
