import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KanbanEditCardService
{
  sendMail: Subject<number> = new Subject<number>();
  showValue = 0;
  typeMove = "move";
  /*
  1 : checklist
  2 : planning
  3 : attachments
  4 : historique
  5 : tiers
  6 : actions
  7 : copier/déplacer
  8 : envoyer un mail
  9 : effectuer un appel
  10: commentaires
  */
  constructor() { }

}
