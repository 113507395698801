import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../services/url-api.service';
import { ThirdPartyEntities } from '../components/AttachmentsEntities/ThirdPartyEntities'
import { CredInfos } from '../components/attachmentsEntities/CredInfos';
import { AttachmentsEntities } from '../components/attachmentsEntities/AttachmentsEntities';
import { InfoByFileID } from '../components/attachmentsEntities/InfoByFileID';
import { KanbanAttachment } from '../kanban/entities/Attachment';
import { MessagerieService } from '../messagerie/messagerie.service';
import { AuthService } from './auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CredentialsDataService } from '../credentials/services/data/credentials-data.service';
import { Credgroupe } from '../components/attachmentsEntities/Credgroupe';
import { GenericViewerData } from '../components/attachmentsEntities/GenericViewerData';
import { KanbanEditCardService } from '../kanban/services/kanban-edit-card.service';
import { SettingsService } from '../settings/services/settings.service';
import { AttachmentService } from './attachment.service';
import { MailEntities } from '../components/attachmentsEntities/MailEntities';
import { CardEntities } from '../components/attachmentsEntities/CardEntities';
import { DataFilter } from '../components/attachmentsEntities/DataFilter';
import { FilterBar } from '../components/attachmentsEntities/FilterBar';
import { BoardEntities } from '../components/attachmentsEntities/BoardEntities';
import { CheckFileExist } from '../entities/check-file-exist';
import { AllHeaderData } from '../components/attachmentsEntities/AllData';
import { KanbanService } from '../kanban/services/kanban.service';
import { Receivedmail } from '../messagerie/entities/receivedmail';
import { MessagerieAccountAlias } from '../messagerie/entities/messagerie-account-alias';
declare function getMD5(blob): any;

@Injectable({
  providedIn: 'root'
})
export class AttachmentsModuleService
{

  constructor(private http: HttpClient,
    public translate: TranslateService,
    public mss: MessagerieService,
    private sanitizer: DomSanitizer,
    private router: Router,
    public message: NzMessageService,
    private translateService: TranslateService,
    public authService: AuthService,
    public credService: CredentialsDataService,
    public kecs: KanbanEditCardService,
    public ss: SettingsService,
    public attservice: AttachmentService,
    private msg: NzMessageService,
    public KanbanService: KanbanService,
    private auth: AuthService,
  ) { }


  typeBase: string = "Attachments";

  //<---------------- DATA OBJECT FOR THE SERVER---------------->
  DataFilter: DataFilter = new DataFilter("");
  FilterBar: FilterBar = new FilterBar;
  //<---------------- ALL DATA---------------->
  AttachmentsData: AttachmentsEntities[] = [];

  //<---------------- GENERIC ATTACHMENTS DATA---------------->
  GenericAttachmentsHeaderData: AllHeaderData[] = [];
  GenericAttachmentsListData: AttachmentsEntities[] = [];

  //<---------------- CRED GROUP LIST FOR THE FILTER SELECT BY CRED GROUP---------------->
  CredGroupe: Credgroupe[] = [];

  //<---------------- GENERIC ATTACHMENTS DATA (VIEWER PART)---------------->
  GenericViewerData: GenericViewerData = null;


  //<---------------- Lists of names linked to something (display in the viewer part when over) ---------------->
  ThirdPartyNameList: ThirdPartyEntities[] = [];
  CardNameList: CardEntities[] = [];
  CredNameList: CredInfos[] = [];
  MailNameList: MailEntities[] = [];
  BoardNameList: BoardEntities[] = [];

  //<---------------- URL FOR PREVIEW---------------->
  urlBase64: string;

  //<---------------- USEFULL TO RENAME THE FILENAME------------->
  editAttachmentName = "";
  editAttachmentExtension = "";
  isEditingAttachmentName = false;
  isIdfile = 0; //USE TO RENAME THE FILENAME SELECTED

  //<---------------- BOOLEAN FOR THE FULLSCREEN (true after you clicked on the image) ---------------->
  isExpand = false;

  //<---------------- USE TO DISPLAY THE SEND MODAL ---------------->
  sendClick = false;

  //<----------------USE TO DISPLAY THE VIEWERPART (true when a file is clicked)---------------->
  ViewerBoolean: boolean = false;

  //<---------------- USE TO DISPLAY THE THIRD PARTY MODAL ( = Id of the thirdparty whe clicked on in the list of third linked ine the viewer part)---------------->
  editedPPId = 0;

  timeout: NodeJS.Timeout;

  //<---------------- USE TO SET THE INDEX OF THE COLOR TAB FOR THE HEADER (AttachmentsHeader)---------------->
  index: number = 0;

  //<---------------- USE TO DISPLAY THE MAIL CONTENT---------------->
  displayMailBool: boolean = false;
  undisplayMailBool: boolean = false;
  idMail: number = 0;
  mailView: Receivedmail = null;
  //<---------------- USE TO SET THE COLOR OF HEADER'S NAME---------------->
  colorStringHeader: string = 'black';


  //<---------------- LIST OF BOARD FOR THE FILTER BY BOARDS IN THE BOARD PART---------------->
  BoardList = [];

  thirdpartyColorP = ' #7ec3da';
  thirdpartyColorM = '#F08080';
  thirdpartyColorHeader = 'rgb(16, 142, 233)';
  credentialColor = 'rgba(17, 187, 85, 0.8)';
  boardColor = 'rgb(113, 65, 23)';
  cardColor = '#F1B83D';
  mailColor = '#cd0000';

  //<---------------- USE IN THE SEARCHBAR COMONENT FOR THE DRAG'N DROP FILTER---------------->
  dataMail;
  data;
  dataA;

  saveFilterBool: boolean = true;

  initBase()
  {
    // <------- set all variables to their initial state -------->
    this.ViewerBoolean = false;
    this.urlBase64 = "";
    this.isEditingAttachmentName = false;
    this.isIdfile = 0;
    this.displayMailBool = false;
    this.mailView = null;
    this.GenericViewerData = new GenericViewerData;
    this.DataFilter.isfinish = false;
    this.DataFilter.start = 0;
  }


  color = "gray";
  PathType: string = "";
  //Set the type 
  ClickHeaderButton(s: string)
  {
    this.initBase();
    this.typeBase = s;
    let elems = document.getElementsByClassName("ant-tabs-ink-bar");
    if (this.saveFilterBool == false)
      this.DataFilter = new DataFilter("");
    switch (s)
    {
      case "Attachments":
        this.color = "Gray";
        this.index = 0;
        this.UpdateList();
        break;
      case "ThirdParty":
        this.color = "rgb(16, 142, 233)";
        this.index = 1;
        this.PathType = "third-party/physical-person"
        this.UpdateList();
        break;
      case "Credentials":
        this.color = "rgba(17, 187, 85, 0.8)"
        this.index = 2;
        this.PathType = "credentials"
        this.UpdateList();
        break;
      case "Boards":
        this.color = "rgb(113, 65, 23)"
        this.index = 3;
        this.PathType = "kanban/board"
        this.UpdateList();
        break;
      case "Cards":
        this.color = "#F1B83D"
        this.index = 4;
        this.PathType = "kanban/card"
        this.UpdateList();
        break;
      case "Mails":
        this.color = "#cd0000"
        this.index = 5;
        this.UpdateList();
        break;
    }

    for (let i = 0; i < elems.length; i++)
    {
      let elem = elems.item(i) as HTMLElement;
      elem.style.backgroundColor = this.color;
    }
    this.ss.setSettingsByName("-SaveTypeBase-", this.typeBase + "/" + this.index + "/" + this.color);
  }

  //Update the variable to show or not the viewer part
  UpdateClick()
  {
    this.ViewerBoolean = !this.ViewerBoolean;
  }

  //Update the collapse list
  showInfinite = true;
  UpdateList()
  {
    this.ViewerBoolean = false;
    this.isEditingAttachmentName = false;
    this.DataFilter.number = 50;
    this.DataFilter.isfinish = false;
    this.DataFilter.start = 0;
    this.showInfinite = false;
    document.getElementById("scrolltop").scrollTop = 0; //put the scrollbar on the top
    switch (this.typeBase)
    {
      case "Attachments":
        this.getAllAttachments().subscribe((sh) =>
        {
          this.AttachmentsData = sh;
          if (this.AttachmentsData.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          this.DataFilter.start += this.DataFilter.number;
          this.showInfinite = true;
        });
        break;
      case "ThirdParty":
        this.getThirdPartyWAtt().subscribe((sh) =>
        {
          this.GenericAttachmentsHeaderData = sh;
          for (let s of sh)
          {
            if (s.type == 1)
              s.color = this.thirdpartyColorP;
            else
              s.color = this.thirdpartyColorM;
          }
          if (this.GenericAttachmentsHeaderData.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          this.DataFilter.start += this.DataFilter.number;
        });
        break;
      case "Credentials":
        this.getCredWAtt().subscribe((sh) =>
        {
          this.GenericAttachmentsHeaderData = sh;
          for (let s of sh)
          {
            s.color = this.credentialColor;
          }
          this.colorStringHeader = this.credentialColor;
          if (this.GenericAttachmentsHeaderData.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          this.DataFilter.start += this.DataFilter.number;
        });
        this.getGroupCred().subscribe((s) =>
        {
          this.CredGroupe = s;
        });;
        break;

      case "Boards":
        this.getBoardWAtt().subscribe((sh) =>
        {
          this.GenericAttachmentsHeaderData = sh;
          for (let s of sh)
          {
            s.color = s.boardColor;
          } if (this.GenericAttachmentsHeaderData.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          this.DataFilter.start += this.DataFilter.number;
        });
        this.KanbanService.getAllBoardAndListForUser().subscribe(boardCollection =>
        {
          this.DataFilter.isfinish = true;
          this.DataFilter.start += this.DataFilter.number;
        });
        break;
      case "Mails":
        this.getMailWAtt().subscribe((sh) =>
        {
          this.GenericAttachmentsHeaderData = sh;
          for (let s of sh)
          {
            s.color = this.mailColor;
          }
          this.colorStringHeader = this.mailColor;
          if (this.GenericAttachmentsHeaderData.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          this.DataFilter.start += this.DataFilter.number;
        });
        break;
      case "Cards":
        this.getCardWAtt().subscribe((sh) =>
        {
          this.GenericAttachmentsHeaderData = sh;
          for (let s of sh)
          {
            s.color = this.cardColor;
          }
          this.colorStringHeader = this.cardColor;
          if (this.GenericAttachmentsHeaderData.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          this.DataFilter.start += this.DataFilter.number;
        })
        break;
    }

  }


  //<----------------update the attachment's list---------------->
  UpdateListAtt(id: number)
  {
    this.DataFilter.start = 0;
    this.DataFilter.isfinish = false;
    switch (this.typeBase)
    {
      case "Attachments":
        this.getAllAttachments().subscribe((sh) =>
        {
          this.AttachmentsData = sh;
        });
        break;
      case "ThirdParty":
        this.getThirdPartyAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh
        });
        break;
      case "Credentials":
        this.getCredentialsAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
        });
        break;
      case "Boards":
        this.getBoardAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
        });
        break;
      case "Mails":
        this.getMailsAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
        })
        break;
      case "Cards":
        this.getCardAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
        })
        break;
    }
  }

  //<-------- Get the attachments when the CollapsePanel is clicked (param = ID) -------->
  LoadData(id)
  {
    switch (this.typeBase)
    {
      case "ThirdParty":
        this.getThirdPartyAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
          let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id);
          this.colorStringHeader = kb.color;
        });
        break;
      case "Credentials":
        this.getCredentialsAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
        });
        break;
      case "Boards":
        this.getBoardAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;

          let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id);
          this.colorStringHeader = kb.boardColor;
        });
        break;
      case "Mails":
        this.getMailsAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
        })
        break;
      case "Cards":
        this.getCardAttachments(id).subscribe((sh) =>
        {
          this.GenericAttachmentsListData = sh;
        })
        break;
    }
    if (this.ViewerBoolean == true) //<-------- If we click on a credential then on a file (so the viewer part is display) and we click again on a credential (same or not, it dosnt matter) the viewer part will be hide -------->
      this.UpdateClick();         //<-------- If we click on a credential then on a credential, it show the attachments of the credentials or (if its the same cred we clicked) it hide the attachments of the credential -------->
    this.id = id;
  }




  //<-------- Get the info of the file clicked usefull for the viewer part  -------->
  GetInfoFileCLick(idFile: number, id: number)
  {
    this.GenericViewerData = new GenericViewerData;
    let dataHeader = this.GenericAttachmentsHeaderData.find(x => x.id == id);
    if (dataHeader)
    {
      this.GenericViewerData.receiveUTC = dataHeader.receiveUTC;
      this.GenericViewerData.NamTitle = dataHeader.fullName;
      this.GenericViewerData.id = dataHeader.id;
      this.GenericViewerData.from = dataHeader.from;
      this.GenericViewerData.to = dataHeader.to;
      this.GenericViewerData.bgColor = dataHeader.boardColor;
      this.GenericViewerData.type = dataHeader.type;
      if (this.typeBase == 'Credentials')
        this.GenericViewerData.idcred = dataHeader.id;
    }

    let data = this.GenericAttachmentsListData.find(x => x.id == idFile);
    if (data)
    {
      this.GenericViewerData.idfile = data.id;
      if (this.typeBase != 'Credentials')
        this.GenericViewerData.idcred = data.idCredentials;
      this.GenericViewerData.filename = data.fileName;
      this.GenericViewerData.size = data.size;
      this.GenericViewerData.createdUTC = data.createdUtc;
      this.GenericViewerData.boardlinked = data.board;
      this.GenericViewerData.credlinked = data.cred;
      this.GenericViewerData.cardlinked = data.card;
      this.GenericViewerData.maillinked = data.mail;
      this.GenericViewerData.thirdlinked = data.thirdParty;
    }

    this.GenericViewerData.deleteTemplate = false;
    this.displayMailBool = false;
    if (this.ViewerBoolean == false)
    {
      this.UpdateClick();
    } else
    {
      this.ViewerBoolean = false;
      setTimeout(() =>
      {
        this.ViewerBoolean = true;
      }, 100);
    }
  }

  //ADD A FILE
  updateAttachments({ file, fileList }: { [key: string]: any }, id)
  {
    const status = file.status;
    if (status === 'done')
    {
      getMD5(
        file.originFileObj
      ).then(
        res =>
        {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
          {
            let atttp = new AttachmentsEntities();
            atttp.fileName = file.name;
            atttp.id = 0;
            atttp.size = file.size;
            let att = new KanbanAttachment();
            att.name = file.name;
            att.url = reader.result.toString();
            att.thumbnail = att.url;
            att.id = 0;
            att.size = file.size;

            let CheckFileExistdto = new CheckFileExist();
            CheckFileExistdto.attachment = att;
            CheckFileExistdto.id = id;
            CheckFileExistdto.type = this.PathType;
            CheckFileExistdto.crc = res;
            this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/check-file-exist`, CheckFileExistdto).subscribe(ret =>
            {
              switch (ret)
              {
                case -1:
                  this.translateService.get("GENERIC-ERROR.FILE-ASSOCIATED").subscribe(text => { this.msg.create('error', text); })
                  break;
                case 0:
                  switch (this.typeBase)
                  {
                    case "ThirdParty":
                      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${id}/attachment`, att).subscribe(x =>
                      {
                        if (x > 0)
                        {
                          att.id = x;

                          this.UpdateListAtt(id);

                          let kb = this.GenericAttachmentsHeaderData.find(y => y.id == id)
                          if (kb)
                            kb.nbAttachments++;
                        }
                        else this.translateService.get("GENERIC-ERROR.FILE-ASSOCIATED").subscribe(text => { this.msg.create('error', text); })
                      });
                      break;
                    case "Credentials":
                      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${id}/attachment`, att).subscribe(x =>
                      {
                        if (x > 0)
                        {
                          att.id = x;
                          this.UpdateListAtt(id);

                          let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id)
                          if (kb)
                            kb.nbAttachments++;
                        }
                        else this.translateService.get("GENERIC-ERROR.FILE-ASSOCIATED").subscribe(text => { this.msg.create('error', text); })
                      });
                      break;
                    case "Boards":
                      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${id}/attachment`, att).subscribe(x =>
                      {
                        this.UpdateListAtt(id);
                        if (x > 0)
                        {
                          att.id = x;
                          let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id)
                          if (kb)
                            kb.nbAttachments++;
                        }
                        else this.translateService.get("GENERIC-ERROR.FILE-ASSOCIATED").subscribe(text => { this.msg.create('error', text); })
                      });
                      break;
                    case "Cards":
                      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${id}/attachment`, att).subscribe(x =>
                      {
                        this.UpdateListAtt(id);
                        if (x > 0)
                        {
                          att.id = x;
                          let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id)
                          if (kb)
                            kb.nbAttachments++;
                        }
                        else this.translateService.get("GENERIC-ERROR.FILE-ASSOCIATED").subscribe(text => { this.msg.create('error', text); })
                      });
                      break;
                  }
                  break;
                default:
                  this.UpdateList();
                  switch (this.typeBase)
                  {
                    case "ThirdParty":
                      att.id = ret;
                      this.UpdateListAtt(id);
                      let b = this.GenericAttachmentsHeaderData.find(x => x.id == id)
                      if (b)
                        b.nbAttachments++;
                      break;
                    case "Credentials":
                      att.id = ret;
                      this.UpdateListAtt(id);
                      let k = this.GenericAttachmentsHeaderData.find(x => x.id == id)
                      if (k)
                        k.nbAttachments++;
                      break;
                    case "Boards":
                      att.id = ret;
                      this.UpdateListAtt(id);
                      let keb = this.GenericAttachmentsHeaderData.find(x => x.id == id)
                      if (keb)
                        keb.nbAttachments++;
                      break;
                    case "Cards":
                      att.id = ret;
                      this.UpdateListAtt(id);
                      let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id)
                      if (kb)
                        kb.nbAttachments++;
                      break;
                  }
              }
            });
          });
          reader.readAsDataURL(file.originFileObj);
        },
        err => console.error(err)
      );
    } else if (status === 'error')
    {
    }
  }

  //<---------------- BUTTONS ATTACHMENTS ---------------->
  DownloadAtt(idfile: number, fileName: string)
  {
    this.stopPropagation();
    this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/Download/${idfile}`).subscribe(x =>
    {
      var byte = this.base64ToArrayBuffer(x);
      this.saveByteArray(fileName, byte);
    });
  }

  //<---------------- RENAME FILENAME---------------->
  editNameMessage: string; //message when the lenght of the newname = 0
  renameAttachmentAtt(file: AttachmentsEntities)
  {
    this.stopPropagation();
    let newname;

    if (this.editAttachmentName.length > 0)
    {
      newname = this.editAttachmentName + "." + this.editAttachmentExtension;
      this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/rename/${file.id}`, newname).subscribe(x =>
      {
        file.fileName = newname;
        this.isEditingAttachmentName = false;
        this.isIdfile = 0;
        if (this.GenericViewerData)
          this.GenericViewerData.filename = newname;
        this.UpdateList();
      });
    } else
    {
      this.translate.get("ATTACHMENTS.EDIT-NAME").subscribe(x => this.editNameMessage = x);
      this.message.info(this.editNameMessage);
      return;
    }
  }

  //<---------------- UNLINK THE FILE (if linked to multiple things) ---------------->
  Unlink(idfile: number, id: number)
  {
    this.stopPropagation();
    switch (this.typeBase)
    {
      case "Attachments":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/${idfile}`).subscribe(x =>
        {
          this.AttachmentsData.splice(this.AttachmentsData.findIndex(x => x.id == idfile), 1);
          this.UpdateListAtt(id);
        });
        break;
      case "ThirdParty":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${id}/attachment/${idfile}`).subscribe(x =>
        {
          this.GenericAttachmentsHeaderData.splice(this.GenericAttachmentsHeaderData.findIndex(x => x.id == idfile), 1)
          this.UpdateListAtt(id);
          let tp = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (tp)
            tp.nbAttachments--;
          if (tp.nbAttachments < 1)
            this.UpdateList();
        });
        break;
      case "Credentials":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${id}/attachment/${idfile}`).subscribe(x =>
        {
          this.GenericAttachmentsHeaderData.splice(this.GenericAttachmentsHeaderData.findIndex(x => x.id == idfile), 1)
          this.UpdateListAtt(id);
          let cr = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (cr)
            cr.nbAttachments--;
          if (cr.nbAttachments < 1)
            this.UpdateList();
        });
        break;
      case "Boards":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${id}/attachment/${idfile}`).subscribe(x =>
        {
          this.GenericAttachmentsHeaderData.splice(this.GenericAttachmentsHeaderData.findIndex(x => x.id == idfile), 1)
          this.UpdateListAtt(id);
          let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (kb)
            kb.nbAttachments--;
          if (kb.nbAttachments < 1)
            this.UpdateList();
        });
        break;
      case "Cards":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${id}/attachment/${idfile}`).subscribe(x =>
        {
          this.GenericAttachmentsHeaderData.splice(this.GenericAttachmentsHeaderData.findIndex(x => x.id == idfile), 1);
          this.UpdateListAtt(id);
          let cd = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (cd)
            cd.nbAttachments--;
          if (cd.nbAttachments < 1)
            this.UpdateList();
        });
        break;
    }
    this.ViewerBoolean = false;
  }

  //<---------------- DELETE THE FILE ---------------->
  DeleteView(idfile: number, id: number)
  {
    switch (this.typeBase)
    {
      case "Attachments":
        if (id)
        {
          this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/realdelete/${idfile}/${id}`).subscribe(x =>
          {
            this.UpdateListAtt(id);
          });
        } else
        {
          this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/realdelete/${idfile}/0`).subscribe(x =>
          {
            this.UpdateListAtt(id);
          });
        }
        break;
      case "ThirdParty":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/realdelete/${idfile}/${id}`).subscribe(x =>
        {
          this.UpdateListAtt(id);
          let tp = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (tp)
            tp.nbAttachments--;
          if (tp.nbAttachments < 1)
            this.UpdateList();
        });

        break;
      case "Credentials":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/realdelete/${idfile}/${id}`).subscribe(x =>
        {
          this.UpdateListAtt(id);
          let cr = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (cr)
            cr.nbAttachments--;
          if (cr.nbAttachments < 1)
            this.UpdateList();
        });
        break;
      case "Boards":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/realdelete/${idfile}/${id}`).subscribe(x =>
        {
          this.UpdateListAtt(id);
          let kb = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (kb)
            kb.nbAttachments--;
          if (kb.nbAttachments < 1)
            this.UpdateList();
        });
        break;
      case "Cards":
        this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/realdelete/${idfile}/${id}`).subscribe(x =>
        {
          this.UpdateListAtt(id);
          let cd = this.GenericAttachmentsHeaderData.find(x => x.id == id)
          if (cd)
            cd.nbAttachments--;
          if (cd.nbAttachments < 1)
            this.UpdateList();
        });
        break;
    }
    this.ViewerBoolean = false;
  }



  initFileRename(fileName: string, idfile)
  {
    this.stopPropagation();
    this.editAttachmentExtension = fileName.split('.').pop();
    this.editAttachmentName = fileName.substring(0, fileName.length - this.editAttachmentExtension.length - 1);
    this.isIdfile = idfile;
  }

  initFileRenameView(fileName: string)
  {
    this.editAttachmentExtension = fileName.split('.').pop();
    this.editAttachmentName = fileName.substring(0, fileName.length - this.editAttachmentExtension.length - 1);
    this.isEditingAttachmentName = true;
  }

  renameAttachmentView(idfile: number, id: number)
  {

    this.stopPropagation();
    let newname;
    if (this.editAttachmentName.length > 0)
    {
      newname = this.editAttachmentName + "." + this.editAttachmentExtension;
      switch (this.typeBase)
      {
        case "Attachments":
          this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/rename/${idfile}`, newname).subscribe(x =>
          {
            this.GenericViewerData.filename = newname;
            this.UpdateListAtt(id);
          });
          break;
        case "ThirdParty":
          this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${id}/attachment/${idfile}`, newname).subscribe(x =>
          {
            this.GenericViewerData.filename = newname;
            this.UpdateListAtt(id);
          });
          break;
        case "Credentials":
          this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${id}/attachment/${idfile}`, newname).subscribe(x =>
          {
            this.GenericViewerData.filename = newname;
            this.UpdateListAtt(id);
          });
          break;
        case "Boards":
          this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/${id}/attachment/${idfile}`, newname).subscribe(x =>
          {
            this.GenericViewerData.filename = newname;
            this.UpdateListAtt(id);
          });
          break;
        case "Mails":
          this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/rename/${idfile}`, newname).subscribe(x =>
          {
            this.GenericViewerData.filename = newname;
            this.UpdateListAtt(id);
          });
          break;
        case "Cards":
          this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${id}/attachment/${idfile}`, newname).subscribe(x =>
          {
            this.GenericViewerData.filename = newname;
            this.UpdateListAtt(id);
          });
          break;
      }
      this.isEditingAttachmentName = false;
      this.isIdfile = 0;
    } else
    {
      this.translate.get("ATTACHMENTS.EDIT-NAME").subscribe(x => this.editNameMessage = x);
      this.message.info(this.editNameMessage);
      return;
    }

  }

  sendByMail(idfile: number, filename: string)
  {
    this.stopPropagation();
    let alias = new MessagerieAccountAlias();
    alias.displayName = this.authService.connectedUser.mail;
    alias.mail = alias.displayName;
    this.mss.emailToInit.from = alias;
    let att = new KanbanAttachment();
    att.id = idfile;
    att.name = filename;
    this.mss.emailToInit.attachments.push(att);
    this.mss.editMail();
  }

  CloseEditing()
  {
    this.stopPropagation();
    this.isIdfile = 0;
    this.isEditingAttachmentName = false;
  }

  //<---------------- BUTTONS NEEDS ---------------->

  //use to add a file to a (third/cred/board)
  getAttachmentUri()
  {
    return UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/attachment`;
  }

  //use to download a file (download button)
  base64ToArrayBuffer(base64)
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  //use to download a file (download button)
  saveByteArray(reportName: string, byte: Uint8Array)
  {
    var blob = new Blob([byte], { type: "application/jpg" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  //<---------------- VIEWER NEEDS ---------------->
  nonSavedInfos = false;
  nonSavedMessage: any;
  updateSavedInfos(hasChanged: boolean)
  { //use to save info on the thirdparty modal (display when we clicked on a third party linked, in the viewer part)
    this.nonSavedInfos = hasChanged;
    if (!this.nonSavedInfos && this.nonSavedMessage)
      this.message.remove();
  }

  //<---------------- CLOSE THE THIRD PARTY MODAL---------------->
  closeThirdPartySheet()
  {
    if (this.nonSavedInfos)
    {
      this.translateService.get("THIRD-PARTY.CALL.THIRD-PARTY-SHEET.ERROR-ONGOING-MODIFICATION").subscribe(x => this.nonSavedMessage = this.message.error(x, { nzDuration: 0 }))
      return;
    }
    this.nonSavedMessage = null;
    this.editedPPId = 0;
  }

  //<---------------- DETECT THE FILETYPE---------------->
  DetectFileType()
  {
    if (this.GenericViewerData)
    {
      if (this.GenericViewerData.filename.toLowerCase().includes('.png') || this.GenericViewerData.filename.toLowerCase().includes('.jpg') || this.GenericViewerData.filename.toLowerCase().includes('.jpeg'))
        return 'img';
      else if (this.GenericViewerData.filename.toLowerCase().includes('.pdf'))
        return 'pdf'
      else
        return 'nothing'
    }
  }

  //use for the visualisation
  getUrl(url: string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  //Show the picture in full screen if we click on
  Expandfile()
  {
    this.isExpand = !this.isExpand;
    this.sendClick = false;
  }

  //when a picture is display ini fullscreen, you can open it in a new tab
  openInNewTab(url: string)
  {
    let newUrl: string = "/attachments/" + this.GenericViewerData.idfile;
    var win = window.open(newUrl, '_blank');
    win.focus();
  }

  ClickCred()
  {
    this.router.navigate(["/Credentials"]);
  }

  //Go to the board we clicked on
  ClickBoard(idboard: number, idfile: number)
  {
    this.router.navigate(["/kanban/" + idboard]);
    let GL = this.GenericAttachmentsListData.find(x => x.id == idfile)
    if (GL.deleteTemplate == true)
    {
      GL.deleteTemplate = false
    }
    if (this.GenericViewerData.deleteTemplate == true)
    {
      this.GenericViewerData.deleteTemplate = false
    }
  }
  ClickMail()
  {
    this.router.navigate(["/messaging/inbox"]);
  }

  //Go to the card we clicked on
  ClickCard(idboard: number, idcard: number, idfile: number)
  {
    this.kecs.showValue = 3;
    this.router.navigate(["/kanban/" + idboard + "/" + idcard]);
    let GL = this.GenericAttachmentsListData.find(x => x.id == idfile)
    if (GL.deleteTemplate == true)
    {
      GL.deleteTemplate = false
    }
    if (this.GenericViewerData.deleteTemplate == true)
    {
      this.GenericViewerData.deleteTemplate = false
    }
  }

  //Show the thirdParty Modal of the ThirdParty we clicked on
  ClickThirdParty(id: number, idfile: number)
  {
    this.editedPPId = id;
    let GL = this.GenericAttachmentsListData.find(x => x.id == idfile)
    if (GL.deleteTemplate == true)
    {
      GL.deleteTemplate = false
    }
    if (this.GenericViewerData.deleteTemplate == true)
    {
      this.GenericViewerData.deleteTemplate = false
    }
  }


  //return a number depending of what filter you choose (up)
  SortUp(item)
  {
    if (item == "NAME-FILTER")
      this.FilterBar.sortNumbername = 10;
    if (item == "DATE-FILTER")
      this.FilterBar.sortNumberdate = 11;
    if (item == "NB-ATT-FILTER")
      this.FilterBar.sortNumbernbatt = 12;
    if (item == "SUBJECT-FILTER")
      this.FilterBar.sortNumbersubject = 13;

    this.DataFilter.FilterBar = this.FilterBar;
    this.UpdateList();

    //put the arrows in grey or black , depending what you clicked
    let upEl = document.getElementById("upSort" + item);
    let downEl = document.getElementById("downSort" + item);
    if (!upEl || !downEl)
      return;
    upEl.style.color = "darkgray";
    upEl.parentElement.style.pointerEvents = "none";
    downEl.style.color = "black";
    downEl.parentElement.style.pointerEvents = "all";
    this.ss.setSettingsByName(this.typeBase + "-AttachmentsComponent", this.FilterBar.sortNumbername + "/" + this.FilterBar.sortNumberdate + "/" + this.FilterBar.sortNumbernbatt + "/" + this.FilterBar.sortNumbersubject + "/" + this.DataFilter.searchBarData + "/" + this.DataFilter.checkBoxP + "/" + this.DataFilter.checkBoxM + "/" + this.DataFilter.checkBoxSender + "/" + this.DataFilter.checkBoxReceiver + "/" + this.DataFilter.searchBarDataM + "/" + this.DataFilter.searchBarDataA + "/" + this.DataFilter.listBoardIds + "/" + this.DataFilter.selectedGroupCredIds);

  }

  //return a number depending of what filter you choose (down)
  SortDown(item)
  {
    if (item == "NAME-FILTER")
      this.FilterBar.sortNumbername = 14;
    if (item == "DATE-FILTER")
      this.FilterBar.sortNumberdate = 15;
    if (item == "NB-ATT-FILTER")
      this.FilterBar.sortNumbernbatt = 16;
    if (item == "SUBJECT-FILTER")
      this.FilterBar.sortNumbersubject = 17;

    console.log("downSort" + item);

    this.DataFilter.FilterBar = this.FilterBar;
    this.UpdateList();

    //put the arrows in grey or black , depending what you clicked
    let upEl = document.getElementById("upSort" + item);
    let downEl = document.getElementById("downSort" + item);
    if (!upEl || !downEl)
      return;
    downEl.style.color = "darkgray";
    downEl.parentElement.style.pointerEvents = "none";
    upEl.style.color = "black";
    upEl.parentElement.style.pointerEvents = "all";
    this.ss.setSettingsByName(this.typeBase + "-AttachmentsComponent", this.FilterBar.sortNumbername + "/" + this.FilterBar.sortNumberdate + "/" + this.FilterBar.sortNumbernbatt + "/" + this.FilterBar.sortNumbersubject + "/" + this.DataFilter.searchBarData + "/" + this.DataFilter.checkBoxP + "/" + this.DataFilter.checkBoxM + "/" + this.DataFilter.checkBoxSender + "/" + this.DataFilter.checkBoxReceiver + "/" + this.DataFilter.searchBarDataM + "/" + this.DataFilter.searchBarDataA + "/" + this.DataFilter.listBoardIds + "/" + this.DataFilter.selectedGroupCredIds);
  }

  //Update the list every 400ms when a key is press on the searchbar
  EnterSearch()
  {
    setTimeout(() =>
    {
      this.UpdateList();
    }, 400);
  }

  //<---------------- Cancel button of the Delete template---------------->
  CancelButton(id, idt)
  {
    if (this.typeBase == 'Attachments')
    {
      let Att = this.AttachmentsData.find(x => x.id == id);
      Att.deleteTemplate = false
    } else
    {
      let GL = this.GenericAttachmentsListData.find(x => x.id == id);
      if (GL.deleteTemplate == true)
      {
        GL.deleteTemplate = false
      }
    }

    if (this.GenericViewerData.deleteTemplate == true)
      this.GenericViewerData.deleteTemplate = false
  }

  stopPropagation()
  {
    event.stopPropagation();
  }

  board: boolean;
  card: boolean;
  cred: boolean;
  thirdparty: boolean;
  mail: boolean;
  LengthThird: number;
  LengthCred: number;
  LengthBoard: number;
  LengthCard: number;
  LengthMail: number;
  UnlinkBool: boolean = false;
  loadNamesForDelete(id: number, idfile: number, board: boolean, card: boolean, cred: boolean, thirdparty: boolean, mail: boolean)
  { //load all the linked names when the buttons delete is clicked
    this.stopPropagation();
    this.board = board;
    this.card = card;
    this.cred = cred;
    this.mail = mail;
    this.thirdparty = thirdparty;


    this.getAllNames(idfile).subscribe((sh) =>
    {
      this.BoardNameList = sh.boards;
      this.CardNameList = sh.cards;
      this.CredNameList = sh.credentials;
      this.ThirdPartyNameList = sh.thirdParty;
      this.MailNameList = sh.mails;
      this.LengthBoard = this.BoardNameList.length;
      this.LengthCard = this.CardNameList.length;
      this.LengthThird = this.ThirdPartyNameList.length;
      this.LengthCred = this.CredNameList.length;
      this.LengthMail = this.MailNameList.length;
      let nb = this.LengthThird + this.LengthBoard + this.LengthCard + this.LengthCred + this.LengthMail; //check if the file is linked to something else or not to see if its a delete or an unlink
      if (nb == 1)
        this.UnlinkBool = true;
      else
        this.UnlinkBool = false;
    });

  }



  ColorVariable: string;
  id: number;

  onScrollDown()
  {
    if (this.DataFilter.isfinish)
    {
      return;
    }
    switch (this.typeBase)
    {
      case "Attachments":
        this.getAllAttachments().subscribe((sh) =>
        {
          if (sh.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          for (let s of sh)
            this.AttachmentsData.push(s);
          this.AttachmentsData = [...this.AttachmentsData];
        });
        break;
      case "ThirdParty":
        this.getThirdPartyWAtt().subscribe((sh) =>
        {
          if (sh.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          for (let s of sh)
          {
            this.GenericAttachmentsHeaderData.push(s);
            if (s.type == 1)
              s.color = this.thirdpartyColorP;
            else
              s.color = this.thirdpartyColorM;
          }
        });
        break;
      case "Credentials":
        this.getCredWAtt().subscribe((sh) =>
        {
          if (sh.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          for (let s of sh)
          {
            this.GenericAttachmentsHeaderData.push(s);
            s.color = this.credentialColor;
          }
          this.colorStringHeader = this.credentialColor;
        });
        break;
      case "Boards":
        this.getBoardWAtt().subscribe((sh) =>
        {
          if (sh.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          for (let s of sh)
          {
            this.GenericAttachmentsHeaderData.push(s);
            s.color = s.boardColor;
          }
        });;
        break;
      case "Mails":
        this.getMailWAtt().subscribe((sh) =>
        {
          if (sh.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          for (let s of sh)
          {
            this.GenericAttachmentsHeaderData.push(s);
            s.color = this.mailColor;
          }
          this.colorStringHeader = this.mailColor;
        });
        break;
      case "Cards":
        this.getCardWAtt().subscribe((sh) =>
        {
          if (sh.length < this.DataFilter.number)
            this.DataFilter.isfinish = true;
          for (let s of sh)
          {
            this.GenericAttachmentsHeaderData.push(s);
            s.color = this.cardColor;
          }
          this.colorStringHeader = this.cardColor;
        });
        break;
    }
    this.DataFilter.start += this.DataFilter.number;
  }



  displayMail(id: number, click: number)
  {
    this.stopPropagation();
    this.mailView = null;

    if (this.displayMailBool == true && click == 2 && this.idMail == id)
    {
      this.displayMailBool = false;

    } else
    {
      click = 3;
      this.displayMailBool = true;
    }

    if (this.displayMailBool == false && click == 1 || click == 3)
    {
      this.getMailforDisplay(id).subscribe((m) =>
      {
        m.content = m.contentInnerText;
        this.mailView = m;
        this.ViewerBoolean = false;
        this.displayMailBool = true;
      });
    }
    this.idMail = id;
  }



  //GET PREVIEW ATTACHMENTS :)

  getPreviewAttachments(idFile: number, idCred: number)
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetPreviewAtt/${idFile}/${idCred}`)
  }

  //THIRDPARTY

  getTPName(idfile: number)
  {
    return this.http.get<ThirdPartyEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetTPName/${idfile}`)
  }

  getThirdPartyWAtt()
  {
    return this.http.post<AllHeaderData[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/watt/`, this.DataFilter)
  }

  getThirdPartyAttachments(idThirdParty: number)
  {
    return this.http.get<AttachmentsEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetTPAttachments/${idThirdParty}`)
  }

  //KANBAN

  getBoardName(idfile: number)
  {
    return this.http.get<BoardEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetBoardName/${idfile}`)
  }

  getBoardWAtt()
  {
    return this.http.post<AllHeaderData[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/watt`, this.DataFilter)
  }

  getBoardAttachments(idBoard: number)
  {
    return this.http.get<AttachmentsEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetBoardAttachments/${idBoard}`)
  }

  getCardName(idfile: number)
  {
    return this.http.get<CardEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetCardName/${idfile}`)
  }

  getCardWAtt()
  {
    return this.http.post<AllHeaderData[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/watt`, this.DataFilter)
  }

  getCardAttachments(idCard: number)
  {
    return this.http.get<AttachmentsEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetCardAttachments/${idCard}`)
  }

  //CRED

  getCredWAtt()
  {
    return this.http.post<AllHeaderData[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/credwatt`, this.DataFilter)
  }
  getGroupCred()
  {
    return this.http.get<Credgroupe[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/groupcred`)
  }

  getCredentialsAttachments(idCred: number)
  {
    return this.http.get<AttachmentsEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetCredAttachments/${idCred}`)
  }

  getCredName(idfile: number)
  {
    return this.http.get<CredInfos[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetCredName/${idfile}`)
  }

  //MAIL

  getMailWAtt()
  {
    return this.http.post<AllHeaderData[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/watt`, this.DataFilter)
  }

  getMailsAttachments(IdMail: number)
  {
    return this.http.get<AttachmentsEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetMailAtt/${IdMail}`)
  }

  getMailName(idfile: number)
  {
    return this.http.get<MailEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetMailName/${idfile}`)
  }

  getMailforDisplay(idmail: number)
  {
    return this.http.get<Receivedmail>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${idmail}/getmail`);
  }



  //ATTACHMENTS
  getAllAttachments()
  {
    return this.http.post<AttachmentsEntities[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetAllAttachments`, this.DataFilter)
  }


  //USEFULL
  getAllNames(idfile: number)
  {
    return this.http.get<InfoByFileID>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/GetAllNames/${idfile}`)
  }
  RealDelete(idfile: number, id: number)
  {
    return this.http.delete<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/attachment/realdelete/${idfile}/${id}`)
  }
}
