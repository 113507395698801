import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThirdPartySheetComponent } from './third-party-sheet.component';

const appRoutes: Routes = [
  {
    path: ':id',
    component: ThirdPartySheetComponent
  },
  {
    path: '',
    component: ThirdPartySheetComponent
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(appRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class ThirdPartySheetRoutingModule { }
