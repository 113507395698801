import { Component, OnInit, Input } from '@angular/core';
import { CustomEvent, BORDER_DARKENING, WRITTEN_DARKENING } from '../entities/event';
import { Color } from '../entities/color';

@Component({
  selector: 'app-conflict-alert',
  templateUrl: './conflict-alert.component.html',
  styleUrls: ['./conflict-alert.component.scss']
})
export class ConflictAlertComponent implements OnInit {

  @Input('EVENT') public EVENT: CustomEvent;
  @Input('SERVICE') public SERVICE: any;
  
  
  public primaryColor: Color;
  public secondaryColor: Color;
  public writtenColor: Color;

  constructor() { }

  ngOnInit() {

    this.SERVICE.getListForCalendar().forEach(object => {
      if(object.id == this.EVENT.id_obj)
      {
        this.primaryColor = object.color;
      }
    });;
    this.secondaryColor = new Color(this.primaryColor).darken(BORDER_DARKENING);
    this.writtenColor = new Color(this.primaryColor).darken(WRITTEN_DARKENING);
  }

}
