import { KanbanBoard } from "./Board";
import { KanbanCard } from "./Card";
import { KanbanList } from "./List";

export class KanbanTreeSelectedInfos
{
    board: KanbanBoard = null;
    list: KanbanList = null;
    card: KanbanCard = null;
    selectedType: string;

    constructor(_board?: KanbanBoard, _list?: KanbanList, _card?: KanbanCard, _type?: string)
    {
        this.board = _board ? _board : null;
        this.list = _list ? _list : null;
        this.card = _card ? _card : null;
        this.selectedType = _type ? _type : "card";
    }
}