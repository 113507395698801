import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanBoardActivitiesComponent } from './kanban-board-activities.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TranslateModule } from '@ngx-translate/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { KanbanBoardTreeModule } from '../kanban-board-tree/kanban-board-tree.module';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    TranslateModule,
    NzInputModule,
    NzCheckboxModule,
    InfiniteScrollModule,
    NzPopoverModule,
    NzAvatarModule,
    FormsModule,
    PipesModule,
    NzSelectModule,
    KanbanBoardTreeModule,
    NzIconModule
  ],
  declarations: [KanbanBoardActivitiesComponent],
  exports: [KanbanBoardActivitiesComponent]
})
export class KanbanBoardActivitiesModule { }
