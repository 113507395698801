import { Injectable } from '@angular/core';
import { UrlApiService } from '../../../../../src/app/services/url-api.service';
import { HttpClient } from '@angular/common/http';
import { PhysicalPerson } from '../entities/physical-person';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { KanbanAttachment } from '../../../../../src/app/kanban/entities/Attachment';
import { AuthService } from '../../../../../src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PhysicalPersonService
{

  constructor(private http: HttpClient, private authService: AuthService) { }

  isLoading = false;
  modal: any;
  dataLoaded = false;
  physicalPersonListChange: Subject<PhysicalPerson[]> = new Subject<PhysicalPerson[]>();

  getAllPhysicalPersons()
  {
    return this.http.get<PhysicalPerson[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person`)
  }

  getPhysicalPerson(idpp: number)
  {
    return this.http.get<PhysicalPerson>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${idpp}`)
  }

  createPhysicalPerson(pp: PhysicalPerson, avatar: File)
  {
    let formData: FormData = new FormData();
    if (avatar)
      formData.append("Image", avatar, "avatar.png");
    formData.append("PhysicalPerson", JSON.stringify(pp));
    pp.creationDate = new Date();
    pp.creatorId = this.authService.Access.idUser;
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person`, formData)
  }



  updatePhysicalPerson(pp: PhysicalPerson, avatar: File)
  {
    let formData: FormData = new FormData();
    if (avatar)
      formData.append("Image", avatar, "avatar.png");
    formData.append("PhysicalPerson", JSON.stringify(pp));
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person`, formData)
  }

  archivePhysicalPerson(idpp: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${idpp}/archive`, null)
  }
  restorePhysicalPerson(idpp: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${idpp}/restore`, null)
  }

  getPhysicalPersonColumnPreferences()
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/third-party/physical-person/column-preferences');
  }

  getPhysicalPersonAttachments(idpp: number)
  {
    return this.http.get<KanbanAttachment[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${idpp}/attachment`);
  }

  downloadVcard(idpp: number)
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${idpp}/vcard`);
  }

  addAttachmentToPhysicalPerson(idpp: number, attachment: KanbanAttachment)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${idpp}/attachment`, attachment);
  }

  removeAttachmentFromPhysicalPerson(idpp: number, idfile: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/physical-person/${idpp}/attachment/${idfile}`);
  }
}
