import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanBoardComponent } from './kanban-board.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { KanbanListModule } from '../kanban-list/kanban-list.module';
import { ColorPickerModule } from '../../../../../src/app/components/color-picker/color-picker.module';
import { LoadingModule } from '../../../../../src/app/components/loading/loading.module';
import { AttachmentManagerModule } from '../../../../../src/app/components/attachment-manager/attachment-manager.module';
import { AvatarUserModule } from '../../../../../src/app/pipes/avatar-user/avatar-user.module';
import { InitialUserModule } from '../../../../../src/app/pipes/initial-user/initial-user.module';
import { KanbanUserExiledPipeModule } from '../../../../../src/app/pipes/kanban/kanban-user-exiled-pipe/kanban-user-exiled-pipe.module';
import { KanbanUserFilteredPipeModule } from '../../../../../src/app/pipes/kanban/kanban-user-filtered-pipe/kanban-user-filtered-pipe.module';
import { NameUserPipeModule } from '../../../../../src/app/pipes/name-user-pipe/name-user-pipe.module';
import { KanbanWorkflowSettingsModule } from '../kanban-workflow-settings/kanban-workflow-settings.module';
import { KanbanLabelCardsModule } from '../kanban-label-cards/kanban-label-cards.module';
import { NameTeamPipeModule } from '../../../../../src/app/pipes/name-team-pipe/name-team-pipe.module';
import { InitialTeamPipeModule } from '../../../../../src/app/pipes/initial-team-pipe/initial-team-pipe.module';
import { AvatarTeamPipeModule } from '../../../../../src/app/pipes/avatar-team-pipe/avatar-team-pipe.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { HelpModule } from '../../../../../src/app/components/help/help.module';
import { CreatorDisplayModule } from '../../../../../src/app/components/creator-display/creator-display.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { LabelTitlePipeModule } from '../../../../../src/app/pipes/label-title-pipe/label-title-pipe.module';
import { LabelColorPipeModule } from '../../../../../src/app/pipes/label-color-pipe/label-color-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    NzInputModule,
    NzDividerModule,
    TranslateModule,
    FormsModule,
    NzIconModule,
    NzPopoverModule,
    NzAvatarModule,
    NzProgressModule,
    NzFormModule,
    NzBadgeModule,
    NzButtonModule,
    DragDropModule,
    KanbanListModule,
    ColorPickerModule,
    LoadingModule,
    NzSelectModule,
    NzToolTipModule,
    NzPopconfirmModule,
    AttachmentManagerModule,
    AvatarUserModule,
    InitialUserModule,
    KanbanUserExiledPipeModule,
    KanbanUserFilteredPipeModule,
    NameUserPipeModule,
    KanbanWorkflowSettingsModule,
    KanbanLabelCardsModule,
    NameTeamPipeModule,
    InitialTeamPipeModule,
    AvatarTeamPipeModule,
    LabelTitlePipeModule,
    LabelColorPipeModule,
    HelpModule,
    NzModalModule,
    CreatorDisplayModule
  ],
  declarations: [KanbanBoardComponent],
  exports: [KanbanBoardComponent],
})
export class KanbanBoardModule { }
