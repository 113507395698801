import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePlaylistSpotifyComponent } from './create-playlist-spotify.component';
import { ContentModule } from 'src/app/components/content/content.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [
    CommonModule,
    ContentModule,
    NzFormModule,
    NzInputModule,
    TranslateModule,
    FormsModule
  ],
  declarations: [CreatePlaylistSpotifyComponent],
  exports: [CreatePlaylistSpotifyComponent]
})
export class CreatePlaylistSpotifyModule { }
