import { Pipe, PipeTransform } from '@angular/core';
import { CacheDataService } from 'src/app/services/cache-data.service';

@Pipe({
  name: 'avatarteam',
  pure: false
})
export class AvatarTeamPipe implements PipeTransform
{

  constructor(public cds: CacheDataService) { }

  transform(value: number): string
  {
    return this.cds.getAvatarTeam(value);
  }

}
