export class MeetingMembers
{
    camelineUserIds: number[] = [];
    camelineTeamIds: number[] = [];
    camelinePhysicalPersonIds: PhysicalPersonMember[] = [];
    externalPersonMails: ExternalPersonMember[] = [];
    listMailNotified: string[] = [];
    listMailPresentInReunion: LeavingInfos[] = [];
}

export class PhysicalPersonMember
{
    id: number;
    mail: string;
    token: string;
}

export class ExternalPersonMember
{
    mail: string;
    token: string;
}

export class LeavingInfos
{
    displayname: string;
    mail: string;
    joindate: Date;
    leavedate: Date;
}