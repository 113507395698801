import { OtherPlanningRights } from "./other-planning-rights";

export class PlanningFilterParameters
{
    cardMembersIds: number[] = [];
    listIds: number[] = [];
    othersPlanning: OtherPlanningRights[] = [];
    finishedCards = true;
    start: Date = new Date();
    end: Date = new Date();
    showOwnAgenda = true;
    showCombinedAgenda = true;
    idUser: number = -1;
}