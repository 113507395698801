import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormData } from './entities/form-data';
import { FormManagerService } from './services/form-manager.service';
import { FormLine } from './entities/form-line';
import { CountryManagerService } from '../country-manager/services/country-manager.service';
import { AuthService } from '../services/auth.service';
import { CountryFormTranslation } from '../country-manager/entities/country-form-translation';

@Component({
  selector: 'app-form-manager',
  templateUrl: './form-manager.component.html',
  styleUrls: ['./form-manager.component.scss']
})
export class FormManagerComponent implements OnInit {

  constructor(public fms: FormManagerService,
    private cms: CountryManagerService,
    private as: AuthService) { }

  @Input() lines: FormLine[];
  @Input() fieldTranslations: CountryFormTranslation[];
  @Output() updateLines = new EventEmitter();
  @Input() readonly: boolean;
  ngOnInit() {
  }

  updateOutput()
  {
    this.updateLines.emit(this.lines);
  }

  getText(key: string)
  {
    let fieldTrad = this.fieldTranslations.find(x => x.codelang == this.as.Access.locale && x.fieldKey == key);
    if(fieldTrad)
      return fieldTrad.text;
    fieldTrad = this.fieldTranslations.find(x => x.codelang == "en" && x.fieldKey == key);
    if(!fieldTrad)
      return key;
    return fieldTrad.text;
  }
}
