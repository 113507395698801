import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService
{

  statusSub: Subject<any> = new Subject<any>();

  constructor(
    private translateService: TranslateService,
  ) { }

  getIdByName(dispoValueName)
  {
    switch (dispoValueName)
    {
      case '$$-ONLINE-F1rst-$$':
        return 'F1rst'
      case '$$-BACK-SOON-Thi3d-$$':
        return 'Thi3d'
      case '$$-BUSY-S2cond-$$':
        return 'S2cond'
      case '$$-DO-NOT-DISTURB-Fourt4-$$':
        return 'Fourt4'
      case '$$-APPEAR-OFFLINE-5ive-$$':
        return 'Fi5e'
      case '$$-INACTIF-s6x-$$':
        return 's6x'
      default:
        return 'pers0'
    }
  }

  getUserStatusName(dispoValueName)
  {
    switch (dispoValueName)
    {
      case '$$-ONLINE-F1rst-$$':
        return this.translater('CHAT.DISPO.ONLINE')
      case '$$-BACK-SOON-Thi3d-$$':
        return this.translater('CHAT.DISPO.BACK-SOON')
      case '$$-BUSY-S2cond-$$':
        return this.translater('CHAT.DISPO.BUSY')
      case '$$-DO-NOT-DISTURB-Fourt4-$$':
        return this.translater('CHAT.DISPO.DO-NOT-DISTURB')
      case '$$-APPEAR-OFFLINE-5ive-$$':
        return this.translater('CHAT.DISPO.APPEAR-OFFLINE')
      case '$$-INACTIF-s6x-$$':
        return this.translater('CHAT.DISPO.INACTIVE')
      default:
        return dispoValueName
    }
  }

  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }


}
