export class FilterRelation
{
    id: number;
    title: string;
    filterCode: string;
    color: string;
    isSystem: boolean;
    isGlobal: boolean;
    creatorId: number;
    creationDate: Date;
}