import { Injectable } from '@angular/core';
import { Country } from '../entities/country';
import { UrlApiService } from 'src/app/services/url-api.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryManagerService {

  countryList: Country[] = [];
constructor(private http: HttpClient) { }
getAllCountries()
{
  return this.http.get<Country[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/country`).pipe(tap(x  => this.countryList = x))
}

/*
[{
      type: "textfield",
      label: "STREET-BOITE-POSTALE",
      key: "1",
      line: 1,
      column: 1,
      value: ""
    },
    {
      type: "textfield",
      label: "CITY-PROVINCE/STATE/COUNTRY-POSTAL-CODE",
      key: "2",
      line: 2,
      column: 1,
      value: ""
    }]
*/
}
