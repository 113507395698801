import { Component, OnInit, Input } from '@angular/core';
import { ReservationResource } from '../../entities/resource';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Color, colors } from 'src/app/SDICalendar/calendar/entities/color';

@Component({
  selector: 'app-resource-add-update',
  templateUrl: './resource-add-update.component.html',
  styleUrls: ['./resource-add-update.component.scss']
})
export class ResourceAddUpdateComponent implements OnInit {

  green: Color = colors.green;
  red: Color = colors.red;
  blue: Color = colors.blue;
  constructor(private fb: FormBuilder) { }
  @Input() resource: ReservationResource;
  name: string;
  description: string;
  color: Color;
  colorHex = "";
  hue: string;
  ngOnInit() {
    if (this.resource != null) {
      this.name = this.resource.name;
      if(this.resource.color.hex.hex == this.green.hex.hex)
      this.color = this.green;
      else if(this.resource.color.hex.hex == this.blue.hex.hex)
      this.color = this.blue;
      else if(this.resource.color.hex.hex == this.red.hex.hex)
      this.color = this.red;
      else this.colorHex = this.resource.color.hex.hex;
    }
  }

  submitForm()
  {
    if(!this.name)
      return null;
    if(this.resource == null)
      this.resource = new ReservationResource();
      this.resource.name = this.name;
      this.resource.description = this.description;
      this.resource.color = this.green;
      this.resource.color.hex.hex = this.colorHex;
      //this.resource.color.hex.hex = this.colorHex;
      return this.resource;
  }

}
