import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KanbanCard } from '../kanban/entities/Card';
import { GlobalLabel } from '../kanban/entities/Label';
import { ReaffectCardLabel } from '../kanban/entities/reaffect-card-label';
import { CacheDataService } from '../services/cache-data.service';
import { UrlApiService } from '../services/url-api.service';
import { DeleteLabelReturn } from './entities/delete-label-return';

@Injectable({
  providedIn: 'root'
})
export class GlobalLabelService
{

  constructor(private http: HttpClient, private cds: CacheDataService) { }

  getAllLabels()
  {
    return this.http.get<GlobalLabel[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/label`);
  }

  getAllBoardLabels(idboard: number)
  {
    return this.http.get<GlobalLabel[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/label/board/${idboard}`);
  }

  getCardsAssociatedWithLabel(idlabel: number, archived: boolean)
  {
    return this.http.get<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/label/${idlabel}/cards/archived/${archived}`);
  }

  getTPsAssociatedWithLabel(idlabel: number)
  {
    return this.http.get<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/label/${idlabel}/third-party`);
  }

  createLabel(label: GlobalLabel)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/label`, label);
  }

  updateLabel(label: GlobalLabel)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/label`, label);
  }

  deleteLabel(idlabel: number, force: boolean)
  {
    return this.http.delete<DeleteLabelReturn>(UrlApiService.settings.apiConfig.uriAPI + `/api/label/${idlabel}/force/${force}`);
  }

  reallocateLabel(idlabel: number, idnewlabel: number, module: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/label/${idlabel}/reallocate/${idnewlabel}/module/${module}`, null);
  }

  moveElementBetweenLabel(idlabel: number, idnewlabel: number, reaffectParams: ReaffectCardLabel)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/label/${idlabel}/reallocate/${idnewlabel}`, reaffectParams);
  }

  fullMigration()
  {
    this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/label/full-migration`, null).subscribe();
  }

}
