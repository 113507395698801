import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NzToolTipModule,
    NzIconModule
  ],
  declarations: [HelpComponent],
  exports: [HelpComponent]
})
export class HelpModule { }
