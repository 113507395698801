import { Component, OnInit, Input } from '@angular/core';
import { KanbanBoard } from '../../entities/Board';
import { RelationForListDTO } from '../../entities/list-relation';
import { KanbanService } from '../../services/kanban.service';
import { KanbanCard } from '../../entities/Card';
import { GlobalLabel } from '../../entities/Label';
import { ThirdPartyForCredential } from '../../../../../src/app/credentials/entities/third-party-for-credentials';
import { ColorService } from '../../../../../src/app/services/color.service';
import { CacheDataService } from '../../../../../src/app/services/cache-data.service';
import { ThirdPartySheetEditIdentityComponent } from '../../../../../src/app/call-interface/third-party-sheet-edit-identity/third-party-sheet-edit-identity.component';
import { TranslateService } from '@ngx-translate/core';
import { PhysicalPersonService } from '../../../../../src/app/third-party/physical-person/services/physical-person.service';
import { PhysicalPerson } from '../../../../../src/app/third-party/physical-person/entities/physical-person';
import { MoralPerson } from '../../../../../src/app/third-party/moral-person/entities/moral-person';
import { MoralPersonService } from '../../../../../src/app/third-party/moral-person/services/moral-person.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TextCompareService } from '../../../../../src/app/services/text-compare.service';
import { KanbanActionParameterRequester } from '../../entities/kanban-action-parameter-requester';
import { KanbanActionParameters } from '../../entities/kanban-action-parameters';

@Component({
  selector: 'app-kanban-actions-manager',
  templateUrl: './kanban-actions-manager.component.html',
  styleUrls: ['./kanban-actions-manager.component.scss']
})
export class KanbanActionsManagerComponent implements OnInit
{

  constructor(public kbs: KanbanService, public cs: ColorService, public cds: CacheDataService, public translateService: TranslateService, public modalService: NzModalService,
    private tcs: TextCompareService,
    public pps: PhysicalPersonService, public mps: MoralPersonService) { }

  @Input() board: KanbanBoard;
  @Input() listid: number;
  @Input() card: KanbanCard;
  @Input() relation: RelationForListDTO;
  @Input() trigger: string;
  @Input() kanbanActionParameterRequester: KanbanActionParameterRequester;

  users: number[] = [];
  teams: number[] = [];
  labelList: GlobalLabel[] = [];
  publicLabelList: GlobalLabel[] = [];
  comment = "";
  description = "";
  listMembersToAffect = [];
  listTeamsToAffect = [];
  listLabelsToAffect = [];
  listThirdPartyToAffect = [];
  listUsersToNotified = [];
  listTeamsToNotified = [];
  showComment = false;
  showDescription = false;
  showMembers = false;
  showTeams = false;
  showLabels = false;
  showNotifiedUsers = false;
  showNotifiedTeams = false;
  confirmationText = "";
  dueDate: Date;
  showDueDate = false;
  showThirdParty = false;
  ppListFilter: ThirdPartyForCredential[] = [];
  mpListFilter: ThirdPartyForCredential[] = [];

  ngOnInit()
  {
    if (this.kanbanActionParameterRequester)
      this.newInit();
  }

  newInit()
  {
    this.kbs.ppList = [];
    this.kbs.mpList = [];

    if (this.kanbanActionParameterRequester.comment)
      this.showComment = true;

    if (this.kanbanActionParameterRequester.description)
      this.showDescription = true;

    if (this.kanbanActionParameterRequester.endDate)
      this.showDueDate = true;

    if (this.kanbanActionParameterRequester.notifyUser)
      this.showNotifiedUsers = true;

    if (this.kanbanActionParameterRequester.sendMailTeam)
      this.showNotifiedTeams = true;

    if (this.kanbanActionParameterRequester.memberUser)
    {
      if (this.kanbanActionParameterRequester.memberUserFilter.length > 0)
      {
        let listMemberids = this.kanbanActionParameterRequester.memberUserFilter.map(x => parseInt(x));
        this.users = this.kbs.getUsersForBoard(this.board).filter(x => listMemberids.indexOf(x) >= 0 && this.card.members.findIndex(y => y == x) < 0);
      }
      else this.users = this.kbs.getUsersForBoard(this.board).filter(x => this.card.members.findIndex(y => y == x) < 0);
      this.showMembers = true;
    }

    if (this.kanbanActionParameterRequester.memberTeam)
    {
      if (this.kanbanActionParameterRequester.memberTeamFilter.length > 0)
      {
        let listMemberids = this.kanbanActionParameterRequester.memberTeamFilter.map(x => parseInt(x));
        if (this.board)
          this.teams = this.board.teams.filter(x => listMemberids.indexOf(x) >= 0 && this.card.teams.findIndex(y => y == x) < 0);
      }
      else if (this.board)
        this.teams = this.board.teams.filter(x => this.card.teams.findIndex(y => y == x) < 0);
      this.showTeams = true;
    }

    if (this.kanbanActionParameterRequester.label)
    {
      if (this.kanbanActionParameterRequester.labelFilter.length > 0)
      {
        let listLabelsids = this.kanbanActionParameterRequester.labelFilter.map(x => parseInt(x));
        this.labelList = this.cds.labelList.filter(x => listLabelsids.indexOf(x.id) >= 0 && this.card.labels.findIndex(y => y.id == x.id) < 0);
        this.publicLabelList = this.cds.publicLabelList.filter(x => listLabelsids.indexOf(x.id) >= 0 && this.card.labels.findIndex(y => y.id == x.id) < 0);
      }
      else
      {
        this.labelList = this.cds.labelList.filter(x => this.card.labels.findIndex(y => y.id == x.id) < 0);
        this.publicLabelList = this.cds.publicLabelList.filter(x => this.card.labels.findIndex(y => y.id == x.id) < 0);
      }
      this.showLabels = true;
    }
    this.confirmationText = this.kanbanActionParameterRequester.confirm;

    if (this.kanbanActionParameterRequester.thirdParty)
    {
      this.kbs.ppList = this.cds.physicalPersonCollection.filter(pp => !pp.archived &&
        (this.kanbanActionParameterRequester.thirdPartyFilter.length == 0 || this.kanbanActionParameterRequester.thirdPartyFilter.indexOf(pp.id.toString()) >= 0) &&
        this.card.physicalPersons.findIndex(x => x.id == pp.id) < 0).map(x => new ThirdPartyForCredential(x.id, "", x.firstName, x.lastName, 1, x.phoneNumbers, x.mails));

      this.kbs.mpList = this.cds.moralPersonCollection.filter(pp => !pp.archived &&
        (this.kanbanActionParameterRequester.thirdPartyFilter.length == 0 || this.kanbanActionParameterRequester.thirdPartyFilter.indexOf(pp.id.toString()) >= 0) &&
        this.card.physicalPersons.findIndex(x => x.id == pp.id) < 0).map(x => new ThirdPartyForCredential(x.id, x.name, "", "", 1, x.phoneNumbers, x.mails));
      this.showThirdParty = true;
    }
  }


  createPhysicalPerson()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.CALL.PHONE-MENU.PROFILE").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);

    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: ThirdPartySheetEditIdentityComponent,
      nzWidth: '80%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzFooter: [{
        label: save,
        type: 'primary',
        onClick: (result) =>
        {
          let pp = new PhysicalPerson();
          pp.firstName = result.pp_firstName;
          pp.lastName = result.pp_lastName;
          pp.spokenLanguages = result.pp_spokenLanguage;
          pp.nationality = result.tp_nationality;
          pp.anniversaryDate = result.tp_anniversaryDate;
          pp.siret = result.tp_siret;
          pp.siren = result.tp_siren;
          pp.comment = result.tp_comment;
          pp.labels = result.tp_labels;
          pp.companyId = result.pp_companyid;
          pp.department = result.pp_department;
          pp.job = result.pp_job;

          this.pps.createPhysicalPerson(pp, null).subscribe(idpp =>
          {
            pp.id = idpp;
            this.cds.physicalPersonCollection.push(pp);
            this.cds.physicalPersonCollection.sort((a, b) => (a.firstName + a.lastName) < (b.firstName + b.lastName) ? -1 : 1);
            let tpforfilter = new ThirdPartyForCredential();
            tpforfilter.id = idpp;
            tpforfilter.thirdPartyType = 1;
            tpforfilter.firstName = pp.firstName;
            tpforfilter.lastName = pp.lastName;
            this.ppListFilter.push(tpforfilter);
            this.kbs.ppList.push(tpforfilter)
            this.ppListFilter.sort((a, b) => (a.firstName + a.lastName) < (b.firstName + b.lastName) ? -1 : 1);
            setTimeout(() =>
            {
              this.listThirdPartyToAffect.push(idpp + '');
              this.listThirdPartyToAffect = [...this.listThirdPartyToAffect];
            }, 100);
            modal.close();
          })
        }
      },
      {
        label: close,
        onClick: () => { modal.close(); }
      }]
    });
    modal.componentInstance.tp = null;
    modal.componentInstance.tptype = 1;
  }

  createMoralPerson()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.CALL.PHONE-MENU.PROFILE").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);

    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: ThirdPartySheetEditIdentityComponent,
      nzWidth: '80%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzFooter: [{
        label: save,
        type: 'primary',
        onClick: (result) =>
        {
          let mp = new MoralPerson();
          mp.name = result.mp_name;
          mp.nationality = result.tp_nationality;
          mp.anniversaryDate = result.tp_anniversaryDate;
          mp.siret = result.tp_siret;
          mp.siren = result.tp_siren;
          mp.comment = result.tp_comment;
          mp.labels = result.tp_labels;
          mp.mainCurrency = result.mp_mainCurrency;
          mp.sign = result.mp_enseigne;
          mp.initials = result.mp_sigle;
          mp.codeTVA = result.mp_codeTVA;
          mp.codeAPE = result.mp_codeAPE;
          if (result.site)
            mp.sites.push(result.site);

          this.mps.createMoralPerson(mp, null).subscribe(idmp =>
          {
            mp.id = idmp;
            this.cds.moralPersonCollection.push(mp);
            this.cds.moralPersonCollection.sort((a, b) => a.name < b.name ? -1 : 1);
            let tpforfilter = new ThirdPartyForCredential();
            tpforfilter.id = idmp;
            tpforfilter.name = mp.name
            tpforfilter.thirdPartyType = 2;
            this.mpListFilter.push(tpforfilter);
            this.kbs.mpList.push(tpforfilter)
            this.mpListFilter.sort((a, b) => a.name < b.name ? -1 : 1);
            setTimeout(() =>
            {
              this.listThirdPartyToAffect.push(idmp + '');
              this.listThirdPartyToAffect = [...this.listThirdPartyToAffect];
            }, 100);

            modal.close();
          })
        }
      },
      {
        label: close,
        onClick: () => { modal.close(); }
      }]
    });
    modal.componentInstance.tp = null;
    modal.componentInstance.tptype = 2;
  }

  getKanbanActionParameter(): KanbanActionParameters
  {
    let actionparam = new KanbanActionParameters();
    if (this.kanbanActionParameterRequester.comment)
      actionparam.comment = this.comment;
    if (this.kanbanActionParameterRequester.description)
      actionparam.description = this.description;
    if (this.kanbanActionParameterRequester.endDate)
      actionparam.endDate = this.dueDate;
    if (this.kanbanActionParameterRequester.label)
      actionparam.labels = this.listLabelsToAffect;
    if (this.kanbanActionParameterRequester.memberTeam)
      actionparam.memberTeams = this.listTeamsToAffect;
    if (this.kanbanActionParameterRequester.memberUser)
      actionparam.memberUsers = this.listMembersToAffect;
    if (this.kanbanActionParameterRequester.notifyUser)
      actionparam.notifyUsers = this.listUsersToNotified;
    if (this.kanbanActionParameterRequester.sendMailTeam)
      actionparam.sendMailTeam = this.listTeamsToNotified;
    if (this.kanbanActionParameterRequester.thirdParty)
      actionparam.thirdParties = this.listThirdPartyToAffect;
    actionparam.listId = this.listid;
    actionparam.cardId = this.card.id;
    return actionparam;
  }

  searchTpText = "";
  onSearch(text)
  {
    this.searchTpText = text;
  }

  getPP()
  {
    return this.kbs.ppList.filter(x => this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, this.searchTpText)).sort((a, b) => (a.firstName.toUpperCase() + " " + a.lastName.toUpperCase()) > (b.firstName.toUpperCase() + " " + b.lastName.toUpperCase()) ? 1 : -1);
  }

  getMp()
  {
    return this.kbs.mpList.filter(x => this.tcs.contains(x.name, this.searchTpText)).sort((a, b) => (a.name.toUpperCase()) > (b.name.toUpperCase()) ? 1 : -1);
  }
}
