import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormManagerService } from '../../../../src/app/form-manager/services/form-manager.service';
import { GlobalLabel } from '../../../../src/app/kanban/entities/Label';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { ColorService } from '../../../../src/app/services/color.service';
import { Site } from '../../../../src/app/third-party/entities/site';
import { SearchClientInfoComponent } from '../../../../src/app/third-party/moral-person/components/search-client-info/search-client-info.component';
import { SearchClientResult } from '../../../../src/app/third-party/moral-person/entities/search-client-result';
import { ThirdPartyService } from '../../../../src/app/third-party/services/third-party.service';
import { SiteService } from '../../../../src/app/third-party/site/services/site.service';
import { ThirdPartySheetInfos } from '../entities/third-party-sheet-infos';
import { CallService } from '../service/call.service';
import { TextCompareService } from '../../services/text-compare.service';
import { MoralPerson } from '../../third-party/moral-person/entities/moral-person';
import { MoralPersonService } from '../../third-party/moral-person/services/moral-person.service';

@Component({
  selector: 'app-third-party-sheet-edit-identity',
  templateUrl: './third-party-sheet-edit-identity.component.html',
  styleUrls: ['./third-party-sheet-edit-identity.component.scss']
})
export class ThirdPartySheetEditIdentityComponent implements OnInit
{

  constructor(public cds: CacheDataService, public tps: ThirdPartyService,
    private drawerService: NzDrawerService,
    private tcs: TextCompareService,
    private mps: MoralPersonService,
    public callService: CallService, public cs: ColorService, public translateService: TranslateService, public modalService: NzModalService,
    private msgs: NzMessageService, public fms: FormManagerService, public siteService: SiteService) { }

  @Input() tp: ThirdPartySheetInfos;
  @Input() tptype: number;
  @Input() tpname: string;

  pp_gender = "";
  pp_firstName = "";
  pp_lastName = "";
  pp_lastName2 = "";
  pp_firstNameMissing = false;
  pp_lastNameMissing = false;
  pp_spokenLanguage = [];
  pp_autoEntrepreneur = false;
  pp_companyid: number = null;
  pp_department = "";
  pp_job = "";

  mp_name = "";
  mp_nameMissing = false;
  mp_mainCurrency = "";
  mp_enseigne = "";
  mp_sigle = "";
  mp_codeTVA = "";
  mp_codeAPE = "";

  tp_siren = "";
  tp_siret = "";
  tp_nationality = "";
  tp_anniversaryDate = null;
  tp_comment = "";
  tp_labels: GlobalLabel[] = [];
  tags = [];
  wrongType = 0;
  site: Site;

  @ViewChild('labelFilteredSelector', { static: false }) templatelabel?: any;


  ngOnInit()
  {
    if (this.tp)
    {
      this.pp_gender = this.tp.gender;
      this.pp_firstName = this.tp.firstName;
      this.pp_lastName = this.tp.lastName;
      this.pp_lastName2 = this.tp.lastName2;
      this.pp_spokenLanguage = this.tp.spokenLanguages;
      this.mp_name = this.tp.name;
      this.mp_mainCurrency = this.tp.mainCurrency;
      this.mp_enseigne = this.tp.enseigne;
      this.mp_sigle = this.tp.sigle;
      this.mp_codeTVA = this.tp.codeTVA;
      this.mp_codeAPE = this.tp.codeAPE;
      this.tp_siren = this.tp.siren;
      this.tp_siret = this.tp.siret;
      this.tp_nationality = this.tp.nationality;
      this.tp_comment = this.tp.comment;
      this.tp_anniversaryDate = this.tp.anniversaryDate;
      this.tp_labels = [...this.tp.labels];
      this.wrongType = this.tp.type == 1 ? 2 : 1;
      this.pp_companyid = this.tp.companyId;
      this.pp_department = this.tp.department;
      this.pp_job = this.tp.job;
      this.tptype = this.tp.type;
    }
    else
    {
      if (this.tpname)
        this.mp_name = this.tpname;
      this.tps.getAllLabels().subscribe()
    }
  }

  labelIsChecked(label: GlobalLabel)
  {
    if (this.tp_labels.findIndex(x => x.id == label.id) >= 0)
      return true;
    return false;
  }

  editLabels()
  {
    let title = "";
    this.drawerService.create({
      nzTitle: title,
      nzContent: this.templatelabel,
      nzWidth: '512px'
    });
  }

  labelIsIn(label: GlobalLabel)
  {
    return this.tp_labels.findIndex(x => x.id == label.id) >= 0;
  }

  changeLabelSelection(label: GlobalLabel)
  {
    let index = this.tp_labels.findIndex(x => x.id == label.id);
    if (index < 0)
    {
      this.tp_labels.push(label);
      this.tp_labels.sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1)
    }
    else 
    {
      this.tp_labels.splice(index, 1);
    }

  }

  removeLabel(label: GlobalLabel)
  {
    let index = this.tp_labels.findIndex(x => x.id == label.id);
    this.tp_labels.splice(index, 1);
  }

  getPublicLabels()
  {
    return this.tps.labelCollection.filter(x => x.thirdPartyType != this.wrongType && !x.boardId && x.modules == 0)
  }

  getTPLabels()
  {
    return this.tps.labelCollection.filter(x => x.thirdPartyType != this.wrongType && x.modules == 2)
  }

  getFilteredLabelCollection()
  {
    return this.tps.labelCollection.filter(x => x.thirdPartyType != this.wrongType);
  }

  checkDuplicatePPName()
  {
    if (!this.pp_firstName || !this.pp_lastName)
      return false;
    let upperfilter = (this.pp_firstName + this.pp_lastName).toUpperCase();
    let isduplicate = this.cds.physicalPersonCollection.findIndex(x => !x.archived && (!this.tp || x.id != this.tp.id) && (x.firstName + x.lastName).toUpperCase() == upperfilter) >= 0;
    return isduplicate;
  }

  checkDuplicateMPName()
  {
    if (!this.mp_name)
      return false;
    let upperfilter = this.mp_name.toUpperCase();
    let isduplicate = this.cds.moralPersonCollection.findIndex(x => !x.archived && (!this.tp || x.id != this.tp.id) && x.name && x.name.toUpperCase() == upperfilter) >= 0;
    return isduplicate;
  }


  checkDuplicateSiren()
  {
    if (!this.tp_siren)
      return false;
    let isduplicate = this.cds.physicalPersonCollection.findIndex(x => !x.archived && (!this.tp || x.id != this.tp.id) && x.siren && x.siren.toUpperCase() == this.tp_siren) >= 0;
    return isduplicate;
  }

  searchResult;
  modalClientSearch;
  openClientSearch()
  {
    let prefilter = null;
    if (this.tp_siret)
      prefilter = this.tp_siret;
    else if (this.tp_siren)
      prefilter = this.tp_siren;
    else if (this.tp)
      prefilter = this.tp.name;
    let title = "";
    this.translateService.get("THIRD-PARTY.MORAL-PERSON.SEARCH-CLIENT-TITLE").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.modalClientSearch = this.modalService.create({
      nzTitle: title,
      nzContent: SearchClientInfoComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzFooter: [
        {
          label: close,
          onClick: () => { this.modalClientSearch.close(); }
        },
        {
          label: confirm,
          type: 'primary',
          disabled(result): boolean { return result.selectedResult == null && result.resultsList.length != 1 },
          onClick: (result) =>
          {
            if (result.selectedResult || result.resultsList.length == 1)
            {
              if (!result.selectedResult)
                result.selectedResult = result.resultsList[0];
              this.searchResult = result.selectedResult;

              if (!this.mp_name)
                this.mp_name = result.selectedResult.raisonSociale
              this.tp_siren = result.selectedResult.numeroSIREN;
              this.tp_siret = result.selectedResult.numeroSIRET;
              this.mp_codeAPE = result.selectedResult.codeNAF;
              this.mp_codeTVA = result.selectedResult.numeroTVAIntracom;
              if (!this.tp_anniversaryDate)
                this.tp_anniversaryDate = result.selectedResult.creationDate;
              this.tp_nationality = "fr";
              this.tp_nationality = "Euro";
              this.addSiteWithSearchResult(result.selectedResult);
            }

          }
        }
      ]
    });
    let ins = this.modalClientSearch.getContentComponent();
    ins.prefilter = prefilter;
  }

  addSiteWithSearchResult(searchResult: SearchClientResult)
  {
    let titleCreateSite = "";
    let yes = "";
    let no = "";
    this.translateService.get("GENERIC-ACTIONS.YES").subscribe(x => yes = x);
    this.translateService.get("GENERIC-ACTIONS.NO").subscribe(x => no = x);
    this.translateService.get("THIRD-PARTY.MORAL-PERSON.SEARCH-CLIENT-CREATE-ADDRESS").subscribe(x => titleCreateSite = x);
    let modal = this.modalService.confirm({
      nzTitle: titleCreateSite,
      nzOnOk: () =>
      {
        let siteTemp = new Site();
        siteTemp.name = searchResult.raisonSociale;
        siteTemp.address = this.fms.initFieldWithSearch(searchResult);
        siteTemp.countryCode = "FR";
        siteTemp.origin = "auto";
        this.siteService.createSite(siteTemp).subscribe(x =>
        {
          siteTemp.id = x;
          this.siteService.siteList.push(siteTemp);
          this.site = siteTemp;
          if (this.tp)
            this.siteService.addThirdPartyToSite(x, [this.tp.id]).subscribe();
          modal.close();
          this.modalClientSearch.close();
        });
      },
      nzOkText: yes,
      nzOnCancel: () => { this.modalClientSearch.close(); modal.close(); },
      nzCancelText: no
    })
  }

  searchTpText = "";
  onSearch(text)
  {
    this.searchTpText = text;
  }

  getMpNotAssociated()
  {
    return this.cds.allmoralperson.filter(x => !x.archived && this.tcs.contains(x.name, this.searchTpText));
  }

  createMoralPerson()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.CALL.PHONE-MENU.PROFILE").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);

    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: ThirdPartySheetEditIdentityComponent,
      nzWidth: '80%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzFooter: [{
        label: save,
        type: 'primary',
        onClick: (result) =>
        {
          let mp = new MoralPerson();
          mp.name = result.mp_name;
          mp.nationality = result.tp_nationality;
          mp.anniversaryDate = result.tp_anniversaryDate;
          mp.siret = result.tp_siret;
          mp.siren = result.tp_siren;
          mp.comment = result.tp_comment;
          mp.labels = result.tp_labels;
          mp.mainCurrency = result.mp_mainCurrency;
          mp.sign = result.mp_enseigne;
          mp.initials = result.mp_sigle;
          mp.codeTVA = result.mp_codeTVA;
          mp.codeAPE = result.mp_codeAPE;
          if (result.site)
            mp.sites.push(result.site);

          this.mps.createMoralPerson(mp, null).subscribe(idmp =>
          {
            mp.id = idmp;
            if (this.cds.moralPersonCollection.findIndex(i => i.id == idmp) < 0)
            {
              this.cds.moralPersonCollection.push(mp);
              this.cds.moralPersonCollection.sort((a, b) => a.name < b.name ? -1 : 1);
            }

            modal.close();
          })
        }
      },
      {
        label: close,
        onClick: () => { modal.close(); }
      }]
    });
    modal.componentInstance.tp = null;
    modal.componentInstance.tptype = 2;
  }

}
