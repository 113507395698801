import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardEditComponent } from './kanban-card-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { KanbanCardChecklistModule } from '../kanban-card-checklist/kanban-card-checklist.module';
import { ColorPickerModule } from '../../../components/color-picker/color-picker.module';
import { AttachmentManagerModule } from '../../../components/attachment-manager/attachment-manager.module';
import { KanbanActionsCreationCardModule } from '../kanban-actions-creation-card/kanban-actions-creation-card.module';
import { KanbanMoveCopyCardModule } from '../kanban-move-copy-card/kanban-move-copy-card.module';
import { KanbanActionsManagerModule } from '../kanban-actions-manager/kanban-actions-manager.module';
import { KanbanLabelDrawerModule } from '../kanban-label-drawer/kanban-label-drawer.module';
import { KANBAN_CARD_PLANNING, THIRD_PARTY_SHEET } from '../../../call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { ThirdPartySheetComponent } from '../../../call-interface/third-party-sheet/third-party-sheet.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NameUserPipeModule } from '../../../pipes/name-user-pipe/name-user-pipe.module';
import { AvatarUserModule } from '../../../pipes/avatar-user/avatar-user.module';
import { InitialUserModule } from '../../../pipes/initial-user/initial-user.module';
import { NameTeamPipeModule } from '../../../pipes/name-team-pipe/name-team-pipe.module';
import { InitialTeamPipeModule } from '../../../pipes/initial-team-pipe/initial-team-pipe.module';
import { AvatarTeamPipeModule } from '../../../pipes/avatar-team-pipe/avatar-team-pipe.module';
import { PipesModule } from '../../../pipes/pipes/pipes.module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { KanbanCardPlanningComponent } from '../kanban-card-planning/kanban-card-planning.component';
import { KanbanCardHistoryModule } from '../kanban-card-history/kanban-card-history.module';
import { KanbanCardThirdPartyModule } from '../kanban-card-third-party/kanban-card-third-party.module';
import { KanbanCardCommentModule } from '../kanban-card-comment/kanban-card-comment.module';
import { EditReccurenceModule } from '../../../SDICalendar/calendar/edit-reccurence/edit-reccurence.module'
import { KanbanCardMailsModule } from '../kanban-card-mails/kanban-card-mails.module';
import { ChecklistTemplateManagerModule } from '../checklist-template-manager/checklist-template-manager.module';

@NgModule({
    imports: [
        CommonModule,
        NzPopconfirmModule,
        TranslateModule,
        NzButtonModule,
        FormsModule,
        NzFormModule,
        NzGridModule,
        NzIconModule,
        NzPopoverModule,
        NzDatePickerModule,
        NzAvatarModule,
        NzInputModule,
        KanbanLabelDrawerModule,
        NzBadgeModule,
        NzLayoutModule,
        NzToolTipModule,
        NzSelectModule,
        ChecklistTemplateManagerModule,
        NzCheckboxModule,
        KanbanCardChecklistModule,
        ColorPickerModule,
        AttachmentManagerModule,
        KanbanActionsCreationCardModule,
        KanbanMoveCopyCardModule,
        NzDrawerModule,
        KanbanActionsManagerModule,
        DragDropModule,
        NameUserPipeModule,
        AvatarUserModule,
        InitialUserModule,
        NameTeamPipeModule,
        InitialTeamPipeModule,
        AvatarTeamPipeModule,
        EditReccurenceModule,
        KanbanCardHistoryModule,
        KanbanCardThirdPartyModule,
        PipesModule,
        KanbanCardMailsModule,
        KanbanCardCommentModule
    ],
    declarations: [KanbanCardEditComponent],
    exports: [KanbanCardEditComponent],
    providers: [{
        provide: THIRD_PARTY_SHEET, useValue: ThirdPartySheetComponent,
    },
    {
        provide: KANBAN_CARD_PLANNING, useValue: KanbanCardPlanningComponent,
    }]
})
export class KanbanCardEditModule { }
