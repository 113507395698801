import { KanbanAttachment } from "src/app/kanban/entities/Attachment";

export class SetRelation
{
    idRelation: number;
    idThirdPartySource: number;
    idThirdPartyTarget: number;
    idTypeRelation: number;
    comment: string;
    attachments: KanbanAttachment[] = [];
}