export class GenericViewerData {

    filename: string;
    size: number;
    idfile: number;
    createdUTC: Date;
    receiveUTC: Date;

    NamTitle: string;
    id: number;
    idcred: number;
    
    from: string;
    to: string;

    bgColor: string;

    type: number;

    credlinked: boolean;
    boardlinked: boolean;
    thirdlinked: boolean;
    cardlinked: boolean;
    maillinked: boolean;

    deleteTemplate: boolean = false;

}

