import { KanbanCard } from "./Card";
import { RelationForListDTO } from "./list-relation";
import { ListAction } from "./list-actions-control";
import { FilterList } from "./filter-list";
import { KanbanActionManager } from "./kanban-action-manager";

export class KanbanList
{
    id: number;
    title: string;
    archived: boolean;
    comment: string;
    visible = true;
    prefVisible = true;
    allowedDeplacementListIds: RelationForListDTO[] = [];
    cards: KanbanCard[] = [];
    undroppable = true;
    autofilter = false;
    color: string;
    fontColor: string;
    autofilterType: string;
    autofilterDirection: string;
    canCreateCard: boolean;
    actionsAtCardCreation: ListAction[] = [];
    actions: KanbanActionManager[] = [];
    filterList: FilterList = new FilterList();
}