// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelForm {
  white-space: unset;
  text-align: left;
  line-height: 1.4;
}

.helper {
  padding-left: 20px;
}

.component {
  margin-bottom: 24px;
}

.block {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/SDICalendar/calendar/form/toogler/toogler.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,cAAA;AACJ","sourcesContent":[".labelForm {\r\n    white-space: unset;\r\n    text-align: left;\r\n    line-height: 1.4;\r\n}\r\n\r\n.helper {\r\n    padding-left: 20px\r\n}\r\n\r\n.component {\r\n    margin-bottom: 24px\r\n}\r\n\r\n.block {\r\n    display: block;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
