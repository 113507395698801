import { Component, OnInit, Input } from '@angular/core';
import { Site } from '../../../../../../src/app/third-party/entities/site';
import { SiteService } from '../../services/site.service';
import { FormManagerService } from '../../../../../../src/app/form-manager/services/form-manager.service';
import { FormData } from '../../../../../../src/app/form-manager/entities/form-data';
import { CountryManagerService } from '../../../../../../src/app/country-manager/services/country-manager.service';
import { FormLine } from '../../../../../../src/app/form-manager/entities/form-line';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { Country } from '../../../../../../src/app/country-manager/entities/country';
import { CountryFormTranslation } from '../../../../../../src/app/country-manager/entities/country-form-translation';
import { SearchClientResult } from '../../../../../../src/app/third-party/moral-person/entities/search-client-result';
import { TranslateService } from '@ngx-translate/core';
import { KanbanService } from '../../../../../../src/app/kanban/services/kanban.service';
import { SearchClientInfoComponent } from '../../../../../../src/app/third-party/moral-person/components/search-client-info/search-client-info.component';
import { TextCompareService } from '../../../../../../src/app/services/text-compare.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-site-edit',
  templateUrl: './site-edit.component.html',
  styleUrls: ['./site-edit.component.scss']
})
export class SiteEditComponent implements OnInit
{

  constructor(public siteService: SiteService, public cms: CountryManagerService,
    private fms: FormManagerService,
    private translateService: TranslateService,
    private kbs: KanbanService,
    private msgs: NzMessageService,
    private modalService: NzModalService,
    public tcs: TextCompareService,
    private ats: AuthService) { }

  @Input() site: Site;
  @Input() readonly: boolean;
  @Input() searchResult: SearchClientResult;
  @Input() prefilter: string;

  name = "";
  origin = "manual";
  nameInvalid = false;
  address = "";
  devise = "Euro";
  countryCode = "FR";
  countryLoaded = false;
  addressLines: FormLine[] = [];
  addressData: FormData[] = [];
  lines: FormLine[] = [];
  filteredCountryList: Country[] = [];
  formTranslation: CountryFormTranslation[] = [];
  ngOnInit()
  {
    this.cms.getAllCountries().subscribe(x =>
    {
      this.countryLoaded = true;
      this.filteredCountryList = x.sort((a, b) => this.getCountryName(a).toUpperCase() < this.getCountryName(b).toUpperCase() ? -1 : 1);
      if (this.searchResult)
      {
        let country = this.cms.countryList.find(x => x.code == "FR");
        this.lines = this.fms.transformDataIntoLines(country.addressFormat);
        this.formTranslation = country.fieldsTranslations;
        for (let line of this.lines)
        {
          for (let field of line.fields)
          {
            switch (field.label)
            {
              case "ENTREE-TOUR-BATIMENT-IMMEUBLE-RESIDENCE":
                field.value = this.searchResult.complementAdresse;
                break;
              case "NUMERO-LIBELLE-VOIE":
                field.value = this.searchResult.adresse;
                break;
              case "BOITE-POSTALE":
                field.value = this.searchResult.boitePostale;
                break;
              case "CODE-POSTALE":
                field.value = this.searchResult.codePostal;
                break;
              case "VILLE":
                field.value = this.searchResult.ville;
                break;
            }
          }

        }
        this.updateAddress(this.lines)
      }
      else
      {
        if (this.site == null)
        {
          let country = this.cms.countryList.find(x => x.code.toUpperCase() == this.ats.Access.locale.toUpperCase());
          if (!country)
            country = this.cms.countryList.find(x => x.code == "GB");
          this.lines = this.fms.transformDataIntoLines(country.addressFormat);
          this.formTranslation = country.fieldsTranslations;
        }
        else 
        {
          let country = this.cms.countryList.find(x => x.code.toUpperCase() == this.site.countryCode.toUpperCase());
          if (!country)
            country = this.cms.countryList.find(x => x.code.toUpperCase() == "GB");
          if (this.site.address == "[]")
            this.lines = this.fms.transformDataIntoLines(country.addressFormat);
          else this.lines = this.fms.transformDataIntoLines(JSON.parse(this.site.address));
          this.addressLines = this.lines;

          this.formTranslation = country.fieldsTranslations;
        }
      }
    });
    if (this.site != null)
    {
      this.name = this.site.name;
      this.address = this.site.address;
      this.countryCode = this.site.countryCode;
    }
  }

  openClientSearch()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.SITE.SEARCH").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: SearchClientInfoComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzFooter: [
        {
          label: close,
          onClick: () => { this.kbs.modalEdit.close(); }
        },
        {
          label: confirm,
          type: 'primary',
          disabled(result): boolean { return result.selectedResult == null && result.resultsList.length != 1 },
          onClick: (result) =>
          {
            if (result.selectedResult || result.resultsList.length == 1)
            {
              if (!result.selectedResult)
                result.selectedResult = result.resultsList[0];

              this.searchResult = result.selectedResult;
              for (let line of this.lines)
              {
                for (let field of line.fields)
                {
                  switch (field.label)
                  {
                    case "ENTREE-TOUR-BATIMENT-IMMEUBLE-RESIDENCE":
                      field.value = this.searchResult.complementAdresse;
                      break;
                    case "NUMERO-LIBELLE-VOIE":
                      field.value = this.searchResult.adresse;
                      break;
                    case "BOITE-POSTALE":
                      field.value = this.searchResult.boitePostale;
                      break;
                    case "CODE-POSTALE":
                      field.value = this.searchResult.codePostal;
                      break;
                    case "VILLE":
                      field.value = this.searchResult.ville;
                      break;
                  }
                }
                this.origin = "auto";
                this.updateAddress(this.lines)
              }
              this.kbs.modalEdit.close();
            }
          }
        }
      ]
    });
    this.kbs.modalEdit.componentInstance.prefilter = this.prefilter;
  }

  onSearch(searchText: string)
  {
    let uppsearch = searchText.toUpperCase();
    this.filteredCountryList = this.cms.countryList.filter(x => this.tcs.contains(this.getCountryName(x), uppsearch));
  }

  changeCountry(codeCountry: string)
  {
    this.countryCode = codeCountry;
    let country = this.cms.countryList.find(x => x.code == this.countryCode.toUpperCase());
    this.lines = this.fms.transformDataIntoLines(country.addressFormat);
    this.formTranslation = country.fieldsTranslations;
  }

  updateAddress(newAddress: FormLine[])
  {
    this.addressLines = newAddress;
  }

  getCountryName(country: Country)
  {
    let trad = country.translations.find(x => x.codelang == this.ats.Access.locale);

    if (trad)
      return trad.text;
    return country.translations.find(x => x.isDefault).text;
  }

  submitForm()
  {
    this.addressData = this.fms.transformLineIntoData(this.addressLines);
    if (!this.name)
    {
      this.nameInvalid = true;
      return null;
    }
    else this.nameInvalid = false;

    let siteTemp = new Site();
    siteTemp.name = this.name;
    siteTemp.address = JSON.stringify(this.addressData);
    siteTemp.countryCode = this.countryCode;
    siteTemp.origin = this.origin;
    return siteTemp;
  }

}
