import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { IConfig } from 'ngx-mask';
import { CalendarComponent } from './calendar/calendar.component';
import { SDICalendarRoutingModule } from './SDICalendar.routing.module';
import { EditModule } from './calendar/edit/edit.module';
import { OverlapModule } from './calendar/overlap/overlap.module';
import { KanbanBoardTreeModule } from '../kanban/components/kanban-board-tree/kanban-board-tree.module';
import { PipesModule } from '../pipes/pipes/pipes.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { KanbanModule } from '../kanban/kanban.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NameUserPipeModule } from '../pipes/name-user-pipe/name-user-pipe.module';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzAlertModule,
    NzButtonModule,
    TranslateModule,
    NzDatePickerModule,
    NzSelectModule,
    FormsModule,
    NzCheckboxModule,
    NzPopoverModule,
    NzDividerModule,
    NzToolTipModule,
    OverlapModule,
    NzDropDownModule,
    NzInputModule,
    NzAvatarModule,
    NzTreeModule,
    NzIconModule,
    NameUserPipeModule,
    NzBadgeModule,
    EditModule,
    KanbanBoardTreeModule,
    NzModalModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }
    ),
    SDICalendarRoutingModule,
    PipesModule
  ],
  declarations: [
    CalendarComponent,
  ],
  exports: [CalendarComponent]
})
export class SDICalendarModule { }
