import { ListAction } from "./list-actions-control";

export class TriggerActions
{
    trigger = "";
    isCollapsed = false;
    isVisible = true;
    actions: ListAction[] = [];
    createdAction: ListAction = new ListAction();
    triggerDisplayText = "";
    nbActions: number = 0;
}