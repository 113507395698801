import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input.component';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzFormModule,
    FormsModule,
    NzButtonModule,
    NzInputModule
  ],
  declarations: [TextInputComponent],
  exports: [TextInputComponent]
})
export class TextInputModule { }
