import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatService } from 'src/app/chat/chat.service';
import { ChatDiscussion } from 'src/app/chat/entities/chat-discussion';
import { NavbarService } from 'src/app/components/navbar/navbar.service';
import { FloatingChatService } from 'src/app/services/floating-chat.service';
import { User } from 'src/app/user/model/user';

@Component({
  selector: 'app-floating-chat-title',
  templateUrl: './floating-chat-title.component.html',
  styleUrls: ['./floating-chat-title.component.scss']
})
export class FloatingChatTitleComponent implements OnInit
{

  constructor(
    public fcs: FloatingChatService,
    public cs: ChatService,
    public navbarService: NavbarService,
  ) { }

  iconType = ""

  ngOnInit()
  {
  }

  openInNewTab(url: String)
  {

    let tab = url.split('/')
    let newUrl: string = "/chat"
    if (tab[1] == "Discussion")
    {
      newUrl = "/chat/discussion/" + this.fcs.openDiscussionId
    }
    else if (tab[1] == "Room")
    {
      newUrl = "/chat/groups/" + this.fcs.openDiscussionId
    }

    var win = window.open(newUrl, '_blank');
    win.focus();
  }

  testIconType()
  {
    if (this.fcs.navigation.startsWith("/Room"))
    {
      this.iconType = "team"
      return true
    }
    else if (this.fcs.navigation.startsWith("/Discussion"))
    {
      this.iconType = "user"
      return true
    }
    else
      return false
  }

  initPrivateParam(member: User)
  {
    this.fcs.param.paramId = member.id
    this.fcs.param.isPrivateDiscussion = true
    this.fcs.param.isFloatingMessage = true
    // this.memberDiscussion = this.fcs.discussionList
  }

  initParam(group: ChatDiscussion)
  {
    this.fcs.param.paramId = group.id
    this.fcs.param.isPrivateDiscussion = false
    this.fcs.param.isFloatingMessage = true
    // this.groupList = this.fcs.groupList
  }

  openDiscussion(member)
  {
    this.fcs.chatTemplate = 'ChatContentTemplate'
    this.fcs.navigateBack()
    this.fcs.navigateBack()
    this.fcs.navigate('Discussion')
    // this.memberDiscussion = this.fcs.discussionList
    this.fcs.openDiscussionId = member.id
    this.fcs.param.isPrivateDiscussion = true
    this.fcs.param.isFloatingMessage = true
    this.initPrivateParam(member)
    this.fcs.navigate(member.name)
    setTimeout(() =>
    {
      this.fcs.displayDiscussion = true
      this.fcs.ChatEditorFSub.next(member.id)
    }, 100);
  }

  openGroup(group)
  {
    this.fcs.chatTemplate = 'ChatContentTemplate'
    this.fcs.navigateBack()
    this.fcs.navigateBack()
    this.fcs.navigate('Room')
    this.fcs.param.roomList = this.fcs.groupList
    this.fcs.openDiscussionId = group.id
    this.fcs.param.isPrivateDiscussion = false
    this.fcs.param.isFloatingMessage = true
    this.initParam(group)
    this.fcs.navigate(group.name)
    setTimeout(() =>
    {
      this.fcs.displayDiscussion = true
      this.fcs.ChatEditorFSub.next(group.id)
    }, 100);
  }

  goToChat()
  {
    let ok = false
    if (this.fcs.chatTemplate == 'ChatnotificationsList')
      ok = true
    this.fcs.chatTemplate = 'ChatContentTemplate'
    if (this.fcs.openDiscussionId && ok)
      this.fcs.ChatEditorFSub.next(this.fcs.openDiscussionId)
  }

}
