import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toogler',
  templateUrl: './toogler.component.html',
  styleUrls: ['./toogler.component.scss']
})
export class TooglerComponent implements OnInit {

  @Input('LABEL') public LABEL: string;
  @Input('VARIABLE') public VARIABLE: boolean;
  @Input('HELP_TXT') public HELP_TXT: string;
  @Input('ON_TXT') public ON_TXT: string;
  @Input('RADIO') public RADIO: boolean = false;
  @Input('OFF_TXT') public OFF_TXT: string;
  @Output() ON_CHANGE = new EventEmitter();
  @Output() VARIABLEChange = new EventEmitter<boolean>();
  @Output() VALIDATE_FUNC = new EventEmitter();
  
  changedNgModel() {
    this.VARIABLEChange.emit(this.VARIABLE)
    this.ON_CHANGE.emit()
    this.validateFunctionCallback()
  }
  
  validateFunctionCallback() {
    this.VALIDATE_FUNC.emit();
  }
  
  constructor() { }

  ngOnInit() {
  }

}
