export class ExportCardParameters
{
    cardId: number;
    cardMembers: boolean = true;
    cardLabels: boolean = true;
    cardDescription: boolean = true;
    cardComment: boolean = true;
    cardAttachments: boolean = true;
    cardActions: boolean = true;
}

export class ExportListParameters
{
    listId: number;
    listActions: boolean = true;
    listStyle: boolean = true;
    listSort: boolean = true;
    listFilters: boolean = true;
    listComment: boolean = true;
    cards: ExportCardParameters[] = [];
}

export class ExportBoardParameters
{
    board: boolean = true;
    boardId: number;
    boardColor: boolean = true;
    boardComment: boolean = true;
    boardVisibility: boolean = true;
    boardLabels: boolean = true;
    boardMembers: boolean = true;
    boardListInternalRelations: boolean = true;
    boardListCreationAuthorization: boolean = true;
    lists: ExportListParameters[] = [];
    
}