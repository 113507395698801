import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarUserPipe } from './avatar-user.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [AvatarUserPipe],
  exports: [AvatarUserPipe]
})
export class AvatarUserModule { }
