import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TextSearch } from '../entities/search-text';
import { KanbanCard } from '../kanban/entities/Card';
import { UrlApiService } from '../services/url-api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService
{
  notes: KanbanCard[] = [];
  noteText = "";
  notevisible = false;
  reloadNoteUI: Subject<any> = new Subject<any>();
  lines: KanbanCard[][];
  constructor(private http: HttpClient) { }

  openCreateNoteModal()
  {
    if (this.notevisible)
    {
      setTimeout(() =>
      {
        this.notevisible = false;
      }, 200);
      return;
    }
    this.noteText = "";
    this.notevisible = true;
    setTimeout(() =>
    {
      document.getElementById("input-note-creator-global").focus();
    }, 500);

  }

  reformHeight()
  {
    setTimeout(() =>
    {
      let linediv = document.getElementsByClassName("note-line");

      for (let i = 0; i < linediv.length; i++)
      {
        let ld = linediv.item(i);
        let lineMaxHeight = 0;
        let childNote = ld.getElementsByClassName("note");

        for (let k = 0; k < childNote.length; k++)
        {
          let nt = childNote.item(k);
          if (nt.clientHeight > lineMaxHeight)
            lineMaxHeight = nt.clientHeight;
        }

        for (let k = 0; k < childNote.length; k++)
        {
          let nt = childNote.item(k);
          if (nt instanceof HTMLElement)
            nt.setAttribute("style", "height: " + lineMaxHeight + "px");
        }
      }
    }, 100);
  }

  initLines()
  {
    this.lines = [];
    let noteWidth = 316;
    let windowSize = document.getElementById("notecontainer").clientWidth;
    let lineLength = Math.trunc(windowSize / noteWidth);
    let firstLine: KanbanCard[] = [];
    this.lines.push(firstLine);
    for (let note of this.notes)
    {
      let lastLine = this.lines[this.lines.length - 1];
      if (lastLine.length >= lineLength)
      {
        let newLine: KanbanCard[] = [];
        newLine.push(note);
        this.lines.push(newLine);
      }
      else lastLine.push(note);
    }
  }

  getNotes()
  {
    return this.http.get<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/notes`);
  }

  createNote(text: TextSearch)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/notes`, text);
  }

}
