import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailEditorComponent } from './mail-editor.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MailSelectorInputModule } from '../../../../src/app/messagerie/mail-selector-input/mail-selector-input.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzDividerModule,
    TranslateModule,
    FormsModule,
    NzSelectModule,
    NzButtonModule,
    NzCheckboxModule,
    NzPopconfirmModule,
    NzToolTipModule,
    NzDatePickerModule,
    NzGridModule,
    NzPopoverModule,
    NgxExtendedPdfViewerModule,
    NzUploadModule,
    NzInputModule,
    MailSelectorInputModule
  ],
  declarations: [MailEditorComponent],
  exports: [MailEditorComponent]
})
export class MailEditorModule { }
