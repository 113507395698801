import { Pipe, PipeTransform } from '@angular/core';
import { KanbanCard } from 'src/app/kanban/entities/Card';

@Pipe({
  name: 'kanban_card_show_end_date_good'
})
export class KanbanCardShowEndDateGoodPipe implements PipeTransform {

  transform(card: KanbanCard, completed: boolean, enddate: Date, duedate: Date): any {
    return completed && enddate && (!duedate || new Date(enddate) <= new Date(duedate));
  }

}
