import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ParameterSubstitution } from '../entities/parameter-substitution';
import { UrlApiService } from './url-api.service';

@Injectable({
    providedIn: 'root'
})
export class VariableSubstitutionService
{

    constructor(private http: HttpClient) { }
    variableList: ParameterSubstitution[] = [];

    getVariableSubstitution()
    {
        return this.http.get<ParameterSubstitution[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/variable-substitution`).pipe(map(x => this.variableList = x));
    }

}
