import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameTeamPipe } from './name-team.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NameTeamPipe],
  exports: [NameTeamPipe],
  providers: [NameTeamPipe]
})
export class NameTeamPipeModule { }
