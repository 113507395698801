import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZoomComponent } from './zoom.component';

const appChildrenRoutes: Routes = [

  { path: '', component: ZoomComponent },
  { path: 'meetings/:idMeeting', component: ZoomComponent },

];

@NgModule({
    imports: [
        RouterModule.forChild(appChildrenRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class ZoomRoutingModule { }
