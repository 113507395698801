import { Component, OnInit, NgZone, Input, HostListener } from '@angular/core';
import { KanbanBoard, KanbanBoardVisibility, KanbanBoardType } from './entities/Board';
import { KanbanService } from './services/kanban.service';
import { AuthService } from '../services/auth.service';
import { KanbanCard } from './entities/Card';
import { KanbanList } from './entities/List';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { TodoService } from './services/todo.service';
import { KanbanCategory } from './entities/category';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { KanbanMoveCategory } from './entities/move-category';
import { SettingsService } from '../settings/services/settings.service';
import { ExportBoardComponent } from './components/export-board/export-board.component';
import { KanbanToolsService } from './services/kanban-tools.service';
import { CacheDataService } from '../services/cache-data.service';
import { KanbanBoardArchivesComponent } from './components/kanban-board-archives/kanban-board-archives.component';
import { KanbanAttachment } from './entities/Attachment';
import { KanbanWorkflowSettingsComponent } from './components/kanban-workflow-settings/kanban-workflow-settings.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KanbanCardEditModalComponent } from './components/kanban-card-edit-modal/kanban-card-edit-modal.component';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { platformBrowser } from '@angular/platform-browser';
import { ColorService } from '../services/color.service';
import { KanbanBoardActivitiesComponent } from './components/kanban-board-activities/kanban-board-activities.component';
import { KanbanCardTemplateParameters } from './entities/card-template-parameters';
import { User } from '../user/model/user';
import { GroupUser } from '../group-user/entities/group-user';
import { TextCompareService } from '../services/text-compare.service';
import { NameUserPipe } from '../pipes/name-user-pipe/name-user.pipe';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { KanbanGlobalSearchParameters } from './entities/kanban-global-search-parameters';

declare function removeUploadList(): any;

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent implements OnInit
{
  public enumVis = KanbanBoardVisibility;
  public enumType = KanbanBoardType;
  ErrorEmptyBoardName = false;
  boardListVisible = false;
  templateListVisible = false;
  private subscription: Subscription;
  customColor = false;
  hue: string;
  color: string;
  idTemplateToApply: number;
  showTemplate = false;
  boardIsUpdating = false;
  cardTemplateIsCreating = false;
  boardStarredIsCreating = false;
  boardPersonalIsCreating = false;
  boardPublicIsCreating = false;
  categoryIsCreating = false;
  searchModalVisible = false;
  showPersonalBoards = true;
  showPublicBoards = true;
  showAllPersonaBoards = false;
  boardsForMove: any[] = [];
  filterGlobalCardText = "";
  idupdatecategory = 0;
  idcreateboardcategory = 0;
  createdBoard: KanbanBoard = new KanbanBoard();
  startDateNotification = false;
  dueDateNotification = false;
  memberChangeNotification = false;
  timeNotification: Date = new Date();
  allowMoveForNonRestrictedList = true;
  allowCopyForNonRestrictedList = true;
  dataLoaded = false;
  archivedBoardList: KanbanBoard[] = [];
  createdCategory: KanbanCategory = new KanbanCategory();
  importedBoard: KanbanBoard = new KanbanBoard();
  templateCreationParameters: KanbanCardTemplateParameters = new KanbanCardTemplateParameters();
  @Input() redirect: boolean = true;

  constructor(public kbs: KanbanService,
    private kts: KanbanToolsService,
    private authService: AuthService,
    private nzDropdownService: NzContextMenuService,
    private router: Router,
    public cs: ColorService,
    private message: NzMessageService,
    private translateService: TranslateService,
    private modalService: NzModalService,
    private tds: TodoService,
    private tcs: TextCompareService,
    private nameuserpipe: NameUserPipe,
    public cds: CacheDataService,
    private settingsService: SettingsService,
    private route: ActivatedRoute)
  {
  }

  /*
  board width : 220px
  total width : event.target.innerWidth
  */
  timeoutresize;
  @HostListener('window:resize', ['$event'])
  onResize(event)
  {
    // if (this.timeoutresize)
    //   clearTimeout(this.timeoutresize);
    // this.timeoutresize = setTimeout(() =>
    // {
    //   this.updateEverything();
    // }, 500);
    this.updateEverything();

  }

  updateEverything()
  {
    this.updatePersonalSegment();
    this.updatePublicSegment();
    this.updateStarredSegment();
    this.updateAllCategorySegment();
  }

  updatePersonalSegment()
  {
    this.kbs.allPersonalSegmentList = [];
    this.updateSegment(this.kbs.allPersonalSegmentList, this.kbs.allPersonalBoardList);
  }

  updatePublicSegment()
  {
    this.kbs.allPublicSegmentList = [];
    this.updateSegment(this.kbs.allPublicSegmentList, this.kbs.allPublicBoardList);
  }

  updateStarredSegment()
  {
    this.kbs.allStarredSegmentList = [];
    this.updateSegment(this.kbs.allStarredSegmentList, this.kbs.allStarredBoardList);
  }

  updateAllCategorySegment()
  {
    for (let cat of this.kbs.allCategoryList)
    {
      this.updateCategorySegment(cat);
    }
  }

  updateCategorySegment(cat: KanbanCategory)
  {
    cat.segments = [];
    this.updateSegment(cat.segments, cat.boardids);
  }

  PersonalPredicate(item: CdkDrag<number>)
  {
    return item.dropContainer.id.startsWith("personal");
  }

  PublicPredicate(item: CdkDrag<number>)
  {
    return item.dropContainer.id.startsWith("public");
  }

  StarredPredicate(item: CdkDrag<number>)
  {
    return true;
  }

  updateSegment(segmentList, boardidlist)
  {
    let doc = document.getElementById("kanban-board-home-container");
    if (!doc)
      return;
    let docwidth = doc.clientWidth;
    let numberOfBoardPerLine = Math.floor(docwidth / 220) - 1;
    let currentLine = [];
    segmentList.push(currentLine);
    //for(let boardid of this.kbs.allPersonalBoardList)
    for (let i = 0; i < boardidlist.length; i++)
    {
      if (currentLine.length == numberOfBoardPerLine)
      {
        currentLine = [];
        segmentList.push(currentLine);
      }
      currentLine.push(boardidlist[i]);

    }
  }

  copyBoardUrlToClipboard()
  {
    let val = window.location.href;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let text = "";
    this.translateService.get("GENERIC-ACTIONS.COPIED-TO-CLIPBOARD").subscribe(x => text = x)
    this.message.success(text);
  }

  ngOnInit()
  {
    this.kbs.filterString = "";
    this.cds.selectedBoard = null;
    if (!this.kbs.doRedirectionGet)
    {
      this.kbs.doRedirectionGet = true;
      return;
    }
    this.tds.taskList = [];
    if (!this.authService.Access.kanbanAccess)
      this.router.navigate(["/"])

    this.authService.setCamelineTitle("kanban");

    this.kbs.reloadBoardList.subscribe(() =>
    {
      this.kbs.getAllHomeInfos().subscribe(x =>
      {
        this.kbs.allBoardList = x.boardsAndTemplate.filter(y => y.type == 1);
        this.kbs.allTemplateList = x.boardsAndTemplate.filter(y => y.type != 1).sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);;
        this.cds.publicLabelList = x.publicLabels.sort((x, y) => (x.title.toUpperCase() > (y.title.toUpperCase()) ? 1 : -1));
        this.kbs.allCategoryList = x.categories;
        this.kbs.allStarredBoardList = x.starredBoardIds;
        this.kbs.allCardTemplate = x.cardTemplates.sort((x, y) => (x.templateTitle.toUpperCase() > (y.templateTitle.toUpperCase()) ? 1 : -1));
        this.updatePublicAndPersonaBoard();
        this.updateEverything()
      });
    })

    this.searchUsers("");
    this.searchTeams("");

    this.kbs.getAllHomeInfos().subscribe(x =>
    {
      this.kbs.allBoardList = x.boardsAndTemplate.filter(y => y.type == 1);
      this.kbs.allTemplateList = x.boardsAndTemplate.filter(y => y.type != 1);
      this.cds.publicLabelList = x.publicLabels.sort((x, y) => (x.title.toUpperCase() > (y.title.toUpperCase()) ? 1 : -1));
      this.kbs.allCategoryList = x.categories;
      this.kbs.allStarredBoardList = x.starredBoardIds;
      this.kbs.allCardTemplate = x.cardTemplates.sort((x, y) => (x.templateTitle.toUpperCase() > (y.templateTitle.toUpperCase()) ? 1 : -1));

      const id = + this.route.snapshot.paramMap.get('idBoard');

      if (this.router.url.startsWith("/kanban/template"))
      {
        this.showTemplate = true;
        if (id)
        {
          this.cds.selectedBoard = this.kbs.GetTemplateById(Number(id));
          this.kbs.initBoard(this.cds.selectedBoard);
        }
      }
      else
      {
        this.showTemplate = false;
        if (id)
        {
          this.kbs.initBoard(this.kbs.GetBoardById(Number(id)));
        }
      }
      this.settingsService.getSettingsByName("ShowPersonalAllBoards").subscribe(x =>
      {
        if (x == "")
          this.showAllPersonaBoards = true;
        else this.showAllPersonaBoards = x == "true";
        this.updatePublicAndPersonaBoard();
        this.updateEverything()
      });
    });


    this.settingsService.getSettingsByName("edit-card-display").subscribe(x => x ? this.kbs.editMode = x : this.kbs.editMode = "wide");

    this.settingsService.getSettingsByName("ShowPersonalBoards").subscribe(x =>
    {
      if (x == "")
        this.showPersonalBoards = true;
      else this.showPersonalBoards = x == "true";
    });

    this.settingsService.getSettingsByName("ShowPublicBoards").subscribe(x =>
    {
      if (x == "")
        this.showPublicBoards = true;
      else this.showPublicBoards = x == "true";
    });
  }

  usersForTemplateCreation: User[] = [];
  teamsForTemplateCreation: GroupUser[] = [];

  searchUsers(search: string)
  {
    this.usersForTemplateCreation = this.cds.unarchivedUsers.filter(x => this.tcs.contains(this.nameuserpipe.transform(x.id), search));
  }

  searchTeams(search: string)
  {
    this.teamsForTemplateCreation = this.cds.unarchivedTeams.filter(x => this.tcs.contains(x.name, search));
  }

  initCardTemplateCreation()
  {
    this.templateCreationParameters = new KanbanCardTemplateParameters();
    this.templateCreationParameters.userIdList.push(this.authService.Access.idUser);
    //this.cardTemplateIsCreating = true;
  }

  cancelCardTemplateCreation()
  {
    this.cardTemplateIsCreating = false;
    this.templateCreationParameters = new KanbanCardTemplateParameters();
    if (this.editcardtemplatemodal)
      this.editcardtemplatemodal.close();
  }

  createCardTemplatePls()
  {
    if (this.templateCreationParameters.userIdList.length + this.templateCreationParameters.teamIdList.length == 0)
      return;
    if (this.templateCreationParameters.templateId)
    {
      this.kbs.renameCardTemplate(this.rightClickedTemplate.id, this.templateCreationParameters).subscribe(() =>
      {
        let index = this.kbs.allCardTemplate.findIndex(x => x.id == this.rightClickedTemplate.id);
        if (index >= 0)
        {
          this.kbs.allCardTemplate[index].templateTitle = this.templateCreationParameters.templateTitle;
          this.kbs.allCardTemplate[index].templateTeamAccess = this.templateCreationParameters.teamIdList;
          this.kbs.allCardTemplate[index].templateUserAccess = this.templateCreationParameters.userIdList;
        }

        this.editcardtemplatemodal.close();
      });
    }
    else
    {
      this.kbs.createCardTemplate(this.templateCreationParameters).subscribe(id =>
      {
        let card = new KanbanCard();
        card.id = id;
        card.templateTitle = this.templateCreationParameters.templateTitle;
        this.kbs.allCardTemplate.push(card);
        this.kbs.allCardTemplate.sort((x, y) => (x.templateTitle.toUpperCase() > (y.templateTitle.toUpperCase()) ? 1 : -1));
        this.cancelCardTemplateCreation();
      })
    }
  }

  editCardTemplate(idcard: number)
  {
    this.kbs.getCardInfos(idcard).subscribe(card =>
    {
      this.editCard(card);
    })

  }

  updatePublicAndPersonaBoard()
  {
    let personalList = this.getPersonalBoardList()
    this.kbs.allPersonalBoardList = this.kbs.homeInfos.personalBoardIds.filter(id => personalList.findIndex(x => id == x.id) >= 0);
    for (let b of personalList)
    {
      if (this.kbs.allPersonalBoardList.indexOf(b.id) < 0 && this.showBoard(b.id))
        this.kbs.allPersonalBoardList.push(b.id);
    }
    let publicList = this.getPublicBoardList();
    this.kbs.allPublicBoardList = this.kbs.homeInfos.publicBoardIds.filter(id => publicList.findIndex(x => id == x.id) >= 0);
    for (let b of publicList)
    {
      if (this.kbs.allPublicBoardList.indexOf(b.id) < 0 && this.showBoard(b.id))
        this.kbs.allPublicBoardList.push(b.id);
    }
  }

  showBoard(idboard: number)
  {
    return this.showAllPersonaBoards || !this.boardIsInCategory(idboard);
  }

  manageListRelations()
  {
    let title = "";
    this.translateService.get("KANBAN.MANAGE-WORKFLOW").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanWorkflowSettingsComponent,
      nzWidth: '90%',
      nzBodyStyle: { height: '75vh' },
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalEdit.close(); }
      }
      ]
    });
    this.kbs.modalEdit.componentInstance.board = this.cds.selectedBoard;
  }

  seeBoardCardActvities()
  {
    let title = "";
    this.translateService.get("KANBAN.SHOW-BOARD-HISTORY").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanBoardActivitiesComponent,
      nzWidth: '90%',
      nzBodyStyle: { height: '75vh' },
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalEdit.close(); }
      }
      ]
    });
    this.kbs.modalEdit.componentInstance.idboard = this.cds.selectedBoard.id;
  }

  attachmentsBoardVisible = false;
  updateBoardAttachments(atts: KanbanAttachment[])
  {
    this.cds.selectedBoard.attachments = atts;
    this.cds.selectedBoard.nbAttachments = atts.length;
  }

  timeoutfilter;
  startFilter(textfilter: string)
  {

    if (this.timeoutfilter)
      clearTimeout(this.timeoutfilter);
    this.timeoutfilter = setTimeout(() =>
    {
      this.kbs.filterBoardLists.searchText = this.kbs.filterString;
      this.kbs.filterBoard(this.cds.selectedBoard);
    }, 500);

  }

  updateCustomCategoryVisibilty(category: KanbanCategory)
  {
    if (category.collapsed)
      this.openCategory(category);
    else this.collapseCategory(category);
  }

  stopPropagation()
  {
    event.stopPropagation();
  }

  removeBoardFromCategory(board: KanbanBoard, category: KanbanCategory)
  {
    this.kbs.removeBoardFromCategory(category.id, board.id).subscribe(x =>
    {
      let index = category.boardids.findIndex(i => i == board.id);
      category.boardids.splice(index, 1);
      let personaindex = this.kbs.allPersonalBoardList.findIndex(x => x == board.id);
      if (personaindex < 0)
        this.kbs.allPersonalBoardList.push(board.id)

      let publicindex = this.kbs.allPublicBoardList.findIndex(x => x == board.id);
      if (publicindex < 0)
        this.kbs.allPublicBoardList.push(board.id)
      this.updatePublicSegment();
      this.updatePersonalSegment();
      this.updateCategorySegment(category);
    })
  }

  getAllArchivedCategory()
  {
    return this.kbs.allCategoryList.filter(x => x.archived);
  }

  restoreCategory(category: KanbanCategory)
  {
    this.kbs.restoreCategory(category.id).subscribe(x => category.archived = false)
  }

  UpdatePersonalBoardVisibility()
  {
    this.showPersonalBoards = !this.showPersonalBoards;
    this.settingsService.setSettingsByName("ShowPersonalBoards", this.showPersonalBoards.toString());
  }

  UpdatePublicBoardVisibility()
  {
    this.showPublicBoards = !this.showPublicBoards;
    this.settingsService.setSettingsByName("ShowPublicBoards", this.showPublicBoards.toString());
  }

  initUpdateCategoryTitle(category: KanbanCategory)
  {
    this.idupdatecategory = category.id;
    setTimeout(() =>
    {
      document.getElementById("kanbancategoryid" + category.id).focus();
    }, 300);

  }

  openCategory(category: KanbanCategory)
  {
    this.kbs.openCategory(category.id).subscribe(() => category.collapsed = false)
  }

  collapseCategory(category: KanbanCategory)
  {
    this.kbs.collapseCategory(category.id).subscribe(() => category.collapsed = true)
  }

  saveCategoryTitleModificiation(category: KanbanCategory)
  {
    this.kbs.updateCategory(category.id, category.title).subscribe(() =>
    {
      this.idupdatecategory = 0;
    })
  }

  closeSearch()
  {
    this.searchModalVisible = false;
    this.filterGlobalCardText = "";
  }

  expandeNode(event: any)
  {
    if (!event.node.isLeaf)
      event.node.isExpanded = !event.node.isExpanded
  }

  openBoardArchives()
  {
    let title = "";
    this.translateService.get("KANBAN.ARCHIVED-BOARD-BUTTON").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const momo = this.modalService.create({
      nzTitle: title,
      nzClassName: 'modal-archives',
      nzBodyStyle: { height: '75vh' },
      nzContent: KanbanBoardArchivesComponent,
      nzWidth: '100%',
      nzFooter: [{
        label: close,
        onClick: () => { momo.close(); }
      }
      ]
    });
    momo.componentInstance.board = this.cds.selectedBoard;

    momo.afterClose.subscribe((result) =>
    {
      this.kbs.editedCard = null;
    });
  }

  noresultfound = false;
  globalFilter: KanbanGlobalSearchParameters = new KanbanGlobalSearchParameters();
  initBoardMove()
  {
    if (!this.globalFilter.searchText)
      return;

    this.kbs.getFilteredBoardAndListForUser(this.globalFilter).subscribe(x =>
    {
      this.boardsForMove = [];
      if (x.length == 0)
      {
        this.noresultfound = true;
        setTimeout(() =>
        {
          this.noresultfound = false;
        }, 2000);
      }
      else this.noresultfound = false;
      x.forEach(board =>
      {
        let boardNode = {
          title: board.title,
          key: "board_" + board.id,
          expanded: true,
          bgcolor: board.bgColor,
          children: []
        };
        board.lists.forEach(list =>
        {
          let listNode =
          {
            title: list.title,
            key: "list_" + list.id,
            expanded: true,
            children: []
          };
          list.cards.forEach(card =>
          {
            listNode.children.push({
              title: card.title,
              key: "card_" + card.id,
              isLeaf: true
            });
          })
          boardNode.children.push(listNode);
        });
        this.boardsForMove.push(boardNode);
      });
      this.boardsForMove = [...this.boardsForMove];
    })

  }

  selectListToMove(event: any)
  {
    if (!event.node.isLeaf)
      event.node.isExpanded = !event.node.isExpanded
    if (event.node.key.startsWith("board"))
    {
      this.router.navigate(["/kanban/" + event.node.key.split('_')[1]]);
    }
    else if (event.node.key.startsWith("card"))
    {
      let theevent = event.node;
      let selectedBoardForMove = "";
      while (theevent.parentNode)
      {
        theevent = theevent.parentNode;
        if (theevent.key.startsWith("board"))
          selectedBoardForMove = theevent.key;

      }
      this.router.navigate(["/kanban/" + selectedBoardForMove.split('_')[1] + "/" + event.node.key.split('_')[1]])

    }
    else
    {
      let theevent = event.node.parentNode;
      let selectedBoardForMove = "";
      while (theevent.parentNode)
      {
        if (theevent.key.startsWith("board"))
          selectedBoardForMove = theevent.key;
        theevent = theevent.parentNode;
      }
      this.router.navigate(["/kanban/" + selectedBoardForMove.split('_')[1]])
    }

  }

  getAllArchivedBoard()
  {
    return this.kbs.allBoardList.filter(x => x.archived).sort((x, y) => x.title > y.title ? 1 : -1);
  }

  restoreBoard(board: KanbanBoard)
  {
    this.kbs.restoreBoard(board.id).subscribe(x =>
    {
      board.archived = false;
    });
  }

  toogleWhite(hex)
  {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result)
    {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      if ((r + g + b) > 620)
      {
        return true;
      }
    }
    return false
  }

  getAllArchivedLists()
  {
    let list = this.cds.selectedBoard.lists.filter(x => x.archived).sort((x, y) => x.title > y.title ? 1 : -1);
    return list;
  }

  getAllArchivedCards()
  {
    let archivedCards: KanbanCard[] = [];
    this.cds.selectedBoard.lists.forEach(x =>
    {
      x.cards.forEach(y =>
      {
        if (y.archived)
        {
          y.visible = true;
          archivedCards.push(y);
        }
      });
    });
    archivedCards = archivedCards.sort((x, y) => (x.title > y.title ? 1 : -1));
    return archivedCards;
  }

  restoreList(list: KanbanList)
  {
    this.kbs.restoreList(list.id).subscribe(x =>
    {
      list.archived = false;
      this.kbs.allBoardList.find(x => x.id == this.cds.selectedBoard.id).lists.find(x => x.id == list.id).archived = false;
      this.cds.selectedBoard.lists.find(x => x.id == list.id).archived = false;
    })
  }

  restoreCard(card: KanbanCard)
  {
    this.kbs.restoreCard(card.id).subscribe(x =>
    {
      card.archived = false;
    });
  }

  initSearchModal()
  {
    this.searchModalVisible = true;
    setTimeout(() =>
    {
      document.getElementById("search-input").focus();
    }, 300);
  }

  getBoardTitle(id: number)
  {
    let board = this.kbs.allBoardList.find(x => x.id == id);
    if (board)
      return board.title;
    return "No Name"
  }

  getBoardBgColor(id: number)
  {
    let board = this.kbs.allBoardList.find(x => x.id == id);
    if (board)
      return board.bgColor;
    return "black"
  }


  getBoardColor(boardkey: string)
  {
    return this.boardsForMove.find(x => x.key == boardkey).bgcolor;
  }

  editCard(card: KanbanCard)
  {
    let title = "";
    this.translateService.get("USER.ADMIN").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let list = this.cds.selectedBoard ? this.cds.selectedBoard.lists.filter(x => x.cards.find(y => y.id == card.id) != null)[0] : null;
    const modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanCardEditModalComponent,
      nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
      nzWidth: '100%',
      nzFooter: [{
        label: close,
        onClick: () => { modalEdit.close(); }
      }
      ]
    });
    modalEdit.componentInstance.card = card;
    modalEdit.componentInstance.list = list;
    modalEdit.componentInstance.board = this.cds.selectedBoard;
    modalEdit.afterClose.subscribe((result) =>
    {
      this.kbs.editedCard = null;
      this.kts.resetEditModalField();
    });
  }

  getCardList(card: KanbanCard)
  {
    let list = this.cds.selectedBoard.lists.find(x =>
      x.cards.findIndex(y => y.id == card.id) > -1
    );
    return list;
  }

  rightClickedTemplate: KanbanCard = null;

  openCardTemplateContextMenu($event: MouseEvent, template: NzDropdownMenuComponent, tempcard: KanbanCard)
  {
    this.rightClickedTemplate = tempcard;
    this.templateCreationParameters.templateId = tempcard.id;
    this.templateCreationParameters.templateTitle = tempcard.templateTitle;
    this.templateCreationParameters.userIdList = tempcard.templateUserAccess;
    this.templateCreationParameters.teamIdList = tempcard.templateTeamAccess;
    this.nzDropdownService.create($event, template);
  }

  deleteCard()
  {
    this.kbs.deleteCard(this.rightClickedTemplate.id).subscribe(() =>
    {
      let index = this.kbs.allCardTemplate.findIndex(x => x.id == this.rightClickedTemplate.id);
      if (index >= 0)
        this.kbs.allCardTemplate.splice(index, 1);
    })
  }

  getStarredBoardList()
  {
    return this.kbs.allBoardList.filter(x => !(x.type & this.enumType.Template) && x.starred && !x.archived).sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
  }

  getOwnedBoardList()
  {
    return this.kbs.allBoardList.filter(x => !(x.type & this.enumType.Template) && x.owner.id == this.authService.Access.idUser && !x.archived).sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
  }

  boardIsInCategory(idboard: number)
  {
    let x = this.kbs.allBoardList.find(x => x.id == idboard);
    if (!x)
      return false;
    if (this.kbs.allCategoryList.findIndex(y => y.boardids.findIndex(z => z == x.id) >= 0) >= 0)
      return true
    return false;
  }

  getPersonalBoardList()
  {
    let listPersonalBoard: KanbanBoard[] = [];
    this.kbs.allBoardList.forEach(x =>
    {
      if (x.visibility == KanbanBoardVisibility.Shared || x.owner.id == this.authService.Access.idUser)
      {
        if (this.kbs.allCategoryList.findIndex(y => y.boardids.findIndex(z => z == x.id) >= 0) >= 0)
        {
          if (this.showAllPersonaBoards)
            listPersonalBoard.push(x);
        }
        else listPersonalBoard.push(x);
      }
    });
    return listPersonalBoard.sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
  }

  saveShowPersonalPreference(pref: boolean)
  {
    this.settingsService.setSettingsByName("ShowPersonalAllBoards", this.showAllPersonaBoards.toString());
    this.updatePublicAndPersonaBoard();
    this.updateEverything();
  }

  getPublicBoardList()
  {
    let listPersonalBoard: KanbanBoard[] = [];
    this.kbs.allBoardList.forEach(x =>
    {
      if (!(x.type & this.enumType.Template) && x.visibility == KanbanBoardVisibility.Public && !x.archived)
      {
        if (this.kbs.allCategoryList.findIndex(y => y.boardids.findIndex(z => z == x.id) >= 0) >= 0)
        {
          if (this.showAllPersonaBoards)
            listPersonalBoard.push(x);
        }
        else listPersonalBoard.push(x);
      }
    });
    return listPersonalBoard.sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
  }

  getTemplate()
  {
    return this.kbs.allTemplateList.filter(x => !x.archived).sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
  }
  unstareBoard(starredboards, event)
  {
    this.kbs.updateBoardStarred(starredboards.id, false).subscribe(x =>
    {
      starredboards.starred = false;
    });
    event.stopPropagation();
  }

  setCreatedBoardColor(color: string)
  {

    this.createdBoard.bgColor = color;
    this.customColor = false;
  }
  changeColor()
  {
    this.createdBoard.bgColor = "#8B0000";
    this.customColor = true;
  }

  updateSelectedBoardListCreationAuthorization()
  {
    this.kbs.updateListCreationAuthorization(this.cds.selectedBoard.id).subscribe()
  }

  selectBoard(board: KanbanBoard, isTemplate: boolean)
  {
    this.boardListVisible = false;
    this.templateListVisible = false;
    board.lists = [];
    this.kbs.doRedirectionGet = false;
    this.kbs.initBoard(board);

    if (this.redirect)
    {
      if (isTemplate)
      {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/kanban/template/' + board.id]));
      }
      else
      {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/kanban/' + board.id]));
      }
    }
  }

  boardIsUpdate()
  {
    if (this.createdBoard.id > 0)
    {
      if (this.createdBoard.type & this.enumType.Template)
        return 2;
      return 1;
    }
    return 0
  }

  initUpdateBoard()
  {
    this.createdBoard.title = this.cds.selectedBoard.title;
    this.createdBoard.bgColor = this.cds.selectedBoard.bgColor;
    this.createdBoard.comment = this.cds.selectedBoard.comment;
    this.createdBoard.id = this.cds.selectedBoard.id;
    this.createdBoard.creationDate = this.cds.selectedBoard.creationDate;
    this.createdBoard.owner = this.cds.userCollection.find(x => x.id == this.cds.selectedBoard.owner.id);
    this.boardIsUpdating = true;
    this.customColor = false;
    if (this.kbs.colorList.findIndex(x => x.value == this.cds.selectedBoard.bgColor) < 0)
    {
      this.customColor = true
    }
    this.settingsService.getSettingsByName("kanban_general_duedate" + this.cds.selectedBoard.id).subscribe(x => { this.dueDateNotification = x == 'true' });
    this.settingsService.getSettingsByName("kanban_general_startdate" + this.cds.selectedBoard.id).subscribe(x => { this.startDateNotification = x == 'true' });
    this.settingsService.getSettingsByName("kanban_general_boardmemberchange" + this.cds.selectedBoard.id).subscribe(x => { this.memberChangeNotification = x == 'true' });
    this.settingsService.getSettingsByName("kanban_general_time" + this.cds.selectedBoard.id).subscribe(x =>
    {
      if (x)
        this.timeNotification = new Date(x);
      else
        this.timeNotification = new Date(this.timeNotification.setHours(0, 0, 0, 0));
    });

    this.settingsService.getSystemSettingsByName("kanban_move_restricted_list" + this.cds.selectedBoard.id).subscribe(x =>
    {
      if (x && x == 'false')
        this.kbs.allowMoveForNonRestrictedList = false;
      else
        this.kbs.allowMoveForNonRestrictedList = true;
    });

    this.settingsService.getSystemSettingsByName("kanban_copy_restricted_list" + this.cds.selectedBoard.id).subscribe(x =>
    {
      if (x && x == 'false')
        this.kbs.allowCopyForNonRestrictedList = false;
      else
        this.kbs.allowCopyForNonRestrictedList = true;
    });

    this.settingsService.getSettingsByName("kanban_sort_labels_alphatically" + this.cds.selectedBoard.id).subscribe(x =>
    {
      if (x)
      {
        if (x == 'false')
          this.kbs.sortLabelsAlphabetically = false;
        else
          this.kbs.sortLabelsAlphabetically = true;
      }

    });

    this.settingsService.getSettingsByName("kanban_card_as_tasks" + this.cds.selectedBoard.id).subscribe(x =>
    {
      if (x)
      {
        if (x == 'false')
          this.kbs.showCardAsTasks = false;
        else
          this.kbs.showCardAsTasks = true;
      }

    });
  }

  loadArchivedBoard()
  {
    this.kbs.getAllArchivedBoardForConnectedUser().subscribe(x =>
    {
      this.archivedBoardList = x;
    })
  }

  deleteBoard()
  {
    if (this.cds.selectedBoard)
    {
      this.kbs.archiveBoard(this.cds.selectedBoard.id).subscribe(x =>
      {
        let index = this.kbs.allBoardList.findIndex(x => x.id == this.cds.selectedBoard.id);
        if (index >= 0)
          this.kbs.allBoardList.splice(index, 1);
        else
        {
          index = this.kbs.allTemplateList.findIndex(x => x.id == this.cds.selectedBoard.id);
          this.kbs.allTemplateList.splice(index, 1);
        }
        this.cds.selectedBoard = null;
      });
    }
  }

  updatePreference(key: string, value: string)
  {
    let realkey = key;
    realkey += this.cds.selectedBoard.id;
    this.settingsService.setSettingsByName(realkey, value);
  }

  templatetitle = "";
  editcardtemplatemodal = null;
  renameCardTemplate(template)
  {
    let title = "";
    this.translateService.get("KANBAN.CARD-TEMPLATE.RENAME").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.editcardtemplatemodal = this.modalService.create({
      nzTitle: title,
      nzContent: template,
      nzWidth: '600px',
      nzMaskClosable: false,
      nzFooter: null
    });
  }

  exportBoard()
  {
    let title = "";
    this.translateService.get("KANBAN.EXPORT.TITLE").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: ExportBoardComponent,
      nzWidth: '95%',
      nzFooter: [{
        label: title,
        type: "primary",
        onClick: (result) =>
        {
          let exportParam = result.submitExportParamObject();
          this.kbs.exportBoard(this.cds.selectedBoard.id, exportParam).subscribe(x =>
          {
            var byte = this.base64ToArrayBuffer(x);
            this.saveByteArray(this.cds.selectedBoard.title + ".json", byte);
          });
          this.kbs.modalEdit.close();
        }
      },
      {
        label: close,
        onClick: (result) =>
        {
          this.kbs.modalEdit.close();
        }
      }
      ]
    });
    this.kbs.modalEdit.componentInstance.board = this.cds.selectedBoard;
  }

  saveByteArray(reportName, byte)
  {
    var blob = new Blob([byte], { type: "application/json" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  base64ToArrayBuffer(base64)
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  updateSystemPreference(key: string, value: string)
  {
    let realkey = key;
    realkey += this.cds.selectedBoard.id;
    this.settingsService.setSystemSettingsByName(realkey, value);
    if (key.startsWith("kanban_move_restricted_list"))
      this.kbs.allowMoveForNonRestrictedList = value == "true";

    else if (key.startsWith("kanban_copy_restricted_list"))
      this.kbs.allowCopyForNonRestrictedList = value == "true";
  }

  getNotArchivedCategory()
  {
    return this.kbs.allCategoryList.filter(x => !x.archived)
  }

  archiveCategory(category: KanbanCategory)
  {
    this.kbs.archiveCategory(category.id).subscribe(x => category.archived = true)
  }

  onDraggedBoard(item: any, list: any[], effect: DropEffect)
  {
  }

  onCategoryDragStart(effect: DropEffect, category: KanbanCategory)
  {
    if (effect === "move")
    {
    }
  }

  getAllListAllowed()
  {
    let list = []
    for (let id of this.getStarredAllowedList())
    {
      list.push(id);
    }
    for (let id of this.getPersonalAllowedList())
    {
      list.push(id);
    }
    for (let id of this.getPublicAllowedList())
    {
      list.push(id);
    }
    for (let id of this.getCategoriesAllowedList())
    {
      list.push(id);
    }
    //console.log(list)

    return list;
  }

  getCategoriesAllowedList()
  {
    let list = [];
    for (let cat of this.kbs.allCategoryList)
    {
      if (!cat.segments)
        continue;
      for (let i = 0; i < cat.segments.length; i++)
      {
        list.push('cat_' + cat.id + '_' + i)
      }
    }
    return list;
  }

  onDropBoardToCategory(event: CdkDragDrop<number[]>, category: KanbanCategory)
  {
    let idboard: number = event.item.data;
    let board: KanbanBoard = this.kbs.allBoardList.find(x => x.id == idboard);
    if (category.boardids.findIndex(x => x == board.id) >= 0)
    {
      if (!event.previousContainer.id.startsWith("cat_" + category.id))
        return;
      if (event.previousContainer === event.container)
      {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      }
      else
      {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
      category.boardids = [];
      for (let segment of category.segments)
      {
        for (let boardid of segment)
        {
          category.boardids.push(boardid);
        }
      }
      this.kbs.sortBoardForCategory(category.id, category.boardids).subscribe();
      this.updateCategorySegment(category);
      return;
    }

    transferArrayItem(event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex);

    this.kbs.addBoardToCategory(category.id, board.id).subscribe(x =>
    {
      category.boardids = [];
      for (let segment of category.segments)
      {
        for (let boardid of segment)
        {
          category.boardids.push(boardid);
        }
      }
      let personaindex = this.kbs.allPersonalBoardList.findIndex(x => x == board.id);
      if (personaindex >= 0 && !this.showAllPersonaBoards)
        this.kbs.allPersonalBoardList.splice(personaindex, 1)

      let publicindex = this.kbs.allPublicBoardList.findIndex(x => x == board.id);
      if (publicindex >= 0 && !this.showAllPersonaBoards)
        this.kbs.allPublicBoardList.splice(publicindex, 1)
      this.kbs.sortBoardForCategory(category.id, category.boardids).subscribe();
      this.updatePersonalSegment();
      this.updatePublicSegment();
      this.updateCategorySegment(category);
    });
    // setTimeout(() =>
    // {

    // }, 300);


  }

  getBoard(idboard: number)
  {
    return this.kbs.allBoardList.find(x => x.id == idboard);
  }

  initTemplateCreation()
  {
    this.createdBoard.type = this.cds.selectedBoard.type | this.enumType.Template;
    this.createdBoard.id = this.cds.selectedBoard.id;
  }
  initBoardCreation(isPublic: boolean, starred: boolean, isTemplate: boolean, isPersonal: boolean, idcategory: number)
  {
    this.idcreateboardcategory = idcategory;
    if (isTemplate)
      this.createdBoard.type = this.enumType.Template | this.enumType.Kanban;
    else this.createdBoard.type = this.enumType.Kanban;

    if (isPublic)
    {
      this.createdBoard.starred = false;
      this.boardPublicIsCreating = true;
    }
    else if (starred)
    {
      this.createdBoard.starred = true;
      this.boardStarredIsCreating = true;
    }
    else if (isPersonal)
    {
      this.createdBoard.starred = false;
      this.boardPersonalIsCreating = true;
    }

    this.createdBoard.bgColor = this.kbs.colorList[0].value;
    event.stopPropagation()
  }

  initCategory()
  {
    this.createdCategory.title = "";
    this.createdCategory.position = this.kbs.allCategoryList.length + 1;
    this.createdCategory.archived = false;
    this.kbs.setFocusToElement("input-create-category")
  }

  copyboard()
  {
    this.kbs.copyBoard(this.cds.selectedBoard.id).subscribe(x =>
    {
      this.kbs.allBoardList.push(x);
      this.selectBoard(x, false);
    })
  }

  noReturnPredicate()
  {
    return false;
  }

  dropPersonal(event: CdkDragDrop<number[]>): void
  {
    if (event.previousContainer === event.container)
    {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else
    {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.kbs.allPersonalBoardList = [];
    for (let segment of this.kbs.allPersonalSegmentList)
    {
      for (let boardid of segment)
      {
        this.kbs.allPersonalBoardList.push(boardid);
      }
    }
    this.kbs.updateBoardPersonalPosition(this.kbs.allPersonalBoardList).subscribe();
    this.kbs.homeInfos.personalBoardIds = this.kbs.allPersonalBoardList;
    this.updatePersonalSegment();
  }

  getPersonalAllowedList()
  {
    let list = []
    for (let i = 0; i < this.kbs.allPersonalSegmentList.length; i++)
    {
      list.push("personalcat" + i);
    }
    return list;
  }

  dropPublic(event: CdkDragDrop<number[]>): void
  {
    if (event.previousContainer === event.container)
    {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else
    {
      let newIndex = event.currentIndex == event.container.data.length ? event.currentIndex : event.currentIndex - 1;
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    setTimeout(() =>
    {
      this.kbs.allPublicBoardList = [];
      for (let segment of this.kbs.allPublicSegmentList)
      {
        for (let boardid of segment)
        {
          this.kbs.allPublicBoardList.push(boardid);
        }
      }
      this.kbs.updateBoardPublicPosition(this.kbs.allPublicBoardList).subscribe();
      this.kbs.homeInfos.publicBoardIds = this.kbs.allPublicBoardList;
      this.updatePublicSegment();
    }, 300);
  }

  getPublicAllowedList()
  {
    let list = []
    for (let i = 0; i < this.kbs.allPublicSegmentList.length; i++)
    {
      list.push("publiccat" + i);
    }
    return list;
  }

  dropStarred(event: CdkDragDrop<number[]>): void
  {

    if (event.previousContainer === event.container)
    {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else
    {
      if (this.kbs.allStarredBoardList.indexOf(event.item.data) >= 0 && !event.previousContainer.id.startsWith("starred"))
        return;
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    setTimeout(() =>
    {
      this.kbs.allStarredBoardList = [];
      for (let segment of this.kbs.allStarredSegmentList)
      {
        for (let boardid of segment)
        {
          this.kbs.allStarredBoardList.push(boardid);
        }
      }
      this.kbs.updateBoardStarredPosition(this.kbs.allStarredBoardList).subscribe();
      this.updatePersonalSegment();
      this.updatePublicSegment();
      this.updateStarredSegment();
    }, 300);
  }

  getStarredAllowedList()
  {
    let list = []
    for (let i = 0; i < this.kbs.allPublicSegmentList.length; i++)
    {
      list.push("starred" + i);
    }

    return list;
  }

  drop(event: CdkDragDrop<KanbanCategory[]>): void
  {
    if (event.previousContainer === event.container)
    {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else
    {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    setTimeout(() =>
    {
      let move = new KanbanMoveCategory();
      move.categoriesId = this.kbs.allCategoryList.map(({ id }) => id);

      this.kbs.moveCategory(move).subscribe();
    }, 300);
  }

  cancel() { }

  createTheCategory()
  {
    this.kbs.createCategory(this.createdCategory.title).subscribe(x =>
    {
      let category = new KanbanCategory();
      category.id = x;
      category.title = this.createdCategory.title;
      category.position = this.createdCategory.position;
      category.archived = this.createdCategory.archived;
      this.kbs.allCategoryList.push(category);
      this.categoryIsCreating = false;
    })
  }

  createTheBoard()
  {
    if (!this.createdBoard.title)
    {
      this.ErrorEmptyBoardName = true;
      return;
    }

    this.ErrorEmptyBoardName = false;
    let board = new KanbanBoard();
    if (this.importedBoard.title)
    {
      this.importedBoard.title = this.createdBoard.title;
      this.importedBoard.bgColor = this.createdBoard.bgColor;
      this.importedBoard.starred = this.createdBoard.starred;
      this.importedBoard.comment = this.createdBoard.comment;
      //this.kbs.allBoardList.find(x => x.id == 1112)
      this.kbs.importBoard(this.importedBoard).subscribe(x =>
      {
        this.kbs.allBoardList.push(x);
        if (this.redirect)
          this.router.navigate(['/kanban/' + x.id]);
        else this.kbs.initBoard(x);
      })

    }
    else
    {
      board.title = this.createdBoard.title;
      board.bgColor = this.createdBoard.bgColor;
      board.starred = this.createdBoard.starred;
      board.owner = this.authService.connectedUser;
      board.type = this.createdBoard.type;
      board.comment = this.createdBoard.comment;
      if (this.boardPublicIsCreating)
        board.visibility = this.enumVis.Public;
      else board.visibility = KanbanBoardVisibility.Shared;

      board.idTemplate = this.idTemplateToApply;

      this.kbs.createBoard(board).subscribe(x =>
      {
        board.id = x;

        board.members.push(this.authService.connectedUser.id);
        if (board.type & KanbanBoardType.Template)
          this.kbs.allTemplateList.push(board);
        else this.kbs.allBoardList.push(board);
        this.createdBoard = new KanbanBoard();
        this.boardStarredIsCreating = false;
        this.boardPersonalIsCreating = false;
        this.boardPublicIsCreating = false;
        if (board.starred)
          this.kbs.allStarredBoardList.push(board.id);
        else if (board.visibility == this.enumVis.Public)
          this.kbs.allPublicBoardList.push(board.id);
        else if (this.idcreateboardcategory > 0)
        {
          this.kbs.addBoardToCategory(this.idcreateboardcategory, board.id).subscribe(() =>
          {
            let cat = this.kbs.allCategoryList.find(t => t.id == this.idcreateboardcategory);
            if (cat)
              cat.boardids.push(board.id);
          });
        }
        else this.kbs.allPersonalBoardList.push(board.id);

        if (this.redirect)
        {
          if (board.type & KanbanBoardType.Template)
            this.router.navigate(['/kanban/template/' + x]);
          else this.router.navigate(['/kanban/' + x]);
        }
        else
        {
          this.kbs.initBoard(board);
        }
      });
    }

  }



  updateImportedBoard({ file, fileList }: { [key: string]: any })
  {
    const status = file.status;
    if (status === 'done')
    {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
      {
        try
        {
          if (file.fileName.split('.').pop() != '.json')
          {
            this.error();
            return;
          }
          this.importedBoard = JSON.parse(reader.result.toString());
          if (!this.importedBoard.title)
          {
            this.error();
          }
        }
        catch (e)
        {
          this.error();
        }
      });
      reader.readAsText(file.originFileObj);
    } else if (status === 'error')
    {
      console.log(`${file.name} file upload failed.`);
    }
  }

  error()
  {
    let errormsg = "";
    this.translateService.get("KANBAN.IMPORT.ERROR-FORMAT-FILE").subscribe(x => errormsg = x)
    this.message.create("error", errormsg);
    removeUploadList();
  }

  exportTemplate()
  {
    this.createdBoard.type = this.enumType.Template | this.enumType.Kanban;
    this.kbs.createTemplateFromBoard(this.createdBoard).subscribe(x =>
    {
      this.kbs.allBoardList.push(x);

      this.createdBoard = new KanbanBoard();
      this.boardStarredIsCreating = false;
      this.boardPersonalIsCreating = false;
      this.boardPublicIsCreating = false;
      this.cds.selectedBoard = null;
      this.router.navigate(['/kanban/template/' + x.id]);
    });
  }

  updateBoard()
  {
    this.kbs.updateBoard(this.createdBoard.id, this.createdBoard).subscribe(x =>
    {
      this.cds.selectedBoard.title = this.createdBoard.title;
      this.cds.selectedBoard.bgColor = this.createdBoard.bgColor;
      this.cds.selectedBoard.comment = this.createdBoard.comment;
      this.boardIsUpdating = false;
    });
  }

  getHeaderColor()
  {
    if (this.cds.selectedBoard)
      return this.cds.selectedBoard.bgColor;
    return "";
  }

  getHeaderOpacity()
  {
    if (this.selectBoard)
      return 0.8;
    return 1;
  }

  returnHome()
  {
    this.cds.selectedBoard = null;
    if (this.redirect)
    {
      if (this.router.url.startsWith("/kanban/template"))
        this.router.navigate(['/kanban/template'])
      else this.router.navigate(['/kanban/'])
    }
  }
}
