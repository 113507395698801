import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { MoralPerson } from '../../third-party/moral-person/entities/moral-person';
import { MoralPersonService } from '../../third-party/moral-person/services/moral-person.service';
import { CacheDataService } from '../cache-data.service';
import { UpdateTpAttachmentDTO } from '../../entities/update-attachment-thirdparty';

@Injectable({
  providedIn: 'root'
})
export class MoralPersonNotificationService
{

  constructor(private mps: MoralPersonService, private cds: CacheDataService) { }

  initRoutes(_hubConnection: HubConnection)
  {

    _hubConnection.on('InitMoralPersonCache', (mplist: MoralPerson[]) =>
    {
      mplist.forEach(mp =>
      {
        if (this.cds.moralPersonCollection.findIndex(x => x.id == mp.id) < 0)
          this.cds.moralPersonCollection.push(mp);
      })
      if (mplist.length == 0)
        this.mps.moralPersonListChange.next(null);
    });

    _hubConnection.on('MoralPersonCreation', (mp: MoralPerson) =>
    {
      let index = this.cds.moralPersonCollection.findIndex(x => x.id == mp.id);
      if (index >= 0)
      {
        let parent = this.cds.moralPersonCollection.find(x => x.id == mp.parentSiteId);
        if (parent)
        {
          if (parent.secondarySites.findIndex(x => x.id == mp.id) < 0)
            parent.secondarySites.push(mp);
        }

        return;
      }

      this.cds.moralPersonCollection.push(mp);
      this.mps.moralPersonListChange.next(null);
    });

    _hubConnection.on('UpdateMoralPersonAttachment', (updatetp: UpdateTpAttachmentDTO) =>
    {
      let index = this.cds.moralPersonCollection.findIndex(x => x.id == updatetp.idtp);
      if (index == -1)
        return;
      this.cds.moralPersonCollection[index].nbAttachments = updatetp.nbAttachment;
      this.mps.moralPersonListChange.next(null);
    });

    _hubConnection.on('MoralPersonUpdate', (mp: MoralPerson) =>
    {
      let index = this.cds.moralPersonCollection.findIndex(x => x.id == mp.id);
      if (index == -1)
        return;
      this.cds.moralPersonCollection[index] = mp;
      this.mps.moralPersonListChange.next(null);
    });

    _hubConnection.on('MoralPersonArchive', (mp: MoralPerson) =>
    {
      let index = this.cds.moralPersonCollection.findIndex(x => x.id == mp.id);
      if (index == -1)
      {
        let parentMp = this.cds.moralPersonCollection.find(x => x.secondarySites.find(y => y.id == mp.id) != null);
        if (!parentMp)
          return;
        index = parentMp.secondarySites.findIndex(x => x.id == mp.id);
        parentMp.secondarySites[index].archived = true;
        this.mps.moralPersonListChange.next(null);
        return;
      }
      this.cds.moralPersonCollection[index].archived = true;
      this.mps.moralPersonListChange.next(null);
    });

    _hubConnection.on('MoralPersonRestore', (mp: MoralPerson) =>
    {
      let index = this.cds.moralPersonCollection.findIndex(x => x.id == mp.id);
      if (index == -1)
      {
        let parentMp = this.cds.moralPersonCollection.find(x => x.secondarySites.find(y => y.id == mp.id) != null);
        if (!parentMp)
          return;
        index = parentMp.secondarySites.findIndex(x => x.id == mp.id);
        parentMp.secondarySites[index].archived = false;
        this.mps.moralPersonListChange.next(null);
        return;
      }

      this.cds.moralPersonCollection[index].archived = false;
      this.mps.moralPersonListChange.next(null);
    });

  }

}
