import { GlobalLabel } from "./Label";
import { KanbanComment } from "./Comment";
import { KanbanChecklist } from "./Checklist";
import { KanbanAttachment } from "./Attachment";
import { KanbanActivity } from "./Activity";
import { PhysicalPerson } from "../../third-party/physical-person/entities/physical-person";
import { MoralPerson } from "../../third-party/moral-person/entities/moral-person";
import { ListAction } from "./list-actions-control";
import { Reccurence } from "../../SDICalendar/calendar/entities/reccurence";
import { Receivedmail } from "../../messagerie/entities/receivedmail";
import { PresenceInfos } from "./presence-infos";

export class KanbanCard
{
    id: number = 0;
    title: string = "";
    templateTitle = "";
    description = "";
    archived = false;
    completed: boolean;
    startDate: Date;
    startDateTemplate = "";
    dueDate: Date;
    dueDateTemplate = "";
    endDate: Date;
    listId: number = 0;
    listName: string = "";
    boardId: number = 0;
    boardName: string = "";
    bgColor: string = "";
    comments: KanbanComment[] = [];
    members: number[] = [];
    teams: number[] = [];
    membersPresence: PresenceInfos[] = [];
    teamsPresence: PresenceInfos[] = [];
    mails: Receivedmail[] = [];
    templateUserAccess: number[] = [];
    templateTeamAccess: number[] = [];
    labels: GlobalLabel[] = [];
    checklists: KanbanChecklist[] = [];
    attachments: KanbanAttachment[] = [];
    activities: KanbanActivity[] = [];
    physicalPersons: PhysicalPerson[] = [];
    moralPersons: MoralPerson[] = [];
    visible = true;
    creatorId: number;
    creationDate: Date;
    actions: ListAction[] = [];
    type: number = 0;
    position = 0;
    reccurence: Reccurence;
    reccurenceId: number = null;
    fullDay = false;
    location = "";
}