import { CustomField } from "../../entities/custom-field";
import { PhoneNumber } from "../../entities/phone-number";
import { Site } from "../../entities/site";
import { KanbanAttachment } from "../../../../../src/app/kanban/entities/Attachment";
import { KanbanCard } from "../../../../../src/app/kanban/entities/Card";
import { FilterRelation } from "../../entities/filter-relation";
import { Mail } from "../../entities/mail";
import { ThirdPartyActivity } from "../../entities/third-party-activity";
import { GlobalLabel } from "../../../../../src/app/kanban/entities/Label";
import { ThirdPartyCustomSystemField } from "../../../../../src/app/call-interface/entities/third-party-custom-system-field";
import { MoralPerson } from "../../moral-person/entities/moral-person";

export class PhysicalPerson
{
    id: number;
    firstName: string;
    lastName: string;
    lastName2: string;
    title: string;
    spokenLanguages: string[] = [];
    anniversaryDate: Date;
    comment: string;
    siret: string;
    siren: string;
    nationality: string;
    archived: boolean;
    nbAttachments: number;
    tags: string[] = [];
    image: string;
    creatorId: number;
    creationDate: Date;
    activities: ThirdPartyActivity[] = []
    companyId: number;
    company: MoralPerson;
    department = "";
    job = "";

    websites: string[] = [];
    secondaryNames: string[] = [];
    brands: string[] = [];
    mails: Mail[] = [];
    customFields: CustomField[] = [];
    customSystemFields: ThirdPartyCustomSystemField[] = [];
    phoneNumbers: PhoneNumber[] = [];
    sites: Site[] = [];
    attachments: KanbanAttachment[] = [];
    cards: KanbanCard[] = [];
    filteredRelations: FilterRelation[] = [];
    labels: GlobalLabel[] = [];
}