// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelForm {
  white-space: unset;
  text-align: left;
  line-height: 1.4;
}

.helper {
  padding-left: 20px;
}

.component {
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/SDICalendar/calendar/form/text-input/text-input.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".labelForm {\r\n  white-space: unset;\r\n  text-align: left;\r\n  line-height: 1.4;\r\n}\r\n\r\n.helper {\r\n  padding-left: 20px\r\n}\r\n\r\n.component {\r\n  margin-bottom: 24px\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
