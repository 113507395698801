import { Pipe, PipeTransform } from '@angular/core';
import { CacheDataService } from 'src/app/services/cache-data.service';

@Pipe({
  name: 'titlelabel',
  pure: false
})
export class TitleLabelPipe implements PipeTransform
{

  constructor(public cds: CacheDataService) { }

  transform(value: number): string
  {
    let label = this.cds.allGlobalLabelCollection.find(x => x.id == value);
    if (label)
      return label.title;
    label = this.cds.labelList.find(x => x.id == value);
    if (label)
      return label.title;
    label = this.cds.publicLabelList.find(x => x.id == value);
    if (label)
      return label.title;
    return "";
  }

}
