import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarTeamPipe } from './avatar-team.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [AvatarTeamPipe],
  exports: [AvatarTeamPipe],
  providers: [AvatarTeamPipe],
})
export class AvatarTeamPipeModule { }
