import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanUserFilteredPipe } from './kanban-user-filtered.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KanbanUserFilteredPipe],
  exports: [KanbanUserFilteredPipe]
})
export class KanbanUserFilteredPipeModule { }
