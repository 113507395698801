import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { ChatService } from 'src/app/chat/chat.service';
import { ChatMessage } from 'src/app/chat/entities/chat-message';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatMessageRead } from 'src/app/chat/entities/chat-message-read';
import { GlobalNotificationService } from './global-notification.service';
import { ChatNotificationContainer } from 'src/app/entities/chat-notification-container';
import { CacheDataService } from '../cache-data.service';
import { AuthService } from '../auth.service';
import { ChatDiscussion } from 'src/app/chat/entities/chat-discussion';
import { FloatingChatService } from '../floating-chat.service';
import { SettingsService } from 'src/app/settings/services/settings.service';

@Injectable({
    providedIn: 'root'
})
export class ChatNotificationService
{

    constructor(
        private chatS: ChatService,
        private router: Router,
        private route: ActivatedRoute,
        private gbs: GlobalNotificationService,
        public cds: CacheDataService,
        public fcs: FloatingChatService,
        public authService: AuthService,
        private settingsService: SettingsService,
    ) { }

    initRoutes(_hubConnection: HubConnection,)
    {
        _hubConnection.on('NotifyReadPrivateMessageFromChat', (chatMessage: ChatMessage) =>
        {
            this.chatS.ReadMessageSub.next(chatMessage)
        });

        _hubConnection.on('NotifyConfirmRead', () =>
        {

            this.chatS.readConfirmSub.next(null)
        });

        _hubConnection.on('NotifyMessageFromChat', (chatMessage: ChatMessage) =>
        {
            this.chatS.ChatNotification.next(chatMessage)

            setTimeout(() =>
            {
                if (chatMessage.discussionId)
                {
                    this.gbs.chatNotifications = []
                    this.gbs.getAllChatNotifications().subscribe()
                    return
                }
                this.chatS.getNotificationsSettings(chatMessage.creatorId).subscribe(x =>
                {

                    if (!x)
                        return
                    else
                        this.gbs.getAllChatNotifications().subscribe()
                });
            }, 500);
        });

        _hubConnection.on('NotifyRoom', (roomId: number) =>
        {
            this.gbs.chatNotifications = []
            this.chatS.reloadDiscussionSub.next(roomId)

            setTimeout(() =>
            {
                this.gbs.getAllChatNotifications().subscribe()
            }, 500);
        });

        _hubConnection.on('messageIdIsRead', () =>
        {
            this.gbs.chatNotifications = []
            setTimeout(() =>
            {
                this.gbs.getAllChatNotifications().subscribe()
            }, 500);
        });
    }
}
