import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentManagerComponent } from './attachment-manager.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FileTypeIconModule } from '../file-type-icon/file-type-icon.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { HtmlSafeModule } from '../../../../src/app/pipes/html-safe/html-safe.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PipesModule } from '../../pipes/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzUploadModule,
    TranslateModule,
    NzIconModule,
    NzPopconfirmModule,
    NzSpinModule,
    HtmlSafeModule,
    FormsModule,
    PipesModule,
    NzToolTipModule,
    NgxExtendedPdfViewerModule,
    FileTypeIconModule
  ],
  declarations: [AttachmentManagerComponent],
  exports: [AttachmentManagerComponent]
})
export class AttachmentManagerModule { }
