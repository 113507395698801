import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanUserExiledPipe } from './kanban-user-exiled.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KanbanUserExiledPipe],
  exports: [KanbanUserExiledPipe]
})
export class KanbanUserExiledPipeModule { }
