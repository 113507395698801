import { Component, OnInit, Input } from '@angular/core';
import { SearchClientResult } from '../../entities/search-client-result';
import { MoralPersonService } from '../../services/moral-person.service';
import { SettingsService } from 'src/app/settings/services/settings.service';
import { CountryManagerService } from 'src/app/country-manager/services/country-manager.service';
import { CacheDataService } from 'src/app/services/cache-data.service';
import { TextCompareService } from 'src/app/services/text-compare.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-client-info',
  templateUrl: './search-client-info.component.html',
  styleUrls: ['./search-client-info.component.scss']
})
export class SearchClientInfoComponent implements OnInit
{

  constructor(private mps: MoralPersonService,
    private cms: CountryManagerService,
    private cds: CacheDataService,
    public tcs: TextCompareService,
    private translateService: TranslateService,
    private settingsService: SettingsService) { }

  @Input() prefilter: string;
  selectedResult: SearchClientResult;
  resultsList: SearchClientResult[] = [];
  filteredResultsList: SearchClientResult[] = [];
  osef: boolean;
  loadingData = false;
  searchtext: string = null;
  startTheShit = false;
  filtertext = "";
  raisonSocialeVisible = false;
  sirenVisible = false;
  siretVisible = false;
  codenafVisible = false;
  numtvaVisible = false;
  secteurActiviteVisible = false;
  addressVisible = false;
  complementAddressVisible = false;
  villeVisible = false;
  codePostalVisible = false;
  boitePostalVisible = false;
  paysVisible = false;
  siegeVisible = false;
  formeJuridiqueVisible = false;
  searchClientMaxResultCount = "50";
  searchRaisonSociale = "";
  searchSiren = "";
  searchSiret = "";
  searchCodeNAF = "";
  searchNumeroTVAIntracom = "";
  searchSecteurActivite = "";
  searchAddresse = "";
  searchComplementAddresse = "";
  searchVille = "";
  searchCodePostal = "";
  searchBoitePostal = "";
  searchCreationDate: Date[] = [];
  searchPays = "";
  searchSiege = "";
  searchFormeJuridique = "";

  ngOnInit()
  {
    this.cms.getAllCountries().subscribe()
    if (this.prefilter)
      this.searchtext = this.prefilter;
    this.settingsService.getSettingsByName("SearchClientResultMaxCount").subscribe(x =>
    {
      this.searchClientMaxResultCount = !x ? "50" : x;

    });
  }

  getTooltip(numinsee)
  {
    let text = "";
    if (this.alreadyExist(numinsee))
      this.translateService.get("THIRD-PARTY.ERROR.MORAL-PERSON-ALREADY-EXIST-SIRET").subscribe(x => text = x);
    return text;
  }

  UpdateMaxResultCountPref()
  {
    this.settingsService.setSettingsByName("SearchClientResultMaxCount", this.searchClientMaxResultCount);
    this.startSearch();
  }

  alreadyExist(siret: string)
  {
    return this.cds.allmoralperson.findIndex(x => x.siret == siret) >= 0
  }

  updatedSelectedResult(checked: boolean, result: SearchClientResult)
  {
    if (this.startTheShit)
      return;
    this.startTheShit = true;
    for (var res of this.resultsList)
    {
      res.selected = false;
    }
    result.selected = true;
    if (checked)
      this.selectedResult = result;
    else this.selectedResult = null;
    this.startTheShit = false;
  }

  startSearch()
  {
    if (!this.searchtext)
      return;
    this.loadingData = true;
    this.mps.searchClient(this.searchtext).subscribe(x =>
    {
      this.resultsList = x;
      this.searchResults();
      this.loadingData = false;
    });
  }

  hasTooMany()
  {
    if (this.searchClientMaxResultCount == "-1")
      return false;
    if (this.resultsList.length > parseInt(this.searchClientMaxResultCount))
      return true;
    return false;
  }

  searchResults()
  {
    let upperfilter = this.filtertext.toUpperCase();
    this.filteredResultsList = this.resultsList.filter(x =>
      (x.raisonSociale && this.tcs.contains(x.raisonSociale, upperfilter)) ||
      (x.codeNAF && this.tcs.contains(x.codeNAF, upperfilter)) ||
      (x.secteurActivite && this.tcs.contains(x.secteurActivite, upperfilter)) ||
      (x.adresse && this.tcs.contains(x.adresse, upperfilter)) ||
      (x.ville && this.tcs.contains(x.ville, upperfilter)) ||
      (x.codePostal && this.tcs.contains(x.codePostal, upperfilter)) ||
      (x.pays && this.tcs.contains(x.pays, upperfilter)) ||
      (x.rCS && this.tcs.contains(x.rCS, upperfilter)) ||
      (x.formeJuridique && this.tcs.contains(x.formeJuridique, upperfilter)) ||
      (x.etatAdministratif && this.tcs.contains(x.etatAdministratif, upperfilter)) ||
      (x.diffusion && this.tcs.contains(x.diffusion, upperfilter)) ||
      (x.numeroTVAIntracom && this.tcs.contains(x.numeroTVAIntracom, upperfilter)) ||
      (x.numeroSIREN && this.tcs.contains(x.numeroSIREN, upperfilter)) ||
      (x.numeroSIRET && this.tcs.contains(x.numeroSIRET, upperfilter))
    );
    if (this.searchRaisonSociale)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.raisonSociale && this.tcs.contains(x.raisonSociale, this.searchRaisonSociale));
    if (this.searchSiren)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.numeroSIREN && this.tcs.contains(x.numeroSIREN, this.searchSiren));
    if (this.searchSiret)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.numeroSIRET && this.tcs.contains(x.numeroSIRET, this.searchSiret));
    if (this.searchCodeNAF)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.codeNAF && this.tcs.contains(x.codeNAF, this.searchCodeNAF));
    if (this.searchNumeroTVAIntracom)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.numeroTVAIntracom && this.tcs.contains(x.numeroTVAIntracom, this.searchNumeroTVAIntracom));
    if (this.searchSecteurActivite)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.secteurActivite && this.tcs.contains(x.secteurActivite, this.searchSecteurActivite));
    if (this.searchAddresse)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.adresse && this.tcs.contains(x.adresse, this.searchAddresse));
    if (this.searchComplementAddresse)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.complementAdresse && this.tcs.contains(x.complementAdresse, this.searchComplementAddresse));
    if (this.searchVille)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.ville && this.tcs.contains(x.ville, this.searchVille));
    if (this.searchCodePostal)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.codePostal && this.tcs.contains(x.codePostal, this.searchCodePostal));
    if (this.searchBoitePostal)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.boitePostale && this.tcs.contains(x.boitePostale, this.searchBoitePostal));
    if (this.searchCreationDate.length == 2)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.creationDate && (x.creationDate.getTime() > this.searchCreationDate[0].getTime() && x.creationDate.getTime() < this.searchCreationDate[1].getTime()));
    if (this.searchPays)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.pays && this.tcs.contains(x.pays, this.searchPays));
    if (this.searchSiege)
      this.filteredResultsList = this.filteredResultsList.filter(x => (this.searchSiege.toLowerCase() == "oui" && x.isSiege) || (this.searchSiege.toLowerCase() == "non" && !x.isSiege));
    if (this.searchFormeJuridique)
      this.filteredResultsList = this.filteredResultsList.filter(x => x.formeJuridique && this.tcs.contains(x.formeJuridique, this.searchFormeJuridique));
    this.filteredResultsList = this.filteredResultsList.sort((a, b) => a.raisonSociale < b.raisonSociale ? -1 : 1);
  }

  sort(sortDirection: string, sortKey: string)
  {
    if (this.filteredResultsList.length == 0)
      return;
    let temp = [...this.filteredResultsList];
    if (sortKey == "creationDate")
    {
      this.filteredResultsList = temp.sort((a, b) => (sortDirection === 'descend') ? (a.creationDate > b.creationDate) ? 1 : -1 : (a.creationDate < b.creationDate) ? 1 : -1);
    }
    else if (typeof this.filteredResultsList[0][sortKey] === "string")
      this.filteredResultsList = temp.sort((a, b) => (sortDirection === 'descend') ? (a[sortKey]?.localeCompare(b[sortKey]) > 0 ? 1 : -1) : (b[sortKey]?.localeCompare(a[sortKey]) > 0 ? 1 : -1));
    else this.filteredResultsList = temp.sort((a, b) => (sortDirection === 'descend') ? (a[sortKey] > b[sortKey] ? 1 : -1) : (b[sortKey] > a[sortKey] ? 1 : -1));
  }
}
