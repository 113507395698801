import { Receivedmail } from "./receivedmail";

export class MessagerieFolder
{
    id: number = 0;
    name: string;
    fullpath = "";
    mails: Receivedmail[] = [];
    unreadMailCount: number;
    subfoldersUnreadMailCount: number;
    autosortSubfolders = false;
    accountId = 0;
    accountName = "";
    archived = false;
    displayName = "";
    opened = false;
    ownerId = 0;
    allowedUsers: number[] = [];
    allowedTeams: number[] = [];
    authorization = 0;
    archivedDate: Date = new Date();
    subfolders: MessagerieFolder[] = [];
    isActive = true;
    isTrash = false;
    isSent = false;
    isDraft = false;
    isSpam = false;
    isOutbox = false;
    isInbox = false;
}