import { Pipe, PipeTransform } from '@angular/core';
import { CacheDataService } from 'src/app/services/cache-data.service';

@Pipe({
  name: 'nameteam',
  pure: false
})
export class NameTeamPipe implements PipeTransform
{
  constructor(public cds: CacheDataService) { }
  transform(value: number): string
  {
    return this.cds.getTeamName(value);
  }

}
