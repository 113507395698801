import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input('LABEL') public LABEL: string;
  @Input('VARIABLE') public VARIABLE: string;
  @Input('HELP_TXT') public HELP_TXT: string;
  @Input('VALIDATE_TXT') public VALIDATE_TXT: string = '';
  @Input('REQUIRED') public REQUIRED: boolean;
  @Input('OPTIONS') public OPTIONS: string[];
  @Input('VALUES') public VALUES: any[];
  @Output() ON_CHANGE = new EventEmitter();
  @Output() VARIABLEChange = new EventEmitter<string>();
  @Output() VALIDATE_FUNC = new EventEmitter();

  changedNgModel() {
    this.VARIABLEChange.emit(this.VARIABLE)
    this.ON_CHANGE.emit()
    this.validateFunctionCallback()
  }

  validateFunctionCallback() {
    this.VALIDATE_FUNC.emit();
  }

  constructor() { }

  ngOnInit() {
  }

}
