import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelpSetting } from 'src/app/entities/help-setting';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit
{

  constructor(private translateService: TranslateService,
    private auth: AuthService) { }

  alertReady = false;
  completeTextKey = "HELP.";
  showAlert = false;
  @Input() showIcon;
  @Input() textKey: string;
  @Input() mode = "bluebox";
  ngOnInit()
  {
    this.completeTextKey += this.textKey;
    //this.translateService.get("HELP." + this.textKey).subscribe(x => this.text = x);
  }
  text = "";

  saveShowAlert()
  {
    if (this.showAlert)
    this.showAlert = false;
    else
    this.showAlert = true;
  }
}
