import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../../services/url-api.service';
import { GroupCredentialsWithRights } from '../../entities/group-credentials-with-rights';
import { Observable } from 'rxjs';
import { GroupCredentials } from '../../entities/group-credentials';
import { GroupCredentialsManager } from '../../entities/group-credentials-manager';
import { InfoCredentials } from '../../entities/info-credentials';
import { GroupAndCredentials } from '../../entities/group-and-credentials';
import { Right } from '../../../entities/right';
import { ManagerUpdateRightCustomGroup } from '../../entities/manager-update-right-custom-group';
import { UserUsingGroupCredentials } from '../../entities/user-using-group-credentials';
import { CredentialsGroupSharedUserGroupDTO } from '../../entities/credentials-group-shared-user-group';
import { UserForCredentialsWithOrigin } from 'src/app/credentials/entities/user-for-credentials-with-origin';

@Injectable({
  providedIn: 'root'
})
export class GroupCredentialsSourceService {

  constructor(private http: HttpClient) { }

  public getAllGroupCredentials(adminMode: boolean) : Observable<GroupCredentialsWithRights[]>{
    return this.http.get<GroupCredentialsWithRights[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/getall/' + adminMode);
  }

  public getAllArchivedGroupCredentials() : Observable<GroupCredentialsWithRights[]>{
    return this.http.get<GroupCredentialsWithRights[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/archived');
  }

  public getUsersUsingGroupCredentials(idgrp: number) : Observable<UserForCredentialsWithOrigin[]>{
    return this.http.get<UserForCredentialsWithOrigin[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/getusers/' + idgrp);
  }

  public createGroupCredentials(grpCred: GroupCredentials) : Observable<GroupCredentials>{
    return this.http.post<GroupCredentials>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/create', grpCred);
  }

  public updateGroupCredentials(grpCred: GroupCredentials){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/update', grpCred);
  }

  public archiveGroupCredentials(grpCred: GroupCredentials){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/archive', grpCred);
  }

  public restoreGroupCredentials(grpCred: GroupCredentials){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/restore', grpCred);
  }

  public GetGroupAndUserWithStatus(grpCred: GroupCredentials) : Observable<GroupCredentialsManager[]>{
    return this.http.post<GroupCredentialsManager[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/getgroupanduserwithstatus', grpCred);
  }

  public addManager(listManager: GroupCredentialsManager[]){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/addmanager', listManager);
  }

  public removeManager(listManager: GroupCredentialsManager[]){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/removemanager', listManager);
  }

  public getCredentials(idgroup: number){
    return this.http.get<InfoCredentials>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/getcredentials/' + idgroup);
  }

  public addCredentialsToGroup(grpAndCred: GroupAndCredentials){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/addcredentials', grpAndCred)
  }

  public removeCredentialsFromGroup(grpAndCred: GroupAndCredentials){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/removecredentials', grpAndCred);
  }

  public getManagerRights(manager: GroupCredentialsManager) : Observable<Right[]>{
    return this.http.post<Right[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/getmanagersrights', manager);
  }

   public changeManagerRight(updateRight: ManagerUpdateRightCustomGroup){
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/changeright', updateRight);
  }

  public getUserGroupForCredentialsGroup(idgroup: number) : Observable<CredentialsGroupSharedUserGroupDTO[]>{
    return this.http.get<CredentialsGroupSharedUserGroupDTO[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/getallgroups/' + idgroup);
  }

  public updateUserGroupForCredentialsGroup(listGroups: CredentialsGroupSharedUserGroupDTO[]){
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/updategroups', listGroups);
  }

  public GetColumnVisibilityPreference(): Observable<string[]>
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupcredentials/preference');
  }
}
