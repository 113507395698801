import { Component, OnInit, Input } from '@angular/core';
import { KanbanList } from '../../entities/List';

@Component({
  selector: 'app-edit-list-sort',
  templateUrl: './edit-list-sort.component.html',
  styleUrls: ['./edit-list-sort.component.scss']
})
export class EditListSortComponent implements OnInit {

  constructor() { }

  @Input() list: KanbanList;
  title = true;
  datecreation = false;
  datestart = false;
  datedue = false;
  direction = "asc"
  typesort = "title";

  ngOnInit() {
    if(this.list.autofilterType)
      this.typesort = this.list.autofilterType;
    if(this.list.autofilterDirection)
      this.direction = this.list.autofilterDirection;
  }

}
