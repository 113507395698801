import { Component, OnInit } from '@angular/core';
import { Playlist } from '../../entities/Playlist';
import { SpotifyService } from '../../services/spotify.service';
import { PlaylistViewerParams } from '../../entities/PlaylistVIsualisorParams';
import { TranslateService } from '@ngx-translate/core';
import { CreatePlaylistSpotifyComponent } from '../create-playlist-spotify/create-playlist-spotify.component';
import { PRIMARY_OUTLET } from '@angular/router';
import { SearchResultContainer } from '../../entities/SearchResultContainer';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-spotify-content-interface',
  templateUrl: './spotify-content-interface.component.html',
  styleUrls: ['./spotify-content-interface.component.scss']
})
export class SpotifyContentInterfaceComponent implements OnInit
{

  userPlaylist: Playlist[] = [];
  searchText = "";

  constructor(public spotifyService: SpotifyService, private translateService: TranslateService, private modalService: NzModalService) { }

  setPlaylist(playlist: Playlist)
  {
    this.spotifyService.selectedPlaylist = new PlaylistViewerParams(playlist, true);
    this.spotifyService.selectedPlaylistChange.next(this.spotifyService.selectedPlaylist.playlist);
    this.spotifyService.selectedCategory = null;
    this.spotifyService.selectedMode = 'playlist';
    this.spotifyService.searchResult = null;
    this.updateFollowState();
  }

  search()
  {
    if (this.searchText)
      this.spotifyService.searchTracksAndPlaylist(this.searchText).subscribe(x => this.spotifyService.searchResult = x);
  }
  updateFollowState()
  {
    if (this.spotifyService.selectedPlaylist.playlist.owner.id == this.spotifyService.user.id)
    {
      this.spotifyService.selectedPlaylist.playlist.followedState = "owned";
    }
    else
    {
      this.spotifyService.checkIfPlaylistFollowed(this.spotifyService.selectedPlaylist.playlist.id).subscribe(x =>
      {
        this.spotifyService.selectedPlaylist.playlist.followedState = x[0] ? "follow" : "not-follow";
      });
    }
  }

  setBrowse()
  {
    this.spotifyService.selectedCategory = null;
    this.spotifyService.selectedPlaylist = null;
    this.spotifyService.selectedMode = 'browse';
    this.spotifyService.searchResult = null;
  }

  getPlaylistImage(playlist: Playlist)
  {
    if (playlist.images.length > 0)
      return playlist.images[0].url;
    else return "";
  }

  createSpotifyPlaylist()
  {
    let title = "";
    this.translateService.get("SPOTIFY.CREATE-PLAYLIST").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: CreatePlaylistSpotifyComponent,
      nzWidth: '600px',
      nzFooter: [
        {
          label: cancel,
          onClick: () => { modal.close(); }
        },
        {
          label: confirm,
          type: 'primary',
          onClick: (result) =>
          {
            this.spotifyService.createUserPlaylist(result.playlistName, result.playlistDescription).subscribe(x =>
            {
              this.userPlaylist.push(x);
              this.spotifyService.userPlaylist.unshift(x);
              this.userPlaylist = this.userPlaylist.slice();
              modal.close();
            });
          }
        },
      ]
    });
  }

  ngOnInit()
  {
    if (!this.spotifyService.Token)
      return;
    if (this.spotifyService.user)
    {
      this.spotifyService.getUserPlaylists().subscribe(x =>
      {
        this.spotifyService.userPlaylist = x.items;
        this.userPlaylist = [...this.spotifyService.userPlaylist].sort((a, b) => (b.name < a.name ? 1 : -1));
      });
    }
    else
    {

      this.spotifyService.getUserInfos().subscribe(x =>
      {
        this.spotifyService.user = x;
        this.spotifyService.getUserPlaylists().subscribe(x =>
        {
          this.spotifyService.userPlaylist = x.items;
          this.userPlaylist = [...this.spotifyService.userPlaylist].sort((a, b) => (b.name < a.name ? 1 : -1));
        });
      });
    }
  }

}
