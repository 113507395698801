import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardShowEndDateBadPipe } from './kanban-card-show-end-date-bad.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KanbanCardShowEndDateBadPipe],
  exports: [KanbanCardShowEndDateBadPipe]
})
export class KanbanCardShowEndDateBadPipeModule { }
