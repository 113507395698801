import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNode } from 'ng-zorro-antd/tree';
import { TextCompareService } from '../../../../../src/app/services/text-compare.service';
import { SettingsService } from '../../../../../src/app/settings/services/settings.service';
import { KanbanBoard, KanbanBoardType } from '../../entities/Board';
import { KanbanCard } from '../../entities/Card';
import { KanbanTreeSelectedInfos } from '../../entities/kanban-tree-selected-infos';
import { RelationForListDTO } from '../../entities/list-relation';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-board-tree',
  templateUrl: './kanban-board-tree.component.html',
  styleUrls: ['./kanban-board-tree.component.scss']
})
export class KanbanBoardTreeComponent implements OnInit
{

  constructor(private kbs: KanbanService,
    public tcs: TextCompareService, public translateService: TranslateService, private settingsService: SettingsService) { }

  moveSearchValue = "";
  boardsForMove: any[] = [];
  todoListForMove: any[] = [];
  filterTextForTree = "";
  selectedForMove = "";
  selectedType = "";
  selectedListForMove = "";
  selectedBoardForMove = "";
  boardForMove: KanbanBoard[] = [];
  timeoutfilter;
  allchecked = false;
  idBoardAlreadyAdded: number[] = [];
  theSelection: KanbanTreeSelectedInfos = null;

  @Input() boards: KanbanBoard[] = [];
  @Input() freeCards: KanbanCard[] = [];
  @Input() idcardSelected: number;
  @Input() idlistselected: number;
  @Input() initialbypass = false;
  @Input() allExpanded = false;
  @Input() cardcheckable = false;
  @Input() listcheckable = false;
  @Input() boardcheckable = false;
  @Input() loadcardonselect = true;
  @Input() showListThatCannotCreateCard = true;
  @Input() checkedlist: number[] = [];
  @Input() openedBoard = 0;

  useCategory = true;
  @Input() allowedListForMove: RelationForListDTO[] = [];
  @Output() onSelection = new EventEmitter<KanbanTreeSelectedInfos>();
  @Output() onCheck = new EventEmitter<number[]>();
  @Output() onListCheck = new EventEmitter<number[]>();
  bypass = false;
  @ViewChild('boardTree', { static: false }) nzTreeComponent!: NzTreeComponent;

  ngOnInit()
  {
    this.settingsService.getSettingsByName("show_categories_board_tree").subscribe(pref =>
    {
      this.useCategory = pref != "false";
      if (this.kbs.allCategoryList.length == 0)
      {
        this.kbs.getAllCategories().subscribe(x =>
        {
          this.kbs.allCategoryList = x;
          this.changeBypassActivation(false);
        })
      }
      else this.changeBypassActivation(false);
    })
  }

  filterLisForMove(filter: string)
  {
    if (this.timeoutfilter)
      clearTimeout(this.timeoutfilter);
    this.timeoutfilter = setTimeout(() =>
    {
      this.initBoardMove();
    }, 500);

    //this.filterTextForTree = filter;
  }

  checkChildren(check: boolean, node: NzTreeNode)
  {
    if (node.children.length > 0)
    {
      node.children.forEach(child =>
      {
        this.checkChildren(check, child);
      })
    }
    else if (node.key.startsWith("card") || node.key.startsWith("freecard"))
      node.isChecked = check;
  }
  checkAllCard(check: boolean)
  {
    let categorynodes = this.nzTreeComponent.getTreeNodes();
    categorynodes.forEach(cat =>
    {
      this.checkChildren(check, cat);
    })
    this.onCheckModified(null);
  }

  changeDisplayPreference()
  {
    this.settingsService.setSettingsByName("show_categories_board_tree", this.useCategory ? "true" : "false");
    this.initBoardMove();
  }

  initBoardMove()
  {
    let filterValueUpper = this.moveSearchValue.toUpperCase();
    this.boardsForMove = [];
    this.todoListForMove = [];
    this.idBoardAlreadyAdded = [];

    if (this.useCategory)
    {
      let starredBoardList = this.boardForMove.filter(x => x.starred);
      let starredTitle = "";
      this.translateService.get('KANBAN.STARRED-BOARD').subscribe(w => starredTitle = w);
      if (starredBoardList.length > 0)
      {
        let starredNode = {
          title: starredTitle,
          key: "starred_",
          expanded: true,
          disableCheckbox: true,
          children: []
        }
        starredBoardList.forEach(board =>
        {
          this.addBoardNode(starredNode, board, filterValueUpper, false);
        })
        this.boardsForMove.push(starredNode);
      }

      this.kbs.allUnarchivedCategories.forEach(category =>
      {
        let categoryNode = {
          title: category.title,
          key: "category_" + category.id,
          disableCheckbox: true,
          expanded: this.allExpanded || !category.collapsed || filterValueUpper,
          children: []
        };

        category.boardids.forEach(boardid =>
        {
          let board = this.boardForMove.find(x => x.id == boardid);
          let boardNode = this.addBoardNode(categoryNode, board, filterValueUpper, false);
          if (boardNode && boardNode.expanded)
            categoryNode.expanded = true;
        });
        if (categoryNode.children.length > 0)
          this.boardsForMove.push(categoryNode);
      });
    }


    let allboardTitle = "";
    this.translateService.get('KANBAN.ALL-BOARDS').subscribe(x => allboardTitle = x);
    let allBoardNode = {
      title: allboardTitle,
      key: "allboard_",
      disableCheckbox: true,
      expanded: this.allExpanded || this.boardsForMove.length == 0 || filterValueUpper,
      children: []
    }
    this.boardForMove.forEach(board =>
    {
      let boardNode = this.addBoardNode(allBoardNode, board, filterValueUpper, true);
      if (boardNode && boardNode.expanded)
        allBoardNode.expanded = true;
    });

    this.freeCards.forEach(card =>
    {
      let addToList = this.tcs.contains(card.title, filterValueUpper)
      let freeCardNode = {
        title: card.title,
        key: "freecard_" + card.id,
        selected: this.idcardSelected == card.id,
        disableCheckbox: !this.cardcheckable,
        isLeaf: true
      };
      if (addToList)
        this.boardsForMove.push(freeCardNode);

    });

    if (this.useCategory)
      this.boardsForMove.push(allBoardNode);
    this.boardsForMove = [...this.boardsForMove];
    this.todoListForMove = [...this.todoListForMove];
  }

  addBoardNode(categoryNode: any, board: KanbanBoard, filterValueUpper: string, filteradd: boolean)
  {
    if (!board)
      return;
    if ((filteradd || filterValueUpper) && this.idBoardAlreadyAdded.findIndex(x => x == board.id) >= 0)
      return;
    let boardNode = {
      title: board.title,
      key: "board_" + board.id,
      bgcolor: board.bgColor,
      checked: false,
      expanded: this.allExpanded || filterValueUpper.length > 0 || this.openedBoard == board.id,
      disableCheckbox: !this.boardcheckable,
      selectable: false,
      children: []
    };

    let allchecked = true;
    board.lists.forEach(list =>
    {
      if (!this.showListThatCannotCreateCard && !list.canCreateCard)
        return;
      let templist = board.lists.find(x => x.id == list.id);
      list.title = templist.title;
      let listchecked = this.checkedlist.findIndex(x => x == list.id) >= 0;
      if (listchecked)
        boardNode.expanded = true;
      let listNode = {
        title: list.title,
        key: "list_" + list.id,
        bgcolor: list.color,
        expanded: this.allExpanded || filterValueUpper.length > 0,
        selected: this.idlistselected == list.id,
        checked: listchecked,
        disableCheckbox: !this.listcheckable,
        isLeaf: !this.loadcardonselect,
        children: []
      };
      if (this.idlistselected == list.id)
        listNode.expanded = true;
      if (!listNode.checked)
        allchecked = false;
      if (this.loadcardonselect)
      {
        list.cards.forEach(card =>
        {
          let addCardToList = this.tcs.contains(card.title, filterValueUpper)
          let cardNode =
          {
            title: card.title,
            key: "card_" + card.id,
            selected: this.idcardSelected == card.id,
            disableCheckbox: !this.cardcheckable,
            isLeaf: true
          };
          if (this.idcardSelected == card.id)
          {
            listNode.expanded = true;
            boardNode.expanded = true;
          }
          if (addCardToList)
          {
            listNode.children.push(cardNode);
          }

        });
        if (listNode.children.length > 0)
        {
          if (listNode.children.length > 0 && filterValueUpper)
            listNode.expanded = true;
        }
      }
      if (this.boardcheckable && allchecked)
        boardNode.checked = true;

      if ((filterValueUpper && listNode.children.length == 0 && this.loadcardonselect) || (filterValueUpper && !this.tcs.contains(list.title, filterValueUpper) && !this.loadcardonselect)) { }
      else boardNode.children.push(listNode);
    })
    if (boardNode.children.length > 0)
    {
      if (boardNode.children.length > 0 && filterValueUpper)
        boardNode.expanded = true;
      if (this.useCategory)
        categoryNode.children.push(boardNode);
      else this.boardsForMove.push(boardNode);
      this.idBoardAlreadyAdded.push(board.id);
    }

    return boardNode;

  }

  updateCategoryNodeCollapse(keynode: any)
  {
    if (keynode.key.startsWith("allboard"))
      return;
    setTimeout(() =>
    {
      let idcategory = keynode.key ? parseInt(keynode.key.toString().slice(9)) : 0;
      if (keynode.isExpanded)
      {
        this.kbs.openCategory(idcategory).subscribe(() =>
        {
          let category = this.kbs.allCategoryList.find(x => x.id == idcategory);
          if (category)
            category.collapsed = false;
        })
      }
      else this.kbs.collapseCategory(idcategory).subscribe(() =>
      {
        let category = this.kbs.allCategoryList.find(x => x.id == idcategory);
        if (category)
          category.collapsed = true;
      })
    }, 100);

  }

  selectListToMove(event: any)
  {
    if (!event.node.isLeaf)
      event.node.isExpanded = !event.node.isExpanded
    if (event.node.key.startsWith("allboard"))
    {
      this.selectedForMove = "";
    }
    else if (event.node.key.startsWith("category"))
    {
      this.selectedForMove = "";
      this.updateCategoryNodeCollapse(event.node);
    }
    else if (event.node.key.startsWith("board"))
    {
      this.selectedForMove = "";
      this.selectedBoardForMove = event.node.key;
      let idboard = this.selectedBoardForMove ? parseInt(this.selectedBoardForMove.toString().slice(6)) : 0;
      let board = this.boards.find(board => board.id == idboard);
      this.onSelectionMethod({ board: board, list: null, card: null, selectedType: "" });
    }
    else if (event.node.key.startsWith("freecard"))
    {
      let idcard = parseInt(event.node.key.split('_')[1]);
      let card = this.freeCards.find(c => c.id == idcard);
      this.onSelectionMethod({ board: null, list: null, card: card, selectedType: this.selectedType });
      event.node.isSelected = true;
    }
    else
    {
      this.selectedForMove = event.node.key;
      this.selectedListForMove = event.node.key;
      let theevent = event.node;
      while (theevent.parentNode)
      {
        theevent = theevent.parentNode;
        if (theevent.key.startsWith("list"))
          this.selectedListForMove = theevent.key;
        else if (theevent.key.startsWith("board"))
          this.selectedBoardForMove = theevent.key;
      }
      if (this.selectedType == "todo")
        this.selectedListForMove = "list_" + this.todoListForMove.find(x => x.key == this.selectedBoardForMove).idlist;

      let idboard = this.selectedBoardForMove ? parseInt(this.selectedBoardForMove.toString().slice(6)) : 0;
      let idlist = parseInt(this.selectedListForMove.split('_')[1]);
      let idcard = this.selectedForMove == this.selectedListForMove ? 0 : parseInt(this.selectedForMove.split('_')[1]);

      let board = this.boards.find(board => board.id == idboard);
      let list = board ? board.lists.find(x => x.id == idlist) : null;
      let card = list ? list.cards.find(c => c.id == idcard) : null;
      this.onSelectionMethod({ board: board, list: list, card: card, selectedType: this.selectedType });
      event.node.isSelected = true;
    }
  }

  onSelectionMethod(selectionInfo: KanbanTreeSelectedInfos)
  {
    this.theSelection = selectionInfo;
    if (selectionInfo.board)
    {
      this.kbs.getLabelList(selectionInfo.board.id).subscribe(x =>
      {
        this.onSelection.emit(selectionInfo);
      });
    }
    else this.onSelection.emit(selectionInfo);
  }

  onCheckModified(event: NzFormatEmitEvent)
  {
    let idCardList: number[] = [];
    let idListList: number[] = [];
    let checkedNode = this.nzTreeComponent.getTreeNodes();
    let currentNode = event.node;
    let typeNode = "list";
    if (currentNode.key.startsWith("board"))
      typeNode = "board";
    else if (currentNode.key.startsWith("card"))
      typeNode = "card";
    let typeAction = "uncheck";
    if (currentNode.isChecked)
      typeAction = "check";

    checkedNode.forEach(node =>
    {

      let s_id = node.key.split("_")[1];
      let id = parseInt(s_id);
      if (node.key.startsWith("card") && node.isChecked)
        idCardList.push(id);
      else if (node.key.startsWith("freecard") && node.isChecked)
        idCardList.push(id);
      else if (node.key.startsWith("list") && node.isChecked && idListList.findIndex(x => x == id) < 0)
        idListList.push(id);
      else if (node.key.startsWith("board"))
      {
        this.manageBoardNodeCheck(node, currentNode, typeNode, typeAction, idListList);
      }
      else if (node.key.startsWith("category") || node.key.startsWith("allboard") || node.key.startsWith("starred"))
      {
        node.children.forEach(boardNode => 
        {
          this.manageBoardNodeCheck(boardNode, currentNode, typeNode, typeAction, idListList);
        });
      }
    });
    this.nzTreeComponent.renderTree();
    this.onCheck.emit(idCardList);
    this.onListCheck.emit(idListList);
  }

  manageBoardNodeCheck(boardNode: NzTreeNode, currentNode: NzTreeNode, typeNode: string, typeAction: string, idListList: number[])
  {
    if (boardNode.key == currentNode.key && typeNode == "board")
    {
      boardNode.isChecked = typeAction == "check";
      boardNode.children.forEach(listnode =>
      {
        listnode.isChecked = boardNode.isChecked;
      })
    }


    boardNode.children.forEach(listnode =>
    {
      if (listnode.key.startsWith("list"))
      {
        if (listnode.key == currentNode.key && typeNode == "list")
          listnode.isChecked = typeAction == "check";

        if (listnode.isChecked || boardNode.isChecked)
        {
          let s_id = listnode.key.split("_")[1];
          let listid = parseInt(s_id);
          if (idListList.findIndex(x => x == listid) < 0)
            idListList.push(listid);
        }
      }
    })
  }

  getListFromId(id: number)
  {
    let t_board = this.boards.find(board => board.lists.findIndex(list => list.id == id) >= 0);
    let t_list = t_board.lists.find(list => list.id == id);
    return t_list;
  }

  getBoardColor(boardkey: string)
  {
    let idboard = parseInt(boardkey.slice(6));
    let board = this.boards.find(x => x.id == idboard)
    if (board)
      return board.bgColor;
  }

  changeBypassActivation(newbypass: boolean)
  {
    if (newbypass || this.initialbypass)
      this.boardForMove = [...this.boards];
    else 
    {
      this.boardForMove = [];
      for (let board of this.boards)
      {
        let allowedList = board.lists.filter(list => list.id == this.idlistselected || this.allowedListForMove.findIndex(rel => rel.targetListId == list.id) >= 0);
        if (allowedList.length == 0)
          continue;
        let f_board = new KanbanBoard();
        f_board.id = board.id;
        f_board.title = board.title;
        f_board.bgColor = board.bgColor;
        f_board.lists = allowedList;
        f_board.starred = board.starred;
        this.boardForMove.push(f_board);
      }
    }
    this.initBoardMove();
  }

  doNothing(event: any)
  {

  }

}
