import { Component, Input, OnInit } from '@angular/core';
import * as Quill from 'quill';
@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit
{

  constructor() { }

  @Input() editContent = "";
  @Input() editTitle = "";

  ideditor = "editortext";
  title = "";
  placeholdereditor = "pogoU";
  quill;
  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],                     //code-block => class style
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],         //class style
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link', 'image', 'video']
  ];

  ngOnInit()
  {
    //this.ideditor += Math.random() + "";
    this.title = this.editTitle;
    setTimeout(() =>
    {
      this.initEditor();
    }, 100);

  }

  getEditorContent()
  {
    return this.quill.root.innerHTML;
  }

  initEditor()
  {
    this.quill = new Quill('#' + this.ideditor, {
      modules: {
        toolbar: this.toolbarOptions
      },
      placeholder: this.placeholdereditor,
      theme: 'snow',
    });
    if (this.editContent)
      this.quill.clipboard.dangerouslyPasteHTML(0, this.editContent);
  }

}
