import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../url-api.service';
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NotificationContainer } from '../../../../src/app/entities/notification-container';
import { ChatNotificationContainer } from '../../../../src/app/entities/chat-notification-container';
import { HubConnection } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { PlanningService } from '../../planning/planning.service';
import { OtherPlanningRights, PlanningRights } from '../../planning/entities/other-planning-rights';

@Injectable({
  providedIn: 'root'
})
export class GlobalNotificationService
{

  constructor(private http: HttpClient, private translateService: TranslateService, private planningService: PlanningService) { }

  userNotifications: NotificationContainer = new NotificationContainer();
  chatNotifications: ChatNotificationContainer[] = [];
  progress = 0;
  textProgress = "";

  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('NotificationProgress', (progress: number, codeText: string) =>
    {
      this.progress = progress;
      this.translateService.get(codeText).subscribe(x => this.textProgress = x);
    });

    _hubConnection.on('NotifyCalendarUpdate', (iduser: number) =>
    {
      this.planningService.refreshCalendars.next(iduser);
    });

    _hubConnection.on('NotifyCalendarRightsUpdate', (iduser: number, right: PlanningRights) =>
    {
      this.planningService.refreshRights.next({ id: iduser, r: right });
    });

  }

  getAllChatNotifications()
  {
    return this.http.get<ChatNotificationContainer[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/chat/message/unread`).pipe(tap(x =>
    {
      this.chatNotifications = x
    }));
  }

  getAllUserNotifications()
  {
    return this.http.get<NotificationContainer>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/notifications').pipe(tap(x => 
    {
      this.userNotifications = x;
    }));
  }

  setNotificationToSeen(id: number)
  {
    return this.http.put<any>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/notifications/' + id + '/seen', null);
  }

  setNotificationToNotSeen(id: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/user/notifications/' + id + '/notseen', null);
  }

  setAllNotificationToSeen()
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/user/notifications/seen', null);
  }

}
