import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GroupUser } from '../../../group-user/entities/group-user';
import { NameUserPipe } from '../../../pipes/name-user-pipe/name-user.pipe';
import { CacheDataService } from '../../../services/cache-data.service';
import { TextCompareService } from '../../../services/text-compare.service';
import { UrlApiService } from '../../../services/url-api.service';
import { User } from '../../../user/model/user';
import { KanbanCard } from '../../entities/Card';
import { KanbanChecklistTemplateParams } from '../../entities/kanban-checklist-template-params';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-checklist-template-manager',
  templateUrl: './checklist-template-manager.component.html',
  styleUrls: ['./checklist-template-manager.component.scss']
})
export class ChecklistTemplateManagerComponent implements OnInit
{

  constructor(public cds: CacheDataService,
    private http: HttpClient,
    public tcs: TextCompareService,
    public kbs: KanbanService,
    public nameuserpipe: NameUserPipe) { }

  userList: number[] = [];
  teamList: number[] = [];
  dummycard = new KanbanCard();
  selectedTemplate: KanbanChecklistTemplateParams;
  createdTemplate: KanbanChecklistTemplateParams;
  showEditTemplate = false;
  createTemplateTitle = "";
  checklistTitle = "";
  templateList: KanbanChecklistTemplateParams[] = [];

  ngOnInit()
  {
    this.searchUsers("");
    this.searchTeams("");
    this.kbs.getChecklistTemplates().subscribe(templates =>
    {
      this.templateList = templates;
    })
  }

  createOrSaveTemplateChecklist()
  {
    if (!this.createdTemplate)
    {
      let template = new KanbanChecklistTemplateParams();
      template.checklist.title = this.createTemplateTitle;
      template.userIds = this.userList;
      template.teamIds = this.teamList;
      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/checklist-template`, template).subscribe(id =>
      {
        template.checklist.id = id;
        this.templateList.push(template);
        this.closeEditTemplate();
        this.initEditTemplate(template);
      })
    }
    else
    {
      this.createdTemplate.userIds = [...this.userList];
      this.createdTemplate.teamIds = [...this.teamList];
      this.createdTemplate.checklist.title = this.createTemplateTitle;
      this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/checklist-template`, this.createdTemplate).subscribe(id =>
      {
        this.closeEditTemplate();
      })
    }

  }

  deleteChecklist(idchecklist)
  {
    this.kbs.removeChecklistFromCard(this.dummycard.id, idchecklist).subscribe(x =>
    {
      this.dummycard.checklists.splice(this.dummycard.checklists.findIndex(x => x.id == idchecklist), 1);
      this.dummycard.checklists = [...this.dummycard.checklists];

      this.templateList.splice(this.templateList.findIndex(x => x.checklist.id == idchecklist), 1);
      this.templateList = [...this.templateList];
    });
  }

  initEditTemplate(template: KanbanChecklistTemplateParams)
  {
    this.createdTemplate = template;
    if (template)
    {
      this.createTemplateTitle = template.checklist.title;
      this.userList = [...template.userIds];
      this.teamList = [...template.teamIds];
    }

    this.showEditTemplate = true;
  }

  closeEditTemplate()
  {
    this.createdTemplate = null;
    this.showEditTemplate = false;

  }

  selectTemplate(template: KanbanChecklistTemplateParams)
  {
    if (!this.selectedTemplate || this.selectedTemplate.checklist.id != template.checklist.id)
      this.selectedTemplate = template;
    else this.selectedTemplate = null;
  }

  usersForTemplateCreation: User[] = [];
  teamsForTemplateCreation: GroupUser[] = [];

  searchUsers(search: string)
  {
    this.usersForTemplateCreation = this.cds.unarchivedUsers.filter(x => this.tcs.contains(this.nameuserpipe.transform(x.id), search));
  }

  searchTeams(search: string)
  {
    this.teamsForTemplateCreation = this.cds.unarchivedTeams.filter(x => this.tcs.contains(x.name, search));
  }

}
