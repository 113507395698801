import { Component, OnInit } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';

@Component({
  selector: 'app-header-playlist-spotify',
  templateUrl: './header-playlist-spotify.component.html',
  styleUrls: ['./header-playlist-spotify.component.scss']
})
export class HeaderPlaylistSpotifyComponent implements OnInit
{

  constructor(public spotifyService: SpotifyService) { }
  ngOnInit()
  {
  }

  getImage()
  {
    if(this.spotifyService.selectedPlaylist.playlist.images.length > 0)
      return this.spotifyService.selectedPlaylist.playlist.images[0].url
      else return "";
  }

  startPlaylist()
  {
    this.spotifyService.playPlaylist(this.spotifyService.selectedPlaylist.playlist).subscribe();
    /*
    this.spotifyService.getPlaylistTracks(this.spotifyService.selectedPlaylist.playlist).subscribe(x =>
    {
      if (x.items.length > 0)
      {
        let index = x.items.findIndex(x => x.track.id == this.spotifyService.runningTrack.id);
        if(index == x.items.length-1)
        index = 0;
        else ++index;
        this.spotifyService.playSong(x.items[index].track);
      }
    });
*/
  }

  changeFollowState(state: boolean)
  {
    if(state)
    {
      this.spotifyService.followPlaylist(this.spotifyService.selectedPlaylist.playlist.id).subscribe(x => {
        this.spotifyService.selectedPlaylist.playlist.followedState = "follow";
        this.spotifyService.userPlaylist.unshift(this.spotifyService.selectedPlaylist.playlist);
      });
    }
    else 
    {
      
      this.spotifyService.unfollowPlaylist(this.spotifyService.selectedPlaylist.playlist.id).subscribe(x => {
        this.spotifyService.userPlaylist = [...this.spotifyService.userPlaylist.filter(x => x.id != this.spotifyService.selectedPlaylist.playlist.id)];
        if(this.spotifyService.selectedPlaylist.playlist.owner.id == this.spotifyService.user.id)
        {
          if(this.spotifyService.userPlaylist.length > 0)
            this.spotifyService.selectedPlaylist.playlist = this.spotifyService.userPlaylist[0];
            else
            {
              this.spotifyService.getFeaturedPlaylist().subscribe(x => {
                this.spotifyService.selectedPlaylist.playlist = x[0];
              })
            }
        }
        else
        {
          this.spotifyService.selectedPlaylist.playlist.followedState = "not-follow";
        }
      });
    }
  }

}
