// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-user-icon {
  border: none;
  background: none;
  cursor: pointer;
}

.thread-container {
  cursor: pointer;
  margin-bottom: 5px;
}

.thread-container:hover {
  background-color: lightgray;
}

.header-view-mail {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.header-container {
  width: 49%;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
}

.confirm-temporary-event {
  border-radius: 5px;
  border: 1px solid black;
  color: white;
  background-color: black;
  opacity: 0.2;
  margin-top: 5px;
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/messagerie/messagerie-mail-viewer/messagerie-mail-viewer.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,uBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AACF","sourcesContent":[".active-user-icon {\r\n  border: none;\r\n  background: none;\r\n  cursor: pointer;\r\n}\r\n\r\n.thread-container {\r\n  cursor: pointer;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.thread-container:hover {\r\n  background-color: lightgray;\r\n}\r\n\r\n.header-view-mail {\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n  white-space: pre;\r\n}\r\n\r\n.header-container {\r\n  width: 49%;\r\n  padding: 10px;\r\n  display: inline-block;\r\n  vertical-align: top;\r\n}\r\n\r\n.confirm-temporary-event {\r\n  border-radius: 5px;\r\n  border: 1px solid black;\r\n  color: white;\r\n  background-color: black;\r\n  opacity: 0.2;\r\n  margin-top: 5px;\r\n  padding: 3px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
