import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { ThirdPartyActivity } from 'src/app/third-party/entities/third-party-activity';
import { MoralPersonService } from 'src/app/third-party/moral-person/services/moral-person.service';
import { PhysicalPersonService } from 'src/app/third-party/physical-person/services/physical-person.service';
import { CacheDataService } from '../cache-data.service';
import { ThirdPartySheetUpdateInfos } from 'src/app/call-interface/entities/update-third-party-sheet-infos';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyNotificationService
{

  constructor(private mps: MoralPersonService, private pps: PhysicalPersonService, private cds: CacheDataService) { }

  initRoutes(_hubConnection: HubConnection)
  {

    _hubConnection.on('AddThirdPartyActivity', (activity: ThirdPartyActivity) =>
    {
      let pp = this.cds.physicalPersonCollection.find(x => x.id == activity.thirdPartyId);
      if (pp)
      {
        pp.activities.splice(0, 0, activity);
        this.pps.physicalPersonListChange.next(null);
      }
      else
      {
        let mp = this.cds.moralPersonCollection.find(x => x.id == activity.thirdPartyId);
        if (mp)
        {
          mp.activities.splice(0, 0, activity);
          this.mps.moralPersonListChange.next(null);
        }
      }
    });

    _hubConnection.on('UpdateThirdPartySheetInfos', (updateinfos: ThirdPartySheetUpdateInfos) =>
    {

      let pp = this.cds.physicalPersonCollection.find(x => x.id == updateinfos.idtp);
      if (pp)
      {
        pp.firstName = updateinfos.firstName;
        pp.lastName = updateinfos.lastName;
        pp.phoneNumbers = updateinfos.phoneNumbers;
        pp.brands = updateinfos.brands;
        pp.mails = updateinfos.mails;
        pp.customFields = updateinfos.customFields;
        pp.websites = updateinfos.websites;
        pp.secondaryNames = updateinfos.secondaryNames;
        pp.spokenLanguages = updateinfos.spokenLanguages;
        pp.anniversaryDate = updateinfos.anniversaryDate;
        pp.comment = updateinfos.comment;
        pp.nationality = updateinfos.nationality;
        pp.labels = updateinfos.labels;
        this.pps.physicalPersonListChange.next(null);
      }
      else
      {
        let mp = this.cds.moralPersonCollection.find(x => x.id == updateinfos.idtp);
        if (mp)
        {
          mp.name = updateinfos.name;
          mp.phoneNumbers = updateinfos.phoneNumbers;
          mp.brands = updateinfos.brands;
          mp.mails = updateinfos.mails;
          mp.customFields = updateinfos.customFields;
          mp.websites = updateinfos.websites;
          mp.secondaryNames = updateinfos.secondaryNames;
          mp.anniversaryDate = updateinfos.anniversaryDate;
          mp.comment = updateinfos.comment;
          mp.nationality = updateinfos.nationality;
          mp.labels = updateinfos.labels;
          this.mps.moralPersonListChange.next(null);
        }
        else
        {
          for (let cds_mp of this.cds.moralPersonCollection)
          {
            mp = cds_mp.secondarySites.find(x => x.id == updateinfos.idtp)
            if (mp)
            {
              mp.name = updateinfos.name;
              mp.phoneNumbers = updateinfos.phoneNumbers;
              mp.brands = updateinfos.brands;
              mp.mails = updateinfos.mails;
              mp.customFields = updateinfos.customFields;
              mp.websites = updateinfos.websites;
              mp.secondaryNames = updateinfos.secondaryNames;
              mp.anniversaryDate = updateinfos.anniversaryDate;
              mp.comment = updateinfos.comment;
              mp.nationality = updateinfos.nationality;
              mp.labels = updateinfos.labels;
              this.mps.moralPersonListChange.next(null);
            }
          }
        }
      }
    });
  }

}
