import { Injectable } from '@angular/core';
import { GroupCredentialsSourceService } from '../source/group-credentials-source.service';
import { tap, catchError } from 'rxjs/operators';
import { GroupCredentialsWithRights } from '../../entities/group-credentials-with-rights';
import { GroupCredentials } from '../../entities/group-credentials';
import { GroupCredentialsManager } from '../../entities/group-credentials-manager';
import { InfoCredentials } from '../../entities/info-credentials';
import { GroupAndCredentials } from '../../entities/group-and-credentials';
import { Right } from '../../../entities/right';
import { ManagerUpdateRightCustomGroup } from '../../entities/manager-update-right-custom-group';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { CredentialsGroupSharedUserGroupDTO } from '../../entities/credentials-group-shared-user-group';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupCredentialsDataService {

  listGroupCredentialsWithRights: GroupCredentialsWithRights[] = [];
  listGroupCredentialsWithRightsChange: Subject<GroupCredentialsWithRights[]> = new  Subject<GroupCredentialsWithRights[]>();
  listManagers: GroupCredentialsManager[];
  infoCredentials: InfoCredentials;
  managerRights: Right[];
  
  constructor(private groupCredentialsService: GroupCredentialsSourceService, private errorHandler: ErrorHandlerService) { }

  public getAllGroupCredentials(adminMode: boolean) {
    return this.groupCredentialsService.getAllGroupCredentials(adminMode).pipe(tap(c => { this.listGroupCredentialsWithRights = c; }));
  }

  public getAllArchivedGroupCredentials() {
    return this.groupCredentialsService.getAllArchivedGroupCredentials().pipe(tap(c => { this.listGroupCredentialsWithRights = c; }));
  }

  public getUsersUsingGroupCredentials(idgrp: number){
    return this.getUsersUsingGroupCredentials(idgrp);
  }

  public createGroupCredentials(grpCred: GroupCredentials) {
    return this.groupCredentialsService.createGroupCredentials(grpCred);
  }

  public updateGroupCredentials(grpCred: GroupCredentials) {
    return this.groupCredentialsService.updateGroupCredentials(grpCred);
  }

  public archiveGroupCredentials(grpCred: GroupCredentials) {
    return this.groupCredentialsService.archiveGroupCredentials(grpCred);
  }

  public restoreGroupCredentials(grpCred: GroupCredentials) {
    return this.groupCredentialsService.restoreGroupCredentials(grpCred);
  }

  public GetGroupAndUserWithStatus(grpCred: GroupCredentials) {
    return this.groupCredentialsService.GetGroupAndUserWithStatus(grpCred).pipe(tap(c => { this.listManagers = c; }));
  }

  public addManager(listManager: GroupCredentialsManager[]) {
    return this.groupCredentialsService.addManager(listManager);
  }

  public removeManager(listManager: GroupCredentialsManager[]) {
    return this.groupCredentialsService.removeManager(listManager);
  }

  public getCredentials(idgroup: number) {
    return this.groupCredentialsService.getCredentials(idgroup).pipe(tap(c => this.infoCredentials = c));
  }

  public addCredentialsToGroup(grpAndCred: GroupAndCredentials) {
    return this.groupCredentialsService.addCredentialsToGroup(grpAndCred);
  }

  public removeCredentialsFromGroup(grpAndCred: GroupAndCredentials) {
    return this.groupCredentialsService.removeCredentialsFromGroup(grpAndCred);
  }

  public getManagerRights(manager: GroupCredentialsManager) {
    return this.groupCredentialsService.getManagerRights(manager).pipe(tap(c => this.managerRights = c));
  }

  public changeManagerRight(updateRight: ManagerUpdateRightCustomGroup) {
    return this.groupCredentialsService.changeManagerRight(updateRight).subscribe();
  }
  public updateUserGroupForCredentialsGroup(listGroups: CredentialsGroupSharedUserGroupDTO[]){
    return this.groupCredentialsService.updateUserGroupForCredentialsGroup(listGroups);
  }

  public GetColumnVisibilityPreference()
  {
    return this.groupCredentialsService.GetColumnVisibilityPreference();
  }
}
