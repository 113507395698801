import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlApiService } from '../services/url-api.service';
import { MessagerieAccountSendGroup } from './entities/messagerie-send-group';

@Injectable({
    providedIn: 'root'
})
export class MessagerieSendgroupService
{

    mailingList: MessagerieAccountSendGroup[] = [];
    constructor(private http: HttpClient) { }

    createSendGroup(sendGroup: MessagerieAccountSendGroup)
    {
        return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/sendgroup`, sendGroup)
    }

    updateSendGroup(sendGroup: MessagerieAccountSendGroup)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/sendgroup`, sendGroup)
    }

    deleteSendGroup(idgroup: number)
    {
        return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/sendgroup/${idgroup}`)
    }

    getSendGroup(accountidlist: number[])
    {
        return this.http.post<MessagerieAccountSendGroup[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/multiple/sendgroup`, accountidlist)
    }

}
