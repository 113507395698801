import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEventComponent } from './edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '../form/date-picker/date-picker.module';
import { SelectModule } from '../form/select/select.module';
import { TooglerModule } from '../form/toogler/toogler.module';
import { FormsModule } from '@angular/forms';
import { TextInputModule } from '../form/text-input/text-input.module';
import { KanbanBoardTreeModule } from '../../../kanban/components/kanban-board-tree/kanban-board-tree.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { EditReccurenceModule } from '../edit-reccurence/edit-reccurence.module';
import { PipesModule } from '../../../pipes/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        NzDividerModule,
        TranslateModule,
        NzGridModule,
        DatePickerModule,
        SelectModule,
        TooglerModule,
        NzCheckboxModule,
        NzInputNumberModule,
        FormsModule,
        NzSelectModule,
        NzAlertModule,
        TextInputModule,
        KanbanBoardTreeModule,
        EditReccurenceModule,
        NzButtonModule,
        PipesModule,
        NzFormModule,
        NzToolTipModule,
        NzIconModule
    ],
    declarations: [EditEventComponent],
    exports: [EditEventComponent]
})
export class EditModule { }
