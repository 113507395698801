import { Component, OnInit } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';

@Component({
  selector: 'app-player-sound-ajuster',
  templateUrl: './player-sound-ajuster.component.html',
  styleUrls: ['./player-sound-ajuster.component.scss']
})
export class PlayerSoundAjusterComponent implements OnInit
{

  deviceVolume = 100;
  initialValue = 100;
  oldvalue = 100;
  isMuted = false;
  constructor(private spotifyService: SpotifyService)
  {
  }

  ngOnInit()
  {
    let vol_s = localStorage.getItem("spotify_volume");
    if(vol_s)
    {
      this.deviceVolume = parseInt(vol_s);
      this.oldvalue = this.deviceVolume;
      this.spotifyService.changeCurrentDeviceVolume(this.deviceVolume).subscribe();
    }
    else {
      this.spotifyService.getCurrentPlaybackInfo().subscribe(x => {
        if(x)
        {
          this.deviceVolume = x.device.volume_percent;
          this.oldvalue = x.device.volume_percent;
        }
      });
    }
  }

  onAfterChange(value)
  {
    if (this.deviceVolume == 0)
      this.isMuted = true;
    else this.isMuted = false;
    this.oldvalue = value;
    this.spotifyService.changeCurrentDeviceVolume(value).subscribe(x => {
      localStorage.setItem("spotify_volume", value);
    });
  }

  muteOrUnmute()
  {
    if (this.isMuted)
      this.deviceVolume = this.oldvalue;
    else this.deviceVolume = 0;
    this.spotifyService.changeCurrentDeviceVolume(this.deviceVolume).subscribe();
    this.isMuted = !this.isMuted;
  }

}
