import { Component, OnInit, Input } from '@angular/core';
import { KanbanBoardType, KanbanBoard } from '../../entities/Board';
import { KanbanService } from '../../services/kanban.service';
import { TodoService } from '../../services/todo.service';
import { KanbanCard } from '../../entities/Card';
import { KanbanList } from '../../entities/List';
import { MoveCardSpecific } from '../../entities/move-card-specific';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KanbanActionsManagerComponent } from '../kanban-actions-manager/kanban-actions-manager.component';
import { RelationForListDTO } from '../../entities/list-relation';
import { NotifyUserForAction } from '../../entities/notify-user';
import { KanbanTreeSelectedInfos } from '../../entities/kanban-tree-selected-infos';
import { EventService } from '../../../../../src/app/SDICalendar/calendar/services/event.service';
import { NameUserPipe } from '../../../../../src/app/pipes/name-user-pipe/name-user.pipe';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotesService } from '../../../../../src/app/notes/notes.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-kanban-move-copy-card',
  templateUrl: './kanban-move-copy-card.component.html',
  styleUrls: ['./kanban-move-copy-card.component.scss']
})
export class KanbanMoveCopyCardComponent implements OnInit
{

  constructor(public kbs: KanbanService, public tds: TodoService, private translateService: TranslateService,
    private nameuserpipe: NameUserPipe, private modalService: NzModalService, private router: Router,
    private ns: NotesService,
    private eventService: EventService) { }

  @Input() card: KanbanCard = new KanbanCard();
  @Input() board: KanbanBoard = new KanbanBoard();
  @Input() list: KanbanList = new KanbanList();
  @Input() typeaction: string;

  allowedListForMove: RelationForListDTO[] = [];
  selectedList: KanbanList;
  s_movestartlist = "";
  s_moveendlist = "";
  s_movebeforecard = "";
  s_moveaftercard = "";
  bypass = false;
  dataHasLoaded = false;
  selectedInfo: KanbanTreeSelectedInfos = new KanbanTreeSelectedInfos();
  boardForMove: KanbanBoard[] = [];

  ngOnInit()
  {
    if (this.typeaction == "move")
    {
      this.translateService.get("KANBAN.EDIT-CARD.MOVE-LIST-START").subscribe(x => this.s_movestartlist = x);
      this.translateService.get("KANBAN.EDIT-CARD.MOVE-LIST-END").subscribe(x => this.s_moveendlist = x);
      this.translateService.get("KANBAN.EDIT-CARD.MOVE-CARD-BEFORE").subscribe(x => this.s_movebeforecard = x);
      this.translateService.get("KANBAN.EDIT-CARD.MOVE-CARD-AFTER").subscribe(x => this.s_moveaftercard = x);
    }
    else 
    {
      this.translateService.get("KANBAN.EDIT-CARD.COPY-LIST-START").subscribe(x => this.s_movestartlist = x);
      this.translateService.get("KANBAN.EDIT-CARD.COPY-LIST-END").subscribe(x => this.s_moveendlist = x);
      this.translateService.get("KANBAN.EDIT-CARD.COPY-CARD-BEFORE").subscribe(x => this.s_movebeforecard = x);
      this.translateService.get("KANBAN.EDIT-CARD.COPY-CARD-AFTER").subscribe(x => this.s_moveaftercard = x);
    }


    if (this.list)
      this.allowedListForMove = this.list.allowedDeplacementListIds;
    if (this.card.listId && this.allowedListForMove.length == 0)
    {
      this.kbs.getListWorkflowInfos(this.card.listId).subscribe(infos =>
      {
        this.allowedListForMove = infos;
        this.initialBypass = this.allowedListForMove.length == 0 && (!this.list || (this.kbs.allowMoveForNonRestrictedList && this.typeaction == "move") || (this.kbs.allowCopyForNonRestrictedList && this.typeaction == "copy"));
        this.kbs.getAllBoardAndListForUser().subscribe(x =>
        {
          this.boardForMove = x;
          this.selectedInfo.board = this.board;
          this.selectedInfo.list = this.list;
          this.dataHasLoaded = true;
        });
      })
    }
    else
    {
      this.kbs.getAllBoardAndListForUser().subscribe(x =>
      {
        this.boardForMove = x;
        this.selectedInfo.board = this.board;
        this.selectedInfo.list = this.list;
        this.initialBypass = this.allowedListForMove.length == 0 && (!this.list || (this.kbs.allowMoveForNonRestrictedList && this.typeaction == "move") || (this.kbs.allowCopyForNonRestrictedList && this.typeaction == "copy"));
        this.dataHasLoaded = true;
      });
    }
  }

  onSelection(data: KanbanTreeSelectedInfos)
  {
    this.selectedInfo = data;
  }

  initialBypass = false;

  //This function forces the refreshment of the component
  redirectTo(uri: string)
  {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  updateMoveBoard(moveCard: MoveCardSpecific)
  {
    if (document.location.href.indexOf("third-party") >= 0)
    {
      this.kbs.reloadThirdPartyCards.next(moveCard.idcard);

      if (this.kbs.modalMove)
        this.kbs.modalMove.close();
      if (this.kbs.modalAction)
        this.kbs.modalAction.close();
      return;
    }
    if (this.kbs.editedEvent)
    {
      this.kbs.editedEvent.id_obj = moveCard.idlistto;
      setTimeout(() =>
      {
        this.eventService.updateEventSubject.next(null);
      }, 500);

      if (this.kbs.modalMove)
        this.kbs.modalMove.close();
      if (this.kbs.modalAction)
        this.kbs.modalAction.close();

      return;
    }
    if (!this.board || this.board.id != moveCard.idBoard)
    {
      if (this.kbs.modalMove)
        this.kbs.modalMove.close();
      if (this.kbs.modalAction)
        this.kbs.modalAction.close();
      let noteindex = this.ns.notes.findIndex(x => x.id == this.card.id);
      if (noteindex >= 0)
      {
        this.ns.notes.splice(noteindex, 1);
        return;
      }
      //this.redirectTo("/kanban/" + moveCard.idBoard + "/" + moveCard.idcard)
      return;
    }
    let taskIndex = this.tds.taskList.findIndex(x => x.id == this.card.id);
    if (taskIndex >= 0)
    {
      this.tds.taskList.splice(taskIndex, 1);
      this.tds.taskListChange.next(null);
    }
    else if (this.list)
    {
      if (this.selectedList.autofilter)
      {
        this.kbs.GetAllListForBoard(this.board.id, this.kbs.filterBoardLists).subscribe(x =>
        {
          this.board.lists = x;
          let list = this.board.lists.find(x => x.id == this.selectedList.id);
          if (list)
          {
            this.kbs.sortCartManually(list).subscribe(() =>
            {
              this.kbs.kanbanBoardChange.next(null);
            });
          }
          else this.kbs.kanbanBoardChange.next(null);
        });
      }
      else
      {
        this.kbs.GetAllListForBoard(this.board.id, this.kbs.filterBoardLists).subscribe(x =>
        {
          this.board.lists = x;
          this.kbs.kanbanBoardChange.next(null);
        });
      }
    }
    if (this.kbs.modalAction)
      this.kbs.modalAction.close();
    if (this.kbs.modalMove)
      this.kbs.modalMove.close();
    this.kbs.editedCard.listId = moveCard.idlistto
    if (this.kbs.modalEdit)
      this.kbs.modalEdit.close();
  }


  makeTheMove(movecard: MoveCardSpecific, typemove: string, relation: RelationForListDTO, comment: string, idmembers: any[], idteams: any[], dueDate: Date, idlabels: any[], idtps: any[], description: string)
  {
    this.card.listId = this.selectedList.id;
    if (!this.bypass && movecard.idcardto != movecard.idlistfrom)
      this.kbs.applyWorkflowActions(this.card, this.list, relation, this.selectedList.actionsAtCardCreation.filter(x => x.triggerAction == "2"), comment, idmembers, idteams, dueDate, idlabels, idtps, description)
    switch (typemove)
    {
      case "startlist":
        this.kbs.moveCardToListStart(movecard).subscribe(x =>
        {
          this.updateMoveBoard(movecard);
          if (this.kbs.modalEdit)
            this.kbs.modalEdit.close();
        });
        break;
      case "endlist":
        this.kbs.moveCardToListEnd(movecard).subscribe(x =>
        {
          this.updateMoveBoard(movecard);
          if (this.kbs.modalEdit)
            this.kbs.modalEdit.close();
        });
        break;
      case "beforecard":
        this.kbs.moveCardToBeforeCard(movecard).subscribe(x =>
        {
          this.updateMoveBoard(movecard);
          if (this.kbs.modalEdit)
            this.kbs.modalEdit.close();
        });
        break;
      case "aftercard":
        this.kbs.moveCardToAfterCard(movecard).subscribe(x =>
        {
          this.updateMoveBoard(movecard);
          if (this.kbs.modalEdit)
            this.kbs.modalEdit.close();
        });
        break;
      default:
        this.updateMoveBoard(movecard);
        if (this.kbs.modalEdit)
          this.kbs.modalEdit.close();
        break;
    }
  }

  async moveWorkflowManageMethod(movecard: MoveCardSpecific, typemove: string)
  {
    let rel = this.allowedListForMove.find(x => x.targetListId == movecard.idlistto);
    let list = this.selectedInfo.list;
    let listaction = list ? list.actionsAtCardCreation.filter(x => x.triggerAction == "2") : [];
    let filteredActionCard = this.card.actions.filter(x => x.triggerAction == "2");
    listaction = listaction.concat(filteredActionCard);
    this.selectedList = list;
    let board = this.selectedInfo.board;
    if (board)
    {
      board.members = (await firstValueFrom(this.kbs.getMembersForBoard(board.id))).filter(x => x.userId).map(x => x.userId);
      board.members.sort((a, b) => this.nameuserpipe.transform(a).toUpperCase() < this.nameuserpipe.transform(b).toUpperCase() ? -1 : 1);
    }

    console.log(movecard);

    if (((rel && rel.actionsRelation) || (list && listaction.length > 0)) && movecard.idcardto != movecard.idlistfrom)
    {
      if ((rel && (rel.actionsRelation.indexOf("SEND-MAIL-TEAM-MANUAL") >= 0 || rel.actionsRelation.indexOf("NOTIFY-USER-GENERAL-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-LABELS-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-CARD-TEAMS-MANUAL") >= 0 || rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || rel.actionsRelation.indexOf("ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0))
        || (list && listaction.length > 0 && listaction.findIndex(x => x.codeAction == "SEND-MAIL-TEAM-MANUAL" || x.codeAction == "NOTIFY-USER-GENERAL-MANUAL" || x.codeAction == "AFFECT-THIRD-PARTY-MANUAL" || x.codeAction == "AFFECT-LABELS-MANUAL" || x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL" || x.codeAction == "AFFECT-CARD-TEAMS-MANUAL" || x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT" || x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT" || x.codeAction == "SET-DUE-DATE") >= 0))
      {
        let title = "";
        this.translateService.get("KANBAN.WORKFLOW-MANAGE-TITLE").subscribe(x => title = x);
        let confirm = "";
        this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
        let cancel = "";
        this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
        this.kbs.modalAction = this.modalService.create({
          nzTitle: title,
          nzContent: KanbanActionsManagerComponent,
          nzWidth: '90%',
          nzClosable: false,
          nzMaskClosable: false,
          nzFooter: [{
            label: confirm,
            type: 'primary',
            disabled: (result) =>
            {
              if (result.comment.length == 0 && rel && rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
                return true;
              if (result.description.length == 0 && listaction.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
                return true;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return true;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && listaction.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return true;
              if (!result.dueDate && rel && rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0)
                return true;
              if (!result.dueDate && listaction.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
                return true;
              if (result.listThirdPartyToAffect.length == 0 && listaction.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return true;
              if (result.listThirdPartyToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return true;
              return false;
            },
            onClick: (result) =>
            {
              if (result.comment.length == 0 && rel && rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
                return;
              if (result.description.length == 0 && listaction.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
                return;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && listaction.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return;
              if (!result.dueDate && rel && rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0)
                return;
              if (!result.dueDate && listaction.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
                return;
              if (result.listThirdPartyToAffect.length == 0 && listaction.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return;
              if (result.listThirdPartyToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return;

              let notify = new NotifyUserForAction();
              notify.trigger = "2";
              notify.oldIdList = movecard.idlistfrom;
              notify.userToNotify = result.listUsersToNotified;
              notify.teamToNotify = result.listTeamsToNotified;
              this.kbs.notifyUser(movecard.idcard, rel, listaction, notify);

              if (this.typeaction == "move")
              {
                this.makeTheMove(movecard, typemove, rel, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.dueDate, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description);
              }
              else
              {
                this.createCopy(movecard, typemove, rel, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.dueDate, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description);
              }
            }
          },
          {
            label: cancel,
            onClick: () =>
            {
              this.kbs.modalAction.close();
            }
          }
          ]
        });
        this.kbs.modalAction.componentInstance.board = board;
        this.kbs.modalAction.componentInstance.card = this.card;
        this.kbs.modalAction.componentInstance.listid = list.id;
        this.kbs.modalAction.componentInstance.relation = rel;
        this.kbs.modalAction.componentInstance.trigger = "2";
      }
      else
      {
        if (this.typeaction == "move")
        {
          this.makeTheMove(movecard, typemove, rel, null, null, null, null, null, null, "");
        }
        else 
        {
          this.createCopy(movecard, typemove, rel, null, null, null, null, null, null, "");
        }
      }
    }
    else 
    {
      if (this.typeaction == "move")
      {
        this.makeTheMove(movecard, typemove, rel, null, null, null, null, null, null, "");
      }
      else 
      {
        this.createCopy(movecard, typemove, rel, null, null, null, null, null, null, "");
      }
    }
  }

  moveStartTodoList()
  {
    let board = this.selectedInfo.board;
    if (!board)
      return;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = this.selectedInfo.list ? this.selectedInfo.list.id : 0;
    movecard.idBoard = board.id;
    movecard.bypass = this.bypass;

    if (this.typeaction == "move")
    {
      this.kbs.moveCardToListStart(movecard).subscribe(x =>
      {
        this.updateMoveBoard(movecard);
      });
    }
    else 
    {
      this.createCopy(movecard, "", null, null, null, null, null, null, null, "");
    }
  }

  moveEndTodoList()
  {
    let board = this.selectedInfo.board;
    if (!board)
      return;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = this.selectedInfo.list ? this.selectedInfo.list.id : 0;
    movecard.idBoard = board.id;
    movecard.bypass = this.bypass;

    if (this.typeaction == "move")
    {
      this.kbs.moveCardToListEnd(movecard).subscribe(x =>
      {
        this.updateMoveBoard(movecard);
      });
    }
    else 
    {
      this.createCopy(movecard, "", null, null, null, null, null, null, null, "");
    }
  }

  moveStartList()
  {
    let board = this.selectedInfo.board;
    if (!board)
      return;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = this.selectedInfo.list ? this.selectedInfo.list.id : 0;
    movecard.idBoard = board.id;
    movecard.bypass = this.bypass;
    this.moveWorkflowManageMethod(movecard, "startlist");
  }

  moveEndList()
  {
    let board = this.selectedInfo.board;
    if (!board)
      return;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = this.selectedInfo.list ? this.selectedInfo.list.id : 0;
    movecard.idBoard = board.id;
    movecard.bypass = this.bypass;
    this.moveWorkflowManageMethod(movecard, "endlist");
  }

  moveBeforeCard()
  {
    let board = this.selectedInfo.board;
    if (!board)
      return;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = this.selectedInfo.list ? this.selectedInfo.list.id : 0;
    movecard.idBoard = board.id;
    movecard.idcardto = this.selectedInfo.card ? this.selectedInfo.card.id : 0;
    movecard.bypass = this.bypass;
    this.moveWorkflowManageMethod(movecard, "beforecard");
  }

  moveAfterCard()
  {
    let board = this.selectedInfo.board;
    if (!board)
      return;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = this.selectedInfo.list ? this.selectedInfo.list.id : 0;
    movecard.idBoard = board.id;
    movecard.idcardto = this.selectedInfo.card ? this.selectedInfo.card.id : 0;
    movecard.bypass = this.bypass;
    this.moveWorkflowManageMethod(movecard, "aftercard");
  }

  createCopy(movecard: MoveCardSpecific, typeaction: string, relation: RelationForListDTO, comment: string, members: any[], teams: any[], dueDate: Date, labels: any[], idtps: any[], description: string)
  {
    let copyCard = new KanbanCard();
    copyCard.title = this.card.title;
    copyCard.description = this.card.description;
    copyCard.startDate = this.card.startDate;
    copyCard.dueDate = this.card.dueDate;
    copyCard.archived = this.card.archived;
    copyCard.checklists = [...this.card.checklists];
    copyCard.labels = [...this.card.labels];
    copyCard.members = [...this.card.members];
    copyCard.attachments = [...this.card.attachments];
    copyCard.comments = [...this.card.comments];
    copyCard.physicalPersons = [...this.card.physicalPersons];
    copyCard.moralPersons = [...this.card.moralPersons];

    this.kbs.addCardToList(movecard.idlistto, copyCard).subscribe(x =>
    {
      copyCard.id = x.id;
      movecard.idcard = x.id;
      if (!this.bypass && movecard.idcardto != movecard.idlistfrom)
        this.kbs.applyWorkflowActions(copyCard, this.list, relation, this.selectedList.actionsAtCardCreation.filter(x => x.triggerAction == "2"), comment, members, teams, dueDate, labels, idtps, description);
      switch (typeaction)
      {
        case "startlist":
          this.kbs.moveCardToListStart(movecard).subscribe(x =>
          {
            this.updateMoveBoard(movecard);
          });
          break;
        case "endlist":
          this.kbs.moveCardToListEnd(movecard).subscribe(x =>
          {
            this.updateMoveBoard(movecard);
          });
          break;
        case "beforecard":
          this.kbs.moveCardToBeforeCard(movecard).subscribe(x =>
          {
            this.updateMoveBoard(movecard);
          });
          break;
        case "aftercard":
          this.kbs.moveCardToAfterCard(movecard).subscribe(x =>
          {
            this.updateMoveBoard(movecard);
          });
          break;
        default:
          this.updateMoveBoard(movecard);
          break;
      }
    });
  }

  cancelMove()
  {
    this.kbs.modalMove.close();
  }

  doNothing(event: any)
  {

  }

}
