import { Component, OnInit, Input } from '@angular/core';
import { KanbanService } from '../../services/kanban.service';
import { TranslateService } from '@ngx-translate/core';
import { KanbanTypeRelationEditComponent } from '../kanban-type-relation-edit/kanban-type-relation-edit.component';
import { TypeRelation } from '../../../../../src/app/third-party/entities/type-relation';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-kanban-manage-type-relation',
  templateUrl: './kanban-manage-type-relation.component.html',
  styleUrls: ['./kanban-manage-type-relation.component.scss']
})
export class KanbanManageTypeRelationComponent implements OnInit
{

  constructor(public kbs: KanbanService,
    private translateService: TranslateService,
    private authService: AuthService,
    private modalService: NzModalService) { }

  @Input() boardId: number;
  sortVal = "descend";
  sortKey = "name";
  dataLoaded = false;
  nbPPPerPage = 50;
  title = "";
  showName = true;
  showDescription = true;
  showTypeSource = true;
  showTypeTarget = true;
  archivedMode = false;

  ngOnInit()
  {
    this.authService.setCamelineTitle("kanban");
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.TITLE").subscribe(x => this.title = x);
    if (this.boardId)
      this.kbs.getAllBoardTypeRelation(this.boardId).subscribe(x => { this.dataLoaded = true; this.filterType(); });
    else
      this.kbs.getAllGlobalTypeRelation().subscribe(x => { this.dataLoaded = true; this.filterType(); });
  }

  filterType()
  {
    for (let typerelation of this.kbs.typeRelationCollection)
    {
      if ((typerelation.archived && this.archivedMode) || (!typerelation.archived && !this.archivedMode))
        typerelation.visible = true;
      else typerelation.visible = false;
    }
  }

  sort(sort: any)
  {
    if (sort.value == null)
      sort.value = "descend";
    if (this.kbs.typeRelationCollection.length == 0)
      return;
    this.sortKey = sort.key;
    this.sortVal = sort.value;
    let temp = [...this.kbs.typeRelationCollection];
    this.kbs.typeRelationCollection = temp.sort((a, b) => (sort.value === 'descend') ? (a[sort.key] > b[sort.key] ? 1 : -1) : (b[sort.key] > a[sort.key] ? 1 : -1));
  }

  getTypeText(type: number)
  {
    let text = "";
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.TYPES." + type).subscribe(x => text = x);
    return text;
  }

  ChangeMode(archived: boolean)
  {
    if (archived)
    {
      this.translateService.get("THIRD-PARTY.TYPE-RELATION.TITLE-ARCHIVED").subscribe(x => this.title = x);
    }
    else
    {
      this.translateService.get("THIRD-PARTY.TYPE-RELATION.TITLE").subscribe(x => this.title = x);
    }

    this.archivedMode = archived;
    this.filterType();
  }

  createTypeRelation()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.ADD").subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CREATE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanTypeRelationEditComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          let typerelation = result.submitForm();
          if (this.boardId)
            typerelation.boardId = this.boardId;
          if (!typerelation)
            return;
          this.kbs.createTypeRelation(typerelation).subscribe(x =>
          {
            typerelation.id = x;
            typerelation.creatorId = this.authService.connectedUser.id;
            typerelation.creationDate = new Date();
            this.kbs.typeRelationCollection.push(typerelation);
            this.kbs.listRelationInfos.typeRelationList.splice(0, 0, typerelation);
            this.sort({ key: this.sortKey, value: this.sortVal });
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.typerelation = null;
  }

  updateTypeRelation(typerelation: TypeRelation)
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.UPDATE", { v: typerelation.nameSourceTarget }).subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanTypeRelationEditComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          let updatedtyperelation = result.submitForm();
          if (!updatedtyperelation)
            return;
          typerelation.nameSourceTarget = updatedtyperelation.nameSourceTarget;
          typerelation.nameTargetSource = updatedtyperelation.nameTargetSource;
          typerelation.description = updatedtyperelation.description;
          typerelation.actionsRelation = updatedtyperelation.actionsRelation;
          this.kbs.updateTypeRelation(typerelation).subscribe(x =>
          {
            //this.sort({key: this.sortKey, value: this.sortVal});
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.typerelation = typerelation;
  }


  archiveTypeRelation(typerelation: TypeRelation)
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.DELETE", { v: typerelation.nameSourceTarget }).subscribe(x => title = x);
    let content = "";
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.DELETE-CONFIRM", { v: typerelation.nameSourceTarget }).subscribe(x => content = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: content,
      nzWidth: '600px',
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          this.kbs.archiveTypeRelation(typerelation.id).subscribe(x =>
          {
            typerelation.archived = true;
            this.filterType();
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });

  }

  restoreTypeRelation(typerelation: TypeRelation)
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.RESTORE", { v: typerelation.nameSourceTarget }).subscribe(x => title = x);
    let content = "";
    this.translateService.get("THIRD-PARTY.TYPE-RELATION.RESTORE-CONFIRM", { v: typerelation.nameSourceTarget }).subscribe(x => content = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: content,
      nzWidth: '600px',
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          this.kbs.restoreTypeRelation(typerelation.id).subscribe(x =>
          {
            typerelation.archived = false;
            this.filterType();
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });

  }
}
