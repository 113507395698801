import { Component, ViewChild, ElementRef, AfterViewInit, Output, HostListener, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ColorService } from '../../services/color.service';

@Component({
  selector: 'app-color-slider',
  templateUrl: './color-slider.component.html',
  styleUrls: ['./color-slider.component.scss']
})
export class ColorSliderComponent implements AfterViewInit
{
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @Output()
  color: EventEmitter<string> = new EventEmitter();

  @Input() startColor: string;

  private ctx: CanvasRenderingContext2D;
  private mousedown: boolean = false;
  private selectedHeight: number;
  subscription: Subscription;

  constructor(private cs: ColorService) { }

  ngAfterViewInit()
  {
    this.initHue(this.startColor);
    //this.draw();
    setTimeout(() =>
    {
      this.emitColor(10, this.selectedHeight);
    }, 10);
  }

  initHue(startColor: string)
  {
    let tempStartColor = startColor;
    let rgbForHue, startHue, rD, gD, bD
    if (tempStartColor)
    {
      if (tempStartColor.charAt(0) == '#')
        tempStartColor = tempStartColor.substr(1)
      rgbForHue = tempStartColor.match(/.{2}/g);
      rD = this.hexToRgb(rgbForHue[0]) / 255
      gD = this.hexToRgb(rgbForHue[1]) / 255
      bD = this.hexToRgb(rgbForHue[2]) / 255

      if (Math.max(rD, gD, bD) == Math.min(rD, gD, bD))
        startHue = 0.1
      else
        if (Math.max(rD, gD, bD) == rD)
          startHue = (gD - bD) / (rD - Math.min(gD, bD))
        else if (Math.max(rD, gD, bD) == gD)
          startHue = 2.0 + (bD - rD) / (gD - Math.min(rD, bD))
        else
          startHue = 4.0 + (rD - gD) / (bD - Math.min(gD, rD))

      startHue *= 60
      if (startHue < 0)
        startHue += 360
      this.selectedHeight = (startHue * (this.canvas.nativeElement.height - 1)) / 360
    }
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes['startColor']) 
    {
      if (changes['startColor'].currentValue)
      {
        this.initHue(changes['startColor'].currentValue);
        this.draw();
        setTimeout(() =>
        {
          this.emitColor(10, this.selectedHeight);
        }, 10);
      }
    }
  }

  draw()
  {
    console.log("toto1");
    if (!this.ctx)
    {
      this.ctx = this.canvas.nativeElement.getContext('2d');
    }


    let width = this.canvas.nativeElement.width;
    let height = this.canvas.nativeElement.height;

    this.ctx.clearRect(0, 0, width, height);
    console.log("toto2");
    let gradient = this.ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, '#FF0000');
    gradient.addColorStop(0.17, '#FFFF00');
    gradient.addColorStop(0.34, '#00FF00');
    gradient.addColorStop(0.51, '#00FFFF');
    gradient.addColorStop(0.68, '#0000FF');
    gradient.addColorStop(0.85, '#FF00FF');
    gradient.addColorStop(1, '#FF0000');
    console.log("toto3");
    this.ctx.beginPath();
    console.log("toto4");
    this.ctx.rect(0, 0, width, height);
    console.log("toto5");
    this.ctx.fillStyle = gradient;
    this.ctx.fill();
    console.log("toto6");
    this.ctx.closePath();
    console.log("toto7");

    if (this.selectedHeight)
    {
      this.ctx.beginPath();
      console.log("toto8");
      this.ctx.strokeStyle = 'white';
      this.ctx.lineWidth = 5;
      this.ctx.rect(0, this.selectedHeight - 5, width, 10);
      console.log("toto9");
      this.ctx.stroke();
      console.log("toto10");
      this.ctx.closePath();
      console.log("toto11");
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(evt: MouseEvent)
  {
    this.mousedown = false;
  }

  onMouseDown(evt: MouseEvent)
  {
    this.mousedown = true;
    this.selectedHeight = evt.offsetY;
    this.draw();
    this.emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseMove(evt: MouseEvent)
  {
    if (this.mousedown)
    {
      console.log("toto");

      this.selectedHeight = evt.offsetY;
      this.draw();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number)
  {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.color.emit(rgbaColor);
  }

  rgbToHex(rgb)
  {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2)
    {
      hex = "0" + hex;
    }
    return hex;
  };

  hexToRgb(hex)
  {
    var rgb = parseInt(hex, 16);
    return rgb;
  };

  hslToRgb(h, s, l)
  {
    var r, g, b;

    if (s == 0)
    {
      r = g = b = l; // achromatic
    }
    else
    {
      const hue2rgb = (p, q, t) =>
      {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [
      Math.max(0, Math.min(Math.round(r * 255), 255))
      , Math.max(0, Math.min(Math.round(g * 255), 255))
      , Math.max(0, Math.min(Math.round(b * 255), 255))
    ];
  }

  getColorAtPosition(x: number, y: number)
  {
    let hvs = this.hslToRgb((this.selectedHeight) / (this.canvas.nativeElement.height - 1), 1, 0.5)
    return ('#' + this.rgbToHex(hvs[0]) + this.rgbToHex(hvs[1]) + this.rgbToHex(hvs[2]))
  }
}
