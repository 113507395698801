import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardCheckDueDatePipe } from './kanban-card-check-due-date.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KanbanCardCheckDueDatePipe],
  exports: [KanbanCardCheckDueDatePipe]
})
export class KanbanCardCheckDueDatePipeModule { }
