import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { NameUserPipe } from '../../../pipes/name-user-pipe/name-user.pipe';
import { CacheDataService } from '../../../services/cache-data.service';
import { ColorService } from '../../../services/color.service';
import { TextCompareService } from '../../../services/text-compare.service';
import { KanbanBoard } from '../../entities/Board';
import { KanbanCard } from '../../entities/Card';
import { KanbanActionManager } from '../../entities/kanban-action-manager';
import { KanbanActionParameters } from '../../entities/kanban-action-parameters';
import { KanbanList } from '../../entities/List';
import { ListAction } from '../../entities/list-actions-control';
import { TriggerActions } from '../../entities/trigger-actions';
import { KanbanActionsService } from '../../services/kanban-actions.service';
import { KanbanBoardTreeComponent } from '../kanban-board-tree/kanban-board-tree.component';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-kanban-list-actions-editor',
  templateUrl: './kanban-list-actions-editor.component.html',
  styleUrls: ['./kanban-list-actions-editor.component.scss']
})
export class KanbanListActionsEditorComponent implements OnInit
{

  constructor(public kas: KanbanActionsService,
    public cds: CacheDataService,
    public modalService: NzModalService,
    public tcs: TextCompareService,
    public nameuserpipe: NameUserPipe,
    private NzMessageService: NzMessageService,
    public translateService: TranslateService,
    public cs: ColorService) { }
  @Input() list: KanbanList;
  @Input() board: KanbanBoard;
  @Input() trigger: string;
  @Input() idtrigger: number;
  actionsManager: KanbanActionManager = new KanbanActionManager();
  boardForMove: KanbanBoard[] = [];
  hasUpdated = false;
  showData = false;
  trig: TriggerActions = new TriggerActions();
  tabindex = 0;
  tabindexTriggerTabel = [
    { index: 0, trigger: "0" },
    { index: 1, trigger: "1" },
    { index: 2, trigger: "4" },
    { index: 3, trigger: "5" },
    { index: 4, trigger: "3" },
    { index: 5, trigger: "2" },
    { index: 6, trigger: "6" }
  ]

  ngOnInit()
  {
    this.kas.initActions(this.list != null, this.actionsManager.actions);
    this.trig = this.kas.triggerActions[0];
    this.kas.getNbActionsPerTrigger(this.list.id).subscribe(infos =>
    {
      for (let info of infos)
      {
        let trig = this.kas.triggerActions.find(x => x.trigger == info.trigger);
        if (trig)
          trig.nbActions = info.nbAction;
      }

    })
    let trig = this.trigger ? this.trigger : "0";
    this.getActionsForTrigger(trig);
    //this.tabindex = this.tabindexTriggerTabel.find(x => x.trigger == trig).index;
    this.kas.getAllBoardAndListForUser().subscribe(boards => this.boardForMove = boards);
  }

  reloadData(actionTrigger: string)
  {
    if (!actionTrigger)
    {
      this.trigger = null;
      this.idtrigger = null;
    }
    else
    {
      let infoaction = actionTrigger.split('-');
      let trigger = infoaction[0];
      let idtrigger = parseInt(infoaction[1]);
      if (this.trigger == trigger && this.idtrigger == idtrigger)
        return;
      this.trigger = trigger;
      this.idtrigger = idtrigger;
    }
    console.log(actionTrigger)
    let trig = this.trigger ? this.trigger : "0";
    //this.kas.triggerActions = [];
    //this.kas.initActions(this.list != null, this.actionsManager.actions);
    this.getActionsForTrigger(trig);
  }

  isUpdating()
  {
    let text = "";
    this.translateService.get("GENERIC-ACTIONS.UNSAVED-MODIFICATIONS").subscribe(x => text = x);
    this.NzMessageService.error(text);
    event.preventDefault();
  }

  checkMoveTab(trigger: TriggerActions, index: number)
  {
    if (this.hasUpdated)
    {
      this.isUpdating();
      return;
    }

    this.trig = trigger;
    this.tabindex = index;
    this.getActionsForTrigger(trigger.trigger)
  }

  getActionsForTrigger(trigger: string)
  {
    this.showData = false;
    this.kas.getListActionsForTrigger(this.list.id, trigger).subscribe(actions =>
    {
      this.actionsManager = actions;
      this.actionsManager.trigger = trigger;
      console.log("data loaded");
      if (this.trig.trigger != trigger)
        this.trig = this.kas.triggerActions.find(x => x.trigger == trigger);
      this.showData = true;
    })
  }

  showAction(action: ListAction, codeAction: string)
  {
    return action.codeAction == codeAction;
  }

  drop(event: CdkDragDrop<ListAction[]>): void
  {
    moveItemInArray(this.actionsManager.actions, event.previousIndex, event.currentIndex);

    // setTimeout(() =>
    // {
    //   this.kas.updateListActionsForTrigger(this.list.id, this.actionsManager).subscribe();
    // }, 300);
  }

  removeAction(trig: TriggerActions, action: ListAction)
  {
    let index = this.actionsManager.actions.findIndex(x => x.triggerAction == action.triggerAction && x.codeAction == action.codeAction);
    if (index >= 0)
      this.actionsManager.actions.splice(index, 1);
    else
    {
      index = trig.actions.findIndex(x => x.triggerAction == action.triggerAction && x.codeAction == action.codeAction);
      if (index >= 0)
        trig.actions.splice(index, 1);
      else 
      {
        trig.createdAction = new ListAction();
      }
    }
    this.hasUpdated = true;
  }

  showActionToAddToTrigger(trig: TriggerActions, codeAction: string)
  {
    return trig.actions.findIndex(x => x.codeAction == codeAction) < 0;
  }

  searchTpTextAuto = "";
  searchTpTextManual = "";
  getPP(search: string)
  {
    return this.cds.physicalPersonCollection.filter(x => !x.archived && this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, search))
  }

  getMP(search: string)
  {
    return this.cds.moralPersonCollection.filter(x => !x.archived && this.tcs.contains(x.name, search))
  }

  getUsersForBoard(upperFilter?: string)
  {
    if (!upperFilter)
      upperFilter = "";
    if (this.board)
    {
      let userList = this.board.members.filter(x => this.tcs.contains(this.nameuserpipe.transform(x), upperFilter));


      for (let idteam of this.board.teams)
      {
        let team = this.cds.teamsCollection.find(x => x.id == idteam);
        if (team)
          userList = userList.concat(team.members.filter(x => this.tcs.contains(this.nameuserpipe.transform(x), upperFilter) && userList.findIndex(t => t == x) < 0))
      }
      return userList;
    }
    else
      return this.cds.unarchivedUsers.filter(x => this.tcs.contains((x.name + x.surname), upperFilter)).map(x => x.id);
  }

  selectList(action: ListAction)
  {
    let title = "";
    let confirm = "";
    let cancel = "";
    this.translateService.get("THIRD-PARTY.CALL.SETTINGS.CHOOSE-LIST").subscribe(x => title = x);
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let typeaction = action.codeAction == 'MOVE-CARD' ? 'move' : 'copy';
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanBoardTreeComponent,
      nzWidth: '90%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzOnOk: (mod) =>
      {
        if (!mod.selectedListForMove)
          return;
        let idlist = parseInt(mod.selectedListForMove.split("_")[1]);
        if (action.codeAction == 'MOVE-CARD')
          action.listIdMoveCard = idlist;
        else action.listIdCopyCard = idlist;
        modal.close();
      },
      nzOkText: confirm,
      nzOnCancel: () =>
      {
        modal.close();
      },
      nzCancelText: cancel,
    });
    modal.componentInstance.boards = this.boardForMove;
    modal.componentInstance.initialbypass = true,
      modal.componentInstance.allowedListForMove = [];
    modal.componentInstance.openedBoard = this.board.id;
    modal.componentInstance.loadcardonselect = false;
    modal.componentInstance.showListThatCannotCreateCard = true;
  }

  getBoardBGColor(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return board.bgColor;
      }
    }
    return "";
  }

  getBoardName(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return board.title;
      }
    }
    return "";
  }

  getListName(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return list.title;
      }
    }
    return "";
  }

  addAction(trig: TriggerActions)
  {
    let action = new ListAction();
    action.triggerAction = this.idtrigger ? this.trigger + "-" + this.idtrigger : trig.trigger;
    action.codeAction = trig.createdAction.codeAction;
    action.confirm = trig.createdAction.confirm;
    action.info = trig.createdAction.info;
    action.memberManualParam = trig.createdAction.memberManualParam;
    action.memberAutoParam = trig.createdAction.memberAutoParam;
    action.teamManualParam = trig.createdAction.teamManualParam;
    action.teamAutoParam = trig.createdAction.teamAutoParam;
    action.dueDate = trig.createdAction.dueDate;
    action.labelAutoParam = trig.createdAction.labelAutoParam;
    action.labelManualParam = trig.createdAction.labelManualParam;
    action.thirdPartyAutoParam = trig.createdAction.thirdPartyAutoParam;
    action.thirdPartyManualParam = trig.createdAction.thirdPartyManualParam;
    action.notifiedUsers = trig.createdAction.notifiedUsers;
    action.notifiedTeams = trig.createdAction.notifiedTeams;
    action.listIdMoveCard = trig.createdAction.listIdMoveCard;
    action.listIdCopyCard = trig.createdAction.listIdCopyCard;
    action.dateSchedulingParameter = trig.createdAction.dateSchedulingParameter;
    this.translateService.get("KANBAN.ACTION-WORKFLOW." + action.codeAction).subscribe(x => action.actionDisplayText = x.toUpperCase());
    this.actionsManager.actions.push(action);
    trig.actions.push(action);
    trig.createdAction = new ListAction();
    this.hasUpdated = true;

  }

  getRelationText(actionTrigger: string)
  {
    let infoaction = actionTrigger.split('-');
    let idlistfrom = parseInt(infoaction[1]);
    return this.getListName(idlistfrom) + " => " + this.getListName(this.list.id);
  }

  canAddAction(trig: TriggerActions)
  {
    if (!trig.createdAction.codeAction)
      return false;
    if (trig.createdAction.codeAction == "ASK-CONFIRMATION" && !trig.createdAction.confirm)
      return false;
    if (trig.createdAction.codeAction == "SHOW-INFORMATION-TEXT" && !trig.createdAction.info)
      return false;
    if (trig.createdAction.codeAction == "AFFECT-CARD-MEMBERS-AUTO" && trig.createdAction.memberAutoParam.length == 0)
      return false;
    if (trig.createdAction.codeAction == "AFFECT-CARD-TEAMS-AUTO" && trig.createdAction.teamAutoParam.length == 0)
      return false;
    if (trig.createdAction.codeAction == "NOTIFY-USER-GENERAL-AUTO" && trig.createdAction.notifiedUsers.length == 0)
      return false;
    if (trig.createdAction.codeAction == "MOVE-CARD" && trig.createdAction.listIdMoveCard <= 0)
      return false;
    if (trig.createdAction.codeAction == "COPY-CARD" && trig.createdAction.listIdCopyCard <= 0)
      return false;
    if (trig.createdAction.codeAction == "PLAN-CARD-ARCHIVING-AUTO" && trig.createdAction.dateSchedulingParameter.years == 0
      && trig.createdAction.dateSchedulingParameter.months == 0
      && trig.createdAction.dateSchedulingParameter.days == 0
      && trig.createdAction.dateSchedulingParameter.hours == 0
      && trig.createdAction.dateSchedulingParameter.minutes == 0)
      return false;
    return true;
  }

  isValidWorkflowAction(action: ListAction)
  {
    if (this.idtrigger && (this.trigger + "-" + this.idtrigger) == action.triggerAction)
      return true;
    if (!this.idtrigger && action.triggerAction.indexOf("-") >= 0)
      return true;
    return false;
  }

  showOtherWorflowAction(action: ListAction)
  {
    if (!this.trigger || !this.idtrigger)
      return true;
    if (action.triggerAction == this.trigger || (this.trigger + "-" + this.idtrigger) == action.triggerAction)
      return true;
    return false;
  }

  previewAction(trigger: string, tplContent: TemplateRef<{}>)
  {
    let trig = this.idtrigger ? this.trigger + "-" + this.idtrigger : trigger;
    this.tplContent = tplContent;
    this.kas.applyActions(new KanbanCard(), this.list.id, this.board, trig, this.previewActionPostProcessing, this.cancelMove, this);
  }

  cancelMove(card: KanbanCard, comp: KanbanListActionsEditorComponent) 
  {
  }
  tplContent: TemplateRef<{}>

  async previewActionPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanListActionsEditorComponent)
  {
    let htmlcontent = await firstValueFrom(comp.kas.previewApplyListActionsForTrigger(actionparam));
    let title = "";
    let triggertext = await firstValueFrom(comp.translateService.get('KANBAN.TRIGGER.OPTION-' + comp.actionsManager.trigger));
    comp.translateService.get("KANBAN.PREVIEW-ACTIONS", { v1: triggertext, v2: comp.list.title }).subscribe(x => title = x);
    let close = "";
    comp.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = comp.modalService.create<any, any>({
      nzTitle: title,
      nzContent: comp.tplContent,
      nzWidth: '90%',
      nzBodyStyle: { height: '75vh' },
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.board = comp.board;
    modal.componentInstance.list = comp.list;

    setTimeout(() =>
    {
      document.getElementById("previewdiv").innerHTML = htmlcontent;
    }, 500);
  }

}
