import { Component, isDevMode, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from './services/auth.service';
import { SignalrService } from './services/signalr.service';
import { SettingsService } from './settings/services/settings.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SiteBlockerService } from './services/site-blocker.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalNotificationService } from './services/notification/global-notification.service';
import { Meta } from '@angular/platform-browser';
import { UserSourceService } from './user/services/source/user-source.service';
import { PhysicalPersonService } from './third-party/physical-person/services/physical-person.service';
import { MoralPersonService } from './third-party/moral-person/services/moral-person.service';
import { CacheDataService } from './services/cache-data.service';
import { ThirdPartyService } from './third-party/services/third-party.service';
import { MessagerieService } from './messagerie/messagerie.service';
import { FloatingChatService } from './services/floating-chat.service';
import { ChatService } from './chat/chat.service';
import { PushNotificationService } from './services/notification/push-notification.service';
import { GroupUserDataService } from './group-user/services/data/group-user-data.service';
import { en_US, fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
{
  title = 'app';
  showMenu = true;
  visibility = true;

  constructor(private route: ActivatedRoute, public state: Router, private breakpointObserver: BreakpointObserver,
    public auth: AuthService, private userService: UserSourceService,
    public signalRService: SignalrService, private settingsService: SettingsService, public router: Router,
    public blockService: SiteBlockerService, public translateService: TranslateService,
    public cds: CacheDataService, private tpService: ThirdPartyService,
    public mss: MessagerieService,
    private i18n: NzI18nService,
    public cs: ChatService,
    public gns: GlobalNotificationService,
    private groupuserservice: GroupUserDataService,
    public fcs: FloatingChatService,
    public pushNotifService: PushNotificationService,
    public GlobalNotificationService: GlobalNotificationService, private meta: Meta, private pps: PhysicalPersonService, private mps: MoralPersonService,
  )
  {
    this.meta.addTag({ name: 'lang', content: 'de' });
  }

  // @HostListener('window:beforeunload')
  // async ngOnDestroy()
  // {
  //   //await this.http.get<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/closeTab/").subscribe();
  // }

  @HostListener('window:mousedown') refreshUserState()
  {
    this.mss.menuDailyDate = new Date();
  }

  @HostListener('window:keydown') refreshUserState2()
  {
    this.mss.menuDailyDate = new Date();
  }

  idmeeting = 0;
  ngOnInit()
  {
    //this.http.get<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/openTab/").subscribe();
    this.translateService.addLangs(['en', 'fr', 'de', 'it', 'es', 'pt']);
    this.translateService.setDefaultLang('en');
    this.signalRService.Initialize();
    setInterval(() =>
    {
      if (this.mss.menuDailyDate && this.mss.menuDailyDate.getDay() != new Date().getDay())
      {
        this.mss.menuDailyDate = new Date();
        this.mss.reloadmails.next(true);
      }
    }, 60000)

    setInterval(() =>
    {
      this.pushNotifService.init()
    }, 3600000)

    try
    {
      if (window.location.href.indexOf("/video/") >= 0)
      {
        let tmp = window.location.href.split('/');
        this.idmeeting = parseInt(tmp[tmp.length - 1]);
        console.log(this.idmeeting)
      }
    }
    catch (error) { }
    this.settingsService.getSystemSettingsByName("cameline_show_menu").subscribe(x =>
    {
      if (x && x == "false")
        this.showMenu = false;

    })


    this.route.queryParams.subscribe(params =>
    {
      let iduser = parseInt(params["iduser"]);
      let action = params["action"];
      let lang = params["lang"];
      if (lang)
        this.translateService.use(lang);
      if (iduser > 0 && action == "activateuser")
      {
        this.auth.logout(false);
      }
    });

    if (this.auth.Token)
    {
      this.pushNotifService.init()
      this.fcs.getSavePosition();
      setTimeout(() =>
      {
        this.userService.getAllUserWithAvatar().subscribe(x =>
        {
          this.pps.getAllPhysicalPersons().subscribe(x =>
          {
          })
          this.mps.getAllMoralPersons().subscribe(x =>
          {
          })
          this.cds.userCollection = x.sort((a, b) => (a.name + a.surname).toUpperCase() < (b.name + b.surname).toUpperCase() ? -1 : 1);
          if (this.auth.Token)
          {
            this.auth.getGroupAccess(this.settingsService.setting.value).subscribe(() =>
            {
              this.translateService.use(this.auth.Access.locale);
              this.i18n.setLocale(this.auth.Access.locale == "fr" ? fr_FR : en_US)
              this.meta.updateTag({ name: "lang", content: this.auth.Access.locale });

            });
            setTimeout(() =>
            {
              //if(!isDevMode())
              this.GlobalNotificationService.getAllUserNotifications().subscribe();
            }, 5000);
            setTimeout(() =>
            {
              //if(!isDevMode())
              this.GlobalNotificationService.getAllChatNotifications().subscribe();
            }, 2000);

          }
        });
      }, 500);



      this.mss.getUnreadTotalCount().subscribe(t => this.mss.totalUnread = t);
      //if(!isDevMode())
      {
        setTimeout(() =>
        {
          if (this.cds.teamsCollection.length == 0)
          {
            this.groupuserservice.getAllGroupUsers(false).subscribe(grps => this.cds.teamsCollection = grps.map(x => x.grpuser));
          }
        }, 4500);



        setTimeout(() =>
        {
          this.tpService.getAllLabels().subscribe();
        }, 6500);


      }


    }
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) =>
      {
        if (e.url == '/' && !this.firstLoadOver)
        {
          this.firstLoadOver = true;
          if (this.auth.Token)
            this.auth.goToLastVisitedPage();
        }
        else this.firstLoadOver = true;
      });

    this.removeFocusListener();


  }

  getUnavailableMailString()
  {
    let s = "";
    for (let i = 0; i < this.mss.unavailableAccount.length; i++)
    {
      if (i > 0)
        s += ", ";
      s += this.mss.unavailableAccount[i].mailAdress;
    }
    return s;
  }

  removeFocusListener()
  {
    document.addEventListener("focus", this.fku, true)
    //document.removeEventListener("focus", this.fku, true)
  }
  fku()
  {
    event.stopImmediatePropagation()
  }
  firstLoadOver = false;

  reload()
  {
    location.reload();
  }

  updateMenuVisibility()
  {
    this.showMenu = !this.showMenu;
    this.settingsService.setSystemSettingsByName("cameline_show_menu", this.showMenu.toString());
    setTimeout(() =>
    {
      this.mss.setShowText();
    }, 500);

  }

  getAction()
  {
    let str = "other";
    if (window.location.href.indexOf("action=resetpassword") > -1)
      str = "resetpassword";
    return str;
  }

  /*isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );*/
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(() => true)
    );

  /*
  // check if window size in too samll
  //
  */
  getWindowSize()
  {
    return window.innerWidth < 1200 || window.innerHeight < 600
  }
  DragMenuPosition = ""


  /*
  // call when drag is finish
  //
  */
  dragEnded(ev)
  {
    let dragPosition = ev.source.getFreeDragPosition()
    this.fcs.oldWidthPositionPourCent = dragPosition.x * 100 / window.innerWidth
    this.fcs.oldHeightPositionPourCent = dragPosition.y * 100 / window.innerHeight
    this.savePosition(this.fcs.oldWidthPositionPourCent, this.fcs.oldHeightPositionPourCent)
  }

  /*
  // Save last position of floating chat
  //
  */
  savePosition(x: number, y: number)
  {
    this.DragMenuPosition = x.toString() + "|" + y.toString();
    this.settingsService.setSettingsByName("DragMenuPosition", this.DragMenuPosition);
  }

  disableClick = false
  visible: boolean = false

  /*
  // Detect click on floating menu
  //
  */
  chatClick()
  {
    if (this.disableClick)
    {
      this.disableClick = false
      return
    }
    else if (this.visible)
    {
      this.visible = false
      return
    }
    else
      this.visible = true

    if (this.GlobalNotificationService.chatNotifications.length > 0)
    {
      this.fcs.chatTemplate = "ChatnotificationsList"
    }
    else
    {
      this.fcs.chatTemplate = "ChatContentTemplate"
    }
  }

  /*
  // Detect drag on floating menu
  //
  */
  chatDrag()
  {
    this.disableClick = true
  }

  //how to place the popover
  whereToPlace(): string
  {
    if (this.fcs.oldWidthPositionPourCent >= 50 && this.fcs.oldWidthPositionPourCent <= 100 && this.fcs.oldHeightPositionPourCent >= 0 && this.fcs.oldHeightPositionPourCent <= 50)
    {
      return "bottomRight"
    }
    if (this.fcs.oldWidthPositionPourCent <= 50 && this.fcs.oldWidthPositionPourCent >= 0 && this.fcs.oldHeightPositionPourCent >= 0 && this.fcs.oldHeightPositionPourCent <= 50)
    {
      return "bottomLeft"
    }
    if (this.fcs.oldWidthPositionPourCent >= 50 && this.fcs.oldWidthPositionPourCent <= 100 && this.fcs.oldHeightPositionPourCent >= 50 && this.fcs.oldHeightPositionPourCent <= 100)
    {
      return "topRight"
    }
    if (this.fcs.oldWidthPositionPourCent <= 50 && this.fcs.oldWidthPositionPourCent >= 0 && this.fcs.oldHeightPositionPourCent >= 50 && this.fcs.oldHeightPositionPourCent <= 100)
    {
      return "topLeft"
    }
    else
      return "bottom"
  }

}
