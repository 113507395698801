import { MessagerieAccountAlias } from "./messagerie-account-alias";
import { MessagerieSignature } from "./messagerie-signature";

export class MailAccount
{
    id: number = 0;
    mailAdress: string = "";
    password: string = "";
    server: string = "";
    port: number;
    type = "IMAP";
    useSSL = true;
    userId: number;
    userAccess: number[] = [];
    teamAccess: number[] = [];
    aliases: MessagerieAccountAlias[] = [];
    sentFolder = "";
    trashFolder = "";
    draftFolder = "";
    spamFolder = "";
    inboxFolder = "";
    outboxFolder = "";
    inboxRecuperationDelay = 1;
    otherFoldersRecuperationDelay = 1440;
    autosortAllFolder = true;
    differedTime = 5;
    signatures: MessagerieSignature[] = [];
    recuperation = true;
    archived = false;
}
