import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingChatTitleComponent } from './floating-chat-title.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

@NgModule({
  imports: [
    CommonModule,
    NzButtonModule,
    NzIconModule,
    FormsModule,
    TranslateModule,
    MatListModule,
    NzPopoverModule,
    NzBadgeModule,
    NzToolTipModule,
    NzAvatarModule,

  ],
  declarations: [FloatingChatTitleComponent],
  exports: [FloatingChatTitleComponent]
})
export class FloatingChatTitleModule { }
