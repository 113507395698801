import { Injectable, PACKAGE_ROOT_URL } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Injectable({
  providedIn: 'root'
})
export class AvatarService
{

  avatarUrl = "";
  avatarFile: File;
  loading = false;
  constructor(private translateService: TranslateService,
    private notification: NzMessageService) { }
  handleChange(info: { file: NzUploadFile }): void
  {
    if (info.file.status === 'uploading')
    {
      this.loading = true;
      return;
    }
    if (info.file.status === 'done')
    {
      if (info.file.size > 500000)
      {
        this.createBasicNotification();
        return;
      }
      // Get this url from response in real world.
      this.avatarFile = info.file.originFileObj;
      this.getBase64(info.file.originFileObj, (img: string) =>
      {
        this.loading = false;
        this.avatarUrl = img;
      });
    }
  }

  handleChangeLocal(info: { file: NzUploadFile }): string
  {
    let url = "";
    if (info.file.status === 'uploading')
    {
      this.loading = true;
      return;
    }
    if (info.file.status === 'done')
    {
      if (info.file.size > 500000)
      {
        this.createBasicNotification();
        return;
      }
      // Get this url from response in real world.
      this.avatarFile = info.file.originFileObj;
      this.getBase64(info.file.originFileObj, (img: string) =>
      {
        this.loading = false;
        url = img;
      });
    }
    return url;
  }
  createBasicNotification(): void
  {
    let errorMessage;
    this.translateService.get("USER.ERROR.INCORRECT-AVATAR-SIZE").subscribe(x => errorMessage = x)
    this.notification
      .create("error",
        errorMessage
      )
  }

  RemoveProfileImage()
  {
    this.avatarUrl = "";
    this.avatarFile = null;
  }

  private getBase64(img: File, callback: (img: {}) => void): void
  {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

}
