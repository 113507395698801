import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'check_date_year'
})
export class CheckDateYearPipe implements PipeTransform {

  transform(date: Date): any {
    let now = new Date();
    let due = new Date(date);
    return now.getFullYear() == due.getFullYear()
  }

}
