import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CacheDataService } from 'src/app/services/cache-data.service';
import { MeetingInfos } from '../entities/meeting-infos';
import { MeetingParticipantInfos } from '../entities/meeting-participant-infos';
import { ZoomService } from '../zoom.service';

@Component({
  selector: 'app-zoom-meeting-participants',
  templateUrl: './zoom-meeting-participants.component.html',
  styleUrls: ['./zoom-meeting-participants.component.scss']
})
export class ZoomMeetingParticipantsComponent implements OnInit
{

  constructor(private zs: ZoomService,
    private translateService: TranslateService,
    private cds: CacheDataService) { }
  dataLoaded = false;
  groupbyuser = true;
  datas: MeetingParticipantInfos[] = [];
  filteredDatas: MeetingParticipantInfos[] = [];
  @Input() meetingid: string;
  meeting: MeetingInfos = new MeetingInfos();
  days = 0;
  hours = 0;
  minutes = 0;


  ngOnInit()
  {
    this.meeting = this.cds.scheduledMeetingCollection.find(x => x.id == this.meetingid);
    if (!this.meeting)
      this.meeting = this.cds.meetingCollection.find(x => x.id == this.meetingid);
    if (this.meeting.realStartDate && this.meeting.endDate)
    {
      console.log(this.meeting.realStartDate, this.meeting.endDate)
      let diffMs = (new Date(this.meeting.endDate).getTime() - new Date(this.meeting.realStartDate).getTime()); // milliseconds
      this.days = Math.floor(diffMs / 86400000); // days
      this.hours = Math.floor((diffMs % 86400000) / 3600000); // hours
      this.minutes = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      console.log(this.days, this.hours, this.minutes)
    }

    console.log(this.meeting)
    this.zs.getMeetingParticipants(this.meetingid).subscribe(participants =>
    {
      if (this.meeting.startDate)
      {
        for (let userid of participants.camelineUserIds)
        {
          let user = this.cds.userCollection.find(x => x.id == userid);
          let participantinfos = participants.listMailPresentInReunion.filter(x => x.mail == user.mail);
          for (let participantinfo of participantinfos)
          {
            let mpi = new MeetingParticipantInfos();
            mpi.userid = userid;
            mpi.mail = user.mail;
            mpi.origin = user.name + " " + user.surname;

            mpi.displayName = participantinfo.displayname;
            mpi.joinDate = participantinfo.joindate;
            mpi.leaveDate = participantinfo.leavedate;
            this.datas.push(mpi);
          }
        }

        for (let teamid of participants.camelineTeamIds)
        {
          let team = this.cds.teamsCollection.find(x => x.id == teamid);
          for (let userid of team.members)
          {
            if (this.datas.findIndex(x => x.userid == userid) >= 0)
              continue;
            let user = this.cds.userCollection.find(x => x.id == userid);
            let participantinfos = participants.listMailPresentInReunion.filter(x => x.mail == user.mail);
            for (let participantinfo of participantinfos)
            {

              let mpi = new MeetingParticipantInfos();
              mpi.userid = userid;
              mpi.mail = user.mail;
              mpi.teamid = teamid;
              this.translateService.get("ZOOM.MEETING.PARTICIPANTS.ORIGIN.TEAM-TEXT", { v1: user.name + " " + user.surname, v2: team.name }).subscribe(text => mpi.origin = text);

              mpi.displayName = participantinfo.displayname;
              mpi.joinDate = participantinfo.joindate;
              mpi.leaveDate = participantinfo.leavedate;
              this.datas.push(mpi);
            }

          }
        }

        for (let ppmember of participants.camelinePhysicalPersonIds)
        {
          let participantinfos = participants.listMailPresentInReunion.filter(x => x.mail == ppmember.mail);
          for (let participantinfo of participantinfos)
          {
            let pp = this.cds.physicalPersonCollection.find(x => x.id == ppmember.id);
            let mpi = new MeetingParticipantInfos();
            mpi.ppid = ppmember.id;
            mpi.mail = ppmember.mail;
            mpi.origin = pp.firstName + " " + pp.lastName;
            mpi.displayName = participantinfo.displayname;
            mpi.joinDate = participantinfo.joindate;
            mpi.leaveDate = participantinfo.leavedate;
            this.datas.push(mpi);
          }

        }

        for (let external of participants.externalPersonMails)
        {
          let participantinfos = participants.listMailPresentInReunion.filter(x => x.mail == external.mail);
          for (let participantinfo of participantinfos)
          {
            let mpi = new MeetingParticipantInfos();
            mpi.mail = external.mail;
            mpi.origin = external.mail;
            mpi.displayName = participantinfo.displayname;
            mpi.joinDate = participantinfo.joindate;
            mpi.leaveDate = participantinfo.leavedate;
            this.datas.push(mpi);
          }

        }
      }
      else 
      {
        for (let present of participants.listMailPresentInReunion)
        {
          let mpi = new MeetingParticipantInfos();
          mpi.mail = present.mail;
          mpi.displayName = present.displayname;
          mpi.joinDate = present.joindate;
          mpi.leaveDate = present.leavedate;
          let user = this.cds.userCollection.find(x => x.mail == mpi.mail);
          if (user)
          {
            mpi.origin = user.name + " " + user.surname;
            mpi.userid = user.id;
          }
          else 
          {
            let pp = this.cds.physicalPersonCollection.find(x => x.mails.findIndex(y => y.mail == mpi.mail) >= 0);
            if (pp)
            {
              mpi.origin = pp.firstName + " " + pp.lastName;
              mpi.ppid = pp.id;
            }
            else mpi.origin = "UNKNOWN";
          }
          this.datas.push(mpi);
        }
      }


      this.datas.sort((a, b) => a.joinDate > b.joinDate ? -1 : 1)
      this.searchText();
      this.dataLoaded = true;
    })
  }

  searchText()
  {
    this.filteredDatas = [];
    if (this.groupbyuser)
    {
      for (let d of this.datas)
      {
        let fd = this.filteredDatas.find(x => x.mail == d.mail);
        if (fd)
          fd.joinDate = d.joinDate;
        else
        {

          let mpi = new MeetingParticipantInfos();
          mpi.mail = d.mail;
          mpi.displayName = d.displayName;
          mpi.joinDate = d.joinDate;
          mpi.leaveDate = d.leaveDate;
          mpi.userid = d.userid;
          mpi.teamid = d.teamid;
          mpi.ppid = d.ppid;
          mpi.origin = d.origin;
          this.filteredDatas.push(mpi);
        }
      }
    }
    else this.filteredDatas = [...this.datas];
  }

}
