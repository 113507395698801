import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { GroupUser } from '../../../../src/app/group-user/entities/group-user';
import { AuthService } from '../../../../src/app/services/auth.service';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { TextCompareService } from '../../../../src/app/services/text-compare.service';
import { Mail } from '../../../../src/app/third-party/entities/mail';
import { PhysicalPerson } from '../../../../src/app/third-party/physical-person/entities/physical-person';
import { User } from '../../../../src/app/user/model/user';
import { MeetingInfos } from '../entities/meeting-infos';
import { ExternalPersonMember, MeetingMembers, PhysicalPersonMember } from '../entities/meeting-members';

@Component({
  selector: 'app-zoom-meeting-scheduler',
  templateUrl: './zoom-meeting-scheduler.component.html',
  styleUrls: ['./zoom-meeting-scheduler.component.scss']
})
export class ZoomMeetingSchedulerComponent implements OnInit
{

  constructor(public auth: AuthService,
    private tcs: TextCompareService,
    public cds: CacheDataService) { }

  @Input() meetingInfos: MeetingInfos;
  name = "";
  password = "";
  passwordVisible = false;
  startDate = new Date();
  duration = 30;
  hostid;
  participants: MeetingMembers = new MeetingMembers();
  potentialUser: number[] = [];
  reminders: string[] = [];
  users: User[] = [];
  teams: GroupUser[] = [];
  @ViewChild('userselector', { static: false }) userselector!: NzSelectComponent;
  @ViewChild('teamselector', { static: false }) teamselector!: NzSelectComponent;
  @ViewChild('ppselector', { static: false }) ppselector!: NzSelectComponent;
  selecteduser = null;
  selectedteam = null;
  selectedppid = null;

  ngOnInit()
  {
    this.users = [...this.cds.unarchivedUsers];
    this.teams = [...this.cds.unarchivedTeams];
    if (this.meetingInfos)
    {
      this.name = this.meetingInfos.name;
      this.startDate = this.meetingInfos.startDate;
      this.duration = this.meetingInfos.duration;
      this.hostid = this.meetingInfos.hostId;
      this.participants.camelineUserIds = [...this.meetingInfos.participants.camelineUserIds];
      this.participants.camelineTeamIds = [...this.meetingInfos.participants.camelineTeamIds];
      this.participants.camelinePhysicalPersonIds = [...this.meetingInfos.participants.camelinePhysicalPersonIds];
      this.participants.externalPersonMails = [...this.meetingInfos.participants.externalPersonMails];
      this.reminders = [...this.meetingInfos.reminders];
    }
    else
    {
      this.hostid = this.auth.connectedUser.id;
      this.participants.camelineUserIds.push(this.auth.connectedUser.id);
    }
  }

  searchCamelineUser(searchtext: string)
  {
    this.users = this.cds.unarchivedUsers.filter(x => this.tcs.contains(x.name + " " + x.surname, searchtext))
  }

  searchCamelineTeams(searchtext: string)
  {
    this.teams = this.cds.unarchivedTeams.filter(x => this.tcs.contains(x.name, searchtext))
  }

  checkValidInput()
  {
    if (!this.name || !this.password || !this.startDate || !this.duration)
      return false;
    if (this.participants.camelinePhysicalPersonIds.length == 0 && this.participants.camelineUserIds.length == 0 && this.participants.camelineTeamIds.length == 0 && this.participants.externalPersonMails.length == 0)
      return false;
    if (this.participants.camelineUserIds.indexOf(this.hostid) < 0)
      return false;
    return true;
  }

  isNoUserParticiPant(iduser: number)
  {
    return this.participants.camelineUserIds.findIndex(x => x == iduser) < 0;
  }

  isNoTeamParticiPant(idteam: number)
  {
    return this.participants.camelineTeamIds.findIndex(x => x == idteam) < 0;
  }

  isNoPPParticiPant(idpp: number)
  {
    return this.participants.camelinePhysicalPersonIds.findIndex(x => x.id == idpp) < 0;
  }

  generatePassword()
  {
    let password = this.auth.generatePassword();
    this.password = password.slice(0, 10);
  }

  addUserToParticipants(iduser: number)
  {
    if (!iduser)
      return;
    this.participants.camelineUserIds.push(iduser);
    this.selecteduser = null;
    this.userselector.onClearSelection();
  }

  removeUserFromParticipants(iduser: number)
  {
    let index = this.participants.camelineUserIds.findIndex(x => x == iduser);
    if (index >= 0)
      this.participants.camelineUserIds.splice(index, 1);
  }

  addTeamToParticipants(idteam: number)
  {
    if (!idteam)
      return;
    this.participants.camelineTeamIds.push(idteam);
    this.selectedteam = null;
    this.teamselector.onClearSelection();
  }

  removeTeamFromParticipants(id: number)
  {
    let index = this.participants.camelineTeamIds.findIndex(x => x == id);
    if (index >= 0)
      this.participants.camelineTeamIds.splice(index, 1);

  }

  selectedPP: PhysicalPerson;
  addPPToParticipants(idpp: number)
  {
    if (!idpp)
      return;
    let pp = this.cds.physicalPersonCollection.find(x => x.id == idpp);
    this.selectedPP = pp;
    console.log("hello?")
  }

  addMailPPToParticipants(mail: Mail)
  {
    let ppmember = new PhysicalPersonMember();
    ppmember.id = this.selectedPP.id;
    ppmember.mail = mail.mail;
    this.participants.camelinePhysicalPersonIds.push(ppmember);
    this.selectedPP = null;
    this.ppselector.onClearSelection();
  }

  removePPFromParticipants(id: number)
  {
    let index = this.participants.camelinePhysicalPersonIds.findIndex(x => x.id == id);
    if (index >= 0)
      this.participants.camelinePhysicalPersonIds.splice(index, 1);

  }

  othertext = null;
  addOtherToParticipants()
  {
    let ext = new ExternalPersonMember();
    ext.mail = this.othertext;
    this.participants.externalPersonMails.push(ext);
    this.othertext = null;
  }

  removeOtherFromParticipants(mail: string)
  {
    let index = this.participants.externalPersonMails.findIndex(x => x.mail == mail);
    if (index >= 0)
      this.participants.externalPersonMails.splice(index, 1);
  }

  reminderTmp = null;
  addReminder()
  {
    if (this.reminderTmp && this.reminders.findIndex(x => x == (this.reminderTmp + "")) < 0)
    {
      this.reminders.push(this.reminderTmp + "");
      this.reminderTmp = null;
    }

  }

  removeReminder(reminder: string)
  {
    let index = this.reminders.findIndex(x => x == reminder);
    if (index >= 0)
      this.reminders.splice(index, 1);
  }

  getSortedReminders()
  {
    return this.reminders.sort((a, b) => parseInt(a) < parseInt(b) ? -1 : 1)
  }

  onChange(result: Date)
  {
    this.startDate = result
  }

  searchTpText = "";
  onSearch(text)
  {
    this.searchTpText = text;
  }

  getPP()
  {
    return this.cds.physicalPersonCollection.filter(x => !x.archived && x.mails.length > 0 && this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, this.searchTpText)).sort((a, b) => (a.firstName.toUpperCase() + " " + a.lastName.toUpperCase()) > (b.firstName.toUpperCase() + " " + b.lastName.toUpperCase()) ? 1 : -1);
  }

}
