export class KanbanActionParameters
{
    memberUsers: string[] = [];
    notifyUsers: string[] = [];
    memberTeams: string[] = [];
    labels: string[] = [];
    thirdParties: string[] = [];
    sendMailTeam: string[] = [];
    comment = "";
    description = "";
    endDate: Date;
    moveIdListFrom = 0;
    listId = 0;
    cardId = 0;
    trigger = "";
}