import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ColorService } from '../../services/color.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit
{

  constructor(private cs: ColorService, private authService: AuthService) { }
  @ViewChild("colorSelect", { static: true }) el: ElementRef;
  @Input() initialColorValue: string = "#000000";
  @Output() colorChange = new EventEmitter<string>();
  hue = "";
  color = "";
  typedColor = "";
  initColor = "";
  languageColorList = [{ text: null, hex: null }]
  showslider = false;
  showpalette = false;

  ngOnInit()
  {
    this.initColor = this.initialColorValue;
    let temp = null;
    if (this.authService.Access.locale == "fr")
    {
      this.languageColorList = this.frColorList;
      this.sortLanguage();
      temp = this.languageColorList.find(x => x.hex.toLowerCase() == this.initialColorValue.toLowerCase());
    }
    else
    {
      this.languageColorList = this.enColorList;
      this.sortLanguage();
      temp = this.languageColorList.find(x => x.hex.toLowerCase() == this.initialColorValue.toLowerCase());
    }
    if (temp)
      this.typedColor = temp.hex;
    else
    {
      this.typedColor = this.initialColorValue;
      this.addColor(this.typedColor);
    }

    this.showslider = true;
    this.showpalette = true;
  }

  sortLanguage()
  {
    this.languageColorList.sort((a, b) => a.text.toLocaleLowerCase() < b.text.toLocaleLowerCase() ? -1 : 1)
  }
  checkTypedColor(tc: string)
  {
    if (!tc)
      return;
    let temp = null;
    let color = this.color;
    temp = this.languageColorList.find(x => x.hex.toLowerCase() == tc.toLowerCase())
    if (temp)
      color = temp.hex;
    if (color && color != this.color)
    {
      this.initColor = color;
      this.updateColor(color);
      //this.cs.notifyColorChange.next(color);
    }
  }

  addColor(tc: string)
  {
    let hex = { text: tc, hex: tc };
    this.languageColorList.splice(0, 0, hex);

  }

  checkHexColor(tc: string)
  {
    if (tc && (tc.startsWith("#") && tc.length == 7))
    {
      if (!this.languageColorList.find(x => x.hex.toUpperCase() == tc.toUpperCase()))
        this.addColor(tc);
      else
      {
        this.checkTypedColor(tc);
        this.typedColor = tc;
      }
      document.getElementById("createBoardInput").blur();
    }
  }

  updateColor(newcolor: string)
  {
    this.color = newcolor;
    this.colorChange.emit(this.color);
  }

  enColorList = [
    { text: "Aqua", hex: "#00FFFF" },
    { text: "Cyan", hex: "#00FFFF" },
    { text: "Black", hex: "#000000" },
    { text: "Blue", hex: "#0000FF" },
    { text: "Fuchsia", hex: "#FF00FF" },
    { text: "Magenta", hex: "#FF00FF" },
    { text: "Green", hex: "#008000" },
    { text: "Gray", hex: "#808080" },
    { text: "Lime", hex: "#00FF00" },
    { text: "Maroon", hex: "#800000" },
    { text: "Navy", hex: "#000080" },
    { text: "Olive", hex: "#808000" },
    { text: "Purple", hex: "#800080" },
    { text: "Red", hex: "#FF0000" },
    { text: "Silver", hex: "#C0C0C0" },
    { text: "Teal", hex: "#008080" },
    { text: "White", hex: "#FFFFFF" },
    { text: "Yellow", hex: "#FFFF00" },
    { text: "Indianred", hex: "#CD5C5C" },
    { text: "Lightcoral", hex: "#F08080" },
    { text: "Salmon", hex: "#FA8072" },
    { text: "Darksalmon", hex: "#E9967A" },
    { text: "Lightsalmon", hex: "#FFA07A" },
    { text: "Crimson", hex: "#DC143C" },
    { text: "Firebrick", hex: "#B22222" },
    { text: "Darkred", hex: "#8B0000" },
    { text: "Maroon", hex: "#800000" },
    { text: "Pink", hex: "#FFC0CB" },
    { text: "Lightpink", hex: "#FFB6C1" },
    { text: "Hotpink", hex: "#FF69B4" },
    { text: "Deeppink", hex: "#FF1493" },
    { text: "Mediumvioletred", hex: "#C71585" },
    { text: "Palevioletred", hex: "#DB7093" },
    { text: "Coral", hex: "#FF7F50" },
    { text: "Tomato", hex: "#FF6347" },
    { text: "Orangered", hex: "#FF4500" },
    { text: "Darkorange", hex: "#FF8C00" },
    { text: "Orange", hex: "#FFA500" },
    { text: "Gold", hex: "#FFD700" },
    { text: "Lightyellow", hex: "#FFFFE0" },
    { text: "Lemonchiffon", hex: "#FFFACD" },
    { text: "Papayawhip", hex: "#FFEFD5" },
    { text: "Moccasin", hex: "#FFE4B5" },
    { text: "Peachpuff", hex: "#FFDAB9" },
    { text: "Palegoldenrod", hex: "#EEE8AA" },
    { text: "Khaki", hex: "#F0E68C" },
    { text: "Darkkhaki", hex: "#BDB76B" },
    { text: "Lavender", hex: "#E6E6FA" },
    { text: "Thistle", hex: "#D8BFD8" },
    { text: "Plum", hex: "#DDA0DD" },
    { text: "Violet", hex: "#EE82EE" },
    { text: "Orchid", hex: "#DA70D6" },
    { text: "Mediumorchid", hex: "#BA55D3" },
    { text: "Mediumpurple", hex: "#9370DB" },
    { text: "Blueviolet", hex: "#8A2BE2" },
    { text: "Darkviolet", hex: "#9400D3" },
    { text: "Darkorchid", hex: "#9932CC" },
    { text: "Darkmagenta", hex: "#8B008B" },
    { text: "Indigo", hex: "#4B0082" },
    { text: "Slateblue", hex: "#6A5ACD" },
    { text: "Darkslateblue", hex: "#483D8B" },
    { text: "Greenyellow", hex: "#ADFF2F" },
    { text: "Chartreuse", hex: "#7FFF00" },
    { text: "Lawngreen", hex: "#7CFC00" },
    { text: "Limegreen", hex: "#32CD32" },
    { text: "Palegreen", hex: "#98FB98" },
    { text: "Lightgreen", hex: "#90FB90" },
    { text: "Mediumspringgreen", hex: "#00FA9A" },
    { text: "Springgreen", hex: "#00FF7F" },
    { text: "Mediumseagreen", hex: "#3CB371" },
    { text: "Seagreen", hex: "#2E8B57" },
    { text: "Forestgreen", hex: "#228B22" },
    { text: "Darkgreen", hex: "#006400" },
    { text: "Yellowgreen", hex: "#9ACD32" },
    { text: "Olivedrab", hex: "#6B8E23" },
    { text: "Darkolivegreen", hex: "#556B2F" },
    { text: "Mediumaquamarine", hex: "#66CDAA" },
    { text: "Darkseagreen", hex: "#8FBC8F" },
    { text: "Lightseagreen", hex: "#20B2AA" },
    { text: "Darkcyan", hex: "#008B8B" },
    { text: "Lightcyan", hex: "#E0FFFF" },
    { text: "Paleturquoise", hex: "#AFEEEE" },
    { text: "Aquamarine", hex: "#7FFFD4" },
    { text: "Turquoise", hex: "#40E0D0" },
    { text: "Mediumturquoise", hex: "#48D1CC" },
    { text: "Darkturquoise", hex: "#00CED1" },
    { text: "Cadetblue", hex: "#5F9EA0" },
    { text: "Steelblue", hex: "#4682B4" },
    { text: "Lightsteelblue", hex: "#B0C4DE" },
    { text: "Powderblue", hex: "#B0E0E6" },
    { text: "Lightblue", hex: "#ADD8E6" },
    { text: "Skyblue", hex: "#87CEEB" },
    { text: "Lightskyblue", hex: "#87CEFA" },
    { text: "Deepskyblue", hex: "#00BFFF" },
    { text: "Dodgerblue", hex: "#1E90FF" },
    { text: "Cornflowerblue", hex: "#6495ED" },
    { text: "Mediumslateblue", hex: "#7B68EE" },
    { text: "Royalblue", hex: "#4169E1" },
    { text: "Mediumblue", hex: "#0000CD" },
    { text: "Darkblue", hex: "#00008B" },
    { text: "Navy", hex: "#000080" },
    { text: "Midnightblue", hex: "#191970" },
    { text: "Cornsilk", hex: "#FFF8DC" },
    { text: "Blanchedalmond", hex: "#FFEBCD" },
    { text: "Bisque", hex: "#FFE4C4" },
    { text: "Navajowhite", hex: "#FFDEAD" },
    { text: "Wheat", hex: "#F5DEB3" },
    { text: "Burlywood", hex: "#DEB887" },
    { text: "Tan", hex: "#D2B48C" },
    { text: "Rosybrown", hex: "#BC8F8F" },
    { text: "Sandybrown", hex: "#F4A460" },
    { text: "Goldenrod", hex: "#DAA520" },
    { text: "Darkgoldenrod", hex: "#B8860B" },
    { text: "Peru", hex: "#CD853F" },
    { text: "Chocolate", hex: "#D2691E" },
    { text: "Saddlebrown", hex: "#8B4513" },
    { text: "Sienna", hex: "#A0522D" },
    { text: "Brown", hex: "#A52A2A" },
    { text: "Sienna", hex: "#A0522D" },
    { text: "Lightgrey", hex: "#D3D3D3" },
    { text: "Gainsboro", hex: "#DCDCDC" },
    { text: "Mistyrose", hex: "#FFE4E1" },
    { text: "Lavenderblush", hex: "#FFF0F5" },
    { text: "Linen", hex: "#FAF0E6" },
    { text: "Antiquewhite", hex: "#FAEBD7" },
    { text: "Ivory", hex: "#FFFFF0" },
    { text: "Floralwhite", hex: "#FFFAF0" },
    { text: "Oldlace", hex: "#FDF5E6" },
    { text: "Beige", hex: "#F5F5DC" },
    { text: "Seashell", hex: "#FFF5EE" },
    { text: "Whitesmoke", hex: "#F5F5F5" },
    { text: "Ghostwhite", hex: "#F8F8FF" },
    { text: "Aliceblue", hex: "#F0F8FF" },
    { text: "Mintcream", hex: "#F5FFFA" },
    { text: "Honeydew", hex: "#F0FFF0" },
    { text: "Snow", hex: "#FFFAFA" },
    { text: "Darkgray", hex: "#A9A9A9" },
    { text: "Dimgray", hex: "#696969" },
    { text: "Lightslategray", hex: "#778899" },
    { text: "Slategray", hex: "#708090" },
    { text: "Darkslategray", hex: "#2F4F4F" },
  ];

  frColorList = [
    { text: "Cyan", hex: "#00FFFF" },
    { text: "Noir", hex: "#000000" },
    { text: "Bleu", hex: "#0000FF" },
    { text: "Fuchsia", hex: "#FF00FF" },
    { text: "Magenta", hex: "#FF00FF" },
    { text: "Vert", hex: "#008000" },
    { text: "Gris", hex: "#808080" },
    { text: "Citron vert", hex: "#00FF00" },
    { text: "Bordeaux", hex: "#800000" },
    { text: "Bleu marin", hex: "#000080" },
    { text: "Olive", hex: "#808000" },
    { text: "Violet", hex: "#800080" },
    { text: "Rouge", hex: "#FF0000" },
    { text: "Argent", hex: "#C0C0C0" },
    { text: "Vert sarcelle", hex: "#008080" },
    { text: "Blanc", hex: "#FFFFFF" },
    { text: "Jaune", hex: "#FFFF00" },
    { text: "Rouge indien", hex: "#CD5C5C" },
    { text: "Corail clair", hex: "#F08080" },
    { text: "Saumon", hex: "#FA8072" },
    { text: "Saumon foncé", hex: "#E9967A" },
    { text: "Saumon clair", hex: "#FFA07A" },
    { text: "Rouge cramoisi", hex: "#DC143C" },
    { text: "Rouge brique", hex: "#B22222" },
    { text: "Rouge foncé", hex: "#8B0000" },
    { text: "Rose", hex: "#FFC0CB" },
    { text: "Rose clair", hex: "#FFB6C1" },
    { text: "Rose passion", hex: "#FF69B4" },
    { text: "Rose profond", hex: "#FF1493" },
    { text: "Violet moyen", hex: "#C71585" },
    { text: "Violet pâle", hex: "#DB7093" },
    { text: "Corail", hex: "#FF7F50" },
    { text: "Tomate", hex: "#FF6347" },
    { text: "Orangé", hex: "#FF4500" },
    { text: "Orange foncé", hex: "#FF8C00" },
    { text: "Orange", hex: "#FFA500" },
    { text: "Or", hex: "#FFD700" },
    { text: "Jaune clair", hex: "#FFFFE0" },
    { text: "Beige citron soie", hex: "#FFFACD" },
    { text: "Beige papaye", hex: "#FFEFD5" },
    { text: "Beige moccasin", hex: "#FFE4B5" },
    { text: "Beige pêche", hex: "#FFDAB9" },
    { text: "Jaune doré pâle", hex: "#EEE8AA" },
    { text: "Brun kaki", hex: "#F0E68C" },
    { text: "Brun kaki foncé", hex: "#BDB76B" },
    { text: "Bleu lavande", hex: "#E6E6FA" },
    { text: "Violet chardon", hex: "#D8BFD8" },
    { text: "Prune", hex: "#DDA0DD" },
    { text: "Violette", hex: "#EE82EE" },
    { text: "Orchidée", hex: "#DA70D6" },
    { text: "Violet orchidée moyen", hex: "#BA55D3" },
    { text: "Pourpre moyen", hex: "#9370DB" },
    { text: "Bleu violet", hex: "#8A2BE2" },
    { text: "Violet foncé", hex: "#9400D3" },
    { text: "Violet orchidée foncé", hex: "#9932CC" },
    { text: "Magenta foncé", hex: "#8B008B" },
    { text: "Indigo", hex: "#4B0082" },
    { text: "Bleu ardoise", hex: "#6A5ACD" },
    { text: "Bleu ardoise foncé", hex: "#483D8B" },
    { text: "Vert jaune", hex: "#ADFF2F" },
    { text: "Chartreuse", hex: "#7FFF00" },
    { text: "Vert prairie", hex: "#7CFC00" },
    { text: "Citron vert foncé", hex: "#32CD32" },
    { text: "Vert pâle", hex: "#98FB98" },
    { text: "Vert clair", hex: "#90FB90" },
    { text: "Vert printemps moyen", hex: "#00FA9A" },
    { text: "Vert printemps", hex: "#00FF7F" },
    { text: "Vert océan moyen", hex: "#3CB371" },
    { text: "Vert océan", hex: "#2E8B57" },
    { text: "Vert forêt", hex: "#228B22" },
    { text: "Vert foncé", hex: "#006400" },
    { text: "Jaune vert", hex: "#9ACD32" },
    { text: "Kaki", hex: "#6B8E23" },
    { text: "Vert olive foncé", hex: "#556B2F" },
    { text: "Aigue-marine moyen", hex: "#66CDAA" },
    { text: "Vert océan foncé", hex: "#8FBC8F" },
    { text: "Vert marin clair", hex: "#20B2AA" },
    { text: "Cyan foncé", hex: "#008B8B" },
    { text: "Cyan clair", hex: "#E0FFFF" },
    { text: "Azurin", hex: "#AFEEEE" },
    { text: "Aigue-marine", hex: "#7FFFD4" },
    { text: "Turquoise", hex: "#40E0D0" },
    { text: "Turquoise moyen", hex: "#48D1CC" },
    { text: "Turquoise foncé", hex: "#00CED1" },
    { text: "Bleu pétrole", hex: "#5F9EA0" },
    { text: "Bleu acier", hex: "#4682B4" },
    { text: "Bleu acier clair", hex: "#B0C4DE" },
    { text: "Bleu poudre", hex: "#B0E0E6" },
    { text: "Bleu clair", hex: "#ADD8E6" },
    { text: "Bleu azur", hex: "#87CEEB" },
    { text: "Bleu azur clair", hex: "#87CEFA" },
    { text: "Bleu azur profond", hex: "#00BFFF" },
    { text: "Bleu toile", hex: "#1E90FF" },
    { text: "Bleuet", hex: "#6495ED" },
    { text: "Bleu ardoise moyen", hex: "#7B68EE" },
    { text: "Bleu royal", hex: "#4169E1" },
    { text: "Bleu moyen", hex: "#0000CD" },
    { text: "Bleu foncé", hex: "#00008B" },
    { text: "Bleu de minuit", hex: "#191970" },
    { text: "Baune maïs doux", hex: "#FFF8DC" },
    { text: "Amande", hex: "#FFEBCD" },
    { text: "Bisque", hex: "#FFE4C4" },
    { text: "Jaune blanc navaro", hex: "#FFDEAD" },
    { text: "Jaune blé", hex: "#F5DEB3" },
    { text: "Brun bois rustique", hex: "#DEB887" },
    { text: "Brun roux", hex: "#D2B48C" },
    { text: "Brun rosé", hex: "#BC8F8F" },
    { text: "Brun sable", hex: "#F4A460" },
    { text: "Jaune doré", hex: "#DAA520" },
    { text: "Jaune doré foncé", hex: "#B8860B" },
    { text: "Brun pérou", hex: "#CD853F" },
    { text: "Chocolat", hex: "#D2691E" },
    { text: "Brun cuir", hex: "#8B4513" },
    { text: "Terre de sienne", hex: "#A0522D" },
    { text: "Marron", hex: "#A52A2A" },
    { text: "Gris clair", hex: "#D3D3D3" },
    { text: "Etain", hex: "#DCDCDC" },
    { text: "Rose brumeux", hex: "#FFE4E1" },
    { text: "Blanc lavande", hex: "#FFF0F5" },
    { text: "Blanc Lin", hex: "#FAF0E6" },
    { text: "Beige antique", hex: "#FAEBD7" },
    { text: "Ivoire", hex: "#FFFFF0" },
    { text: "Blanc floral", hex: "#FFFAF0" },
    { text: "Blanc dentelle", hex: "#FDF5E6" },
    { text: "Beige", hex: "#F5F5DC" },
    { text: "Blanc coquillage", hex: "#FFF5EE" },
    { text: "Blanc fumée", hex: "#F5F5F5" },
    { text: "Blanc spectral", hex: "#F8F8FF" },
    { text: "Bleu alice", hex: "#F0F8FF" },
    { text: "Blanc menthe", hex: "#F5FFFA" },
    { text: "Miellat", hex: "#F0FFF0" },
    { text: "Neige", hex: "#FFFAFA" },
    { text: "Acier", hex: "#A9A9A9" },
    { text: "Gris mat", hex: "#696969" },
    { text: "Gris ardoise clair", hex: "#778899" },
    { text: "Gris ardoise", hex: "#708090" },
    { text: "Gris ardoise foncé", hex: "#2F4F4F" },
  ];

}
