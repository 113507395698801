// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.select-credentials {
  width: 100%;
  margin-bottom: 5px;
}

.button-group {
  float: right;
}

.span-credentials {
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/third-party/components/associate-third-party-credentials/associate-third-party-credentials.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".tab-title{\r\n    font-weight: bold;\r\n    text-align: center;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.select-credentials{\r\n    width: 100%;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.button-group{\r\n    float: right;\r\n}\r\n\r\n.span-credentials{\r\n    height: 40px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
