import { Component, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { CacheDataService } from '../../services/cache-data.service';
import { PhysicalPerson } from '../../third-party/physical-person/entities/physical-person';
import { MoralPerson } from '../../third-party/moral-person/entities/moral-person';
import { PhoneProfileConfig } from '../entities/phone-profile-config';
import { CallInterfaceProfileService } from '../service/call-interface-profile.service';
import { PhoneProfile } from '../entities/phone-profile';
import { KanbanCardWithThirdParties } from '../entities/card-with-third-party';
import { KanbanCard } from '../../kanban/entities/Card';
import { User } from '../../user/model/user';
import { AuthService } from '../../services/auth.service';
import { ThirdPartyService } from '../../third-party/services/third-party.service';
import { ButtonToLinkTp } from '../entities/button-to-link-tiers';
import { KanbanService } from '../../kanban/services/kanban.service';
import { KanbanToolsService } from '../../kanban/services/kanban-tools.service';
import { TranslateService } from '@ngx-translate/core';
import { CallService } from '../service/call.service';
import { TypeRelationService } from '../../third-party/type-relation/services/type-relation.service';
import { ThirdPartySheetInfos } from '../entities/third-party-sheet-infos';
import { PhoneNumber } from '../../third-party/entities/phone-number';
import { PhysicalPersonService } from '../../third-party/physical-person/services/physical-person.service';
import { ColorService } from '../../services/color.service';
import { KanbanBoard } from '../../kanban/entities/Board';
import { KanbanList } from '../../kanban/entities/List';
import { Subscription, firstValueFrom } from 'rxjs';
import { CredentialsEdit } from '../../third-party/entities/credentials-edit';
import { KanbanAttachment } from '../../kanban/entities/Attachment';
import { Mail } from '../../third-party/entities/mail';
import { ThirdPartySheetUpdateInfos } from '../entities/update-third-party-sheet-infos';
import { FilterRelation } from '../../third-party/entities/filter-relation';
import { CustomField } from '../../third-party/entities/custom-field';
import { AvatarService } from '../../services/avatar.service';
import { UrlApiService } from '../../services/url-api.service';
import { ThirdPartySheetEditIdentityComponent } from '../third-party-sheet-edit-identity/third-party-sheet-edit-identity.component';
import { SiteService } from '../../third-party/site/services/site.service';
import { Site } from '../../third-party/entities/site';
import { SiteEditComponent } from '../../third-party/site/components/site-edit/site-edit.component';
import { KanbanTreeSelectedInfos } from '../../kanban/entities/kanban-tree-selected-infos';
import { TextCompareService } from '../../services/text-compare.service';
import { MoralPersonService } from '../../third-party/moral-person/services/moral-person.service';
import { ModalManagerService } from '../../services/modal-manager-service/modal-manager.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeComponent } from 'ng-zorro-antd/tree';
import { KanbanCardEditModalComponent } from '../../kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.component';
import { ThirdPartyCustomSystemField } from '../entities/third-party-custom-system-field';
import { HttpClient } from '@angular/common/http';
import { TextSearch } from '../../entities/search-text';

declare function scrollHorizontally(e, id): any;
@Component({
  selector: 'app-third-party-sheet',
  templateUrl: './third-party-sheet.component.html',
  styleUrls: ['./third-party-sheet.component.scss']
})
export class ThirdPartySheetComponent implements OnInit
{

  constructor(
    public tpservice: ThirdPartyService,
    private route: ActivatedRoute,
    public avatarService: AvatarService,
    private router: Router,
    public kbs: KanbanService,
    private kts: KanbanToolsService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private cds: CacheDataService,
    private auth: AuthService,
    public translateService: TranslateService,
    public callService: CallService,
    private http: HttpClient,
    public profileService: CallInterfaceProfileService,
    public typerelationService: TypeRelationService,
    public ppService: PhysicalPersonService,
    public mpService: MoralPersonService,
    public siteService: SiteService,
    private mms: ModalManagerService,
    public tcs: TextCompareService,
    public cs: ColorService,
  ) { }

  url = UrlApiService.settings.apiConfig.uriAPI + '/api/user/donothing';
  pp: PhysicalPerson = null;
  mp: MoralPerson = null;
  @Input() id: string;
  @Input() showeverything = false;
  @Output() changed = new EventEmitter<boolean>();

  oldThirdPartyInfos: ThirdPartySheetInfos = new ThirdPartySheetInfos();
  buttonList: ButtonToLinkTp[] = [];
  buttonListMoral: ButtonToLinkTp[] = [];
  searchTiers: string;
  relations: ButtonToLinkTp[] = [];
  relationsCard: Array<{ id: number, name: string }> = [];
  error: boolean = false;
  phoneConfig: PhoneProfileConfig = new PhoneProfileConfig();
  profileList: Array<PhoneProfile>;
  selectedProfile: number;
  you: User = new User;
  cardName = ""
  firstName: string;
  lastName: string;
  filteredOptions: string[] = [];
  options = [];
  noFocusNumber = true;
  showContent: number = -1;
  changeAllowed = false;
  showTreeCard = true;
  previewACard = 0;
  credentialsAssociated: CredentialsEdit[] = [];
  credentialsNotAssociated: CredentialsEdit[] = [];
  credentialsLoaded = false;
  attachmentsPictures: KanbanAttachment[] = [];
  moralP: MoralPerson[] = [];
  physicalP: PhysicalPerson[] = [];
  brandList: Array<{ id: number, name: string }> = [];
  mails: Mail = new Mail();
  websites: Array<{ id: number, name: string }> = [];
  secondaryNames: Array<{ id: number, name: string }> = [];
  selectedFilterRelation: FilterRelation = null;
  isLoading = false;
  customFields: CustomField[] = [];
  customSystemFields: ThirdPartyCustomSystemField[] = [];
  collapsed = true;
  modifieDesc: boolean = false;
  pageInfo: ThirdPartySheetInfos = new ThirdPartySheetInfos();
  isPp: boolean = true;
  isEditingName = false;
  showArchived = false;
  cardsLoaded = false;
  reloadCards: Subscription;
  avatarUrl = "";

  chooseNumber()
  {

  }

  scrollRight(id)
  {
    var div = document.getElementById(id);
    div.scrollBy({ left: 100, behavior: 'smooth' })
  }

  isMaxScrolledRight(id)
  {
    var div = document.getElementById(id);
    if (div.scrollLeft >= (div.scrollWidth - div.offsetWidth))
      return true;
    return false;
  }

  isMaxScrolledLeft(id)
  {
    var div = document.getElementById(id);
    if (div.scrollLeft <= 0)
      return true;
    return false;
  }

  scrollLeft(id)
  {
    var div = document.getElementById(id);
    div.scrollBy({ left: -100, behavior: 'smooth' })
  }

  menuIsScrolling(id)
  {
    var div = document.getElementById(id);
    return div.scrollWidth > div.clientWidth;
  }

  ngOnInit()
  {
    this.auth.setCamelineTitle("third-party");
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.MOBILE").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.HOME").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.WORK").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.MAIN").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.FAX-WORK").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.FAX-HOME").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.BEEPER").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.OTHER").subscribe(x => this.options.push(x));
    this.filteredOptions = this.options;
    this.filteredMailOptions = this.tpservice.initMailFilteredOption();

    setTimeout(() =>
    {
      if (document.getElementById('invis-scroll').addEventListener)
      {
        // IE9, Chrome, Safari, Opera
        document.getElementById('invis-scroll').addEventListener('mousewheel', function (e) { scrollHorizontally(e, 'invis-scroll') }, false);
        // Firefox
        document.getElementById('invis-scroll').addEventListener('DOMMouseScroll', function (e) { scrollHorizontally(e, 'invis-scroll') }, false);
      }

      if (document.getElementById('relation-scroll').addEventListener)
      {
        // IE9, Chrome, Safari, Opera
        document.getElementById('relation-scroll').addEventListener('mousewheel', function (e) { scrollHorizontally(e, 'relation-scroll') }, false);
        // Firefox
        document.getElementById('relation-scroll').addEventListener('DOMMouseScroll', function (e) { scrollHorizontally(e, 'relation-scroll') }, false);
      }
    }, 1000);


    if (!this.id)
      this.id = this.route.snapshot.paramMap.get('id')
    if (this.id)
    {
      this.changeAllowed = true;
      this.init();
    }

    this.reloadCards = this.kbs.reloadThirdPartyCards.subscribe(to =>
    {
      this.showContent = -1
      setTimeout(() =>
      {
        this.previewACard = to;
        this.cardsLoaded = false;
        this.previewedBoard = null;
        this.previewedList = null;
        this.previewedCard = null;
        this.callService.getThirdPartySheetInfo(this.pageInfo.id + '').subscribe(x =>
        {
          let cardToOpen = this.createBoardFromCards(x.cards);
          this.showContent = 0;
          if (cardToOpen)
            this.onSelection(cardToOpen)

        })
      }, 1000);

    })

    this.subscription = this.kbs.removeCardChange.subscribe(idcard =>
    {
      let fountIt = false;
      this.showContent = -1;
      this.boards.forEach(b =>
      {
        b.lists.forEach(l =>
        {
          let index = l.cards.findIndex(x => x.id == idcard);
          if (index >= 0)
          {
            fountIt = true;
            l.cards.splice(index, 1);
            this.previewedCard = null;
            this.previewedList = null;
            this.previewedBoard = null;
            this.selectedType = "";
          }

        })
      })
      if (!fountIt)
      {
        let index = this.freeCards.findIndex(x => x.id == idcard)
        if (index >= 0)
        {
          this.freeCards.splice(index, 1);
          this.previewedCard = null;
          this.previewedList = null;
          this.previewedBoard = null;
          this.selectedType = "";
        }
      }
      this.showContent = 0;
    })
  }

  sendWhastappMessage(pn: PhoneNumber)
  {
    this.tpservice.sendWhatsappMessage(pn).subscribe(success =>
    {
      let text = "";
      if (success)
      {
        this.translateService.get("WHATSAPP.MESSAGE-SENT").subscribe(x => text = x);
        this.message.success(text);
      }
      else
      {
        this.translateService.get("WHATSAPP.MESSAGE-ERROR").subscribe(x => text = x);
        this.message.error(text);
      }
    });
  }

  selectedSite: Site;
  addSite(site: Site)
  {
    this.pageInfo.sites.push(site);
    this.siteService.addThirdPartyToSite(site.id, [this.pageInfo.id]).subscribe();
    setTimeout(() =>
    {
      this.selectedSite = null;
    }, 200);
  }

  removeSite(site: Site)
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.SITE.REMOVE-ARCHIVE", { v: site.name }).subscribe(x => title = x);
    let content = "";
    this.translateService.get("THIRD-PARTY.SITE.REMOVE-ARCHIVE-CONFIRM", { v: site.name }).subscribe(x => content = x);
    let archive = "";
    this.translateService.get("GENERIC-ACTIONS.ARCHIVE").subscribe(x => archive = x);
    let remove = "";
    this.translateService.get("GENERIC-ACTIONS.REMOVE").subscribe(x => remove = x);
    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: content,
      nzWidth: '500px',
      nzMaskClosable: false,
      nzFooter: [{
        label: archive,
        type: "primary",
        onClick: () =>
        {
          this.siteService.archiveSite(site.id).subscribe(() =>
          {
            let index1 = this.pageInfo.sites.findIndex(x => x.id == site.id);
            this.pageInfo.sites.splice(index1, 1);
            let index2 = this.siteService.siteList.findIndex(x => x.id == site.id);
            this.siteService.siteList.splice(index2, 1);
          });
          this.siteService.removeThirdPartyFromSite(site.id, [this.pageInfo.id]).subscribe();
          modal.close();
        }
      },
      {
        label: remove,
        type: "primary",
        onClick: () => 
        {
          this.siteService.removeThirdPartyFromSite(site.id, [this.pageInfo.id]).subscribe();
          let index1 = this.pageInfo.sites.findIndex(x => x.id == site.id);
          this.pageInfo.sites.splice(index1, 1);
          modal.close();
        }
      },
      {
        label: cancel,
        onClick: () => { modal.close(); }
      }
      ]
    });
  }

  editSite(site: Site)
  {
    let prefiler = "";
    if (this.pageInfo.siret)
      prefiler = this.pageInfo.siret;
    else if (this.pageInfo.siren)
      prefiler = this.pageInfo.siren;
    else if (this.pageInfo.name)
      prefiler = this.pageInfo.name;

    let title = "";
    this.translateService.get("THIRD-PARTY.SITE.UPDATE", { v: site.name }).subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: SiteEditComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          let res = result.submitForm()
          if (!res)
            return;
          site.name = res.name;
          site.address = res.address;
          site.countryCode = res.countryCode;
          this.siteService.updateSite(site).subscribe(x =>
          {
            let siteFromService = this.siteService.siteList.find(x => x.id == site.id);
            if (siteFromService != null)
            {
              siteFromService.name = res.name;
              siteFromService.address = res.address;
              siteFromService.countryCode = res.countryCode;
            }
            site.isUpdated = true;
            setTimeout(() =>
            {
              site.isUpdated = false;
            }, 1);
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });

    let instance = modal.getContentComponent();
    instance.site = site;
    instance.prefilter = prefiler;
  }

  openSiteCreation()
  {
    let prefiler = "";
    if (this.pageInfo.siret)
      prefiler = this.pageInfo.siret;
    else if (this.pageInfo.siren)
      prefiler = this.pageInfo.siren;
    else if (this.pageInfo.name)
      prefiler = this.pageInfo.name;

    let title = "";
    this.translateService.get("THIRD-PARTY.SITE.ADD").subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CREATE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: SiteEditComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          let site = result.submitForm();
          if (!site)
            return;
          this.siteService.createSite(site).subscribe(x =>
          {
            site.id = x;
            site.creatorId = this.auth.connectedUser.id;
            site.creationDate = new Date();
            this.addSite(site);
            this.siteService.siteList.push(site);
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    let ins = modal.getContentComponent();
    ins.site = null;
    ins.prefilter = prefiler;
  }

  saveOldInfos(infos: ThirdPartySheetInfos)
  {
    this.oldThirdPartyInfos.brands = [...infos.brands];
    this.oldThirdPartyInfos.websites = [...infos.websites];
    this.oldThirdPartyInfos.secondaryNames = [...infos.secondaryNames];
    this.oldThirdPartyInfos.phoneNumbers = [];
    this.oldThirdPartyInfos.customFields = [];
    this.oldThirdPartyInfos.customeSystemFields = [];
    this.oldThirdPartyInfos.comment = infos.comment;
    this.oldThirdPartyInfos.name = infos.name;
    this.oldThirdPartyInfos.firstName = infos.firstName;
    this.oldThirdPartyInfos.lastName = infos.lastName;
    this.oldThirdPartyInfos.lastName2 = infos.lastName2;
    this.oldThirdPartyInfos.image = infos.image;
    this.avatarUrl = infos.image;

    for (let cf of infos.customFields)
    {
      let newcf = new CustomField();
      newcf.label = cf.label;
      newcf.value = cf.value;
      this.oldThirdPartyInfos.customFields.push(newcf)
    }

    for (let cf of infos.customeSystemFields)
    {
      let newcf = new ThirdPartyCustomSystemField();
      newcf.name = cf.name;
      newcf.value = cf.value;
      this.oldThirdPartyInfos.customeSystemFields.push(newcf)
    }

    for (let pn of infos.phoneNumbers)
    {
      let newpn = new PhoneNumber();
      newpn.type = pn.type;
      newpn.number = pn.number;
      this.oldThirdPartyInfos.phoneNumbers.push(newpn)
    }

    this.oldThirdPartyInfos.mails = [];
    for (let m of infos.mails)
    {
      let newpn = new Mail();
      newpn.type = m.type;
      newpn.mail = m.mail;
      this.oldThirdPartyInfos.mails.push(newpn)
    }
  }

  updateOldInfos(infos: ThirdPartySheetUpdateInfos)
  {
    this.oldThirdPartyInfos.brands = [...infos.brands];
    this.oldThirdPartyInfos.websites = [...infos.websites];
    this.oldThirdPartyInfos.secondaryNames = [...infos.secondaryNames];
    this.oldThirdPartyInfos.phoneNumbers = [];
    this.oldThirdPartyInfos.customFields = [];
    this.oldThirdPartyInfos.customeSystemFields = [];
    this.oldThirdPartyInfos.comment = infos.comment;
    this.oldThirdPartyInfos.name = infos.name;
    this.oldThirdPartyInfos.firstName = infos.firstName;
    this.oldThirdPartyInfos.lastName = infos.lastName;
    this.oldThirdPartyInfos.lastName2 = infos.lastName2;
    this.oldThirdPartyInfos.image = infos.image;

    for (let cf of infos.customFields)
    {
      let newcf = new CustomField();
      newcf.label = cf.label;
      newcf.value = cf.value;
      this.oldThirdPartyInfos.customFields.push(newcf)
    }

    for (let cf of infos.customeSystemFields)
    {
      let newcf = new ThirdPartyCustomSystemField();
      newcf.name = cf.name;
      newcf.value = cf.value;
      this.oldThirdPartyInfos.customeSystemFields.push(newcf)
    }

    for (let pn of infos.phoneNumbers)
    {
      let newpn = new PhoneNumber();
      newpn.type = pn.type;
      newpn.number = pn.number;
      this.oldThirdPartyInfos.phoneNumbers.push(newpn)
    }

    this.oldThirdPartyInfos.mails = [];
    for (let m of infos.mails)
    {
      let newpn = new Mail();
      newpn.type = m.type;
      newpn.mail = m.mail;
      this.oldThirdPartyInfos.mails.push(newpn)
    }
  }

  infosHasChanged()
  {
    //On vérifie si toutes les listes actuelles ont la même taille qu'elles avaient au début
    if (this.brandList.length != this.oldThirdPartyInfos.brands.length
      || this.pageInfo.phoneNumbers.length != this.oldThirdPartyInfos.phoneNumbers.length
      || this.pageInfo.mails.length != this.oldThirdPartyInfos.mails.length
      || this.websites.length != this.oldThirdPartyInfos.websites.length
      || this.secondaryNames.length != this.oldThirdPartyInfos.secondaryNames.length
      || this.pageInfo.comment != this.oldThirdPartyInfos.comment
      || this.pageInfo.name != this.oldThirdPartyInfos.name
      || this.pageInfo.firstName != this.oldThirdPartyInfos.firstName
      || this.pageInfo.lastName != this.oldThirdPartyInfos.lastName
      || this.pageInfo.lastName2 != this.oldThirdPartyInfos.lastName2
      || this.avatarUrl != this.oldThirdPartyInfos.image
      || this.customFields.length != this.oldThirdPartyInfos.customFields.length)
    {
      this.changed.emit(true);
      return true;
    }


    //On vérifie si toutes les marques sont identiques
    for (let i = 0; i < this.brandList.length; ++i)
    {
      if (this.brandList[i].name !== this.oldThirdPartyInfos.brands[i])
      {
        this.changed.emit(true);
        return true;
      }
    }

    //On vérifie si toutes les numéros de téléphone sont identiques
    for (let i = 0; i < this.pageInfo.phoneNumbers.length; ++i)
    {
      if (this.pageInfo.phoneNumbers[i].type !== this.oldThirdPartyInfos.phoneNumbers[i].type || this.pageInfo.phoneNumbers[i].number !== this.oldThirdPartyInfos.phoneNumbers[i].number)
      {
        this.changed.emit(true);
        return true;
      }
    }

    //On vérifie si toutes les mails sont identiques
    for (let i = 0; i < this.pageInfo.mails.length; ++i)
    {
      if (this.pageInfo.mails[i].type !== this.oldThirdPartyInfos.mails[i].type || this.pageInfo.mails[i].mail !== this.oldThirdPartyInfos.mails[i].mail)
      {
        this.changed.emit(true);
        return true;
      }
    }

    //On vérifie si toutes les champs perso sont identiques
    for (let i = 0; i < this.customFields.length; ++i)
    {
      if (this.customFields[i].label !== this.oldThirdPartyInfos.customFields[i].label || this.customFields[i].value !== this.oldThirdPartyInfos.customFields[i].value)
      {
        this.changed.emit(true);
        return true;
      }
    }

    //On vérifie si toutes les champs system sont identiques
    for (let i = 0; i < this.customSystemFields.length; ++i)
    {
      if (this.customSystemFields[i].value !== this.oldThirdPartyInfos.customeSystemFields[i].value)
      {
        this.changed.emit(true);
        return true;
      }
    }

    //On vérifie si toutes les sites web sont identiques
    for (let i = 0; i < this.websites.length; ++i)
    {
      if (this.websites[i].name !== this.oldThirdPartyInfos.websites[i])
      {
        this.changed.emit(true);
        return true;
      }
    }

    //On vérifie si toutes les pseudonymes sont identiques
    for (let i = 0; i < this.secondaryNames.length; ++i)
    {
      if (this.secondaryNames[i].name !== this.oldThirdPartyInfos.secondaryNames[i])
      {
        this.changed.emit(true);
        return true;
      }
    }

    this.changed.emit(false);
    return false;
  }

  search()
  {
    this.tpservice.searchThirdParty(this.searchTiers, 0).subscribe(data =>
    {
      this.moralP = data.moralPersons
      this.physicalP = data.physicalPersons
    })
  }

  openFicheTiers(id: string, isPp: boolean)
  {
    this.isPp = isPp;
    this.changeAllowed = true
    this.redirectTo('/call-interface/third-party-sheet/' + id);
  }

  redirectTo(uri: string)
  {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  moralPerson: MoralPerson = null;
  selectedSecondarySite: MoralPerson = null;

  getPotentialSecondarySit()
  {
    return this.cds.moralPersonCollection.filter(x => !x.archived && !x.parentSiteId && x.id != this.moralPerson.id && x.secondarySites.length == 0 && this.moralPerson.secondarySites.findIndex(y => y && y.id == x.id) < 0).sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)
  }

  addSecondarySites(mp: MoralPerson)
  {
    let themp = this.cds.moralPersonCollection.find(x => x.id == this.moralPerson.id);
    if (themp)
    {
      themp.secondarySites.push(mp);
      let index2 = this.cds.moralPersonCollection.findIndex(x => x.id == mp.id);
      this.cds.moralPersonCollection.splice(index2, 1);
      mp.parentSiteId = themp.id;
    }

    this.mpService.updateMoralPersonSecondarySites(this.moralPerson).subscribe(() => this.selectedSecondarySite = null);
  }

  createSecondarySite()
  {
    let modal = this.mms.createMPFull(this.moralPerson, null);
    // modal.afterClose.subscribe((result) =>
    // {
    //   if (modal.getContentComponent().moralPerson)
    //   {
    //     this.moralPerson.secondarySites.push(modal.getContentComponent().moralPerson)
    //     this.mpService.updateMoralPersonSecondarySites(this.moralPerson).subscribe();
    //   }
    // });
  }

  editThirdParty(idtp: number)
  {
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: ThirdPartySheetComponent,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: close,
        danger: true,
        onClick: (result) =>
        {
          if (result.infosHasChanged())
          {
            this.translateService.get("THIRD-PARTY.CALL.THIRD-PARTY-SHEET.ERROR-ONGOING-MODIFICATION").subscribe(x => this.message.error(x, { nzDuration: 4000 }))
            return;
          }
          modal.close()
        }
      }]
    });
    let ins = modal.getContentComponent();
    ins.id = idtp.toString();
  }

  removeSecondarySite(mp: MoralPerson)
  {
    let themp = this.cds.moralPersonCollection.find(x => x.id == this.moralPerson.id);
    if (themp)
    {
      let index2 = themp.secondarySites.findIndex(x => x.id == mp.id);
      themp.secondarySites.splice(index2, 1);
      this.cds.moralPersonCollection.push(mp);
      mp.parentSiteId = null;
    }
    this.mpService.updateMoralPersonSecondarySites(this.moralPerson).subscribe();
  }

  updateSecondarySite(ss: MoralPerson[])
  {
    this.moralPerson.secondarySites = ss;
    this.mpService.updateMoralPersonSecondarySites(this.moralPerson).subscribe();
  }

  init()
  {
    this.you = this.cds.userCollection.find(usr => usr.id == this.auth.Access.idUser);

    this.profileService.getProfileAssociatedToUser(this.you.id).subscribe(
      data =>
      {
        this.profileList = data;
        let prof = this.profileList.find(x => x.active);
        if (prof)
        {
          this.selectedProfile = prof.id;
          this.phoneConfig = prof.config
        }
        else if (this.profileList.length > 0)
        {
          this.selectedProfile = this.profileList[0].id
          this.phoneConfig = this.profileList[0].config
        }

        let i = 0;
        for (let pp of this.tpservice.infoTPsByPhoneNumber.physicalPersons)
        {
          i = this.createButtonTp(i, pp.id, pp.firstName + " " + pp.lastName)
        }
        let e = 0
        for (let mp of this.tpservice.infoTPsByPhoneNumber.moralPersons)
        {
          e = this.createButtonTpMoral(e, mp.id, mp.name)
        }
      }
    )
    this.initData();
  }

  initData()
  {
    this.showContent = -1;
    this.callService.getThirdPartySheetInfo(this.id).subscribe(data =>
    {
      this.pageInfo = data;
      this.isPp = this.pageInfo.type == 1;
      if (!this.isPp)
      {
        this.moralPerson = this.cds.moralPersonCollection.find(x => x.id == this.pageInfo.id);
      }
      this.saveOldInfos(data);
      this.previewedCard = null;
      this.selectedType = "";
      this.allCards = data.cards;
      this.customFields = data.customFields;
      this.customSystemFields = data.customeSystemFields;
      this.boards = [];
      this.websites = [];
      this.secondaryNames = [];
      this.brandList = [];
      data.websites.forEach(w =>
      {
        this.websites.push({ id: 0, name: w })
      })
      data.secondaryNames.forEach(w =>
      {
        this.secondaryNames.push({ id: 0, name: w })
      })
      data.brands.forEach(w =>
      {
        this.brandList.push({ id: 0, name: w })
      })
      let cardToOpen = this.createBoardFromCards(data.cards);
      //this.initArchivesTree();
      this.showContent = 0;
      if (cardToOpen)
        this.onSelection(cardToOpen);

      this.tpservice.getPotentialsRelationsForThirdParty(this.pageInfo.id).subscribe(x =>
      {
      });
    })
  }

  changeArchiveMode(showcard)
  {
    this.cardsLoaded = false;
    this.previewedBoard = null;
    this.previewedList = null;
    this.previewedCard = null;
    if (showcard)
    {
      this.callService.getThirdPartyCards(this.pageInfo.id).subscribe(x =>
      {
        this.createBoardFromCards(x);
      })
    }
    else
    {
      this.createBoardFromCards(this.pageInfo.cards);
    }
  }

  createBoardFromCards(cards: KanbanCard[])
  {
    this.freeCards = [];
    this.boards = [];
    let cardToOpen: KanbanTreeSelectedInfos = null;
    for (let card of cards)
    {
      let board = this.boards.find(b => b.id == card.boardId);
      if (board)
      {
        let list = board.lists.find(l => l.id == card.listId);
        if (list)
        {
          if (this.previewACard == card.id)
          {
            this.previewedBoard = board;
            this.previewedList = list;
            this.previewedCard = card;
            cardToOpen = new KanbanTreeSelectedInfos(board, list, card, "card");
          }
          list.cards.push(card);
        }
        else if (card.listId)
        {
          list = new KanbanList();
          board.lists.push(list);
          list.id = card.listId;
          list.title = card.listName;
          list.cards.push(card);

          if (this.previewACard == card.id)
          {
            this.previewedBoard = board;
            this.previewedList = list;
            this.previewedCard = card;
            cardToOpen = new KanbanTreeSelectedInfos(board, list, card, "card");
          }
        }
      }
      else if (card.boardId)
      {
        board = new KanbanBoard();
        board.id = card.boardId;
        board.title = card.boardName;
        board.bgColor = card.bgColor;
        this.boards.push(board);

        let list = new KanbanList();
        board.lists.push(list);
        list.id = card.listId;
        list.title = card.listName;
        list.cards.push(card);

        if (this.previewACard == card.id)
        {
          this.previewedBoard = board;
          this.previewedList = list;
          this.previewedCard = card;
          cardToOpen = new KanbanTreeSelectedInfos(board, list, card, "card");
        }
      }
      else
      {
        if (this.previewACard == card.id)
        {
          this.previewedBoard = null;
          this.previewedList = null;
          this.previewedCard = card;
          cardToOpen = new KanbanTreeSelectedInfos(null, null, card, "card");
        }
        this.freeCards.push(card);
      }
    }
    setTimeout(() =>
    {
      this.cardsLoaded = true;
    }, 500);
    return cardToOpen;
  }

  createCard(listId: number)
  {
    let newCard: KanbanCardWithThirdParties = new KanbanCardWithThirdParties()
    newCard.cardTitle = this.cardName

    if (listId != -1)
      newCard.listId = listId

    if (this.cardName)
    {
      this.relationsCard.push({ id: this.pageInfo.id, name: this.pageInfo.name ? this.pageInfo.name : this.pageInfo.firstName + " " + this.pageInfo.lastName })
      newCard.thirdPartyIdsToAffect = this.relationsCard.map(x => x.id)
      this.callService.creatCardWithThirdParty(newCard).subscribe(
        x =>
        {
          let card = new KanbanCard();
          card.id = x;
          card.title = newCard.cardTitle;
          let you = this.cds.userCollection.find(usr => usr.id == this.auth.Access.idUser);
          newCard.thirdPartyIdsToAffect.forEach(tp =>
          {
            let pp = this.cds.physicalPersonCollection.find(phy => phy.id == tp)
            if (pp)
              card.physicalPersons.push(pp);
            else
            {
              let mp = this.cds.moralPersonCollection.find(mhp => mhp.id == tp)
              if (mp)
                card.moralPersons.push(mp)
            }
          })
          if (this.phoneConfig.addCardCreatorAsMember == true)
            card.members.push(you.id)
          // if (this.phoneConfig.openCardOnCreation)
          //   this.editTask(card);
          this.message.create("success", this.translater("THIRD-PARTY.CALL.CARD-CREATED"))
          this.relationsCard = [];
          this.previewACard = card.id;
          this.initData();
        },
        (error) =>
        {
          this.message.create("error", this.translater("THIRD-PARTY.CALL.ERROR"))
          console.log(error);
        },
      )
    }
    else
    {
      this.message.create("error", this.translater("THIRD-PARTY.CALL.MISSING-CARD-NAME"));
    }
    this.cardName = ""
  }

  createButtonTp(i, ppId, ppName)
  {
    if (this.phoneConfig.relationButtonPP1.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonPP1.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonPP1.name
      newButton.id = i;
      newButton.thirdPartyId = ppId;
      newButton.thirdPartyName = ppName;
      i++;
      this.buttonList.push(newButton);
    }
    if (this.phoneConfig.relationButtonPP2.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonPP2.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonPP2.name
      newButton.id = i;
      newButton.thirdPartyId = ppId;
      newButton.thirdPartyName = ppName;
      i++;
      this.buttonList.push(newButton);
    }
    if (this.phoneConfig.relationButtonPP3.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonPP3.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonPP3.name
      newButton.id = i;
      newButton.thirdPartyId = ppId;
      newButton.thirdPartyName = ppName;
      i++;
      this.buttonList.push(newButton);
    }
    if (this.phoneConfig.relationButtonPP4.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonPP4.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonPP4.name
      newButton.id = i;
      newButton.thirdPartyId = ppId;
      newButton.thirdPartyName = ppName;
      i++;
      this.buttonList.push(newButton);
    }
    return i;
  }

  createButtonTpMoral(i, mpId, mpName)
  {
    if (this.phoneConfig.relationButtonMP1.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonMP1.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonMP1.name
      newButton.id = i;
      newButton.thirdPartyId = mpId;
      newButton.thirdPartyName = mpName;
      i++;
      this.buttonListMoral.push(newButton);
    }
    if (this.phoneConfig.relationButtonMP2.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonMP2.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonMP2.name
      newButton.id = i;
      newButton.thirdPartyId = mpId;
      newButton.thirdPartyName = mpName;
      i++;
      this.buttonListMoral.push(newButton);
    }
    if (this.phoneConfig.relationButtonMP3.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonMP3.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonMP3.name
      newButton.id = i;
      newButton.thirdPartyId = mpId;
      newButton.thirdPartyName = mpName;
      i++;
      this.buttonListMoral.push(newButton);
    }
    if (this.phoneConfig.relationButtonMP4.show)
    {
      let newButton = new ButtonToLinkTp();
      newButton.typeRelationId = this.phoneConfig.relationButtonMP4.typeRelationId
      newButton.typeRelationName = this.phoneConfig.relationButtonMP4.name
      newButton.id = i;
      newButton.thirdPartyId = mpId;
      newButton.thirdPartyName = mpName;
      i++;
      this.buttonListMoral.push(newButton);
    }
    return i;
  }

  editTask(task: KanbanCard)
  {
    if (task.id == 0)
      return;
    this.kbs.editedCard = task;

    let title = "";
    this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanCardEditModalComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalEdit.close(); }
      }]
    });

    let ins = this.kbs.modalEdit.getContentComponent();
    ins.card = task;
    ins.list = null;
    ins.board = null;

    this.kbs.modalEdit.afterClose.subscribe((result) =>
    {

      this.kbs.editedCard = null;
      this.kts.resetEditModalField();
    });
  }

  deletePhoneNumber()
  {

  }

  startTheCall(number: string)
  {
    var link = document.createElement('a');
    link.href = "sip:" + number;
    link.click();
  }

  addPhoneNumber()
  {
    let phoneNumber: PhoneNumber = new PhoneNumber();
    this.pageInfo.phoneNumbers.push(phoneNumber)

  }

  removePhoneNumber(pn: PhoneNumber)
  {
    let index = this.pageInfo.phoneNumbers.findIndex(x => x.id == pn.id);
    this.pageInfo.phoneNumbers.splice(index, 1);

  }

  addBrand()
  {
    let ind = this.brandList.length;
    this.brandList.push({ id: ind, name: "" })
  }

  removeBrand(ind: number)
  {
    this.brandList.splice(ind, 1);
  }

  addMail()
  {
    let mail = new Mail();
    mail.thirdPartyId = this.pageInfo.id;
    this.pageInfo.mails.push(mail);
  }

  removeMail(mail: Mail)
  {
    let index = this.pageInfo.mails.findIndex(x => x.mail == mail.mail);
    this.pageInfo.mails.splice(index, 1);
  }

  sendTheMail(mail: string)
  {
    var link = document.createElement('a');
    link.href = "mailto:" + mail;
    link.click();
  }

  updateThirdPartyCredentials(creds: CredentialsEdit[])
  {
    this.tpservice.updateThirdPartyCredential(this.pageInfo.id, creds).subscribe();
  }

  addSecondaryName()
  {
    let ind = this.secondaryNames.length;
    this.secondaryNames.push({ id: ind, name: "" })
  }

  removeSecondaryName(ind: number)
  {
    this.secondaryNames.splice(ind, 1);
  }

  addWebsite()
  {
    let ind = this.websites.length;
    this.websites.push({ id: ind, name: "" })
  }

  removeWebSite(ind: number)
  {
    this.websites.splice(ind, 1);
  }

  gotoSite(ws: string)
  {
    if (!ws.startsWith("https"))
      window.open("https://" + ws, "_blank");
    else window.open(ws, "_blank");

  }
  filteredMailOptions: string[] = [];

  onInputNumber(value: string): void
  {
    this.filteredOptions = this.options.filter(option => this.tcs.contains(option, value));
  }

  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }

  // board start

  archivesNodes: any[] = [];
  selectedId = 0;
  selectedType = "";
  filterValue = "";
  previewedBoard: KanbanBoard = null;
  previewedList: KanbanList = null;
  previewedCard: KanbanCard = null;
  dataIsLoading = false;
  allchecked = false;
  labelSelectorVisible = false;
  boards: KanbanBoard[] = [];
  allCards: KanbanCard[] = [];
  freeCards: KanbanCard[] = [];
  subscription: Subscription;
  @ViewChild('nzTreeComponent', { static: false }) nzTreeComponent!: NzTreeComponent;

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  getFontColor(color: string)
  {
    return this.cs.getFontColor(color);
  }

  onSelection(data: KanbanTreeSelectedInfos)
  {
    if (!data.card)
      return;
    this.previewedCard = null;
    this.dataIsLoading = true;
    this.selectedId = data.card.id;


    this.kbs.getCardInfos(this.selectedId).subscribe(card =>
    {
      this.kbs.editedCard = card;
      this.dataIsLoading = false;
      this.previewedList = data.list;
      this.previewedBoard = data.board;
      this.previewedCard = card;
      this.previewedCard.visible = true;
      this.selectedType = "card";
      this.showTreeCard = false;
    })
  }

  loadCards()
  {
    this.showContent = 0
  }

  loadCredential()
  {
    this.showContent = 4
    this.tpservice.getCredentialsForThirdParty(this.pageInfo.id).subscribe(x =>
    {
      this.credentialsAssociated = x.credentialsAssociated;
      this.credentialsNotAssociated = x.credentialsNotAssociated;
      this.credentialsLoaded = true;
    });
  }

  loadActivities()
  {
    this.tpservice.getThirdPartyActivities(this.pageInfo.id).subscribe(x =>
    {
      this.showContent = 8;
    })
  }

  loadSites()
  {
    this.siteService.getAllSites().subscribe(() =>
    {
      this.tpservice.getThirdPartySites(this.pageInfo.id).subscribe(x =>
      {
        this.pageInfo.sites = x;
        this.showContent = 7;
      })
    });

  }

  donothing(osef)
  {

  }

  collapse(): string
  {
    if (this.collapsed)
    {
      return ("-webkit-box")
    }
    else
      return ("block");
  }

  directionArrow = "down";
  getDirection()
  {
    if (this.collapsed)
    {
      this.collapsed = false
      this.directionArrow = "up"
      return
    }
    this.collapsed = true
    this.directionArrow = "down"
  }

  @ViewChild('logoHandler', { static: false }) logoHandler?: TemplateRef<{}>;

  async saveThirdParty()
  {
    for (let cs of this.customSystemFields.filter(x => x.mandatory))
    {
      if (cs.value == "")
        return null;
    }
    let updateinfos = new ThirdPartySheetUpdateInfos();
    updateinfos.idtp = this.pageInfo.id;
    updateinfos.comment = this.pageInfo.comment;
    updateinfos.name = this.pageInfo.name;
    updateinfos.firstName = this.pageInfo.firstName;
    updateinfos.lastName = this.pageInfo.lastName;
    updateinfos.lastName2 = this.pageInfo.lastName2;
    updateinfos.phoneNumbers = this.pageInfo.phoneNumbers;
    updateinfos.mails = this.pageInfo.mails;
    updateinfos.customFields = this.customFields;
    updateinfos.customeSystemFields = this.customSystemFields;
    updateinfos.websites = this.websites.map(x => x.name);
    updateinfos.secondaryNames = this.secondaryNames.map(x => x.name);
    updateinfos.brands = this.brandList.map(x => x.name);
    updateinfos.image = this.avatarUrl;

    if (updateinfos.websites.length > 0 && !updateinfos.image)
    {
      let close = "";
      this.translateService.get("THIRD-PARTY.LOGO.CONTINUE").subscribe(x => close = x);
      //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
      let modal = this.modalService.create({
        nzTitle: null,
        nzContent: this.logoHandler,
        nzBodyStyle: { backgroundColor: '#f0f2f5' },
        nzWidth: '700px',
        nzMaskClosable: false,
        nzClosable: false,
        nzFooter: [{
          label: close,
          danger: true,
          onClick: () =>
          {
            if (this.avatarUrl)
              updateinfos.image = this.avatarUrl;
            this.callService.updateThirdPartySheetInfo(this.pageInfo.id, updateinfos).subscribe(() =>
            {
              this.updateOldInfos(updateinfos);
              this.isEditingName = false;
              modal.close()
              this.message.create("success", this.translater("GENERIC-ACTIONS.MODIFICATIONS-SAVED"))
            })

          }
        }]
      });
      return;
    }

    this.callService.updateThirdPartySheetInfo(this.pageInfo.id, updateinfos).subscribe(() =>
    {
      this.updateOldInfos(updateinfos);
      this.isEditingName = false;
      this.message.create("success", this.translater("GENERIC-ACTIONS.MODIFICATIONS-SAVED"))
    })
  }

  async setLogoAsAvatar(site: string)
  {
    try
    {
      let logo = await this.getLogo(site);
      if (logo)
      {
        this.avatarUrl = "data:image/png;base64, " + logo;
        this.message.create("success", this.translater("THIRD-PARTY.LOGO.DONE"))
      }
      else
      {
        this.message.error(this.translater("THIRD-PARTY.LOGO.NOT-FOUND"))
      }

    }
    catch (e) { }
  }

  async setLogoAsImage(ws: string)
  {
    let logo = await firstValueFrom(this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/external/register-logo`, { thirdPartyId: this.pageInfo.id, site: this.getLogoDomain(ws) }));
    if (logo)
      this.message.create("success", this.translater("THIRD-PARTY.LOGO.DONE"))
    else this.message.error(this.translater("THIRD-PARTY.LOGO.NOT-FOUND"))
  }

  async setLogoAsAvatarAndImage(ws: string)
  {
    let logo = await firstValueFrom(this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/external/register-logo`, { thirdPartyId: this.pageInfo.id, site: this.getLogoDomain(ws) }));;
    if (logo)
    {
      this.avatarUrl = "data:image/png;base64, " + logo
      this.message.create("success", this.translater("THIRD-PARTY.LOGO.DONE"))
    }
    else 
    {
      this.message.error(this.translater("THIRD-PARTY.LOGO.NOT-FOUND"))
    }

  }

  async getLogo(mail: string)
  {
    let ttemp = new TextSearch(this.getLogoDomain(mail));
    return await firstValueFrom(this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/external/logo-clearbit`, ttemp));
  }

  getLogoDomain(url: string)
  {
    let domain = (new URL(url));
    return "https://logo.clearbit.com/" + domain.hostname.replace('www.', '');
  }

  avatarFile;
  changeAvatar(info)
  {
    if (info.file.status === 'uploading')
    {
      return;
    }
    if (info.file.status === 'done')
    {
      if (info.file.size > 500000)
      {
        this.createBasicNotification();
        return;
      }
      // Get this url from response in real world.
      this.avatarFile = info.file.originFileObj;
      this.getBase64(info.file.originFileObj, (img: any) =>
      {
        this.avatarUrl = img;
      });
    }
  }

  createBasicNotification(): void
  {
    let errorMessage;
    this.translateService.get("USER.ERROR.INCORRECT-AVATAR-SIZE").subscribe(x => errorMessage = x)
    this.message
      .create("error",
        errorMessage
      )
  }
  private getBase64(img: File, callback: (img: {}) => void): void
  {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  openEditProfile()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.CALL.PHONE-MENU.PROFILE").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);

    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: ThirdPartySheetEditIdentityComponent,
      nzWidth: '80%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzFooter: [{
        label: save,
        type: 'primary',
        onClick: (result) =>
        {
          let updateinfos = new ThirdPartySheetUpdateInfos();
          updateinfos.idtp = this.pageInfo.id;
          updateinfos.name = result.mp_name;
          updateinfos.firstName = result.pp_firstName;
          updateinfos.lastName = result.pp_lastName;
          updateinfos.lastName2 = result.pp_lastName2;
          updateinfos.gender = result.pp_gender;
          updateinfos.spokenLanguages = result.pp_spokenLanguage;
          updateinfos.mainCurrency = result.mp_mainCurrency;
          updateinfos.enseigne = result.mp_enseigne;
          updateinfos.sigle = result.mp_sigle;
          updateinfos.codeTVA = result.mp_codeTVA;
          updateinfos.codeAPE = result.mp_codeAPE;
          updateinfos.nationality = result.tp_nationality;
          updateinfos.anniversaryDate = result.tp_anniversaryDate;
          updateinfos.siret = result.tp_siret;
          updateinfos.siren = result.tp_siren;
          updateinfos.comment = result.tp_comment;
          updateinfos.labels = result.tp_labels;
          updateinfos.companyid = result.pp_companyid;
          updateinfos.department = result.pp_department;
          updateinfos.job = result.pp_job;
          this.callService.updateThirdPartySheetInfoIdentity(this.pageInfo.id, updateinfos).subscribe(() =>
          {
            this.pageInfo.name = result.mp_name;
            this.pageInfo.firstName = result.pp_firstName;
            this.pageInfo.lastName = result.pp_lastName;
            this.pageInfo.lastName2 = result.pp_lastName2;
            this.pageInfo.gender = result.pp_gender;
            this.pageInfo.spokenLanguages = result.pp_spokenLanguage;
            this.pageInfo.mainCurrency = result.mp_mainCurrency;
            this.pageInfo.enseigne = result.mp_enseigne;
            this.pageInfo.sigle = result.mp_sigle;
            this.pageInfo.codeTVA = result.mp_codeTVA;
            this.pageInfo.codeAPE = result.mp_codeAPE;
            this.pageInfo.nationality = result.tp_nationality;
            this.pageInfo.anniversaryDate = result.tp_anniversaryDate;
            this.pageInfo.siret = result.tp_siret;
            this.pageInfo.siren = result.tp_siren;
            this.pageInfo.comment = result.tp_comment;
            this.pageInfo.labels = result.tp_labels;
            this.pageInfo.companyId = result.pp_companyid;
            this.pageInfo.department = result.pp_department;
            this.pageInfo.job = result.pp_job;

            this.oldThirdPartyInfos.comment = updateinfos.comment;
            this.oldThirdPartyInfos.name = updateinfos.name;
            this.oldThirdPartyInfos.firstName = updateinfos.firstName;
            this.oldThirdPartyInfos.lastName = updateinfos.lastName;

            modal.close();
          })
        }
      },
      {
        label: close,
        onClick: () => { modal.close(); }
      }]
    });

    let ins = modal.getContentComponent();
    ins.tp = this.pageInfo;
  }

  removeTpImage()
  {
    this.avatarUrl = "";
    this.avatarService.avatarFile = null;
    // this.callService.removeThirdPartyAvatar(this.pageInfo.id).subscribe(() =>
    // {

    // })
  }

  getPotentialSites()
  {
    return this.siteService.siteList.filter(x => this.pageInfo.sites.findIndex(y => x.id == y.id) < 0).sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);
  }

  getNbLine()
  {
    if (!this.pageInfo.comment)
      return false;
    let lines = this.pageInfo.comment.split('\n');
    let nbLines = lines.length;
    if (nbLines > 3)
      return true;
    return false;
  }

  undoChanges()
  {
    this.brandList = [];
    this.oldThirdPartyInfos.brands.forEach(x =>
    {
      this.brandList.push({ id: 0, name: x })
    })

    this.pageInfo.comment = this.oldThirdPartyInfos.comment
    this.pageInfo.name = this.oldThirdPartyInfos.name
    this.pageInfo.firstName = this.oldThirdPartyInfos.firstName
    this.pageInfo.lastName = this.oldThirdPartyInfos.lastName
    this.pageInfo.lastName2 = this.oldThirdPartyInfos.lastName2

    this.pageInfo.phoneNumbers = [];
    this.oldThirdPartyInfos.phoneNumbers.forEach(x =>
    {
      let pn = new PhoneNumber();
      pn.id = x.id;
      pn.type = x.type;
      pn.number = x.number;
      this.pageInfo.phoneNumbers.push(pn);
    })

    this.pageInfo.mails = [];
    this.oldThirdPartyInfos.mails.forEach(x =>
    {
      let mail = new Mail();
      mail.id = x.id;
      mail.type = x.type;
      mail.mail = x.mail;
      this.pageInfo.mails.push(mail);
    })

    this.websites = [];
    this.oldThirdPartyInfos.websites.forEach(x =>
    {
      this.websites.push({ id: 0, name: x })
    })

    this.secondaryNames = [];
    this.oldThirdPartyInfos.secondaryNames.forEach(x =>
    {
      this.secondaryNames.push({ id: 0, name: x })
    })

    this.customFields = [];
    this.oldThirdPartyInfos.customFields.forEach(x =>
    {
      let cs = new CustomField();
      cs.id = x.id;
      cs.label = x.label;
      cs.value = x.value;
      this.customFields.push(cs);
    })

    this.customSystemFields = [];
    this.oldThirdPartyInfos.customeSystemFields.forEach(x =>
    {
      let cs = new ThirdPartyCustomSystemField();
      cs.id = x.id;
      cs.name = x.name;
      cs.value = x.value;
      this.customSystemFields.push(cs);
    })

    this.isEditingName = false;
    return false;
  }

  loadFilterTab(filter: FilterRelation)
  {
    this.isLoading = true;
    this.showContent = 5;
    this.selectedFilterRelation = filter;
    setTimeout(() =>
    {
      this.isLoading = false;
    }, 200);

  }

  getRelationCount(filter: FilterRelation)
  {
    if (!filter)
      return this.tpservice.potentialRelationsInfos.relationList.length;
    let filterParams = filter.filterCode.split(",");
    let idtype = parseInt(filterParams[2]);
    let direction = filterParams.length >= 4 ? filterParams[3] : "base";
    let count = 0;
    try
    {
      count = this.tpservice.potentialRelationsInfos.relationList.filter(rel => (!idtype || idtype == rel.idTypeRelation) && (direction == "base" || (direction == "source" && this.pageInfo.id == rel.idSource) || (direction == "target" && this.pageInfo.id == rel.idTarget))).length;
    }
    catch (e) { }
    return count;
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (user)
      return user.avatar;
    return "";
  }

  setFocus(id)
  {
    this.isEditingName = true;
    setTimeout(() =>
    {
      var firstNameInput = document.getElementById(id);
      firstNameInput.focus();
    }, 100);
  }

  setDescriptionFocus(id)
  {
    this.modifieDesc = true;
    setTimeout(() =>
    {
      var firstNameInput = document.getElementById(id);
      firstNameInput.focus();
    }, 100);
  }

  isFocusingInputName()
  {
    setTimeout(() =>
    {
      if (this.isPp)
      {
        var firstNameInput = document.getElementById('edit-firstname-input');
        var lastNameInput = document.getElementById('edit-lastname-input');
        this.isEditingName = (document.activeElement === firstNameInput || document.activeElement === lastNameInput);
      }
      else 
      {
        var nameInput = document.getElementById('edit-namemp-input');
        this.isEditingName = (document.activeElement === firstNameInput || document.activeElement === nameInput);
      }
    }, 100);

  }

  removeFocusDescriptionInput()
  {
    setTimeout(() =>
    {
      this.modifieDesc = false;
    }, 100);
  }

  addCustomField()
  {
    this.noFocusNumber = false;
    let customField = new CustomField();
    let idTemp = -1;
    while (this.customFields.findIndex(x => x.id == idTemp) >= 0)
    {
      idTemp--;
    }
    customField.id = idTemp;
    this.customFields.push(customField)
  }

  removeCustomField(cf: CustomField)
  {
    let index = this.customFields.findIndex(x => x.id == cf.id);
    this.customFields.splice(index, 1);
  }

  getContent(event)
  {
    this.pageInfo.comment = event.path[0].innerText
  }

  saveDesc()
  {


    // this.ppService.updatePhysicalPerson(, null);
  }



  downloadLogo(mail: string)
  {
    let ttemp = new TextSearch(this.getLogoDomain(mail));
    this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/external/logo-clearbit`, ttemp).subscribe(x =>
    {
      var byte = this.base64ToArrayBuffer(x);
      var blob = new Blob([byte], { type: "application/png" });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = "logo.png";
      link.download = fileName;
      link.click();

    });
  }



  base64ToArrayBuffer(base64)
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  downloadImage(url: string)
  {
    this.http.get(this.getLogoDomain(url), { responseType: 'blob' }).subscribe(blob =>
    {
      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'CoverImage.jpg'; // Set the download filename

      // Append link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, error =>
    {
      console.error('Error downloading the image: ', error);
    });
  }

}