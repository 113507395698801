import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoomMeetingSchedulerComponent } from './zoom-meeting-scheduler.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NameTeamPipeModule } from 'src/app/pipes/name-team-pipe/name-team-pipe.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AvatarUserModule } from 'src/app/pipes/avatar-user/avatar-user.module';
import { ThirdPartyNamePipeModule } from 'src/app/pipes/third-party/third-party-name-pipe/third-party-name-pipe.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzButtonModule,
    NzGridModule,
    NzSelectModule,
    NameUserPipeModule,
    AvatarUserModule,
    ThirdPartyNamePipeModule,
    NzAvatarModule,
    NameTeamPipeModule,
    NzPopoverModule
  ],
  declarations: [ZoomMeetingSchedulerComponent]
})
export class ZoomMeetingSchedulerModule { }
