// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boardList-ticket-title {
  color: black;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  margin-left: 40px;
  padding: 9px 15px;
  padding-left: 15px;
  transition: padding-left 0.1s linear;
  word-break: break-all;
}

.boardList-ticket {
  width: 90%;
  border-radius: 3px;
  margin: 0px 0px 6px 0px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  vertical-align: top !important;
}

.darken {
  width: 100%;
  border-radius: 3px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}

.darken:hover {
  background-color: rgba(255, 255, 255, 0);
}

.selected-node {
  background-color: lightgray;
}

.move-button {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/kanban/components/kanban-board-tree/kanban-board-tree.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,0CAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,oCAAA;EACA,qBAAA;AACJ;;AAEE;EACE,UAAA;EACA,kBAAA;EACA,uBAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,8BAAA;AACJ;;AAEE;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,0CAAA;AACJ;;AAEE;EACE,wCAAA;AACJ;;AACE;EACA,2BAAA;AAEF;;AACE;EACE,kBAAA;AAEJ","sourcesContent":[".boardList-ticket-title {\r\n    color: black;\r\n    font-weight: 500;\r\n    background-color: rgba(255, 255, 255, 0.7);\r\n    height: 100%;\r\n    margin-left: 40px;\r\n    padding: 9px 15px;\r\n    padding-left: 15px;\r\n    transition: padding-left 0.1s linear;\r\n    word-break: break-all;\r\n  }\r\n  \r\n  .boardList-ticket {\r\n    width: 90%;\r\n    border-radius: 3px;\r\n    margin: 0px 0px 6px 0px;\r\n    display: inline-block;\r\n    cursor: pointer;\r\n    position: relative;\r\n    vertical-align: top !important;\r\n  }\r\n  \r\n  .darken {\r\n    width: 100%;\r\n    border-radius: 3px;\r\n    height: 100%;\r\n    background-color: rgba(255, 255, 255, 0.3);\r\n  }\r\n  \r\n  .darken:hover {\r\n    background-color: rgba(255, 255, 255, 0);\r\n  }\r\n  .selected-node{\r\n  background-color: lightgray;\r\n  }\r\n\r\n  .move-button{\r\n    margin-right: 10px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
