import { Playlist } from "./Playlist";

export class PlaylistViewerParams
{
    playlist: Playlist;
    ownedPlaylist: boolean;

    constructor(p: Playlist, f: boolean)
    {
        this.playlist = p;
        this.ownedPlaylist = f;
    }
}