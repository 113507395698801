import { Pipe, PipeTransform } from '@angular/core';
import { KanbanCard } from 'src/app/kanban/entities/Card';

@Pipe({
  name: 'kanban_card_show_end_date_bad'
})
export class KanbanCardShowEndDateBadPipe implements PipeTransform {

  transform(card: KanbanCard, completed: boolean, endDate: Date, dueDate: Date): any {
    return completed && endDate && dueDate && new Date(endDate) > new Date(dueDate);
  }

}
