import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlEditorComponent } from './html-editor.component';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzInputModule
  ],
  declarations: [HtmlEditorComponent],
  exports: [HtmlEditorComponent]
})
export class HtmlEditorModule { }
