import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-blocker',
  templateUrl: './page-blocker.component.html',
  styleUrls: ['./page-blocker.component.scss']
})
export class PageBlockerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
