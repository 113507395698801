import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanManageTypeRelationComponent } from './kanban-manage-type-relation.component';
import { ContentModule } from 'src/app/components/content/content.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { FormsModule } from '@angular/forms';
import { KanbanManageTypeRelationRoutingModule } from './kanban-manage-type-relation.routing.module';
import { KanbanTypeRelationEditModule } from '../kanban-type-relation-edit/kanban-type-relation-edit.module';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    KanbanManageTypeRelationRoutingModule,
    ContentModule,
    NzCardModule,
    NzTableModule,
    TranslateModule,
    NzToolTipModule,
    NzButtonModule,
    LoadingModule,
    NzSwitchModule,
    FormsModule,
    NzIconModule,
    KanbanTypeRelationEditModule
  ],
  declarations: [KanbanManageTypeRelationComponent],
  exports: [KanbanManageTypeRelationComponent]
})
export class KanbanManageTypeRelationModule { }
