import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomEvent, BORDER_DARKENING } from '../entities/event';
import { EventService } from '../services/event.service';
import RRule from 'rrule';
import { Color } from '../entities/color';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ColorService } from '../../../services/color.service';
import { KanbanTreeSelectedInfos } from '../../../kanban/entities/kanban-tree-selected-infos';
import { Reccurence } from '../entities/reccurence';
import { PresenceInfos, PresenceState } from '../../../kanban/entities/presence-infos';
import { CacheDataService } from '../../../services/cache-data.service';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../../services/url-api.service';
import { PlanningEvent } from '../../../planning/entities/planning-event';
import { ConflictCheckInfos } from '../../../planning/entities/conflicts-infos';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KanbanCardEditModalComponent } from '../../../kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.component';
import { KanbanCard } from '../../../kanban/entities/Card';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditEventComponent implements OnInit
{

  /*=========
    VARIABLES
    =========*/

  public events: CustomEvent[];
  public boards: any = null;
  public startDate: Date = null;
  public endDate: Date = null;
  public event: CustomEvent;
  creatorId: number;
  public CUSTOM_DHCP: boolean = false;
  public siteListOptions: string[];
  public siteListValues: number[];
  public showRecurrence: boolean = true;
  public agendaMode = true;

  public rules: {
    freq: number,
    daily: {
      choice: {
        withInterval: boolean,
        all: boolean
      },
      value: boolean,
      interval: number
    },
    weekly: {
      value: boolean,
      interval: number,
      byweekday: any,
      weekdays: {
        monday: boolean,
        tuesday: boolean,
        wednesday: boolean,
        thursday: boolean,
        friday: boolean,
        saturday: boolean,
        sunday: boolean
      }
    },
    monthly: {
      choice: {
        withoutSetpos: boolean,
        withSetpos: boolean
      },
      value: boolean,
      interval: number,
      bymonthday: number,
      byweekday: any,
      bysetpos: number,
    },
    yearly: {
      choice: {
        withoutSetpos: boolean,
        withSetpos: boolean
      },
      value: boolean,
      interval: number,
      bymonthday: number,
      bymonth: number,
      bysetpos: number,
      byweekday: any
    }
  } = {
      freq: null,
      daily: {
        choice: {
          withInterval: true,
          all: false
        },
        value: false,
        interval: 1
      },
      weekly: {
        value: false,
        interval: 1,
        byweekday: [],
        weekdays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        }
      },
      monthly: {
        choice: {
          withoutSetpos: true,
          withSetpos: false
        },
        value: false,
        interval: 1,
        bymonthday: null,
        byweekday: [],
        bysetpos: null,
      },
      yearly: {
        choice: {
          withoutSetpos: true,
          withSetpos: false
        },
        value: false,
        interval: 1,
        bymonthday: null,
        bymonth: null,
        bysetpos: null,
        byweekday: []
      }
    }

  list: any;
  listOptions: any;
  listValues: any;
  until: Date;

  groupEvents: CustomEvent[];
  service: any;
  selectedObjectId;
  showBoards = false;
  showPresenceSelector = false;
  resourceTitle = "";
  resourceColor = "";

  constructor(private msg: NzMessageService, public eventService: EventService, private http: HttpClient,
    public translateService: TranslateService, public modalService: NzModalService,
    public cds: CacheDataService, public cs: ColorService, private cdr: ChangeDetectorRef) { }

  /*====
    INIT
    ====*/

  setFromSelect(val: string)
  {
    if (val)
      this.event.id_obj = parseInt(val);
  }

  updateReccurrence(reccurence: Reccurence)
  {
    this.event.rrule = reccurence;
  }

  ngOnInit()
  {

    this.eventService.service = this.service;

    if (!this.event)
    {
      this.event = new CustomEvent();
      this.getRules();
      this.event.presence.state = PresenceState.Away;
      this.event.isAdded = true;
      this.event.creatorId = this.creatorId;
      this.event.id_obj = this.selectedObjectId
      this.resourceTitle = this.service.resources.find(x => x.id == this.selectedObjectId).name;
      this.resourceColor = "#000000";
    }
    else
    {
      this.event.isAdded = false;
      this.until = this.event.until;
      this.getRules();
    }

    if (this.startDate)
    {
      this.event.start = new Date(this.startDate.getTime())
      this.event.end = new Date(this.startDate.getTime() + (60 * 60 * 1000))
    }

    if (this.endDate)
      this.event.end = new Date(this.endDate.getTime())


    this.setRules();

    this.listOptions = this.list.map(a => a.name);
    this.listValues = this.list.map(a => a.id);

    this.checkConflict();
  }

  updateEndDate(startDate: Date)
  {
    this.event.end = new Date(startDate.getTime() + (60 * 60 * 1000));
    this.checkConflict();
  }

  resetResource()
  {
    this.event.id_obj = 0
    this.selectedObjectId = 0;
    this.resourceTitle = this.service.resources.find(x => x.id == this.selectedObjectId).name;
    this.resourceColor = "#000000";
  }

  onSelection(data: KanbanTreeSelectedInfos)
  {
    if (!data.list)
      return;
    this.selectedObjectId = data.list.id;
    this.event.id_obj = data.list.id;
    this.resourceTitle = data.board.title == data.list.title ? data.board.title : data.board.title + " / " + data.list.title;
    this.resourceColor = data.board.bgColor;
  }

  /*========
    METHODES
    ========*/

  setDefaultRules()
  {
    this.rules.daily.value = true;
    this.rules.weekly.value = false;
    this.rules.monthly.value = false;
    this.rules.yearly.value = false;

    this.rules.daily.interval = 1;
    this.rules.daily.choice = { withInterval: true, all: false };

    this.rules.weekly.interval = 1;
    this.rules.weekly.byweekday = [this.event.start.getDay() - 1];
    this.rules.weekly.weekdays = {
      monday: this.event.start.getDay() == 1 ? true : false,
      tuesday: this.event.start.getDay() == 2 ? true : false,
      wednesday: this.event.start.getDay() == 3 ? true : false,
      thursday: this.event.start.getDay() == 4 ? true : false,
      friday: this.event.start.getDay() == 5 ? true : false,
      saturday: this.event.start.getDay() == 6 ? true : false,
      sunday: this.event.start.getDay() == 7 ? true : false
    };

    this.rules.monthly.interval = 1;
    this.rules.monthly.byweekday = [this.event.start.getDay() - 1];
    this.rules.monthly.bymonthday = this.event.start.getDate();
    this.rules.monthly.bysetpos = 1;
    this.rules.monthly.choice = { withoutSetpos: true, withSetpos: false };

    this.rules.yearly.interval = 1;
    this.rules.yearly.byweekday = [this.event.start.getDay() - 1];
    this.rules.yearly.bymonthday = this.event.start.getDate();
    this.rules.yearly.bysetpos = 1;
    this.rules.yearly.bymonth = this.event.start.getMonth() + 1;
    this.rules.yearly.choice = { withoutSetpos: true, withSetpos: false };
  }

  getRules()
  {
    this.setDefaultRules();
    if (!this.event.rrule)
      return;
    switch (this.event.rrule.freq)
    {
      case RRule.DAILY:
        this.rules.daily.value = true;
        this.rules.weekly.value = false;
        this.rules.monthly.value = false;
        this.rules.yearly.value = false;
        this.rules.daily.interval = this.event.rrule.interval;
        this.rules.daily.choice = { withInterval: true, all: false };
        break;
      case RRule.WEEKLY:
        this.rules.daily.value = false;
        this.rules.weekly.value = true;
        this.rules.monthly.value = false;
        this.rules.yearly.value = false;
        this.rules.weekly.interval = this.event.rrule.interval ? this.event.rrule.interval : 1;
        this.rules.weekly.byweekday = this.event.rrule.byweekday ? this.event.rrule.byweekday : [this.event.start.getDay() - 1];
        if (this.rules.weekly.byweekday)
        {
          this.rules.weekly.byweekday.forEach(day =>
          {
            switch (day)
            {
              case 0:
                this.rules.weekly.weekdays.monday = true;
                break;
              case 1:
                this.rules.weekly.weekdays.tuesday = true;
                break;
              case 2:
                this.rules.weekly.weekdays.wednesday = true;
                break;
              case 3:
                this.rules.weekly.weekdays.thursday = true;
                break;
              case 4:
                this.rules.weekly.weekdays.friday = true;
                break;
              case 5:
                this.rules.weekly.weekdays.saturday = true;
                break;
              case 6:
                this.rules.weekly.weekdays.sunday = true;
                break;
            }
          });
        }

        break;
      case RRule.MONTHLY:
        this.rules.daily.value = false;
        this.rules.weekly.value = false;
        this.rules.monthly.value = true;
        this.rules.yearly.value = false;
        this.rules.monthly.interval = this.event.rrule.interval ? this.event.rrule.interval : 1;
        this.rules.monthly.byweekday = this.event.rrule.byweekday ? this.event.rrule.byweekday : [this.event.start.getDay() - 1];
        this.rules.monthly.bymonthday = this.event.rrule.bymonthday ? this.event.rrule.bymonthday : this.event.start.getDate();
        this.rules.monthly.bysetpos = this.event.rrule.bysetpos ? this.event.rrule.bysetpos : 1;
        if (this.event.rrule.bysetpos)
          this.rules.monthly.choice = { withoutSetpos: false, withSetpos: true };
        else
          this.rules.monthly.choice = { withoutSetpos: true, withSetpos: false };
        break;
      case RRule.YEARLY:
        this.rules.daily.value = false;
        this.rules.weekly.value = false;
        this.rules.monthly.value = false;
        this.rules.yearly.value = true;
        this.rules.yearly.interval = this.event.rrule.interval ? this.event.rrule.interval : 1;
        this.rules.yearly.byweekday = this.event.rrule.byweekday ? this.event.rrule.byweekday : [this.event.start.getDay() - 1];
        this.rules.yearly.bymonthday = this.event.rrule.bymonthday ? this.event.rrule.bymonthday : this.event.start.getDate();
        this.rules.yearly.bysetpos = this.event.rrule.bysetpos ? this.event.rrule.bysetpos : 1;
        this.rules.yearly.bymonth = this.event.rrule.bymonth ? this.event.rrule.bymonth : this.event.start.getMonth() + 1;
        if (this.event.rrule.bymonth)
          this.rules.yearly.choice = { withoutSetpos: false, withSetpos: true };
        else
          this.rules.yearly.choice = { withoutSetpos: true, withSetpos: false };
        break;
      default:
        this.rules.daily.value = false;
        this.rules.weekly.value = false;
        this.rules.monthly.value = false;
        this.rules.yearly.value = false;
    }

  }

  setRules(value?: number)
  {
    this.event.rrule = new Reccurence();
    this.event.rrule.freq = RRule.DAILY;
    this.event.rrule.interval = 1;

    if (value)
    {
      this.rules.yearly.interval = value;
    }


    if (this.rules.daily.value)
    {
      this.event.rrule.freq = RRule.DAILY;
      this.event.rrule.interval = this.rules.daily.choice.withInterval ? this.rules.daily.interval : null;
    }
    else if (this.rules.weekly.value)
    {
      this.event.rrule.freq = RRule.WEEKLY;
      this.event.rrule.interval = this.rules.weekly.interval;
      this.event.rrule.byweekday = this.rules.weekly.byweekday;
    }
    else if (this.rules.monthly.value)
    {
      this.event.rrule.freq = RRule.MONTHLY;
      this.event.rrule.interval = this.rules.monthly.interval;
      this.event.rrule.bymonthday = this.rules.monthly.choice.withoutSetpos ? this.rules.monthly.bymonthday : null;
      this.event.rrule.byweekday = this.rules.monthly.choice.withSetpos ? [this.rules.monthly.byweekday] : null;
      this.event.rrule.bysetpos = this.rules.monthly.choice.withSetpos ? this.rules.monthly.bysetpos : null;
    }
    else if (this.rules.yearly.value)
    {
      this.event.rrule.freq = RRule.YEARLY;
      this.event.rrule.interval = this.rules.yearly.interval;
      this.event.rrule.bymonth = this.rules.yearly.bymonth;
      this.event.rrule.bymonthday = this.rules.yearly.choice.withoutSetpos ? this.rules.yearly.bymonthday : null;
      this.event.rrule.byweekday = this.rules.yearly.choice.withSetpos ? [this.rules.yearly.byweekday] : null;
      this.event.rrule.bysetpos = this.rules.yearly.choice.withSetpos ? this.rules.yearly.bysetpos : null;
    }
    else
    {
      this.event.isRecurrent = false; // Only one occurrence to prevent the reccurence
    }
  }

  updateCheckBox(rule: number)
  {
    this.rules.daily.value = false;
    this.rules.weekly.value = false;
    this.rules.monthly.value = false;
    this.rules.yearly.value = false;
    switch (rule)
    {
      case 1:
        setTimeout(() =>
        {
          this.rules.daily.value = true;
          this.setRules();
        }, 1);
        break;
      case 2:
        setTimeout(() =>
        {
          this.rules.weekly.value = true;
          this.setRules();
        }, 1);
        break;
      case 3:
        setTimeout(() =>
        {
          this.rules.monthly.value = true;
          this.setRules();
        }, 1);
        break;
      case 4:
        setTimeout(() =>
        {
          this.rules.yearly.value = true;
          this.setRules();
        }, 1);
        break;
      default:
        break;
    }

  }

  updateDailyCheckbox(choice: number, value?: number)
  {
    switch (choice)
    {
      case 1:
        if (value)
          this.rules.daily.interval = value;
        setTimeout(() =>
        {
          this.rules.daily.choice = { withInterval: true, all: false };
          this.setRules();
        }, 1);
        break;
      case 2:
        setTimeout(() =>
        {
          this.rules.daily.choice = { withInterval: false, all: true };
          this.setRules();
        }, 1);
        break;
    }
  }

  updateWeeklyCheckbox(day: number)
  {
    if (this.rules.weekly.byweekday.includes(day))
      if (this.rules.weekly.byweekday.length > 1)
      {
        this.rules.weekly.byweekday = this.rules.weekly.byweekday.filter(value => value != day)
        this.setRules();
      }
      else
        switch (day)
        {
          case 0:
            setTimeout(() =>
            {
              //this.rules.weekly.weekdays.monday = true;
              this.setRules();
            }, 1);
            break;
          case 1:
            setTimeout(() =>
            {
              //this.rules.weekly.weekdays.tuesday = true;
              this.setRules();
            }, 1);
            break;
          case 2:
            setTimeout(() =>
            {
              //this.rules.weekly.weekdays.wednesday = true;
              this.setRules();
            }, 1);
            break;
          case 3:
            setTimeout(() =>
            {
              //this.rules.weekly.weekdays.thursday = true;
              this.setRules();
            }, 1);
            break;
          case 4:
            setTimeout(() =>
            {
              //this.rules.weekly.weekdays.friday = true;
              this.setRules();
            }, 1);
            break;
          case 5:
            setTimeout(() =>
            {
              //this.rules.weekly.weekdays.saturday = true;
              this.setRules();
            }, 1);
            break;
          case 6:
            setTimeout(() =>
            {
              //this.rules.weekly.weekdays.sunday = true;
              this.setRules();
            }, 1);
            break;
        }

    else
    {
      this.rules.weekly.byweekday.push(day);
      this.setRules();
    }


  }

  updateMonthlyCheckbox(choice: number, value?: number, which?: number)
  {
    switch (choice)
    {
      case 1:
        switch (which)
        {
          case 1:
            if (value)
              this.rules.monthly.bymonthday = value;
            break;
          case 2:
            if (value)
              this.rules.monthly.interval = value;
            break;
        }

        setTimeout(() =>
        {
          this.rules.monthly.choice = { withoutSetpos: true, withSetpos: false };
          this.setRules();
        }, 1);
        break;
      case 2:
        if (value)
          this.rules.monthly.interval = value;
        setTimeout(() =>
        {
          this.rules.monthly.choice = { withoutSetpos: false, withSetpos: true };
          this.setRules();
        }, 1);
        break;
    }
  }

  updateYearlyCheckbox(choice: number, value?: number)
  {
    switch (choice)
    {
      case 1:
        setTimeout(() =>
        {
          if (value)
            this.rules.yearly.bymonthday = value;
          this.rules.yearly.choice = { withoutSetpos: true, withSetpos: false };
          this.setRules();
        }, 1);
        break;
      case 2:
        setTimeout(() =>
        {
          this.rules.yearly.choice = { withoutSetpos: false, withSetpos: true };
          this.setRules();
        }, 1);
        break;
    }
  }



  /* updateCustomCheckBox(rule: number, value: boolean) {
    if (value) // We check if we already have the rule
    {
      switch (rule) {
        case 1:
          this.rules.custom.weekly = true;
          this.rules.custom.monthly = false;
          break;
        case 2:
          this.rules.custom.weekly = false;
          this.rules.custom.monthly = true;
          break;
        default:
          this.rules.custom.weekly = false;
          this.rules.custom.monthly = false;
      }
    }
    else { // If we already have the rule, we don't keep it 
      this.rules.weekly = false;
      this.rules.monthly = false;
      this.rules.fourWeeks = false;
    }
  } */


  onObjChange()
  {
    //this.event.title = this.list.find(obj => { return obj.id === this.event.id_obj }).name.toString();
    this.event.originColor = this.list.find(obj => { return obj.id === this.event.id_obj }).color;
    this.event.color = {
      primary: new Color(this.event.originColor).darken(BORDER_DARKENING).toString(false),
      secondary: this.event.originColor.hex.hex
    }
  }

  handleChange({ file, fileList }): void
  {
    const status = file.status;
    if (status !== 'uploading')
    {
    }
    if (status === 'done')
    {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error')
    {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  getPresenceStateColor()
  {
    let state = this.cds.presenceOptions.find(x => x.val == this.event.presence.state);
    if (state)
      return state.color;
    return "black";
  }

  conflictEvents: PlanningEvent[] = [];
  checkConflict()
  {
    let infos = new ConflictCheckInfos();
    infos.start = this.event.start;
    infos.end = this.event.end;
    infos.userId = this.creatorId;
    console.log(this.event);

    this.http.post<PlanningEvent[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/check-conflicts', infos).subscribe(events =>
    {
      this.conflictEvents = events;
    })
  }

  editCard(idcard: number)
  {
    let card = new KanbanCard();
    card.id = idcard;
    let title = "";
    this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanCardEditModalComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      }]
    });

    modal.componentInstance.card = card;
    modal.componentInstance.board = null;
    modal.componentInstance.loaddata = true;
    modal.componentInstance.list = null;

    modal.afterClose.subscribe((result) =>
    {
      this.checkConflict();
      //this.kbs.editedCard = null;
    });
  }
}