import { Injectable } from '@angular/core';
import { PhysicalPersonService } from '../../third-party/physical-person/services/physical-person.service';
import { HubConnection } from '@microsoft/signalr';
import { PhysicalPerson } from '../../third-party/physical-person/entities/physical-person';
import { CacheDataService } from '../cache-data.service';

@Injectable({
  providedIn: 'root'
})
export class PhysicalPersonNotificationService
{

  constructor(private pps: PhysicalPersonService, private cds: CacheDataService) { }

  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('InitPhysicalPersonCache', (pplist: PhysicalPerson[]) =>
    {
      pplist.forEach(pp =>
      {
        if (this.cds.physicalPersonCollection.findIndex(x => x.id == pp.id) < 0)
          this.cds.physicalPersonCollection.push(pp);
      })

      if (pplist.length == 0)
        this.pps.physicalPersonListChange.next(null);
    });

    _hubConnection.on('PhysicalPersonCreation', (pp: PhysicalPerson) =>
    {
      if (this.cds.physicalPersonCollection.findIndex(x => x.id == pp.id) < 0)
      {
        this.cds.physicalPersonCollection.push(pp);
        this.pps.physicalPersonListChange.next(null);
      }
    });

    _hubConnection.on('UpdatePhysicalPersonAttachment', (idpp: number, nbatt: number) =>
    {
      let index = this.cds.physicalPersonCollection.findIndex(x => x.id == idpp);
      if (index == -1)
        return;
      this.cds.physicalPersonCollection[index].nbAttachments = nbatt;
      this.pps.physicalPersonListChange.next(null);
    });

    _hubConnection.on('PhysicalPersonUpdate', (pp: PhysicalPerson) =>
    {
      let index = this.cds.physicalPersonCollection.findIndex(x => x.id == pp.id);
      if (index == -1)
        return;
      this.cds.physicalPersonCollection[index] = pp;
      this.pps.physicalPersonListChange.next(null);
    });

    _hubConnection.on('PhysicalPersonArchive', (pp: PhysicalPerson) =>
    {
      let index = this.cds.physicalPersonCollection.findIndex(x => x.id == pp.id);
      if (index == -1)
        return;
      this.cds.physicalPersonCollection[index].archived = true;
      this.pps.physicalPersonListChange.next(null);
    });

    _hubConnection.on('PhysicalPersonRestore', (pp: PhysicalPerson) =>
    {
      let index = this.cds.physicalPersonCollection.findIndex(x => x.id == pp.id);
      if (index == -1)
        return;
      this.cds.physicalPersonCollection[index].archived = false;
      this.pps.physicalPersonListChange.next(null);
    });

  }

}
