import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardShowEndDateGoodPipe } from './kanban-card-show-end-date-good.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KanbanCardShowEndDateGoodPipe],
  exports: [KanbanCardShowEndDateGoodPipe]
})
export class KanbanCardShowEndDateGoodPipeModule { }
