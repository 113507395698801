// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.size-span {
  margin-right: 15px;
  margin-top: 13px;
  float: right;
}

.kanban-attachment-container {
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
}

.kanban-attachment-container:hover {
  background-color: #b9bcbd;
}

.kanban-attachment-img {
  height: 50px;
  margin-right: 25px;
}

.kanban-attachment-img-placeholder {
  margin-right: 75px;
  height: 50px;
  display: inline-block;
}

.kanban-attachement-span {
  padding-right: 25px;
  display: inline-block;
  margin-top: 14px;
}

.kanban-attachement-delete {
  margin-right: 15px;
  float: right;
  margin-top: 17px;
  cursor: pointer;
}

.kanban-attachement-download {
  margin-right: 15px;
  float: right;
  margin-top: 17px;
  cursor: pointer;
}

.kanbard-edit-card-side-category-span {
  border-radius: 5px;
  background-color: #9ca0a1;
  padding: 10px 10px;
  cursor: pointer;
  text-align: center;
}

.add-attachment-container {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/attachment-manager/attachment-manager.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,4BAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,qBAAA;AACF;;AAEA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".size-span {\r\n  margin-right: 15px;\r\n  margin-top: 13px;\r\n  float: right;\r\n}\r\n\r\n.kanban-attachment-container {\r\n  padding: 10px 10px 10px 10px;\r\n  border-radius: 5px;\r\n  margin-bottom: 5px;\r\n  position: relative;\r\n}\r\n\r\n.kanban-attachment-container:hover {\r\n  background-color: #b9bcbd;\r\n}\r\n\r\n.kanban-attachment-img {\r\n  height: 50px;\r\n  margin-right: 25px;\r\n}\r\n\r\n.kanban-attachment-img-placeholder {\r\n  margin-right: 75px;\r\n  height: 50px;\r\n  display: inline-block;\r\n}\r\n\r\n.kanban-attachement-span {\r\n  padding-right: 25px;\r\n  display: inline-block;\r\n  margin-top: 14px;\r\n}\r\n\r\n.kanban-attachement-delete {\r\n  margin-right: 15px;\r\n  float: right;\r\n  margin-top: 17px;\r\n  cursor: pointer;\r\n}\r\n\r\n.kanban-attachement-download {\r\n  margin-right: 15px;\r\n  float: right;\r\n  margin-top: 17px;\r\n  cursor: pointer;\r\n}\r\n\r\n.kanbard-edit-card-side-category-span {\r\n  border-radius: 5px;\r\n  background-color: #9ca0a1;\r\n  padding: 10px 10px;\r\n  cursor: pointer;\r\n  text-align: center;\r\n}\r\n\r\n.add-attachment-container {\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
