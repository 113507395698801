import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appInputFocus]'
})
export class InputFocusDirective {

  elem: ElementRef;
  @Input() stopFocus: boolean;
  constructor(el: ElementRef) {
    this.elem = el;
   }
   ngOnInit()
   {
    if(this.stopFocus)
    return;
    return;
      setTimeout(() => {this.elem.nativeElement.focus()}, 350);
   }
}
