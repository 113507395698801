import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlApiService } from '../url-api.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService
{

  constructor(
    private http: HttpClient,
  ) { }

  public pushNotificationStatus = {
    isSubscribed: false,
    isSupported: false,
    isInProgress: false
  };

  swRegistration: ServiceWorkerRegistration

  init()
  {
    if ('serviceWorker' in navigator && 'PushManager' in window)
    {
      navigator.serviceWorker.register('../../../assets/scripts/service-worker.js')
        .then(swReg =>
        {
          this.swRegistration = swReg;
          this.resubscribe();
        })
        .catch(error =>
        {
          console.error('Service Worker Error', error);
        });
      this.pushNotificationStatus.isSupported = true;
    } else
    {
      this.pushNotificationStatus.isSupported = false;
    }
  }

  urlB64ToUint8Array(base64String)
  {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i)
    {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  PUBLIC_KEY = "BFyx9gTYjfUjBzhSSLdPfgfGMjAdjZz8Xb_o2ivLyuqcXRf0rLpG4XANZU8T5Z4q6r1jw_IRKbYEHxOAoOCaML8"

  subscribe()
  {
    this.pushNotificationStatus.isInProgress = true;
    const applicationServerKey = this.urlB64ToUint8Array(this.PUBLIC_KEY);
    this.swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
      .then(subscription =>
      {
        this.pushNotificationStatus.isSubscribed = true;
        this.saveSubscription(subscription).subscribe(x =>
        {
          console.log("subscribed push notif");
          this.pushNotificationStatus.isInProgress = false;
        })
      })
      .catch(err =>
      {
        console.error('Failed to subscribe the user: ', err);
      })
  }

  resubscribe()
  {
    this.pushNotificationStatus.isInProgress = true;
    this.swRegistration.pushManager.getSubscription()
      .then(subscription => 
      {
        if (subscription)
        {
          subscription.unsubscribe().then(() =>
          {
            console.log("unsubscribed push notif");

            this.subscribe()
          });
        }
        else this.subscribe()
      })
      .catch(function (error)
      {
        console.error('Error unsubscribing', error);
      })
      .then(() =>
      {
        this.pushNotificationStatus.isSubscribed = false;
        this.pushNotificationStatus.isInProgress = false;
      });
  }

  saveSubscription(subscription: PushSubscription)
  {
    let jsonSub = JSON.parse(JSON.stringify(subscription))
    let sub = new pushSubscription()
    sub.endpoint = jsonSub.endpoint
    sub.p256DH = jsonSub.keys.p256dh
    sub.auth = jsonSub.keys.auth

    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/pushNotif/create/subscribe`, sub)
  }
}

export class pushSubscription
{
  endpoint: string
  p256DH: string
  auth: string
}