import { DateSchedulingParameter } from "src/app/entities/date-scheduling-parameter";

export class ListAction
{
    triggerAction = "";
    codeAction = "";
    memberManualParam: string[] = [];
    memberAutoParam: string[] = [];
    teamManualParam: string[] = [];
    teamAutoParam: string[] = [];
    notifiedUsers: string[] = [];
    notifiedTeams: string[] = [];
    labelManualParam: string[] = [];
    labelAutoParam: string[] = [];
    thirdPartyManualParam: string[] = [];
    thirdPartyAutoParam: string[] = [];
    listIdMoveCard: number = 0;
    listIdCopyCard: number = 0;
    dateSchedulingParameter: DateSchedulingParameter = new DateSchedulingParameter();
    dueDate: Date;
    confirm = "";
    info = "";
    isVisible = true;
    actionDisplayText = "";
}