import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlApiService } from '../../../services/url-api.service';
import { DTOLog } from '../../entities/Log';
import { Observable } from 'rxjs';
import { LogCountInfos } from '../../entities/log-count-infos';
import { LogCountInfosParams } from '../../entities/log-count-infos-param';
import { LogAction } from '../../entities/log-action';
import { Level } from '../../entities/level';
import { Module } from '../../entities/module';
import { AuthService } from '../../../services/auth.service';
import { LogCustomiserSetting } from '../../entities/log-customiser-settings';

@Injectable({
  providedIn: 'root'
})
export class LogsSourceService
{

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getLogsCountInfosPerDay()
  {
    return this.http.get<LogCountInfos[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/log/count-infos/day')
  }

  public getLogsCountInfosPerMonth()
  {
    return this.http.get<LogCountInfos[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/log/count-infos/month')
  }

  public getLogsCountInfosFiltered(filters: LogCountInfosParams)
  {
    return this.http.post<LogCountInfos[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/log/count-infos', filters)
  }

  public purgeLogsWithFilters(filters: LogCountInfosParams)
  {
    return this.http.post<LogCountInfos[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/log/count-infos/purge', filters)
  }

  public getLogs(dateBegin: Date, dateEnd: Date, listModule: number[], listCategory: number[], listAction: number[], listLevel: number[], ip: string, username: string, start: number, number: number, content: string): Observable<DTOLog[]>
  {
    let myParams = new HttpParams();
    for (var i = 0; i < listModule.length; i++)
    {
      myParams = myParams.append('module-list', listModule[i].toString());
    }
    for (var i = 0; i < listCategory.length; i++)
    {
      myParams = myParams.append('category-list', listCategory[i].toString());
    }
    for (var i = 0; i < listAction.length; i++)
    {
      myParams = myParams.append('action-list', listAction[i].toString());
    }
    for (var i = 0; i < listLevel.length; i++)
    {
      myParams = myParams.append('level-list', listLevel[i].toString());
    }
    myParams = myParams.append('begin-date', dateBegin.toISOString());
    myParams = myParams.append('end-date', dateEnd.toISOString());
    if (typeof ip !== "undefined" && ip.length > 0)
      myParams = myParams.append('ip', ip);
    myParams = myParams.append('content', content);
    myParams = myParams.append('start', start.toString());
    myParams = myParams.append('number', number.toString());
    if (typeof username !== "undefined" && username.length > 0)
      myParams = myParams.append('username', username);
    return this.http.get<DTOLog[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/log/by-period', {
      params: myParams
    });
  }

  public UpdateNbLogPagePreferences(nbLogPerPage: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/log/update-preference?nbLogPerPage=' + nbLogPerPage, null)
  }

  public GetPreference(): Observable<string>
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + '/api/log/preference');
  }

  public getCustomiserSettings()
  {
    return this.http.get<LogCustomiserSetting[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/log/customiser-settings');
  }

  public updateCustomiserSettings(settings: LogCustomiserSetting[])
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/log/customiser-settings', settings);
  }

  initFilters(logActionList: any[], levelList: any[], moduleList: any[])
  {
    logActionList.length = 0;
    levelList.length = 0;
    moduleList.length = 0;

    for (var n in LogAction)
    {
      if (typeof LogAction[n] === 'number')
        continue;
      let num: number = parseInt(n);
      logActionList.push({ text: LogAction[n], value: num, byDefault: false });
    }
    for (var n in Level)
    {
      if (typeof Level[n] === 'number')
        continue;
      let num: number = parseInt(n);
      levelList.push({ text: Level[n], value: num, byDefault: false });
    }
    for (var n in Module)
    {
      if (typeof Module[n] === 'number')
        continue;
      switch (Module[n])
      {
        case "user":
        case "userGroup":
          if (!this.authService.Access.userAccess)
            continue;
          else break;
        case "credentials":
        case "credentialsGroup":
          if (!this.authService.Access.isCredentialsAdmin)
            continue;
          else break;
        case "contract":
        case "common":
        case "kanban":
        case "changelog":
        case "log":
          if (!this.authService.Access.rightAccess)
            continue;
          else break;
      }
      let num: number = parseInt(n);
      moduleList.push({ text: Module[n], value: num, byDefault: false });
    }
  }
}
