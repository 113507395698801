import { Injectable } from '@angular/core';
import { PhysicalPersonService } from '../../third-party/physical-person/services/physical-person.service';
import { PhysicalPerson } from '../../third-party/physical-person/entities/physical-person';
import { TranslateService } from '@ngx-translate/core';
import { PhysicalPersonEditComponent } from '../../third-party/physical-person/components/physical-person-edit/physical-person-edit.component';
import { ThirdPartyService } from '../../third-party/services/third-party.service';
import { MoralPerson } from '../../third-party/moral-person/entities/moral-person';
import { MoralPersonService } from '../../third-party/moral-person/services/moral-person.service';
import { MoralPersonEditComponent } from '../../third-party/moral-person/components/moral-person-edit/moral-person-edit.component';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../url-api.service';
import { SearchClientInfoComponent } from '../../third-party/moral-person/components/search-client-info/search-client-info.component';
import { SearchClientResult } from '../../third-party/moral-person/entities/search-client-result';
import { AvatarService } from '../avatar.service';
import { CacheDataService } from '../cache-data.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalManagerService
{

  constructor(private ppService: PhysicalPersonService,
    private mpService: MoralPersonService,
    private translateService: TranslateService,
    private tpService: ThirdPartyService,
    private http: HttpClient,
    private msgs: NzMessageService,
    private cds: CacheDataService,
    private avatarService: AvatarService,
    private modalService: NzModalService) { }



  private updatePPAttribute(pp: PhysicalPerson, result: PhysicalPersonEditComponent)
  {
    pp.firstName = result.firstName;
    pp.lastName = result.lastName;
    pp.nationality = result.nationality;
    pp.spokenLanguages = result.spokenLanguages;
    pp.anniversaryDate = result.anniversaryDate;
    pp.siret = result.siret;
    pp.siren = result.siren;
    pp.comment = result.comment;
    pp.title = result.title;
    pp.phoneNumbers = result.phoneNumbers;
    pp.customFields = result.customFields;
    pp.tags = result.tags;
    pp.secondaryNames = result.secondaryNames.map(x => x.value);
    pp.websites = result.websites.map(x => x.value);
    pp.brands = result.brands.map(x => x.value);
    pp.image = result.avatarService.avatarUrl;
    pp.mails = result.mails;
    pp.labels = result.labels;
  }

  openClientSearchForMPCreation()
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.MORAL-PERSON.SEARCH-CLIENT-TITLE").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: SearchClientInfoComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzFooter: [
        {
          label: close,
          onClick: () => { modal.close(); }
        },
        {
          label: confirm,
          type: 'primary',
          disabled(result): boolean { return result.selectedResult == null && result.resultsList.length != 1 },
          onClick: (result) =>
          {
            if (result.selectedResult || result.resultsList.length == 1)
            {
              if (!result.selectedResult)
                result.selectedResult = result.resultsList[0];
              if (result.alreadyExist(result.selectedResult.numeroSIRET))
              {
                let text = "";
                this.translateService.get("THIRD-PARTY.ERROR.MORAL-PERSON-ALREADY-EXIST-SIRET").subscribe(x => text = x);
                this.msgs.error(text);
                return;
              }
              this.createMPFull(null, result.selectedResult);
              modal.close();
            }
          }
        }
      ]
    });
  }

  createMPFull(parent: MoralPerson, searchResult: SearchClientResult)
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.MORAL-PERSON.ADD").subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CREATE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);

    this.tpService.modal = this.modalService.create({
      nzTitle: title,
      nzContent: MoralPersonEditComponent,
      nzWidth: '80%',
      nzMaskClosable: false,
      nzOnOk: (result) =>
      {
        let mp = result.submitForm();
        if (!mp)
          return false;
        if (result.isFetchinglogo)
        {
          this.tpService.modal.close();
          return;
        }

        if (result.moralPerson == null)
        {
          this.mpService.createMoralPerson(mp, this.avatarService.avatarFile).subscribe(x =>
          {
            mp.id = x;
            result.moralPerson = mp;
            mp.nbAttachments = result.attachmentsPictures.length + result.attachmentsNotPictures.length;
            for (let att of result.attachmentsPictures)
            {
              this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${mp.id}/attachment`, att).subscribe(x =>
              {
                att.id = x;
                mp.attachments.push(att);
              });
            }

            for (let att of result.attachmentsNotPictures)
            {
              this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${mp.id}/attachment`, att).subscribe(x =>
              {
                att.id = x;
                mp.attachments.push(att);
              });
            }
            this.tpService.updateThirdPartyCredential(mp.id, result.credentialsAssociated).subscribe();
            let mpmodel = this.cds.moralPersonCollection ? this.cds.moralPersonCollection[0] : null;
            if (mpmodel)
            {
              mp.filteredRelations = mpmodel.filteredRelations.filter(x => x.isGlobal || x.isSystem)
            }

            if (this.cds.allmoralperson.findIndex(m => m.id == mp.id) < 0)
            {
              if (parent)
              {
                let temp = this.cds.moralPersonCollection.find(t => t.id == parent.id);
                temp.secondarySites.push(mp);
              }
              else this.cds.moralPersonCollection.push(mp);
              this.mpService.moralPersonListChange.next(null);
            }

          });
        }
        else
        {
          mp.id = result.moralPerson.id;
          mp.name = result.name;
          mp.mainCurrency = result.mainCurrency;
          mp.codeTVA = result.codeTVA;
          mp.codeAPE = result.codeAPE;
          mp.nationality = result.nationality;
          mp.anniversaryDate = result.anniversaryDate;
          mp.siren = result.siren;
          mp.siret = result.siret;
          mp.comment = result.comment;
          mp.phoneNumbers = result.phoneNumbers;
          mp.customFields = result.customFields;
          mp.tags = result.tags;
          mp.secondaryNames = result.secondaryNames.map(x => x.value);
          mp.websites = result.websites.map(x => x.value);
          mp.sign = result.sign;
          mp.initials = result.initials;
          mp.brands = result.brands.map(x => x.value);
          mp.image = result.avatarService.avatarUrl;
          mp.mails = result.mails;
          mp.labels = result.labels;
          this.mpService.updateMoralPerson(mp, this.avatarService.avatarFile).subscribe(x =>
          {
            this.tpService.updateThirdPartyCredential(mp.id, result.credentialsAssociated).subscribe();
            let cachemp = this.cds.moralPersonCollection.find(m => m.id == mp.id);
            if (cachemp)
              cachemp = mp;
            if (this.mpService.modal)
              this.mpService.modal.close();
            this.mpService.moralPersonListChange.next(null);
          });
        }
        if (this.tpService.modal)
          this.tpService.modal.close();
      },
      nzOkText: create,
      nzOnCancel: () => { this.tpService.modal.close(); },
      nzCancelText: close
    });
    this.tpService.modal.componentInstance.modalPerson = null;
    this.tpService.modal.componentInstance.parent = parent;
    this.tpService.modal.componentInstance.searchResult = searchResult;
    return this.tpService.modal;
  }



  updateMPAttribute(mp: MoralPerson, result: MoralPersonEditComponent)
  {
    mp.name = result.name;
    mp.mainCurrency = result.mainCurrency;
    mp.codeTVA = result.codeTVA;
    mp.codeAPE = result.codeAPE;
    mp.nationality = result.nationality;
    mp.anniversaryDate = result.anniversaryDate;
    mp.siren = result.siren;
    mp.siret = result.siret;
    mp.comment = result.comment;
    mp.phoneNumbers = result.phoneNumbers;
    mp.customFields = result.customFields;
    mp.tags = result.tags;
    mp.secondaryNames = result.secondaryNames.map(x => x.value);
    mp.websites = result.websites.map(x => x.value);
    mp.sign = result.sign;
    mp.initials = result.initials;
    mp.brands = result.brands.map(x => x.value);
    mp.image = result.avatarService.avatarUrl;
    mp.mails = result.mails;
    mp.labels = result.labels;
    mp.secondarySites = result.secondarySites;
  }

}
