import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { EmailToSend } from '../../entities/email-to-send';
import { AttachmentsModuleService } from '../../services/AttachmentsModule.service';
import { MessagerieAccountAlias } from '../entities/messagerie-account-alias';
import { MessagerieConversation } from '../entities/messagerie-conversation';
import { MessagerieFolder } from '../entities/messagerie-folder';
import { MessagerieMailAction } from '../entities/messagerie-mail-action';
import { Receivedmail } from '../entities/receivedmail';
import { MessagerieFolderService } from '../messagerie-folder.service';
import { MessagerieRuleService } from '../messagerie-rule.service';
import { MessagerieService } from '../messagerie.service';
import { DatelocalePipe } from '../../../../src/app/pipes/pipes/datelocale.pipe';
import { TextCompareService } from '../../services/text-compare.service';
import { PlanningEvent } from '../../planning/entities/planning-event';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../services/url-api.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KanbanCardEditModalComponent } from '../../kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.component';
import { KanbanCard } from '../../kanban/entities/Card';
import { AuthService } from '../../services/auth.service';
import { PlanningRights } from '../../planning/entities/other-planning-rights';
import { CalendarView } from 'angular-calendar';
import { DomSanitizer } from '@angular/platform-browser';
import { KanbanService } from '../../kanban/services/kanban.service';

@Component({
  selector: 'app-messagerie-mail-viewer',
  templateUrl: './messagerie-mail-viewer.component.html',
  styleUrls: ['./messagerie-mail-viewer.component.scss']
})
export class MessagerieMailViewerComponent implements OnInit
{

  constructor(public mss: MessagerieService, public mfs: MessagerieFolderService,
    public mrs: MessagerieRuleService,
    public http: HttpClient,
    public nzContextMenuService: NzContextMenuService,
    public modalService: NzModalService,
    public datepipe: DatelocalePipe,
    public tcs: TextCompareService,
    public auth: AuthService,
    public router: Router,
    private sanitized: DomSanitizer,
    private route: ActivatedRoute, public ams: AttachmentsModuleService,
    public kbs: KanbanService,
    public translateService: TranslateService) { }
  @Input() mail: Receivedmail = new Receivedmail();


  @HostListener('window:resize', ['$event'])
  onResize(event)
  {
    this.mss.setShowText();
    this.iframeHeight = this.getIframeHeight();
  }
  iframeHeight = "500px";
  mailLoading = false;
  mailfrom = "";
  mailto = "";
  mailcc = "";
  mailcci = "";
  canSeeCci = false;
  updateStatus: Subscription;
  events: PlanningEvent[] = [];

  ngOnInit()
  {
    const id = + this.route.snapshot.paramMap.get('idMail');
    if (id)
    {
      this.mailLoading = true;

      this.mss.getMailById(id).subscribe(m => 
      {
        this.mail = m.mail
        this.mail.content = m.mailContent;
        this.mailLoading = false;
        this.initmailinfo()
        if (this.mss.markAsReadOnFocus)
          this.mss.readMail(id).subscribe();
      });
    }
    else this.initmailinfo();

    //this.replaceLinkForContent(this.mail.content);

    this.mss.getAppointments(id ? id : this.mail.id).subscribe(evts =>
    {
      this.events = evts;
      this.initCalendar();
    })
    this.mss.getMailAccounts().subscribe(accs =>
    {
      this.mss.profileList = accs.filter(x => !x.archived)
    });

  }

  manualRuleApplyVisible = false;
  selectedRuleForApply: number = null;
  initManualRuleApply()
  {
    this.mrs.getRules(this.mss.selectedProfiles).subscribe(rules => 
    {
      this.mrs.rules = rules
      this.selectedRuleForApply = null;
      this.manualRuleApplyVisible = true;
    });
  }

  printMail()
  {
    var iframe = document.getElementById("printmaildiv");
    var printwindow = window.open('', '_blank', 'height=1000,width=1000');
    printwindow.document.write(iframe.outerHTML);
    printwindow.document.title = "Cameline"
    printwindow.document.close();
    printwindow.focus();
    setTimeout(() =>
    {
      printwindow.print();
    }, 500);

    //printwindow.close();
  }

  cancelManualRuleApply()
  {
    this.selectedRuleForApply = null;
    this.manualRuleApplyVisible = false;
  }

  applyRuleToMail()
  {
    let idfolder = this.mss.getMailFolderId(this.mail)
    if (idfolder == 0)
    {
      this.cancelManualRuleApply();
      return;
    }

    this.mrs.applyRuleToMail(this.selectedRuleForApply, idfolder, this.mail.id).subscribe(() =>
    {
      this.mss.reloadmails.next(null);
      this.cancelManualRuleApply();
    });
  }

  getAttachmentMaxHeight()
  {
    //HACK : Pour compenser pour la taille de la navbar
    return (window.innerHeight - 100) + "px";
  }

  initmailinfo()
  {
    if (this.mail.pseudo)
      this.mailfrom = this.mail.pseudo + " <" + this.mail.from + ">"
    else this.mailfrom = this.mail.from;
    if (!this.mail.content.startsWith("<base target='_parent'>"))
      this.mail.content = "<base target='_blank'>" + this.mail.content;

    this.mailto = "";
    for (let to of this.mail.to)
    {
      this.mailto += to + "; ";
    }
    if (this.mail.to.length > 0)
      this.mailto = this.mailto.slice(0, this.mailto.length - 2)

    this.mailcc = "";
    for (let to of this.mail.cc)
    {
      this.mailcc += to + "; ";
    }
    if (this.mail.cc.length > 0)
      this.mailcc = this.mailcc.slice(0, this.mailcc.length - 2)

    this.mailcci = "";
    for (let to of this.mail.bcc)
    {
      this.mailcci += to + "; ";
    }
    if (this.mail.bcc.length > 0)
    {
      this.mailcci = this.mailcci.slice(0, this.mailcci.length - 2)
      if (this.mss.selectedFolder && this.mss.selectedFolder.isSent)
        this.canSeeCci = true;

    }

  }

  ngAfterViewChecked()
  {
    this.iframeHeight = this.getIframeHeight();
  }

  ngAfterViewInit()
  {
    this.mss.setShowText();
  }



  openInAnotherTab()
  {
    window.open(window.location.origin + "/messaging/inbox/" + this.mail.id, "_blank");
  }

  discussionThreadList: MessagerieConversation[] = [];
  openDiscussionThreadList()
  {
    this.mss.getDiscussionThreadsForMail(this.mail.id).subscribe(threads =>
    {
      this.discussionThreadList = threads;

    })
  }

  openDiscussionThread(iddiscussionthread: number)
  {
    this.mss.initModalThreadList(this.discussionThreadList.filter(x => x.id == iddiscussionthread));
    this.mss.showDiscussionContent(iddiscussionthread, this.mss.searchDiscussionThread, this.mss.start, this.mss.number);
  }

  async replyMail(mailToReply: Receivedmail)
  {
    this.mss.dontOpen = true;
    let email: EmailToSend = new EmailToSend();
    email.to[0] = mailToReply.from;
    this.mss.setAlias(mailToReply, email);

    email.object = "Re: " + mailToReply.subject;
    email.content = await this.createHeaderMailContent(mailToReply);
    email.action = new MessagerieMailAction();
    email.action.mailId = mailToReply.id;
    email.action.accountId = mailToReply.idAccount;
    email.action.typeAction = 1;
    email.action.date = new Date();
    this.mail.actions.push(email.action)
    this.mss.emailToInit = email;
    this.mss.editMail();

  }

  async replyAllMail(mailToReply: Receivedmail)
  {
    this.mss.dontOpen = true;
    let email: EmailToSend = new EmailToSend();

    email.to.push(mailToReply.from);
    mailToReply.to.forEach(elem =>
    {
      try
      {
        if (!this.tcs.equals(elem, this.mss.profileList.find(x => x.id == mailToReply.idAccount).mailAdress))
          email.to.push(elem)
      } catch (e) { }
    })
    mailToReply.cc.forEach(element =>
    {
      email.cc.push(element)
    });
    this.mss.setAlias(this.mail, email);
    email.object = "RE: " + mailToReply.subject
    email.content = await this.createHeaderMailContent(mailToReply);
    email.action = new MessagerieMailAction();
    email.action.mailId = mailToReply.id;
    email.action.accountId = mailToReply.idAccount;
    email.action.typeAction = 1;
    email.action.date = new Date();
    this.mail.actions.push(email.action)
    this.mss.emailToInit = email;
    this.mss.editMail();
  }

  async transferMail(mailToReply: Receivedmail)
  {
    this.mss.dontOpen = true;
    let email: EmailToSend = new EmailToSend();
    this.mss.setAlias(mailToReply, email);
    email.attachments = await firstValueFrom(this.mss.getMailAttachments(mailToReply.id));
    email.object = "Tr: " + mailToReply.subject;
    email.content = await this.createHeaderMailContent(mailToReply);
    email.action = new MessagerieMailAction();
    email.action.mailId = mailToReply.id;
    email.action.accountId = mailToReply.idAccount;
    email.action.typeAction = 2;
    email.action.date = new Date();
    this.mail.actions.push(email.action)
    this.mss.emailToInit = email;
    this.mss.editMail();
  }

  deleteMail(mailToDelete: Receivedmail, folder: MessagerieFolder)
  {
    this.mss.dontOpen = true;
    mailToDelete.deleted = true;
    this.mss.openMail = false;
    let id = this.mss.getMailFolderId(this.mail)
    this.mss.deleteMail(mailToDelete.id, id).subscribe(() =>
    {
      this.mss.reloadmenu.next(true);
      for (let datefolder of this.mss.dateCategories)
      {
        let index = datefolder.mails.findIndex(x => x.id == mailToDelete.id);
        if (index >= 0)
        {
          datefolder.mails.splice(index, 1);
          break;
        }
      }

      for (let datefolder of this.mss.dateCategoriesThreads)
      {
        let index = datefolder.mails.findIndex(x => x.id == mailToDelete.id);
        if (index >= 0)
        {
          datefolder.mails.splice(index, 1);
          break;
        }
      }
      let index = this.mss.selectMailList.findIndex(x => x.id == mailToDelete.id);
      if (index >= 0)
        this.mss.selectMailList.splice(index, 1);
      this.mss.dontOpen = false;
      this.mss.start--;
      if (window.location.href.indexOf("/messaging/inbox/") >= 0)
      {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/messaging/inbox']));
      }
    });
  }

  restoreMail(mailToDelete: Receivedmail)
  {
    this.mss.dontOpen = true;
    mailToDelete.deleted = true;
    this.mss.openMail = false;
    this.mss.restoreMail(mailToDelete.id).subscribe(() =>
    {
      if (this.mss.selectedFolder && !mailToDelete.read)
        this.mss.selectedFolder.unreadMailCount--;
      else if (!this.mss.selectedFolder && !mailToDelete.read)
        this.mss.unreadInbox--;

    });
  }

  readMail(mailToRead: Receivedmail)
  {
    if (mailToRead.read)
      return;
    this.mss.readMail(mailToRead.id).subscribe(content => 
    {
      mailToRead.content = content
      mailToRead.read = true;
    });
  }

  async createHeaderMailContent(mailToReply: Receivedmail)
  {
    let content: string;

    content = "<div><div/><br /><hr>" + this.translater("GENERIC-FORM.MAIL-EDITOR.FROM") + " : " + mailToReply.from + "<br />";
    content += this.translater("GENERIC-FORM.MAIL-EDITOR.TO") + " : " + mailToReply.to + "<br />";
    content += this.translater("GENERIC-FORM.DATE") + " : " + this.datepipe.transform(mailToReply.receivedUTC, 'medium') + "<br />";
    if (mailToReply.cc.length > 0)
    {
      content += this.translater("GENERIC-FORM.MAIL-EDITOR.CC") + " : " + mailToReply.cc.toString() + "<br />";
    }
    if (mailToReply.subject && mailToReply.subject.length > 0)
    {
      content += this.translater("GENERIC-FORM.MAIL-EDITOR.SUBJECT") + " : " + mailToReply.subject + "<br />";
    }
    if (!mailToReply.content || mailToReply.content.length == 0)
      mailToReply.content = await firstValueFrom(this.mss.readMail(mailToReply.id));

    let doc = new DOMParser().parseFromString(mailToReply.content, 'text/html');
    content += doc.body.innerHTML; + "<br />";
    return content;
  }

  stopPropagation()
  {
    event.stopPropagation();
  }

  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }

  getIframeHeight()
  {
    var iframes = document.querySelectorAll("iframe");
    if (!iframes)
      return "50px";

    for (var i = 0; i < iframes.length; i++)
    {
      if (iframes[i].contentWindow.document.body)
        return (iframes[i].contentWindow.document.body.scrollHeight + 60) + 'px';
    }
    return "50px"
  }

  editAppointment(event: PlanningEvent)
  {
    let task = new KanbanCard();
    task.id = event.id;
    let title = "";
    this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanCardEditModalComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      }]
    });

    modal.componentInstance.card = task;
    modal.componentInstance.board = null;
    modal.componentInstance.loaddata = false;
    modal.componentInstance.list = null;

    modal.afterClose.subscribe((result) =>
    {
      this.mss.getAppointments(this.mail.id).subscribe(evts =>
      {
        this.events = evts;
        this.initCalendar();
      })
    });
  }

  showCalendar = false;
  calendarDate: Date;
  initCalendar()
  {
    this.calendarDate = this.getEventsDate();
    this.showCalendar = true;
  }
  getEventsDate()
  {
    let date = this.events.length > 0 ? new Date(this.events[0].start) : new Date();
    let otherdate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    return otherdate;
  }

  confirmEvent(event: PlanningEvent)
  {
    if (!event.temporary)
      return;
    event.temporary = false;
    this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/appointment/${event.id}/confirm`, null).subscribe();
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void
  {
    this.nzContextMenuService.create($event, menu);
    event.stopPropagation();
  }

  view: CalendarView = CalendarView.Day;
  allRight(): PlanningRights
  {
    let r = PlanningRights.None;
    let keys = Object.keys(PlanningRights);
    keys.forEach((key, index) =>
    {
      r |= parseInt(key);
    })
    return r;
  }

  replaceLinkForContent(html: string)
  {
    let index = html.indexOf("&lt;http");
    if (index < 0)
      return;

    let index2 = html.indexOf("&gt;", index);
    this.replaceLink(html, index, index2);
  }

  replaceLink(html: string, indexstart: number, indexend: number)
  {
    let link = html.substring(indexstart + 4, indexend).replace(/<p>/gi, "").replace(/<\/p>/gi, "");
    console.log(link);

    fetch(link)
      .then(response => 
      {
        console.log("hello");

        let Hstart = html.substring(0, indexstart);
        let Hend = html.substring(indexend + 1);
        let hlink = response.text();
        html = Hstart + hlink + Hend;
        indexstart = html.indexOf("&lt;http");
        console.log(html);
        if (indexstart < 0)
          return;
        indexend = html.indexOf("&gt;", indexstart);
        this.replaceLink(html, indexstart, indexend);

      })
      .then(data => console.log("hello 2"));
  }

  confirmInvitation(evt: PlanningEvent)
  {
    this.showCalendar = false;
    this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/appointment/${evt.id}/confirm`, null).subscribe(() =>
    {
      evt.temporary = false;
      this.showCalendar = true;
    });
  }

  deleteInvitation(evt: PlanningEvent)
  {
    this.showCalendar = false;
    this.kbs.deleteCard(evt.id).subscribe(() =>
    {
      let index = this.events.findIndex(x => x.id == evt.id);
      if (index >= 0)
        this.events.splice(index, 1);
      this.showCalendar = true;
    })
  }

  getIframeHeigh()
  {
    let elem = document.getElementById("mail-viewer-header");
    return "calc(100% - " + (elem.clientHeight - 5) + "px)"
  }

}
