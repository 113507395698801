import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanActionsCreationCardComponent } from './kanban-actions-creation-card.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NameUserPipeModule } from 'src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NameTeamPipeModule } from 'src/app/pipes/name-team-pipe/name-team-pipe.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { KanbanBoardTreeModule } from '../kanban-board-tree/kanban-board-tree.module';

@NgModule({
    imports: [
        CommonModule,
        NzInputModule,
        FormsModule,
        NzButtonModule,
        NzGridModule,
        TranslateModule,
        NzIconModule,
        NzDividerModule,
        NzSelectModule,
        KanbanBoardTreeModule,
        NzToolTipModule,
        NameUserPipeModule,
        NameTeamPipeModule
    ],
    declarations: [KanbanActionsCreationCardComponent],
    exports: [KanbanActionsCreationCardComponent]
})
export class KanbanActionsCreationCardModule { }
