import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { KanbanCardEditModalComponent } from '../kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.component';
import { KanbanBoard } from '../kanban/entities/Board';
import { KanbanCard } from '../kanban/entities/Card';
import { KanbanList } from '../kanban/entities/List';
import { KanbanComponent } from '../kanban/kanban.component';
import { KanbanService } from '../kanban/services/kanban.service';
import { TodoService } from '../kanban/services/todo.service';
import { NameUserPipe } from '../pipes/name-user-pipe/name-user.pipe';
import { ReservationResource } from '../reservation/entities/resource';
import { Color } from '../SDICalendar/calendar/entities/color';
import { BORDER_DARKENING, CustomEvent } from '../SDICalendar/calendar/entities/event';
import { CalendarOccurence } from '../SDICalendar/calendar/entities/occurence';
import { Reccurence } from '../SDICalendar/calendar/entities/reccurence';
import { ResourceCategory } from '../SDICalendar/calendar/entities/resource-category';
import { EventService } from '../SDICalendar/calendar/services/event.service';
import { AuthService } from '../services/auth.service';
import { CacheDataService } from '../services/cache-data.service';
import { UrlApiService } from '../services/url-api.service';
import { SettingsService } from '../settings/services/settings.service';
import { HEXColor } from '../timeline/entities/color';
import { DayEventsComponent } from './day-events/day-events.component';
import { PlanningEvent } from './entities/planning-event';
import { PlanningFilterParameters } from './entities/planning-filter-parameters';
import { PlanningSharingParameters } from './entities/planning-sharing-parameters';
import { EventsPerUser } from './entities/events-per-user';
import { OtherPlanningRights, PlanningRights } from './entities/other-planning-rights';
import { ConflictCheckInfos } from './entities/conflicts-infos';
import { MessagerieService } from '../messagerie/messagerie.service';
import { EmailToSend } from '../entities/email-to-send';
import { MailEditorComponent } from '../components/mail-editor/mail-editor.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MessagerieAccountAlias } from '../messagerie/entities/messagerie-account-alias';

@Injectable({
    providedIn: 'root'
})
export class PlanningService
{

    constructor(private http: HttpClient,
        private eventService: EventService,
        private settingsService: SettingsService,
        private translateService: TranslateService,
        private modalService: NzModalService,
        private msgService: NzMessageService,
        private nameuserpipe: NameUserPipe,
        private mss: MessagerieService,
        private tds: TodoService,
        private cds: CacheDataService,
        private authService: AuthService,
        private kbs: KanbanService) { }
    eventsAreLoaded = false;
    resources: ReservationResource[] = [];
    categories: ResourceCategory[] = [];
    boards: KanbanBoard[] = [];
    initialView: any = "month";
    weekhoursegment = 1;
    eventPerUser: EventsPerUser[];
    updateCardSubscription: Subscription;
    showCardDetails: boolean = true;
    filters: PlanningFilterParameters = new PlanningFilterParameters();
    showCreatorColor = false;
    nodes = [];
    //filters: PlanningFilterParameters = new PlanningFilterParameters();
    refreshCalendars: Subject<any> = new Subject<any>();
    refreshRights: Subject<any> = new Subject<any>();
    allowedPlannings: OtherPlanningRights[] = [];
    readonly = false;

    getAllPlannedEvents(planningevents: CustomEvent[], filters: PlanningFilterParameters, agendaMode = true)
    {
        this.updateCardSubscription = this.kbs.updateCardDate.subscribe(card =>
        {
            if (card.completed && !filters.finishedCards)
            {
                let index = planningevents.findIndex(x => x.id == card.id);
                if (index >= 0)
                    planningevents.splice(index, 1);
                this.eventService.updateEventSubject.next(null);
                return;
            }
            else if (!card.completed)
            {
                let index = planningevents.findIndex(x => x.id == card.id);
                if (index < 0)
                {
                    let res = this.resources.find(x => x.id == card.listId);
                    this.addCardToEventList(planningevents, card, res);
                }
            }

            if (card.startDate && card.dueDate)
            {
                let diff = Math.abs(new Date(card.dueDate).getTime() - new Date(card.startDate).getTime());
                let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                if (diffDays > 2)
                {
                    let firstEventStart = new Date(card.startDate);
                    let firstEventEnd = new Date(new Date(card.startDate).setTime(firstEventStart.getTime() + 2 * 60 * 60 * 1000));

                    let secondEventEnd = new Date(card.dueDate);
                    let secondEventStart = new Date(new Date(card.dueDate).setTime(secondEventEnd.getTime() - 2 * 60 * 60 * 1000));

                    let eventStart = planningevents.find(x => x.id == card.id && x.longEventStatus == 1);
                    let eventEnd = planningevents.find(x => x.id == card.id && x.longEventStatus == 2);
                    if (eventStart && eventEnd)
                    {
                        eventStart.title = card.title;
                        eventStart.start = firstEventStart;
                        eventStart.end = firstEventEnd;
                        eventEnd.title = card.title;
                        eventEnd.start = secondEventStart;
                        eventEnd.end = secondEventEnd;
                    }
                    else
                    {
                        let eventIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 0);
                        this.copyEvent(planningevents, planningevents[eventIndex], firstEventStart, firstEventEnd, 1, card.title);
                        this.copyEvent(planningevents, planningevents[eventIndex], secondEventStart, secondEventEnd, 2, card.title);
                        planningevents.splice(eventIndex, 1);

                    }

                }
                else
                {
                    let eventStartIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 1);
                    if (eventStartIndex >= 0)
                    {
                        this.copyEvent(planningevents, planningevents[eventStartIndex], new Date(card.startDate), new Date(card.dueDate), 0, card.title);
                        planningevents.splice(eventStartIndex, 1);
                        let eventEndIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 2);
                        planningevents.splice(eventEndIndex, 1);
                    }
                    else 
                    {
                        let eventIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 0);
                        this.copyEvent(planningevents, planningevents[eventIndex], new Date(card.startDate), new Date(card.dueDate), 0, card.title);
                        planningevents.splice(eventIndex, 1);
                    }

                }
            }
            else if (card.startDate)
            {
                let firstEventStart = new Date(card.startDate);
                let firstEventEnd = new Date(new Date(card.startDate).setTime(firstEventStart.getTime() + 2 * 60 * 60 * 1000));

                let eventStartIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 1);
                if (eventStartIndex >= 0)
                {
                    this.copyEvent(planningevents, planningevents[eventStartIndex], firstEventStart, firstEventEnd, 0, card.title);
                    planningevents.splice(eventStartIndex, 1);
                    let eventEndIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 2);
                    planningevents.splice(eventEndIndex, 1);
                }
                else 
                {
                    let eventIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 0);
                    planningevents[eventIndex].title = card.title;
                    planningevents[eventIndex].start = firstEventStart;
                    planningevents[eventIndex].end = firstEventEnd;
                }
            }
            else if (card.dueDate)
            {
                let secondEventEnd = new Date(card.dueDate);
                let secondEventStart = new Date(new Date(card.dueDate).setTime(secondEventEnd.getTime() - 2 * 60 * 60 * 1000));

                let eventStartIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 2);
                if (eventStartIndex >= 0)
                {
                    this.copyEvent(planningevents, planningevents[eventStartIndex], secondEventStart, secondEventEnd, 0, card.title);
                    planningevents.splice(eventStartIndex, 1);
                    let eventEndIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 1);
                    planningevents.splice(eventEndIndex, 1);
                }
                else 
                {
                    let eventIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 0);
                    planningevents[eventIndex].title = card.title;
                    planningevents[eventIndex].start = secondEventStart;
                    planningevents[eventIndex].end = secondEventEnd;
                }
            }
            else
            {
                let eventStartIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 1);
                if (eventStartIndex >= 0)
                {
                    planningevents.splice(eventStartIndex, 1);
                    let eventEndIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 2);
                    planningevents.splice(eventEndIndex, 1);
                }
                else 
                {
                    let eventIndex = planningevents.findIndex(x => x.id == card.id && x.longEventStatus == 0);
                    planningevents.splice(eventIndex, 1);
                }
            }

            this.eventService.updateEventSubject.next(null);
        })

        this.kbs.getAllBoardAndListForUser().subscribe(boardCollection =>
        {
            this.boards = [...boardCollection];
            this.resources = [];
            this.categories = [];

            let titleTasks = "";
            this.translateService.get("PLANNING.TASK-TITLE").subscribe(t => titleTasks = t);
            let freeboard = new KanbanBoard();
            freeboard.id = 0;
            freeboard.title = titleTasks;
            freeboard.bgColor = "#111111";
            let freelist = new KanbanList();
            freelist.id = 0;
            freelist.title = titleTasks;
            freeboard.lists.push(freelist);
            boardCollection.unshift(freeboard)

            for (let b of boardCollection)
            {
                if (b.lists.length == 0)
                    continue;
                let resCategory = new ResourceCategory();
                resCategory.id = b.id;
                resCategory.name = b.title;
                resCategory.color = b.bgColor;
                this.categories.push(resCategory);
                for (let list of b.lists)
                {
                    let board = new ReservationResource();
                    board.id = list.id;
                    if (b.title == list.title)
                        board.name = b.title;
                    else board.name = b.title + ' / ' + list.title;
                    let hex = new HEXColor(b.bgColor);
                    board.color = new Color(hex);
                    this.resources.push(board);

                    let boardForFilter = new ReservationResource();
                    boardForFilter.id = list.id;
                    boardForFilter.name = list.title;
                    boardForFilter.color = new Color(hex);
                    resCategory.resources.push(boardForFilter);
                }
            }

            if (agendaMode)
                this.getAgendaEvents(filters);
            else this.getEvents(filters);
            this.kbs.getPublicLabelList().subscribe();
        })

    }

    initResources()
    {
        this.kbs.getAllBoardAndListForUser().subscribe(boardCollection =>
        {
            this.boards = [...boardCollection];
            this.resources = [];
            this.categories = [];

            let titleTasks = "";
            this.translateService.get("PLANNING.TASK-TITLE").subscribe(t => titleTasks = t);
            let freeboard = new KanbanBoard();
            freeboard.id = 0;
            freeboard.title = titleTasks;
            freeboard.bgColor = "#111111";
            let freelist = new KanbanList();
            freelist.id = 0;
            freelist.title = titleTasks;
            freeboard.lists.push(freelist);
            boardCollection.unshift(freeboard)

            for (let b of boardCollection)
            {
                if (b.lists.length == 0)
                    continue;
                let resCategory = new ResourceCategory();
                resCategory.id = b.id;
                resCategory.name = b.title;
                resCategory.color = b.bgColor;
                this.categories.push(resCategory);
                for (let list of b.lists)
                {
                    let board = new ReservationResource();
                    board.id = list.id;
                    if (b.title == list.title)
                        board.name = b.title;
                    else board.name = b.title + ' / ' + list.title;
                    let hex = new HEXColor(b.bgColor);
                    board.color = new Color(hex);
                    this.resources.push(board);

                    let boardForFilter = new ReservationResource();
                    boardForFilter.id = list.id;
                    boardForFilter.name = list.title;
                    boardForFilter.color = new Color(hex);
                    resCategory.resources.push(boardForFilter);
                }
            }
        })
    }

    updateWeekHourSegmentPreference(full: number, iduser: number)
    {
        this.settingsService.setSettingsByName("calendar_view_weekhoursegment_" + iduser, full + '');
    }

    prepareEvents(eventCollection: PlanningEvent[], planningevents: CustomEvent[], filters: PlanningFilterParameters)
    {
        this.eventsAreLoaded = false
        eventCollection.forEach(evt =>
        {
            let board = this.resources.find(x => x.id == evt.listId);
            if (!board)
            {
                board = new ReservationResource();
                board.id = evt.listId;
                if (evt.boardName == evt.listName)
                    board.name = evt.boardName;
                else board.name = evt.boardName + ' / ' + evt.listName;
                let hex = new HEXColor(evt.boardColor);
                board.color = new Color(hex);
                this.resources.push(board);
            }

            let diff = Math.abs(new Date(evt.end).getTime() - new Date(evt.start).getTime());
            let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
            if (diffDays > 2)
            {
                let firstEventStart = new Date(evt.start);
                let firstEventEnd = new Date(new Date(evt.start).setTime(firstEventStart.getTime() + 2 * 60 * 60 * 1000));

                let secondEventEnd = new Date(evt.end);
                let secondEventStart = new Date(new Date(evt.end).setTime(secondEventEnd.getTime() - 2 * 60 * 60 * 1000));


                this.addEventToList(planningevents, evt, board, firstEventStart, firstEventEnd, 1);

                this.addEventToList(planningevents, evt, board, secondEventStart, secondEventEnd, 2);
            }
            else
            {
                this.addEventToList(planningevents, evt, board, new Date(evt.start), new Date(evt.end), 0);
            }
        })
        planningevents.sort((a, b) => a.start < b.start ? -1 : 1)

        this.eventService.updateEventSubject.next(null);
        this.eventsAreLoaded = true;

    }

    async getAgendaEvents(filters: PlanningFilterParameters)
    {
        let eventlist: PlanningEvent[] = await firstValueFrom(this.http.post<PlanningEvent[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/agenda', filters));

        let planningevents: CustomEvent[] = [];
        this.prepareEvents(eventlist, planningevents, filters);

        return planningevents;
    }

    async getEvents(filters: PlanningFilterParameters)
    {
        let eventlist: PlanningEvent[] = await firstValueFrom(this.http.post<PlanningEvent[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning', filters));

        let planningevents: CustomEvent[] = [];
        this.prepareEvents(eventlist, planningevents, filters);
        return planningevents;
    }

    updatePlanningAccess(params: PlanningSharingParameters)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/access', params);
    }

    getPlanningAccess()
    {
        return this.http.get<PlanningSharingParameters>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/access');
    }

    getAllowedPlanning()
    {
        return this.http.get<OtherPlanningRights[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/allowed-agenda');
    }

    updateFilterPreferences(filters: PlanningFilterParameters)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/filters', filters);
    }

    getFilterPreferences(iduser: number)
    {
        return this.http.get<PlanningFilterParameters>(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/filters/${iduser}`);
    }


    updateViewType(newview: string, iduser: number)
    {
        this.settingsService.setSettingsByName("calendar_view_type_" + iduser, newview);
    }

    updateMonthDisplay(newDisplayValue: boolean, iduser: number)
    {
        this.settingsService.setSettingsByName("calendar_monthdisplay_cardetails_" + iduser, newDisplayValue ? "true" : "false");
    }

    closeCalendar()
    {
        this.updateCardSubscription?.unsubscribe();
    }

    addCardToEventList(planningevents: CustomEvent[], card: KanbanCard, board: ReservationResource)
    {
        let event = new CustomEvent();
        event.id = card.id;
        event.id_obj = card.listId;
        event.title = card.title;
        event.start = card.startDate;
        event.end = card.dueDate;
        event.location = card.location;

        if (card.reccurence)
        {
            event.reccurenceId = card.reccurenceId;
            event.rrule = card.reccurence;
            event.customRule = true;
        }

        if (this.showCreatorColor)
        {
            let hex = new HEXColor(this.cds.userCollection.find(x => x.id == card.creatorId)?.color);
            event.originColor = new Color(hex);
        }
        else event.originColor = board.color;
        event.draggable = !this.readonly;
        event.color = {
            primary: new Color(event.originColor).darken(BORDER_DARKENING).toString(),
            secondary: event.originColor.toString()
        }
        planningevents.push(event);
    }

    addEventToList(planningevents: CustomEvent[], evt: PlanningEvent, board: ReservationResource, start: Date, end: Date, status: number)
    {
        let event = new CustomEvent();
        event.id = evt.id;
        event.id_obj = board.id;
        event.start = start;
        event.end = end
        event.title = evt.title;
        event.longEventStatus = status;
        event.type_obj = evt.type_obj;
        event.completed = evt.completed;
        event.temporary = evt.temporary;
        event.allDay = evt.fullDay;
        event.creatorId = evt.creatorId;
        event.location = evt.location;
        if (evt.reccurence != null)
        {
            event.rrule = new Reccurence();
            event.rrule.freq = evt.reccurence.freq,
                event.rrule.interval = evt.reccurence.interval,
                event.rrule.bymonth = evt.reccurence.bymonth,
                event.rrule.bymonthday = evt.reccurence.bymonthday,
                event.rrule.count = evt.reccurence.count,
                event.rrule.bysetpos = evt.reccurence.bysetpos,
                event.rrule.byweekday = evt.reccurence.byweekday
            event.isRecurrent = true;

        }
        // else if (!evt.isException)
        // event.isRecurrent = true;

        event.reccurenceId = evt.reccurenceId;
        event.customRule = evt.originalStart != null || evt.originalEnd != null;

        if (this.showCreatorColor)
        {
            let hex = new HEXColor(this.cds.userCollection.find(x => x.id == evt.creatorId)?.color);
            event.originColor = new Color(hex);
        }
        else event.originColor = board.color;

        if (evt.type_obj == "ChecklistItem" || this.readonly || !this.canEditEvent(this.authService.connectedUser.id, event))
        {
            event.draggable = false;
            event.resizable = { beforeStart: false, afterEnd: false };
        }
        else 
        {
            event.draggable = true;
            if (status == 0)
                event.resizable = { beforeStart: true, afterEnd: true };
            else event.resizable = { beforeStart: false, afterEnd: false };
        }

        event.color = {
            primary: new Color(event.originColor).darken(BORDER_DARKENING).toString(),
            secondary: event.originColor.toString()
        }
        planningevents.push(event);
    }

    copyEvent(planningevents: CustomEvent[], evt: CustomEvent, start: Date, end: Date, status: number, title: string)
    {
        let event = new CustomEvent();
        event.id = evt.id;
        event.id_obj = evt.id_obj;
        event.start = start;
        event.end = end
        event.title = title;
        event.longEventStatus = status;
        event.reccurenceId = evt.reccurenceId;
        event.rrule = evt.rrule;
        event.isRecurrent = evt.isRecurrent;
        event.customRule = evt.customRule;
        event.completed = evt.completed;
        event.temporary = evt.temporary;
        event.allDay = evt.allDay;
        event.location = evt.location;
        event.creatorId = evt.creatorId;

        event.originColor = evt.originColor;
        event.draggable = !this.readonly;
        if (status == 0 && !this.readonly)
            event.resizable = { beforeStart: true, afterEnd: true };
        else event.resizable = { beforeStart: false, afterEnd: false };
        event.color = {
            primary: new Color(event.originColor).darken(BORDER_DARKENING).toString(),
            secondary: event.originColor.toString()
        }
        planningevents.push(event);
    }

    getOverlapSettings()
    {
        return this.settingsService.getSettingsByName("CalendarOverlapSetting");
    }

    getListForCalendar()
    {
        return this.resources;
    }

    async createEvent(event: CustomEvent, filters: PlanningFilterParameters, agendaMode = false)
    {
        let list = this.resources.find(x => x.id == event.id_obj);
        let card = new KanbanCard();
        card.title = event.title;
        card.startDate = event.start;
        card.dueDate = event.end;
        card.completed = false;
        card.fullDay = event.allDay;
        card.creatorId = event.creatorId;
        card.location = event.location;
        card.membersPresence.push(event.presence)

        if (event.rrule)
        {
            card.reccurence = new Reccurence();
            card.reccurence.freq = event.rrule.freq;
            card.reccurence.interval = event.rrule.interval;
            card.reccurence.bymonth = event.rrule.bymonth;
            card.reccurence.bymonthday = event.rrule.bymonthday;
            card.reccurence.bysetpos = event.rrule.bysetpos;
            card.reccurence.byweekday = event.rrule.byweekday;
            card.reccurence.count = event.rrule.count;
        }

        if (agendaMode)
            event.id = await firstValueFrom(this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/appointment', card));
        else
        {
            if (filters.listIds.length > 0 && filters.listIds.findIndex(x => x == event.id_obj) < 0)
            {
                let closetext = "";
                let contenttext = "";
                let removefilters = "";
                this.translateService.get("PLANNING.FILTER-CREATION-HIDE").subscribe(t => contenttext = t);
                this.translateService.get("PLANNING.REMOVE-ALL-FILTERS").subscribe(t => removefilters = t);
                this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(t => closetext = t);
                this.modalService.create({
                    nzTitle: null,
                    nzContent: contenttext,
                    nzClosable: false,
                    nzCancelText: closetext,
                    nzOkText: removefilters,
                    nzOnOk: () => 
                    {
                        filters.listIds = [];
                        this.eventService.ressourceFilter = filters.listIds;
                        this.updateFilterPreferences(filters).subscribe();
                        this.eventService.updateEventSubject.next(null);
                    }
                })
            }
            if (list.id == 0)
            {
                card.members.push(this.authService.connectedUser.id);
                event.id = await firstValueFrom(this.tds.createTask(card));
            }
            else
                event.id = (await firstValueFrom(this.kbs.addCardToList(list.id, card))).id
        }
    }

    async updateEvent(event: CustomEvent)
    {
        await firstValueFrom(this.kbs.updateCardStartDate(event.id, event.start));
        let card = new KanbanCard();
        card.id = event.id;
        await firstValueFrom(this.kbs.updateCardEndDate(card, event.end));
    }

    confirmEvent(event: CustomEvent)
    {
        if (!event.temporary)
            return;
        event.temporary = false;
        this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/appointment/${event.id}/confirm`, null).subscribe();
    }

    editTask(task: KanbanCard)
    {
        if (task.id == 0)
            return;
        this.kbs.editedCard = task;

        let thelist: KanbanList = null;
        let theboard: KanbanBoard = null;
        boardloop:
        for (let board of this.boards)
        {
            for (let list of board.lists)
            {
                if (list.id == task.listId)
                {
                    thelist = list;
                    theboard = board;
                    break boardloop;
                }
            }
        }

        if (theboard)
        {
            if (theboard.members.length == 0)
            {
                this.kbs.getMembersForBoard(theboard.id).subscribe(x => 
                {
                    theboard.members = x.filter(t => t.userId).map(t => t.userId).sort((a, b) => this.nameuserpipe.transform(a).toUpperCase() < this.nameuserpipe.transform(b).toUpperCase() ? -1 : 1);
                })
            }
            this.kbs.getLabelList(theboard.id).subscribe();
        }

        let title = "";
        this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
        let close = "";
        this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
        let modal = this.modalService.create({
            nzTitle: title,
            nzContent: KanbanCardEditModalComponent,
            nzWidth: '100%',
            nzMaskClosable: false,
            nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
            nzFooter: [{
                label: close,
                onClick: () => { modal.close(); }
            }]
        });
        modal.componentInstance.card = task;
        modal.componentInstance.board = theboard;
        modal.componentInstance.list = thelist;
        modal.componentInstance.loaddata = false;

        modal.afterClose.subscribe((result) =>
        {
            this.kbs.editedEvent = null;
            //this.kbs.editedCard = null;
        });
    }



    editEvent(event: CustomEvent, createException = false)
    {
        this.kbs.getCardInfos(event.id).subscribe(x =>
        {
            this.kbs.editedEvent = event;
            x.listId = event.id_obj;
            let occu: CalendarOccurence = null;
            if (createException && event.rrule && !event.customRule && (event.start != x.startDate || event.end != x.dueDate))
            {
                occu = new CalendarOccurence();
                occu.idreccurence = event.reccurenceId;
                occu.start = event.start;
                occu.end = event.end;
            }
            this.cds.occurence = occu;

            this.editTask(x);
        })
    }

    deleteEvent(event: CustomEvent)
    {

        this.kbs.archiveCard(event.id).subscribe(x => { })
    }

    goToRessources()
    {
        let close = "";
        this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
        const modal = this.modalService.create({
            nzTitle: "",
            nzContent: KanbanComponent,
            nzWidth: '95%',
            nzBodyStyle: { height: '95vh' },
            nzClosable: false,
            nzMaskClosable: true,
            nzFooter: null
        });
        modal.componentInstance.redirect = false;
    }

    initFilterTreeNodes(filters: PlanningFilterParameters)
    {
        if (!this.categories || this.categories.length == 0)
            return;

        let tempNode = [];

        for (let cat of this.categories)
        {
            let catNode = {
                title: cat.name,
                key: "cat_" + cat.id,
                bgcolor: cat.color,
                expanded: false,
                checked: false,
                children: []
            };
            tempNode.push(catNode)

            let allchecked = true;
            for (let res of cat.resources)
            {
                let resNode = {
                    title: res.name,
                    key: "res_" + res.id,
                    checked: filters.listIds.findIndex(x => x == res.id) >= 0,
                    isLeaf: true
                };
                catNode.children.push(resNode);
                if (!resNode.checked)
                    allchecked = false;
            }
            if (allchecked)
                catNode.checked = true;
        }

        this.nodes = [...tempNode];
    }

    canEditEvent(iduser: number, event: CustomEvent)
    {
        return iduser == event.creatorId || this.canUpdateAppointment(event);
    }

    canUpdateAppointment(event: CustomEvent)
    {
        let right = this.allowedPlannings.find(x => x.id == event.creatorId);
        if (right)
            return right.rights == PlanningRights.None || (right.rights & PlanningRights.UpdateAppointment) > 0;
    }

    allRight(): PlanningRights
    {
        let r = PlanningRights.None;
        let keys = Object.keys(PlanningRights);
        keys.forEach((key, index) =>
        {
            r |= parseInt(key);
        })
        return r;
    }

    initAppointmentInvitation(event: CustomEvent)
    {
        let card = new KanbanCard();
        card.id = event.id;
        card.startDate = event.start;
        card.dueDate = event.end;
        card.fullDay = event.allDay;
        card.location = event.location;
        card.title = event.title;


        let title = "";
        let send = "";
        let close = "";
        this.translateService.get("PLANNING.SEND-INVITATION").subscribe(x => title = x)
        this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x)
        this.translateService.get("MESSAGERIE.EDITOR.SEND").subscribe(x => send = x)
        let emailCreated = new EmailToSend();
        let alias = new MessagerieAccountAlias();
        alias.displayName = this.authService.connectedUser.mail;
        alias.mail = this.authService.connectedUser.mail;
        emailCreated.from = alias;

        const modal = this.modalService.create({
            nzTitle: title,
            nzBodyStyle: { height: '90vh' },
            nzStyle: { 'padding': 'unset' },
            nzContent: MailEditorComponent,
            nzWidth: '90%',
            nzZIndex: 10,
            nzMaskClosable: false,
            nzMask: false,
            nzFooter: [{
                label: close,
                onClick: (x) =>
                {
                    modal.close()
                }
            }, {
                label: send,
                type: "primary",
                disabled(result): boolean { return !result.checkToSend(); },
                onClick: (x) =>
                {
                    let mail = x.getDatas()

                    if (x.emailToInit.idMail > 0)
                        mail.idMail = x.emailToInit.idMail;
                    mail.messageId = x.emailToInit?.messageId;
                    this.mss.sendMailWithInvitation(mail, card, x.attachments).subscribe(() =>
                    {
                        let success = "";
                        this.translateService.get("MESSAGERIE.EDITOR.SUCCESS-SEND").subscribe(x => success = x)
                        this.msgService.success(success)
                    })
                    modal.close()
                }
            }]
        });
        modal.componentInstance.emailToInit = emailCreated;
    }

}
