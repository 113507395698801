import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService
{

    constructor() { }

    convertUTCDateToLocalDate(date: Date)
    {
        let theRealDate = new Date(date);
        let timezoneOffset = theRealDate.getTimezoneOffset();
        let newDate = new Date(theRealDate.getTime() + timezoneOffset * 60 * 1000);

        let offset = timezoneOffset / 60;
        var hours = theRealDate.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    convertLocalDateToUTCDate(date: Date)
    {
        let theRealDate = new Date(date);
        let timezoneOffset = theRealDate.getTimezoneOffset();
        let newDate = new Date(theRealDate.getTime() + timezoneOffset * 60 * 1000);

        let offset = timezoneOffset / 60;
        var hours = theRealDate.getHours();

        newDate.setHours(hours + offset);

        return newDate;
    }

    convertSecToDayHourMinSec(sec: number): string
    {
        let d = "", h = "", m = "";
        let day: number = (sec / (24 * 3600));
        sec = sec % (24 * 3600);
        let hour: number = sec / 3600;
        sec %= 3600;
        let min: number = sec / 60;
        sec %= 60;
        sec = sec;
        day = Math.trunc(day);
        min = Math.trunc(min);
        hour = Math.trunc(hour);
        sec = Math.trunc(sec);
        if (day != 0)
            d = day.toString() + "d "
        if (hour != 0)
            h = hour.toString() + "h "
        if (min != 0)
            m = min.toString() + "min "

        return d + h + m + sec.toString() + "s";
    }

}
