import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanLabelCardsComponent } from './kanban-label-cards.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KanbanCardEditModule } from '../kanban-card-edit/kanban-card-edit.module';
import { KanbanBoardTreeModule } from '../kanban-board-tree/kanban-board-tree.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { KanbanCardEditModalModule } from '../kanban-card-edit-modal/kanban-card-edit-modal.module';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzInputModule,
    NzTreeModule,
    FormsModule,
    NzSpinModule,
    TranslateModule,
    KanbanCardEditModalModule,
    NzPopoverModule,
    NzButtonModule,
    KanbanBoardTreeModule,
    NzSwitchModule
  ],
  declarations: [KanbanLabelCardsComponent],
  exports: [KanbanLabelCardsComponent]
})
export class KanbanLabelCardsModule { }
