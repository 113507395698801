import { Component, OnInit, Input } from '@angular/core';
import { KanbanCard } from '../../entities/Card';
import { KanbanService } from '../../services/kanban.service';
import { KanbanAttachment } from '../../entities/Attachment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-image-from-clipboard',
  templateUrl: './image-from-clipboard.component.html',
  styleUrls: ['./image-from-clipboard.component.scss']
})
export class ImageFromClipboardComponent implements OnInit {

  constructor(private kbs: KanbanService, private datepipe: DatePipe) { }

  @Input() card: KanbanCard;

  ngOnInit() {

    window.addEventListener("paste", (pasteEvent: ClipboardEvent) => {
      var items = pasteEvent.clipboardData.items;
      if(!items)
        return; 
  
      for (var i = 0; i < items.length; i++) {
          // Skip content if not image
          if (items[i].type.indexOf("image") == -1) continue;
          // Retrieve image on clipboard as blob
          var blob = items[i].getAsFile();
  
          var reader = new FileReader();

          reader.addEventListener('load', () => {
            let date = new Date();
            let att = new KanbanAttachment();
            att.name = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss") + "_clipboard.png";
            att.url = reader.result.toString();
            this.kbs.addAttachment(this.card.id, att).subscribe(x => {
              att.id = x;
              this.card.attachments.push(att);
              this.kbs.modalPaste.close();
            });
          });
reader.readAsDataURL(blob); 
      }
  }, false);
  }

}
