import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormManagerComponent } from './form-manager.component';


const appChildrenRoutes: Routes = [
  { path: '', component: FormManagerComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(appChildrenRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class FormManagerRoutingModule { }
