import { Component, OnInit, Input } from '@angular/core';
import { KanbanList } from '../../entities/List';
import { KanbanService } from '../../services/kanban.service';
import { KanbanBoard, KanbanBoardType } from '../../entities/Board';
import { MoveListAnotherBoard } from '../../entities/move-list-another-board';
import { CacheDataService } from 'src/app/services/cache-data.service';
import { TextCompareService } from 'src/app/services/text-compare.service';

@Component({
  selector: 'app-kanban-move-list',
  templateUrl: './kanban-move-list.component.html',
  styleUrls: ['./kanban-move-list.component.scss']
})
export class KanbanMoveListComponent implements OnInit
{

  constructor(public kbs: KanbanService,
    public tcs: TextCompareService, private cds: CacheDataService) { }

  @Input() list: KanbanList;
  @Input() action: string;

  moveSearchValue = "";
  filterTextForTree = "";
  boardsForMove: any[] = [];
  boardForMove: KanbanBoard[] = [];
  selectedForMove = "";
  selectedListForMove = "";
  selectedBoardForMove = "";
  bypass = false;

  ngOnInit()
  {
    this.boardForMove = [...this.kbs.allBoardList];
    this.initBoardMove(this.boardForMove);
  }

  filterLisForMove(filter: string)
  {
    this.initBoardMove(this.boardForMove);
    this.filterTextForTree = filter;
  }

  initBoardMove(x: KanbanBoard[])
  {
    let filterValueUpper = this.moveSearchValue.toUpperCase();
    this.boardsForMove = [];
    x.forEach(board =>
    {
      let addToList = this.tcs.contains(board.title, filterValueUpper)
      let boardNode = {
        title: board.title,
        key: "board_" + board.id,
        bgcolor: board.bgColor,
        expanded: false,
        idlist: 0,
        children: []
      };
      board.lists.forEach(list =>
      {
        if (!addToList)
          addToList = this.tcs.contains(list.title, filterValueUpper)
        let listNode =
        {
          title: list.title,
          key: "list_" + list.id,
          children: []
        };
        boardNode.children.push(listNode);
      });
      if (addToList)
      {
        this.boardsForMove.push(boardNode);
      }

    });
    this.boardsForMove = [...this.boardsForMove];
  }

  selectListToMove(event: any)
  {
    if (!event.node.isLeaf)
      event.node.isExpanded = !event.node.isExpanded
    if (event.node.key.startsWith("board"))
    {
      this.selectedForMove = "";
      this.selectedBoardForMove = event.node.key;
      let id = parseInt(event.node.key.toString().slice(6));
      this.kbs.getLabelList(id).subscribe();
    }
    else
    {
      this.selectedForMove = event.node.key;
      this.selectedListForMove = event.node.key;
      let theevent = event.node;
      while (theevent.parentNode)
      {
        if (theevent.key.startsWith("list"))
          this.selectedListForMove = theevent.key;
        else if (theevent.key.startsWith("board"))
        {
          this.selectedBoardForMove = theevent.key;
          let id = parseInt(this.selectedBoardForMove.toString().slice(6));
          this.kbs.getLabelList(id).subscribe();
        }

        theevent = theevent.parentNode;
      }
      event.node.isSelected = true;
    }
  }

  moveStartBoard()
  {
    let idboard = this.selectedBoardForMove ? parseInt(this.selectedBoardForMove.toString().slice(6)) : 0;
    if (idboard == 0)
      return;
    let moveinfos = new MoveListAnotherBoard();
    moveinfos.before = true;
    moveinfos.boardIdDest = idboard;
    moveinfos.listIdDest = 0;
    moveinfos.listIdSource = this.list.id;
    moveinfos.isCopy = this.action == 'copy';
    this.makeTheMove(moveinfos, "start-board");
  }

  moveEndBoard()
  {
    let idboard = this.selectedBoardForMove ? parseInt(this.selectedBoardForMove.toString().slice(6)) : 0;
    if (idboard == 0)
      return;
    let moveinfos = new MoveListAnotherBoard();
    moveinfos.before = false;
    moveinfos.boardIdDest = idboard;
    moveinfos.listIdDest = 0;
    moveinfos.listIdSource = this.list.id;
    moveinfos.isCopy = this.action == 'copy';
    this.makeTheMove(moveinfos, "end-board");
  }

  moveBeforeList()
  {
    let idboard = this.selectedBoardForMove ? parseInt(this.selectedBoardForMove.toString().slice(6)) : 0;
    let idlist = parseInt(this.selectedForMove.split('_')[1]);
    if (idboard == 0 || idlist == 0)
      return;
    let moveinfos = new MoveListAnotherBoard();
    moveinfos.before = true;
    moveinfos.boardIdDest = idboard;
    moveinfos.listIdDest = idlist;
    moveinfos.listIdSource = this.list.id;
    moveinfos.isCopy = this.action == 'copy';
    this.makeTheMove(moveinfos, "before-list");
  }

  moveAfterList()
  {
    let idboard = this.selectedBoardForMove ? parseInt(this.selectedBoardForMove.toString().slice(6)) : 0;
    let idlist = parseInt(this.selectedForMove.split('_')[1]);
    if (idboard == 0 || idlist == 0)
      return;
    let moveinfos = new MoveListAnotherBoard();
    moveinfos.before = false;
    moveinfos.boardIdDest = idboard;
    moveinfos.listIdDest = idlist;
    moveinfos.listIdSource = this.list.id;
    moveinfos.isCopy = this.action == 'copy';
    this.makeTheMove(moveinfos, "after-list");
  }

  makeTheMove(moveinfos: MoveListAnotherBoard, type: string)
  {
    this.kbs.moveListToAnotherBoard(moveinfos).subscribe((copylist: KanbanList) =>
    {
      let index = this.cds.selectedBoard.lists.findIndex(x => x.id == moveinfos.listIdSource);
      if (!moveinfos.isCopy)
        this.cds.selectedBoard.lists.splice(index, 1);
      if (this.cds.selectedBoard && moveinfos.boardIdDest == this.cds.selectedBoard.id)
      {
        let indexDest = 0;
        switch (type)
        {
          case "start-board":
            if (copylist)
              this.cds.selectedBoard.lists.splice(0, 0, copylist);
            else this.cds.selectedBoard.lists.splice(0, 0, this.list);
            break;
          case "end-board":
            if (copylist)
              this.cds.selectedBoard.lists.push(copylist);
            else this.cds.selectedBoard.lists.push(this.list);
            break;
          case "before-list":
            indexDest = this.cds.selectedBoard.lists.findIndex(x => x.id == moveinfos.listIdDest);
            if (copylist)
              this.cds.selectedBoard.lists.splice(indexDest, 0, copylist);
            else this.cds.selectedBoard.lists.splice(indexDest, 0, this.list);
            break;
          case "after-list":
            indexDest = this.cds.selectedBoard.lists.findIndex(x => x.id == moveinfos.listIdDest);
            if (copylist)
              this.cds.selectedBoard.lists.splice(indexDest + 1, 0, copylist);
            else this.cds.selectedBoard.lists.splice(indexDest + 1, 0, this.list);
            break;
        }
        this.kbs.kanbanBoardChange.next(null);
        this.kbs.modalMove.close();
      }
    });
  }

  getBoardColor(boardkey: string)
  {
    let board = this.boardsForMove.find(x => x.key == boardkey);
    if (!board)
      return "#eeeeee"
    return board.bgcolor;
  }

  cancelMove()
  {
    this.kbs.modalMove.close();
  }

  doNothing(event: any)
  {

  }

}
