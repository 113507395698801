import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit
{

  @Input('LABEL') public LABEL: string;
  @Input('VARIABLE') public VARIABLE: Date;
  @Input('HELP_TXT') public HELP_TXT: string;
  @Input('ISRECURRENT') public ISRECURRENT: boolean;
  @Input() MANDATORY = false;


  @Output() ON_CHANGE = new EventEmitter();
  @Output() VARIABLEChange = new EventEmitter<Date>();
  @Output() VALIDATE_FUNC = new EventEmitter();

  changedNgModel()
  {
    this.VARIABLEChange.emit(this.VARIABLE)
    this.ON_CHANGE.emit()
    this.validateFunctionCallback()
  }

  validateFunctionCallback()
  {
    this.VALIDATE_FUNC.emit();
  }

  constructor() { }

  ngOnInit()
  {
  }
}
