export class OtherPlanningRights
{
    id = 0;
    rights: PlanningRights = PlanningRights.None;
}

export enum PlanningRights
{
    None = 0,
    CreateAppointment = 1,
    UpdateAppointment = 2,
    DeleteAppointment = 4
}