// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kanban-card-edit-category-icon {
  vertical-align: middle;
}

.kanban-edit-card-category-title-span {
  margin-left: 15px;
  margin-bottom: 10px !important;
  font-size: 18px;
  font-weight: 700;
}

.select-credentials {
  width: 100%;
  margin-bottom: 5px;
}

.third-party-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}

.span-credentials {
  height: 40px;
}

.button-group {
  float: right;
}

.tp-description-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 15px;
  background-color: lightgrey;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  max-width: 300px;
  min-width: 100px;
  min-height: 31px;
}

.tp-description-textarea {
  max-width: 300px;
  float: right;
  display: none;
  margin-right: 15px;
  height: 400px;
}

.phone-number-call {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
}

.phone-number-call:hover {
  background-color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/app/kanban/components/kanban-card-third-party/kanban-card-third-party.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,iBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,0BAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,2BAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".kanban-card-edit-category-icon {\r\n  vertical-align: middle;\r\n}\r\n\r\n.kanban-edit-card-category-title-span {\r\n  margin-left: 15px;\r\n  margin-bottom: 10px !important;\r\n  font-size: 18px;\r\n  font-weight: 700;\r\n}\r\n\r\n.select-credentials {\r\n  width: 100%;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.third-party-title {\r\n  color: rgba(0, 0, 0, 0.45);\r\n  font-size: 12px;\r\n  line-height: 32px;\r\n}\r\n\r\n.span-credentials {\r\n  height: 40px;\r\n}\r\n\r\n.button-group {\r\n  float: right;\r\n}\r\n\r\n.tp-description-span {\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  margin: 0px 15px;\r\n  background-color: lightgrey;\r\n  padding: 5px 15px;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  float: right;\r\n  max-width: 300px;\r\n  min-width: 100px;\r\n  min-height: 31px;\r\n}\r\n\r\n.tp-description-textarea {\r\n  max-width: 300px;\r\n  float: right;\r\n  display: none;\r\n  margin-right: 15px;\r\n  height: 400px;\r\n}\r\n\r\n.phone-number-call {\r\n  cursor: pointer;\r\n  margin-bottom: 10px;\r\n  padding: 10px;\r\n}\r\n\r\n.phone-number-call:hover {\r\n  background-color: lightgray;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
