import { ChatDiscussion } from "./chat-discussion"
import { ChatMessage } from "./chat-message"

export class ChatParam
{
    // messageToReply: ChatMessage = new ChatMessage()
    messageList: ChatMessage[] = []
    isFloatingMessage = false
    isPrivateDiscussion = true
    // displayReply = false
    // messageToEdit: ChatMessage = new ChatMessage()
    // editing = false
    dataLoaded = false
    paramId: number = 0
    roomList: ChatDiscussion[] = []
    // memberDiscussion: User[] = []
    // memberDiscussionAvailable: User[] = []
    // idnewmessage = 0;
    // displayEditor = false
    oldLength: number = 0
    readIdmessages = false
}
