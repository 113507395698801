// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-number-container {
  margin-bottom: 10px;
  padding: 5px;
  cursor: pointer;
}

.phone-number-container:hover {
  background-color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/app/kanban/components/call-third-party/call-third-party.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,2BAAA;AACJ","sourcesContent":[".phone-number-container{\r\n    margin-bottom: 10px;\r\n    padding: 5px;\r\n    cursor: pointer;\r\n}\r\n\r\n.phone-number-container:hover{\r\n    background-color: lightgray;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
