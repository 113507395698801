import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageBlockerComponent } from './page-blocker.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
  imports: [
    CommonModule,
    NzCardModule,
    NzGridModule,
    TranslateModule
  ],
  declarations: [PageBlockerComponent],
  exports: [PageBlockerComponent]
})
export class PageBlockerModule { }
