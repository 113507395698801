import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { CacheDataService } from '../../../../../src/app/services/cache-data.service';
import { KanbanBoard } from '../../entities/Board';
import { KanbanCard } from '../../entities/Card';
import { KanbanComment } from '../../entities/Comment';
import { KanbanActionParameters } from '../../entities/kanban-action-parameters';
import { KanbanList } from '../../entities/List';
import { KanbanActionsService } from '../../services/kanban-actions.service';
import { KanbanToolsService } from '../../services/kanban-tools.service';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-card-comment',
  templateUrl: './kanban-card-comment.component.html',
  styleUrls: ['./kanban-card-comment.component.scss']
})
export class KanbanCardCommentComponent implements OnInit
{

  constructor(public auth: AuthService,
    private kbs: KanbanService,
    private cds: CacheDataService,
    private kas: KanbanActionsService) { }

  @Input() board: KanbanBoard;
  @Input() list: KanbanList;
  @Input() card: KanbanCard;
  focusedCommentOptions;
  commentTemp = "";
  commentsInEdition: KanbanComment[] = [];

  ngOnInit()
  {
  }

  cancelDeleteComment()
  {

  }

  deleteComment(comment: KanbanComment)
  {
    this.kbs.deleteComment(this.card.id, comment.id).subscribe(x =>
    {
      let index = this.card.comments.findIndex(x => x.id == comment.id);
      this.card.comments.splice(index, 1);
      this.card.comments = [...this.card.comments];
    });
  }

  EditComment(comment: KanbanComment)
  {
    let tempcomment = new KanbanComment();
    tempcomment.id = comment.id;
    tempcomment.comment = comment.comment;
    tempcomment.user = comment.user;
    tempcomment.date = comment.date;
    this.commentsInEdition.push(tempcomment);
  }

  CancelEditComment(comment: KanbanComment)
  {
    let com = this.commentsInEdition.find(x => x.id == comment.id);
    comment.comment = com.comment;
    let index = this.commentsInEdition.findIndex(x => x.id == comment.id);
    this.commentsInEdition.splice(index, 1);
  }

  SaveEditComment(comment: KanbanComment)
  {
    this.kbs.updateComment(this.card.id, comment.id, comment.comment).subscribe(x =>
    {
      let index = this.commentsInEdition.findIndex(x => x.id == comment.id);
      this.commentsInEdition.splice(index, 1);
    })
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (user)
      return user.avatar;
    return ""
  }

  getSortedComment()
  {
    return this.card.comments.sort((a, b) => a.date > b.date ? -1 : 1);
  }

  showEditComment(comment: KanbanComment)
  {
    return this.commentsInEdition.find(x => x.id == comment.id) != null;
  }

  addCommentPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanCardCommentComponent)
  {
    let comment = new KanbanComment();
    comment.date = new Date();
    comment.comment = comp.commentTemp;
    comment.user = comp.auth.connectedUser;

    comp.kbs.addCommentToCard(comp.card.id, comment).subscribe(async x =>
    {
      comment.id = x;
      comp.card.comments.splice(0, 0, comment);
      comp.commentTemp = "";
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
    });
  }

  AddComment()
  {
    //SERVER
    if (!this.commentTemp)
      return;
    this.kas.applyActions(this.card, this.list ? this.list.id : 0, this.board, "3", this.addCommentPostProcessing, this.cancelAction, this);
  }

  cancelAction(card: KanbanCard, comp: KanbanCardCommentComponent) { }

}
