import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFocusDirective } from './input-focus.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InputFocusDirective],
  exports: [InputFocusDirective]
})
export class InputFocusDirectiveModule { }
