import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanWorkflowSettingsComponent } from './kanban-workflow-settings.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { KanbanBoardTreeModule } from '../kanban-board-tree/kanban-board-tree.module';
import { NameUserPipeModule } from '../../../../../src/app/pipes/name-user-pipe/name-user-pipe.module';
import { NameTeamPipeModule } from '../../../../../src/app/pipes/name-team-pipe/name-team-pipe.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { KanbanManageTypeRelationModule } from '../kanban-manage-type-relation/kanban-manage-type-relation.module';
import { KanbanListActionsEditorModule } from '../kanban-list-actions-editor/kanban-list-actions-editor.module';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NgxGraphModule,
    TranslateModule,
    NzToolTipModule,
    NzSelectModule,
    FormsModule,
    NzInputModule,
    NzIconModule,
    NzButtonModule,
    NzPopoverModule,
    NzPopconfirmModule,
    KanbanBoardTreeModule,
    KanbanManageTypeRelationModule,
    NameUserPipeModule,
    NameTeamPipeModule,
    KanbanListActionsEditorModule
  ],
  declarations: [KanbanWorkflowSettingsComponent],
  exports: [KanbanWorkflowSettingsComponent]
})
export class KanbanWorkflowSettingsModule { }
