export class TodoSearchParams
{
    start = 0;
    number = 25;
    searchtext = "";
    showcompleted = false;
    showtodolist = true;
    showboard = true;
    showfloatingcards = true;
    labelFiltered: number[] = [];
    labelExiled: number[] = [];
    physicalPersonFiltered: number[] = [];
    physicalPersonExiled: number[] = [];
    moralPersonFiltered: number[] = [];
    moralPersonExiled: number[] = [];
    userFiltered: number[] = [];
    userExiled: number[] = [];
    teamFiltered: number[] = [];
    teamExiled: number[] = [];
}