export class GlobalLabel
{
    id: number = 0;
    title: string;
    color: string = "#2d3436";
    thirdPartyType: number;
    boardId: number;
    modules: number;
    creatorId: number;
    creationDate: Date;
}