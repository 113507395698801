// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-thirdparty {
  width: 300px;
}

.create-relation-button {
  margin-top: 10px;
  margin-right: 15px;
  display: inline-block;
}

.create-relation-title {
  font-weight: bold;
  margin-top: 10px;
}

.icon-set-source {
  margin-left: 15px;
}

.no-data {
  text-align: center;
  border: 1px solid black;
  display: table;
  width: 100%;
}

.no-data > div {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  font-size: 18px;
}

.memo {
  width: 300px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  padding-right: 15px;
  margin: 8px;
  background-color: rgba(237, 232, 134, 0.4901960784);
  display: inline-block;
  position: relative;
  vertical-align: top;
  min-height: 50px;
  cursor: pointer;
  box-shadow: 6px 6px 6px 3px #646464;
}`, "",{"version":3,"sources":["webpack://./src/app/third-party/physical-person/components/physical-person-relations/physical-person-relations.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,uBAAA;EACA,cAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;EACA,sBAAA;EACA,iBAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,mDAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,mCAAA;AACJ","sourcesContent":[".select-thirdparty {\r\n    width: 300px;\r\n}\r\n\r\n.create-relation-button {\r\n    margin-top: 10px;\r\n    margin-right: 15px;\r\n    display: inline-block;\r\n}\r\n\r\n.create-relation-title {\r\n    font-weight: bold;\r\n    margin-top: 10px;\r\n}\r\n\r\n.icon-set-source {\r\n    margin-left: 15px;\r\n}\r\n\r\n.no-data {\r\n    text-align: center;\r\n    border: 1px solid black;\r\n    display: table;\r\n    width: 100%;\r\n}\r\n\r\n.no-data>div {\r\n    display: table-cell;\r\n    vertical-align: middle;\r\n    font-weight: bold;\r\n    font-size: 18px;\r\n}\r\n\r\n.memo {\r\n    width: 300px;\r\n    border: 1px solid black;\r\n    border-radius: 5px;\r\n    padding: 5px;\r\n    padding-right: 15px;\r\n    margin: 8px;\r\n    background-color: #ede8867d;\r\n    display: inline-block;\r\n    position: relative;\r\n    vertical-align: top;\r\n    min-height: 50px;\r\n    cursor: pointer;\r\n    box-shadow: 6px 6px 6px 3px #646464;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
