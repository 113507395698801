import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerSoundAjusterComponent } from './player-sound-ajuster.component';
import { FormsModule } from '@angular/forms';
import { NzSliderModule } from 'ng-zorro-antd/slider';

@NgModule({
  imports: [
    CommonModule,
    NzSliderModule,
    FormsModule
  ],
  declarations: [PlayerSoundAjusterComponent],
  exports: [PlayerSoundAjusterComponent]
})
export class PlayerSoundAjusterModule { }
