// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.origin-user {
  color: #F08080;
}

.origin-team {
  color: rgb(140, 188, 69);
}

.origin-pp {
  color: #7ec3da;
}

.origin-ext {
  color: #737373;
}`, "",{"version":3,"sources":["webpack://./src/app/zoom/zoom-meeting-participants/zoom-meeting-participants.component.scss","webpack://./src/assets/style/variable.scss"],"names":[],"mappings":"AAEA;EACE,cCKQ;ADNV;;AAIA;EACE,wBCLgB;ADIlB;;AAIA;EACE,cCJQ;ADGV;;AAIA;EACE,cCXa;ADUf","sourcesContent":["@import '../../../assets/style/variable.scss';\r\n\r\n.origin-user {\r\n  color: $mpcolor;\r\n}\r\n\r\n.origin-team {\r\n  color: $mainactioncolor\r\n}\r\n\r\n.origin-pp {\r\n  color: $ppcolor;\r\n}\r\n\r\n.origin-ext {\r\n  color: $selectedmenu;\r\n}\r\n","//changer aussi la variable dans menu.component.ts\r\n$headersize: 80px;\r\n$mainactioncolor: rgb(140, 188, 69);\r\n$submenu: #2b2b2b;\r\n$selectedmenu: #737373;\r\n$mainmenu: black;\r\n$attentioncolor: red;\r\n$ppcolor: #7ec3da;\r\n$mpcolor: #F08080;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
