import { Component, OnInit, Input } from '@angular/core';
import { TypeRelation } from 'src/app/third-party/entities/type-relation';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-type-relation-edit',
  templateUrl: './kanban-type-relation-edit.component.html',
  styleUrls: ['./kanban-type-relation-edit.component.scss']
})
export class KanbanTypeRelationEditComponent implements OnInit {

  constructor(public kbs: KanbanService) { }

  @Input() typerelation: TypeRelation;
  
  nameSourceTarget = "";
  nameTargetSource = "";
  nameInvalid = false;
  description = "";
  tagValue = []

  ngOnInit() {
    if(this.typerelation != null)
    {
      this.nameSourceTarget = this.typerelation.nameSourceTarget;
      this.nameTargetSource = this.typerelation.nameTargetSource;
      this.description = this.typerelation.description;
      if(this.typerelation.actionsRelation)
        this.tagValue = this.typerelation.actionsRelation.split(";")
    }

  }

  submitForm()
  {
    if(!this.nameSourceTarget)
    {
      this.nameInvalid = true;
      return null;
    }
    else this.nameInvalid = false;
    
    let typerelationTemp = new TypeRelation();
    typerelationTemp.nameTargetSource = this.nameTargetSource;
    typerelationTemp.nameSourceTarget = this.nameSourceTarget;
    typerelationTemp.description = this.description;
    typerelationTemp.actionsRelation = this.tagValue.join(";");
    return typerelationTemp;
  }

}
