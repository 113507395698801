import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConflictAlertComponent } from './conflict-alert.component';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    PipesModule
  ],
  declarations: [ConflictAlertComponent],
  exports: [ConflictAlertComponent]
})
export class ConflictAlertModule { }
