export class MeetingParticipantInfos
{
    userid: number;
    teamid: number;
    ppid: number;
    mail: string;
    displayName: string;
    origin: string;
    joinDate: Date;
    leaveDate: Date;
}