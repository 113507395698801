// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  width: 200px;
  padding: 5px;
  height: 80px;
  background-color: black;
}

.logo-img {
  width: auto;
  display: block;
  margin: 10px 0 0 55px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/logo/logo.component.scss","webpack://./src/assets/style/variable.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,YAAA;EACA,YCJW;EDKX,uBCDS;ADAX;;AAIA;EACE,WAAA;EACA,cAAA;EACA,qBAAA;EACA,YAAA;AADF","sourcesContent":["@import '../../../assets/style/variable';\r\n\r\n.logo-container {\r\n  width: 200px;\r\n  padding: 5px;\r\n  height: $headersize;\r\n  background-color: $mainmenu;\r\n}\r\n\r\n.logo-img {\r\n  width: auto;\r\n  display: block;\r\n  margin: 10px 0 0 55px;\r\n  height: 50px;\r\n}\r\n","//changer aussi la variable dans menu.component.ts\r\n$headersize: 80px;\r\n$mainactioncolor: rgb(140, 188, 69);\r\n$submenu: #2b2b2b;\r\n$selectedmenu: #737373;\r\n$mainmenu: black;\r\n$attentioncolor: red;\r\n$ppcolor: #7ec3da;\r\n$mpcolor: #F08080;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
