import { KanbanAttachment } from "../../../../src/app/kanban/entities/Attachment";
import { MessagerieMailAction } from "./messagerie-mail-action";

export class Receivedmail
{
    id: number;
    to: string[] = [];
    from: string = "";
    pseudo: string = "";
    cc: string[] = [];
    bcc: string[] = [];
    replyTo: string[] = [];
    folderIds: number[] = [];
    subject: string;
    content: string;
    contentInnerText: string;
    deleted: boolean;
    attachments: KanbanAttachment[] = [];
    actions: MessagerieMailAction[] = [];
    createdUtc: Date;
    receivedUTC: Date;
    read: boolean;
    idAccount: number;
    readReceipt: boolean;
    readDate: Date;
    receptionReceipt = false;
    hasConversation = false;
    idConversation = 0;
    messageId = "";
    hasDiscussionThread = false;
}
