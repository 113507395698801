import { Injectable } from '@angular/core';
import { LogsSourceService } from '../source/logs-source.service';
import { DTOLog } from '../../../monitoring/entities/Log';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogsDataService
{

  constructor(private srcService: LogsSourceService) { }
  public logs: DTOLog[] = [];
  public nbLogPerPage = 100;

  public getLogs(dateBegin: Date, dateEnd: Date, listModule: number[], listCategory: number[], listAction: number[], listLevel: number[], ip: string, username: string, start: number, number: number, content: string)
  {
    return this.srcService.getLogs(dateBegin, dateEnd, listModule, listCategory, listAction, listLevel, ip, username, start, number, content).pipe(tap(x => this.logs = x),);
  }
  public GetPreference()
  {
    this.srcService.GetPreference().pipe(tap(x => this.nbLogPerPage = x ? parseInt(x) : 100)).subscribe();
  }
  public UpdateNbLogPagePreferences()
  {
    this.srcService.UpdateNbLogPagePreferences(this.nbLogPerPage + '').subscribe();
  }
}
