import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {},
];

export const SDICalendarRoutes = RouterModule.forChild(routes);


@NgModule({})
export class SDICalendarRoutingModule { }
