import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SignalrService } from '../../services/signalr.service';
import { tap } from 'rxjs/operators';
import { UserDataService } from '../../user/services/data/user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AvatarService } from '../../services/avatar.service';
import { SpotifyService } from '../../spotify/services/spotify.service';
import { ScriptService } from '../../scripts/script.service';
import { GlobalNotificationService } from '../../services/notification/global-notification.service';
import { UserNotification } from '../../entities/user-notification';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { User } from '../../user/model/user';
import { CacheDataService } from '../../services/cache-data.service';
import { ChatService } from '../../chat/chat.service';
import { NavbarService } from './navbar.service';
import { en_US, fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NotesService } from '../../notes/notes.service';
import { TextSearch } from '../../entities/search-text';
import { KanbanCard } from '../../kanban/entities/Card';
import { KanbanService } from '../../kanban/services/kanban.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KanbanToolsService } from '../../kanban/services/kanban-tools.service';
import { KanbanCardEditModalComponent } from '../../kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.component';
import { SettingsService } from '../../settings/services/settings.service';
declare var spotifyPlayerID: string;
declare var langBrowser: string;

declare function InitSpotifyWebPlayer(): any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit
{

  iduser: number;
  currentFlag;
  now: Date;
  filteredSeenNotification = true;
  paramHello = { v: this.auth.Access.user };

  dispoValue: string
  personnalStatus = "";
  personnalStatusVisible = false

  UserStatusVisible = false

  NotificationVisible = false;

  statusSubscription: Subscription

  userStatusList: Array<{ status: string, userName: string, userId: number }> = []
  favUserStatusList: Array<{ status: string, userName: string, userId: number }> = []

  you: User = new User()
  controlKeyPressed = false;

  constructor(public auth: AuthService,
    public signalr: SignalrService,
    private i18n: NzI18nService,
    private userService: UserDataService,
    private translateService: TranslateService,
    public avatarService: AvatarService,
    public ns: NotesService,
    private modalService: NzModalService,
    private kts: KanbanToolsService,
    public spotifyService: SpotifyService,
    private script: ScriptService,
    public cds: CacheDataService,
    public gbs: GlobalNotificationService,
    public kbs: KanbanService,
    private router: Router,
    public chatS: ChatService,
    private settingsService: SettingsService,
    private message: NzMessageService,
    public navbarService: NavbarService,
    private meta: Meta) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent)
  {
    if (event.ctrlKey)
      this.controlKeyPressed = true;

    if (event.altKey && event.key == "n")
    {
      this.ns.openCreateNoteModal();
      event.preventDefault();
      return;
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUPEvent(event: KeyboardEvent)
  {

    if (event.key == "Control")
      this.controlKeyPressed = false;

  }


  ngOnInit()
  {
    setInterval(() =>
    {
      this.now = new Date();
    }, 100);
    this.statusSubscription = this.navbarService.statusSub.subscribe(() =>
    {
      this.getUserListStatus()
    })
    this.settingsService.getSettingsByName("note_open_oncreation").subscribe(x => { this.kbs.openNoteOnCreation = x == 'true' });

    this.auth.getConnectedUser().subscribe(x =>
    {
      this.auth.connectedUser = x;
      this.userService.getAvatar(this.auth.connectedUser.id).subscribe(y => this.auth.connectedUserAvatar = y);
      this.you = this.auth.connectedUser;
    });

    if (this.spotifyService.Token)
    {
      this.script.load('spotifyWebPlayer').then(data =>
      {
        InitSpotifyWebPlayer();
      }).catch(error => console.log(error));
      this.spotifyService.getUserInfos().subscribe();
    }


    this.translateService.use(this.auth.Access.locale);
    this.auth.getConnectedUser().pipe(tap(c => this.iduser = c.id)).subscribe(x =>
    {
      this.userService.getAvatar(this.iduser).pipe(tap(d => { this.auth.connectedUserAvatar = d; })).subscribe();
    });
    setTimeout(() =>
    {
      this.getStatusConnexion();
    }, 500);
  }

  changeNotificationVisibility()
  {
    if (this.NotificationVisible)
    {
      setTimeout(() =>
      {
        this.NotificationVisible = false;
      }, 200);
      return;
    }
  }

  changeUserStatusVisibility()
  {
    if (this.UserStatusVisible)
    {
      setTimeout(() =>
      {
        this.UserStatusVisible = false;
      }, 200);
      return;
    }
  }

  createNote(open = false)
  {
    if (event)
      event.preventDefault();
    if (!this.ns.noteText.replace(/\n|\r|(\n\r)/g, '').trim())
    {
      this.ns.noteText = this.ns.noteText.replace(/\n|\r|(\n\r)/g, '');
      return false;
    }
    this.ns.noteText = this.ns.noteText.trim();
    this.ns.createNote(new TextSearch(this.ns.noteText)).subscribe(id =>
    {
      let note = new KanbanCard();
      note.id = id;
      note.creationDate = new Date();
      note.title = this.ns.noteText;
      this.ns.notes.push(note);
      this.ns.notes = [...this.ns.notes];
      this.ns.notevisible = false;
      this.ns.initLines();
      this.ns.reformHeight();
      this.createMessage("success", this.translater('NOTES.CREATE-SUCCESS'))

      if (open)
      {
        this.kbs.editedCard = note;
        let title = "";
        this.translateService.get("NOTES.EDIT-NOTE").subscribe(x => title = x);
        let close = "";
        this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
        this.kbs.modalEdit = this.modalService.create({
          nzTitle: title,
          nzContent: KanbanCardEditModalComponent,
          nzWidth: '100%',
          nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
          nzMaskClosable: false,
          nzAutofocus: null,
          nzFooter: [{
            label: close,
            onClick: () => { this.kbs.modalEdit.close(); }
          }
          ]
        });
        this.kbs.modalEdit.componentInstance.card = note;
        this.kbs.modalEdit.componentInstance.list = null;
        this.kbs.modalEdit.componentInstance.board = null;

        this.kbs.modalEdit.afterClose.subscribe((result) =>
        {
          this.kbs.editedCard = null;
          this.kts.resetEditModalField();
        });
      }
    })
  }

  openInNewTab(id)
  {
    let url = document.location.origin + "/chat/discussion/" + id
    var win = window.open(url, '_blank');
    win.focus();
  }

  getFilteredNotification()
  {
    if (this.filteredSeenNotification)
      return this.gbs.userNotifications.notifications.filter(x => !x.seen);
    else return this.gbs.userNotifications.notifications;
  }

  getUser(notif: UserNotification)
  {
    if (notif.trigger)
      return this.cds.userCollection.find(x => x.id == notif.trigger.id);
    return new User();
  }

  deviceSet()
  {
    return spotifyPlayerID != "" && this.spotifyService.Token;
  }

  openLangSelector()
  {
    let elem: HTMLElement = document.getElementById("langselector") as HTMLElement;
    elem.click();
  }

  setFlag()
  {
    switch (this.auth.Access.locale)
    {
      case "en":
        return "../assets/flag/en.png";
      case "fr":
        return "../assets/flag/fr.jpg";
      case "it":
        return "../assets/flag/it.jpg";
      case "de":
        return "../assets/flag/de.png";
      case "es":
        return "../assets/flag/es.jpg";
      case "pt":
        return "../assets/flag/pt.png";
    }
    return "";
  }

  getNotificationText(notif: UserNotification)
  {
    let text = "";
    if (!notif || !notif.codeText)
      return text;
    if (!notif.parameters)
      this.translateService.get(notif.codeText).subscribe(x => { text += x });
    else 
    {
      switch (notif.parameters.length)
      {
        case 0:
          this.translateService.get(notif.codeText).subscribe(x => { text += x });
          break;
        case 1:
          this.translateService.get(notif.codeText, { v: notif.parameters[0] }).subscribe(x => text = x);
          break;
        case 2:
          this.translateService.get(notif.codeText, { v1: notif.parameters[0], v2: notif.parameters[1] }).subscribe(x => text = x);
          break;
        case 3:
          this.translateService.get(notif.codeText, { v1: notif.parameters[0], v2: notif.parameters[1], v3: notif.parameters[2] }).subscribe(x => text = x);
          break;
        case 4:
          this.translateService.get(notif.codeText, { v1: notif.parameters[0], v2: notif.parameters[1], v3: notif.parameters[2], v4: notif.parameters[3] }).subscribe(x => text = x);
          break;
        case 5:
          this.translateService.get(notif.codeText, { v1: notif.parameters[0], v2: notif.parameters[1], v3: notif.parameters[2], v4: notif.parameters[3], v5: notif.parameters[4] }).subscribe(x => text = x);
          break;
        case 6:
          this.translateService.get(notif.codeText, { v1: notif.parameters[0], v2: notif.parameters[1], v3: notif.parameters[2], v4: notif.parameters[3], v5: notif.parameters[4], v6: notif.parameters[5] }).subscribe(x => text = x);
          break;
      }
    }

    return text;
  }

  filterNotifications()
  {
    this.filteredSeenNotification = !this.filteredSeenNotification;
  }
  changeNotificationStatus(notif: UserNotification)
  {
    if (!notif.seen)
    {
      this.gbs.setNotificationToNotSeen(notif.id).subscribe(x =>
      {
      });
    }
    else
    {
      this.gbs.setNotificationToSeen(notif.id).subscribe(x =>
      {
      });
    }
  }

  getLink(notif: UserNotification)
  {
    if (notif.routeLink)
    {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([notif.routeLink.startsWith('/') ? notif.routeLink : '/' + notif.routeLink]));
    }
  }

  setAllNotificationAsSeen()
  {
    this.gbs.setAllNotificationToSeen().subscribe(x =>
    {
      this.gbs.userNotifications.notifications.forEach(x => x.seen = true);
    });
  }

  getNumberUnreadNotif()
  {
    return this.gbs.userNotifications.notifications.filter(x => !x.seen).length;
  }

  getNumberUnreadMessageNotif()
  {
    return this.gbs.chatNotifications.length;
  }

  changeLang()
  {
    this.auth.updateLang(this.auth.Access.locale);
    this.translateService.use(this.auth.Access.locale);
    this.i18n.setLocale(this.auth.Access.locale == "fr" ? fr_FR : en_US)
    this.meta.updateTag({ name: "lang", content: this.auth.Access.locale });
  }

  redirectToNote()
  {
    event.preventDefault();
    if (this.controlKeyPressed)
      window.open(window.location.origin + "/notes", "_blank").focus();
    else
    {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/notes']));
    }

  }

  openUserStatus()
  {
    if (this.UserStatusVisible)
    {
      setTimeout(() =>
      {
        this.closeUserStatus();
      }, 250);

      return;
    }

    this.getUserListStatus()

    this.UserStatusVisible = true
  }

  getUserListStatus()
  {
    this.userStatusList = []

    let onlineStatusList: Array<{ status: string, userName: string, userId: number }> = []
    let backSoonStatusList: Array<{ status: string, userName: string, userId: number }> = []
    let busyStatusList: Array<{ status: string, userName: string, userId: number }> = []
    let notDisturbStatusList: Array<{ status: string, userName: string, userId: number }> = []
    let persoStatusList: Array<{ status: string, userName: string, userId: number }> = []
    let inactifStatusList: Array<{ status: string, userName: string, userId: number }> = []
    let outlineStatusList: Array<{ status: string, userName: string, userId: number }> = []

    this.cds.userCollection.forEach(user =>
    {
      if (user.actived == true && user.archived == false)
      {
        if (user.id == this.you.id)
          this.you = user
        else if (user.status == "$$-ONLINE-F1rst-$$")
          onlineStatusList.push({ status: user.status, userName: user.name + ' ' + user.surname, userId: user.id });
        else if (user.status == "$$-BACK-SOON-Thi3d-$$")
          backSoonStatusList.push({ status: user.status, userName: user.name + ' ' + user.surname, userId: user.id });
        else if (user.status == "$$-BUSY-S2cond-$$")
          busyStatusList.push({ status: user.status, userName: user.name + ' ' + user.surname, userId: user.id });
        else if (user.status == "$$-DO-NOT-DISTURB-Fourt4-$$")
          notDisturbStatusList.push({ status: user.status, userName: user.name + ' ' + user.surname, userId: user.id });
        else if (user.status == "$$-INACTIF-s6x-$$")
          inactifStatusList.push({ status: user.status, userName: user.name + ' ' + user.surname, userId: user.id });
        else if (user.status == "$$-APPEAR-OFFLINE-5ive-$$")
          outlineStatusList.push({ status: user.status, userName: user.name + ' ' + user.surname, userId: user.id });
        else
          persoStatusList.push({ status: user.status, userName: user.name + ' ' + user.surname, userId: user.id });
      }
    });

    onlineStatusList.sort((a, b) => (a.userName.toUpperCase()) > (b.userName.toUpperCase()) ? 1 : -1)
    backSoonStatusList.sort((a, b) => (a.userName.toUpperCase()) > (b.userName.toUpperCase()) ? 1 : -1)
    busyStatusList.sort((a, b) => (a.userName.toUpperCase()) > (b.userName.toUpperCase()) ? 1 : -1)
    notDisturbStatusList.sort((a, b) => (a.userName.toUpperCase()) > (b.userName.toUpperCase()) ? 1 : -1)
    inactifStatusList.sort((a, b) => (a.userName.toUpperCase()) > (b.userName.toUpperCase()) ? 1 : -1)
    persoStatusList.sort((a, b) => (a.userName.toUpperCase()) > (b.userName.toUpperCase()) ? 1 : -1)
    outlineStatusList.sort((a, b) => (a.userName.toUpperCase()) > (b.userName.toUpperCase()) ? 1 : -1)

    this.userStatusList = onlineStatusList.concat(backSoonStatusList, busyStatusList, notDisturbStatusList, inactifStatusList, persoStatusList, outlineStatusList);

    let favUserListId: number[] = []
    this.favUserStatusList = this.userStatusList.filter(x => favUserListId.includes(x.userId))
  }


  closeUserStatus()
  {
    this.UserStatusVisible = false
  }

  openPersonnalStatus()
  {
    if (this.personnalStatusVisible)
    {
      setTimeout(() =>
      {
        this.closePersonnalStatus();
      }, 250);

      return;
    }

    this.chatS.getConnexionStatus().subscribe(x =>
    {
      if (x > '0' && x < '7')
      {
        this.dispoValue = x
      }
      else
      {
        this.dispoValue = '0'
        this.personnalStatus = x;
      }
      this.personnalStatusVisible = true
    })
  }

  getStatusConnexion()
  {
    this.chatS.getConnexionStatus().subscribe(x =>
    {
      if (x > '0' && x < '7')
      {
        this.dispoValue = x
      }
      else
      {
        this.dispoValue = '7'
        this.personnalStatus = x;
      }
    })
  }

  closePersonnalStatus()
  {
    if (this.dispoValue == '0')
    {
      if (this.personnalStatus.length > 0 && this.personnalStatus.length <= 15)
      {
        this.personnalStatusVisible = false;
      }
      else
      {
        this.createMessage("error", this.translater('CHAT.DISPO.ERROR-STATUS-NAME'))
      }
    }
    else if (this.dispoValue > '0' && this.dispoValue < '7')
    {
      this.personnalStatusVisible = false;
    }
    else
    {
      this.createMessage("error", this.translater('CHAT.DISPO.ERROR-STATUS'))
    }
  }

  setNewConnexionStatus()
  {
    this.chatS.setNewConnexionStatus(this.dispoValue == '0' ? this.personnalStatus : this.dispoValue).subscribe(x =>
    { })
  }

  timeoutfilter;
  startWriteStatus(statusText: string)
  {
    this.personnalStatus = statusText;
    if (this.timeoutfilter)
      clearTimeout(this.timeoutfilter);
    this.timeoutfilter = setTimeout(() =>
    {
      if (this.personnalStatus.length > 0)
        this.setNewConnexionStatus();
    }, 500);

  }

  colorIcon

  getId(dispoValue)
  {
    switch (dispoValue)
    {
      case '1':
        return 'F1rst'
      case '2':
        return 'S2cond'
      case '3':
        return 'Thi3d'
      case '4':
        return 'Fourt4'
      case '5':
        return 'Fi5e'
      case '6':
        return 's6x'
      default:
        return 'pers0'
    }
  }

  createMessage(type: string, message: string): void
  {
    this.message.create(type, message);
  }

  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }

}
