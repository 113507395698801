// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spotify-search-cards {
  margin: 5px;
}

.image-playlist-header {
  height: 50px;
  width: 50px;
}

.title-spotify-header {
  font-size: 20px;
  font-weight: bold;
}

.side-spotify-header {
  font-size: 14px;
}

.container-playlist-header {
  padding-left: 25px;
}

.row-res-search {
  margin-bottom: 15px;
}

.ant-row {
  margin-bottom: 15px;
}

.search-result-container {
  height: 380px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/search-spotify/search-spotify.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AAAA;EACI,mBAAA;AAGJ;;AAAA;EACI,mBAAA;AAGJ;;AADA;EACI,aAAA;EACA,cAAA;AAIJ","sourcesContent":[".spotify-search-cards{\r\n    margin:5px;\r\n}\r\n\r\n.image-playlist-header{\r\n    height:50px;\r\n    width:50px;\r\n}\r\n\r\n.title-spotify-header{\r\n    font-size:20px;\r\n    font-weight: bold;\r\n}\r\n\r\n.side-spotify-header{\r\n    font-size:14px;\r\n}\r\n.container-playlist-header{\r\n    padding-left: 25px;\r\n}\r\n.row-res-search{\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.ant-row{\r\n    margin-bottom: 15px;\r\n}\r\n.search-result-container{\r\n    height:380px;\r\n    overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
