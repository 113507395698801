import { MeetingMembers } from "./meeting-members";

export class MeetingInfos
{
    id: string;
    name: string;
    password: string;
    signature: string;
    createdUtc: Date;
    temp: boolean = false;
    startDate: Date;
    duration: number;
    reminders: string[] = [];
    participants: MeetingMembers = new MeetingMembers();
    hostId: number;
    /*
    0   :   Waiting
    1   :   Started
    2   :   Closing
    3   :   Closed
    */
    status: number;
    endDate: Date;
    realStartDate: Date;
}