import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MailAccount } from '../../../../src/app/messagerie/entities/mail-account';
import { Receivedmail } from '../../../../src/app/messagerie/entities/receivedmail';
import { MessagerieFolderService } from '../../../../src/app/messagerie/messagerie-folder.service';
import { MessagerieService } from '../../../../src/app/messagerie/messagerie.service';
import { TextSearch } from '../../entities/search-text';
import { UrlApiService } from '../url-api.service';

@Injectable({
  providedIn: 'root'
})
export class MessagerieNotificationService
{

  constructor(private mss: MessagerieService,
    private http: HttpClient, private mfs: MessagerieFolderService, private modalService: NzModalService,
    private translateService: TranslateService) { }

  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('StartRefreshingMail', (mail: any) =>
    {
      this.mss.isRefreshing = true;
    });

    _hubConnection.on('FinishedRefreshingMail', (mail: any) =>
    {
      this.mss.isRefreshing = false;
    });

    _hubConnection.on('ReloadMails', (mail: any) =>
    {
      console.log("allo");

      this.mss.reloadmails.next(null);
    });

    _hubConnection.on('UpdateOnAccountRecuperation', (current: number, max: number, foldername: string) =>
    {
      this.mss.progress = Math.round((current / max) * 100);
      this.mss.texttoshow = current + "/" + max;
      this.mss.foldername = foldername;
      console.log(this.mss.progress)
      console.log(this.mss.texttoshow + " - " + this.mss.foldername)
    });

    _hubConnection.on('ServerMailUnavailable', (account: MailAccount) =>
    {
      if (this.mss.unavailableAccount.findIndex(x => x.id == account.id) < 0)
      {
        this.mss.unavailableAccount.push(account);
      }
    });

    _hubConnection.on('ReadMail', (idfolder: number) =>
    {
      this.mss.getUnreadTotalCount().subscribe(t => this.mss.totalUnread = t);
      this.mss.reloadmenu.next(true);
    });

    _hubConnection.on('ServerMailAvailable', (account: MailAccount) =>
    {
      let index = this.mss.unavailableAccount.findIndex(x => x.id == account.id);
      if (index >= 0)
      {
        this.mss.unavailableAccount.splice(index, 1);
      }
    });

    _hubConnection.on('UnloadOldMails', (nope: any) =>
    {
      if (document.location.href.indexOf("messaging/inbox") >= 0)
      {
        let title = "";

        let content = "";
        let ok = "";
        let cancel = "";
        this.translateService.get("MESSAGERIE.INBOX.UNLOAD-MAIL-TITLE").subscribe(x => title = x);
        this.translateService.get("MESSAGERIE.INBOX.UNLOAD-MAIL-CONTENT").subscribe(x => content = x);
        this.translateService.get("GENERIC-ACTIONS.YES").subscribe(x => ok = x);
        this.translateService.get("GENERIC-ACTIONS.NO").subscribe(x => cancel = x);
        let modal = this.modalService.create({
          nzTitle: title,
          nzContent: content,
          nzOkText: ok,
          nzOnOk: () =>
          {
            this.mss.unloadOldMails().subscribe(() =>
            {
              this.mss.reloadmails.next(null);
              modal.close();
            });
          },
          nzCancelText: cancel,
          nzOnCancel: () => { modal.close() }
        });

      }

    });

    _hubConnection.on('NotifyPickupMail', (mail: Receivedmail[]) =>
    {
      this.mss.getUnreadTotalCount().subscribe(t => this.mss.totalUnread = t);
      console.log(mail);

      this.mss.reloadmenu.next(true);
      mail.forEach(x =>
      {
        let textitem = new TextSearch(x.subject);
        this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/reception-check`, textitem).subscribe();


        if (this.mss.selectedFolder)
        {
          if (x.folderIds.findIndex(i => i == this.mss.selectedFolder.id) >= 0)
          {
            //this.mss.myMail.unshift(x);
            this.mss.pickupmailUpdate.next([x]);
            if (this.mss.openMailInd >= 0)
              this.mss.openMailInd++;
          }
          else this.mss.unreadInbox++;
        }
        else if (this.mss.selectedVirtualFolder)
        {

          let update = false;

          for (let realfol of this.mss.selectedVirtualFolder.realFolderIds)
          {
            if (x.folderIds.indexOf(realfol) >= 0)
            {
              update = true;
            }

          }
          if (update)
          {
            this.mss.pickupmailUpdate.next([x]);
            if (this.mss.openMailInd >= 0)
              this.mss.openMailInd++;
          }
        }
        else if (this.mss.selectedUnread)
        {
          this.mss.pickupmailUpdate.next([x]);
          if (this.mss.openMailInd >= 0)
            this.mss.openMailInd++;
        }
        else
        {
          if (this.mss.selectedProfiles.indexOf(x.idAccount) >= 0 && x.folderIds.length == 0)
          {
            //this.mss.myMail.unshift(x);
            this.mss.pickupmailUpdate.next([x]);
            if (x.deleted)
              this.mss.unreadDeleted++;
            else this.mss.unreadInbox++;
            if (this.mss.openMailInd >= 0)
              this.mss.openMailInd++;
          }
        }

      })
    });
  }
}
