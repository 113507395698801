export class MenuItem
{
    id: number;
    backgroundColor: string;
    fontColor: string;
    visible: boolean;
    title: string;
    route: string;
    position: number;
    originId: number;
    iduser: number;
}