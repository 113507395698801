import { PhoneNumber } from "./phone-number";

export class Site
{
    id: number;
    name: string;
    address: string;
    countryCode: string;
    archived: boolean;
    isUpdated: boolean = false;
    creatorId: number;
    creationDate: Date;
    origin: string = "manual";

    phoneNumbers: PhoneNumber[] = [];


}