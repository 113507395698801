// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterOn {
  color: rgb(140, 188, 69) !important;
}

.alreadyExist > td {
  background-color: #F08080;
}`, "",{"version":3,"sources":["webpack://./src/app/third-party/moral-person/components/search-client-info/search-client-info.component.scss","webpack://./src/assets/style/variable.scss"],"names":[],"mappings":"AAEA;EACE,mCAAA;AADF;;AAIA;EACE,yBCCQ;ADFV","sourcesContent":["@import '../../../../../assets/style/variable.scss';\r\n\r\n.filterOn {\r\n  color: $mainactioncolor !important;\r\n}\r\n\r\n.alreadyExist>td {\r\n  background-color: $mpcolor;\r\n}\r\n","//changer aussi la variable dans menu.component.ts\r\n$headersize: 80px;\r\n$mainactioncolor: rgb(140, 188, 69);\r\n$submenu: #2b2b2b;\r\n$selectedmenu: #737373;\r\n$mainmenu: black;\r\n$attentioncolor: red;\r\n$ppcolor: #7ec3da;\r\n$mpcolor: #F08080;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
