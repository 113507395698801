import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooglerComponent } from './toogler.component';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzFormModule,
    NzRadioModule,
    NzSwitchModule,
    FormsModule
  ],
  declarations: [TooglerComponent],
  exports: [TooglerComponent]
})
export class TooglerModule { }
