import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowseSpotifyComponent } from './browse-spotify.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

@NgModule({
  imports: [
    CommonModule,
    NzCardModule,
    NzTabsModule,
    NzAvatarModule,
    TranslateModule
  ],
  declarations: [BrowseSpotifyComponent],
  exports: [BrowseSpotifyComponent]
})
export class BrowseSpotifyModule { }
