import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { MessagerieSearchParameters } from '../messagerie/entities/messagerie-search-parameteres';
import { UrlApiService } from '../services/url-api.service';
import { AdvancedProspectionParameters } from './entities/advanced-prospection-parameters';
import { ImportProspectionColumn, ImportProspectionDataPreview, ImportProspectionPreview } from './entities/import-prospection';
import { ProspectionFile, ProspectionFileSchema } from './entities/prospection-file';
import { SubFileCreationParameters } from './entities/prospection-subfile-creation-param';
import { UpdateChildFileDatas, UpdateFileLines } from './entities/update-file-line';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ProspectionService
{

  constructor(private http: HttpClient) { }

  prospectionFiles: ProspectionFile[] = [];
  modifmodal: NzModalRef;
  selectedProspectionFile: ProspectionFile;
  selectedFileSearches: AdvancedProspectionParameters[] = [];
  prospectionDatas: ImportProspectionPreview;
  importProgressText = "";
  imporProgressState: 'success' | 'info' | 'warning' | 'error' = "info";
  cancreate = false;
  columnTypes = [0, 1, 2, 3, 4, 5, 6]
  isLoading = false;

  getColumns()
  {
    if (this.prospectionDatas.file.schema)
    {
      return this.prospectionDatas.file.schema.columns;
    }
    return this.prospectionDatas.columns;
  }

  getAllFiles()
  {
    return this.http.get<ProspectionFile[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/prospection/files').pipe(tap({ next: (files) => this.prospectionFiles = files }));
  }

  getDatasForFiles(idfile: number, start: number, nb: number, filtertext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = filtertext;
    return this.http.post<ImportProspectionPreview>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/start/${start}/number/${nb}`, filters);
  }

  createSubfile(idfile: number, params: SubFileCreationParameters)
  {
    return this.http.post<ImportProspectionPreview>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/sub-file`, params);
  }

  searchDatasForFiles(idfile: number, start: number, nb: number, filtertext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = filtertext;
    return this.http.post<ImportProspectionPreview>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/start/${start}/number/${nb}/search`, filters);
  }

  advancedSearchDatasForFiles(idfile: number, start: number, nb: number, filters: AdvancedProspectionParameters)
  {
    return this.http.post<ImportProspectionPreview>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/start/${start}/number/${nb}/advanced-search`, filters);
  }

  saveSearchForFile(idfile: number, filters: AdvancedProspectionParameters)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/save-advanced-search`, filters);
  }

  deleteSearchForFile(idsearch: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/save-advanced-search/${idsearch}`);
  }

  getFileModifications(idfile: number)
  {
    return this.http.get<UpdateFileLines[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/modifications`);
  }

  getFileChildModifications(idfile: number)
  {
    return this.http.get<UpdateChildFileDatas>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/child-modifications`);
  }

  updateFileLine(idfile: number, updatedata: ImportProspectionPreview)
  {
    return this.http.put<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/line`, updatedata);
  }

  updateFileLineMerge(idfile: number, updatedata: ImportProspectionPreview)
  {
    return this.http.put<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/line-merge`, updatedata);
  }

  updateFileSchema(idfile: number, updatedata: ProspectionFileSchema)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/schema`, updatedata);
  }

  applyLineMerge(idfile: number, updatedata: ImportProspectionDataPreview)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/apply-merge`, updatedata);
  }

  applyLineModifications(idfile: number, updatedata: ImportProspectionDataPreview[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/apply-modifications`, updatedata);
  }

  cancelLineMerge(idfile: number, updatedata: ImportProspectionDataPreview)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/cancel-merge`, updatedata);
  }

  cancelLineModifications(idfile: number, idline: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/modifications/${idline}`);
  }

  getallSearchDatasForFiles(idfile: number)
  {
    return this.http.get<AdvancedProspectionParameters[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/prospection/files/${idfile}/save-advanced-search`).pipe(tap({ next: (files) => this.selectedFileSearches = files }));
  }

  public AskProspectionFilePreview(file: File)
  {
    let formData: FormData = new FormData();
    if (file)
      formData.append("File", file, file.name);
    return this.http.post<ImportProspectionPreview>(UrlApiService.settings.apiConfig.uriAPI + '/api/prospection/import-preview', formData);
  }

  ConfirmProspectionFileImport(file: File, columns: ImportProspectionPreview)
  {
    let formData: FormData = new FormData();
    if (file)
      formData.append("File", file, file.name);
    formData.append("Columns", JSON.stringify(columns));
    return this.http.post<ImportProspectionPreview>(UrlApiService.settings.apiConfig.uriAPI + '/api/prospection/import-confirm', formData);
  }

}
