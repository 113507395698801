import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleLabelPipe } from './label-title-pipe.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TitleLabelPipe],
  exports: [TitleLabelPipe],
  providers: [TitleLabelPipe],
})
export class LabelTitlePipeModule { }
