import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditListStyleComponent } from './edit-list-style.component';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'src/app/components/color-picker/color-picker.module';
import { NzFormModule } from 'ng-zorro-antd/form';

@NgModule({
  imports: [
    CommonModule,
    NzFormModule,
    TranslateModule,
    ColorPickerModule,
  ],
  declarations: [EditListStyleComponent],
  exports: [EditListStyleComponent]
})
export class EditListStyleModule { }
