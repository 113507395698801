export enum LogAction{
    create,
    update,
    delete,
    archive,
    select,
    login,
    logout,
    append,
    remove,
    restore,
    notify
}