import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchClientInfoComponent } from './search-client-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@NgModule({
  imports: [
    CommonModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    TranslateModule,
    NzSpinModule,
    NzTableModule,
    NzDropDownModule,
    FormsModule,
    NzAlertModule,
    NzToolTipModule,
    NzIconModule,
    PipesModule
  ],
  declarations: [SearchClientInfoComponent],
  exports: [SearchClientInfoComponent]
})
export class SearchClientInfoModule { }
