import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kanban_card_check_due_date'
})
export class KanbanCardCheckDueDatePipe implements PipeTransform {

  transform(duedate: Date): any {
    if (duedate) {
      let due = new Date(duedate);
      return due.setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
    }
  }

}
