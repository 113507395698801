import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardThirdPartyComponent } from './kanban-card-third-party.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FormsModule } from '@angular/forms';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ThirdPartySheetComponent } from 'src/app/call-interface/third-party-sheet/third-party-sheet.component';
import { THIRD_PARTY_SHEET } from 'src/app/call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ThirdPartyNamePipeModule } from 'src/app/pipes/third-party/third-party-name-pipe/third-party-name-pipe.module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    TranslateModule,
    NzSelectModule,
    NzButtonModule,
    NzToolTipModule,
    FormsModule,
    NzPopoverModule,
    NzPopconfirmModule,
    NzIconModule,
    NzInputModule,
    ThirdPartyNamePipeModule
  ],
  declarations: [KanbanCardThirdPartyComponent],
  exports: [KanbanCardThirdPartyComponent],
  providers: [{
    provide: THIRD_PARTY_SHEET, useValue: ThirdPartySheetComponent,
  }]
})
export class KanbanCardThirdPartyModule { }
