// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-wrapper {
  width: 274px;
}

.palette {
  display: inline-block;
}

.slider {
  margin-left: 8px;
  display: inline-block;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/color-picker/color-picker.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEE;EACE,qBAAA;AACJ;;AAEE;EACE,gBAAA;EACA,qBAAA;AACJ;;AAEE;EACE,2BAAA;EACA,yBAAA;EAIA,iBAAA;AACJ","sourcesContent":[".color-wrapper {\r\n    width: 274px;\r\n  }\r\n  \r\n  .palette {\r\n    display: inline-block;\r\n  }\r\n  \r\n  .slider {\r\n    margin-left: 8px;\r\n    display: inline-block;\r\n  }\r\n  \r\n  .noSelect {\r\n    -webkit-touch-callout: none;\r\n    -webkit-user-select: none;\r\n    -khtml-user-select: none;\r\n    -moz-user-select: none;\r\n    -ms-user-select: none;\r\n    user-select: none;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
