import { Component, OnInit } from '@angular/core';
import { MessagerieService } from '../messagerie.service';

@Component({
  selector: 'app-messagerie-import-progress',
  templateUrl: './messagerie-import-progress.component.html',
  styleUrls: ['./messagerie-import-progress.component.scss']
})
export class MessagerieImportProgressComponent implements OnInit
{

  constructor(public mss: MessagerieService) { }
  visibility = true;

  ngOnInit()
  {
  }

}
