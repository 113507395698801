import Quill from 'quill';

let BlockEmbed = Quill.import('blots/block/embed');


export class Signature extends BlockEmbed
{
    static blotName = 'action';
    static className = 'block-action';
    static tagName = 'div';
    static create(value)
    {
        const node = super.create(value);
        //node.contentEditable = 'false';
        this._addSignature(node, value);
        return node;
    }

    static value(node)
    {
        return node.getAttribute(Signature.blotName)
    }

    static _addSignature(node, value)
    {
        node.innerHTML = value;
    }

    static _addSignature1(node)
    {
        const div = document.createElement('DIV');
        div.textContent = 'Signature with image';
        const img = document.createElement('IMG');

        node.appendChild(div);
        node.appendChild(img);
    }
}
Signature.blotName = 'signature';
Signature.tagName = 'DIV';
Signature.className = 'ql-signature';