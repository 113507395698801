import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';


const appRoutes: Routes = [
    { path: '', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) },

    { path: 'chat', loadChildren: () => import('./chat/chat-menu/chat-menu.module').then(m => m.ChatMenuModule) },

    { path: 'whatsapp', loadChildren: () => import('./chat/whatsapp/whatsapp.module').then(m => m.WhatsappModule) },

    { path: 'global-label', loadChildren: () => import('./global-label/global-label.module').then(m => m.GlobalLabelModule) },

    { path: 'video', loadChildren: () => import('./zoom/zoom.module').then(m => m.ZoomModule) },

    { path: 'prospection', loadChildren: () => import('./prospection/prospection.module').then(m => m.ProspectionModule) },

    { path: 'facturation', loadChildren: () => import('./facturation/facturation.module').then(m => m.FacturationModule) },
    { path: 'facturation/account', loadChildren: () => import('./facturation/facturation-account/facturation-account.module').then(m => m.FacturationAccountModule) },
    { path: 'facturation/client', loadChildren: () => import('./facturation/facturation-client/facturation-client.module').then(m => m.FacturationClientModule) },
    { path: 'facturation/cdr', loadChildren: () => import('./facturation/facturation-cdr/facturation-cdr.module').then(m => m.FacturationCdrModule) },

    { path: 'video-call', loadChildren: () => import('./video-call/video-call.module').then(m => m.VideoCallModule) },

    { path: 'messaging/inbox', loadChildren: () => import('./messagerie/messagerie-inbox/messagerie-inbox.module').then(m => m.MessagerieInboxModule) },
    { path: 'messaging/sended', loadChildren: () => import('./messagerie/messagerie-sended/messagerie-sended.module').then(m => m.MessagerieSendedModule) },
    { path: 'messaging/settings', loadChildren: () => import('./messagerie/messagerie-settings/messagerie-settings.module').then(m => m.MessagerieSettingsModule) },

    { path: 'call-interface', loadChildren: () => import('./call-interface/call-interface.module').then(m => m.CallInterfaceModule) },
    { path: 'call-interface/profile', loadChildren: () => import('./call-interface/call-interface-profile/call-interface-profile.module').then(m => m.CallInterfaceProfileModule) },
    { path: 'call-interface/third-party-sheet', loadChildren: () => import('./call-interface/third-party-sheet/third-party-sheet.module').then(m => m.ThirdPartySheetModule) },
    { path: 'call-interface/setting', loadChildren: () => import('./call-interface/call-interface-setting/call-interface-setting.module').then(m => m.CallInterfaceSettingModule) },

    { path: 'security/black-list-ip', loadChildren: () => import('./ip-address/black-list-ip/black-list-ip.module').then(m => m.BlackListIpModule) },
    { path: 'security/white-list-ip', loadChildren: () => import('./ip-address/white-list-ip/white-list-ip.module').then(m => m.WhiteListIpModule) },
    { path: 'security/group-ip', loadChildren: () => import('./ip-address/group-ip/group-ip.module').then(m => m.GroupIpModule) },
    { path: 'security/as-ip', loadChildren: () => import('./ip-address/as-ip/as-ip.module').then(m => m.AsIpModule) },
    { path: 'security/ip-settings', loadChildren: () => import('./ip-address/ip-settings/ip-settings.module').then(m => m.IpSettingsModule) },
    { path: 'security/simulator', loadChildren: () => import('./ip-address/simulator/simulator.module').then(m => m.SimulatorModule) },
    { path: 'security/attack-ip', loadChildren: () => import('./ip-address/attack-ip/attack-ip.module').then(m => m.AttackIpModule) },
    { path: 'security/settings-user', loadChildren: () => import('./ip-address/settings-user/settings-user.module').then(m => m.SettingsUserModule) },

    { path: 'third-party/physical', loadChildren: () => import('./third-party/physical-person/components/physical-person-home/physical-person-home.module').then(m => m.PhysicalPersonHomeModule) },
    { path: 'third-party/custom-system-fields', loadChildren: () => import('./third-party/components/third-party-custom-system-field-manager/third-party-custom-system-field-manager.module').then(m => m.ThirdPartyCustomSystemFieldManagerModule) },
    { path: 'third-party/moral', loadChildren: () => import('./third-party/moral-person/components/moral-person-home/moral-person-home.module').then(m => m.MoralPersonHomeModule) },
    { path: 'third-party/site', loadChildren: () => import('./third-party/site/components/site-home/site-home.module').then(m => m.SiteHomeModule) },
    { path: 'third-party/type-relation', loadChildren: () => import('./third-party/type-relation/components/type-relation-home/type-relation-home.module').then(m => m.TypeRelationHomeModule) },
    { path: 'third-party/relations', loadChildren: () => import('./third-party/physical-person/components/third-party-global-relations/third-party-global-relations.module').then(m => m.ThirdPartyGlobalRelationsModule) },
    { path: 'third-party/label', loadChildren: () => import('./third-party/components/third-party-label/third-party-label.module').then(m => m.ThirdPartyLabelModule) },
    { path: 'third-party/global-filter', loadChildren: () => import('./third-party/components/third-party-global-filter-home/third-party-global-filter-home.module').then(m => m.ThirdPartyGlobalFilterHomeModule) },

    { path: 'kanban/type-relation', loadChildren: () => import('./kanban/components/kanban-manage-type-relation/kanban-manage-type-relation.module').then(m => m.KanbanManageTypeRelationModule) },
    { path: 'kanban/todo', loadChildren: () => import('./kanban/components/todo-home/todo-home.module').then(m => m.TodoHomeModule) },
    { path: 'kanban/settings', loadChildren: () => import('./kanban/components/kanban-notification/kanban-notification.module').then(m => m.KanbanNotificationModule) },
    { path: 'kanban/public-label', loadChildren: () => import('./kanban/components/kanban-manage-public-label/kanban-manage-public-label.module').then(m => m.KanbanManagePublicLabelModule) },
    { path: 'kanban/board', loadChildren: () => import('./kanban/components/kanban-board/kanban-board.module').then(m => m.KanbanBoardModule) },
    { path: 'kanban/card-template', loadChildren: () => import('./kanban/components/kanban-card-template-home/kanban-card-template-home.module').then(m => m.KanbanCardTemplateHomeModule) },
    { path: 'kanban', loadChildren: () => import('./kanban/kanban.module').then(m => m.KanbanModule) },

    { path: 'credentials', loadChildren: () => import('./credentials/components/credentials/credentials.module').then(m => m.CredentialsModule) },
    { path: 'credentials/admin', loadChildren: () => import('./credentials/components/credentials-admin/credentials-admin.module').then(m => m.CredentialsAdminModule) },

    { path: 'group-credentials', loadChildren: () => import('./group-credentials/components/group-credentials/group-credentials.module').then(m => m.GroupCredentialsModule) },

    { path: 'contract', loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule) },

    { path: 'user', loadChildren: () => import('./user/components/active-user/active-user.module').then(m => m.ActiveUserModule) },
    { path: 'user/profile', loadChildren: () => import('./user/components/profile/profile.module').then(m => m.ProfileModule) },
    { path: 'user/admin', loadChildren: () => import('./user/components/user-admin/user-admin.module').then(m => m.UserAdminModule) },

    { path: 'group-user', loadChildren: () => import('./group-user/components/active-group-user/active-group-user.module').then(m => m.ActiveGroupUserModule) },

    { path: 'monitoring', loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule) },

    { path: 'settings', loadChildren: () => import('./settings/components/settings-general/settings-general.module').then(m => m.SettingsGeneralModule) },

    { path: 'settings/rights', loadChildren: () => import('./admin-manager/admin-manager.module').then(m => m.AdminManagerModule) },
    { path: 'settings/rights/members', loadChildren: () => import('./admin-manager/components/admin-membership/admin-membership.module').then(m => m.AdminMembershipModule) },
    { path: 'settings/rights/super-admin', loadChildren: () => import('./admin-manager/components/super-admin-manager/super-admin-manager.module').then(m => m.SuperAdminManagerModule) },
    { path: 'settings/rights/module-access', loadChildren: () => import('./admin-manager/components/module-access/module-access.module').then(m => m.ModuleAccessModule) },

    { path: 'spotify', loadChildren: () => import('./spotify/spotify.module').then(m => m.SpotifyModule) },

    { path: 'notes', loadChildren: () => import('./notes/note-home/note-home.module').then(m => m.NoteHomeModule) },

    { path: 'glossary/entries', loadChildren: () => import('./glossary/components/glossary-entry/glossary-entry.module').then(m => m.GlossaryEntryModule) },
    { path: 'glossary/categories', loadChildren: () => import('./glossary/components/glossary-category/glossary-category.module').then(m => m.GlossaryCategoryModule) },

    { path: 'reservation', loadChildren: () => import('./reservation/reservation.module').then(m => m.ReservationModule) },
    { path: 'reservation/timeline', loadChildren: () => import('./reservation/components/reservation-timeline/reservation-timeline.module').then(m => m.ReservationTimelineModule) },
    { path: 'reservation/resource', loadChildren: () => import('./reservation/components/resources/resources.module').then(m => m.ResourcesModule) },

    { path: 'form-manager', loadChildren: () => import('./form-manager/form-manager.module').then(m => m.FormManagerModule) },

    { path: 'meet', loadChildren: () => import('./meet/meet.module').then(m => m.MeetModule) },
    { path: 'changelog', loadChildren: () => import('./components/changelog/changelog.module').then(m => m.ChangelogModule) },
    { path: 'attachments', loadChildren: () => import('./components/attachment-viewer/attachment-viewer.module').then(m => m.AttachmentViewerModule) },
    { path: 'planning', loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule) },
    { path: 'change-password', loadChildren: () => import('./components/change-password/change-password.module').then(m => m.ChangePasswordModule) },

];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            urlUpdateStrategy: "eager"
        })
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }
