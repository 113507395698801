import { Injectable } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorService
{

  constructor() { }

  notifyColorChange: Subject<string> = new Subject<string>();

  toogleWhite(hexcolor)
  {
    if (!hexcolor)
      return false;

    if (hexcolor.startsWith("#"))
      hexcolor = hexcolor.substr(1);
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    if ((r * 0.299 + g * 0.587 + b * 0.114) > 120)
      return true;
    return false
  }

  getFontColor(hexcolor: string)
  {
    if (!hexcolor || typeof hexcolor != "string")
      return 'white';
    if (hexcolor.startsWith("#"))
      hexcolor = hexcolor.substr(1);
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

}
