import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { CacheDataService } from '../../../../../src/app/services/cache-data.service';
import { ColorService } from '../../../../../src/app/services/color.service';
import { TextCompareService } from '../../../../../src/app/services/text-compare.service';
import { KanbanBoard } from '../../entities/Board';
import { KanbanCard } from '../../entities/Card';
import { GlobalLabel } from '../../entities/Label';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-label-drawer',
  templateUrl: './kanban-label-drawer.component.html',
  styleUrls: ['./kanban-label-drawer.component.scss']
})
export class KanbanLabelDrawerComponent implements OnInit
{

  constructor(public cs: ColorService, public tcs: TextCompareService,
    public auth: AuthService,
    public cds: CacheDataService,
    public kbs: KanbanService) { }

  @Input() board: KanbanBoard;
  @Input() card: KanbanCard;
  filterDrawer = "";
  addLabel = false;
  createdLabel: GlobalLabel = {
    id: 0,
    title: "",
    color: "#2d3436",
    creatorId: 0,
    creationDate: new Date(), boardId: null, modules: 0, thirdPartyType: null
  };
  customColor = false;

  ngOnInit()
  {
    if (this.cds.publicLabelList.length == 0)
      this.kbs.getPublicLabelList().subscribe();
  }

  getPotentialMembers()
  {
    return this.kbs.getUsersForBoard(this.board, this.filterDrawer)
  }

  getFilteredPublicLabels()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    return this.cds.publicLabelList.filter(x => this.tcs.contains(x.title, upperFilter))
  }

  getFilteredBoardLabels()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    return this.cds.labelList.filter(x => this.tcs.contains(x.title, upperFilter))
  }

  changeLabelSelection(label)
  {
    let labelInListIndex = this.card.labels.findIndex(x => x.id == label.id);
    if (labelInListIndex == -1)
    {
      this.kbs.addLabelToCard(this.card.id, label.id).subscribe(x =>
      {
        this.card.labels.push(label);
        this.card.labels = [...this.card.labels];
      });
    }
    else
    {
      this.kbs.removeLabelFromCard(this.card.id, label.id).subscribe(x =>
      {
        this.card.labels.splice(labelInListIndex, 1);
        this.card.labels = [...this.card.labels];
      });
    }
  }

  labeIsSelected(label: GlobalLabel)
  {
    return this.card.labels.find(x => x.id == label.id) != null;
  }

  setCreatedLabelColor(colorValue)
  {
    this.createdLabel.color = colorValue;
    this.customColor = false;
  }

  createOrSaveLabel()
  {
    if (this.createdLabel.id == 0)
      this.createLabel();
    else this.saveLabel();
  }

  createLabel()
  {
    if (!this.createdLabel.title)
      return;
    let label = this.kbs.deepCopy(this.createdLabel);
    this.kbs.createLabel(label, this.board.id).subscribe(x =>
    {
      label.id = x;
      label.creatorId = this.auth.connectedUser.id;
      label.creationDate = new Date();
      this.cds.labelList.push(label);
      this.cds.labelList = this.cds.labelList.sort((x, y) => (x.title > y.title) ? 1 : -1);
      this.createdLabel = new GlobalLabel();
      this.addLabel = false;
      this.changeLabelSelection(label)
    });
  }

  initCreateLabel()
  {
    this.createdLabel = {
      id: 0,
      title: "",
      color: "#2d3436",
      creatorId: 0,
      creationDate: new Date(), boardId: null, modules: 0, thirdPartyType: null
    };
    this.addLabel = true;
  }

  createPublicLabel()
  {
    if (!this.createdLabel.title)
      return;
    let label = this.kbs.deepCopy(this.createdLabel);
    this.kbs.createPublicLabel(label).subscribe(x =>
    {
      label.id = x;
      label.creatorId = this.auth.connectedUser.id;
      label.creationDate = new Date();
      this.cds.publicLabelList.push(label);
      this.cds.publicLabelList = this.cds.publicLabelList.sort((x, y) => (x.title.toUpperCase() > (y.title.toUpperCase()) ? 1 : -1));
      this.createdLabel = new GlobalLabel();
      this.addLabel = false;
      this.changeLabelSelection(label)
    });
  }

  saveLabel()
  {
    if (!this.createdLabel.title)
      return;
    this.kbs.updateLabel(this.createdLabel).subscribe(x =>
    {
      let label = this.cds.labelList.find(x => x.id == this.createdLabel.id);
      if (!label)
        label = this.cds.publicLabelList.find(x => x.id == this.createdLabel.id);
      label.title = this.createdLabel.title;
      label.color = this.createdLabel.color;
      let labelcard = this.card.labels.find(x => x.id == this.createdLabel.id);
      if (labelcard)
      {
        labelcard.title = this.createdLabel.title;
        labelcard.color = this.createdLabel.color;
      }

      this.addLabel = false;
      this.createdLabel = new GlobalLabel();
    });
  }

  deleteLabel()
  {
    this.kbs.deleteLabel(this.createdLabel.id).subscribe(x =>
    {
      let index = this.cds.labelList.findIndex(x => x.id == this.createdLabel.id);
      let indexPublic = this.cds.publicLabelList.findIndex(x => x.id == this.createdLabel.id);
      if (index != -1)
        this.cds.labelList.splice(index, 1);
      if (indexPublic != -1)
        this.cds.publicLabelList.splice(indexPublic, 1);
      this.addLabel = false;
      let indexCard = this.card.labels.findIndex(x => x.id == this.createdLabel.id);
      this.card.labels.splice(indexCard, 1);
      this.createdLabel = new GlobalLabel();
    });
  }

  editLabel(label: GlobalLabel)
  {
    this.createdLabel.id = label.id;
    this.createdLabel.title = label.title;
    this.createdLabel.color = label.color;
    this.createdLabel.creatorId = label.creatorId;
    this.createdLabel.creationDate = label.creationDate;
    this.addLabel = true;
  }



}
