import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from './url-api.service';
import { ThirdPartyRelationInfos } from '../third-party/entities/third-party-relation-info-display';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterModuleService {

constructor(private http: HttpClient) { }

tpRelationsInfos: ThirdPartyRelationInfos[] = [];
tp: ThirdPartyRelationInfos = new ThirdPartyRelationInfos();

getThirdPartyInRelationWithThirdPartyInCard(idtp: number)
  {
    return this.http.get<ThirdPartyRelationInfos[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idtp}/relations-for-display`).pipe(tap(x => this.tpRelationsInfos = x));
  }

}
