import { MessagerieAccountAlias } from "../messagerie/entities/messagerie-account-alias";
import { MessagerieAccountSendGroup } from "../messagerie/entities/messagerie-send-group";

export class Email
{
    from: MessagerieAccountAlias = new MessagerieAccountAlias();
    to: Array<{ label: string; value: string }> = [];
    cc: Array<{ label: string; value: string }> = [];
    cci: Array<{ label: string; value: string }> = [];
    toML: MessagerieAccountSendGroup[] = [];
    ccML: MessagerieAccountSendGroup[] = [];
    cciML: MessagerieAccountSendGroup[] = [];
    object: string = "";
    content: string = "";
    readReceipt = false;
    receptionReceipt = false;
    sendDate: Date = null;
}