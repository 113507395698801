// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[nz-row] {
  height: 100vh;
  background-color: rgb(190, 190, 190);
}

nz-card {
  width: 1200px;
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/change-password/change-password.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oCAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;AACF","sourcesContent":["[nz-row] {\r\n  height: 100vh;\r\n  background-color: rgb(190, 190, 190);\r\n}\r\n\r\nnz-card {\r\n  width: 1200px;\r\n  height: 300px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
