import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import { KanbanList } from '../../entities/List';
import { KanbanBoard, KanbanBoardVisibility, KanbanBoardType } from '../../entities/Board';
import { KanbanService } from '../../services/kanban.service';
import { User } from '../../../user/model/user';
import { KanbanCard } from '../../entities/Card';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { ActivatedRoute } from '@angular/router';
import { GlobalLabel } from '../../entities/Label';
import { KanbanAttachment } from '../../entities/Attachment';
import { Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../../settings/services/settings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { ColorService } from '../../../services/color.service';
import { CacheDataService } from '../../../services/cache-data.service';
import { AuthService } from '../../../services/auth.service';
import { KanbanLabelCardsComponent } from '../kanban-label-cards/kanban-label-cards.component';
import { GroupUser } from '../../../group-user/entities/group-user';
import { GroupUserSourceService } from '../../../group-user/services/source/group-user-source.service';
import { NameTeamPipe } from '../../../pipes/name-team-pipe/name-team.pipe';
import { TextCompareService } from '../../../services/text-compare.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KanbanCardEditModalComponent } from '../kanban-card-edit-modal/kanban-card-edit-modal.component';
import { MessagerieService } from '../../../messagerie/messagerie.service';
import { KanbanEditCardService } from '../../services/kanban-edit-card.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.scss']
})
export class KanbanBoardComponent implements OnInit
{

  public enumVis = KanbanBoardVisibility;
  public enumType = KanbanBoardType;
  sub: Subscription;
  @Input() board: KanbanBoard = new KanbanBoard();
  @Output() boardValueChange = new EventEmitter();
  @Input() card: KanbanCard;

  constructor(public kbs: KanbanService, private route: ActivatedRoute, private translateService: TranslateService,
    public settingsService: SettingsService,
    private sanitizer: DomSanitizer,
    private datepipe: DatePipe,
    public cs: ColorService,
    public authService: AuthService,
    private tcs: TextCompareService,
    private groupuserService: GroupUserSourceService,
    private pipeteam: NameTeamPipe,
    public cds: CacheDataService,
    public mss: MessagerieService,
    public kecs: KanbanEditCardService,
    private modalService: NzModalService)
  {

  }
  createdList: KanbanList = new KanbanList();
  labelOption = 4;
  customColor = false;
  hue: string;
  color: string;
  labelsCollapsed = false;
  publicLabelsCollapsed = true;
  isCreatingList = false;
  boardIsTemplate = false;
  ErrorEmptyListName = false;
  idAddedGuest: number = null;
  userList: User[] = [];
  createdLabel = new GlobalLabel();
  idEditingBoardName = false;
  dataloaded = true;
  updatedMember: number = 0;
  visualizedUrl = "";
  visuHeight = "800px";
  pasteSubscription: Subscription;
  attachmentsBoardVisible = false;
  subscription: Subscription;
  timeoutfilter: any;
  teamsloaded = false;
  searchUser = "";
  searchTeam = "";

  dragListDisable()
  {
    if (this.kbs.filterString || this.kbs.isAddingCardToList || this.kbs.isEditionListName)
      return true;
    return false;
  }

  drop(event: CdkDragDrop<KanbanList[]>): void
  {
    if (event.previousContainer === event.container)
    {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else
    {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    setTimeout(() =>
    {
      this.kbs.moveList(this.board.id, event.item.data.id, this.board.lists.map(({ id }) => id)).subscribe();
    }, 300);
  }

  ngOnInit()
  {
    this.kbs.filterString = "";
    this.kbs.editedCard = null;
    this.authService.setLastVisitedPage();
    this.authService.setCamelineTitle("kanban");
    this.translateService.get("KANBAN.PUBLIC-LABEL.MOVE-CARDS-TITLE").subscribe(x => this.associatedCardsReallocateText = x);
    if (this.cds.teamsCollection.length == 0)
    {
      this.groupuserService.getAllGroupUsers(false).subscribe(grps => { this.cds.teamsCollection = grps.map(x => x.grpuser); this.teamsloaded = true; });
    }
    else this.teamsloaded = true;
    this.subscription = this.kbs.openEditCardOnStartSub.subscribe(() =>
    {
      let idRouteCard = + this.route.snapshot.paramMap.get('idCard');
      let idRouteBoard = + this.route.snapshot.paramMap.get('idBoard');
      if (idRouteCard > 0 && idRouteBoard > 0 && window.location.href.endsWith(idRouteBoard + "/" + idRouteCard))
        this.startEditCard(idRouteCard, this.board);
    })
    this.sub = this.kbs.kanbanBoardChange.subscribe(() =>
    {
      this.kbs.filterBoard(this.board);

    });
    this.kbs.getBoardGuests(this.board.id).subscribe(x => 
    {
      this.board.guestIds = x.users;
      this.board.guestTeamsIds = x.teams;
      this.board.guestIds.sort((a, b) => this.getGuestName(a).toUpperCase() < this.getGuestName(b).toUpperCase() ? -1 : 1)
      this.board.guestTeamsIds.sort((a, b) => this.pipeteam.transform(a).toUpperCase() < this.pipeteam.transform(b).toUpperCase() ? -1 : 1)
    });

    this.kbs.updateBoardChange.subscribe(() =>
    {
      this.kbs.initBoard(this.board);
    })
    this.boardIsTemplate = (this.board.type & this.enumType.Template) != 0;

    document.documentElement.style.setProperty('--bb-board-color', this.board.bgColor);
  }

  filterPPString = "";
  getFilteredPhysicalPerson()
  {
    let ppincards = [];
    for (let list of this.board.lists)
    {
      for (let card of list.cards)
      {
        for (let pp of card.physicalPersons)
        {
          if (ppincards.findIndex(x => x.id == pp.id) < 0)
            ppincards.push(this.cds.getPP(pp.id));
        }
      }
    }

    ppincards = ppincards.filter(x => this.tcs.contains((x.firstName + " " + x.lastName), this.filterPPString)
      || (x.comment && this.tcs.contains(x.comment, this.filterPPString))
      || (x.siren && this.tcs.contains(x.siren, this.filterPPString))
      || x.phoneNumbers.findIndex(y => this.tcs.contains(y.number, this.filterPPString)) >= 0
      || x.websites.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.mails.findIndex(y => this.tcs.contains(y.mail, this.filterPPString)) >= 0
      || x.brands.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.secondaryNames.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || (x.siret && this.tcs.contains(x.siret, this.filterPPString)));
    return ppincards.sort((a, b) => (a.firstName + a.lastName) < (b.firstName + b.lastName) ? -1 : 1)
  }

  filterMPString = "";
  getFilteredMoralPerson()
  {
    let mpincards = [];
    for (let list of this.board.lists)
    {
      for (let card of list.cards)
      {
        for (let mp of card.moralPersons)
        {
          if (mpincards.findIndex(x => x.id == mp.id) < 0)
            mpincards.push(this.cds.getMP(mp.id));
        }
      }
    }

    mpincards = mpincards.filter(x => this.tcs.contains(x.name, this.filterPPString)
      || this.tcs.contains(x.comment, this.filterPPString)
      || this.tcs.contains(x.codeTVA, this.filterPPString)
      || this.tcs.contains(x.codeAPE, this.filterPPString)
      || (x.sign && this.tcs.contains(x.sign, this.filterPPString))
      || (x.initials && this.tcs.contains(x.initials, this.filterPPString))
      || x.phoneNumbers.findIndex(y => this.tcs.contains(y.number, this.filterPPString)) >= 0
      || x.websites.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.mails.findIndex(y => this.tcs.contains(y.mail, this.filterPPString)) >= 0
      || x.brands.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.secondaryNames.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || this.tcs.contains(x.mainCurrency, this.filterPPString)
      || this.tcs.contains(x.siren, this.filterPPString)
      || this.tcs.contains(x.siret, this.filterPPString))
    return mpincards.sort((a, b) => a.name < b.name ? -1 : 1);
  }

  getUserForFilter()
  {
    return this.cds.userCollection.filter(user => !user.archived && (
      this.kbs.getUsersForBoard(this.board, "").findIndex(x => x == user.id) >= 0 ||
      (this.board.lists.findIndex(x => x.cards.findIndex(y => y.members.findIndex(z => z == user.id) >= 0) >= 0) >= 0))
    ).map(x => x.id);
  }

  getTeamsForFilter()
  {
    return this.cds.unarchivedTeams.filter(team =>
      this.board.teams.findIndex(x => x == team.id) >= 0 ||
      (this.board.lists.findIndex(x => x.cards.findIndex(y => y.teams.findIndex(z => z == team.id) >= 0) >= 0) >= 0)
    ).map(x => x.id);
  }

  hasThirdPartyFilter()
  {
    return this.kbs.filterBoardLists.physicalPersonFiltered.length +
      this.kbs.filterBoardLists.physicalPersonExiled.length +
      this.kbs.filterBoardLists.moralPersonFiltered.length +
      this.kbs.filterBoardLists.moralPersonExiled.length > 0;
  }

  hasLabeldFilter()
  {
    return this.kbs.filterBoardLists.labelFiltered.length + this.kbs.filterBoardLists.labelExiled.length > 0;
  }

  hasMemberFilter()
  {
    return this.kbs.filterBoardLists.userFiltered.length +
      this.kbs.filterBoardLists.userExiled.length +
      this.kbs.filterBoardLists.teamFiltered.length +
      this.kbs.filterBoardLists.teamExiled.length > 0;
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  getContainerHeight(padding: number)
  {
    return (window.innerHeight - padding) * 0.8 + "px";
  }

  startEditCard(idcard: number, board: KanbanBoard)
  {
    if (idcard == 0 || this.kbs.editedCard && this.kbs.editedCard.id == idcard)
      return;
    let card: KanbanCard = null;
    let list: KanbanList = null;
    for (let thelist of board.lists)
    {
      let thecard = thelist.cards.find(x => x.id == idcard);
      if (thecard)
      {
        card = thecard;
        list = thelist;
      }
    }
    if (!card)
      return;
    this.kbs.editedCard = card;
    let title = "";
    this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title + " " + list.title,
      nzContent: KanbanCardEditModalComponent,
      nzWidth: '100%',
      nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
      nzMaskClosable: false,
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalEdit.close(); }
      }
      ]
    });
    this.kbs.modalEdit.componentInstance.card = card;
    this.kbs.modalEdit.componentInstance.list = list;
    this.kbs.modalEdit.componentInstance.board = board;

    this.kbs.modalEdit.afterClose.subscribe((result) =>
    {
      this.kbs.editedCard = null;
      let titleDiv = document.getElementById("kanban-card-title");
      if (titleDiv)
        titleDiv.style.display = "none";
      let descriptionDiv = document.getElementById("kanban-card-description");
      if (descriptionDiv)
        descriptionDiv.style.display = "none";
      window.history.replaceState({}, '', `/kanban/${board.id}`);
    });
  }

  changeAttachmentsBoardVisibility(vis: boolean)
  {
    if (vis)
      this.pasteSubscription = fromEvent(document, "paste").subscribe((pasteEvent: any) => { this.pasteFunction(pasteEvent); })
    else this.pasteSubscription.unsubscribe();
  }

  pasteFunction(pasteEvent: ClipboardEvent)
  {
    var items = pasteEvent.clipboardData.items;
    if (!items)
      return;

    for (var i = 0; i < items.length; i++)
    {
      // Skip content if not image
      //if (items[i].type.indexOf("image") == -1) continue;
      // Retrieve image on clipboard as blob
      var blob = items[i].getAsFile();

      var reader = new FileReader();

      reader.addEventListener('load', () =>
      {
        let date = new Date();
        let att = new KanbanAttachment();
        att.name = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss") + "_clipboard.png";
        att.url = reader.result.toString();
        this.kbs.addAttachmentToBoard(this.board.id, att).subscribe(x =>
        {
          att.id = x;
          this.board.attachments.push(att);
        });
      });
      reader.readAsDataURL(blob);
    }
  }

  setVisualization(url: string)
  {
    if (this.visualizedUrl == url)
      this.visualizedUrl = "";
    else
    {
      this.visualizedUrl = "";
      setTimeout(() =>
      {
        this.visualizedUrl = url;
      }, 100);

    }
    if (url.startsWith("data:image/"))
      this.visuHeight = "";
    else if (url.startsWith("data:application/pdf"))
      this.visuHeight = "800px";
  }

  getUrl(url: string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showVisuIcon(url: string)
  {
    if (url.startsWith("data:image/") || url.startsWith("data:application/pdf"))
      return true;
    return false;
  }

  addUserToGuest(iduser: number)
  {
    this.kbs.addGuestToBoard(iduser, this.board.id).subscribe(() =>
    {
      this.board.guestIds.push(iduser);
      this.idAddedGuest = null
      this.board.guestIds.sort((a, b) => this.getGuestName(a).toUpperCase() < this.getGuestName(b).toUpperCase() ? -1 : 1)
      let index = this.board.members.findIndex(x => x == iduser);
      if (index >= 0)
      {
        this.kbs.removeMemberFromBoard(iduser, this.board.id).subscribe(() =>
        {
          this.board.members.splice(index, 1);
        })
      }
    })
  }

  removeGuestFromBoard(iduser: number)
  {
    this.kbs.removeGuestFromBoard(iduser, this.board.id).subscribe(() =>
    {
      let index = this.board.guestIds.findIndex(x => x == iduser);
      if (index >= 0)
        this.board.guestIds.splice(index, 1);
    })
  }

  idAddedTeamGuest = 0;

  addTeamToGuest(idteam: number)
  {
    this.kbs.addTeamGuestToBoard(idteam, this.board.id).subscribe(() =>
    {
      this.board.guestTeamsIds.push(idteam);
      this.idAddedTeamGuest = null;
      this.board.guestTeamsIds.sort((a, b) => this.pipeteam.transform(a).toUpperCase() < this.pipeteam.transform(b).toUpperCase() ? -1 : 1)
      let index = this.board.teams.findIndex(x => x == idteam);
      if (index >= 0)
      {
        this.kbs.removeTeamFromBoard(idteam, this.board.id).subscribe(() =>
        {
          this.board.teams.splice(index, 1);
        })
      }
    })
  }

  removeTeamGuestFromBoard(idteam: number)
  {
    this.kbs.removeTeamGuestFromBoard(idteam, this.board.id).subscribe(() =>
    {
      let index = this.board.guestTeamsIds.findIndex(x => x == idteam);
      if (index >= 0)
        this.board.guestTeamsIds.splice(index, 1);
      this.idAddedTeamGuest = null
      this.updatingTeam = false;
    })
  }

  userIsNotGuest(iduser: number)
  {
    if (!this.board.guestIds)
      return true;
    if (this.board.members.findIndex(x => x == iduser) >= 0)
      return false;
    return this.board.guestIds.findIndex(x => x == iduser) < 0;
  }

  teamIsNotGuest(idteam: number)
  {
    if (!this.board.guestTeamsIds)
      return true;
    if (this.board.teams.findIndex(x => x == idteam) >= 0)
      return false;
    return this.board.guestTeamsIds.findIndex(x => x == idteam) < 0;
  }

  getGuestName(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.name + " " + user.surname;
  }

  getGuestInitial(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.name.slice(0, 1).toUpperCase() + user.surname.slice(0, 1).toUpperCase()
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.avatar;
  }

  updateFilteredLabelPref()
  {
    let filteredPref = this.kbs.filterBoardLists.labelFiltered.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_filtered_label_" + this.board.id, filteredPref);
  }

  updateExiledLabelPref()
  {
    let exiledPref = this.kbs.filterBoardLists.labelExiled.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_exiled_label_" + this.board.id, exiledPref);
  }

  updateFilteredPPPref()
  {
    let filteredPref = this.kbs.filterBoardLists.physicalPersonFiltered.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_filtered_pp_" + this.board.id, filteredPref);
  }

  updateExiledPPPref()
  {
    let exiledPref = this.kbs.filterBoardLists.physicalPersonExiled.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_exiled_pp_" + this.board.id, exiledPref);
  }

  updateFilteredMPPref()
  {
    let filteredPref = this.kbs.filterBoardLists.moralPersonFiltered.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_filtered_mp_" + this.board.id, filteredPref);
  }

  updateExiledMPPref()
  {
    let exiledPref = this.kbs.filterBoardLists.moralPersonExiled.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_exiled_mp_" + this.board.id, exiledPref);
  }

  updateFilteredUserPref()
  {
    let filteredPref = this.kbs.filterBoardLists.userFiltered.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_filtered_user_" + this.board.id, filteredPref);
  }

  updateExiledUserPref()
  {
    let exiledPref = this.kbs.filterBoardLists.userExiled.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_exiled_user_" + this.board.id, exiledPref);
  }

  updateFilteredTeamPref()
  {
    let filteredPref = this.kbs.filterBoardLists.teamFiltered.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_filtered_team_" + this.board.id, filteredPref);
  }

  updateExiledTeamPref()
  {
    let exiledPref = this.kbs.filterBoardLists.teamExiled.reduce((a, b) => a + "," + b, "");
    this.settingsService.setSettingsByName("kanban_exiled_team_" + this.board.id, exiledPref);
  }

  addLabelToFiltered(idlabel: number)
  {
    let index = this.kbs.filterBoardLists.labelFiltered.findIndex(x => x == idlabel);
    if (index == -1)
    {
      index = this.kbs.filterBoardLists.labelExiled.findIndex(x => x == idlabel);
      if (index == -1)
        this.kbs.filterBoardLists.labelFiltered.push(idlabel);
      else this.kbs.filterBoardLists.labelExiled.splice(index, 1);
    }
    else
    {
      this.kbs.filterBoardLists.labelFiltered.splice(index, 1);
      this.kbs.filterBoardLists.labelExiled.push(idlabel);
    }
    this.updateFilteredLabelPref();
    this.updateExiledLabelPref();

    this.kbs.filterBoard(this.board);
  }

  removeLabelFiltered(idlabel: number)
  {
    let index = this.kbs.filterBoardLists.labelFiltered.findIndex(x => x == idlabel);
    if (index > -1)
      this.kbs.filterBoardLists.labelFiltered.splice(index, 1);
    this.updateFilteredLabelPref();
    this.kbs.filterBoard(this.board);
  }

  removeLabelExiled(idlabel: number)
  {
    let index = this.kbs.filterBoardLists.labelExiled.findIndex(x => x == idlabel);
    if (index > -1)
      this.kbs.filterBoardLists.labelExiled.splice(index, 1);
    this.updateExiledLabelPref();
    this.kbs.filterBoard(this.board);
  }

  labelIsfiletered(idlabel: number)
  {
    return this.kbs.filterBoardLists.labelFiltered.findIndex(x => x == idlabel) >= 0;
  }

  labelIsExiled(idlabel: number)
  {
    return this.kbs.filterBoardLists.labelExiled.findIndex(x => x == idlabel) >= 0;
  }

  addTeamToFiltered(idteam: number)
  {
    let index = this.kbs.filterBoardLists.teamFiltered.findIndex(x => x == idteam);
    if (index == -1)
    {
      index = this.kbs.filterBoardLists.teamExiled.findIndex(x => x == idteam);
      if (index == -1)
        this.kbs.filterBoardLists.teamFiltered.push(idteam);
      else this.kbs.filterBoardLists.teamExiled.splice(index, 1);
    }
    else
    {
      this.kbs.filterBoardLists.teamFiltered.splice(index, 1);
      this.kbs.filterBoardLists.teamExiled.push(idteam);
    }
    this.updateFilteredUserPref();
    this.updateExiledUserPref();
    this.kbs.filterBoard(this.board);
  }

  ppIsFiltered(idpp)
  {
    return this.kbs.filterBoardLists.physicalPersonFiltered.findIndex(x => x == idpp) >= 0
  }

  ppIsExiled(idpp)
  {
    return this.kbs.filterBoardLists.physicalPersonExiled.findIndex(x => x == idpp) >= 0
  }

  mpIsFiltered(id)
  {
    return this.kbs.filterBoardLists.moralPersonFiltered.findIndex(x => x == id) >= 0
  }

  mpIsExiled(id)
  {
    return this.kbs.filterBoardLists.moralPersonExiled.findIndex(x => x == id) >= 0
  }

  addPPToFiltered(pp: number)
  {
    let index = this.kbs.filterBoardLists.physicalPersonFiltered.findIndex(x => x == pp);
    if (index == -1)
    {
      index = this.kbs.filterBoardLists.physicalPersonExiled.findIndex(x => x == pp);
      if (index == -1)
        this.kbs.filterBoardLists.physicalPersonFiltered.push(pp);
      else this.kbs.filterBoardLists.physicalPersonExiled.splice(index, 1);
    }
    else
    {
      this.kbs.filterBoardLists.physicalPersonFiltered.splice(index, 1);
      this.kbs.filterBoardLists.physicalPersonExiled.push(pp);
    }
    this.updateFilteredPPPref();
    this.updateExiledPPPref();
    this.kbs.filterBoard(this.board);
  }

  removePPFiltered(id: number)
  {
    let index = this.kbs.filterBoardLists.physicalPersonFiltered.findIndex(x => x == id);
    if (index > -1)
      this.kbs.filterBoardLists.physicalPersonFiltered.splice(index, 1);
    this.updateFilteredPPPref();
    this.kbs.filterBoard(this.board);
  }

  removeMPFiltered(id: number)
  {
    let index = this.kbs.filterBoardLists.moralPersonFiltered.findIndex(x => x == id);
    if (index > -1)
      this.kbs.filterBoardLists.moralPersonFiltered.splice(index, 1);
    this.updateFilteredMPPref();
    this.kbs.filterBoard(this.board);
  }

  removePPExiled(id: number)
  {
    let index = this.kbs.filterBoardLists.physicalPersonExiled.findIndex(x => x == id);
    if (index > -1)
      this.kbs.filterBoardLists.physicalPersonExiled.splice(index, 1);
    this.updateExiledPPPref();
    this.kbs.filterBoard(this.board);
  }

  removeMPExiled(id: number)
  {
    let index = this.kbs.filterBoardLists.moralPersonExiled.findIndex(x => x == id);
    if (index > -1)
      this.kbs.filterBoardLists.moralPersonExiled.splice(index, 1);
    this.updateExiledMPPref();
    this.kbs.filterBoard(this.board);
  }

  addMPToFiltered(pp: number)
  {
    let index = this.kbs.filterBoardLists.moralPersonFiltered.findIndex(x => x == pp);
    if (index == -1)
    {
      index = this.kbs.filterBoardLists.moralPersonExiled.findIndex(x => x == pp);
      if (index == -1)
        this.kbs.filterBoardLists.moralPersonFiltered.push(pp);
      else this.kbs.filterBoardLists.moralPersonExiled.splice(index, 1);
    }
    else
    {
      this.kbs.filterBoardLists.moralPersonFiltered.splice(index, 1);
      this.kbs.filterBoardLists.moralPersonExiled.push(pp);
    }
    this.updateFilteredMPPref();
    this.updateExiledMPPref();
    this.kbs.filterBoard(this.board);
  }

  addUserToFiltered(iduser: number)
  {
    let index = this.kbs.filterBoardLists.userFiltered.findIndex(x => x == iduser);
    if (index == -1)
    {
      index = this.kbs.filterBoardLists.userExiled.findIndex(x => x == iduser);
      if (index == -1)
        this.kbs.filterBoardLists.userFiltered.push(iduser);
      else this.kbs.filterBoardLists.userExiled.splice(index, 1);
    }
    else
    {
      this.kbs.filterBoardLists.userFiltered.splice(index, 1);
      this.kbs.filterBoardLists.userExiled.push(iduser);
    }
    this.updateFilteredUserPref();
    this.updateExiledUserPref();
    this.kbs.filterBoard(this.board);
  }

  removeUserFiltered(iduser: number)
  {
    let index = this.kbs.filterBoardLists.userFiltered.findIndex(x => x == iduser);
    if (index > -1)
      this.kbs.filterBoardLists.userFiltered.splice(index, 1);
    this.updateFilteredUserPref();
    this.kbs.filterBoard(this.board);
  }

  removeUserExiled(iduser: number)
  {
    let index = this.kbs.filterBoardLists.userExiled.findIndex(x => x == iduser);
    if (index > -1)
      this.kbs.filterBoardLists.userExiled.splice(index, 1);
    this.updateExiledUserPref();
    this.kbs.filterBoard(this.board);
  }

  userIsfiletered(iduser: number)
  {
    return this.kbs.filterBoardLists.userFiltered.findIndex(x => x == iduser) >= 0;
  }

  userIsExiled(iduser: number)
  {
    return this.kbs.filterBoardLists.userExiled.findIndex(x => x == iduser) >= 0;
  }

  teamIsfiletered(idteam: number)
  {
    return this.kbs.filterBoardLists.teamFiltered.findIndex(x => x == idteam) >= 0;
  }

  teamIsExiled(idteam: number)
  {
    return this.kbs.filterBoardLists.teamExiled.findIndex(x => x == idteam) >= 0;
  }

  getLabelContainerHeight()
  {
    return (window.innerHeight - 120) * 0.8 + "px";
  }

  toogleWhite(hex)
  {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result)
    {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      if ((r + g + b) > 620)
        return true;
    }
    return false
  }

  editLabel(label: GlobalLabel)
  {
    this.createdLabel.id = label.id;
    this.createdLabel.title = label.title;
    this.createdLabel.color = label.color;
    this.createdLabel.creatorId = label.creatorId;
    this.createdLabel.creationDate = label.creationDate;
    this.labelOption = 4;
    this.customColor = false
    console.log(label.color);
    console.log(this.kbs.colorList);
    if (this.kbs.colorList.indexOf(label.color) == -1)
    {


      this.customColor = true
    }
  }

  setCreatedLabelColor(colorValue)
  {
    this.createdLabel.color = colorValue;
  }

  createOrSaveLabel()
  {
    if (this.createdLabel.id == 0)
      this.createLabel();
    else this.saveLabel();
  }

  createLabel()
  {
    if (!this.createdLabel.title)
      return;
    let label = this.kbs.deepCopy(this.createdLabel);
    this.kbs.createLabel(label, this.board.id).subscribe(x =>
    {
      label.id = x;
      label.creatorId = this.authService.connectedUser.id;
      label.creationDate = new Date();
      this.cds.labelList.push(label);
      this.cds.labelList = this.cds.labelList.sort((x, y) => (x.title > y.title) ? 1 : -1);
      this.createdLabel = new GlobalLabel();
      this.setOption(2);
    });
  }

  saveLabel()
  {
    if (!this.createdLabel.title)
      return;
    this.kbs.updateLabel(this.createdLabel).subscribe(x =>
    {
      let label = this.cds.labelList.find(x => x.id == this.createdLabel.id);
      label.title = this.createdLabel.title;
      label.color = this.createdLabel.color;
      this.cds.labelList = this.cds.labelList.sort((x, y) => (x.title > y.title) ? 1 : -1);
      this.labelOption = 2;
      this.createdLabel = new GlobalLabel();
    });
  }

  associatedCardModalVisible = false;
  associatedCardsNumberText = "";
  associatedCardsReallocateText = "";
  labelToReallocate: GlobalLabel = null;

  closeReaffectModal()
  {
    this.associatedCardModalVisible = false;
  }

  deleteLabel()
  {
    this.kbs.deleteLabel(this.createdLabel.id).subscribe(deletereturn =>
    {
      if (deletereturn.nbCardAttached == 0)
        this.afterDelete();
      else
      {
        this.translateService.get("KANBAN.PUBLIC-LABEL.CARD-ASSOCIATED-NUMBER", { v1: deletereturn.nbCardAttached, v2: this.createdLabel.title }).subscribe(x =>
        {
          this.associatedCardsNumberText = x;
          this.associatedCardModalVisible = true;
        });
      }
    });
  }

  forceDeleteLabel()
  {
    if (this.labelToReallocate)
    {
      this.kbs.reallocateDeleteLabel(this.createdLabel.id, this.labelToReallocate.id).subscribe(() =>
      {
        for (let list of this.board.lists)
        {
          for (let card of list.cards)
          {
            let index = card.labels.findIndex(x => x.id == this.createdLabel.id);
            if (index >= 0)
            {
              let i2 = card.labels.findIndex(x => x.id == this.labelToReallocate.id)
              if (i2 >= 0)
                card.labels.splice(index, 1);
              else card.labels.splice(index, 1, this.labelToReallocate);
              card.labels = [...card.labels];
            }

          }
        }
        this.afterDelete();
      });
    }
    else
    {
      this.kbs.forceDeleteLabel(this.createdLabel.id).subscribe(() =>
      {
        this.board.lists.forEach(list =>
        {
          list.cards.forEach(card =>
          {
            let index = card.labels.findIndex(x => x.id == this.createdLabel.id);
            if (index >= 0)
            {
              card.labels.splice(index, 1);
              card.labels = [...card.labels];
            }
          })
        })
        this.afterDelete();
      })
    }
  }

  afterDelete()
  {
    let indexboard = this.board.labels.findIndex(l => l.id == this.createdLabel.id);
    if (indexboard >= 0)
      this.board.labels.splice(indexboard, 1);
    let index = this.cds.labelList.findIndex(x => x.id == this.createdLabel.id);
    this.cds.labelList.splice(index, 1);
    this.labelOption = 2;
    this.createdLabel = new GlobalLabel();
    this.associatedCardModalVisible = false;
    this.labelToReallocate = null;
  }

  setOption(option: number)
  {
    this.createdLabel = new GlobalLabel();
    this.labelOption = option;
  }

  cancelListCreation()
  {
    this.isCreatingList = false;
    this.ErrorEmptyListName = false;
  }

  removeMemberFromBoard()
  {
    this.kbs.removeMemberFromBoard(this.updatedMember, this.board.id).subscribe(x =>
    {
      let index = this.board.members.findIndex(x => x == this.updatedMember);
      if (index >= 0)
        this.board.members.splice(index, 1);
      // this.kbs.potentialBoardMembers.push(this.updatedMember);
      // this.kbs.potentialBoardMembers.sort((a, b) => (a.name + a.surname).toUpperCase() < (b.name + b.surname).toUpperCase() ? -1 : 1);
    });

  }

  onDragStart(event: DragEvent, effect: DropEffect, add: boolean)
  {
    if (effect === "move")
    {
    }
  }

  onDragged(item: any, list: any[], effect: DropEffect)
  {
    if (effect === "move")
    {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragEnd(event: DragEvent)
  {
  }

  onDrop(event: DndDropEvent, lists?: any[], add?: boolean)
  {
    let list: KanbanList = event.data as KanbanList;
    if (lists && (event.dropEffect === "copy" || event.dropEffect === "move"))
    {
      let index = event.index;
      if (typeof index === "undefined")
        index = lists.length - 1;
      lists.splice(index, 0, event.data);
      setTimeout(() =>
      {
        this.board.lists = lists;
        this.kbs.moveList(this.board.id, list.id, lists.map(({ id }) => id)).subscribe();
      }, 300);

    }
  }

  saveBoardTitleModification()
  {
    this.board.title = this.board.title.trim()
    if (!this.board.title)
      return;
    this.kbs.changeBoardName(this.board.id, this.board.title).subscribe(x =>
    {
      this.idEditingBoardName = false;
    });

  }

  userIsMember(id)
  {
    return this.board.members.findIndex(x => x == id) >= 0
  }

  teamIsMember(id)
  {
    return this.board.teams.findIndex(x => x == id) >= 0
  }

  userIsGuest(id)
  {
    return this.board.guestIds.findIndex(x => x == id) >= 0
  }

  teamIsGuest(id)
  {
    return this.board.guestTeamsIds.findIndex(x => x == id) >= 0
  }

  addMemberToBoard(user: number)
  {
    this.kbs.addMemberToBoard(user, this.board.id).subscribe(x =>
    {
      let guestIndex = this.board.guestIds.findIndex(t => t == user);
      if (guestIndex >= 0)
      {
        this.kbs.removeGuestFromBoard(user, this.board.id).subscribe(() =>
        {
          this.board.guestIds.splice(guestIndex, 1);
        })
      }
      this.board.members.push(user);
      // this.kbs.potentialBoardMembers.splice(this.kbs.potentialBoardMembers.findIndex(x => x.id == user), 1);
    });
  }

  getPotentialBoardMembers()
  {
    return this.cds.unarchivedUsers.filter(x => this.board.members.indexOf(x.id) < 0 && this.tcs.contains(x.name + x.surname, this.searchUser));
  }

  getPotentialBoardGuests()
  {
    return this.cds.unarchivedUsers.filter(x => this.board.guestIds.indexOf(x.id) < 0 && this.tcs.contains(x.name + x.surname, this.searchUser));
  }


  addTeamToBoard(team: GroupUser)
  {
    this.kbs.addTeamToBoard(team.id, this.board.id).subscribe(x =>
    {
      let guestIndex = this.board.guestIds.findIndex(t => t == team.id);
      if (guestIndex >= 0)
      {
        this.kbs.removeTeamGuestFromBoard(team.id, this.board.id).subscribe(() =>
        {
          this.board.guestTeamsIds.splice(guestIndex, 1);
        })
      }
      this.board.teams.push(team.id);
    });
  }

  removeTeamFromBoard(idteam: number)
  {
    this.kbs.removeTeamFromBoard(idteam, this.board.id).subscribe(() =>
    {
      let index = this.board.teams.findIndex(x => x == idteam);
      this.board.teams.splice(index, 1);
      this.updatingTeam = false;
    })
  }

  openedUser = 0;
  openedTeam = 0;
  updatingTeam = false;
  openedTeamMembers: number[] = [];
  canRemove = true;
  openTeamModal(idteam: number, can: boolean)
  {
    this.openedTeam = 0;
    this.groupuserService.getMemberIds(idteam).subscribe(ids =>
    {
      this.openedTeamMembers = ids;
      this.openedTeam = idteam;
      this.updatingTeam = true;
      this.canRemove = can;
    })
  }

  initBoardTitleEdit()
  {
    this.idEditingBoardName = true;
    this.kbs.setFocusToElement("kanbanboardtitleinput");
  }

  getMemberInitial(user: User)
  {
    return user.name.slice(0, 1).toUpperCase() + user.surname.slice(0, 1).toUpperCase()
  }

  setBoardVisibility(vis: KanbanBoardVisibility)
  {
    this.kbs.updateBoardVisibility(this.board.id, vis).subscribe(x =>
    {
      this.board.visibility = vis;
      if (vis == KanbanBoardVisibility.Private)
      {
        this.kbs.removeAllMembersFromBoard(this.board);
      }
      else
      {
        this.kbs.addMemberToBoard(this.authService.connectedUser.id, this.board.id).subscribe(() =>
        {
          this.board.members.push(this.authService.connectedUser.id);
        })
      }
    });
  }

  setBoardStarred(starred: boolean)
  {
    this.kbs.updateBoardStarred(this.board.id, starred).subscribe(x =>
    {
      this.board.starred = starred;
    })
  }

  initListCreation()
  {
    this.isCreatingList = true;
    this.createdList = new KanbanList();
    this.kbs.setFocusToElement("creationListInput");
  }

  cancelCreationList()
  {
    this.isCreatingList = false;
    this.createdList = new KanbanList();
  }

  createList()
  {
    if (!this.createdList.title)
    {
      this.ErrorEmptyListName = true;
      return;
    }
    this.ErrorEmptyListName = false;
    let list = new KanbanList();
    list.title = this.createdList.title;
    list.undroppable = false;
    this.kbs.createListIntoBoard(this.board.id, list).subscribe(x =>
    {
      // list.id = x;
      // list.canCreateCard = true;
      // list.color = "#dfe3e6";
      // this.board.lists.push(list);
      this.createdList = new KanbanList();
      this.isCreatingList = false;
    });
  }

  initAssociatedCardModal(label: GlobalLabel)
  {
    let title = "";
    this.translateService.get("KANBAN.PUBLIC-LABEL.CARD-ASSOCIATED-TITLE", { v: label.title }).subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanLabelCardsComponent,
      nzWidth: '100%',
      nzBodyStyle: { height: '75vh' },
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      },
      ]
    });
    modal.componentInstance.label = label;

  }

  getTeamsToAdd()
  {
    return this.cds.teamsCollection.filter(x => x.enabled && this.board.teams.indexOf(x.id) < 0 && this.tcs.contains(x.name, this.searchTeam));
  }

  getTeamsToAddAsGuests()
  {
    return this.cds.teamsCollection.filter(x => x.enabled && (!this.board.guestTeamsIds || this.board.guestTeamsIds.indexOf(x.id) < 0) && this.tcs.contains(x.name, this.searchTeam));
  }

  labeIsSelected(idlabel: number)
  {
    if (this.kbs.filterBoardLists.labelFiltered.findIndex(x => x == idlabel) == -1)
      return false;
    return true;
  }

  stopPropagation()
  {
    event.stopPropagation();
  }

  downloadAttachment(att: KanbanAttachment)
  {
    event.stopPropagation();
    this.kbs.downloadBoardAttachment(this.board.id, att.id).subscribe(x =>
    {
      var byte = this.base64ToArrayBuffer(x);
      this.saveByteArray(att.name, byte);
    });
  }

  updateBoardAttachments(atts: KanbanAttachment[])
  {
    this.board.attachments = atts;
    this.board.nbAttachments = atts.length;
  }

  saveByteArray(reportName, byte)
  {
    var blob = new Blob([byte], { type: "application/jpg" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  base64ToArrayBuffer(base64)
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  deleteAttachment(att: KanbanAttachment)
  {
    this.kbs.removeAttachmentFromBoard(this.board.id, att.id).subscribe(x =>
    {
      this.board.attachments.splice(this.board.attachments.findIndex(x => x.id == att.id), 1);
    });
  }

  doNothin() { }

  updateAttachments({ file, fileList }: { [key: string]: any })
  {
    const status = file.status;
    if (status === 'done')
    {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
      {
        let att = new KanbanAttachment();
        att.name = file.name;
        att.url = reader.result.toString();
        this.kbs.addAttachmentToBoard(this.board.id, att).subscribe(x =>
        {
          att.id = x;
          this.board.attachments.push(att);
        });
      });
      reader.readAsDataURL(file.originFileObj);
    } else if (status === 'error')
    {
      console.log(`${file.name} file upload failed.`);
    }
  }
  initMailSender()
  {
    this.kbs.initCardMail(this.board.id, null);
    let user = this.cds.userCollection.find(x => x.id == this.updatedMember);
    if (user)
      this.mss.emailToInit.to.push(user.mail);
    this.mss.editMail();
  }
}
