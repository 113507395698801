import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'kanban_card_completed_tooltip'
})
export class KanbanCardCompletedTooltipPipe implements PipeTransform {

  constructor(private translateService: TranslateService){}

  transform(completed: boolean): any {
    let tooltip ="";
    if(completed)
      this.translateService.get("KANBAN.MARK-CARD-NOT-COMPLETED").subscribe(x => tooltip = x);
    else this.translateService.get("KANBAN.MARK-CARD-COMPLETED").subscribe(x => tooltip = x);
    return tooltip;
  }

}
