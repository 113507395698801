import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanNotificationComponent } from './kanban-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { KanbanNotificationRoutingModule } from './kanban-notification.routing.module';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@NgModule({
  imports: [
    CommonModule,
    KanbanNotificationRoutingModule,
    NzCardModule,
    NzGridModule,
    NzTimePickerModule,
    NzCheckboxModule,
    TranslateModule,
    FormsModule
  ],
  declarations: [KanbanNotificationComponent],
  exports: [KanbanNotificationComponent]
})
export class KanbanNotificationModule { }
