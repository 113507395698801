// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-category-spotify {
  width: 200px;
  height: 200px;
}

.container-category-spotify {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 10px;
  cursor: pointer;
}

.title-playlist-spotify {
  font-size: 18px;
  font-weight: bold;
}

.description-playlist-spotify {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/playlist-spotify/playlist-spotify.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;AACJ;;AAEE;EACE,qBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAEE;EACE,eAAA;EACA,iBAAA;AACJ;;AAEE;EACE,eAAA;AACJ","sourcesContent":[".icon-category-spotify {\r\n    width: 200px;\r\n    height: 200px;\r\n  }\r\n  \r\n  .container-category-spotify {\r\n    display: inline-block;\r\n    position: relative;\r\n    width: 200px;\r\n    height: 200px;\r\n    margin: 10px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .title-playlist-spotify{\r\n    font-size:18px;\r\n    font-weight: bold;\r\n  }\r\n\r\n  .description-playlist-spotify{\r\n    font-size:14px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
