import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomEvent } from '../entities/event';
import { concatMap } from 'rxjs/operators';
import RRule from 'rrule';
import moment from 'moment-timezone';
import { Color } from '../entities/color';
import { Subject } from 'rxjs';
import { TextCompareService } from '../../../services/text-compare.service';
import { NzModalService } from 'ng-zorro-antd/modal';


@Injectable({
    providedIn: 'root'
})
export class EventService
{

    public ressourceFilter: number[] = [];
    public displayData = [];
    private _loading: boolean
    public footer: string = ""
    public maxEvent: number = 10
    public searchTerms = "";
    public sortName = 'number';
    public sortValue = "ascend";
    public DAYTIME = 86400000;
    public service: any;
    public overlapGlobal = false;

    public updateEventSubject: Subject<any> = new Subject();

    constructor(public translate: TranslateService,
        public tcs: TextCompareService, public modalService: NzModalService) { }

    public isLoading(loading: boolean = null)
    {
        if (loading != null)
            this._loading = loading
        return this._loading
    }


    public copyOrDuplicateEvent(baseEvent: CustomEvent, finalEvent: CustomEvent)
    {

        if (!baseEvent.hasToBeDuplicated)
        {

            let tempEvent = new CustomEvent();

            //Start Date
            if (Math.abs(finalEvent.start.getTime() - baseEvent.start.getTime()) < 7 * this.DAYTIME)
            {
                tempEvent.start = baseEvent.start;
            }
            else
            {
                if (finalEvent.start.getTime() >= baseEvent.start.getTime())
                {
                    tempEvent.start.setTime(finalEvent.start.getTime() - Math.abs(finalEvent.start.getTime() - baseEvent.start.getTime()) % (7 * this.DAYTIME));
                }
                else
                {
                    tempEvent.start.setTime(finalEvent.start.getTime() + Math.abs(finalEvent.start.getTime() - baseEvent.start.getTime()) % (7 * this.DAYTIME));
                }
            }

            //End date
            if (Math.abs(finalEvent.end.getTime() - baseEvent.end.getTime()) < 7 * this.DAYTIME)
            {
                tempEvent.end = baseEvent.end;
            }
            else
            {
                if (finalEvent.end.getTime() >= baseEvent.end.getTime())
                {
                    tempEvent.end.setTime(finalEvent.end.getTime() - Math.abs(finalEvent.end.getTime() - baseEvent.end.getTime()) % (7 * this.DAYTIME));
                }
                else
                {
                    tempEvent.end.setTime(finalEvent.end.getTime() + Math.abs(finalEvent.end.getTime() - baseEvent.end.getTime()) % (7 * this.DAYTIME));
                }
            }

            tempEvent.title = baseEvent.title;
            tempEvent.originColor = new Color(baseEvent.originColor);
            tempEvent.color = baseEvent.color;
            tempEvent.rrule = baseEvent.rrule;
            tempEvent.until = baseEvent.until;
            tempEvent.type_obj = baseEvent.type_obj;
            tempEvent.id_obj = baseEvent.id_obj;
            tempEvent.name = baseEvent.name;
            tempEvent.isRecurrent = baseEvent.isRecurrent;
            tempEvent.customRule = baseEvent.customRule;
            tempEvent.isDragged = baseEvent.isDragged;
            tempEvent.completed = baseEvent.completed;
            tempEvent.temporary = baseEvent.temporary;


            tempEvent = this.service.createEvent(tempEvent);
            // this.newEvent(tempEvent); // A commenter pour la science
            this.service.deleteEvent(finalEvent);
        }
        else
        {
            let duplicatedEvent = new CustomEvent(); // We create a new event based on the selected event
            duplicatedEvent.start = baseEvent.start;
            duplicatedEvent.end = baseEvent.end;
            duplicatedEvent.title = baseEvent.title;
            duplicatedEvent.originColor = new Color(baseEvent.originColor);
            duplicatedEvent.color = baseEvent.color;
            duplicatedEvent.rrule = baseEvent.rrule;
            duplicatedEvent.type_obj = baseEvent.type_obj;
            duplicatedEvent.id_obj = baseEvent.id_obj;
            duplicatedEvent.name = baseEvent.name;
            duplicatedEvent.isRecurrent = baseEvent.isRecurrent;
            duplicatedEvent.customRule = baseEvent.customRule;
            duplicatedEvent.isDragged = baseEvent.isDragged;
            duplicatedEvent.completed = baseEvent.completed;
            duplicatedEvent.temporary = baseEvent.temporary;


            duplicatedEvent = this.service.createEvent(duplicatedEvent);
            // this.newEvent(duplicatedEvent); // We push the new event into our service

            let tempEvent = new CustomEvent(this.service.getListForCalendar(), finalEvent);
            tempEvent.until = baseEvent.start; // We give an end to the origin event reccurence.
            tempEvent.until.setHours(0);

            tempEvent = this.service.createEvent(tempEvent);
            this.service.deleteEvent(finalEvent);

        }
    }


    /* deleteOld: boolean = true;
    keepOld: boolean = false;
    deleteFrom: boolean = false;
      
    isSimpleConflict: boolean = false;
    manageSimple: boolean = false;
  
    manageKeepOld: boolean = false;
    manageKeepNew: boolean = true;
    manageFrom: boolean = false; */


    public overlapManagement(collisionedEvents: CustomEvent[], resultEvents: CustomEvent[])
    {
        collisionedEvents.forEach(x =>
        {
            if (x.id)
                this.service.deleteEvent(x);
        })

        resultEvents.forEach(x =>
        {
            this.service.createEvent(x);
        })
    }

    private getStatus(activated, registered)
    {
        if (activated)
        {
            if (registered)
                return 1
            return 0
        }
        return -1
    }

    private sortIpAddress(dataTmp, order)
    {
        return dataTmp.sort(function (a, b)
        {
            if (order === 'descend')
            {
                const c = b
                b = a
                a = c
            }
            a = a.contactDomain.split('.');
            b = b.contactDomain.split('.');
            for (var i = 0; i < a.length; i++)
            {
                if ((a[i] = parseInt(a[i])) < (b[i] = parseInt(b[i])))
                    return -1;
                else if (a[i] > b[i])
                    return 1;
            }
            return 0;
        });
    }

    sort(sort: { key: string, value: string } = null): void
    {
        if (sort)
        {
            this.sortName = sort.key;
            this.sortValue = sort.value;
        }
        const dataTmp = [...this.displayData];
        switch (this.sortName)
        {
            case 'state':
                this.displayData = dataTmp.sort((a, b) => (this.sortValue === 'ascend') ? (this.getStatus(a['activated'], a['registered']) > this.getStatus(b['activated'], b['registered']) ? 1 : -1) : (this.getStatus(b['activated'], b['registered']) > this.getStatus(a['activated'], a['registered']) ? 1 : -1));
                break;
            case 'contactDomain':
                this.displayData = this.sortIpAddress(dataTmp, this.sortValue);
                break;
            default:
                this.displayData = dataTmp.sort((a, b) => (this.sortValue === 'ascend') ? (a[this.sortName].toLowerCase() > b[this.sortName].toLowerCase() ? 1 : -1) : (b[this.sortName].toLowerCase() > a[this.sortName].toLowerCase() ? 1 : -1));
                break;
        }
    }

    public checkEvents(eventToCheck: CustomEvent, eventList: CustomEvent[], oldId?: string | number)
    {

        //Recuperation of useful events
        // let visibleEvents = this._eventList.filter(iEvent => iEvent.isRecurrent !== true); // We keep only non recurring events
        // let recurringEvents = this._eventList.filter(iEvent => iEvent.isRecurrent == true); // We extract recurring events

        let recurringEvents = [];
        /*
        if (eventToCheck.rrule && ((eventToCheck.rrule.freq == RRule.WEEKLY && eventToCheck.rrule.byweekday == [eventToCheck.start.getDay() - 1]) ||
            (eventToCheck.rrule.freq == RRule.MONTHLY && eventToCheck.rrule.bymonthday == eventToCheck.start.getDate()) ||
            (eventToCheck.rrule.freq == RRule.YEARLY && eventToCheck.rrule.bymonthday == eventToCheck.start.getDate() && eventToCheck.rrule.bymonth == eventToCheck.start.getMonth() - 1)))
            return this.checkCollisions(recurringEvents, visibleEvents, eventToCheck, oldId);
        else
            return [true, []];
            */
        return this.checkCollisions(recurringEvents, eventList, eventToCheck, oldId);
    }

    private checkCollisions(recurringEvents: CustomEvent[], visibleEvents: CustomEvent[], eventToCheck: CustomEvent, oldId: string | number)
    {
        let untilDate = new Date(eventToCheck.end);
        untilDate.setTime(untilDate.getTime() + 7 * this.DAYTIME);
        let momentEnd;
        console.log("test 1");

        recurringEvents.forEach(event =>
        {
            const rule: RRule = new RRule({
                ...event.rrule,
                dtstart: event.start,
                until: event.until != null ? event.until : untilDate
            });
            const { id, title, originColor, color, isRecurrent, rrule, start, end, id_obj, resizable, draggable, isDragged, until, customRule, temporary, location, presence } = event;
            rule.all().forEach(date =>
            {
                date.setHours(start.getHours());
                date.setMinutes(start.getMinutes());
                momentEnd = new Date(date);
                momentEnd.setDate(date.getDate() + (end.getDate() - start.getDate()));
                momentEnd.setHours(end.getHours());
                momentEnd.setMinutes(end.getMinutes());
                visibleEvents.push({
                    id,
                    title,
                    originColor,
                    color,
                    completed: false,
                    start: moment(date).toDate(),
                    end: momentEnd,
                    isRecurrent,
                    rrule,
                    temporary,
                    id_obj,
                    resizable,
                    draggable,
                    isDragged,
                    until,
                    customRule,
                    longEventStatus: 0,
                    location,
                    presence
                });
            });
        });
        console.log("test 2");
        //Checks of collisions between events
        let result = true;
        let collisionedEvents = [];
        if (eventToCheck.rrule)
        {
            let rule: RRule = new RRule({
                ...eventToCheck.rrule,
                dtstart: eventToCheck.start,
                until: eventToCheck.until != null ? eventToCheck.until : untilDate
            });
            console.log("test 3");
            console.log(eventToCheck.rrule);

            rule.all().forEach(date =>
            {
                if (eventToCheck.until && eventToCheck.until <= date)
                    return;
                let startDate = date;
                let endDate = new Date(date.getTime() + (eventToCheck.end.getTime() - eventToCheck.start.getTime()))
                visibleEvents.forEach(event_ =>
                {


                    if (event_.id != eventToCheck.id && (event_.id_obj == eventToCheck.id_obj || this.overlapGlobal) && event_.id != oldId && !((startDate.getTime() <= event_.start.getTime() && endDate.getTime() <= event_.start.getTime()) || (startDate.getTime() >= event_.end.getTime() && endDate.getTime() >= event_.end.getTime()))) // If there is no collisions we return true
                    {
                        if (collisionedEvents.findIndex(x => x.id == event_.id) < 0)
                        {
                            result = false;
                            collisionedEvents.push(event_);
                        }
                    }
                });
            });
        }
        else 
        {
            console.log("test 4");
            visibleEvents.forEach(event_ =>
            {
                if (event_.rrule)
                {
                    let rule: RRule = new RRule({
                        ...event_.rrule,
                        dtstart: event_.start,
                        until: event_.until != null ? event_.until : untilDate
                    });
                    rule.all().forEach(date =>
                    {
                        if (event_.until && event_.until < date)
                            return;
                        let startDate = date;
                        let endDate = new Date(date.getTime() + (event_.end.getTime() - event_.start.getTime()))
                        if (event_.id != eventToCheck.id && (event_.id_obj == eventToCheck.id_obj || this.overlapGlobal) && event_.id != oldId && !((startDate.getTime() <= eventToCheck.start.getTime() && endDate.getTime() <= eventToCheck.start.getTime()) || (startDate.getTime() >= eventToCheck.end.getTime() && endDate.getTime() >= eventToCheck.end.getTime()))) // If there is no collisions we return true
                        {
                            if (collisionedEvents.findIndex(x => x.id == event_.id) < 0)
                            {
                                result = false;
                                collisionedEvents.push(event_);
                            }
                        }
                    });
                }
                else if (event_.id != eventToCheck.id && (event_.id_obj == eventToCheck.id_obj || this.overlapGlobal) && event_.id != oldId && !((eventToCheck.start.getTime() <= event_.start.getTime() && eventToCheck.end.getTime() <= event_.start.getTime()) || (eventToCheck.start.getTime() >= event_.end.getTime() && eventToCheck.end.getTime() >= event_.end.getTime()))) // If there is no collisions we return true
                {
                    result = false;
                    collisionedEvents.push(event_);
                }
            });
        }
        console.log("over");
        return [result, collisionedEvents];
    }
}