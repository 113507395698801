import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlApiService } from '../services/url-api.service';
import { MessagerieFolder } from './entities/messagerie-folder';
import { MessagerieSearchParameters } from './entities/messagerie-search-parameteres';
import { MoveMailToFolders } from './entities/move-mails-folders';
import { Receivedmail } from './entities/receivedmail';
import { find, Subject } from 'rxjs';
import { MessagerieFoldersPerAccount } from './entities/messagerie-account-folder';
import { MessagerieView, MessagerieViewVirualFolder } from './entities/messagerie-view';
import { MessagerieSubFolderCreationParameters } from './entities/messagerie-subfolder-creation-parameters';
import { FolderShareParameters } from './entities/folder-share-parameters';


@Injectable({
  providedIn: 'root'
})
export class MessagerieFolderService
{

  constructor(private http: HttpClient) { }

  allfolders: MessagerieFolder[] = [];
  folderList: MessagerieFolder[] = [];
  folderByAccount: MessagerieFoldersPerAccount[] = [];
  sharedFolders: MessagerieFolder[] = [];
  ignoredSharedFolders: number[] = [];
  menus = [];
  menusByAccount = [];
  folderListChange: Subject<MessagerieFolder[]> = new Subject<MessagerieFolder[]>();
  updateMenuInterface(update = false)
  {
    this.updateInterfaceByAccount(update);
    return;
  }

  initMenuFromFolders(folder: MessagerieFolder, menuitemlist: any[], level: number)
  {
    if (folder.archived || !folder.isActive)
      return;
    let menuitem = {
      id: folder.id,
      level: level,
      title: folder.displayName ? folder.displayName : folder.name,
      icon: 'folder',
      open: folder.opened,
      folder: folder,
      unreadMailCount: folder.unreadMailCount,
      subfolderUnreadMailCount: folder.subfoldersUnreadMailCount,
      selected: false,
      disabled: false,
      children: folder.subfolders.findIndex(x => !x.archived) < 0 ? null : []
    }
    menuitemlist.push(menuitem);

    for (let sub of folder.subfolders)
    {
      let newlevel = level + 1;
      this.initMenuFromFolders(sub, menuitem.children, newlevel);
    }
  }

  updateFolderReadCount(folder: MessagerieFolder, id: number)
  {
    if (folder.id == id)
      folder.unreadMailCount--;

    for (let sub of folder.subfolders)
    {
      this.updateFolderReadCount(sub, id);
    }
  }

  updateViewReadCount(folder: MessagerieViewVirualFolder, id: number)
  {
    if (folder.realFolderIds.indexOf(id) >= 0)
      folder.unreadMailCount--;

    for (let sub of folder.subfolders)
    {
      this.updateViewReadCount(sub, id);
    }
  }

  updateInterfaceByAccount(update = false)
  {

    if (!update)
      this.menusByAccount = [];

    for (let fba of this.folderByAccount)
    {
      let menuitem = null;
      if (update)
        menuitem = this.menusByAccount.find(x => x.id == fba.accountId);
      else
      {
        menuitem = {
          id: fba.accountId,
          accountname: fba.accountMail,
          open: true,
          folders: []
        }
        this.menusByAccount.push(menuitem);
      }

      for (let folder of fba.folders)
      {
        folder.fullpath = folder.name;
        this.initMenuByAccountFromFolders(folder, menuitem.folders, 2, update)
      }
    }

    for (let sf of this.sharedFolders)
    {
      if (this.ignoredSharedFolders.indexOf(sf.id) >= 0)
        continue;

      let menu = this.menusByAccount.find(x => x.id == sf.accountId);
      if (!menu)
      {
        menu = {
          id: sf.accountId,
          accountname: sf.accountName,
          open: true,
          folders: []
        }
        this.menusByAccount.push(menu);
      }
      if (menu.folders.findIndex(x => x.id == sf.id) >= 0)
        continue;
      if (update)
      {
        let item = menu.folders.find(x => x.id == sf.id);
        if (item)
          item.unreadMailCount = sf.unreadMailCount;
      }
      else
      {
        menu.folders.push({
          id: sf.id,
          level: 1,
          title: sf.displayName ? sf.displayName : sf.name,
          icon: 'folder',
          open: sf.opened,
          folder: sf,
          unreadMailCount: sf.unreadMailCount,
          selected: false,
          disabled: false,
          children: sf.subfolders.findIndex(x => !x.archived) < 0 ? null : []
        });
      }
    }
  }

  initMenuByAccountFromFolders(folder: MessagerieFolder, menuitemlist: any[], level: number, update = false)
  {
    if (folder.archived || !folder.isActive)
      return;
    let menuitem = null;

    if (update)
    {
      menuitem = menuitemlist.find(x => x.id == folder.id);
      menuitem.unreadMailCount = folder.unreadMailCount;
      menuitem.subfolderUnreadMailCount = folder.subfoldersUnreadMailCount;
      menuitem.folder = folder;
    }
    else
    {

      menuitem = {
        id: folder.id,
        level: level,
        title: folder.displayName ? folder.displayName : folder.name,
        icon: 'folder',
        open: folder.opened,
        folder: folder,
        unreadMailCount: folder.unreadMailCount,
        subfolderUnreadMailCount: folder.subfoldersUnreadMailCount,
        selected: false,
        disabled: false,
        children: folder.subfolders.findIndex(x => !x.archived) < 0 ? null : []
      }
      menuitemlist.push(menuitem);
    }


    for (let sub of folder.subfolders)
    {
      sub.fullpath = folder.fullpath + "/" + sub.name;
      let newlevel = level + 1;
      this.initMenuByAccountFromFolders(sub, menuitem.children, newlevel, update);
    }
  }

  findFolder(folderList: MessagerieFolder[], folderid: number)
  {
    for (let folder of folderList)
    {
      if (folder.id == folderid)
        return folder;

      let fol = this.findFolder(folder.subfolders, folderid)
      if (fol)
        return fol;

    }
    return null;
  }



  updateMenuInterfaceForView(views: MessagerieView[], update = false)
  {
    if (!update)
      this.menus = [];

    for (let fba of views)
    {
      let menuitem = null;
      if (update)
        menuitem = this.menus.find(x => x.id == fba.id);
      else
      {
        menuitem = {
          id: fba.id,
          accountname: fba.name,
          open: true,
          folders: []
        }
        this.menus.push(menuitem);
      }

      for (let folder of fba.folders)
      {
        this.initMenuFromFoldersForView(folder, menuitem.folders, 2, update)
      }
    }
  }

  initMenuFromFoldersForView(folder: MessagerieViewVirualFolder, menuitemlist: any[], level: number, update = false)
  {
    let menuitem = null;
    if (update)
    {
      menuitem = menuitemlist.find(x => x.id == folder.virtualFolderName);
      menuitem.unreadMailCount = folder.unreadMailCount;
    }
    else
    {
      menuitem = {
        id: folder.virtualFolderName,
        level: level,
        title: folder.virtualFolderName,
        icon: 'folder',
        open: folder.opened,
        folder: folder,
        unreadMailCount: folder.unreadMailCount,
        selected: false,
        disabled: false,
        children: folder.subfolders.length == 0 ? null : []
      }
      menuitemlist.push(menuitem);
    }

    for (let sub of folder.subfolders)
    {
      let newlevel = level + 1;
      this.initMenuFromFoldersForView(sub, menuitem.children, newlevel, update);
    }
  }

  createFolder(folder: MessagerieFolder, accountid: number)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/` + accountid, folder);
  }

  createSubFolder(creationinfos: MessagerieSubFolderCreationParameters)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/subfolder`, creationinfos);
  }

  renameFolder(idfolder: number, newfoldername: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}`, newfoldername);
  }

  openFolder(idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/open`, null);
  }

  closeFolder(idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/close`, null);
  }

  archiveFolder(idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/archive`, null);
  }

  restoreFolder(idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/restore`, null);
  }

  readAllMailInFolder(idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/read-all`, null);
  }

  readAllMailInFolderAndSubfolders(idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/read-all-subfolders`, null);
  }

  readAllMailInMultipleFolder(idfolder: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/read-all`, idfolder);
  }

  moveMailsToMultipleFolder(moveinfo: MoveMailToFolders)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/move-mails-to-folders`, moveinfo);
  }

  getAllFolderForConnectedUser(accountidlist: number[])
  {
    return this.http.post<MessagerieFolder[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/list`, accountidlist);
  }

  getAllFolderPerAccountForConnectedUser()
  {
    return this.http.get<MessagerieFoldersPerAccount[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/list-per-account`);
  }

  getAllSystemFolderForConnectedUser()
  {
    return this.http.get<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/system`);
  }

  getAllARchivedFolderForConnectedUser(accountidlist: number[])
  {
    return this.http.post<MessagerieFolder[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/list/archived`, accountidlist);
  }

  getAllMailsForFolder(idfolder: number, start: number, number: number, searchtext: MessagerieSearchParameters)
  {
    return this.http.put<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/mails/start/${start}/number/${number}`, searchtext);
  }

  getAllConversationForFolder(idfolder: number, start: number, number: number, searchtext: MessagerieSearchParameters)
  {
    return this.http.put<MessagerieFolder[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/conversations/start/${start}/number/${number}`, searchtext);
  }

  getMailsForConversation(idconversation: number, idfolder: number, start: number, number: number)
  {
    return this.http.get<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/conversations/${idconversation}/start/${start}/number/${number}`);
  }

  //-------------- SHARED FOLDERS -----------------

  getAllSharedFolders()
  {
    return this.http.get<MessagerieFolder[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/shared`);
  }

  getAllOwnedSharedFolders()
  {
    return this.http.get<MessagerieFolder[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/shared/owned`);
  }

  shareFolderWithUser(shareParameters: FolderShareParameters)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/shared/user`, shareParameters);
  }

  changeshareFolderAuthorization(shareParameters: FolderShareParameters)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/shared/authorization`, shareParameters);
  }

  changeshareFolderVisibility(shareParameters: FolderShareParameters)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/shared/visibility`, shareParameters);
  }

  removeUserFromSharedFolder(idfolder: number, iduser: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/shared/${iduser}`);
  }

  removeTeamFromSharedFolder(idfolder: number, idteam: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/shared-team/${idteam}`);
  }

  unshareFolder(idfolder: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${idfolder}/shared`);
  }

  //-----------------------------------------------

}
