import { Pipe, PipeTransform } from '@angular/core';
import { KanbanCard } from 'src/app/kanban/entities/Card';

@Pipe({
  name: 'kanban_card_show_due_date'
})
export class KanbanCardShowDueDatePipe implements PipeTransform {

  transform(card: KanbanCard, duedate: Date, completed: boolean, enddate: Date): boolean {
    return duedate && !(completed && enddate);
  }

}
