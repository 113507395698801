import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { AuthToken } from '../entities/auth-token';
import { UrlApiService } from './url-api.service';
import { User } from '../user/model/user';
import { GroupAccess } from '../entities/group-access';
import { SignalrService } from './signalr.service';
import { Observable } from 'rxjs';
import { ChangePasswordDTO } from '../entities/change-password';
import { TranslateService } from '@ngx-translate/core';
import { HelpSetting } from '../entities/help-setting';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class AuthService
{

  private _token: AuthToken;
  private _access: GroupAccess;
  connectedUser: User;
  connectedUserAvatar = "";
  moduleList = [0, 1, 2]
  needUpdateReload = false;
  private s_login = "";
  private _clientid: string = "totoid";
  private _clientsecret: string = "secretkey_secretkey123!";
  inputsearchname = "";

  constructor(private http: HttpClient, private modalService: NzModalService, private translateService: TranslateService,
    private router: Router) { }

  setLastVisitedPage(manual?: string)
  {
    let uri = manual ? manual : this.router.url;
    if (this.Token)
      this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/user/last-visited-page?uripage=" + uri, null).subscribe();
  }

  goToLastVisitedPage()
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/last-visited-page").subscribe(x => this.router.navigate([x]));
  }



  checkLoginToken()
  {
    this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/user/donothing', null).subscribe();
  }

  setCamelineTitle(moduleName: string)
  {
    switch (moduleName)
    {
      case "kanban":
        this.translateService.get("MENU.KANBAN.TITLE").subscribe(x => document.title = x);
        break;
      case "credentials":
        this.translateService.get("MENU.CREDENTIALS.TITLE").subscribe(x => document.title = x);
        break;
      case "third-party":
        this.translateService.get("THIRD-PARTY.TITLE").subscribe(x => document.title = x);
        break;
      case "chat":
        this.translateService.get("CHAT.TITLE").subscribe(x => document.title = x);
        break;
      case "prospection":
        this.translateService.get("PROSPECTION.TITLE").subscribe(x => document.title = x);
        break;
      case "user":
        this.translateService.get("HOME.USER").subscribe(x => document.title = x);
        break;
      case "phone":
        this.translateService.get("THIRD-PARTY.CALL.PHONE-MENU.TITLE").subscribe(x => document.title = x);
        break;
      case "profile":
        this.translateService.get("THIRD-PARTY.CALL.PHONE-MENU.PROFILE").subscribe(x => document.title = x);
        break;
      case "messagerie":
        this.translateService.get("MESSAGERIE.MENU.TITLE").subscribe(x => document.title = x);
        break;
      case "security":
        this.translateService.get("MENU.IP-ADDRESS.TITLE").subscribe(x => document.title = x);
        break;
      case "monitoring":
        this.translateService.get("MENU.MONITORING").subscribe(x => document.title = x);
        break;
      case "admin":
        this.translateService.get("MENU.SETTINGS.ADMIN").subscribe(x => document.title = x);
        break;
      case "spotify":
        this.translateService.get("MENU.SPOTIFY").subscribe(x => document.title = x);
        break;
      case "glossary":
        this.translateService.get("GLOSSARY.TITLE").subscribe(x => document.title = x);
        break;
      case "planning":
        this.translateService.get("MENU.PLANNING").subscribe(x => document.title = x);
        break;
      case "changelog":
        this.translateService.get("MENU.CHANGELOG").subscribe(x => document.title = x);
        break;
      default:
        document.title = "Cameline";
        break;
    }
  }

  login(login: string, password: string, baseUrl: string)
  {

    return this.http.post<AuthToken>(baseUrl + '/OAuth/Token', "username=" + login + "&password=" + password + "&scope=bio&grant_type=password&client_id=" + this._clientid,
      {
        headers: {
          'Content-Type': "application/x-www-form-urlencoded; charset=utf-8",
          'Authorization': "Bearer " + this._clientsecret
        }
      }
    ).pipe(
      tap(rep =>
      {
        if (rep.access_token == "")
        {
          let title = "";
          this.translateService.get("LOGIN.RESET-FIRST-CONNECTION").subscribe(x => title = x);
          let content = "";
          this.translateService.get("LOGIN.RESET-FIRST-CONNECTION-CONTENT").subscribe(x => content = x);
          let urireset = rep.reset_password + "&uri=" + UrlApiService.settings.apiConfig.uriAPI + "&lang=" + (this.translateService.currentLang ? this.translateService.currentLang : "fr");
          const modal = this.modalService.create({
            nzTitle: title,
            nzContent: content,
            nzWidth: '500px',
            nzFooter: [{
              label: 'Ok',
              onClick: () =>
              {
                modal.close();
                window.location.href = urireset;
              }
            }
            ]
          });

        }
        else
        {
          let temptoken = new AuthToken();
          temptoken.access_token = rep.access_token;
          this.Token = temptoken;
        }
      })
    );
  }

  //get connected user module access rights
  getGroupAccess(baseUrl: string): Observable<GroupAccess>
  {
    return this.http.get<GroupAccess>(UrlApiService.settings.apiConfig.uriAPI + '/api/right/getgroupaccess').pipe(tap(c => this.Access = c));
  }

  shuffle(array)
  {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0)
    {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  generatePassword()
  {
    let passwordLength = 16;
    let password = "";
    var lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var symbols = ['!', '#', '$', '%', '(', ')', '*', '+', ',', '-', '.', ':', ';', '@', '[', ']', '^', '_', '{', '|', '}', '~'];
    var finalCharacters = lowerCharacters;
    var passwordArray = [];
    passwordArray.push(lowerCharacters[Math.floor(Math.random() * lowerCharacters.length)]);
    //if(passWord.addUpper){
    finalCharacters = finalCharacters.concat(upperCharacters);
    passwordArray.push(upperCharacters[Math.floor(Math.random() * upperCharacters.length)]);
    //}
    //if(passWord.addNumbers){
    finalCharacters = finalCharacters.concat(numbers);
    passwordArray.push(numbers[Math.floor(Math.random() * numbers.length)]);
    //}
    //if(passWord.addSymbols){
    finalCharacters = finalCharacters.concat(symbols);
    passwordArray.push(symbols[Math.floor(Math.random() * symbols.length)]);
    //}

    for (var i = 1; i < passwordLength - 4; i++)
    {
      passwordArray.push(finalCharacters[Math.floor(Math.random() * finalCharacters.length)]);
    }
    this.shuffle(passwordArray);

    //update the passwrod input field in the form
    password = passwordArray.join("");
    return password;
  }

  getConnectedUser()
  {
    return this.http.get<User>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getconnecteduser');
  }

  connectedUserHasAccess(idfct: number, moduleName: string): Observable<boolean>
  {
    return this.http.get<boolean>(UrlApiService.settings.apiConfig.uriAPI + '/api/right/connected-user-access?idfct=' + idfct + "&moduleName=" + moduleName);
  }

  updateLang(codeLang: string)
  {
    return this.http.put<void>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/update-lang?codelang=' + codeLang, null).subscribe();

  }

  public resetPassword(login: string)
  {
    let parameters = new HttpParams();
    parameters = parameters.append("login", login);
    parameters = parameters.append("uri", UrlApiService.settings.apiConfig.uriAPI);
    return this.http.get(UrlApiService.settings.apiConfig.uriAPI + '/api/user/generateresettokenpassword', { params: parameters });
  }

  public resetPasswordProfile(iduser: number)
  {
    let parameters = new HttpParams();
    parameters = parameters.append("uri", UrlApiService.settings.apiConfig.uriAPI);
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/user/generateresettokenpassword/' + iduser, null, { params: parameters });
  }

  public changePassword(changePassword: ChangePasswordDTO)
  {
    return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/changepassword', changePassword)

  }

  reloadServerCache()
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/right/reload-cache', null)
  }

  logout(logDisconnect: boolean)
  {
    if (logDisconnect)
    {
      this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/logout/', this.Token.access_token).subscribe(() =>
      {
        this.Token = null;
        this._access = null;
      }
      );
    }
    else
    {
      this.Token = null;
      this._access = null;
    }
  }

  public get hasAccess()
  {
    try
    {
      if (!this._access)
        this._access = JSON.parse(localStorage.getItem("sdiuser_name"));
    }
    catch (e) { }
    return this._access != null;
  }

  public get Access()
  {
    try
    {
      if (!this._access)
        this._access = JSON.parse(localStorage.getItem("sdiuser_name"));
      if (!this._access)
        this.logout(false);
    }
    catch (e) { }
    return this._access;
  }

  public set Access(value: GroupAccess)
  {
    this._access = value;
    try
    {
      localStorage.setItem("sdiuser_name", JSON.stringify(value));
    }
    catch (e) { }
  }

  public get Token()
  {
    try
    {
      if (!this._token)
        this._token = JSON.parse(localStorage.getItem("auth_token"));
    }
    catch (e) { }
    return this._token;
  }

  public set Token(value: AuthToken)
  {
    try
    {
      this._token = value;
      localStorage.setItem("auth_token", JSON.stringify(value));
    }
    catch (e) { }
  }

  public get s_Login()
  {
    try
    {
      if (!this.s_login)
        this.s_login = JSON.parse(localStorage.getItem("cameline_login"));
    }
    catch (e) { }
    return this.s_login;
  }

  public set s_Login(value: string)
  {
    try
    {
      this.s_login = value;
      localStorage.setItem("cameline_login", JSON.stringify(value));
    }
    catch (e) { }
  }
}
