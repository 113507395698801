import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportBoardComponent } from './export-board.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTreeModule } from 'ng-zorro-antd/tree';

@NgModule({
  imports: [
    CommonModule,
    NzInputModule,
    TranslateModule,
    FormsModule,
    NzCheckboxModule,
    NzButtonModule,
    NzTreeModule
  ],
  declarations: [ExportBoardComponent],
  exports: [ExportBoardComponent]
})
export class ExportBoardModule { }
