import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from 'src/app/services/url-api.service';
import { PhoneProfile } from '../entities/phone-profile';

@Injectable({
    providedIn: 'root'
  })
export class CallInterfaceProfileService {

    constructor(private http: HttpClient) { }

    createProfile(phoneProfile: PhoneProfile) {
        return this.http.post<PhoneProfile>(UrlApiService.settings.apiConfig.uriAPI + `/api/phone/profile`, phoneProfile)
    }

    updateProfile(phoneProfile: PhoneProfile, profileId: number) {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/phone/profile/${profileId}`, phoneProfile)
    }

    getProfileList() {
        return this.http.get<PhoneProfile[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/phone/profile`)
    }

    deleteProfile(profileId: number) {
        return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/phone/profile/${profileId}`);
    }

    getUserAssociated(profileId: number){
        return this.http.get<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/phone/profile/${profileId}/users`)
    }

    updateUserAssociated(phoneProfile: PhoneProfile, userId: number[]){
        return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/phone/profile/${phoneProfile.id}/users`, userId)
    }

    getProfileAssociatedToUser(id: number) {
        return this.http.get<PhoneProfile[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/user/${id}/phone-profile`)
    }

    updateActiveProfileUser(idProfile: number, idUser: number) {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/phone/profile/${idProfile}/user/${idUser}/active`, null)
    }

}
