import { Injectable } from '@angular/core';
import { PhysicalPerson } from '../third-party/physical-person/entities/physical-person';
import { MoralPerson } from '../third-party/moral-person/entities/moral-person';
import { KanbanBoard } from '../kanban/entities/Board';
import { User } from '../user/model/user';
import { GroupUser } from '../group-user/entities/group-user';
import { GlobalLabel } from '../kanban/entities/Label';
import { MeetingInfos } from '../zoom/entities/meeting-infos';
import { CalendarOccurence } from '../SDICalendar/calendar/entities/occurence';

@Injectable({
  providedIn: 'root'
})
export class CacheDataService
{

  physicalPersonCollection: PhysicalPerson[] = [];
  userCollection: User[] = [];
  teamsCollection: GroupUser[] = [];
  moralPersonCollection: MoralPerson[] = [];
  thirdPartylabelCollection: GlobalLabel[] = [];
  allGlobalLabelCollection: GlobalLabel[] = [];
  labelList: GlobalLabel[] = [];
  publicLabelList: GlobalLabel[] = [];
  meetingCollection: MeetingInfos[] = [];
  scheduledMeetingCollection: MeetingInfos[] = [];
  selectedBoard: KanbanBoard = null;
  occurence: CalendarOccurence = null;
  idAccountMailSend = 0;
  copiedMailId = 0;
  presenceOptions = [{ val: 0, color: 'green' },
  { val: 1, color: 'lightcoral' },
  { val: 2, color: 'lightgreen' },
  { val: 3, color: 'red' },
  { val: 4, color: 'orange' },
  { val: 5, color: 'purple' }];

  get allmoralperson()
  {
    let list = [];
    this.moralPersonCollection.forEach(mp =>
    {
      list.push(mp);
      mp.secondarySites.forEach(sec =>
      {
        list.push(sec);
      })
    })
    return list.sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);
  }

  get unarchivedpp()
  {
    return this.physicalPersonCollection.filter(x => !x.archived)
  }

  get unarchivedmp()
  {
    return this.allmoralperson.filter(x => !x.archived)
  }

  get unarchivedUsers()
  {
    return this.userCollection.filter(x => !x.archived);
  }

  get unarchivedTeams()
  {
    return this.teamsCollection.filter(x => x.enabled && x.showToUser)
  }

  constructor() { }

  getAvatarUser(iduser)
  {
    let user = this.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.avatar;
  }

  getAvatarTeam(idteam)
  {
    let team = this.teamsCollection.find(x => x.id == idteam);
    if (!team)
      return "";
    return team.avatar;
  }

  getTeamName(idteam)
  {
    let team = this.teamsCollection.find(x => x.id == idteam);
    if (!team)
    {
      return "";
    }
    return team.name;
  }

  getInitalTeam(idteam)
  {
    let team = this.teamsCollection.find(x => x.id == idteam);
    if (!team)
      return "";
    return team.name.slice(0, 3).toUpperCase();
  }

  getUserName(iduser)
  {
    let user = this.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.name + ' ' + user.surname;
  }

  getInitalUser(iduser)
  {
    let user = this.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.name.slice(0, 1).toUpperCase() + user.surname.slice(0, 1).toUpperCase();
  }

  getPP(id)
  {
    return this.physicalPersonCollection.find(x => x.id == id);
  }

  getPPName(id)
  {
    let pp = this.physicalPersonCollection.find(x => x.id == id);
    if (!pp)
      return "";
    return pp.firstName + " " + pp.lastName;
  }

  getMP(id)
  {
    return this.allmoralperson.find(x => x.id == id);
  }

  getMPName(id)
  {
    let pp = this.allmoralperson.find(x => x.id == id);
    if (!pp)
      return "";
    return pp.name;
  }

  storePhysicalPersonsToStorage()
  {
    localStorage.setItem("cameline_physical_persons_collection", JSON.stringify(this.physicalPersonCollection));
  }

  getPhysicalPersonsFromStorage()
  {
    this.physicalPersonCollection = JSON.parse(localStorage.getItem("cameline_physical_persons_collection"));
  }

}
