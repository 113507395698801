import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlapComponent } from './overlap.component';
import { ConflictAlertModule } from '../conflict-alert/conflict-alert.module';
import { TooglerModule } from '../form/toogler/toogler.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@NgModule({
  imports: [
    CommonModule,
    NzFormModule,
    NzAlertModule,
    ConflictAlertModule,
    NzGridModule,
    NzDividerModule,
    TooglerModule,
    TranslateModule
  ],
  declarations: [OverlapComponent],
  exports: [OverlapComponent]
})
export class OverlapModule { }
