// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spotify-player-icon {
  font-size: 25px;
  margin-right: 10px;
  cursor: pointer;
}

.spotify-player-img {
  height: 25px;
  margin-right: 10px;
  vertical-align: bottom;
  cursor: pointer;
}

.spotify-player-container {
  margin-top: 15px;
  line-height: 0px;
  text-align: center;
}

.moreInfoSpotify {
  color: white;
  background-color: #2fc944;
  border-color: #2fc944;
}

.songInfoContainer {
  margin-left: 15px;
}

.titleSongSpotify {
  color: white;
}

.artistSongSpotify {
  color: #6d7175;
}

.spotify-progress-container {
  line-height: 15px;
}

.activated {
  color: #2fc944;
}

.spotify-big-container {
  width: 600px;
}

.image-album {
  height: 50px;
  margin-left: 15px;
}

.device-row :hover {
  background-color: rgb(230, 247, 255);
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/spotify-player/spotify-player.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AACA;EACI,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,gBAAA;EACA,gBAAA;EACA,kBAAA;AAGJ;;AAAA;EACI,YAAA;EACA,yBAAA;EACA,qBAAA;AAGJ;;AAAA;EACI,iBAAA;AAGJ;;AADA;EACI,YAAA;AAIJ;;AADA;EACI,cAAA;AAIJ;;AADA;EACI,iBAAA;AAIJ;;AADA;EACI,cAAA;AAIJ;;AADA;EACI,YAAA;AAIJ;;AADA;EACI,YAAA;EACA,iBAAA;AAIJ;;AADA;EACA,oCAAA;AAIA","sourcesContent":[".spotify-player-icon{\r\n    font-size:25px;\r\n    margin-right:10px;\r\n    cursor: pointer;\r\n}\r\n.spotify-player-img{\r\n    height:25px;\r\n    margin-right:10px;\r\n    vertical-align: bottom;\r\n    cursor: pointer;\r\n}\r\n.spotify-player-container{\r\n    margin-top:15px;\r\n    line-height:0px;\r\n    text-align: center;\r\n}\r\n\r\n.moreInfoSpotify{\r\n    color:white;\r\n    background-color: #2fc944;\r\n    border-color: #2fc944;\r\n}\r\n\r\n.songInfoContainer{\r\n    margin-left:15px;\r\n}\r\n.titleSongSpotify{\r\n    color: white;\r\n}\r\n\r\n.artistSongSpotify{\r\n    color: #6d7175\r\n}\r\n\r\n.spotify-progress-container{\r\n    line-height:15px;\r\n}\r\n\r\n.activated{\r\n    color:#2fc944;\r\n}\r\n\r\n.spotify-big-container{\r\n    width:600px;\r\n}\r\n\r\n.image-album{\r\n    height:50px;\r\n    margin-left:15px;\r\n}\r\n\r\n.device-row :hover{\r\nbackground-color: rgb(230, 247, 255);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
