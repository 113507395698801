import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardCompletedTooltipPipe } from './kanban-card-completed-tooltip.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KanbanCardCompletedTooltipPipe],
  exports: [KanbanCardCompletedTooltipPipe]
})
export class KanbanCardCompletedTooltipPipeModule { }
