import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-creator-display',
  templateUrl: './creator-display.component.html',
  styleUrls: ['./creator-display.component.scss']
})
export class CreatorDisplayComponent implements OnInit
{

  constructor() { }

  @Input() creatorId: number;
  @Input() creationDate: Date;

  ngOnInit()
  {
  }

}
