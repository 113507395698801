import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { KanbanCard } from '../../entities/Card';
import { KanbanService } from '../../services/kanban.service';
import { KanbanComment } from '../../entities/Comment';
import { KanbanBoard, KanbanBoardType } from '../../entities/Board';
import { GlobalLabel } from '../../entities/Label';
import { KanbanChecklist } from '../../entities/Checklist';
import { KanbanList } from '../../entities/List';
import { KanbanAttachment } from '../../entities/Attachment';
import { User } from '../../../user/model/user';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { KanbanActivity } from '../../entities/Activity';
import { MoveCardSpecific } from '../../entities/move-card-specific';
import { TodoService } from '../../services/todo.service';
import { KanbanMoveCopyCardComponent } from '../kanban-move-copy-card/kanban-move-copy-card.component';
import { MoralPersonService } from '../../../third-party/moral-person/services/moral-person.service';
import { KanbanToolsService } from '../../services/kanban-tools.service';
import { UrlApiService } from '../../../services/url-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, fromEvent, firstValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ColorService } from '../../../services/color.service';
import { InterModuleService } from '../../../services/inter-module.service';
import { KanbanActionsCreationCardComponent } from '../kanban-actions-creation-card/kanban-actions-creation-card.component';
import { CacheDataService } from '../../../services/cache-data.service';
import { CallThirdPartyComponent } from '../call-third-party/call-third-party.component';
import { ChecklistDisplayInfos } from '../../entities/checklist-display-infos';
import { PhysicalPersonService } from '../../../third-party/physical-person/services/physical-person.service';
import { MessagerieService } from '../../../messagerie/messagerie.service';
import { KanbanLabelDrawerComponent } from '../kanban-label-drawer/kanban-label-drawer.component';
import { KANBAN_CARD_PLANNING, THIRD_PARTY_SHEET } from '../../../call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { ComponentType } from '@angular/cdk/portal';
import { TextCompareService } from '../../../services/text-compare.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NameUserPipe } from '../../../pipes/name-user-pipe/name-user.pipe';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KanbanEditCardService } from '../../services/kanban-edit-card.service';
import { ModuleLabel } from '../../../global-label/entities/modules-label';
import { NotesService } from '../../../notes/notes.service';
import { KanbanActionParameters } from '../../entities/kanban-action-parameters';
import { KanbanActionsService } from '../../services/kanban-actions.service';
import { Reccurence } from '../../../SDICalendar/calendar/entities/reccurence';
import { HttpClient } from '@angular/common/http';
import { CalendarOccurence } from '../../../SDICalendar/calendar/entities/occurence';
import { VariableSubstitutionService } from '../../../services/variable-substitution.service';
import { ChecklistTemplateManagerComponent } from '../checklist-template-manager/checklist-template-manager.component';
import { PresenceInfos, PresenceState } from '../../entities/presence-infos';
declare function getInputCursorPosition(id: string): number;
@Component({
  selector: 'app-kanban-card-edit',
  templateUrl: './kanban-card-edit.component.html',
  styleUrls: ['./kanban-card-edit.component.scss']
})
export class KanbanCardEditComponent implements OnInit
{

  @Input() card: KanbanCard = new KanbanCard();
  @Input() board: KanbanBoard = new KanbanBoard();
  @Input() list: KanbanList = new KanbanList();
  @Input() loaddata = true;


  addLabel = false;

  customColor = false;
  hue: string;

  descriptionTemp = "";
  commentTemp: string = "";
  checkListCreatorVisible: boolean;
  createdChecklistTitle = "";
  recurrence = false;
  commentsInEdition: KanbanComment[] = [];
  copyTitle = "";
  selectedLabel: GlobalLabel = new GlobalLabel();
  copyChecklist = true;
  copyLabels = true;
  copyMembers = true;
  addDescription = true;
  copyAttachments = true;
  showActivities = false;
  showThirdParty = false;
  showMails = false;
  copyComments = true;
  addComment = false;
  dateCreatorVisible;
  copyBoard: KanbanBoard = new KanbanBoard();
  copyList: KanbanList = new KanbanList();
  copyBoardVisible = false;
  copyListVisible = false;
  copyPositionVisible = false;
  copyGlobalVisible = false;
  moveSearchValue = "";
  boardsForMove: any[] = [];
  todoListForMove: any[] = [];
  moveGlobalVisible = false;
  publicLabelsCollapsed = true;
  labelsCollapsed = false;
  copyPosition: number;
  focusedCommentOptions;
  potentialMembers: number[] = [];
  selectedForMove = "";
  selectedType = "";
  selectedListForMove = "";
  selectedBoardForMove = "";
  boardForMove: KanbanBoard[] = [];
  filterTextForTree = "";
  idselected = null;
  initAttachments = false;
  selectedTP = 0;
  tpfocus = 0;
  showAttachments = false;
  visuHeight = "800px";
  filterTPRelationText = "";
  pasteSubscription: Subscription;
  showBeginDate = true;
  showEndDate = true;
  showCreationDate = false;
  showPresenceSelector = false;
  subscriptionPP: Subscription;
  subscriptionMP: Subscription;
  checklistDataLoaded = false;
  isCreatingPP = false;
  creatingPPFirstName = "";
  creatingPPLastName = "";
  isCreatingMP = false;
  creatingMPName = "";
  filterDrawer = "";
  showCustomDueDate = false;
  showCustomStartDate = false;


  @ViewChild('memberSelector', { static: false }) templatemember?: any;

  constructor(@Inject(KANBAN_CARD_PLANNING) private cardPlanningComponent: ComponentType<any>,
    @Inject(THIRD_PARTY_SHEET) private component: ComponentType<any>,
    public kbs: KanbanService,
    private http: HttpClient,
    public auth: AuthService,
    private tds: TodoService,
    public ns: NotesService,
    private modalService: NzModalService,
    public kts: KanbanToolsService,
    private sanitizer: DomSanitizer,
    private datepipe: DatePipe,
    public cs: ColorService,
    public cds: CacheDataService,
    public ims: InterModuleService,
    public mss: MessagerieService,
    public kas: KanbanActionsService,
    private pps: PhysicalPersonService,
    private mps: MoralPersonService,
    public kecs: KanbanEditCardService,
    public tcs: TextCompareService,
    public vss: VariableSubstitutionService,
    private nameuserpipe: NameUserPipe,
    private drawerService: NzDrawerService,
    public messageService: NzMessageService, public translate: TranslateService)
  {
  }

  createdLabel: GlobalLabel = {
    id: 0,
    title: "",
    color: "#61bd4f",
    creatorId: 0,
    thirdPartyType: null,
    boardId: null,
    modules: ModuleLabel.Kanban,
    creationDate: new Date()
  }

  async ngOnInit()
  {
    this.mss.openedCardId = this.card.id;

    console.log(this.loaddata, this.card);

    if (this.loaddata)
      this.card = await firstValueFrom(this.kbs.getCardInfos(this.card.id));

    this.kbs.getMailsForCard(this.card.id).subscribe(x =>
    {
      this.card.mails = x;
    })
    if (this.card.dueDateTemplate)
      this.showCustomDueDate = true;
    if (this.card.startDateTemplate)
      this.showCustomStartDate = true;
    if (this.card.startDate)
      this.card.startDate = new Date(this.card.startDate)
    if (this.card.dueDate)
      this.card.dueDate = new Date(this.card.dueDate)
    if (this.card.endDate)
      this.card.endDate = new Date(this.card.endDate)

    this.kts.titleHasFocus = false;
    this.kts.descriptionHasFocus = false;
    this.kbs.currentListChange = false;
    this.kbs.currentEditPositionCard = this.card.listId;

    this.kts.oldTitle = this.card.title;
    this.kts.oldDescription = this.card.description;
    this.kbs.visualizedUrl = "";
    if (this.card.reccurence)
      this.recurrence = true;
    this.vss.getVariableSubstitution().subscribe();

    this.kbs.getCardChecklistDisplayInfos(this.card.id).subscribe(() =>
    {
    });

    this.subscriptionPP = this.pps.physicalPersonListChange.subscribe(() =>
    {
      for (let i = 0; i < this.card.physicalPersons.length; ++i)
      {
        let pp = this.cds.physicalPersonCollection.find(x => x.id == this.card.physicalPersons[i].id);
        this.card.physicalPersons[i] = pp;
      }
    })

    this.subscriptionMP = this.mps.moralPersonListChange.subscribe(() =>
    {
      for (let i = 0; i < this.card.moralPersons.length; ++i)
      {
        let mp = this.cds.allmoralperson.find(x => x.id == this.card.moralPersons[i].id);
        this.card.moralPersons[i] = mp;
      }
    })

    if (this.cds.publicLabelList.length == 0)
    {
      this.kbs.getPublicLabelList().subscribe();
    }
    if (this.board)
    {
      this.kbs.getLabelList(this.board.id).subscribe();
      this.potentialMembers = this.board.members;
    }
    else
    {
      if (this.card.boardId)
        this.kbs.getLabelList(this.card.boardId).subscribe();
      else if (this.board)
        this.kbs.getLabelList(this.board.id).subscribe();
      else this.cds.labelList = [];
      this.potentialMembers = this.kbs.nonArchivedList.map(x => x.id);
    }


    if (this.card.comments.length > 0 && this.kbs.editMode == "normal")
      this.addComment = true

  }

  async reccurenceManager()
  {
    if (!this.cds.occurence)
      return;
    let idcard = await firstValueFrom(this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/occurence`, this.cds.occurence));


    this.cds.occurence = null;
    this.card = await firstValueFrom(this.kbs.getCardInfos(idcard));
    this.card.reccurenceId = this.card.reccurence.id;
  }

  initCardCreationDueVariable()
  {
    if (this.showCustomDueDate)
    {
      let vari = this.vss.variableList.find(x => x.searchCode == "$$cardcreationtimeadd");

      if (vari)
        this.card.dueDateTemplate = vari.code;
    }
    else
    {
      this.card.dueDateTemplate = "";
    }
    this.updateDueDateTemplate();
  }

  initCardCreationStartVariable()
  {
    if (this.showCustomStartDate)
    {
      let vari = this.vss.variableList.find(x => x.searchCode == "$$cardcreationtimeadd");

      if (vari)
        this.card.startDateTemplate = vari.code;
    }
    else
    {
      this.card.startDateTemplate = "";
    }
    this.updateStartDateTemplate();
  }

  async applyModificationToReccurence()
  {

  }

  inputtimeout = null;

  updateStartDateTemplate()
  {
    if (this.inputtimeout)
      clearTimeout(this.inputtimeout);

    this.inputtimeout = setTimeout(() =>
    {
      this.kbs.updateTemplateStartDateVariable(this.card.id, this.card.startDateTemplate).subscribe(() =>
      {
        this.inputtimeout = null;
        clearTimeout(this.inputtimeout);
      });
    }, 500);
  }

  updateDueDateTemplate()
  {
    if (this.inputtimeout)
      clearTimeout(this.inputtimeout);

    this.inputtimeout = setTimeout(() =>
    {
      this.kbs.updateTemplateDueDateVariable(this.card.id, this.card.dueDateTemplate).subscribe(() =>
      {
        this.inputtimeout = null;
        clearTimeout(this.inputtimeout);
      });
    }, 500);
  }

  editLabels()
  {
    this.filterDrawer = "";
    let title = "";
    this.translate.get("KANBAN.ADD-CARD-OPTIONS-TITLE.LABELS").subscribe(x => title = x);
    this.drawerService.create({
      nzTitle: title,
      nzContent: KanbanLabelDrawerComponent,
      nzContentParams: {
        card: this.card,
        board: this.board
      },
      nzWidth: '512px'
    });
  }

  editMembers()
  {
    this.filterDrawer = "";
    let title = "";
    this.translate.get("KANBAN.ADD-CARD-OPTIONS-TITLE.MEMBERS").subscribe(x => title = x);
    this.drawerService.create({
      nzTitle: title,
      nzContent: this.templatemember,
      nzWidth: '700px'
    });
  }

  getPotentialMembers()
  {
    return this.kbs.getUsersForBoard(this.board, this.filterDrawer)
  }

  getFilteredPublicLabels()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    return this.cds.publicLabelList.filter(x => this.tcs.contains(x.title, upperFilter))
  }

  getFilteredBoardLabels()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    return this.cds.labelList.filter(x => this.tcs.contains(x.title, upperFilter))
  }

  openCheckistTemplates()
  {
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: ChecklistTemplateManagerComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzFooter: [{
        label: close,
        danger: true,
        onClick: (result) =>
        {
          modal.close()
        }
      }]
    });
  }

  openCardPlanning()
  {
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: this.cardPlanningComponent,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: close,
        danger: true,
        onClick: (result) =>
        {
          modal.close()
        }
      }]
    });
    modal.componentInstance.card = this.card;
    modal.componentInstance.list = this.list;
    modal.componentInstance.board = this.board;
  }

  changeCardFullDay()
  {
    if (this.recurrence)
    {
      this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/card/${this.card.id}/full-day`, this.card.fullDay).subscribe(() =>
      {
      })
    }
  }

  setRecurrence()
  {
    if (this.recurrence)
    {
      this.card.reccurence = new Reccurence();
      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/recurrence/card/${this.card.id}`, this.card.reccurence).subscribe(id =>
      {
        this.card.reccurence.id = id;
      })
    }
    else
    {
      this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/recurrence/${this.card.reccurence.id}`).subscribe(() =>
      {
        this.card.reccurence = null;
      })
    }
  }

  updateReccurrence(reccurence: Reccurence)
  {
    reccurence.id = this.card.reccurence.id;
    this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/planning/recurrence`, reccurence).subscribe(() =>
    {
      this.card.reccurence = reccurence;
    })
  }

  getCardPosition()
  {
    if (!this.list || this.list.cards.length == 0)
      return ""
    let text;
    let i, rank, listLength = 0;
    for (i = 0; i < this.list.cards.length; i++)
    {
      if (!this.list.cards[i].archived && this.card.id == this.list.cards[i].id)
        rank = listLength + 1;
      if (!this.list.cards[i].archived)
        listLength++;
    }
    this.translate.get('KANBAN.CARD-NUMBER', { v: rank + "/" + listLength }).subscribe(x => text = x)
    return text;
  }

  manageActions()
  {
    let title = "";
    this.translate.get("KANBAN.MANAGE-LIST-ACTIONS-CARD-CREATION").subscribe(x => title = x);
    let titleConfirm = "";
    this.translate.get("KANBAN.ACTION-CONFIRM-CLOSE-TITLE").subscribe(x => titleConfirm = x);
    let contentConfirm = "";
    this.translate.get("KANBAN.ACTION-CONFIRM-CLOSE-CONTENT").subscribe(x => contentConfirm = x);
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let cancel = "";
    this.translate.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let save = "";
    this.translate.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanActionsCreationCardComponent,
      nzWidth: '80%',
      nzFooter: [{
        label: close,
        onClick: (result) =>
        {
          let confirmModal = this.modalService.confirm({
            nzTitle: titleConfirm,
            nzContent: contentConfirm,
            nzCancelText: cancel,
            nzOkText: close,
            nzOnOk: () =>
            {
              if (modal)
                modal.close();
              confirmModal.destroy();
            }

          });

        }
      }, {
        label: save,
        type: "primary",
        onClick: async (result) =>
        {
          await this.reccurenceManager();
          this.kbs.updateListActionsOfCard(this.card.id, this.kbs.actionsControls).subscribe(x =>
          {
            this.card.actions = this.kbs.actionsControls;
            if (modal)
              modal.close();
          })
        }
      }]
    });
    modal.componentInstance.card = this.card;
    modal.componentInstance.list = this.list;
    modal.componentInstance.board = this.board;
  }

  ngOnDestroy()
  {
    if (this.pasteSubscription)
      this.pasteSubscription.unsubscribe();
    this.mss.openedCardId = 0;
  }

  pasteFunction(pasteEvent: ClipboardEvent)
  {
    var items = pasteEvent.clipboardData.items;
    if (!items)
      return;

    for (var i = 0; i < items.length; i++)
    {
      // Skip content if not image
      if (items[i].type.indexOf("image") == -1) continue;
      // Retrieve image on clipboard as blob
      var blob = items[i].getAsFile();

      var reader = new FileReader();

      reader.addEventListener('load', async () =>
      {
        let date = new Date();
        let att = new KanbanAttachment();
        att.name = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss") + "_clipboard.png";
        att.url = reader.result.toString();

        await this.reccurenceManager();
        this.kbs.addAttachment(this.card.id, att).subscribe(x =>
        {
          att.id = x;
          this.card.attachments.push(att);
        });
      });
      reader.readAsDataURL(blob);
    }
  }

  getAttachmentUri()
  {
    return UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/attachment`;
  }

  updateAttachmentsPictures(attachment: KanbanAttachment[])
  {
    this.card.attachments = attachment;
  }

  doShowAttachments()
  {
    if (this.showAttachments)
      return "block";
    return "none";
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (user)
      return user.avatar;
    return ""
  }

  getMemberInitial(user: User)
  {
    return user.name.slice(0, 1).toUpperCase() + user.surname.slice(0, 1).toUpperCase()
  }

  initCopyCard()
  {
    let title = "";
    this.translate.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalMove = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanMoveCopyCardComponent,
      nzBodyStyle: { height: '90vh' },
      nzWidth: '90%',
      nzFooter: null
    });
    this.kbs.modalMove.componentInstance.card = this.card;
    this.kbs.modalMove.componentInstance.list = this.list;
    this.kbs.modalMove.componentInstance.board = this.board;
    this.kbs.modalMove.componentInstance.typeaction = "copy";
  }

  getCreatorName()
  {
    let user = this.cds.userCollection.find(x => x.id == this.card.creatorId);
    if (user)
      return user.name + " " + user.surname;
    return "";
  }


  getSortedLabels(sort: boolean)
  {
    if (sort)
      return [...this.card.labels].sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
    else return [...this.card.labels];
  }

  initMoveCard()
  {
    let title = "";
    this.translate.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalMove = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanMoveCopyCardComponent,
      nzBodyStyle: { height: '90vh' },
      nzWidth: '90%',
      nzFooter: null
    });
    this.kbs.modalMove.componentInstance.card = this.card;
    this.kbs.modalMove.componentInstance.list = this.list;
    this.kbs.modalMove.componentInstance.board = this.board;
    this.kbs.modalMove.componentInstance.typeaction = "move";
  }

  async unlinkCard()
  {

    if (this.card.members.length == 0)
    {
      await this.reccurenceManager();
      this.kbs.addMemberToCard(this.card.id, this.auth.Access.idUser).subscribe(x =>
      {
        this.card.members.push(this.auth.Access.idUser)
      })
    }
    else
    {
      if (this.card.members.findIndex(x => x == this.auth.Access.idUser) < 0)
      {
        let title = "prout";
        this.translate.get("KANBAN.EDIT-CARD.UNLINK-NO-MEMBER").subscribe(x => title = x);
        let content = "pouet";
        this.translate.get("KANBAN.EDIT-CARD.UNLINK-NO-MEMBER-CONFIRM").subscribe(x => content = x);
        let confirm = "";
        this.translate.get("GENERIC-ACTIONS.YES").subscribe(x => confirm = x);
        let cancel = "";
        this.translate.get("GENERIC-ACTIONS.NO").subscribe(x => cancel = x);
        this.modalService.confirm({
          nzTitle: title,
          nzContent: content,
          nzOkText: confirm,
          nzOnOk: async () =>
          {
            await this.reccurenceManager();
            this.kbs.addMemberToCard(this.card.id, this.auth.Access.idUser).subscribe(x =>
            {
              this.card.members.push(this.auth.Access.idUser)
            })
          },
          nzCancelText: cancel,
          nzOnCancel: () =>
          {

          }

        });
      }
    }

    this.kbs.unlinkCard(this.card.id).subscribe(x => 
    {
      if (!this.list)
        this.tds.updateChange.next(null);
      else this.kbs.updateBoardChange.next(null)
    })
  }

  cancel()
  {

  }

  initBoardMove(x: KanbanBoard[])
  {
    let filterValueUpper = this.moveSearchValue.toUpperCase();
    this.boardsForMove = [];
    this.todoListForMove = [];
    x.forEach(board =>
    {
      let addToList = this.tcs.contains(board.title, filterValueUpper)
      let boardNode = {
        title: board.title,
        key: "board_" + board.id,
        bgcolor: board.bgColor,
        expanded: false,
        idlist: 0,
        children: []
      };
      board.lists.forEach(list =>
      {
        if (!addToList)
          addToList = this.tcs.contains(list.title, filterValueUpper)
        let listNode =
        {
          title: list.title,
          key: "list_" + list.id,
          children: []
        };
        list.cards.forEach(card =>
        {
          let cardnode = {
            title: card.title,
            key: "card_" + card.id,
            isLeaf: true
          };
          if (board.type == KanbanBoardType.Todo)
          {
            boardNode.children.push(cardnode);
            boardNode.idlist = list.id;
          }
          else listNode.children.push(cardnode);
        })
        if (board.type != KanbanBoardType.Todo)
          boardNode.children.push(listNode);
      });
      if (addToList)
      {
        if (board.type == KanbanBoardType.Todo)
          this.todoListForMove.push(boardNode);
        else this.boardsForMove.push(boardNode);
      }

    });
    this.boardsForMove = [...this.boardsForMove];
    this.todoListForMove = [...this.todoListForMove];
  }

  selectListToMove(event: any)
  {
    if (!event.node.isLeaf)
      event.node.isExpanded = !event.node.isExpanded
    if (event.node.key.startsWith("board"))
    {
      this.selectedForMove = "";
      this.selectedBoardForMove = event.node.key;
    }
    else
    {
      this.selectedForMove = event.node.key;

      this.selectedListForMove = event.node.key;
      let theevent = event.node.parentNode;
      while (theevent.parentNode)
      {
        if (theevent.key.startsWith("list"))
          this.selectedListForMove = theevent.key;
        else if (theevent.key.startsWith("board"))
          this.selectedBoardForMove = theevent.key;
        theevent = theevent.parentNode;
      }
      if (this.selectedType == "todo")
        this.selectedListForMove = "list_" + this.todoListForMove.find(x => x.key == this.selectedBoardForMove).idlist;
      event.node.isSelected = true;
    }

  }

  cancelMove()
  {
    this.moveGlobalVisible = false;
  }

  updateMoveBoard()
  {
    let taskIndex = this.tds.taskList.findIndex(x => x.id == this.card.id);
    if (taskIndex >= 0)
    {
      this.tds.taskList.splice(taskIndex, 1);
      this.tds.taskListChange.next(null);
    }
    else if (this.list)
    {
      //taskIndex = this.list.cards.findIndex(x => x.id == this.card.id);
      //this.list.cards.splice(taskIndex, 1);      
      this.kbs.GetAllListForBoard(this.board.id, this.kbs.filterBoardLists).subscribe(x =>
      {
        this.board.lists = x;
        this.kbs.kanbanBoardChange.next(null);
      });
    }
    if (this.kbs.modalEdit)
      this.kbs.modalEdit.close();
  }

  async moveStartTodoList()
  {
    let board = this.todoListForMove.find(x => x.key == this.selectedBoardForMove);
    if (!board)
      return;
    await this.reccurenceManager();
    let idlist = board.idlist;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = idlist;
    this.kbs.moveCardToListStart(movecard).subscribe(x =>
    {
      this.updateMoveBoard();
      this.moveGlobalVisible = false;

    });
  }

  async moveEndTodoList()
  {
    let board = this.todoListForMove.find(x => x.key == this.selectedBoardForMove);
    if (!board)
      return;
    await this.reccurenceManager();
    let idlist = board.idlist;
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = idlist;
    this.kbs.moveCardToListEnd(movecard).subscribe(x =>
    {
      this.updateMoveBoard();
      this.moveGlobalVisible = false;

    });
  }

  async moveStartList()
  {
    await this.reccurenceManager();
    let idlist = parseInt(this.selectedForMove.split('_')[1]);
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = idlist;
    this.kbs.moveCardToListStart(movecard).subscribe(x =>
    {
      this.updateMoveBoard();
      this.moveGlobalVisible = false;

    });
  }

  async moveEndList()
  {
    await this.reccurenceManager();
    let idlist = parseInt(this.selectedForMove.split('_')[1]);
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = idlist;
    this.kbs.moveCardToListEnd(movecard).subscribe(x =>
    {
      this.updateMoveBoard();
      this.moveGlobalVisible = false;
    });
  }

  getBoardColor(boardkey: string)
  {
    let board = this.boardsForMove.find(x => x.key == boardkey);
    if (!board)
      board = this.todoListForMove.find(x => x.key == boardkey);
    return board.bgcolor;
  }

  async moveBeforeCard()
  {
    await this.reccurenceManager();
    let idcard = parseInt(this.selectedForMove.split('_')[1]);
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = parseInt(this.selectedListForMove.split('_')[1]);
    movecard.idcardto = idcard;
    this.kbs.moveCardToBeforeCard(movecard).subscribe(x =>
    {
      this.updateMoveBoard();
      this.moveGlobalVisible = false;
    });
  }

  async moveAfterCard()
  {
    await this.reccurenceManager();
    let idcard = parseInt(this.selectedForMove.split('_')[1]);
    let movecard = new MoveCardSpecific();
    movecard.idcard = this.card.id;
    movecard.idlistfrom = this.list ? this.list.id : this.card.listId;
    movecard.idlistto = parseInt(this.selectedListForMove.split('_')[1]);
    movecard.idcardto = idcard;
    this.kbs.moveCardToAfterCard(movecard).subscribe(x =>
    {
      this.updateMoveBoard();
      this.moveGlobalVisible = false;
    });
  }

  doNothing(event: any)
  {

  }

  filterLisForMove(filter: string)
  {
    this.initBoardMove(this.boardForMove);
    this.filterTextForTree = filter;
  }

  toogleWhite(hex)
  {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result)
    {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      if ((r + g + b) > 620)
        return true;
    }
    return false
  }

  setCopyBoard(boardChoice: KanbanBoard)
  {
    this.kbs.GetAllListForBoard(boardChoice.id, this.kbs.filterBoardLists).subscribe(x =>
    {
      boardChoice.lists = x;
      this.copyBoardVisible = false;
      this.copyBoard = boardChoice;
      this.copyList = boardChoice.lists[0];
    });
  }

  setCopyList(listChoice: KanbanList)
  {
    this.copyListVisible = false;
    this.copyList = listChoice;
  }

  setCopyPosition(pos: number)
  {
    this.copyPositionVisible = false;
    this.copyPosition = pos;

  }

  getCopyBoard()
  {
    return this.kbs.allBoardList.filter(x => !x.archived);
  }

  getCopyList()
  {
    return this.copyBoard.lists.filter(x => !x.archived);
  }

  getPositionList()
  {
    let posList: number[] = [];
    if (this.copyList.cards.length == 0)
    {
      posList.push(1);
      this.copyPosition = 1;
      return posList;
    }

    for (var i = 0; i < this.copyList.cards.length; i++)
    {
      posList.push(i + 1);
    }
    this.copyPosition = posList[0];
    return posList;
  }

  async createCopy()
  {
    await this.reccurenceManager();
    let copyCard = new KanbanCard();
    copyCard.title = this.copyTitle;
    copyCard.description = this.card.description;
    copyCard.startDate = this.card.startDate;
    copyCard.dueDate = this.card.dueDate;
    copyCard.archived = this.card.archived;
    if (this.copyChecklist)
      copyCard.checklists = [...this.card.checklists];
    if (this.copyLabels)
      copyCard.labels = [...this.card.labels];
    if (this.copyMembers)
      copyCard.members = [...this.card.members];
    if (this.copyAttachments)
      copyCard.attachments = [...this.card.attachments];
    if (this.copyComments)
      copyCard.comments = [...this.card.comments];
    this.kbs.addCardToList(this.copyList.id, copyCard).subscribe(x =>
    {
      copyCard = x;
      this.copyList.cards.splice(this.copyPosition - 1, 0, copyCard);
      this.copyGlobalVisible = false;
    });
  }

  async saveStartDate(result: Date)
  {
    await this.reccurenceManager();
    this.kbs.updateCardStartDate(this.card.id, result).subscribe(() => 
    {
      this.kbs.updateCardDate.next(this.card)
    });
  }

  async saveEndDate(result: Date)
  {
    await this.reccurenceManager();

    this.kbs.updateCardEndDate(this.card, result).subscribe(() => 
    {
      if (!result && this.card.fullDay)
      {
        this.card.fullDay = false;
        this.changeCardFullDay();
      }
      this.kbs.updateCardDate.next(this.card)
    });
  }

  saveByteArray(reportName, byte)
  {
    var blob = new Blob([byte], { type: "application/jpg" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  base64ToArrayBuffer(base64)
  {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++)
    {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  moveCard()
  {

  }



  doNothin() { }

  checkDueDate()
  {
    return this.card.dueDate && new Date(this.card.dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  }

  checkItemIsEdited(item: KanbanComment)
  {
    return this.focusedCommentOptions && this.focusedCommentOptions.id == item.id;
  }

  getNotificationText(activity: KanbanActivity)
  {
    let text = activity.user.name + " " + activity.user.surname + " ";
    switch (activity.parameters.length)
    {
      case 0:
        this.translate.get(activity.codeText).subscribe(x => text += x);
        break;
      case 1:
        this.translate.get(activity.codeText, { v: activity.parameters[0] }).subscribe(x => text += x);
        break;
      case 2:
        this.translate.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1] }).subscribe(x => text += x);
        break;
      case 3:
        this.translate.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1], v3: activity.parameters[2] }).subscribe(x => text += x);
        break;
    }

    return text;
  }

  setFocus()
  {
    var elem = document.getElementById("kanban-card-title");
    elem.style.display = "inline-block";
    elem.focus();
    this.kts.titleHasFocus = true;
  }

  cancelTitleUpdate(card: KanbanCard, comp: KanbanCardEditComponent)
  {
    comp.card.title = comp.kts.oldTitle;
  }

  async removeFocus()
  {
    if (this.kts.oldTitle == this.card.title)
    {
      this.kts.titleHasFocus = false;
      document.getElementById("kanban-card-title").style.display = "none";
      return;
    }

    let title = this.card.title;

    await this.reccurenceManager();

    this.card.title = title;

    if (this.list)
      this.kas.applyActions(this.card, this.list.id, this.board, "1", this.addTitlePostProcessing, this.cancelTitleUpdate, this);
    else 
    {
      this.kbs.updateCardObject(this.card.id, this.card.title).subscribe(async x =>
      {
        document.getElementById("kanban-card-title").style.display = "none";
        this.kts.titleHasFocus = false;
        this.kbs.updateCardDate.next(this.card);
        this.kts.oldTitle = this.card.title;
        let noteindex = this.ns.notes.findIndex(x => x.id == this.card.id);
        if (noteindex >= 0)
          this.ns.notes[noteindex].title = this.card.title;
      });
    }
  }

  addTitlePostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanCardEditComponent)
  {
    let noteindex = comp.ns.notes.findIndex(x => x.id == comp.card.id);
    if (noteindex >= 0)
    {
      comp.ns.notes[noteindex].title = comp.card.title;
    }

    comp.kbs.updateCardObject(card.id, card.title).subscribe(async x =>
    {
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
      document.getElementById("kanban-card-title").style.display = "none";
      comp.kts.titleHasFocus = false;
      this.kbs.updateCardDate.next(card);
      comp.kts.oldTitle = card.title;
    });
  }



  setDescriptionFocus()
  {
    var elem = document.getElementById("kanban-card-description");
    elem.style.display = "inline-block";
    elem.focus();
    this.descriptionTemp = this.card.description;
    this.kts.descriptionHasFocus = true;
    this.addDescription = true;
  }

  removeDescriptionFocus()
  {
    document.getElementById("kanban-card-description").style.display = "none";
    this.kts.descriptionHasFocus = false;
  }

  getDescriptionHeigh()
  {
    let title = document.getElementById("kanban-card-description");
    return title.scrollHeight + 'px';
  }

  locationTemp = "";
  showLocationInput = false;
  saveLocation()
  {
    if (!this.locationTemp)
      this.locationTemp = "";
    this.kbs.updateCardLocation(this.card.id, this.locationTemp).subscribe(() =>
    {
      document.getElementById("kanban-card-location").style.display = "none";
      this.showLocationInput = false;
      this.card.location = this.locationTemp;
    })
  }

  setLocationFocus()
  {
    var elem = document.getElementById("kanban-card-location");
    elem.style.display = "inline-block";
    elem.focus();
    this.locationTemp = this.card.location;
    this.showLocationInput = true;
  }

  getLocationHeigh()
  {
    let title = document.getElementById("kanban-card-location");
    return title.scrollHeight + 'px';
  }

  getTitleHeigh()
  {
    let title = document.getElementById("kanban-card-title");
    return title.scrollHeight + 'px';
  }

  cancelDescriptionUpdate(card: KanbanCard, comp: KanbanCardEditComponent)
  {
    comp.card.description = comp.kts.oldDescription;
  }



  async saveDescription()
  {
    if (this.showDescriptionVariableMenu)
      return;
    if (this.card.description == this.descriptionTemp)
    {
      this.removeDescriptionFocus();
      return;
    }

    await this.reccurenceManager();


    this.card.description = this.descriptionTemp;

    if (this.list)
      this.kas.applyActions(this.card, this.list.id, this.board, "4", this.updateDescriptionPostProcessing, this.cancelDescriptionUpdate, this);
    else 
    {
      this.kbs.updateCardDescription(this.card.id, this.card.description).subscribe(() =>
      {
        document.getElementById("kanban-card-description").style.display = "none";
        this.kts.descriptionHasFocus = false;
        let noteindex = this.ns.notes.findIndex(x => x.id == this.card.id);
        if (noteindex >= 0)
          this.ns.notes[noteindex].description = this.card.description;
      })
    }
    //this.kts.updateCardProcess(this.card, this.list, this.board, "4", null);
  }

  updateDescriptionPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanCardEditComponent)
  {
    comp.kbs.updateCardDescription(card.id, card.description).subscribe(async x =>
    {
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
      document.getElementById("kanban-card-description").style.display = "none";
      comp.kts.descriptionHasFocus = false;
    });
  }

  cancelDescription()
  {
    this.descriptionTemp = this.card.description;
    this.removeDescriptionFocus();
  }

  cancelAction(card: KanbanCard, comp: KanbanCardEditComponent)
  {
  }

  async AddComment()
  {
    //SERVER
    if (!this.commentTemp)
      return;
    await this.reccurenceManager();
    this.kas.applyActions(this.card, this.list.id, this.board, "3", this.addCommentPostProcessing, this.cancelAction, this);
  }

  addCommentPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanCardEditComponent)
  {
    let comment = new KanbanComment();
    comment.date = new Date();
    comment.comment = comp.commentTemp;
    comment.user = comp.auth.connectedUser;

    comp.kbs.addCommentToCard(comp.card.id, comment).subscribe(async x =>
    {
      comment.id = x;
      comp.card.comments.splice(0, 0, comment);
      comp.commentTemp = "";
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
    });
  }

  EditComment(comment: KanbanComment)
  {
    let tempcomment = new KanbanComment();
    tempcomment.id = comment.id;
    tempcomment.comment = comment.comment;
    tempcomment.user = comment.user;
    tempcomment.date = comment.date;
    this.commentsInEdition.push(tempcomment);
  }

  changeTaskCompletion()
  {
    if (this.card.completed)
      this.kbs.setCardAsOngoing(this.card.id).subscribe(() =>
      {
        this.card.completed = false;
        this.card.endDate = null;
        this.kbs.updateCardDate.next(this.card)
        let noteindex = this.ns.notes.findIndex(x => x.id == this.card.id);
        if (noteindex >= 0)
          this.ns.notes[noteindex].completed = false;
      })
    else
    {
      if (this.list)
        this.kas.applyActions(this.card, this.list.id, this.board, "6", this.addCardCompletedPostProcessing, this.cancelAction, this);
      else 
      {
        this.kbs.setCardAsCompleted(this.card.id).subscribe(async () =>
        {
          this.card.completed = true;
          this.card.endDate = new Date();
          this.kbs.updateCardDate.next(this.card)
          let noteindex = this.ns.notes.findIndex(x => x.id == this.card.id);
          if (noteindex >= 0)
            this.ns.notes[noteindex].completed = true;
        })
      }
    }
  }

  addCardCompletedPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanCardEditComponent)
  {
    comp.kbs.setCardAsCompleted(comp.card.id).subscribe(async () =>
    {
      comp.card.completed = true;
      comp.card.endDate = new Date();
      comp.kbs.updateCardDate.next(comp.card)
      let noteindex = comp.ns.notes.findIndex(x => x.id == comp.card.id);
      if (noteindex >= 0)
        comp.ns.notes[noteindex].completed = true;
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
    })
  }

  showEditComment(comment: KanbanComment)
  {
    return this.commentsInEdition.find(x => x.id == comment.id) != null;
  }

  SaveEditComment(comment: KanbanComment)
  {
    this.kbs.updateComment(this.card.id, comment.id, comment.comment).subscribe(x =>
    {
      let index = this.commentsInEdition.findIndex(x => x.id == comment.id);
      this.commentsInEdition.splice(index, 1);
    })
  }

  CancelEditComment(comment: KanbanComment)
  {
    let com = this.commentsInEdition.find(x => x.id == comment.id);
    comment.comment = com.comment;
    let index = this.commentsInEdition.findIndex(x => x.id == comment.id);
    this.commentsInEdition.splice(index, 1);
  }

  deleteComment(comment: KanbanComment)
  {
    this.kbs.deleteComment(this.card.id, comment.id).subscribe(x =>
    {
      let index = this.card.comments.findIndex(x => x.id == comment.id);
      this.card.comments.splice(index, 1);
      this.card.comments = [...this.card.comments];
    });
  }

  cancelDeleteComment()
  {

  }

  userIsSelected(user: number)
  {
    return this.card.members.find(x => x == user) != null;
  }

  teamIsSelected(idteam: number)
  {
    return this.card.teams.find(x => x == idteam) != null;
  }

  getTeamsToAdd()
  {
    let upperFilter = this.filterDrawer.toUpperCase();
    if (this.board)
      return this.board.teams.filter(x => this.tcs.contains(this.nameuserpipe.transform(x), upperFilter));
    else
      return this.cds.unarchivedTeams.filter(x => this.tcs.contains((x.name), upperFilter)).map(x => x.id);
  }

  async changeUserSelection(user: number)
  {
    await this.reccurenceManager();
    this.kts.updateCardProcess(this.card, this.list, this.board, "5", user);
  }

  async changeTeamSelection(idteam: number)
  {
    await this.reccurenceManager();
    let index = this.card.teams.findIndex(x => x == idteam);
    if (index < 0)
    {
      this.kbs.addTeamToCard(this.card.id, idteam).subscribe(() =>
      {
        if (this.card.teams.findIndex(x => x == idteam) < 0)
          this.card.teams.push(idteam)
      })
    }
    else 
    {
      this.kbs.removeTeamFromCard(this.card.id, idteam).subscribe(() =>
      {
        this.card.teams.splice(index, 1);
      })
    }
  }



  createOrSaveLabel()
  {
    if (this.createdLabel.id == 0)
      this.createLabel();
    else this.saveLabel();
  }

  createLabel()
  {
    if (!this.createdLabel.title)
      return;
    let label = this.kbs.deepCopy(this.createdLabel);
    this.kbs.createLabel(label, this.board.id).subscribe(x =>
    {
      label.id = x;
      this.cds.labelList.push(label);
      this.cds.labelList = this.cds.labelList.sort((x, y) => (x.title > y.title) ? 1 : -1);
      this.createdLabel = new GlobalLabel();
      this.addLabel = false;
      this.changeLabelSelection(label)
    });
  }

  createPublicLabel()
  {
    if (!this.createdLabel.title)
      return;
    let label = this.kbs.deepCopy(this.createdLabel);
    this.kbs.createPublicLabel(label).subscribe(x =>
    {
      label.id = x;
      this.cds.publicLabelList.push(label);
      this.cds.publicLabelList = this.cds.publicLabelList.sort((x, y) => (x.title.toUpperCase() > (y.title.toUpperCase()) ? 1 : -1));
      this.createdLabel = new GlobalLabel();
      this.addLabel = false;
      this.changeLabelSelection(label)
    });
  }

  drop(event: CdkDragDrop<string[]>): void
  {
    moveItemInArray(this.card.checklists, event.previousIndex, event.currentIndex);
    setTimeout(() =>
    {
      this.kbs.moveChecklist(this.card.id, this.card.checklists.map(({ id }) => id)).subscribe();
    }, 300);
  }

  InitLabelPopup()
  {
    this.customColor = false
    if (this.kbs.colorList.indexOf(this.createdLabel.color) == -1)
    {
      this.customColor = true
    }
    this.editLabels();
    // setTimeout(() => {
    //   this.kbs.setFocusToElement("label-button-input-card");
    // }, 500);
  }

  saveLabel()
  {
    if (!this.createdLabel.title)
      return;
    this.kbs.updateLabel(this.createdLabel).subscribe(x =>
    {
      let label = this.cds.labelList.find(x => x.id == this.createdLabel.id);
      if (!label)
        label = this.cds.publicLabelList.find(x => x.id == this.createdLabel.id);
      label.title = this.createdLabel.title;
      label.color = this.createdLabel.color;
      let labelcard = this.card.labels.find(x => x.id == this.createdLabel.id);
      if (labelcard)
      {
        labelcard.title = this.createdLabel.title;
        labelcard.color = this.createdLabel.color;
      }

      this.addLabel = false;
      this.createdLabel = new GlobalLabel();
    });
  }

  async deleteLabel()
  {
    await this.reccurenceManager();
    this.kbs.deleteLabel(this.createdLabel.id).subscribe(x =>
    {
      let index = this.cds.labelList.findIndex(x => x.id == this.createdLabel.id);
      let indexPublic = this.cds.publicLabelList.findIndex(x => x.id == this.createdLabel.id);
      if (index != -1)
        this.cds.labelList.splice(index, 1);
      if (indexPublic != -1)
        this.cds.publicLabelList.splice(indexPublic, 1);
      this.addLabel = false;
      let indexCard = this.card.labels.findIndex(x => x.id == this.createdLabel.id);
      this.card.labels.splice(indexCard, 1);
      this.createdLabel = new GlobalLabel();
    });
  }

  setCreatedLabelColor(colorValue)
  {
    this.createdLabel.color = colorValue;
    this.customColor = false;
  }

  labeIsSelected(label: GlobalLabel)
  {
    return this.card.labels.find(x => x.title == label.title) != null;
  }

  async changeLabelSelection(label)
  {
    //await this.reccurenceManager();
    let labelInListIndex = this.card.labels.findIndex(x => x.id == label.id);
    if (labelInListIndex == -1)
    {
      this.kbs.addLabelToCard(this.card.id, label.id).subscribe(x =>
      {
        this.card.labels.push(label);
        this.card.labels = [...this.card.labels];
      });
    }
    else
    {
      this.kbs.removeLabelFromCard(this.card.id, label.id).subscribe(x =>
      {
        this.card.labels.splice(labelInListIndex, 1);
        this.card.labels = [...this.card.labels];
      });
    }
  }

  getContainerHeight(padding: number)
  {
    return (window.innerHeight - padding) * 0.8 + "px";
  }

  editLabel(label: GlobalLabel)
  {
    this.createdLabel.id = label.id;
    this.createdLabel.title = label.title;
    this.createdLabel.color = label.color;
    this.addLabel = true;
  }

  async createChecklist()
  {
    await this.reccurenceManager();
    let checklist = new KanbanChecklist();
    checklist.title = this.createdChecklistTitle;
    this.kbs.addChecklistToCard(this.card.id, checklist).subscribe(x =>
    {
      checklist.id = x;
      this.card.checklists.push(checklist);
      let displayinfos = new ChecklistDisplayInfos();
      displayinfos.showDateBegin = this.showBeginDate;
      displayinfos.showDateEnd = this.showEndDate;
      displayinfos.showDateCreation = this.showCreationDate;
      displayinfos.checklistId = x;
      this.kbs.checklistsDisplayInfos.push(displayinfos);
      this.kbs.updateCardChecklistDisplayInfos(this.card.id, displayinfos).subscribe();
      this.createdChecklistTitle = "";
      this.checkListCreatorVisible = false;
    });
  }

  async archiveCard()
  {
    await this.reccurenceManager();
    this.kbs.archiveCard(this.card.id).subscribe(() =>
    {
      this.card.archived = true;
      let board: KanbanBoard = null;
      let noteindex = this.ns.notes.findIndex(x => x.id == this.card.id);
      if (noteindex >= 0)
      {
        this.ns.notes.splice(noteindex, 1);
        this.ns.notes = [...this.ns.notes];
      }
      if (this.board)
        board = this.kbs.allBoardList.find(x => x.id == this.board.id)
      let task = this.tds.taskList.find(x => x.id == this.card.id);
      if (task)
      {
        task.archived = true;
        this.tds.taskListChange.next(null);
      } else if (board)
      {
        let list = board.lists.find(x => x.id == this.list.id)
        if (list)
        {
          let card = list.cards.findIndex(x => x.id == this.card.id)
          if (card >= 0)
            list.cards.splice(card, 1);
        }
      }
    });
  }

  restoreCard()
  {
    this.kbs.restoreCard(this.card.id).subscribe(x =>
    {
      this.card.archived = false;
      let board = this.kbs.allBoardList.find(x => x.id == this.board.id)
      let task = this.tds.taskList.find(x => x.id == this.card.id);
      if (task)
      {
        task.archived = false;
        this.tds.taskListChange.next(null);
      } else if (board)
      {
        let list = board.lists.find(x => x.id == this.list.id)
        if (list)
        {
          let card = list.cards.findIndex(x => x.id == this.card.id)
          if (card < 0)
            this.list.cards.push(this.card)
          this.kbs.reloadBoardArchivesSub.next(null);
        }
      }
    });
  }

  async deleteCard()
  {
    await this.reccurenceManager();
    this.kbs.deleteCard(this.card.id).subscribe(x =>
    {
      if (this.list)
      {
        let index = this.list.cards.findIndex(x => x.id == this.card.id);
        if (index >= 0)
          this.list.cards.splice(index, 1);
      }
      let noteindex = this.ns.notes.findIndex(x => x.id == this.card.id);
      if (noteindex >= 0)
      {
        this.ns.notes.splice(noteindex, 1);
        this.ns.notes = [...this.ns.notes];
        this.ns.reloadNoteUI.next(null);
      }
      if (this.kbs.modalEdit)
        this.kbs.modalEdit.close();
      //if(this.modal)
      //this.modal.close()
      this.kbs.reloadBoardArchivesSub.next(null);
    });
  }

  nbdollar = 0;
  showDescriptionVariableMenu = false;
  cursorPosition = -1;

  descriptionKeyup(evt: KeyboardEvent)
  {
    if (this.card.type != 3)
      return;

    if (evt.key == "$")
    {
      this.nbdollar++;
      if (this.nbdollar > 1)
      {
        this.cursorPosition = getInputCursorPosition("kanban-card-description");
        this.showDescriptionVariableMenu = true;
      }
    }
    else
    {
      this.nbdollar = 0;
      this.showDescriptionVariableMenu = false;
    }
  }

  closeVariableInput()
  {
    this.showDescriptionVariableMenu = false;
    this.nbdollar = 0;
    var elem = document.getElementById("kanban-card-description");
    elem.focus();
  }

  insertVariableIntoInput(codevariable: string)
  {
    this.showDescriptionVariableMenu = false;
    this.nbdollar = 0;
    this.descriptionTemp = this.descriptionTemp.slice(0, this.cursorPosition - 2) + codevariable + this.descriptionTemp.slice(this.cursorPosition);
    var elem = document.getElementById("kanban-card-description");
    elem.focus();
  }

  shareUrl()
  {
    let copiedTXT
    this.translate.get("KANBAN.EDIT-CARD.COPIED", {}).subscribe(x => copiedTXT = x);

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = window.location.href;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.messageService.create('info', copiedTXT);
    // TODO TRANSLATE
  }

  getUrl(url: string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showVisuIcon(url: string)
  {
    if (url.startsWith("data:image/") || url.startsWith("data:application/pdf"))
      return true;
    return false;
  }

  setVisualization(url: string)
  {
    if (this.kbs.visualizedUrl == url)
      this.kbs.visualizedUrl = "";
    else
    {
      this.kbs.visualizedUrl = "";
      setTimeout(() =>
      {
        this.kbs.visualizedUrl = url;
      }, 100);

    }
    if (url.startsWith("data:image/"))
      this.visuHeight = "";
    else if (url.startsWith("data:application/pdf"))
      this.visuHeight = "800px";
  }

  initUserNotification()
  {
    this.kbs.initCardMail(this.board ? this.board.id : 0, this.card.id);
    this.mss.editMail();
  }

  openCallInterface()
  {
    let title = "";
    this.translate.get("KANBAN.CALL-THIRD-PARTY.TITLE").subscribe(x => title = x);
    let confirm = "";
    this.translate.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: CallThirdPartyComponent,
      nzWidth: '80%',
      nzFooter: null
    });
    modal.componentInstance.card = this.card;
    return;
  }

  clickOnMenu_Checklist()
  {
    if (this.kbs.editMode == "normal")
      this.checklistDataLoaded = !this.checklistDataLoaded;
    else
    {
      this.kecs.showValue = 1;
    }
  }

  clickOnMenu_Planning()
  {
    this.kecs.showValue = 2;
    this.kbs.editMode = "wide";
  }

  clickOnMenu_Attachments()
  {
    if (this.kbs.editMode == "normal")
      this.showAttachments ? this.showAttachments = false : this.showAttachments = true
    else this.kecs.showValue = 3;
  }

  clickOnMenu_History()
  {
    if (this.kbs.editMode == "normal")
      this.showActivities = !this.showActivities
    else this.kecs.showValue = 4;
  }

  clickOnMenu_ThirdParty()
  {
    if (this.kbs.editMode == "normal")
      this.showThirdParty = !this.showThirdParty
    else this.kecs.showValue = 5;
  }

  clickOnMenu_Mails()
  {
    if (this.kbs.editMode == "normal")
      this.showMails = !this.showMails
    else this.kecs.showValue = 11;
  }

  clickOnMenu_Actions()
  {
    if (this.kbs.editMode == "normal")
      this.manageActions();
    else this.kecs.showValue = 6;
  }

  clickOnMenu_CopyMove(typeaction: string)
  {
    if (this.kbs.editMode == "normal")
    {
      if (typeaction == "copy")
        this.initCopyCard();
      else this.initMoveCard()
    }
    else
    {
      this.kecs.showValue = 0;
      this.kecs.typeMove = typeaction;
      setTimeout(() =>
      {
        this.kecs.showValue = 7;
      }, 500);
    }
  }

  clickOnMenu_SendMail(id: number, type: string)
  {
    this.kbs.initCardMail(this.board ? this.board.id : 0, this.card.id);
    if (type == "user")
    {
      let user = this.cds.userCollection.find(x => x.id == id);
      if (user)
        this.mss.emailToInit.to.push(user.mail);
    }
    this.mss.editMail();
  }

  clickOnMenu_Call()
  {
    if (this.kbs.editMode == "normal")
      this.openCallInterface();
    else this.kecs.showValue = 9;
  }

  clickOnMenu_Comment()
  {
    if (this.kbs.editMode == "normal")
      this.addComment ? this.addComment = false : this.addComment = true
    else this.kecs.showValue = 10;
  }

  userHasPresence(iduser: number)
  {
    return this.card.membersPresence.findIndex(x => x.userId == iduser) >= 0
  }

  getUserPresence(iduser: number)
  {
    return this.card.membersPresence.find(x => x.userId == iduser).state;
  }

  teamHasPresence(idteam: number)
  {
    return this.card.teamsPresence.findIndex(x => x.teamId == idteam) >= 0
  }

  getTeamPresence(idteam: number)
  {
    return this.card.teamsPresence.find(x => x.teamId == idteam).state;
  }

  async updateUserPresence(iduser: number, state: number)
  {
    let infos = this.card.membersPresence.find(x => x.userId == iduser);
    if (!infos)
    {
      infos = new PresenceInfos();
      infos.userId = iduser;
      infos.cardId = this.card.id;
      this.card.membersPresence.push(infos);
    }
    infos.state = state;
    await firstValueFrom(this.kbs.updatePresence(infos));
    this.showPresenceSelector = false;
  }

  getUserSelectedStateColor(iduser: number)
  {
    let infos = this.card.membersPresence.find(x => x.userId == iduser);
    if (!infos)
      return "black";
    let state = this.cds.presenceOptions.find(x => x.val == infos.state);
    if (state)
      return state.color;
    return "black";
  }

  async updateTeamPresence(iduser: number, state: number)
  {
    let infos = this.card.teamsPresence.find(x => x.teamId == iduser);
    if (!infos)
    {
      infos = new PresenceInfos();
      infos.teamId = iduser;
      infos.cardId = this.card.id;
      this.card.teamsPresence.push(infos);
    }
    infos.state = state;
    await firstValueFrom(this.kbs.updatePresence(infos));
    this.showPresenceSelector = false;

  }

  getTeamSelectedStateColor(iduser: number)
  {
    let infos = this.card.teamsPresence.find(x => x.teamId == iduser);
    if (!infos)
      return "black";
    let state = this.cds.presenceOptions.find(x => x.val == infos.state);
    if (state)
      return state.color;
    return "black";
  }

}
