import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit
{


  public patterns = {
    // Copy of the original package's list of patterns - ngx-mask.umd.js ^7.9.9
    '0': {
      pattern: new RegExp('\\d'),
    },
    '9': {
      pattern: new RegExp('\\d'),
      optional: true,
    },
    X: {
      pattern: new RegExp('\\d'),
      symbol: '*',
    },
    A: {
      pattern: new RegExp('[a-zA-Z0-9]'),
    },
    S: {
      pattern: new RegExp('[a-zA-Z]'),
    },
    d: {
      pattern: new RegExp('\\d'),
    },
    m: {
      pattern: new RegExp('\\d'),
    },
    M: {
      pattern: new RegExp('\\d'),
    },
    H: {
      pattern: new RegExp('\\d'),
    },
    h: {
      pattern: new RegExp('\\d'),
    },
    s: {
      pattern: new RegExp('\\d'),
    },
    // New patterns added to the original package's list of patterns
    'x': {
      pattern: new RegExp('[a-fA-F0-9]')
    }
  }

  @Input('LABEL') public LABEL: string;
  @Input('VARIABLE') public VARIABLE: string;
  @Input('REQUIRED') public REQUIRED: boolean;
  @Input('SECURED') public SECURED: boolean = false;
  @Input('MASK') public MASK: string = null;
  @Input('MASK_DSC') public MASK_DSC: boolean = true;
  @Input('VALIDATE_BTN') public VALIDATE_BTN: string = null;
  @Input('DISABLED') public DISABLED: boolean = false;
  @Input('PLACEHOLDER') public PLACEHOLDER: string = "";
  @Input('HELP_TXT') public HELP_TXT: string;
  @Input('VALIDATE_TXT') public VALIDATE_TXT: string;
  @Output() VARIABLEChange = new EventEmitter<string>();
  @Output() VALIDATE_FUNC = new EventEmitter();
  @Output() BTN_ACTION = new EventEmitter();

  changedNgModel()
  {
    this.VARIABLEChange.emit(this.VARIABLE)
    this.validateFunctionCallback()
  }

  doActionButton()
  {
    this.BTN_ACTION.emit();
  }

  validateFunctionCallback()
  {
    this.VALIDATE_FUNC.emit();
  }

  constructor() { }

  ngOnInit()
  {
  }

}
