import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardHistoryComponent } from './kanban-card-history.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TranslateModule } from '@ngx-translate/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    TranslateModule,
    NzAvatarModule,
    PipesModule,
    NzIconModule
  ],
  declarations: [KanbanCardHistoryComponent],
  exports: [KanbanCardHistoryComponent]
})
export class KanbanCardHistoryModule { }
