import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialTeamPipe } from './initial-team.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InitialTeamPipe],
  exports: [InitialTeamPipe]
})
export class InitialTeamPipeModule { }
