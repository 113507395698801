import { CustomField } from "../../entities/custom-field";
import { PhoneNumber } from "../../entities/phone-number";
import { Site } from "../../entities/site";
import { KanbanAttachment } from "src/app/kanban/entities/Attachment";
import { KanbanCard } from "src/app/kanban/entities/Card";
import { FilterRelation } from "../../entities/filter-relation";
import { Mail } from "../../entities/mail";
import { ThirdPartyActivity } from "../../entities/third-party-activity";
import { GlobalLabel } from "src/app/kanban/entities/Label";
import { ThirdPartyCustomSystemField } from "src/app/call-interface/entities/third-party-custom-system-field";

export class MoralPerson
{
    id: number;
    name: string;
    mainCurrency: string;
    codeTVA: string;
    codeAPE: string;
    anniversaryDate: Date;
    siret: string;
    siren: string;
    comment: string;
    nationality: string;
    archived: boolean;
    initials: string = "";
    image: string;
    sign: string = "";
    parentSiteId: number;
    nbAttachments: number;
    creatorId: number;
    creationDate: Date;
    activities: ThirdPartyActivity[] = []

    websites: string[] = [];
    secondaryNames: string[] = [];
    brands: string[] = [];
    mails: Mail[] = [];
    tags: string[] = [];

    labels: GlobalLabel[] = [];
    customFields: CustomField[] = [];
    customSystemFields: ThirdPartyCustomSystemField[] = [];
    phoneNumbers: PhoneNumber[] = [];
    sites: Site[] = [];
    attachments: KanbanAttachment[] = [];
    cards: KanbanCard[] = [];
    filteredRelations: FilterRelation[] = [];
    secondarySites: MoralPerson[] = [];
    expand = false;
}