import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeComponent } from 'ng-zorro-antd/tree';
import { firstValueFrom } from 'rxjs';
import { GroupUser } from '../group-user/entities/group-user';
import { KanbanAttachment } from '../kanban/entities/Attachment';
import { KanbanService } from '../kanban/services/kanban.service';
import { NameUserPipe } from '../pipes/name-user-pipe/name-user.pipe';
import { EventService } from '../SDICalendar/calendar/services/event.service';
import { AuthService } from '../services/auth.service';
import { CacheDataService } from '../services/cache-data.service';
import { TextCompareService } from '../services/text-compare.service';
import { UrlApiService } from '../services/url-api.service';
import { SettingsService } from '../settings/services/settings.service';
import { User } from '../user/model/user';
import { PlanningFilterParameters } from './entities/planning-filter-parameters';
import { PlanningSharingParameters } from './entities/planning-sharing-parameters';
import { PlanningService } from './planning.service';
import { OtherPlanningRights, PlanningRights } from './entities/other-planning-rights';
import { EventsPerUser } from './entities/events-per-user';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit
{

  constructor(public planningService: PlanningService, public cds: CacheDataService, private translateService: TranslateService,
    public auth: AuthService,
    private settingsService: SettingsService,
    public tcs: TextCompareService,
    private nameuserpipe: NameUserPipe,
    private http: HttpClient,
    private message: NzMessageService,
    private drawerService: NzDrawerService,
    public eventService: EventService,
    private kbs: KanbanService) { }

  @Input() readonly = false;
  @Input() date: Date = new Date();

  @ViewChild('nzTreeComponent', { static: false }) nzTreeComponent!: NzTreeComponent;
  filterDrawer = "";
  categoriesFilterOpened = false;
  freecardIsFiltered = false;
  userList: OtherPlanningRights[] = [];
  teamList: OtherPlanningRights[] = [];
  planningAccessVisible = false;

  planningAccess: PlanningSharingParameters = new PlanningSharingParameters();
  rightsList = [PlanningRights.None, PlanningRights.CreateAppointment, PlanningRights.UpdateAppointment, PlanningRights.DeleteAppointment];
  openedRightIdUser = 0;
  openedRightIdTeam = 0;

  async ngOnInit()
  {
    this.searchUsers("");
    this.searchTeams("");
    this.planningService.readonly = this.readonly;
    if (window.location.href.endsWith("planning"))
      this.auth.setCamelineTitle("planning");

    try
    {
      this.planningService.filters = await firstValueFrom(this.planningService.getFilterPreferences(-1))
      this.planningService.filters.idUser = -1;
    }
    catch (e) { }


    this.planningService.allowedPlannings = await firstValueFrom(this.planningService.getAllowedPlanning());
    this.planningService.allowedPlannings = this.planningService.allowedPlannings.sort((a, b) => this.nameuserpipe.transform(a.id).toUpperCase() < this.nameuserpipe.transform(b.id).toUpperCase() ? -1 : 1)

    this.planningService.initResources();
    this.initEventPerUser();


    this.planningService.getPlanningAccess().subscribe(accessParam =>
    {
      this.planningAccess = accessParam;
      this.userList = [...accessParam.userIds];
      this.teamList = [...accessParam.teamIds];
    })

    this.planningService.eventsAreLoaded = false;

    this.reloadCalendarAll();

  }

  initEventPerUser()
  {
    this.planningService.eventPerUser = [];

    if (this.planningService.filters.showCombinedAgenda)
    {
      let self = new EventsPerUser();
      self.iduser = 0;
      self.rights = this.planningService.allRight();
      this.planningService.eventPerUser.push(self);
    }

    if (this.planningService.filters.showOwnAgenda)
    {
      let self = new EventsPerUser();
      self.iduser = this.auth.connectedUser.id;
      self.rights = this.planningService.allRight();
      this.planningService.eventPerUser.push(self);
    }

    for (let userright of this.planningService.filters.othersPlanning)
    {
      if (this.planningService.eventPerUser.findIndex(x => x.iduser == userright.id) >= 0)
        continue;
      let evtuser = new EventsPerUser();
      evtuser.iduser = userright.id;
      evtuser.rights = this.planningService.allowedPlannings.find(x => x.id == userright.id)?.rights;
      this.planningService.eventPerUser.push(evtuser);
    }

    if (this.planningService.eventPerUser.length == 1 || this.isOdd(this.planningService.eventPerUser.length))
    {
      this.planningService.eventPerUser[this.planningService.eventPerUser.length - 1].size = "100%";
    }
  }

  updateVisibleCalendar()
  {
    this.planningService.updateFilterPreferences(this.planningService.filters).subscribe();
    this.initEventPerUser()
    this.reloadCalendarAll();
  }

  reloadCalendarAll()
  {
    for (let cal of this.planningService.eventPerUser)
    {
      this.reloadCalendar(cal);
    }
  }

  reloadCalendar(calinfos: EventsPerUser)
  {
    calinfos.visible = false;
    setTimeout(() =>
    {
      calinfos.visible = true;
    }, 100);
  }

  isChecked(iduser: number)
  {
    return this.planningService.filters.othersPlanning.findIndex(x => x.id == iduser) >= 0;
  }

  url = UrlApiService.settings.apiConfig.uriAPI + '/api/user/donothing';
  addAttachment(info)
  {
    if (info.file.status === 'uploading')
    {
      return;
    }
    if (info.file.status === 'done')
    {
      if (info.file.size > 10000000)
      {
        this.createBasicNotification();
        return;
      }
      // Get this url from response in real world.


      let formData: FormData = new FormData();
      formData.append("IcalFile", info.file.originFileObj, info.file.name);
      this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/import-ical', formData).subscribe(() =>
      {
        this.planningService.refreshCalendars.next(null);
      })
    }
  }

  exportIcalFile()
  {
    // let listIds = this.eventService._eventList.map(x => x.id);
    // if (listIds.length > 0)
    // {
    //   this.http.post<KanbanAttachment>(UrlApiService.settings.apiConfig.uriAPI + '/api/planning/export-ical', listIds).subscribe(att =>
    //   {
    //     var link = document.createElement('a');
    //     link.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(att.url))
    //     link.setAttribute("download", att.name)
    //     link.click();
    //   })
    // }
  }

  createBasicNotification(): void
  {
    let errorMessage;
    this.translateService.get("USER.ERROR.INCORRECT-AVATAR-SIZE").subscribe(x => errorMessage = x)
    this.message
      .create("error",
        errorMessage
      )
  }

  updateOtherPlanningFilter(iduser: number, state: boolean)
  {
    let index = this.planningService.filters.othersPlanning.findIndex(x => x.id == iduser);
    if (!state)
      this.planningService.filters.othersPlanning = this.planningService.filters.othersPlanning.filter(x => x.id != iduser);
    else if (index < 0)
    {
      let filter = new OtherPlanningRights();
      filter.id = iduser;
      this.planningService.filters.othersPlanning.push(filter);
    }

    this.updateVisibleCalendar()
  }

  cancelPlanningAccess()
  {
    this.planningAccessVisible = false;
    this.userList = [...this.planningAccess.userIds];
    this.teamList = [...this.planningAccess.teamIds];
  }

  savePlanningAccess()
  {
    let access = new PlanningSharingParameters();
    access.userIds = this.userList;
    access.teamIds = this.teamList;
    this.planningService.updatePlanningAccess(access).subscribe(() =>
    {
      //this.planningAccessVisible = false;
      this.planningAccess.userIds = [...this.userList];
      this.planningAccess.teamIds = [...this.teamList];
    });
  }

  idusershared = null;
  addUserToShared(iduser: number)
  {
    let planningsharing = new OtherPlanningRights();
    planningsharing.id = iduser;
    this.userList.push(planningsharing);
    this.idusershared = null;
    this.savePlanningAccess();
  }

  removeUserFromShared(iduser: number)
  {
    let index = this.userList.findIndex(x => x.id == iduser);
    if (index >= 0)
      this.userList.splice(index, 1);
    this.savePlanningAccess();
  }

  idteamshared = null;
  addTeamToShared(idteam: number)
  {
    let planningsharing = new OtherPlanningRights();
    planningsharing.id = idteam;
    this.teamList.push(planningsharing);
    this.idteamshared = null;
    this.savePlanningAccess();

  }

  removeTeamFromShared(idteam: number)
  {
    let index = this.teamList.findIndex(x => x.id == idteam);
    if (index >= 0)
      this.teamList.splice(index, 1);
    this.savePlanningAccess();
  }



  usersForTemplateCreation: User[] = [];
  teamsForTemplateCreation: GroupUser[] = [];

  searchUsers(search: string)
  {
    this.usersForTemplateCreation = this.cds.unarchivedUsers.filter(x => this.tcs.contains(this.nameuserpipe.transform(x.id), search));
  }

  searchTeams(search: string)
  {
    this.teamsForTemplateCreation = this.cds.unarchivedTeams.filter(x => this.tcs.contains(x.name, search));
  }

  updateRightUser(iduser: number, val: PlanningRights, state: boolean)
  {
    let right = this.userList.find(x => x.id == iduser);
    if (!right)
    {
      right = new OtherPlanningRights();
      right.id = iduser;
      this.userList.push(right);
    }

    if (val == PlanningRights.None)
    {
      right.rights = val;
      if (!state)
      {
        return;
      }

    }

    if (state)
      right.rights |= val;
    else right.rights &= ~val;
    this.savePlanningAccess();
  }

  checkIfUserHasRight(iduser: number, val: PlanningRights)
  {
    let right = this.userList.find(x => x.id == iduser);
    if (!right || right.rights == PlanningRights.None)
      return false;
    else if (right.rights == this.planningService.allRight())
      return true;

    if (val == right.rights)
      return true;

    return (right.rights & val) != 0;
  }

  updateRightTeam(idteam: number, val: PlanningRights, state: boolean)
  {
    let right = this.teamList.find(x => x.id == idteam);
    if (!right)
    {
      right = new OtherPlanningRights();
      right.id = idteam;
      this.teamList.push(right);
    }

    if (state)
      right.rights |= val;
    else right.rights &= ~val;
    this.savePlanningAccess();
  }

  checkIfTeamHasRight(idteam: number, val: PlanningRights)
  {
    let right = this.teamList.find(x => x.id == idteam);
    if (!right)
      return false;
    return (right.rights & val) != 0;
  }

  isOdd(num) { return num % 2; }

  tempsub;
  ngOnDestroy()
  {
    if (this.tempsub)
      this.tempsub.unsubscribe();
  }

}
