import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../services/url-api.service';
import { ThirdPartyPotentialRelation } from '../entities/potential-relations';
import { tap } from 'rxjs/operators';
import { SetRelation } from '../entities/set-relation';
import { KanbanAttachment } from '../../kanban/entities/Attachment';
import { CredentialsForThirdPartyEdit } from '../entities/credentials-for-third-party';
import { CredentialsEdit } from '../entities/credentials-edit';
import { PhysicalPerson } from '../physical-person/entities/physical-person';
import { MoralPerson } from '../moral-person/entities/moral-person';
import { FilterRelation } from '../entities/filter-relation';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ThirdPartyActivity } from '../entities/third-party-activity';
import { CacheDataService } from '../../services/cache-data.service';
import { ThirdPartyByPhoneNumberDTO } from '../entities/third-party-by-phone-number';
import { SearchThirdParty } from '../../call-interface/entities/search-third-party';
import { Site } from '../entities/site';
import { TextCompareService } from '../../services/text-compare.service';
import { GlobalLabel } from '../../kanban/entities/Label';
import { GlobalLabelService } from '../../global-label/global-label.service';
import { ModuleLabel } from '../../global-label/entities/modules-label';
import { KanbanCard } from '../../kanban/entities/Card';
import { PhoneNumber } from '../entities/phone-number';
import { RelationMemo } from '../entities/relation-memo';
import { SearchRelation } from '../entities/search-relation';
import { GetRelation } from '../entities/get-relation';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService
{

  potentialRelationsInfos: ThirdPartyPotentialRelation = new ThirdPartyPotentialRelation();
  potentialRelationsInfosChange: Subject<ThirdPartyPotentialRelation> = new Subject<ThirdPartyPotentialRelation>();
  relationsInfosChange: Subject<ThirdPartyPotentialRelation> = new Subject<ThirdPartyPotentialRelation>();
  infoTPsByPhoneNumber: ThirdPartyByPhoneNumberDTO = new ThirdPartyByPhoneNumberDTO();
  editedPhysicalPerson: PhysicalPerson;
  editedMoralPerson: MoralPerson;
  labelCollection: GlobalLabel[] = [];
  labelCollectionChange: Subject<GlobalLabel[]> = new Subject<GlobalLabel[]>();
  globalFilterCollection: FilterRelation[] = [];
  modal: NzModalRef;
  constructor(private http: HttpClient,
    private gls: GlobalLabelService,
    public tcs: TextCompareService, private translateService: TranslateService, public cds: CacheDataService) { }

  filterPhysicalPersons(ppList: PhysicalPerson[], filterText: string)
  {
    let filteredData = [];
    let upperfilter = filterText.toUpperCase();

    filteredData = ppList.filter(x => this.tcs.contains(`${x.firstName} ${x.lastName} ${x.lastName2}`, upperfilter)
      || this.tcs.contains(this.getFlagHover(x.nationality), upperfilter)
      || (x.comment && this.tcs.contains(x.comment, upperfilter))
      || (x.siren && this.tcs.contains(x.siren, upperfilter))
      || x.phoneNumbers.findIndex(y => this.tcs.contains(y.number, upperfilter)) >= 0
      || x.websites.findIndex(y => this.tcs.contains(y, upperfilter)) >= 0
      || x.mails.findIndex(y => this.tcs.contains(y.mail, upperfilter)) >= 0
      || x.brands.findIndex(y => this.tcs.contains(y, upperfilter)) >= 0
      || x.labels.findIndex(y => this.tcs.contains(y.title, upperfilter)) >= 0
      || x.secondaryNames.findIndex(y => this.tcs.contains(y, upperfilter)) >= 0
      || (x.siret && this.tcs.contains(x.siret, upperfilter)));
    filteredData = filteredData.filter(x => !x.archived).sort((a, b) => (a.firstName.toUpperCase() + a.lastName.toUpperCase()) > (b.firstName.toUpperCase() + b.lastName.toUpperCase()) ? 1 : -1);
    return filteredData;
  }

  filterMoralPerson(mpList: MoralPerson[], filterText: string)
  {
    let filteredData = [];
    let upperfilter = filterText.toUpperCase();
    filteredData = mpList.filter(x =>
      this.tcs.contains(x.name, upperfilter)
      || this.tcs.contains(this.getFlagHover(x.nationality), upperfilter)
      || this.tcs.contains(x.comment, upperfilter)
      || this.tcs.contains(x.codeTVA, upperfilter)
      || this.tcs.contains(x.codeAPE, upperfilter)
      || (x.sign && this.tcs.contains(x.sign, upperfilter))
      || (x.initials && this.tcs.contains(x.initials, upperfilter))
      || x.phoneNumbers.findIndex(y => this.tcs.contains(y.number, upperfilter)) >= 0
      || x.websites.findIndex(y => this.tcs.contains(y, upperfilter)) >= 0
      || x.mails.findIndex(y => this.tcs.contains(y.mail, upperfilter)) >= 0
      || x.brands.findIndex(y => this.tcs.contains(y, upperfilter)) >= 0
      || x.secondaryNames.findIndex(y => this.tcs.contains(y, upperfilter)) >= 0
      || this.tcs.contains(x.mainCurrency, upperfilter)
      || x.labels.findIndex(y => this.tcs.contains(y.title, upperfilter)) >= 0
      || this.tcs.contains(x.siren, upperfilter)
      || this.tcs.contains(x.siret, upperfilter)
    )
    filteredData = filteredData.filter(x => !x.archived).sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
    return filteredData;
  }

  getFlagHover(langcode: string)
  {
    if (!langcode)
      return "";
    let text = "";
    this.translateService.get("LANGUAGES." + langcode.toUpperCase()).subscribe(x => text = x);
    return text;
  }

  initMailFilteredOption()
  {
    let filteredOptions: string[] = [];
    this.translateService.get("THIRD-PARTY.MAIL.PERSONAL").subscribe(x => filteredOptions.push(x));
    this.translateService.get("THIRD-PARTY.MAIL.PROFESSIONAL").subscribe(x => filteredOptions.push(x));
    return filteredOptions;
  }

  initPhoneFilteredOptions()
  {
    let options = [];
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.MOBILE").subscribe(x => options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.HOME").subscribe(x => options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.WORK").subscribe(x => options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.MAIN").subscribe(x => options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.FAX-WORK").subscribe(x => options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.FAX-HOME").subscribe(x => options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.BEEPER").subscribe(x => options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.OTHER").subscribe(x => options.push(x));
    return options;
  }

  getThirdPartiesByPhoneNumber(phoneNumber: string)
  {
    return this.http.get<ThirdPartyByPhoneNumberDTO>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/phone-number/${phoneNumber}`).pipe(tap(x => this.infoTPsByPhoneNumber = x));
  }

  getAllGlobalFilters()
  {
    return this.http.get<FilterRelation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/global-filter`).pipe(tap(x => this.globalFilterCollection = x));
  }

  getPotentialsRelationsForThirdParty(idthirdparty: number)
  {
    return this.http.get<ThirdPartyPotentialRelation>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/potential-relations`).pipe(tap(x => 
    {
      this.potentialRelationsInfos = x;
      this.potentialRelationsInfos.relationList.forEach(r =>
      {
        let ppsource = this.cds.physicalPersonCollection.find(p => p.id == r.idSource);
        if (ppsource)
          r.titleSource = ppsource.firstName + " " + ppsource.lastName;
        else 
        {
          let mpsource = this.cds.moralPersonCollection.find(m => m.id == r.idSource);
          if (mpsource)
            r.titleSource = mpsource.name;
        }
        let pptarget = this.cds.physicalPersonCollection.find(p => p.id == r.idTarget);
        if (pptarget)
          r.titleTarget = pptarget.firstName + " " + pptarget.lastName;
        else 
        {
          let mptarget = this.cds.moralPersonCollection.find(m => m.id == r.idTarget);
          if (mptarget)
            r.titleTarget = mptarget.name;
        }
      })
      this.potentialRelationsInfos.physicalPersonList = this.cds.physicalPersonCollection.filter(t => !t.archived);
      this.potentialRelationsInfos.moralPersonList = this.cds.moralPersonCollection.filter(t => !t.archived);
    }));
  }

  addFilteredRelationTab(idthirdparty: number, filter: FilterRelation)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/filtered-relation`, filter);
  }

  removeFilteredRelationTab(idthirdparty: number, filter: FilterRelation)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/filtered-relation`, filter);
  }

  sendWhatsappMessage(pn: PhoneNumber)
  {
    return this.http.post<boolean>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/whatsapp/send-message`, pn);
  }

  searchTpRelations(filter: SearchRelation)
  {
    return this.http.post<ThirdPartyPotentialRelation>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/search`, filter).pipe(tap(x => this.potentialRelationsInfos = x));
  }

  addSystemFilteredRelationTab(filter: FilterRelation)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/filter`, filter);
  }

  removeSystemFilteredRelationTab(idfilter: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/filter/${idfilter}`);
  }

  updateFilteredRelationTab(idfilter: number, filter: FilterRelation)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/filter/${idfilter}`, filter);
  }

  setFilteredRelationTabAsGlobal(idfilter: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/filtered-relation/${idfilter}/global`, null);
  }

  setFilteredRelationTabAsSystem(idfilter: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/filtered-relation/${idfilter}/system`, null);
  }

  getPotentialsRelationsForAllThirdParty()
  {
    return this.http.get<ThirdPartyPotentialRelation>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/potential-relations`).pipe(tap(x =>
    {
      this.potentialRelationsInfos = x
      this.potentialRelationsInfos.physicalPersonList = this.cds.physicalPersonCollection.filter(t => !t.archived);
      this.potentialRelationsInfos.moralPersonList = this.cds.moralPersonCollection.filter(t => !t.archived);
    }));
  }

  createRelation(setRelation: SetRelation)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation`, setRelation);
  }

  updateRelation(setRelation: SetRelation)
  {
    return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/${setRelation.idRelation}`, setRelation);
  }

  deleteRelation(idRelation: number)
  {
    return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/${idRelation}`);
  }

  getThirdPartyRelationAttachments(idrelation: number)
  {
    return this.http.get<KanbanAttachment[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/${idrelation}/attachment`);
  }

  getMemoFromThirdPartyRelation(idrelation: number)
  {
    return this.http.get<RelationMemo[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/${idrelation}/memo`);
  }

  addMemoToThirdPartyRelation(idrelation: number, memo: RelationMemo)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/${idrelation}/memo`, memo);
  }

  updateMemo(memo: RelationMemo)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/memo`, memo);
  }

  removeMemoFromThirdPartyRelation(idmemo: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/memo/${idmemo}`);
  }

  addAttachmentToThirdPartyRelation(idrelation: number, attachment: KanbanAttachment)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/${idrelation}/attachment`, attachment);
  }

  removeAttachmentFromThirdPartyRelation(idrelation: number, idfile: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/relation/${idrelation}/attachment/${idfile}`);
  }

  getCredentialsForThirdParty(idthirdparty: number)
  {
    return this.http.get<CredentialsForThirdPartyEdit>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/credentials`);
  }

  updateThirdPartyCredential(idthirdparty: number, listcred: CredentialsEdit[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/credentials`, listcred);
  }

  associateThirdPartyAndCredential(idthirdparty: number, idcredential: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/credentials/${idcredential}`, null);
  }

  dissociateThirdPartyAndCredential(idthirdparty: number, idcredential: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/credentials/${idcredential}`);
  }

  editedThirdPartyActivities: ThirdPartyActivity[] = [];
  getThirdPartyActivities(idthirdparty: number)
  {
    return this.http.get<ThirdPartyActivity[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/activities`).pipe(tap(x => this.editedThirdPartyActivities = x));
  }

  getThirdPartySites(idthirdparty: number)
  {
    return this.http.get<Site[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/sites`);
  }

  searchThirdParty(searchText: string, idType: number)
  {
    return this.http.get<SearchThirdParty>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/search/${searchText}/type/${idType}`);
  }

  getNotificationText(activity: ThirdPartyActivity)
  {
    let user = this.cds.userCollection.find(x => x.id == activity.userId);
    let text = user.name + " " + user.surname + " ";
    switch (activity.parameters.length)
    {
      case 0:
        this.translateService.get(activity.codeText).subscribe(x => text += x);
        break;
      case 1:
        this.translateService.get(activity.codeText, { v: activity.parameters[0] }).subscribe(x => text += x);
        break;
      case 2:
        this.translateService.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1] }).subscribe(x => text += x);
        break;
      case 3:
        this.translateService.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1], v3: activity.parameters[2] }).subscribe(x => text += x);
        break;
    }

    return text;
  }

  getAllArchivedCardsFromAThirdParty(idthirdparty: number)
  {
    return this.http.get<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/${idthirdparty}/all-archived-card`);
  }

  getAllLabels()
  {
    return this.http.get<GlobalLabel[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/label`).pipe(tap(x => this.labelCollection = x.filter(y => !y.boardId && ((y.modules & ModuleLabel.ThirdParty) != 0 || y.modules == 0))));
  }

  createLabel(label: GlobalLabel)
  {
    label.modules = ModuleLabel.ThirdParty;
    return this.gls.createLabel(label);
  }

  updateLabel(label: GlobalLabel)
  {
    return this.gls.updateLabel(label);
  }

  deleteLabel(idlabel: number)
  {
    return this.gls.deleteLabel(idlabel, true);
  }

  addLabelToThirdParty(idthirdparty: number, idlabel: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/label/${idlabel}/third-party/${idthirdparty}`, null);
  }

  removeLabelFromThirdParty(idthirdparty: number, idlabel: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/label/${idlabel}/third-party/${idthirdparty}`);
  }
}
