import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartySheetEditIdentityComponent } from './third-party-sheet-edit-identity.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SearchClientInfoModule } from '../../../../src/app/third-party/moral-person/components/search-client-info/search-client-info.module';
import { NameUserPipeModule } from '../../../../src/app/pipes/name-user-pipe/name-user-pipe.module';
import { AvatarUserModule } from '../../../../src/app/pipes/avatar-user/avatar-user.module';
import { PipesModule } from '../../../../src/app/pipes/pipes/pipes.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NzFormModule,
    NzSelectModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzInputModule,
    NzButtonModule,
    NzToolTipModule,
    SearchClientInfoModule,
    NzAvatarModule,
    NameUserPipeModule,
    AvatarUserModule,
    NzIconModule,
    PipesModule
  ],
  declarations: [ThirdPartySheetEditIdentityComponent]
})
export class ThirdPartySheetEditIdentityModule { }
