import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PlanningComponent } from './planning.component';

const routes: Routes = [
  {
    path: '',
    component: PlanningComponent
  }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class PlanningRoutingModule { }
