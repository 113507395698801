import { ChangeDetectorRef, Injectable } from '@angular/core';
import { CredentialsSourceService } from '../source/credentials-source.service';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { CredentialsWithRights } from '../../entities/credentials-with-rights';
import { tap, catchError } from 'rxjs/operators';
import { InfoFilterCredentials } from '../../entities/info-filter-credentials';
import { Credentials } from '../../entities/credentials';
import { GroupCredentials } from '../../../group-credentials/entities/group-credentials';
import { CredentialsSharedGroupsDTO } from '../../entities/credentials-shared-groups';
import { CredentialsDTOWithRights } from '../../entities/credentials-dto-with-rights';
import { Subject, Observable } from 'rxjs';
import { CredentialWithGroupDTO } from '../../entities/credential-with-groups';
import { HttpClient } from '@angular/common/http';
import { KanbanAttachment } from '../../../kanban/entities/Attachment';
import { UrlApiService } from '../../../services/url-api.service';
import { ThirdPartyForCredentialEdit } from '../../entities/third-party-credentials-edit';
import { ThirdPartyForCredential } from '../../entities/third-party-for-credentials';
import { AuthService } from '../../../services/auth.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { SearchCredentials } from '../../entities/search-credentials';
import { FilterValues } from '../../entities/filter-values';
import { VirtualDataInterface } from '../../../entities/virtual-data-interface';



@Injectable({
  providedIn: 'root'
})
export class CredentialsDataService
{
  listCredentialsWithRights: CredentialsDTOWithRights[] = [];
  isLoading = false;
  listCredentialsWithRightsChange: Subject<CredentialsDTOWithRights[]> = new Subject<CredentialsDTOWithRights[]>();
  listGroupCredentials: GroupCredentials[];
  infoFilterCredentials: InfoFilterCredentials;
  groupsOfCredentials: CredentialsSharedGroupsDTO[] = [];
  modal: NzModalRef;
  canCreateCred: boolean;
  listUsedCredentialsName: string[] = [];
  ownerList: any[] = [];
  creatorList: any[] = [];
  thirdpartyList: any[] = [];
  groupCredentialList: any[] = [];
  adminMode = false;
  searchParams: SearchCredentials = new SearchCredentials();
  filterPrivate = 0;
  dataLoaded = false;
  listOfData: VirtualDataInterface[] = [];

  constructor(public credentialsSource: CredentialsSourceService, private errorHandler: ErrorHandlerService,
    private http: HttpClient, private authService: AuthService) { }

  checkCredentialsName(name: string)
  {
    let upper = name.toUpperCase();
    if (this.listCredentialsWithRights.find(x => x.credentials.enabled && x.credentials.name.toUpperCase() == upper) != null)
      return false;
    return true;
  }

  checkCredentialsNameForUpdate(cred: Credentials)
  {
    let upper = cred.name.toUpperCase();
    if (this.listCredentialsWithRights.find(x => x.credentials.enabled && x.credentials.name.toUpperCase() == upper && x.credentials.id != cred.id) != null)
      return false;
    return true;
  }

  isFinish = false;
  onScrollDown()
  {

  }

  resetParam()
  {
    this.isaskingserver = false;
    this.listOfData = [];
    this.isFinish = false;
    this.searchParams.start = 0;
    //this.searchParams = new SearchCredentials();
  }


  temp = null;
  isaskingserver = false;
  resetTable(scrolling?: boolean)
  {
    this.isFinish = false;
    if (this.isaskingserver)
      return;
    if (this.isFinish && scrolling)
      return;
    if (!scrolling)
    {
      this.searchParams.start = 0;
    }

    if (this.temp)
      clearTimeout(this.temp);

    this.temp = setTimeout(() =>
    {
      this.isaskingserver = true;
      console.log(this.searchParams);

      this.searchCredentials(this.adminMode, this.searchParams).subscribe(creds =>
      {
        if (creds.listCredentials.length < this.searchParams.count)
          this.isFinish = true;
        else this.isFinish = false;
        if (scrolling)
        {
          let index = this.listCredentialsWithRights.length - 1;
          creds.listCredentials.forEach(y =>
          {
            this.listCredentialsWithRights.push(y);
            this.listOfData.push({
              index: index + 1,
              data: y
            });
            index++;
          })
          this.listOfData = [...this.listOfData];
          //this.listCredentialsWithRights = [...this.listCredentialsWithRights]
        }
        else
        {
          this.listCredentialsWithRights = creds.listCredentials;
          this.listOfData = [];
          let index = 0;
          creds.listCredentials.forEach(y =>
          {
            this.listCredentialsWithRights.push(y);
            this.listOfData.push({
              index: index + 1,
              data: y
            });
            index++;
          })
          this.listOfData = [...this.listOfData];
        }

        this.canCreateCred = this.authService.Access.canCreatePrivateCredentials || this.authService.Access.canCreateSharedCredentials;
        this.dataLoaded = true;
        this.isaskingserver = false;
        window.dispatchEvent(new Event('resize'));

      })

      this.searchParams.start += this.searchParams.count;
      clearTimeout(this.temp);
    }, 500);

  }

  public getFilters(adminMode: boolean)
  {
    return this.http.get<FilterValues>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getcredentialsforuser/' + adminMode + "/filters");
  }

  public getCredentials(adminMode: boolean, typefilter: number): Observable<CredentialsWithRights>
  {
    return this.http.get<CredentialsWithRights>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getcredentialsforuser/' + adminMode + "/visibility/" + typefilter);
  }

  public searchCredentials(adminMode: boolean, searchCred: SearchCredentials): Observable<CredentialsWithRights>
  {
    return this.http.post<CredentialsWithRights>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getcredentialsforuser/' + adminMode + "/search", searchCred);
  }

  public getPassword(id: number)
  {
    return this.credentialsSource.GetPassword(id);
  }

  public createCredentials(cred: Credentials)
  {
    return this.credentialsSource.createCredentials(cred).pipe(tap(c => this.infoFilterCredentials = c));
  }

  public createCredentialsWithGroups(cred: CredentialWithGroupDTO)
  {
    return this.credentialsSource.createCredentialsWithGroups(cred).pipe(tap(c => this.infoFilterCredentials = c));
  }

  public updateCredentials(cred: Credentials)
  {
    return this.credentialsSource.updateCredentials(cred);
  }

  public getGroupsOfCredentials(idcred: number)
  {
    return this.credentialsSource.getGroupsOfCredentials(idcred).pipe(tap(c => this.groupsOfCredentials = c));
  }

  public updateGroupsOfCredentials(groups: CredentialsSharedGroupsDTO[])
  {
    return this.credentialsSource.updateGroupsOfCredentials(groups);
  }

  public UpdateShowLoginPreferences(key: string, value: string)
  {
    return this.credentialsSource.UpdateShowLoginPreferences(key, value);
  }

  public GetShowLoginPreference()
  {
    return this.credentialsSource.GetShowLoginPreference();
  }
  public GetColumnVisibilityPreference()
  {
    return this.credentialsSource.GetColumnVisibilityPreference();
  }

  public GetAdminModePreference()
  {
    return this.credentialsSource.GetAdminModePreference();
  }

  public ArchiveCredentials(idcred: number)
  {
    return this.credentialsSource.ArchiveCredentials(idcred);
  }

  public RestoreCredentials(idcred: number)
  {
    return this.credentialsSource.RestoreCredentials(idcred);
  }

  getCredentialAttachments(idcred: number)
  {
    return this.http.get<KanbanAttachment[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${idcred}/attachment`);
  }

  addAttachmentToCredential(idcred: number, attachment: KanbanAttachment)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${idcred}/attachment`, attachment);
  }

  removeAttachmentFromCredential(idcred: number, idfile: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${idcred}/attachment/${idfile}`);
  }

  downloadCredentialAttachment(idcred: number, idattachment: number)
  {
    return this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${idcred}/attachment/${idattachment}`);
  }

  getThirdPartyForCredential(idcred: number)
  {
    return this.http.get<ThirdPartyForCredentialEdit>(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${idcred}/third-party`);
  }

  updateThirdPartyForCredential(idcred: number, tps: ThirdPartyForCredential[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${idcred}/third-party`, tps);
  }

  notifyCredentialsVisualisation(idcred: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/credentials/${idcred}/notify-visualisation`, null);
  }
}
