import { NotifyCardMemberAction } from "./notify-card-member-action";

export class NotifyUserForAction
{
    trigger: string = "";
    userToNotify: string[] = [];
    teamToNotify: string[] = [];
    oldCardTitle: string = "";
    oldIdList: number = 0;
    comment: string = "";
    oldDescription: string = "";
    changedmember: NotifyCardMemberAction = null;
}