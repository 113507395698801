import { Injectable } from '@angular/core';
import { KanbanService } from './kanban.service';
import { TranslateService } from '@ngx-translate/core';
import { KanbanCard } from '../entities/Card';
import { KanbanList } from '../entities/List';
import { KanbanBoard } from '../entities/Board';
import { KanbanActionsManagerComponent } from '../components/kanban-actions-manager/kanban-actions-manager.component';
import { User } from '../../../../src/app/user/model/user';
import { NotifyUserForAction } from '../entities/notify-user';
import { NotifyCardMemberAction } from '../entities/notify-card-member-action';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class KanbanToolsService
{

  constructor(public kbs: KanbanService, private translateService: TranslateService,
    private modalService: NzModalService) { }

  actionManagerModal: NzModalRef;
  oldTitle = "";
  oldDescription = "";
  titleHasFocus = false;
  descriptionHasFocus = false;

  resetEditModalField()
  {
    let titleDiv = document.getElementById("kanban-card-title");
    if (titleDiv)
      titleDiv.style.display = "none";
    let descriptionDiv = document.getElementById("kanban-card-description");
    if (descriptionDiv)
      descriptionDiv.style.display = "none";
    this.titleHasFocus = false;
    this.descriptionHasFocus = false;
  }

  createCardProcess(card: KanbanCard, list: KanbanList, board: KanbanBoard, openAfterCreation = false)
  {
    let filteredActionList = list.actionsAtCardCreation.filter(x => x.triggerAction == "0");
    let filteredActionCard = card.actions.filter(x => x.triggerAction == "0");
    filteredActionList = filteredActionList.concat(filteredActionCard);
    if (filteredActionList.findIndex(x => x.codeAction == "SEND-MAIL-TEAM-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-LABELS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-TEAMS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-CONFIRMATION") >= 0 || filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
    {
      let title = "";
      this.translateService.get("KANBAN.WORKFLOW-MANAGE-TITLE").subscribe(x => title = x);
      let confirm = "";
      this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
      let cancel = "";
      this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
      this.actionManagerModal = this.modalService.create({
        nzTitle: title,
        nzContent: KanbanActionsManagerComponent,
        nzWidth: '90%',
        nzClosable: false,
        nzMaskClosable: false,
        nzFooter: [{
          label: confirm,
          type: 'primary',
          disabled: (result) =>
          {
            if (result.comment.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
              return true;
            if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
              return true;
            if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
              return true;
            if (result.listUsersToNotified.length == 0 && filteredActionList.findIndex(x => x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0)
              return true;
            if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
              return true;
            return false;
          },
          onClick: (result) =>
          {
            if (result.comment.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
              return;
            if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
              return;
            if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
              return;
            if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
              return;

            this.kbs.addCardToList(list.id, card).subscribe(x =>
            {
              card.id = x.id;
              list.cards.push(card);
              this.kbs.createdCard = new KanbanCard();
              this.kbs.isAddingCardToList = 0;
              this.kbs.applyListActions(card, list, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, true, true, true, true, result.dueDate);
              var objDiv = document.getElementById("kanbanlist_" + list.id);
              objDiv.scrollTop = objDiv.scrollHeight;
              let notify = new NotifyUserForAction();
              notify.trigger = "0";
              notify.userToNotify = result.listUsersToNotified;
              notify.teamToNotify = result.listTeamsToNotified;
              this.kbs.notifyUser(card.id, null, filteredActionList, notify);
              if (list.autofilter)
                this.kbs.sortCartManually(list).subscribe();
              this.actionManagerModal.close();
              if (openAfterCreation)
                this.kbs.editCard(card.id);
            });
          }
        },
        {
          label: cancel,
          onClick: () =>
          {
            this.actionManagerModal.close();
          }
        }
        ]
      });
      this.actionManagerModal.componentInstance.board = board;
      this.actionManagerModal.componentInstance.card = card;
      this.actionManagerModal.componentInstance.relation = null;
      this.actionManagerModal.componentInstance.listid = list.id;
      this.actionManagerModal.componentInstance.trigger = "0";
    }
    else
    {
      this.kbs.addCardToList(list.id, card).subscribe(x =>
      {
        card.id = x.id;
        list.cards.push(card);
        this.kbs.createdCard = new KanbanCard();
        this.kbs.isAddingCardToList = 0;
        this.kbs.applyListActions(card, list, filteredActionList, "", null, null, null, null, "");
        var objDiv = document.getElementById("kanbanlist_" + list.id);
        objDiv.scrollTop = objDiv.scrollHeight;

        if (list.autofilter)
          this.kbs.sortCartManually(list).subscribe();
        if (openAfterCreation)
          this.kbs.editCard(card.id);
      });
    }
  }



  updateCardProcess(card: KanbanCard, list: KanbanList, board: KanbanBoard, triggerAction: string, member: number)
  {
    let filteredActionList = list ? list.actionsAtCardCreation.filter(x => x.triggerAction == triggerAction) : [];
    let filteredActionCard = card.actions.filter(x => x.triggerAction == triggerAction);
    filteredActionList = filteredActionList.concat(filteredActionCard);
    if (filteredActionList.findIndex(x => x.codeAction == "SEND-MAIL-TEAM-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-LABELS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-TEAMS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-CONFIRMATION") >= 0 || filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
    {
      let title = "";
      this.translateService.get("KANBAN.WORKFLOW-MANAGE-TITLE").subscribe(x => title = x);
      let confirm = "";
      this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
      let cancel = "";
      this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
      this.actionManagerModal = this.modalService.create({
        nzTitle: title,
        nzContent: KanbanActionsManagerComponent,
        nzWidth: '90%',
        nzClosable: false,
        nzMaskClosable: false,
        nzFooter: [{
          label: confirm,
          type: 'primary',
          disabled: (result) =>
          {
            if (result.comment.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
              return true;
            if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
              return true;
            if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
              return true;
            if (result.listUsersToNotified.length == 0 && filteredActionList.findIndex(x => x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0)
              return true;
            if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
              return true;
            return false;
          },
          onClick: (result) =>
          {
            if (result.comment.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
              return;
            if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
              return;
            if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
              return;
            if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
              return;

            if (triggerAction == "1")
            {
              this.kbs.updateCardObject(card.id, card.title).subscribe(x =>
              {

                document.getElementById("kanban-card-title").style.display = "none";
                this.titleHasFocus = false;
                this.kbs.applyListActions(card, list, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, true, true, true, true, result.dueDate);
                let notify = new NotifyUserForAction();
                notify.trigger = "1";
                notify.oldCardTitle = this.oldTitle;
                notify.userToNotify = result.listUsersToNotified;
                notify.teamToNotify = result.listTeamsToNotified;
                this.kbs.notifyUser(card.id, null, filteredActionList, notify);
                this.kbs.updateCardDate.next(card);
                this.oldTitle = card.title;
                this.actionManagerModal.close();
              });
            }
            else if (triggerAction == "4")
            {
              this.kbs.updateCardDescription(card.id, card.description).subscribe(x =>
              {
                document.getElementById("kanban-card-description").style.display = "none";
                this.descriptionHasFocus = false;
                let notify = new NotifyUserForAction();
                notify.trigger = "4";
                notify.oldDescription = this.oldDescription;
                notify.userToNotify = result.listUsersToNotified;
                notify.teamToNotify = result.listTeamsToNotified;
                this.kbs.notifyUser(card.id, null, filteredActionList, notify);

                this.kbs.applyListActions(card, list, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, true, true, true, true, result.dueDate);
                this.actionManagerModal.close();
              });
            }
            else if (triggerAction == "5")
            {
              let labelInListIndex = card.members.findIndex(x => x == member);
              if (labelInListIndex == -1)
              {
                this.kbs.addMemberToCard(card.id, member).subscribe(x =>
                {
                  card.members.push(member);
                  this.kbs.applyListActions(card, list, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, true, true, true, true, result.dueDate);
                  let notify = new NotifyUserForAction();
                  notify.trigger = "5";
                  notify.changedmember = new NotifyCardMemberAction();
                  notify.changedmember.add = true;
                  let user = new User();
                  user.id = member;
                  notify.changedmember.member = user;
                  notify.userToNotify = result.listUsersToNotified;
                  notify.teamToNotify = result.listTeamsToNotified;
                  this.kbs.notifyUser(card.id, null, filteredActionList, notify);
                  this.actionManagerModal.close();
                });
              }
              else
              {
                this.kbs.removeMemberFromCard(card.id, member).subscribe(x =>
                {
                  card.members.splice(labelInListIndex, 1);
                  this.kbs.applyListActions(card, list, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, true, true, true, true, result.dueDate);
                  let notify = new NotifyUserForAction();
                  notify.trigger = "5";
                  notify.changedmember = new NotifyCardMemberAction();
                  notify.changedmember.add = false;
                  let user = new User();
                  user.id = member;
                  notify.changedmember.member = user;
                  notify.userToNotify = result.listUsersToNotified;
                  notify.teamToNotify = result.listTeamsToNotified;
                  this.kbs.notifyUser(card.id, null, filteredActionList, notify);
                  this.actionManagerModal.close();
                });
              }
            }
          }
        },
        {
          label: cancel,
          onClick: () =>
          {
            card.title = this.oldTitle;
            let titleDiv = document.getElementById("kanban-card-title");
            if (titleDiv)
              titleDiv.style.display = "none";
            let descriptionDiv = document.getElementById("kanban-card-description");
            if (descriptionDiv)
              descriptionDiv.style.display = "none";
            this.titleHasFocus = false;
            this.descriptionHasFocus = false;
            card.description = this.oldDescription;
            this.actionManagerModal.close();
          }
        }
        ]
      });
      this.actionManagerModal.componentInstance.board = board;
      this.actionManagerModal.componentInstance.card = card;
      this.actionManagerModal.componentInstance.relation = null;
      this.actionManagerModal.componentInstance.listid = list.id;
      this.actionManagerModal.componentInstance.trigger = triggerAction;
    }
    else
    {
      if (triggerAction == "1")
      {
        this.kbs.updateCardObject(card.id, card.title).subscribe(x =>
        {
          this.oldTitle = card.title;
          this.kbs.updateCardDate.next(card);
          document.getElementById("kanban-card-title").style.display = "none";
          this.titleHasFocus = false;
          this.kbs.applyListActions(card, list, filteredActionList, "", null, null, null, null, "");
        });
      }
      else if (triggerAction == "4")
      {
        this.kbs.updateCardDescription(card.id, card.description).subscribe(x =>
        {
          document.getElementById("kanban-card-description").style.display = "none";
          this.descriptionHasFocus = false;
          this.kbs.applyListActions(card, list, filteredActionList, "", null, null, null, null, "");
        });
      }
      else if (triggerAction == "5")
      {
        let labelInListIndex = card.members.findIndex(x => x == member);
        if (labelInListIndex == -1)
        {
          this.kbs.addMemberToCard(card.id, member).subscribe(x =>
          {
            card.members.push(member);
            this.kbs.applyListActions(card, list, filteredActionList, "", null, null, null, null, "");
          });
        }
        else
        {

          this.kbs.removeMemberFromCard(card.id, member).subscribe(x =>
          {
            card.members.splice(labelInListIndex, 1);
            this.kbs.applyListActions(card, list, filteredActionList, "", null, null, null, null, "");
          });
        }
      }
    }
  }

}
