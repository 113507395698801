import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { CredentialsDataService } from '../../../../src/app/credentials/services/data/credentials-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CredentialsDTOWithRights } from '../../../../src/app/credentials/entities/credentials-dto-with-rights';
import { NotifyCredentialWithRight } from '../../../../src/app/credentials/entities/notify-credential-with-rights';
import { KanbanAttachment } from '../../../../src/app/kanban/entities/Attachment';
import { NotifyCredTPAssociation } from '../../../../src/app/kanban/entities/Notify/notifiy-cred-tp-association';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class CredentialNotificationService
{

  constructor(private credentialsService: CredentialsDataService,
    private notification: NzNotificationService,
    private translateService: TranslateService) { }

  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('CreateCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      this.credentialsService.listCredentialsWithRights.push(credentialWithRights);
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('UpdateCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentials.id);
      if (index == -1)
        return;
      this.credentialsService.listCredentialsWithRights[index] = credentialWithRights;
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('NotifyCredentialsModification', (message: string) =>
    {
      let title = "";
      this.translateService.get("NOTIFICATION.CREDENTIALS.TITLE").subscribe(x => title = x);
      this.notification.create(
        'info',
        title,
        message
      );
    });

    _hubConnection.on('NotifyCredentialThirdPartyUpdate', (notify: NotifyCredTPAssociation) =>
    {
      this.credentialsService.listCredentialsWithRights.forEach(cred =>
      {
        let index = notify.idcredential.findIndex(id => id == cred.credentials.id);
        if (index == -1)
        {
          let index2 = cred.credentials.thirdPartyInfos.findIndex(x => x.id == notify.tpinfos.id);
          if (index2 >= 0)
            cred.credentials.thirdPartyInfos.splice(index2, 1);
        }
        else 
        {
          let index2 = cred.credentials.thirdPartyInfos.findIndex(x => x.id == notify.tpinfos.id);
          if (index2 < 0)
            cred.credentials.thirdPartyInfos.push(notify.tpinfos);
        }

      })
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('NotifyCredentialThirdPartyAssociation', (notify: NotifyCredTPAssociation) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == notify.idcredential[0]);
      if (index == -1)
        return;
      this.credentialsService.listCredentialsWithRights[index].credentials.thirdPartyInfos.push(notify.tpinfos);
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('NotifyCredentialThirdPartyDisassociation', (notify: NotifyCredTPAssociation) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == notify.idcredential[0]);
      if (index == -1)
        return;
      let index2 = this.credentialsService.listCredentialsWithRights[index].credentials.thirdPartyInfos.findIndex(x => x.id == notify.tpinfos.id);
      this.credentialsService.listCredentialsWithRights[index].credentials.thirdPartyInfos.splice(index2, 1);
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('AddAttachmentToCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentials.id);
      if (index == -1)
        return;
      let att = new KanbanAttachment();
      this.credentialsService.listCredentialsWithRights[index].credentials.attachments.push(att);
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('RemoveAttachmentFromCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentials.id);
      if (index == -1)
        return;
      this.credentialsService.listCredentialsWithRights[index].credentials.attachments.splice(0, 1);
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('AddManagerToCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentials.id);
      if (index >= 0)
        this.credentialsService.listCredentialsWithRights[index] = credentialWithRights;
      else this.credentialsService.listCredentialsWithRights.push(credentialWithRights);
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('RemoveManagerFromCredential', (credentialWithRights: NotifyCredentialWithRight) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentialWithRight.credentials.id);
      if (index >= 0)
      {
        if (credentialWithRights.delete)
          this.credentialsService.listCredentialsWithRights.splice(index, 1);
        else this.credentialsService.listCredentialsWithRights[index] = credentialWithRights.credentialWithRight;
        this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
      }
    });

    _hubConnection.on('ChangeRightForCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentials.id);
      if (index == -1)
        return;
      this.credentialsService.listCredentialsWithRights[index] = credentialWithRights;
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('ArchiveCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentials.id);
      if (index == -1)
        return;
      this.credentialsService.listCredentialsWithRights[index] = credentialWithRights;
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('RestoreCredential', (credentialWithRights: CredentialsDTOWithRights) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentials.id);
      if (index == -1)
        return;
      this.credentialsService.listCredentialsWithRights[index] = credentialWithRights;
      this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
    });

    _hubConnection.on('UpdateCredentialGroupCredentials', (credentialWithRights: NotifyCredentialWithRight) =>
    {
      let index = this.credentialsService.listCredentialsWithRights.findIndex(x => x.credentials.id == credentialWithRights.credentialWithRight.credentials.id);
      if (index >= 0)
      {
        if (credentialWithRights.delete)
        {
          this.credentialsService.listCredentialsWithRights.splice(index, 1);
        }
        else this.credentialsService.listCredentialsWithRights[index] = credentialWithRights.credentialWithRight;
        this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
      }
      else if (!credentialWithRights.delete)
      {
        this.credentialsService.listCredentialsWithRights.push(credentialWithRights.credentialWithRight);
        this.credentialsService.listCredentialsWithRightsChange.next(this.credentialsService.listCredentialsWithRights);
      }
    });
  }


}
