import { Component, OnInit, Input } from '@angular/core';
import { LoginInfos } from '../../entities/login';
import { AuthService } from '../../services/auth.service';
import { catchError, map, tap, filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UrlApiService } from '../../services/url-api.service';
import { UserSourceService } from '../../user/services/source/user-source.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalNotificationService } from '../../../../src/app/services/notification/global-notification.service';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { MoralPersonService } from '../../../../src/app/third-party/moral-person/services/moral-person.service';
import { PhysicalPersonService } from '../../../../src/app/third-party/physical-person/services/physical-person.service';
import { FloatingChatService } from '../../../../src/app/services/floating-chat.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { en_US, fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';
import { Meta } from '@angular/platform-browser';
import { SettingsService } from '../../settings/services/settings.service';
import { GroupUserDataService } from '../../group-user/services/data/group-user-data.service';
import { ThirdPartyService } from '../../third-party/services/third-party.service';
import { of } from 'rxjs';
import { MessagerieService } from '../../messagerie/messagerie.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{

  errorReset: boolean = false;
  token: string;
  login = "";
  iduser: number = 0;
  constructor(private route: ActivatedRoute, public authService: AuthService, private router: Router, private i18n: NzI18nService,
    private mss: MessagerieService,
    private modalService: NzModalService, private userService: UserSourceService, private translateService: TranslateService,
    private meta: Meta, private settingsService: SettingsService, private groupuserservice: GroupUserDataService, private tpService: ThirdPartyService,
    private pps: PhysicalPersonService, private mps: MoralPersonService, private cds: CacheDataService, public fcs: FloatingChatService,
    private GlobalNotificationService: GlobalNotificationService)
  {
    if (authService.Token != null)
    {
      //this.router.navigate(['/']);
    }

  }
  userInfos: LoginInfos = new LoginInfos("", "", "");

  isSubmitted: boolean = false;
  ngOnInit()
  {
    this.route.queryParams.subscribe(params =>
    {
      this.token = params["token"];
      this.iduser = parseInt(params["iduser"]);
      let action = params["action"];
      if (params["login"])
      {
        this.userInfos.Username = params["login"];
        this.authService.s_Login = this.userInfos.Username;
      }
      else if (this.authService.s_Login)
      {
        this.userInfos.Username = this.authService.s_Login;
      }
      if (this.iduser > 0 && action == "activateuser")
      {
        this.userService.activateUser(this.iduser, this.token).subscribe();
      }
    });
  }

  ResetPassword()
  {
    let title = "";
    this.translateService.get("LOGIN.MAIL-RESET-CONFIRMATION").subscribe(x => title = x);
    let content = "";
    this.translateService.get("PASSWORD.RESET").subscribe(x => content = x);
    this.authService.resetPassword(this.userInfos.Username).subscribe(x =>
    {
      const modal = this.modalService.create({
        nzTitle: title,
        nzContent: content,
        nzWidth: '500px',
        nzFooter: [{
          label: 'Close',
          onClick: () => { modal.close(); }
        }
        ]
      });
    });
  }

  firstLoadOver = false;

  onSubmit()
  {
    this.isSubmitted = true;
    this.authService.login(this.userInfos.Username, this.userInfos.Password, UrlApiService.settings.apiConfig.uriAPI).subscribe(x =>
    {
      this.fcs.getSavePosition();
      this.authService.s_Login = this.userInfos.Username;
      setTimeout(() =>
      {
        this.pps.getAllPhysicalPersons().subscribe(x =>
        {
          if (x && x.length > 0)
          {
            this.cds.physicalPersonCollection = x;
            this.pps.physicalPersonListChange.next(null);
          }

        })
        this.mps.getAllMoralPersons().subscribe(x =>
        {
          if (x && x.length > 0)
          {
            this.cds.moralPersonCollection = x;
            this.mps.moralPersonListChange.next(null);
          }
        })
      }, 500);

      this.userService.getAllUserWithAvatar().subscribe(x =>
      {

        this.cds.userCollection = x.sort((a, b) => (a.name + a.surname).toUpperCase() < (b.name + b.surname).toUpperCase() ? -1 : 1);
        if (this.authService.Token)
        {
          this.authService.getGroupAccess(this.settingsService.setting.value).subscribe(() =>
          {
            this.translateService.use(this.authService.Access.locale);
            this.i18n.setLocale(this.authService.Access.locale == "fr" ? fr_FR : en_US)
            this.meta.updateTag({ name: "lang", content: this.authService.Access.locale });

          });
          setTimeout(() =>
          {
            //if(!isDevMode())
            this.GlobalNotificationService.getAllUserNotifications().subscribe();
          }, 5000);
          setTimeout(() =>
          {
            //if(!isDevMode())
            this.GlobalNotificationService.getAllChatNotifications().subscribe();
          }, 2000);

        }
      });

      this.mss.getUnreadTotalCount().subscribe(t => this.mss.totalUnread = t);
      //if(!isDevMode())
      {
        setTimeout(() =>
        {
          if (this.cds.teamsCollection.length == 0)
          {
            this.groupuserservice.getAllGroupUsers(false).subscribe(grps => this.cds.teamsCollection = grps.map(x => x.grpuser));
          }
        }, 4500);


        setTimeout(() =>
        {
          this.tpService.getAllLabels().subscribe();
        }, 6500);

      }



    });
  }
}
