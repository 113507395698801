import { Component, OnInit, Input } from '@angular/core';
import { KanbanList } from '../../entities/List';

@Component({
  selector: 'app-edit-list-style',
  templateUrl: './edit-list-style.component.html',
  styleUrls: ['./edit-list-style.component.scss']
})
export class EditListStyleComponent implements OnInit {

  constructor() { }

  @Input() list: KanbanList;
  bghue = "";
  bgcolor = "";

  fonthue = "";
  fontcolor = "";

  ngOnInit() {
    this.bgcolor = this.list.color;
    this.fontcolor = this.list.fontColor;
  }

}
