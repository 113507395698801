import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../src/app/services/auth.service';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { ThirdPartyService } from '../../../../src/app/third-party/services/third-party.service';
import { ChatService } from '../chat.service';
import { ChatMessage } from '../entities/chat-message';
import { User } from '../../../../src/app/user/model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatMessageRead } from '../entities/chat-message-read';
import { GlobalNotificationService } from '../../../../src/app/services/notification/global-notification.service';
import { ChatParam } from '../entities/chat-param';
import { Subscription } from 'rxjs';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';


@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit
{

  private changeDetectionRef: ChangeDetectorRef;

  constructor(
    public translateService: TranslateService,
    public cds: CacheDataService,
    private auth: AuthService,
    public tpservice: ThirdPartyService,
    public chatS: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    private nzContextMenuService: NzContextMenuService,
    public gbs: GlobalNotificationService,
    changeDetectionRef: ChangeDetectorRef,
  )
  {
    this.changeDetectionRef = changeDetectionRef;
    this.route.paramMap.subscribe(x =>
    {
      let id = x.get("id")
      let messageId = x.get("messageId")
      if (id)
      {
        this.id = id
        if (messageId)
        {
          this.markMessageId = messageId;
        }
      }
    })
  }

  @ViewChild("viewportref")
  public viewportRef!: ElementRef;


  id
  markMessageId
  you: User = new User
  today: Date = new Date()
  oldDate: Date = new Date()
  noMoreOldMessage = false
  number = 25
  start = 0
  oldMessageToEdit: ChatMessage = new ChatMessage
  scrollId = "ScrollF"
  idnewmessage = 0;
  displayReply = false
  messageToEdit: ChatMessage = new ChatMessage()
  editing = false

  notifSubscription: Subscription
  messageSubscription: Subscription
  readConfirmSubscription: Subscription
  readSendedMessageSubscription: Subscription

  @Input() paramIn: ChatParam = new ChatParam()
  param: ChatParam = new ChatParam()

  ngOnInit()
  {
    console.log(this.paramIn);

    this.auth.setCamelineTitle("chat");
    this.chatS.discussionIsOpen = true
    this.chatS.readIdmessages = false
    this.param.dataLoaded = true;
    /**
     * subscription to display message in the right discussion when i send message
     */
    this.messageSubscription = this.chatS.sendMessageSub.subscribe(message =>
    {
      this.displayMessageSended(message)
    })

    this.readConfirmSubscription = this.chatS.readConfirmSub.subscribe(() =>
    {
      if (this.param.isPrivateDiscussion)
      {
        if (this.param.paramId && this.param.paramId > 0)
          this.getLastPrivateMessageReadList(this.param.paramId)
      } else
      {
        if (this.param.paramId && this.param.paramId > 0)
          this.getLastMessageReadList(this.param.paramId)
      }
    })

    this.readSendedMessageSubscription = this.chatS.ReadMessageSub.subscribe(message =>
    {
      this.chatS.readIdmessages = true
    })

    /**
     * subscription to get notification
     */
    this.notifSubscription = this.chatS.ChatNotification.subscribe(newMessage =>
    {
      this.notifyNewMessage(newMessage)
    })

    /**
     * is call when route param change and when page is init
     */
    this.route.params.subscribe(routeParams =>
    {
      this.gbs.getAllChatNotifications().subscribe(() =>
      {
        this.changeRouteSetParams(routeParams)
      })
    });
  }

  /**
   * Display message in the right discussion when i send message
   */
  displayMessageSended(message: ChatMessage)
  {
    this.displayReply = false
    if (this.param.paramId == message.receiverId && !message.discussionId && message.receiverId)  // ca vient d'une discussion privé
    {
      if (this.router.url.includes('/chat/discussion') && !this.param.isFloatingMessage)
      {
        this.param.messageList.push(message)
        this.chatS.updateScroll("Scroll", this.param)
      }
    }

    if (this.param.paramId == message.discussionId && message.discussionId && !message.receiverId)  // ca vient d'un groupe
    {
      if (this.router.url.includes('/chat/groups') && !this.param.isFloatingMessage)
      {
        this.param.messageList.push(message)
        this.chatS.updateScroll("Scroll", this.param)
      }
    }

    if (this.param.paramId == message.receiverId && !message.discussionId && message.receiverId)  // ca vient d'une discussion privé et je suis dans un chat flottant
    {
      if (this.param.isFloatingMessage)
      {
        this.param.messageList.push(message)
        this.chatS.updateScroll("ScrollF", this.param)
      }
    }

    if (this.param.paramId == message.discussionId && message.discussionId && !message.receiverId)  // ca vient d'un groupe et je suis dans un chat flottant
    {
      if (this.param.isFloatingMessage)
      {
        this.param.messageList.push(message)
        this.chatS.updateScroll("ScrollF", this.param)
      }
    }
  }

  /**
   * subscription to get notification
   */
  notifyNewMessage(newMessage: ChatMessage)
  {

    if (!this.param.isFloatingMessage && ((this.param.paramId == newMessage.creatorId && !newMessage.discussionId) || (this.param.paramId == newMessage.discussionId && newMessage.discussionId)))
    {
      this.param.messageList.push(newMessage)
      this.chatS.updateScroll("Scroll", this.param)
      this.messagesToRead(newMessage)
    }
    if (this.param.isFloatingMessage && ((this.param.paramId == newMessage.creatorId && !newMessage.discussionId) || (this.param.paramId == newMessage.discussionId && newMessage.discussionId)))
    {
      this.param.messageList.push(newMessage)
      this.chatS.updateScroll("ScrollF", this.param)
      this.messagesToRead(newMessage)
    }
  }

  /**
   * set params when route param change
   */
  changeRouteSetParams(routeParams)
  {
    this.chatS.messageToReply = new ChatMessage()
    this.displayReply = false
    this.idnewmessage = 0
    this.number = 25
    this.start = 0
    this.noMoreOldMessage = false

    if (this.markMessageId)
    {
      this.scrollId = "Scroll"
      this.param.paramId = this.id
      this.param.isFloatingMessage = false
      if (window.location.href.includes('/chat/groups'))
      {
        this.param.isPrivateDiscussion = false
        this.getMessagesToMark();
      }
      else if (window.location.href.includes('/chat/discussion'))
      {
        this.param.isPrivateDiscussion = true
        this.getPrivateMessagesToMark();
      }
    }
    else if (!this.paramIn.paramId)
    {
      this.scrollId = "Scroll"
      this.param.paramId = routeParams.id
      this.param.isFloatingMessage = false
      if (window.location.href.includes('/chat/groups'))
      {
        this.param.isPrivateDiscussion = false
        this.getUnreadMessages();
      }
      else if (window.location.href.includes('/chat/discussion'))
      {
        this.param.isPrivateDiscussion = true
        this.getUnreadPrivateMessages();
      }
    }
    else
    {
      this.param = this.paramIn
      console.log("im here");


      setTimeout(() =>
      {
        if (!this.param.isPrivateDiscussion)
          this.getUnreadMessages();
        else
          this.getUnreadPrivateMessages();
      }, 100);

    }
    this.you = this.cds.userCollection.find(usr => usr.id == this.auth.Access.idUser);
  }


  /**
   * read message when i sent it
   */
  messagesToRead(chatMessage: ChatMessage)
  {
    let messageToRead: ChatMessageRead[] = []
    let message: ChatMessageRead = new ChatMessageRead()
    message.messageId = chatMessage.id
    message.userId = chatMessage.creatorId
    messageToRead.push(message)
    this.chatS.readMessages(messageToRead).subscribe()
  }

  ngOnDestroy()
  {
    this.chatS.discussionIsOpen = false
    this.param = new ChatParam()
    this.notifSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.readSendedMessageSubscription.unsubscribe();
  }

  /**
   * get messages associated to room with room id define in var id
   *
   */
  getMessagesToMark()
  {
    this.chatS.getMessagesToMark(this.id, this.markMessageId).subscribe(x =>
    {
      this.param.messageList = [...x];
      var element = document.getElementById(this.scrollId);
      if (!element)
        return

      setTimeout(() =>
      {
        this.goToElement(this.markMessageId)
      }, 100);
      setTimeout(() =>
      {
        element.style.opacity = '1'
        this.param.dataLoaded = true
      }, 200);
    })
  }

  getPrivateMessagesToMark()
  {
    this.chatS.getPrivateMessagesToMark(this.id, this.markMessageId).subscribe(x =>
    {
      this.param.messageList = x;
      var element = document.getElementById(this.scrollId);
      if (!element)
        return

      setTimeout(() =>
      {
        this.goToElement(this.markMessageId)
      }, 100);
      setTimeout(() =>
      {
        element.style.opacity = '1'
        this.param.dataLoaded = true
      }, 200);
    })
  }

  getMessages()
  {
    if (this.noMoreOldMessage)
      return;
    this.chatS.getMessages(this.start, this.number, this.param.paramId).subscribe(x =>
    {
      this.param.messageList = [...x];

      if (x.length < 25)
        this.noMoreOldMessage = true
      this.start += this.number
      setTimeout(() =>
      {
        this.chatS.updateScroll(this.scrollId, this.param)
      }, 100);
    })
  }

  /**
   * get unread messages associated to room with room id define in var id
   *
   */
  getUnreadMessages()
  {
    this.chatS.getUnreadMessages(this.param.paramId).subscribe(x =>
    {
      this.param.messageList = [...x];
      if (x.length == 0)
      {
        this.param.dataLoaded = true
        return
      }
      let lenght = x.length - 1
      // this.chatS.lastMessageIsRead(this.param.messageList[lenght].id, this.param)
      setTimeout(() =>
      {
        if (this.gbs.chatNotifications.length > 0)
          this.readMessages(x)

        this.start = x.length
        if (this.gbs.chatNotifications.length == 0)
        {
          this.gbs.getAllChatNotifications().subscribe(y =>
          {
            x.forEach(elem =>
            {
              if (this.idnewmessage != 0)
                return;
              this.getUnreadMessagesPosition(elem);
            });
          })
        }
        else
        {
          x.forEach(elem =>
          {
            if (this.idnewmessage != 0)
              return;
            this.getUnreadMessagesPosition(elem);
          });
        }
        this.getLastMessageReadList(this.param.paramId)
      }, 500);
    })
  }

  /**
   * get messages associated to user with user id define in var id
   *
   */
  getPrivateMessages()
  {
    console.log("wtf dude");

    if (this.noMoreOldMessage)
      return;
    this.chatS.getPrivateMessages(this.start, this.number, this.param.paramId).subscribe(x =>
    {
      this.param.messageList = [...x];
      if (x.length < 25)
        this.noMoreOldMessage = true
      this.start += this.number
      setTimeout(() =>
      {
        this.chatS.updateScroll(this.scrollId, this.param)
      }, 100);
    })
  }

  /**
   * get unread messages associated to user with user id define in var id
   *
   */
  getUnreadPrivateMessages()
  {
    console.log("pogo 1");

    this.chatS.getUnreadPrivateMessages(this.param.paramId).subscribe(x =>
    {
      this.param.messageList = [...x];
      console.log(this.param.messageList);

      if (x.length == 0)
      {
        this.param.dataLoaded = true
        return
      }
      let lenght = x.length - 1
      // this.chatS.lastMessageIsRead(this.param.messageList[lenght].id, this.param)
      setTimeout(() =>
      {
        if (this.gbs.chatNotifications.length > 0)
          this.readMessages(x)

        else if (this.gbs.chatNotifications.length == 0)
        {
          console.log("pogo 3");
          this.gbs.getAllChatNotifications().subscribe(y =>
          {
            x.forEach(elem =>
            {
              if (this.idnewmessage != 0)
                return;
              this.getUnreadMessagesPosition(elem);
            });
          })
        }
        else
        {
          console.log("pogo 4");

          x.forEach(elem =>
          {
            this.getUnreadMessagesPosition(elem);
          });
        }
        this.start = x.length;
        if (this.param.paramId && this.param.paramId > 0)
          this.getLastPrivateMessageReadList(this.param.paramId)
      }, 500);
    })
  }

  /**
   * read list of messages
   * param:
   * x => array of message
   */
  readMessages(x: ChatMessage[])
  {
    console.log("pogo 2");
    let messageToRead: ChatMessageRead[] = []
    x.forEach(mess =>
    {
      if (!messageToRead.find(f => f.messageId == mess.id))
      {
        let message: ChatMessageRead = new ChatMessageRead()
        message.messageId = mess.id
        message.userId = mess.creatorId
        messageToRead.push(message)
      }
      this.getUnreadMessagesPosition(mess);
    })
    this.chatS.readMessages(messageToRead).subscribe(x => this.gbs.getAllChatNotifications().subscribe())
  }

  /**
   * get the position of oldest unread message to focus on
   * param:
   * message => message where i want to go
   *
   */
  getUnreadMessagesPosition(message: ChatMessage)
  {
    console.log("poggers");

    if (this.gbs.chatNotifications.length == 0)
    {
      setTimeout(() =>
      {
        this.chatS.updateScroll(this.scrollId, this.param)
      }, 100);
    }
    let ok = true
    for (let x of this.gbs.chatNotifications)
    {
      if (x.message.id == message.id)
      {
        this.idnewmessage = x.message.id;

        setTimeout(() =>
        {
          document.getElementById(this.idnewmessage.toString()).scrollIntoView({ behavior: "auto", block: "end", inline: "end" });
          var element = document.getElementById(this.scrollId);
          element.style.opacity = '1'
          console.log("hyeo");

          this.param.dataLoaded = true
          ok = false
        }, 200);
        break;
      }
    }
    if (ok)
    {
      this.chatS.updateScroll(this.scrollId, this.param)
    }
  }

  /**
   * scroll to element with id set in param
   *
   */
  goToElement(id)
  {
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  /**
   * know if message's date is today
   * param:
   * message => message i want to know
   *
   */
  checkIsntToday(message: ChatMessage, index: number)
  {
    this.today = new Date()
    if (this.param.messageList.length <= 1)
      return false

    if (index == 0)
    {
      this.oldDate = new Date(message.createdUtc)
      return true
    }

    let firstMessage = new Date(this.param.messageList[0].createdUtc)
    let secondMessage = new Date(this.param.messageList[1].createdUtc)
    if (firstMessage.getDate() == secondMessage.getDate() && index == 1)
      return false

    let messageDate = new Date(message.createdUtc)
    if (this.oldDate.getDate() == messageDate.getDate())
      return false

    this.oldDate = messageDate
    return true
  }

  /**
   * know if this message is the old message subsequently sent by the same user 
   * param:
   * message => message i want to know
   *
   */
  checkOldMessage(message: ChatMessage)
  {
    let index = this.param.messageList.findIndex(x => x.id == message.id)
    if (index > 0)
    {
      if (this.param.messageList[index - 1].creatorId == message.creatorId)
        return false
    }
    return true
  }

  messageReadListUser: ChatMessageRead[] = [];

  getLastMessageReadList(discussionId: number)
  {
    this.chatS.getLastMessageReadList(discussionId).subscribe(x =>
    {
      this.messageReadListUser = x;
    });
  }

  getLastPrivateMessageReadList(receiverId: number)
  {
    this.chatS.getLastPrivateMessageReadList(receiverId).subscribe(x =>
    {
      this.messageReadListUser = x;
    });
  }

  /**
   * check if message is read
   * param:
   * message => message i want to know
   *
   */
  checkRead(message)
  {
    if (message.creatorId == this.you.id && message.id == this.param.messageList[this.param.messageList.length - 1].id && this.param.isPrivateDiscussion)
      return true
    return false
  }

  checkNumberUserHaveRead(messageId: number)
  {
    let num = 0
    this.messageReadListUser.forEach(x =>
    {
      if (x.messageId == messageId)
      {
        num++;
      }
    })
    if (num > 3)
      return true;
    return false;
  }

  getNumberUserHaveRead(messageId: number): string
  {
    let num = 0
    this.messageReadListUser.forEach(x =>
    {
      if (x.messageId == messageId)
      {
        num++;
      }
    })

    num -= 3
    return '+' + num
  }

  /**
   * called when mouse scrolling
   *
   */
  onWindowScroll()
  {
    let pos = document.getElementById(this.scrollId).scrollTop;
    if (pos == 0 && this.param.dataLoaded)
    {
      this.onScrollUp()
    }
  }

  /**
   * called when i'm on the top of page. get old messages
   *
   */
  onScrollUp()
  {

    if (this.noMoreOldMessage)
      return;
    else if (this.param.messageList.length < 25)
    {
      this.noMoreOldMessage = true
      return;
    }
    if (!this.param.isPrivateDiscussion)
    {
      this.chatS.getMessages(this.start, this.number, this.param.paramId).subscribe(x =>
      {
        var preScrollHeight = this.viewportRef.nativeElement.scrollHeight
        x.reverse()

        x.forEach(element =>
        {
          this.param.messageList.unshift(element);
        });

        this.changeDetectionRef.detectChanges();

        var postScrollOffset = this.viewportRef.nativeElement.scrollTop
        var postScrollHeight = this.viewportRef.nativeElement.scrollHeight

        var deltaHeight = (postScrollHeight - preScrollHeight);
        this.setScrollTop(postScrollOffset, deltaHeight);


        if (x.length < 25)
          this.noMoreOldMessage = true
        this.start += this.number

      })
    }
    else
    {
      this.chatS.getPrivateMessages(this.start, this.number, this.param.paramId).subscribe(x =>
      {
        var preScrollHeight = this.viewportRef.nativeElement.scrollHeight
        x.reverse()

        x.forEach(element =>
        {
          this.param.messageList.unshift(element);
        });

        this.changeDetectionRef.detectChanges();

        var postScrollOffset = this.viewportRef.nativeElement.scrollTop
        var postScrollHeight = this.viewportRef.nativeElement.scrollHeight

        var deltaHeight = (postScrollHeight - preScrollHeight);
        this.setScrollTop(postScrollOffset, deltaHeight);

        if (x.length < 25)
          this.noMoreOldMessage = true
        this.start += this.number

      })
    }
  }

  /**
   * called when old messages are loaded to prevent the page from moving
   *
   */
  private setScrollTop(currentScrollTop: number, delta: number): void
  {

    this.viewportRef.nativeElement.scrollTop = (currentScrollTop + delta);

    window.scrollBy(0, delta);

  }

  /**
   * to open the ngAnt right click
   *
   */
  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void
  {
    this.nzContextMenuService.create($event, menu);
  }

  /**
   * to close the ngAnt right click
   *
   */
  closeMenu(): void
  {
    this.nzContextMenuService.close();
  }

  /**
   * display the message to reply
   * @message message to reply
   *
   */
  replyMessage(message)
  {
    this.chatS.messageToReply = message
    this.displayReply = true
    setTimeout(() =>
    {
      this.chatS.updateScroll(this.scrollId, this.param)
    }, 100);
  }

  /**
   * markMessage
   * @messageId id of message to mark
   */
  markMessage(messageId)
  {
    this.chatS.markMessage(messageId).subscribe(m =>
    {

      let index = this.param.messageList.findIndex(mess => mess.id == messageId)
      if (index != -1)
      {
        if (m != -1)
          this.param.messageList[index].mark = true;
        else
          this.param.messageList[index].mark = false;
      }
    })
  }

  /**
   * cancel reply
   *
   */
  loseReply()
  {
    this.chatS.messageToReply = null
    this.displayReply = false
  }

  /**
   * change content of messsage
   * param:
   * message => message to edit
   *
   */
  editMessage(message: ChatMessage)
  {
    this.oldMessageToEdit = message
    this.messageToEdit = message;
    this.editing = true
    let elem = 'id' + message.id
    var div = document.getElementById(elem);
    setTimeout(function ()
    {
      div.focus();
    }, 10);
  }

  /**
   * reset edit var
   *
   */
  exitEdit()
  {
    this.messageToEdit = new ChatMessage
    this.editing = false
    this.oldMessageToEdit = new ChatMessage
  }

  /**
   * cancel edit
   *
   */
  annulEdit(message: ChatMessage)
  {
    document.getElementById('id' + message.id).innerHTML = this.oldMessageToEdit.message

    let index = this.param.messageList.findIndex(x => x.id == message.id)
    this.param.messageList[index].message = this.oldMessageToEdit.message
    this.messageToEdit = new ChatMessage
    this.editing = false
    this.oldMessageToEdit = new ChatMessage
  }

  /**
   * save edited message
   * param:
   * message => message to save
   *
   */
  getContentEdit(message: ChatMessage)
  {
    let elem = 'id' + message.id
    var div = document.getElementById(elem);
    this.messageToEdit.message = div.innerHTML
    if (!this.messageToEdit.message || this.messageToEdit.message == "<p><br/></p>")
    {
      let index = this.param.messageList.findIndex(x => x.id == message.id)
      this.param.messageList[index].message = this.oldMessageToEdit.message
    }
    else
      this.chatS.editMessage(this.messageToEdit).subscribe(x => { })
    this.exitEdit()
  }

  /**
   * get translate word in json files
   * param:
   * path => string i want to translate
   *
   */
  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }

}