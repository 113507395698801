// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchContainer {
  display: flex;
}
.searchContainer button {
  margin-left: 10px;
}

.playlist-img {
  height: 50px;
  float: right;
  margin-left: 25px;
}

.songIsPlaying {
  color: #2fc944;
}

.playplaylist-button {
  font-size: 18px;
  background-color: #2fc944;
  margin-top: 10px;
  border-color: #2fc944;
}

.ant-card-extra {
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/manage-playlist-tracks-spotify/manage-playlist-tracks-spotify.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,iBAAA;AAER;;AACA;EACI,YAAA;EACA,YAAA;EACA,iBAAA;AAEJ;;AACA;EACI,cAAA;AAEJ;;AACA;EACI,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,qBAAA;AAEJ;;AACA;EACA,qBAAA;AAEA","sourcesContent":[".searchContainer{\r\n    display: flex;\r\n    button{\r\n        margin-left: 10px;\r\n    }\r\n}\r\n.playlist-img{\r\n    height:50px;\r\n    float:right;\r\n    margin-left:25px;\r\n}\r\n\r\n.songIsPlaying{\r\n    color: #2fc944;\r\n}\r\n\r\n.playplaylist-button{\r\n    font-size:18px;\r\n    background-color:#2fc944;\r\n    margin-top:10px;\r\n    border-color: #2fc944;\r\n}\r\n\r\n.ant-card-extra{\r\npadding:0!important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
