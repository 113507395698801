import { CalendarEvent, EventColor, EventAction } from 'calendar-utils';
import { Color } from './color';
import { Reccurence } from './reccurence';
import { PresenceInfos } from '../../../kanban/entities/presence-infos';

export const BORDER_DARKENING = 30;
export const WRITTEN_DARKENING = 100;

interface RecurringEvent
{
  title: string;
  color: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
}


export class CustomEvent implements CalendarEvent, RecurringEvent
{

  id: number = 0;
  start: Date = new Date(Date.now());
  end?: Date = new Date(Date.now() + 3600000);
  title: string = "";
  color: EventColor;
  originColor?: Color;
  actions?: EventAction[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: { beforeStart?: boolean; afterEnd?: boolean; } = { beforeStart: true, afterEnd: true };
  draggable?: boolean = true;
  meta?: any;
  creatorId?: number;
  presence: PresenceInfos = new PresenceInfos();
  longEventStatus: number = 0;
  /*
  0 if not a long event
  1 if start of a long event
  2 if end of a long event
  long event is more than 2 days
  */

  isAdded?: boolean = false; // To know the difference between added and saved events
  hasToBeDuplicated?: boolean = false;

  customRule?: boolean = false;
  rrule?: Reccurence;
  reccurenceId?: number = null;
  until?: Date; //End date of the recurrence

  type_obj?: string; // Object type (Profile, music ...)
  id_obj?: number = 0; // Object ID
  name?: string; // Object name
  completed = false;
  isRecurrent?: boolean = false; // Is the event recurrent ?
  isDragged?: boolean = false; // Is the event a non-special event (can't modify its recurrence)
  temporary = false;
  location = "";

  constructor(list?: any, event?: CustomEvent)
  {



    if (event)
    {
      this.id = event.id;
      this.start = new Date(event.start);
      this.end = new Date(event.end);
      this.title = event.title;
      this.originColor = new Color(event.originColor);
      this.color = event.color;
      this.until = event.until;
      this.resizable.afterEnd = event.resizable.afterEnd;
      this.resizable.beforeStart = event.resizable.beforeStart;
      this.draggable = event.draggable;
      this.rrule = event.rrule;
      this.temporary = event.temporary;

      this.type_obj = event.type_obj;
      this.id_obj = event.id_obj;
      this.isRecurrent = event.isRecurrent;
      this.isDragged = event.isDragged;
      this.completed = event.completed;
      this.creatorId = event.creatorId;

    }

    else if (list)
    {
      this.end.setHours(this.start.getHours() + 1);
      this.title = list.find(obj => { return obj.id === this.id_obj }).name.toString();
      this.originColor = new Color(list.find(obj => { return obj.id === this.id_obj }).color);
      this.color = {
        primary: new Color(this.originColor).darken(BORDER_DARKENING).toString(),
        secondary: this.originColor.hex.hex
      }
      this.rrule.byweekday = this.start.getDay() == 0 ? 6 : this.start.getDay() - 1;
    }

  }
}