import { Mail } from "./mail";
import { PhoneNumber } from "./phone-number";

export class ThirdPartyRelationInfos
{
    idtp: number;
    name: string;
    relationTypeText: string;
    mails: Mail[] = [];
    phoneNumbers: PhoneNumber[] = [];
}