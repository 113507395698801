import { KanbanBoard } from "./Board";
import { GlobalLabel } from "./Label";
import { KanbanCategory } from "./category";
import { KanbanCard } from "./Card";

export class KanbanHomeInfos
{
    boardsAndTemplate: KanbanBoard[] = [];
    cardTemplates: KanbanCard[] = [];
    publicLabels: GlobalLabel[] = [];
    categories: KanbanCategory[] = [];
    starredBoardIds: number[] = [];
    personalBoardIds: number[] = [];
    publicBoardIds: number[] = [];
}