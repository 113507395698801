// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  height: 100%;
  background-color: gray;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11001;
}

.content {
  width: 300px;
  height: 150px;
  background-color: white;
}

#spanblocker {
  color: black;
  margin-left: 10px;
}

#icnblocker {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/page-blocker/page-blocker.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".container {\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: gray;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  z-index: 11001;\r\n}\r\n\r\n.content {\r\n  width: 300px;\r\n  height: 150px;\r\n  background-color: white;\r\n}\r\n\r\n#spanblocker {\r\n  color: black;\r\n  margin-left: 10px;\r\n}\r\n\r\n#icnblocker {\r\n  font-size: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
