import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { EN_ERROR_PAYLOAD } from '../../const/localized/common/en-error_payload';
import { LocalizedMessage } from '../../entities/localized-message';
import { SiteBlockerService } from '../site-blocker.service';
import { SpotifyService } from 'src/app/spotify/services/spotify.service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService
{

  constructor(private router: Router, private auth: AuthService, private modalService: NzModalService, private blockService: SiteBlockerService,
    private spotifyService: SpotifyService,
    private translateService: TranslateService) { }

  checkAliveOn = false;
  modalOpened = false;
  handleError<T>(error: any)
  {
    console.log(error)
    if (this.modalOpened)
      return;
    if (error instanceof HttpErrorResponse)
    {
      console.log(error.error)
      switch (error.status)
      {
        case 401:
          this.unauthorized();
          break;
        case 403:
          this.forbidden(error);
          break;
        case 400:
          this.badRequest(error);
          break;
        case 500:
          this.internalServerError(error);
          break;
        case 440:
          this.timeoutInactivity(error);
          break;
        case 503:
          this.moduleUnavailable(error);
          break;
        default:
          this.serverUnavailable();
      }
    }
  }

  moduleUnavailable(error)
  {
    if (this.modalOpened)
      return;
    this.modalOpened = true;
    const modal = this.modalService.create({
      nzTitle: "Module unavailable",
      nzContent: this.getMessage(error.error, "Module unavailable."),
      nzFooter: [
        {
          label: 'OK',
          onClick: () => { modal.close(); this.modalOpened = false; },
          type: 'primary',
        }
      ],
      nzOnCancel: () => { this.modalOpened = false; }
    });
  }

  timeoutInactivity(error: HttpErrorResponse)
  {

    let title = "";
    this.translateService.get("INACTIVITY.TITLE").subscribe(x => title = x);
    let content = "";
    this.translateService.get("INACTIVITY.CONTENT").subscribe(x => content = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: content,
      nzFooter: [
        {
          label: confirm,
          onClick: () =>
          {
            modal.close();
          },
          type: 'primary',
        }
      ]
    });
    modal.afterClose.subscribe(() => { this.modalService.closeAll(); this.modalOpened = false; this.auth.logout(false) });
  }

  handleErrorSpotify<T>(error: any)
  {
    if (this.modalOpened)
      return;
    if (error instanceof HttpErrorResponse)
    {
      console.log(error);
      switch (error.status)
      {
        case 401:
          this.unauthorizedSpotify();
          break;
        case 400:
          this.badRequest(error);
          break;
      }
    }
  }
  unauthorizedSpotify()
  {
    console.log("refreshing token");
    if (this.spotifyService.Token)
    {
      this.spotifyService.getSpotifyAccessToken("").subscribe(x =>
      {
        console.log("token refreshed");
        this.spotifyService.Token = x;
      });
    }
  }

  getMessage(localizedMsg: any, genericMsg: string): string
  {
    let temp: LocalizedMessage = new LocalizedMessage();
    if (localizedMsg == null)
    {
      return genericMsg;
    }
    if (this.auth.Access)
    {
      switch (this.auth.Access.locale)
      {
        case "en":
          temp = EN_ERROR_PAYLOAD.find(x => x.id == localizedMsg.id);
          if (!temp && localizedMsg)
            return localizedMsg;
          break;
        default:
          if (localizedMsg.message)
            temp.message = localizedMsg.message;
          else temp.message = localizedMsg;
          break;
      }
    }
    else
    {
      if (localizedMsg.message)
        temp.message = localizedMsg.message;
      else temp.message = localizedMsg;
    }


    if (temp === undefined)
    {
      if (localizedMsg.message == null)
        return genericMsg;
      return localizedMsg.message;
    }

    return temp.message;
  }

  unauthorized()
  {
    if (this.auth.Token && location.href.indexOf("facturation") < 0)
    {
      this.auth.logout(false);
      this.router.navigate(['/']);
    }
  }
  checkalive = false;
  serverUnavailable()
  {


  }

  badRequest(error)
  {
    if (this.modalOpened)
      return;
    this.modalOpened = true;
    const modal = this.modalService.create({
      nzTitle: "Bad Request",
      nzContent: this.getMessage(error.error, "Bad request."),
      nzFooter: [
        {
          label: 'OK',
          onClick: () => { modal.close(); this.modalOpened = false; },
          type: 'primary',
        }
      ],
      nzOnCancel: () => { this.modalOpened = false; }
    });
  }

  internalServerError(error)
  {
    if (this.modalOpened)
      return;
    this.modalOpened = true;
    const modal = this.modalService.create({
      nzTitle: "Server Error",
      nzContent: this.getMessage(error.error, "A server error occured."),
      nzFooter: [
        {
          label: 'OK',
          onClick: () => { modal.close(); this.modalOpened = false; },
          type: 'primary',
        }
      ],
      nzOnCancel: () => { this.modalOpened = false; }
    });
  }

  forbidden(error)
  {
    if (this.modalOpened)
      return;
    this.modalOpened = true;
    if (error.error == null)
      this.auth.logout(false);
    const modal = this.modalService.create({
      nzTitle: "Access Denied",
      nzContent: this.getMessage(error.error, "Access forbidden."),
      nzFooter: [
        {
          label: 'OK',
          onClick: () => { modal.close(); this.modalOpened = false; },
          type: 'primary'
        }
      ],
      nzOnCancel: () => { this.modalOpened = false; }
    });
  }
}
