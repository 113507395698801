import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ChangePasswordComponent } from './change-password.component';

const routes: Routes = [
  {
    path:'',
    component: ChangePasswordComponent
  }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class ChangePasswordRoutingModule { }
