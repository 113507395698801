import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Reservation } from '../entities/reservation';
import { UrlApiService } from '../../../../src/app/services/url-api.service';
import { tap } from 'rxjs/operators';
import { ReservationResource } from '../entities/resource';
import { colors, Color } from '../../../../src/app/SDICalendar/calendar/entities/color';
import { ResourceService } from './resource.service';
import { CustomEvent, BORDER_DARKENING } from '../../SDICalendar/calendar/entities/event';
import { EventService } from '../../../../src/app/SDICalendar/calendar/services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesComponent } from '../components/resources/resources.component';
import { SettingsService } from '../../../../src/app/settings/services/settings.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ReservationService
{

  reservations: Reservation[] = [];
  isRecurrent = false;
  serverActionOngoing = false;
  constructor(private http: HttpClient,
    private eventService: EventService,
    private modalService: NzModalService,
    private settingsService: SettingsService,
    private resService: ResourceService) { }

  getAllReservations()
  {
    this.serverActionOngoing = true;
    return this.http.get<Reservation[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/reservations').pipe(tap(x => 
    {

      this.reservations = x;
      //this.eventService._eventList = [];
      x.forEach(y =>
      {
        let event = new CustomEvent();
        event.id = y.event.id;
        event.id_obj = y.resource.id;
        event.start = new Date(y.event.start);
        //event.start = new Date(Date.UTC(y.event.start.getFullYear(), y.event.start.getMonth(), y.event.start.getDate(), y.event.start.getHours(), y.event.start.getMinutes(), y.event.start.getSeconds(), y.event.start.getMilliseconds()));
        event.end = new Date(y.event.end)
        //event.end = new Date(Date.UTC(y.event.end.getFullYear(), y.event.end.getMonth(), y.event.end.getDate(), y.event.end.getHours(), y.event.end.getMinutes(), y.event.end.getSeconds(), y.event.end.getMilliseconds()));
        event.title = this.getListForCalendar().find(obj => { return obj.id === y.resource.id }).name.toString();
        let date = y.event.until ? new Date(y.event.until) : null;
        /*
        if(date)
          date.setDate(date.getDate() -1);
          */
        event.until = date;

        event.originColor = new Color(this.getListForCalendar().find(obj => { return obj.id === y.resource.id }).color);
        event.draggable = true;
        event.rrule = y.event.rrule;
        event.isRecurrent = y.event.rrule ? true : false;
        event.resizable = { beforeStart: true, afterEnd: true };
        event.color = {
          primary: new Color(event.originColor).darken(BORDER_DARKENING).toString(),
          secondary: event.originColor.toString()
        }
        //this.eventService._eventList.push(event);
      })
      this.eventService.updateEventSubject.next(null);
      this.serverActionOngoing = false;

    }));
  }

  convertDateToUTC(date) { return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds())); }

  createReservation(reservation: Reservation)
  {
    this.serverActionOngoing = true;

    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/reservations', reservation);
  }

  removeReservation(idreservation: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + '/api/reservations/' + idreservation);
  }

  getListForCalendar()
  {
    return this.resService.resources;
  }

  createEvent(event: CustomEvent)
  {
    let createdEvent = new CustomEvent();
    if (!event.isRecurrent)
      createdEvent.rrule = null;
    createdEvent.start = event.start
    createdEvent.end = event.end;
    createdEvent.rrule = event.rrule;
    if (event.until)
    {
      event.until.setDate(event.until.getDate() - 1);
      createdEvent.until = event.until;
      if (event.until < event.start)
        return event;
    }
    let res = new Reservation();
    res.event = createdEvent;
    res.resource = this.resService.resources.find(x => x.id == event.id_obj);
    this.createReservation(res).subscribe(x => this.getAllReservations().subscribe());
    return event;
  }
  deleteEvent(event: CustomEvent)
  {
    let reservation = this.reservations.find(x => x.event.id == event.id && x.resource.id == event.id_obj);
    this.removeReservation(reservation.id).subscribe(x => this.getAllReservations().subscribe())
  }

  saveOverlapSettings(newValue: string)
  {
    this.settingsService.setSettingsByName("CalendarOverlapSetting", newValue);
  }

  getOverlapSettings()
  {
    return this.settingsService.getSettingsByName("CalendarOverlapSetting");
  }

  goToRessources()
  {
    const modal = this.modalService.create({
      nzTitle: "",
      nzContent: ResourcesComponent,
      nzWidth: '1000px',
      nzMaskClosable: true,
      nzFooter: null
    });
  }
}
