import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlannedAction, PlannedActionFilters } from '../entities/date-scheduling-parameter';
import { UrlApiService } from './url-api.service';

@Injectable({
  providedIn: 'root'
})
export class PlannedEventsService
{

  constructor(private http: HttpClient) { }

  getPlannedActions(plannedActionFilters: PlannedActionFilters)
  {
    return this.http.post<PlannedAction[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/planned-actions/search`, plannedActionFilters)
  }

  createPlannedAction(plannedAction: PlannedAction)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/planned-actions`, plannedAction)
  }

  reprogramPlannedAction(plannedAction: PlannedAction)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/planned-actions/reprogram`, plannedAction)
  }

  markPlannedActionAsComplete(id: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/planned-actions/reprogram/${id}/mark-completed`, null)
  }

  deletePlannedAction(id: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/planned-actions/reprogram/${id}`)
  }

}
