import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService
{

    constructor() { }

    getFileSizeString(length: number)
    {
        if (length < 1000)
        {
            return length + " octets";
        }
        else if (length < 1000000)
        {
            let size = (length / 1000) + "";
            return (size.length >= 3 ? size.substring(0, 3) : size) + " Ko";
        }
        else
        {
            let size = (length / 1000000) + "";
            return (size.length >= 3 ? size.substring(0, 3) : size) + " Mo";
        }
    }

}
