// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-icon {
  float: right;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/third-party/moral-person/components/open-edit-moral-person/open-edit-moral-person.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".site-icon{\r\n    float: right;\r\n    font-size: 16px;\r\n    margin-left: 5px;\r\n    cursor: pointer;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
