import { Injectable } from '@angular/core';
import { ReservationResource } from 'src/app/reservation/entities/resource';
import { HubConnection } from '@microsoft/signalr';
import { ResourceService } from 'src/app/reservation/services/resource.service';
import { ReservationService } from 'src/app/reservation/services/reservation.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceNotificationService
{

  constructor(private resourceService: ResourceService,
    private reservationService: ReservationService) { }
  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('UpdateResource', (resource: ReservationResource) =>
    {
      let res = this.resourceService.resources.find(x => x.id == resource.id);
      if (!res)
        return;

      res.name = resource.name;
      res.description = resource.description;
      res.color = resource.color;
      if (this.reservationService.reservations.length > 0)
        this.reservationService.getAllReservations().subscribe()
    });

    _hubConnection.on('DeleteResource', (idresource: number) =>
    {
      let index = this.resourceService.resources.findIndex(x => x.id == idresource);
      if (index < 0)
        return;
      this.resourceService.resources.splice(index, 1)
      if (this.reservationService.reservations.length > 0)
        this.reservationService.getAllReservations().subscribe()
    });
  }
}
