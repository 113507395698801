export class Reccurence
{
    id: number;
    freq: any;
    interval: number;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
    count?: number;
    bysetpos?: number;
    end?: Date;
}