import { Component, OnInit, Input } from '@angular/core';
import { CredentialsSeePasswordService } from '../../services/data/credentials-see-password.service';
import { Credentials } from '../../entities/credentials';
import { CredentialsDataService } from '../../services/data/credentials-data.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpSetting } from '../../../../../src/app/entities/help-setting';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-see-password',
  templateUrl: './see-password.component.html',
  styleUrls: ['./see-password.component.scss']
})
export class SeePasswordComponent implements OnInit
{

  @Input() idcred: number;
  showAlertHost = false;
  cred: Credentials = new Credentials();
  constructor(private message: NzMessageService,
    private credService: CredentialsDataService,
    private translateService: TranslateService) { }

  ngOnInit()
  {
    this.credService.getPassword(this.idcred).subscribe(x => this.cred = x);
  }

  getUrl(url: string)
  {
    if (!url.startsWith("http://") && !url.startsWith("https://"))
      return "https://" + url;
    return url;
  }

  saveShowAlert(type: string, show: boolean)
  {
    let helpSetting = new HelpSetting();
    helpSetting.type = type;
    if (show)
      helpSetting.value = false;
    else
      helpSetting.value = true;
    switch (type)
    {
      case "CREDENTIALS.CREATE.HOST":
        this.showAlertHost = helpSetting.value;
        break;
    }

  }

  copyText(text)
  {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let t = "";
    this.translateService.get("GENERIC-ACTIONS.COPIED-TO-CLIPBOARD").subscribe(x => t = x)
    this.message.success(t);
  }
  submitForm()
  {

  }
}
