import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { MessagerieService } from '../../../messagerie/messagerie.service';
import { PlanningService } from '../../../planning/planning.service';
import { CalendarOccurence } from '../../../SDICalendar/calendar/entities/occurence';
import { EventService } from '../../../SDICalendar/calendar/services/event.service';
import { CacheDataService } from '../../../services/cache-data.service';
import { KanbanAttachment } from '../../entities/Attachment';
import { KanbanBoard } from '../../entities/Board';
import { KanbanCard } from '../../entities/Card';
import { KanbanChecklist } from '../../entities/Checklist';
import { ChecklistDisplayInfos } from '../../entities/checklist-display-infos';
import { KanbanChecklistItem } from '../../entities/ChecklistItem';
import { KanbanList } from '../../entities/List';
import { KanbanEditCardService } from '../../services/kanban-edit-card.service';
import { KanbanService } from '../../services/kanban.service';
import { ChecklistTemplateManagerComponent } from '../checklist-template-manager/checklist-template-manager.component';

@Component({
  selector: 'app-kanban-card-planning',
  templateUrl: './kanban-card-planning.component.html',
  styleUrls: ['./kanban-card-planning.component.scss']
})
export class KanbanCardPlanningComponent implements OnInit
{

  @Input() card: KanbanCard = new KanbanCard();
  @Input() board: KanbanBoard = new KanbanBoard();
  @Input() list: KanbanList = new KanbanList();
  @Input() loaddata = false;
  loadPlanning = false;
  canSendMail = false;
  showBeginDate = false;
  showEndDate = false;
  checkListCreatorVisible = false;
  createdChecklistTitle = "";
  cardLoaded = false;
  constructor(public planningService: PlanningService,
    private modalService: NzModalService,
    private translate: TranslateService,
    public mss: MessagerieService,
    public eventService: EventService, public kecs: KanbanEditCardService, public kbs: KanbanService) { }

  async ngOnInit()
  {
    this.card = await firstValueFrom(this.kbs.getCardInfos(this.card.id));

    this.cardLoaded = true;
    if (this.kecs.showValue == 0 && this.card.checklists.length > 0)
      this.kecs.showValue = 1;
    if (this.board)
    {
      let temp = this.board.lists.map(x => x.id);
      // if (location.href.indexOf("planning") >= 0)
      // {
      //   this.planningService.filters.listIds = temp;
      //   this.eventService.ressourceFilter = temp;
      //   this.planningService.updateFilterPreferences().subscribe();
      // }
      this.loadPlanning = true;
    }
    else this.loadPlanning = true;
  }

  updateCanSendMail(cansend: boolean)
  {
    this.canSendMail = cansend;
  }

  openCheckistTemplates()
  {
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let confirm = "";
    this.translate.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let modal = this.modalService.create({
      nzTitle: null,
      nzContent: ChecklistTemplateManagerComponent,
      nzWidth: '80%',
      nzMaskClosable: false,
      nzFooter: [{
        label: confirm,
        type: "primary",
        onClick: async (result) =>
        {
          let checklist = new KanbanChecklist();
          checklist.title = result.checklistTitle;
          checklist.id = await firstValueFrom(this.kbs.addChecklistToCard(this.card.id, checklist))
          this.card.checklists.push(checklist);
          let displayinfos = new ChecklistDisplayInfos();
          displayinfos.showDateBegin = this.showBeginDate;
          displayinfos.showDateEnd = this.showEndDate;
          displayinfos.checklistId = checklist.id;
          this.kbs.checklistsDisplayInfos.push(displayinfos);
          this.kbs.updateCardChecklistDisplayInfos(this.card.id, displayinfos).subscribe();
          this.createdChecklistTitle = "";

          for (let item of result.selectedTemplate.checklist.items)
          {
            let newitem = new KanbanChecklistItem();
            newitem.title = item.title;
            newitem.dateBegin = item.dateBegin;
            newitem.dateEnd = item.dateEnd;
            newitem.done = item.done;
            newitem.id = await firstValueFrom(this.kbs.addItemToChecklist(this.card.id, checklist.id, newitem));
            checklist.items.push(newitem);
          }

          this.checkListCreatorVisible = false;
          modal.close()
        }
      },
      {
        label: close,
        type: "default",
        onClick: (result) =>
        {
          modal.close()
        }
      }]
    });
  }

  createQuickChecklist()
  {
    if (this.card.checklists.length > 0)
      return;
    event.preventDefault();
    this.createdChecklistTitle = "To-do";
    this.createChecklist();
  }

  createChecklist()
  {
    let checklist = new KanbanChecklist();
    checklist.title = this.createdChecklistTitle;
    this.kbs.addChecklistToCard(this.card.id, checklist).subscribe(x =>
    {
      checklist.id = x;
      this.card.checklists.push(checklist);
      let displayinfos = new ChecklistDisplayInfos();
      displayinfos.showDateBegin = this.showBeginDate;
      displayinfos.showDateEnd = this.showEndDate;
      displayinfos.checklistId = x;
      this.kbs.checklistsDisplayInfos.push(displayinfos);
      this.kbs.updateCardChecklistDisplayInfos(this.card.id, displayinfos).subscribe();
      this.createdChecklistTitle = "";
      this.checkListCreatorVisible = false;
    });
  }

  sendMail()
  {
    this.kecs.sendMail.next(null);
  }

  cancelActionsModifications()
  {
    let titleConfirm = "";
    this.translate.get("KANBAN.ACTION-CONFIRM-CLOSE-TITLE").subscribe(x => titleConfirm = x);
    let contentConfirm = "";
    this.translate.get("KANBAN.ACTION-CONFIRM-CLOSE-CONTENT").subscribe(x => contentConfirm = x);
    let close = "";
    this.translate.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let cancel = "";
    this.translate.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let confirmModal = this.modalService.confirm({
      nzTitle: titleConfirm,
      nzContent: contentConfirm,
      nzCancelText: cancel,
      nzOkText: close,
      nzOnOk: () =>
      {
        this.kecs.showValue = 0;
        setTimeout(() =>
        {
          this.kecs.showValue = 6;
        }, 500);
        confirmModal.destroy();
      }

    });
  }

  saveActions()
  {
    this.kbs.updateListActionsOfCard(this.card.id, this.kbs.actionsControls).subscribe(x =>
    {
      this.card.actions = this.kbs.actionsControls;
    })
  }

  updateAttachmentsPictures(attachment: KanbanAttachment[])
  {
    this.card.attachments = attachment;
  }

  drop(event: CdkDragDrop<string[]>): void
  {
    moveItemInArray(this.card.checklists, event.previousIndex, event.currentIndex);
    setTimeout(() =>
    {
      this.kbs.moveChecklist(this.card.id, this.card.checklists.map(({ id }) => id)).subscribe();
    }, 300);
  }

}
