import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { registerLocaleData, DatePipe } from '@angular/common';
import { AuthService } from './services/auth.service';
import { httpInterceptorProviders } from './services/interceptors';
import { SignalrService } from './services/signalr.service';
import { UrlApiService } from './services/url-api.service';
import { SiteBlockerService } from './services/site-blocker.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScriptService } from './scripts/script.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PasswordStrategyService } from './services/password-strategy.service';
import { DateService } from './services/date.service';
import { BlackListIpService } from './ip-address/services/black-list-ip.service';
import { IpSettingsService } from './ip-address/services/Ip-settings.service';
import { UserSettingsService } from './ip-address/services/user-settings.service';
import { QuillModule } from 'ngx-quill';
import { BrowserModule } from '@angular/platform-browser';
import { LogoModule } from './components/logo/logo.module';
import { MenuModule } from './components/menu/menu.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { LoginModule } from './components/login/login.module';
import { ChangePasswordModule } from './components/change-password/change-password.module';
import { PageBlockerModule } from './components/page-blocker/page-blocker.module';
import { MailEditorModule } from './components/mail-editor/mail-editor.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { KanbanCardEditModule } from './kanban/components/kanban-card-edit/kanban-card-edit.module';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeDe from '@angular/common/locales/de';
import { DayEventsModule } from './planning/day-events/day-events.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FloatingChatModule } from './components/floating-chat/floating-chat.module';
import { FloatingChatTitleModule } from './components/floating-chat/floating-chat-title/floating-chat-title/floating-chat-title.module';
import { ThirdPartySheetModule } from './call-interface/third-party-sheet/third-party-sheet.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NzConfig } from 'ng-zorro-antd/core/config';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { KanbanModule } from './kanban/kanban.module';
import { KanbanCardPlanningModule } from './kanban/components/kanban-card-planning/kanban-card-planning.module';
import { KANBAN_ACTION_MANAGER } from './call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { KanbanActionsManagerComponent } from './kanban/components/kanban-actions-manager/kanban-actions-manager.component';
import { ZoomModule } from './zoom/zoom.module';
import { MessagerieImportProgressModule } from './messagerie/messagerie-import-progress/messagerie-import-progress.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeDe);
export function initializeApp(appconfig: UrlApiService)
{
  return () => appconfig.load();
}

export function createTranslateLoader(http: HttpClient)
{
  return new TranslateHttpLoader(http, './assets/localized/', '.json?rand=' + Math.random());
}

const ngZorroConfig: NzConfig = {
  modal: {
    nzCloseOnNavigation: true
  },
  notification: {}
};



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NzModalModule,
    NzNotificationModule,
    NzMessageModule,
    NzToolTipModule,
    NzLayoutModule,
    LogoModule,
    MenuModule,
    NavbarModule,
    LoginModule,
    NzIconModule,
    ChangePasswordModule,
    PageBlockerModule,
    MessagerieImportProgressModule,
    MailEditorModule,
    NzAlertModule,
    NgxGraphModule,
    DayEventsModule,
    KanbanCardEditModule,
    ThirdPartySheetModule,
    KanbanCardPlanningModule,
    DragDropModule,
    NzButtonModule,
    NzPopoverModule,
    FloatingChatModule,
    ZoomModule,
    FloatingChatTitleModule,
    NzBadgeModule,
    KanbanModule,
    NgxExtendedPdfViewerModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [DatePipe, { provide: NZ_I18N, useValue: localeFr }, AuthService, SignalrService, PasswordStrategyService, BlackListIpService, IpSettingsService, UserSettingsService, DateService, httpInterceptorProviders, SiteBlockerService, UrlApiService, ScriptService, {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [UrlApiService], multi: true
  },
    {
      provide: KANBAN_ACTION_MANAGER, useValue: KanbanActionsManagerComponent,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }