import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ChatDiscussion } from '../chat/entities/chat-discussion';
import { ChatParam } from '../chat/entities/chat-param';
import { SettingsService } from '../settings/services/settings.service';
import { User } from '../user/model/user';

@Injectable({
    providedIn: 'root'
})
export class FloatingChatService
{

    constructor(
        private translateService: TranslateService,
        private settingsService: SettingsService,
    ) { }

    navigation = "/"
    groupList: ChatDiscussion[] = []
    discussionList: User[] = []
    discussionListAvailable: User[] = []
    title = ""
    displayDiscussion = false
    openDiscussionId: number = null
    ChatEditorFSub: Subject<number> = new Subject<number>();
    switchNotifDiscussionSub: Subject<void> = new Subject<void>();

    param: ChatParam = new ChatParam()

    chatTemplate = "ChatContentTemplate"

    /*
    // navigate forward in the menu
    // param:
    // destination => the destination to go 
    */
    navigate(destination: string)
    {
        console.log(destination)
        if (destination == "Room")
            this.translateService.get("CHAT.GROUPS").subscribe(x => this.title = x);
        else if (destination == "Discussion")
            this.translateService.get("CHAT.MEMBERS").subscribe(x => this.title = x);
        else
            this.title = destination.trim().replace(' ', '-')
        let dest = destination.trim().replace(' ', '-')
        // if (this.navigation != '/')
        //     this.navigation += '/'
        this.navigation = dest

        let url = dest + '|' + this.openDiscussionId

        this.settingsService.setSettingsByName("LastFloatingChatOpenDiscussion", url);
    }

    /*
    // navigate back in the menu
    //
    */
    navigateBack()
    {
        this.openDiscussionId = null
        this.displayDiscussion = false
        if (this.navigation == "/")
        {
            let url = this.navigation + '|null'
            this.settingsService.setSettingsByName("LastFloatingChatOpenDiscussion", url);
            return
        }
        this.translateService.get("CHAT.TITLE").subscribe(x => this.title = x);
        this.navigation = "/"
        let url = this.navigation + '|null'
        this.settingsService.setSettingsByName("LastFloatingChatOpenDiscussion", url);
    }

    dragPosition = { x: 0, y: 0 };
    oldHeightPositionPourCent: number = 1
    oldWidthPositionPourCent: number = 20
    displayChat = false

    /*
    // set position of floating chat
    //
    */
    setPosition()
    {
        if (this.oldWidthPositionPourCent < 0 || this.oldWidthPositionPourCent > window.innerWidth || this.oldHeightPositionPourCent < 0 || this.oldHeightPositionPourCent > window.innerHeight)
        {
            this.oldHeightPositionPourCent = 1
            this.oldWidthPositionPourCent = 20
        }

        this.dragPosition = { x: this.oldWidthPositionPourCent, y: this.oldHeightPositionPourCent };
        this.displayChat = true
    }

    /*
    // get the last save position of floating chat
    //
    */
    getSavePosition()
    {
        this.settingsService.getSettingsByName("DragMenuPosition").subscribe(x =>
        {
            if (x)
            {
                let pos = x.split('|');

                var width: any = pos[0];
                var height: any = pos[1];

                this.oldWidthPositionPourCent = width * window.innerWidth / 100;
                this.oldHeightPositionPourCent = height * window.innerHeight / 100;
            }
            this.setPosition();
        });
    }

}
