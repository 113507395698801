import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaylistSpotifyComponent } from './playlist-spotify.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

@NgModule({
  imports: [
    CommonModule,
    NzCardModule,
    NzAvatarModule
  ],
  declarations: [PlaylistSpotifyComponent],
  exports: [PlaylistSpotifyComponent]
})
export class PlaylistSpotifyModule { }
