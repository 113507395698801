import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-playlist-spotify',
  templateUrl: './create-playlist-spotify.component.html',
  styleUrls: ['./create-playlist-spotify.component.scss']
})
export class CreatePlaylistSpotifyComponent implements OnInit {

  playlistName: string ="";
  playlistDescription: string ="";
  constructor() { }

  ngOnInit() {
  }

}
