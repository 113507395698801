import { SpotifyUser } from "./SpotifyUser";
import { Track } from "./Track";

export class PlaylistTrack
{
    added_at: Date;
    added_by: SpotifyUser;
    is_local: boolean;
    track: Track;

    constructor(track: Track)
    {
        this.track = track;
        this.added_at = new Date();
    }
}