// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participants-title {
  font-weight: bold;
  text-align: center;
}

.remove-participant-icon {
  margin-left: 10px;
  color: blue;
  cursor: pointer;
}

.reminder {
  display: inline-block;
  margin-right: 10px;
  margin-left: 5px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.mail-selection {
  background-color: white;
  cursor: pointer;
}

.mail-selection:hover {
  background-color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/app/zoom/zoom-meeting-scheduler/zoom-meeting-scheduler.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;EACA,eAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".participants-title {\r\n  font-weight: bold;\r\n  text-align: center;\r\n}\r\n\r\n.remove-participant-icon {\r\n  margin-left: 10px;\r\n  color: blue;\r\n  cursor: pointer;\r\n}\r\n\r\n.reminder {\r\n  display: inline-block;\r\n  margin-right: 10px;\r\n  margin-left: 5px;\r\n  border: 1px solid black;\r\n  border-radius: 5px;\r\n  padding: 5px;\r\n}\r\n\r\n.mail-selection {\r\n  background-color: white;\r\n  cursor: pointer;\r\n}\r\n\r\n.mail-selection:hover {\r\n  background-color: lightgray;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
