import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { Receivedmail } from '../../../messagerie/entities/receivedmail';
import { MessagerieMailViewerComponent } from '../../../messagerie/messagerie-mail-viewer/messagerie-mail-viewer.component';
import { MessagerieService } from '../../../messagerie/messagerie.service';
import { KanbanCard } from '../../entities/Card';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-card-mails',
  templateUrl: './kanban-card-mails.component.html',
  styleUrls: ['./kanban-card-mails.component.scss']
})
export class KanbanCardMailsComponent implements OnInit
{

  @Input() card: KanbanCard = new KanbanCard();

  constructor(public mss: MessagerieService,
    private modalService: NzModalService,
    private translateService: TranslateService) { }

  getMailFrom(mail: Receivedmail)
  {
    if (mail.pseudo)
      return mail.pseudo;
    else return mail.from;
  }

  getMailTo(mail: Receivedmail)
  {
    let mailto = "";
    for (let to of mail.to)
    {
      mailto += to + ", ";
    }
    if (mail.to.length > 0)
      mailto = mailto.slice(0, mailto.length - 2)
    return mailto;
  }

  ngOnInit()
  {

  }

  async viewMail(idmail: number)
  {
    event.stopImmediatePropagation();
    //event.preventDefault();

    let mailinfos = await firstValueFrom(this.mss.getMailById(idmail));

    let themail = mailinfos.mail;
    themail.content = mailinfos.mailContent;
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzWidth: "80%",
      nzBodyStyle: { height: "85vh" },
      nzTitle: null,
      nzContent: MessagerieMailViewerComponent,
      nzOnCancel: () =>
      {
        modal.close();
      },
      nzFooter: [{
        label: close,
        onClick: () =>
        {
          modal.close();
        }
      }]
    })
    modal.componentInstance.mail = themail;
  }

}
