import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanMoveListComponent } from './kanban-move-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  imports: [
    CommonModule,
    NzInputModule,
    NzIconModule,
    NzTreeModule,
    TranslateModule,
    NzDividerModule,
    NzButtonModule,
    FormsModule
  ],
  declarations: [KanbanMoveListComponent],
  exports: [KanbanMoveListComponent]
})
export class KanbanMoveListModule { }
