import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardCommentComponent } from './kanban-card-comment.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TranslateModule } from '@ngx-translate/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { KanbanActionsManagerModule } from '../kanban-actions-manager/kanban-actions-manager.module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    TranslateModule,
    NzAvatarModule,
    FormsModule,
    PipesModule,
    NzButtonModule,
    NzPopoverModule,
    KanbanActionsManagerModule,
    NzPopconfirmModule,
    NzInputModule,
    NzIconModule
  ],
  declarations: [KanbanCardCommentComponent],
  exports: [KanbanCardCommentComponent]
})
export class KanbanCardCommentModule { }
