import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CacheDataService } from '../../../services/cache-data.service';
import { KanbanActivity } from '../../entities/Activity';
import { KanbanBoardActivitiesSearchParameters } from '../../entities/kanban-board-activities-search-parameters';
import { KanbanService } from '../../services/kanban.service';

@Component({
  selector: 'app-kanban-board-activities',
  templateUrl: './kanban-board-activities.component.html',
  styleUrls: ['./kanban-board-activities.component.scss']
})
export class KanbanBoardActivitiesComponent implements OnInit
{

  constructor(public cds: CacheDataService, public kbs: KanbanService,
    private translate: TranslateService) { }

  @Input() idboard: number;
  cardActivities: KanbanActivity[] = [];
  isFinished = false;
  categoriesFilterOpened = false;
  searchParameters: KanbanBoardActivitiesSearchParameters = new KanbanBoardActivitiesSearchParameters();
  ngOnInit()
  {
    this.initFilters();
    this.getDatas();
  }

  getDatas()
  {
    if (this.isFinished)
      return;
    this.kbs.getBoardCardActivities(this.idboard, this.searchParameters).subscribe(x =>
    {
      if (x.length < this.searchParameters.number)
        this.isFinished = true;

      for (let act of x)
      {
        act.user = this.cds.userCollection.find(u => u.id == act.user.id);
        let foundcard = false;
        for (let list of this.cds.selectedBoard.lists)
        {
          for (let card of list.cards)
          {
            if (card.id == act.card.id)
            {
              foundcard = true;
              act.card = card;
              break;
            }
          }
          if (foundcard)
            break;
        }
        this.cardActivities.push(act);
      }
      this.cardActivities = [...this.cardActivities];
      this.searchParameters.start += this.searchParameters.number;
    });
  }

  timeoutrequest = null;
  searchTextUpdate()
  {
    if (this.timeoutrequest)
      clearTimeout(this.timeoutrequest);
    this.timeoutrequest = setTimeout(() =>
    {
      this.searchParameters.start = 0;
      this.cardActivities = [];
      this.isFinished = false;
      this.getDatas();
    }, 500);
  }

  initFilters()
  {
    for (let category of this.kbs.filterBoardActivities)
    {
      for (let action of category.actions)
      {
        this.searchParameters.options.push(action);
      }
    }
    this.searchParameters.start = 0;
    this.searchParameters.listIds = [];
    this.searchParameters.searchText = "";
  }

  changeFilterCheck(add: boolean, category: string)
  {
    let cat = this.kbs.filterBoardActivities.find(x => x.category == category);
    if (add && cat)
    {
      for (let action of cat.actions)
      {
        this.searchParameters.options.push(action);
      }
    }
    else if (!add && cat)
    {
      for (let action of cat.actions)
      {
        let index = this.searchParameters.options.findIndex(x => x == action);
        if (index >= 0)
          this.searchParameters.options.splice(index, 1);
      }
    }


    this.searchParameters.start = 0;
    this.cardActivities = [];
    this.isFinished = false;
    this.getDatas();
  }

  categoryIsCheck(category: string)
  {
    let cat = this.kbs.filterBoardActivities.find(x => x.category == category);
    if (!cat)
      return false;
    if (this.searchParameters.options.findIndex(x => x == cat.actions[0]) >= 0)
      return true;
    return false;
  }

  onScrollDown()
  {
    this.getDatas();
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (user)
      return user.avatar;
    return ""
  }

  onCheck(idListCollection: number[])
  {
    this.searchParameters.listIds = [...idListCollection];
    this.searchParameters.start = 0;
    this.cardActivities = [];
    this.isFinished = false;
    //this.categoriesFilterOpened = false;
    this.getDatas();
  }

  getNotificationText(activity: KanbanActivity)
  {
    let text = this.cds.getUserName(activity.user.id) + " ";
    // do sql request in prod : <update dbo.DFOKB_Activity set Parameters = null where CodeText = 'NOTIFICATION.ACTIVITY.CARTE-COMPLETE' or CodeText = 'NOTIFICATION.ACTIVITY.CARTE-NOT-COMPLETE'> if not done already
    switch (activity.parameters.length)
    {
      case 0:
        this.translate.get(activity.codeText, { v: `<${activity.card.title}>` }).subscribe(x => text += x);
        break;
      case 1:
        this.translate.get(activity.codeText, { v1: activity.parameters[0], v2: `<${activity.card.title}>` }).subscribe(x => text += x);
        break;
      case 2:
        this.translate.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1], v3: `<${activity.card.title}>` }).subscribe(x => text += x);
        break;
      case 3:
        this.translate.get(activity.codeText, { v1: activity.parameters[0], v2: activity.parameters[1], v3: activity.parameters[2], v4: `<${activity.card.title}>` }).subscribe(x => text += x);
        break;
    }

    return text;
  }

}
