import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlApiService } from '../services/url-api.service';
import { EmailToSend } from '../entities/email-to-send';
import { Subject, firstValueFrom } from 'rxjs';
import { LabelValue } from '../entities/keyvalue';
import { Receivedmail } from './entities/receivedmail';
import { MailAccount } from './entities/mail-account';
import { NotReadEmail } from './entities/not-read-email';
import { AuthService } from '../services/auth.service';
import { MailEditorComponent } from '../components/mail-editor/mail-editor.component';
import { TranslateService } from '@ngx-translate/core';
import { MessagerieSearchParameters } from './entities/messagerie-search-parameteres';
import { MessagerieFolder } from './entities/messagerie-folder';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CacheDataService } from '../services/cache-data.service';
import { MessagerieConversation } from './entities/messagerie-conversation';
import { TextSearch } from '../entities/search-text';
import { MessagerieView, MessagerieViewVirualFolder } from './entities/messagerie-view';
import { ApplyImapFolderSelection } from './entities/apply-imap-folder-selection';
import { MessagerieAccountAlias } from './entities/messagerie-account-alias';
import { TextCompareService } from '../services/text-compare.service';
import { MessagerieMailPlusContent } from './entities/messagerie-mail-plus-content';
import { MessagerieAdvancedSearchParameters } from './entities/messagerie-advanced-search-parameters';
import { KanbanAttachment } from '../kanban/entities/Attachment';
import { SentMailInfo } from './entities/sent-mail-infos';
import { MessagerieMenuInfos } from '../entities/menu-infos';
import { PlanningEvent } from '../planning/entities/planning-event';
import { KanbanCard } from '../kanban/entities/Card';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
declare function scrollHorizontally(e, id): any;
@Injectable({
  providedIn: 'root'
})
export class MessagerieService
{

  constructor(private http: HttpClient, private authService: AuthService,
    private tcs: TextCompareService,
    private cds: CacheDataService,
    private modalService: NzModalService, private msgService: NzMessageService, public translateService: TranslateService,) { }

  draggedMail: Receivedmail = null;
  draggedMailFolder: MessagerieFolder = null;
  showMailEditor = false;
  showMailsInFolders = false;
  markAsReadOnFocus = true;
  profileList: MailAccount[] = [];
  reloading = false;
  controlKeyPressed = false;
  shiftKeyPressed = false;
  scrollOnClick = true;
  selectedProfiles: number[] = [];
  initMailEditorModal: Subject<EmailToSend> = new Subject<EmailToSend>();
  pickupmailUpdate: Subject<Receivedmail[]> = new Subject<Receivedmail[]>();
  updateMailActionStatus: Subject<any> = new Subject<any>();
  reloadmails: Subject<boolean> = new Subject<boolean>();
  reloadmenu: Subject<boolean> = new Subject<boolean>();
  cardMailCodeParams: LabelValue[] = []
  myMail: Receivedmail[] = [];
  totalUnread = 0;
  disableScroll = false;
  viewList: MessagerieView[] = [];
  myConversation: MessagerieFolder[] = [];
  dateCategories: MessagerieFolder[] = [];
  openMailInd: number = -1;
  openedMail: Receivedmail = null;
  openedMailPanel: MessagerieFolder = null;
  unreadExcludedFolders: number[] = [];
  selectedUnread = false;
  selectedFolder: MessagerieFolder = null;
  selectedVirtualFolder: MessagerieViewVirualFolder = null;
  selectedView: MessagerieView = null;
  selectMailList: Receivedmail[] = [];
  menuDailyRefresh = null;
  menuDailyDate: Date = null;
  inactivityTime = 300000;
  openedCardId = 0;
  unreadInbox = 0;
  openMail = false;
  dontOpen = false;
  clearlist = false;
  everythingIsLoaded = false;
  unreadDeleted = 0;
  start: number = 1;
  number: number = 20;
  sortType = 0;
  sortDirection = "desc";
  startConversation = 0;
  conversationMode = false;
  discussionThread: MessagerieFolder[] = [];
  discussionThreadVisible = false;
  searchDiscussionThread = "";
  searchMailDiscussionThread = "";
  dateCategoriesThreads: MessagerieFolder[] = [];
  unavailableAccount: MailAccount[] = [];
  selectedDiscussionThreadID = 0;
  advancedSearchParam: MessagerieAdvancedSearchParameters = null;
  display = "horizontal";
  //------ notif -------
  progress = 0;
  texttoshow = "";
  foldername = "";
  format = (percent: number) => this.texttoshow;
  //------ notif -------

  isRefreshing = false;

  //paramettre mail
  listMailTo: string[] = [];
  codeParams: string[] = [];
  emailToInit: EmailToSend = new EmailToSend();
  modalTitle = "";
  confirmButton = ""

  bodyStyle = {
    'max-height': '90vh'
  }

  modalStyle = {
    'padding': 'unset'
  }

  emailCreated: EmailToSend = new EmailToSend();

  optionLength = 0;
  showText = true;
  setShowText()
  {
    try
    {
      let headerwidth = document.getElementById("mail-viewer-header").clientWidth;
      if (this.optionLength == 0)
      {
        let elems = document.getElementsByClassName("active-user-icon");
        for (let i = 0; i < elems.length; i++)
        {
          this.optionLength += elems.item(i).clientWidth + 18;
        }
      }

      if (headerwidth < this.optionLength)
        this.showText = false;
      else this.showText = true;
    }
    catch (e) { }
  }

  async deleteMailClient(themail: Receivedmail)
  {
    this.openMail = false;
    if (this.selectMailList.length > 0)
    {
      for (let mail of this.selectMailList)
      {
        await (this.effectiveDelete(mail))
      }
      this.selectMailList = [];
    }
    else if (themail)
      await (this.effectiveDelete(themail));
    else return;
    this.reloadmenu.next(true);

  }

  async effectiveDelete(mail: Receivedmail)
  {
    let id = this.getMailFolderId(mail)
    mail.deleted = true;

    await firstValueFrom(this.deleteMail(mail.id, id))
    this.start--;
    for (let datefolder of this.dateCategories)
    {
      let index = datefolder.mails.findIndex(x => x.id == mail.id);
      if (index >= 0)
      {
        datefolder.mails.splice(index, 1);
        break;
      }
    }

    for (let datefolder of this.dateCategoriesThreads)
    {
      let index = datefolder.mails.findIndex(x => x.id == mail.id);
      if (index >= 0)
      {
        datefolder.mails.splice(index, 1);
        break;
      }
    }
  }

  getMailFolderId(mail: Receivedmail)
  {
    if (this.selectedFolder)
      return this.selectedFolder.id;
    if (mail.folderIds.length == 1)
      return mail.folderIds[0];
    if (!this.selectedVirtualFolder)
      return mail.folderIds[0];
    for (let idfolder of mail.folderIds)
    {
      if (this.selectedVirtualFolder.realFolderIds.indexOf(idfolder) >= 0)
        return idfolder;
    }
    return 0;
  }

  findAddressMail(email: EmailToSend, mail: string)
  {
    for (let acc of this.profileList)
    {
      let alias = acc.aliases.find(x => this.tcs.equals(x.mail, mail))
      if (alias)
      {
        return alias;
      }
    }

    if (email.from && email.from.mail == "")
    {
      let acc = this.profileList.find(x => this.tcs.equals(x.mailAdress, mail));
      if (acc)
      {
        let alias = new MessagerieAccountAlias();
        alias.displayName = acc ? acc.mailAdress : this.profileList[0].mailAdress;
        alias.mail = alias.displayName;
        return alias;
      }
    }
    return null;
  }

  setAlias(mail: Receivedmail, email: EmailToSend)
  {
    let alias: MessagerieAccountAlias = null;

    if (mail.to)
    {
      for (let dest of mail.to)
      {
        alias = this.findAddressMail(email, dest)
        if (alias)
        {
          email.from = alias;
          return;
        }

      }
    }

    if (!alias && mail.cc)
    {
      for (let dest of mail.cc)
      {
        alias = this.findAddressMail(email, dest)
        if (alias)
        {
          email.from = alias;
          return;
        }
      }
    }
    console.log("didnt found");

  }

  getNumberMail()
  {
    let nbmail = 0;
    for (let cat of this.dateCategories)
    {
      nbmail += cat.mails.length;
    }

    for (let cat of this.dateCategoriesThreads)
    {
      nbmail += cat.mails.length;
    }
    return nbmail;
  }

  initSendMail(destinationMail: string)
  {

    this.cardMailCodeParams = [];
    let mail = new EmailToSend();
    let alias = new MessagerieAccountAlias();
    alias.displayName = this.authService.connectedUser.mail;
    alias.mail = alias.displayName;
    mail.from = alias;
    if (destinationMail)
      mail.to.push(destinationMail);

    this.initMailEditorModal.next(mail);
    this.showMailEditor = true;
  }

  //mettre sendOrNot a false pour ne pas envoyer le mail
  //emailCreated est rempli lorsque on ferme la modal
  editMail(sendOrNot: boolean = true)
  {

    let draftbutton = "";
    let close = "";
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x)
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x)
    this.translateService.get("MESSAGERIE.EDITOR.SEND").subscribe(x => this.confirmButton = x)
    this.translateService.get("MESSAGERIE.EDITOR.DRAFT").subscribe(x => draftbutton = x)
    this.emailCreated = new EmailToSend();
    this.dontOpen = true;
    let isdraft = this.selectedFolder && this.selectedFolder.isDraft

    const modal = this.modalService.create({
      nzTitle: this.modalTitle,
      nzBodyStyle: this.bodyStyle,
      nzStyle: this.modalStyle,
      nzContent: MailEditorComponent,
      nzWidth: '90%',
      nzZIndex: 10,
      nzMaskClosable: false,
      nzMask: false,
      nzFooter: [{
        label: close,
        onClick: (x) =>
        {
          modal.close()
        }
      },
      {
        label: save,
        show(): boolean { return isdraft; },
        onClick: (x) =>
        {
          let mail = x.getDatas();
          mail.idMail = this.openedMail.id;
          mail.cardId = this.openedCardId;
          mail.messageId = x.emailToInit?.messageId;
          if (sendOrNot)
          {
            this.updateMailAsDraft(mail, x.attachments).subscribe(x =>
            {
              let success = "";
              this.translateService.get("GENERIC-ACTIONS.SAVED").subscribe(x => success = x)
              this.openedMail.id = x;
              this.msgService.success(success)
              this.reloadmails.next(false);
              modal.close();
            })
          }
          this.emailCreated = mail;
          modal.close()
        }
      },
      {
        label: draftbutton,
        show(): boolean { return !isdraft; },
        onClick: (x) =>
        {
          let mail = x.getDatas()
          if (x.emailToInit.idMail > 0)
            mail.idMail = x.emailToInit.idMail;
          mail.cardId = this.openedCardId;
          mail.messageId = x.emailToInit?.messageId;
          if (sendOrNot)
          {
            this.saveMailAsDraft(mail, x.attachments).subscribe(x =>
            {
              let success = "";
              this.translateService.get("GENERIC-ACTIONS.SAVED").subscribe(x => success = x)
              this.msgService.success(success)
            })
          }
          this.emailCreated = mail;
          modal.close()
        }
      }, {
        label: this.confirmButton,
        type: "primary",
        disabled(result): boolean { return !result.checkToSend(); },
        onClick: (x) =>
        {
          let mail = x.getDatas()

          if (x.emailToInit.idMail > 0)
            mail.idMail = x.emailToInit.idMail;
          mail.cardId = this.openedCardId;
          mail.messageId = x.emailToInit?.messageId;
          if (sendOrNot)
          {
            this.sendMail(mail, x.attachments).subscribe(x =>
            {
              let success = "";
              this.translateService.get("MESSAGERIE.EDITOR.SUCCESS-SEND").subscribe(x => success = x)
              this.msgService.success(success)
              this.updateMailActionStatus.next(x);
            })
          }
          this.emailCreated = mail;
          modal.close()
        }
      }]
    });
    modal.componentInstance.listMailTo = this.listMailTo;
    modal.componentInstance.codeParams = this.codeParams;
    modal.componentInstance.emailToInit = this.emailToInit;
    modal.componentInstance.selectedFolder = this.selectedFolder;
    modal.componentInstance.cardMailCodeParams = this.cardMailCodeParams;
    this.listMailTo = [];
    this.codeParams = [];
    this.emailToInit = new EmailToSend();
    modal.afterClose.subscribe(() =>
    {
      this.dontOpen = false;
    })
  }

  refresh()
  {
    return this.http.get(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/refresh`)
  }

  unloadOldMails()
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/unload-mails`, null)
  }

  loadMoreMails(idaccountlist: number[])
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/load-more-mails`, idaccountlist)
  }

  updateAccountAliases(account: MailAccount)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/aliases`, account)
  }

  sortAccountFolders(idaccount: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idaccount}/sort-alphabetically`, null)
  }

  sortFolderSubFolders(idaccount: number, idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idaccount}/folder/${idfolder}/sort-alphabetically`, null)
  }

  disableAutosort(idaccount: number, idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idaccount}/folder/${idfolder}/disable-autosort`, null)
  }

  enableAutosort(idaccount: number, idfolder: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idaccount}/folder/${idfolder}/enable-autosort`, null)
  }

  getUnreadTotalCount()
  {
    return this.http.get<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/total-unread`)
  }

  ReimportFolderFromServer(accountid: number, folderfullpath: string)
  {
    let text = new TextSearch(folderfullpath);
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${accountid}/folder/reimport`, text)
  }

  ForceReimportFolderFromServer(accountid: number, folderfullpath: string)
  {
    let text = new TextSearch(folderfullpath);
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${accountid}/folder/reimport-force`, text)
  }

  CountAccountMailNotRead(idaccountlist: number[])
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/inbox-not-read`, idaccountlist)
  }

  CountAccountDeletedMailNotRead(idaccountlist: number[])
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/inbox-not-read-deleted`, idaccountlist)
  }

  advancedSearch(searchparameters: MessagerieAdvancedSearchParameters)
  {
    return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/advanced-search`, searchparameters)
  }

  getMail(idmail: number)
  {
    return this.http.get<Receivedmail>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${idmail}/getmail`)
  }

  generateMsgFromMail(idmail: number)
  {
    return this.http.get<KanbanAttachment>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${idmail}/generate-msg`)
  }

  getMailAttachments(idmail: number)
  {
    return this.http.get<KanbanAttachment[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${idmail}/attachment`)
  }

  getMailById(idmail: number)
  {
    return this.http.get<MessagerieMailPlusContent>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${idmail}`)
  }

  getMailSentInfos(idmail: number)
  {
    return this.http.get<SentMailInfo>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${idmail}/outbox-infos`)
  }

  getMailsInfos(idmails: number[])
  {
    return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/get-infos`, idmails)
  }

  createConversationThreadForAccount(conversation: MessagerieConversation, accountid: number)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${accountid}/conversation`, conversation)
  }

  addMailToConversation(idconversation: number, idmail: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/conversation/${idconversation}/mails/${idmail}`, null)
  }

  removeMailFromConversation(idconversation: number, idmail: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/conversation/${idconversation}/mails/${idmail}`)
  }

  sendMail(mail: EmailToSend, attachments: File[])
  {
    let formData: FormData = new FormData();
    for (let i = 0; i < attachments.length; i++)
    {
      formData.append("Attachments" + i, attachments[i], attachments[i].name);
    }

    formData.append("Mail", JSON.stringify(mail));
    formData.append("AccountId", this.cds.idAccountMailSend + '');
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail", formData)
  }

  sendMailWithInvitation(mail: EmailToSend, card: KanbanCard, attachments: File[])
  {
    let formData: FormData = new FormData();
    for (let i = 0; i < attachments.length; i++)
    {
      formData.append("Attachments" + i, attachments[i], attachments[i].name);
    }

    formData.append("Mail", JSON.stringify(mail));
    formData.append("Card", JSON.stringify(card));
    formData.append("AccountId", this.cds.idAccountMailSend + '');
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail-invitation", formData)
  }

  sendMailReadReceipt(mail: EmailToSend, accountid: number)
  {
    let formData: FormData = new FormData();

    formData.append("Mail", JSON.stringify(mail));
    formData.append("AccountId", accountid + '');
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail/send-read-receipt", formData)
  }

  saveMailAsDraft(mail: EmailToSend, attachments: File[])
  {
    let formData: FormData = new FormData();
    for (let i = 0; i < attachments.length; i++)
    {
      formData.append("Attachments" + i, attachments[i], attachments[i].name);
    }

    formData.append("Mail", JSON.stringify(mail));
    formData.append("AccountId", this.cds.idAccountMailSend + '');
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail/draft", formData)
  }

  updateMailAsDraft(mail: EmailToSend, attachments: File[])
  {
    let formData: FormData = new FormData();
    for (let i = 0; i < attachments.length; i++)
    {
      formData.append("Attachments" + i, attachments[i], attachments[i].name);
    }

    formData.append("Mail", JSON.stringify(mail));
    formData.append("AccountId", this.cds.idAccountMailSend + '');
    return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/mail/draft", formData)
  }

  getDeletedMail(start: number, number: number, idaccountlist: number[], filtertext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = filtertext;
    filters.accountIdList = idaccountlist;
    return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/position/${start}/number/${number}/deleted`, filters, httpOptions)
  }

  getSentMail(start: number, number: number, idaccountlist: number[], filtertext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = filtertext;
    filters.accountIdList = idaccountlist;
    return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/position/${start}/number/${number}/sent`, filters, httpOptions)
  }

  getDraftMail(start: number, number: number, idaccountlist: number[], filtertext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = filtertext;
    filters.accountIdList = idaccountlist;
    return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/position/${start}/number/${number}/draft`, filters, httpOptions)
  }

  deleteMail(mailId: number, folderid: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/folder/${folderid}/mail/${mailId}/delete`, null)
  }

  restoreMail(mailId: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/restore`, mailId)
  }

  deleteMultipleMail(mailId: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/mail/delete-multiple`, mailId)
  }

  readMail(mailId: number)
  {
    return this.http.put<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${mailId}/read`, null)
  }

  getAppointments(mailId: number)
  {
    return this.http.get<PlanningEvent[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${mailId}/appointments`)
  }

  foceReadMail(mailId: number)
  {
    return this.http.put<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${mailId}/force-read`, null)
  }

  unreadMail(mailId: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/${mailId}/unread`, null)
  }

  getMailContent(mailId: number, accountid: number)
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${accountid}/mail/${mailId}/content`)
  }

  readAllMail(idaccountlist: number[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/read-all`, idaccountlist)
  }

  getIMAPAccountFoldersInfos(idaccount: number)
  {
    return this.http.get<ApplyImapFolderSelection>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idaccount}/folders-infos`)
  }

  forceGetIMAPAccountFoldersInfos(idaccount: number)
  {
    return this.http.get<ApplyImapFolderSelection>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idaccount}/folders-infos/force`)
  }

  updateIMAPAccountFoldersInfos(idaccount: number, folderinfos: ApplyImapFolderSelection)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idaccount}/folders-infos`, folderinfos)
  }

  getEmailsBaseAccount(start: number, number: number, idaccountlist: number[], showMailInFolder: boolean, filtertext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = filtertext;
    filters.accountIdList = idaccountlist;
    if (showMailInFolder)
      return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/position/${start}/number/${number}`, filters, httpOptions)
    else return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mail/position/${start}/number/${number}/without-folder`, filters, httpOptions)
  }

  getConversationsWithMails(start: number, number: number, idaccountlist: number[], filtertext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = filtertext;
    filters.accountIdList = idaccountlist;
    return this.http.post<MessagerieFolder[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/conversations/position/${start}/number/${number}`, filters, httpOptions)
  }

  getConversationMails(idconversation: number, idaccountlist: number[])
  {
    return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/conversations/${idconversation}/mails`, idaccountlist)
  }

  checkMailCache()
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/check-mail-cache`)
  }

  getConversationsForAccount(idaccountlist: number[])
  {
    return this.http.post<MessagerieConversation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/conversations`, idaccountlist)
  }

  getDiscussionThreadsForMail(idmail: number)
  {
    return this.http.get<MessagerieConversation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/mails/${idmail}/discussions`)
  }

  getConversationThreadsForAccount(idaccountlist: number[], searchtext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = searchtext;
    filters.accountIdList = idaccountlist;
    return this.http.post<MessagerieConversation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/discussions`, filters)
  }

  getArchivedConversationThreadsForAccount(idaccountlist: number[], searchtext: string)
  {
    let filters = new MessagerieSearchParameters();
    filters.filterText = searchtext;
    filters.accountIdList = idaccountlist;
    return this.http.post<MessagerieConversation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/discussions/archives`, filters)
  }

  getMenuInfos()
  {
    return this.http.get<MessagerieMenuInfos>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/loadmenu`)
  }

  createDiscussionThread(discussionThread: MessagerieConversation, accountid: number)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${accountid}/discussion`, discussionThread)
  }

  addConversationToDiscussion(iddiscussion: number, idconversation: number)
  {
    return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/discussion/${iddiscussion}/conversation/${idconversation}`, null)
  }

  removeConversationFromDiscussion(iddiscussion: number, idconversation: number)
  {
    return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/discussion/${iddiscussion}/conversation/${idconversation}`)
  }

  archiveConversationOrDiscussion(id: number)
  {
    return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/conversation/${id}/archive`, null)
  }

  restoreConversationOrDiscussion(id: number)
  {
    return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/conversation/${id}/restore`, null)
  }

  getMailsForDiscussionThread(iddiscussion: number, searchtext: string, start: number, count: number)
  {
    return this.http.post<Receivedmail[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/discussion/${iddiscussion}/mails/start/${start}/count/${count}`, new TextSearch(searchtext))
  }

  getConversationDiscussionThread(iddiscussion: number, searchtext: string, start: number, count: number)
  {
    return this.http.post<MessagerieFolder[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/discussion/${iddiscussion}/conversations/start/${start}/count/${count}`, new TextSearch(searchtext))
  }

  addMailAccount(mailAccount: MailAccount)
  {
    return this.http.post<MailAccount>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/account", mailAccount)
  }

  updateMailAccount(mailAccount: MailAccount)
  {

    return this.http.put<MailAccount>(UrlApiService.settings.apiConfig.uriAPI + "/api/messagerie/account", mailAccount)
  }

  getMailAccount(idAccount: number)
  {
    return this.http.get<MailAccount>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idAccount}`)
  }

  getMailAccounts()
  {
    return this.http.get<MailAccount[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/`)
  }

  getMailAccountsForEdit()
  {
    return this.http.get<MailAccount[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/edit`)
  }

  deleteAccount(idAccount: number)
  {
    return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idAccount}`)
  }

  archiveAccount(idAccount: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idAccount}/archive`, null)
  }

  restoreAccount(idAccount: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idAccount}/restore`, null)
  }

  disableAccountRecuperation(idAccount: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idAccount}/disable-recuperation`, null)
  }

  enableAccountRecuperation(idAccount: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/messagerie/account/${idAccount}/enable-recuperation`, null)
  }

  showDiscussionThreads()
  {
    this.dateCategoriesThreads = [];
    this.selectedDiscussionThreadID = 0;
    this.getConversationThreadsForAccount(this.selectedProfiles, this.searchDiscussionThread).subscribe(discussions =>
    {
      this.openedMail = null;
      this.initModalThreadList(discussions);
    })
  }

  initModalThreadList(discussionThreadList: MessagerieConversation[])
  {
    this.start = 0;
    this.discussionThread = [];
    for (let disc of discussionThreadList)
    {
      let fold = new MessagerieFolder();
      fold.id = disc.id;
      fold.name = disc.name;
      fold.mails = disc.mails;
      this.discussionThread.push(fold);
    }
    this.discussionThreadVisible = true;

    setTimeout(() =>
    {
      if (document.getElementById('thread-scroll').addEventListener)
      {
        // IE9, Chrome, Safari, Opera
        document.getElementById('thread-scroll').addEventListener('mousewheel', function (e) { scrollHorizontally(e, 'thread-scroll') }, false);
        // Firefox
        document.getElementById('thread-scroll').addEventListener('DOMMouseScroll', function (e) { scrollHorizontally(e, 'thread-scroll') }, false);
      }
    }, 1000);
  }


  showDiscussionContent(discussionId: number, searchtext: string, start: number, count: number)
  {
    if (this.conversationMode)
    {
      this.getConversationDiscussionThread(discussionId, searchtext, start, count).subscribe(conversations =>
      {
        this.dateCategoriesThreads = [];
        this.selectedDiscussionThreadID = discussionId;
        this.generateConversationDateCategories(conversations, this.dateCategoriesThreads);
      })
    }
    else
    {
      this.getMailsForDiscussionThread(discussionId, searchtext, start, count).subscribe(mails =>
      {
        this.dateCategoriesThreads = [];
        this.selectedDiscussionThreadID = discussionId;
        this.generateDateCategories(mails, this.dateCategoriesThreads);
      })
    }
  }

  generateSubjectCategories(themails: Receivedmail[], dateCategories: MessagerieFolder[])
  {
    for (let mail of themails)
    {
      let cat = dateCategories.find(x => this.tcs.equals(x.name, mail.subject));
      if (!cat)
      {
        cat = new MessagerieFolder();
        cat.name = mail.subject;
        dateCategories.push(cat);
      }
      cat.mails.push(mail);
    }
  }

  generatePseudoCategories(themails: Receivedmail[], dateCategories: MessagerieFolder[])
  {
    for (let mail of themails)
    {
      let cat = dateCategories.find(x => this.tcs.equals(x.name, mail.pseudo));
      if (!cat)
      {
        cat = new MessagerieFolder();
        cat.name = mail.pseudo;
        dateCategories.push(cat);
      }
      cat.mails.push(mail);
    }
  }

  generateToCategories(themails: Receivedmail[], dateCategories: MessagerieFolder[])
  {
    for (let mail of themails)
    {
      if (mail.to.length == 0)
        continue;
      let cat = dateCategories.find(x => this.tcs.equals(x.name, mail.to[0]));
      if (!cat)
      {
        cat = new MessagerieFolder();
        cat.name = mail.to[0];
        dateCategories.push(cat);
      }
      cat.mails.push(mail);
    }
  }

  generateDateCategories(themails: Receivedmail[], truecat: MessagerieFolder[])
  {
    let isrefresh = truecat.length > 0 && this.clearlist;
    if (isrefresh)
      themails.reverse();

    let dateCategories: MessagerieFolder[] = [];
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
    for (let mail of themails)
    {
      let cat: MessagerieFolder = null;
      let mailDate = new Date(mail.receivedUTC);
      if (mailDate.getDate() == today.getDate() && mailDate.getMonth() == today.getMonth() && mailDate.getFullYear() == today.getFullYear())
      {
        cat = dateCategories.find(x => x.id == today.getTime());
        if (!cat)
        {
          cat = truecat.find(x => x.id == today.getTime())
          if (!cat)
          {
            cat = new MessagerieFolder();
            cat.id = today.getTime();

            dateCategories.push(cat);
          }
          else if (this.clearlist)
            dateCategories.push(cat);
          this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.TODAY").subscribe(x => cat.name = x)
        }
      }
      else 
      {
        let dateOneWeek = new Date(today.getTime() - (6 * 24 * 60 * 60 * 1000));
        if (mailDate.getTime() > dateOneWeek.getTime())
        {
          let thatday = new Date(today);
          thatday = new Date(thatday.getTime() - ((thatday.getDate() - mailDate.getDate()) * 24 * 60 * 60 * 1000));
          thatday = new Date(thatday.getFullYear(), thatday.getMonth(), thatday.getDate(), 0, 0, 0, 0);
          cat = dateCategories.find(x => x.id == thatday.getTime());
          if (!cat)
          {
            cat = truecat.find(x => x.id == thatday.getTime());
            if (!cat)
            {
              cat = new MessagerieFolder();
              cat.id = thatday.getTime();
              dateCategories.push(cat);
            }
            else if (this.clearlist)
              dateCategories.push(cat);

            switch (mailDate.getDay())
            {
              case 0:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.SUNDAY").subscribe(x => cat.name = x)
                break;
              case 1:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.MONDAY").subscribe(x => cat.name = x)
                break;
              case 2:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.TUESDAY").subscribe(x => cat.name = x)
                break;
              case 3:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.WEDNESDAY").subscribe(x => cat.name = x)
                break;
              case 4:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.THURSDAY").subscribe(x => cat.name = x)
                break;
              case 5:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.FRIDAY").subscribe(x => cat.name = x)
                break;
              case 6:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.SATURDAY").subscribe(x => cat.name = x)
                break;
            }
          }
        }
        else
        {
          let date2Week = new Date(today.getTime() - (14 * 24 * 60 * 60 * 1000));
          date2Week = new Date(date2Week.getFullYear(), date2Week.getMonth(), date2Week.getDate(), 0, 0, 0, 0);
          if (mailDate.getTime() > date2Week.getTime())
          {
            cat = dateCategories.find(x => x.id == date2Week.getTime());
            if (!cat)
            {
              cat = truecat.find(x => x.id == date2Week.getTime());
              if (!cat)
              {
                cat = new MessagerieFolder();
                cat.id = date2Week.getTime();

                dateCategories.push(cat);
              }
              else if (this.clearlist)
                dateCategories.push(cat);
              this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.LAST-WEEK").subscribe(x => cat.name = x)
            }
          }
          else
          {
            cat = dateCategories.find(x => x.id == -1);
            if (!cat)
            {
              cat = truecat.find(x => x.id == -1);
              if (!cat)
              {
                cat = new MessagerieFolder();
                cat.id = -1;

                dateCategories.push(cat);
              }
              else if (this.clearlist)
                dateCategories.push(cat);
              this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.MORE-THAN-TWO-WEEK").subscribe(x => cat.name = x)
            }
          }
        }
      }
      if (cat.mails.findIndex(x => x.id == mail.id) < 0)
      {
        if (isrefresh)
          cat.mails.unshift(mail);
        else cat.mails.push(mail);
      }

    }

    if (isrefresh)
      dateCategories.reverse();

    if (this.clearlist)
    {
      truecat.length = 0;
      this.clearlist = false;
    }

    truecat.push(...dateCategories);
    this.disableScroll = false;
  }

  generateConversationDateCategories(theconversations: MessagerieFolder[], dateCategories: MessagerieFolder[])
  {
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
    for (let conv of theconversations)
    {
      let mail = conv.mails[0];
      let mailDate = new Date(mail.receivedUTC);
      if (mailDate.getDate() == today.getDate() && mailDate.getMonth() == today.getMonth() && mailDate.getFullYear() == today.getFullYear())
      {
        let todaycat = dateCategories.find(x => x.id == today.getTime());
        if (!todaycat)
        {
          todaycat = new MessagerieFolder();
          todaycat.id = today.getTime();
          this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.TODAY").subscribe(x => todaycat.name = x)
          dateCategories.push(todaycat);
        }
        todaycat.subfolders.push(conv);
      }
      else 
      {
        let dateOneWeek = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
        if (mailDate.getTime() > dateOneWeek.getTime())
        {
          let thatday = new Date(today);
          thatday = new Date(thatday.getTime() - ((thatday.getDate() - mailDate.getDate()) * 24 * 60 * 60 * 1000));
          thatday = new Date(thatday.getFullYear(), thatday.getMonth(), thatday.getDate(), 0, 0, 0, 0);
          let thatdaycat = dateCategories.find(x => x.id == thatday.getTime());
          if (!thatdaycat)
          {
            thatdaycat = new MessagerieFolder();
            thatdaycat.id = thatday.getTime();
            dateCategories.push(thatdaycat);
            switch (mailDate.getDay())
            {
              case 0:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.SUNDAY").subscribe(x => thatdaycat.name = x)
                break;
              case 1:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.MONDAY").subscribe(x => thatdaycat.name = x)
                break;
              case 2:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.TUESDAY").subscribe(x => thatdaycat.name = x)
                break;
              case 3:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.WEDNESDAY").subscribe(x => thatdaycat.name = x)
                break;
              case 4:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.THURSDAY").subscribe(x => thatdaycat.name = x)
                break;
              case 5:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.FRIDAY").subscribe(x => thatdaycat.name = x)
                break;
              case 6:
                this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.SATURDAY").subscribe(x => thatdaycat.name = x)
                break;
            }
          }
          thatdaycat.subfolders.push(conv);
        }
        else
        {
          let date2Week = new Date(today.getTime() - (14 * 24 * 60 * 60 * 1000));
          date2Week = new Date(date2Week.getFullYear(), date2Week.getMonth(), date2Week.getDate(), 0, 0, 0, 0);
          if (mailDate.getTime() > date2Week.getTime())
          {
            let twoweekCat = dateCategories.find(x => x.id == date2Week.getTime());
            if (!twoweekCat)
            {
              twoweekCat = new MessagerieFolder();
              twoweekCat.id = date2Week.getTime();
              this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.LAST-WEEK").subscribe(x => twoweekCat.name = x)
              dateCategories.push(twoweekCat);
            }
            twoweekCat.subfolders.push(conv);
          }
          else
          {
            let othercat = dateCategories.find(x => x.id == -1);
            if (!othercat)
            {
              othercat = new MessagerieFolder();
              othercat.id = -1;
              this.translateService.get("MESSAGERIE.INBOX.DATE-CATEGORIES.MORE-THAN-TWO-WEEK").subscribe(x => othercat.name = x)
              dateCategories.push(othercat);
            }
            othercat.subfolders.push(conv);
          }
        }
      }

    }
  }

  clearMailSelection()
  {
    this.openMailInd = -1;
    this.openMail = false;
    this.openedMail = null;
    this.selectMailList = [];
  }

  reloadButton()
  {
    this.dateCategories = [];
    this.clearMailSelection();
    this.reloadmails.next(null);
  }

  resetScroll()
  {
    let el = document.getElementById("scrollmailcontainer");
    if (el)
      el.scrollTop = 0;
  }

}
