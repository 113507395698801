import { Component, OnInit } from '@angular/core';
import { ReservationResource } from '../../entities/resource';
import { TranslateService } from '@ngx-translate/core';
import { ResourceAddUpdateComponent } from '../resource-add-update/resource-add-update.component';
import { ResourceService } from '../../services/resource.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit
{

  constructor(private translateService: TranslateService, private modalService: NzModalService,
    private auth: AuthService,
    public resService: ResourceService) { }
  filteredData: ReservationResource[] = [];
  private subscription: Subscription;
  sortVal = "descend";
  sortKey = "name";
  dataLoaded = false;
  nbPPPerPage = 50;
  title = "";
  archivedMode = false;
  relationDirection = "base";
  ngOnInit()
  {
    this.auth.setCamelineTitle("planning");
    this.translateService.get("RESERVATION.RESOURCE.TITLE").subscribe(x => this.title = x);
    this.subscription = this.resService.resourcesChange
      .subscribe(item =>
      {
        this.resService.getAllResources().subscribe(x =>
        {
          this.filteredData = x.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
          this.dataLoaded = true;
        })
      });
    this.resService.resourcesChange.next(null);
  }

  sort(sort: any)
  {
    if (sort.value == null)
      sort.value = "descend";
    if (this.filteredData.length == 0)
      return;
    this.sortKey = sort.key;
    this.sortVal = sort.value;
    let temp = [...this.filteredData];
    this.filteredData = temp.sort((a, b) => (sort.value === 'descend') ? (a[sort.key] > b[sort.key] ? 1 : -1) : (b[sort.key] > a[sort.key] ? 1 : -1));
  }

  createResource()
  {
    let title = "";
    this.translateService.get("RESERVATION.RESOURCE.CREATE").subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CREATE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ResourceAddUpdateComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          let res = result.submitForm();
          if (res == null)
            return;
          this.resService.createResource(res).subscribe(x =>
          {
            this.resService.resourcesChange.next(null);
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.resource = null;
  }

  updateResource(resUpdate: ReservationResource)
  {
    let title = "";
    this.translateService.get("RESERVATION.RESOURCE.UPDATE", { v: resUpdate.name }).subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ResourceAddUpdateComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          let res = result.submitForm();
          if (res == null)
            return;
          this.resService.updateResource(res).subscribe(x =>
          {
            this.resService.resourcesChange.next(null);
            modal.close();
          });
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.resource = resUpdate;
  }
  deleteResource(resUpdate: ReservationResource)
  {
    let title = "";
    this.translateService.get("RESERVATION.RESOURCE.DELETE", { v: resUpdate.name }).subscribe(x => title = x);
    let content = "";
    this.translateService.get("RESERVATION.RESOURCE.DELETE-CONFIRM", { v: resUpdate.name }).subscribe(x => content = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: content,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          this.resService.deleteResource(resUpdate.id).subscribe(x =>
          {
            this.resService.resourcesChange.next(null);
            modal.close();
          });
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
  }
}
