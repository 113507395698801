import { Component, OnInit, Input } from '@angular/core';
import { KanbanBoard } from '../../entities/Board';
import { KanbanList } from '../../entities/List';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { KanbanService } from '../../services/kanban.service';
import { SetRelationKanban } from '../../entities/set-relation';
import { TypeRelation } from '../../../../../src/app/third-party/entities/type-relation';
import { GetRelation } from '../../../../../src/app/third-party/entities/get-relation';
import { KanbanManageTypeRelationComponent } from '../kanban-manage-type-relation/kanban-manage-type-relation.component';
import { RelationForListDTO } from '../../entities/list-relation';
import { ColorService } from '../../../../../src/app/services/color.service';
import { CacheDataService } from '../../../../../src/app/services/cache-data.service';
import { KanbanBoardTreeComponent } from '../kanban-board-tree/kanban-board-tree.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KanbanTreeSelectedInfos } from '../../entities/kanban-tree-selected-infos';
import { ListAction } from '../../entities/list-actions-control';
import { TextCompareService } from '../../../../../src/app/services/text-compare.service';
import { KanbanListActionsEditorComponent } from '../kanban-list-actions-editor/kanban-list-actions-editor.component';

@Component({
  selector: 'app-kanban-workflow-settings',
  templateUrl: './kanban-workflow-settings.component.html',
  styleUrls: ['./kanban-workflow-settings.component.scss']
})
export class KanbanWorkflowSettingsComponent implements OnInit
{

  @Input() board: KanbanBoard;
  @Input() list: KanbanList;
  constructor(private translateService: TranslateService,
    public kbs: KanbanService,
    private modalService: NzModalService,
    public cs: ColorService,
    private tcs: TextCompareService,
    public cds: CacheDataService,
    private NzMessageService: NzMessageService) { }

  objectName = null;

  ppIsSource = true;
  updatedPPIsSource = true;
  viewHeight = "40vh"
  dataLoaded = false;
  noData = false;
  updatedRelationVisible = false;
  update$: Subject<boolean> = new Subject();
  center$: Subject<boolean> = new Subject();
  zoomToFit: Subject<boolean> = new Subject();
  //filteredTypeRelations: TypeRelation[] = [];
  filteredKanbanBoardList: KanbanBoard[] = [];
  kanbanBoardListSourceForRelationCreation: KanbanBoard[] = [];
  style = {
    display: 'block',
    marginTop: '15px'
  };
  relationDirection = "base";
  editRelationVisible = false;
  listMembersToAffect = [];
  updatedlistMembersToAffect = [];

  listTeamsToAffect = [];
  updatedlistTeamsToAffect = [];

  listMembersAutoToAffect = [];
  updatedlistMembersAutoToAffect = [];

  listTeamsAutoToAffect = [];
  updatedlistTeamsAutoToAffect = [];

  listLabelsAutoToAffect = [];
  updatedListLabelsAutoToAffect = [];

  listLabelManualToAffect = [];
  updatedListLabelManualToAffect = [];

  listThirdPartyManualToAffect = [];
  updatedThirdPartyManualToAffect = [];

  listThirdPartyAutoToAffect = [];
  updatedThirdPartyAutoToAffect = [];

  listUserToNotify = [];
  updatedListUserToNotify = [];

  dueDate: Date;
  updateDueDate: Date;

  confirmationText = "";
  updatedconfirmationText = "";

  informationText = "";
  updatedinformationText = "";

  idSource: number = null;
  idFilteredSource: number;
  idTarget: number = null;
  idFilteredTarget: number;
  idType: string = null;
  idFilteredType: string;
  comment = "";
  tagValue: any[] = [];
  updatedIdRelation: number = 0;
  updatedRelationIdSource: number = null;
  updatedRelationIdTarget: number = null;
  updatedRelationIdType: string = null;
  updatedRelationComment = "";
  updatedRelationTagValue: any[] = [];
  sourceBoardColor = "";
  sourceBoardName = "";
  sourceListName = "";

  targetBoardColor = "";
  targetBoardName = "";
  targetListName = "";

  theLinks: any[] = [];
  theNodes: any[] = [];
  ngOnInit()
  {
    this.kbs.updateBoardRelations.subscribe(() =>
    {
      this.initData();
    })
    this.kbs.getThirdPartyForCard(0).subscribe(x =>
    {
      this.kbs.ppList = [];
      this.kbs.mpList = [];
      for (let tp of x)
      {
        if (tp.thirdPartyType == 1)
          this.kbs.ppList.push(tp);
        else if (tp.thirdPartyType == 2)
          this.kbs.mpList.push(tp)
      }
      this.kbs.ppList = this.kbs.ppList.sort((a, b) => a.firstName.toUpperCase() + a.lastName.toUpperCase() < b.firstName.toUpperCase() + b.lastName.toUpperCase() ? -1 : 1)
      this.kbs.mpList = this.kbs.mpList.sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)
    });

    if (this.list)
    {
      this.kbs.getListRelation(this.list.id).subscribe(() =>
      {
        this.filteredKanbanBoardList = this.getFilteredKanbanBoards();
        this.kanbanBoardListSourceForRelationCreation = this.kbs.listRelationInfos.boards;
        this.initData();
      })
    }
    else 
    {
      this.kbs.getBoardRelation(this.cds.selectedBoard.id).subscribe(() =>
      {
        this.filteredKanbanBoardList = this.getFilteredKanbanBoards();

        this.kanbanBoardListSourceForRelationCreation = this.kbs.listRelationInfos.boards.filter(x => x.id == this.board.id)
        this.initData();
      })
    }

    if (this.boardForMove.length == 0)
    {
      this.kbs.getAllBoardAndListForUser().subscribe(x =>
      {
        this.boardForMove = x;
        if (this.boardForMove.findIndex(b => b.id == this.board.id) < 0)
        {
          this.boardForMove.unshift(this.board);
        }
      });
    }

  }

  boardForMove: KanbanBoard[] = [];

  selectList(type)
  {
    if (this.boardForMove.length == 0)
    {
      this.kbs.getAllBoardAndListForUser().subscribe(x =>
      {
        this.boardForMove = x;
        this.openSelectBoardWindow(type);
      });
    }
    else this.openSelectBoardWindow(type);


  }

  initialBypass = false;
  idlistmoveaction = 0;
  idlistcopyaction = 0;
  idlistmoveactionupdate = 0;
  idlistcopyactionupdate = 0;
  popvisible = false;
  selectListForAction(type, movecopy)
  {
    let list = null;
    if (type == "create")
      list = this.getList(this.idTarget);
    else
      list = this.getList(this.updatedRelationIdTarget);
    this.initialBypass = list && list.allowedDeplacementListIds.length == 0;
    let title = "";
    let confirm = "";
    let cancel = "";
    this.translateService.get("THIRD-PARTY.CALL.SETTINGS.CHOOSE-LIST").subscribe(x => title = x);
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);

    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanBoardTreeComponent,
      nzWidth: '90%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzOnOk: (mod) =>
      {
        if (!mod.selectedListForMove)
          return;
        let idlist = parseInt(mod.selectedListForMove.split("_")[1]);
        if (movecopy == "move" && type == "create")
          this.idlistmoveaction = idlist;
        else if (movecopy == "move" && type == "update")
          this.idlistmoveactionupdate = idlist;
        else if (movecopy == "copy" && type == "create")
          this.idlistcopyaction = idlist;
        else if (movecopy == "move" && type == "update")
          this.idlistcopyactionupdate = idlist;
        modal.close();
      },
      nzOkText: confirm,
      nzOnCancel: () =>
      {
        modal.close();
      },
      nzCancelText: cancel,
    });
    modal.componentInstance.boards = this.boardForMove;
    modal.componentInstance.initialbypass = this.initialBypass;
    modal.componentInstance.allowedListForMove = list.allowedDeplacementListIds;
    modal.componentInstance.openedBoard = this.board.id;
    modal.componentInstance.loadcardonselect = false;
    modal.componentInstance.showListThatCannotCreateCard = true;
  }

  onSelection(data: KanbanTreeSelectedInfos, action: ListAction)
  {
    if (!data.list)
      return;
    if (action.codeAction == 'MOVE-CARD')
      action.listIdMoveCard = data.list.id;
    else if (action.codeAction == 'COPY-CARD')
      action.listIdCopyCard = data.list.id;
  }

  getList(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return list;
      }
    }
    return null;
  }

  getBoardBGColor(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return board.bgColor;
      }
    }
    return "";
  }

  getBoardName(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return board.title;
      }
    }
    return "";
  }

  getListName(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return list.title;
      }
    }
    return "";
  }

  openSelectBoardWindow(type)
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.CALL.SETTINGS.CHOOSE-LIST").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanBoardTreeComponent,
      nzWidth: '80%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzOnOk: (mod) =>
      {
        if (mod.theSelection.list)
        {
          if (type == "source")
          {
            this.sourceBoardColor = mod.theSelection.board.bgColor;
            this.sourceBoardName = mod.theSelection.board.title;
            this.sourceListName = mod.theSelection.list.title;
            this.idSource = mod.theSelection.list.id;
          }
          else 
          {
            this.targetBoardColor = mod.theSelection.board.bgColor;
            this.targetBoardName = mod.theSelection.board.title;
            this.targetListName = mod.theSelection.list.title;
            this.idTarget = mod.theSelection.list.id;
          }

        }
      },
      nzOkText: confirm,
      nzOnCancel: () =>
      {
        return
      },
      nzCancelText: cancel,
    });
    modal.componentInstance.boards = this.boardForMove;
    modal.componentInstance.initialbypass = true;
    modal.componentInstance.openedBoard = this.board.id;
    modal.componentInstance.loadcardonselect = false;
  }

  showMoveCardListSelector()
  {
    return this.tagValue.indexOf("MOVE-CARD") >= 0;
  }

  showupdatedMoveCardListSelector()
  {
    return this.updatedRelationTagValue.indexOf("MOVE-CARD") >= 0;
  }

  showCopyCardListSelector()
  {
    return this.tagValue.indexOf("COPY-CARD") >= 0;
  }

  showupdatedCopyCardListSelector()
  {
    return this.updatedRelationTagValue.indexOf("COPY-CARD") >= 0;
  }

  showMemberSelector()
  {
    return this.tagValue.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0;
  }

  showupdatedMemberSelection()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0;
  }

  showTeamSelector()
  {
    return this.tagValue.indexOf("AFFECT-CARD-TEAMS-MANUAL") >= 0;
  }

  showupdatedTeamSelection()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-CARD-TEAMS-MANUAL") >= 0;
  }

  showNotifiedUsers()
  {
    return this.tagValue.indexOf("NOTIFY-USER-GENERAL-AUTO") >= 0;
  }

  showupdatedNotifiedUserAuto()
  {
    return this.updatedRelationTagValue.indexOf("NOTIFY-USER-GENERAL-AUTO") >= 0;
  }

  showLabelSelectorAuto()
  {
    return this.tagValue.indexOf("AFFECT-LABELS-AUTO") >= 0;
  }

  showLabelSelectorManual()
  {
    return this.tagValue.indexOf("AFFECT-LABELS-MANUAL") >= 0;
  }

  showThirdPartySelectorManual()
  {
    return this.tagValue.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0;
  }

  showThirdPartySelectorAuto()
  {
    return this.tagValue.indexOf("AFFECT-THIRD-PARTY-AUTO") >= 0;
  }

  showUpdatedThirdPartySelectorManual()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0;
  }

  showUpdatedThirdPartySelectorAuto()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-THIRD-PARTY-AUTO") >= 0;
  }

  showUpdatedLabelSelectorAuto()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-LABELS-AUTO") >= 0;
  }

  showUpdatedLabelSelectorManual()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-LABELS-MANUAL") >= 0;
  }

  showMemberSelectorAuto()
  {
    return this.tagValue.indexOf("AFFECT-CARD-MEMBERS-AUTO") >= 0;
  }

  showupdatedMemberSelectionAuto()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-CARD-MEMBERS-AUTO") >= 0;
  }

  showTeamSelectorAuto()
  {
    return this.tagValue.indexOf("AFFECT-CARD-TEAMS-AUTO") >= 0;
  }

  showupdatedTeamSelectionAuto()
  {
    return this.updatedRelationTagValue.indexOf("AFFECT-CARD-TEAMS-AUTO") >= 0;
  }

  showDueDateSelection()
  {
    return this.tagValue.indexOf("SET-DUE-DATE") >= 0;
  }


  showupdatedDueDateSelection()
  {
    return this.updatedRelationTagValue.indexOf("SET-DUE-DATE") >= 0;
  }

  showConfirmationText()
  {
    return this.tagValue.indexOf("ASK-CONFIRMATION") >= 0;
  }

  showupdatedConfirmationText()
  {
    return this.updatedRelationTagValue.indexOf("ASK-CONFIRMATION") >= 0;
  }

  showInformationText()
  {
    return this.tagValue.indexOf("SHOW-INFORMATION-TEXT") >= 0;
  }

  showupdatedInformationText()
  {
    return this.updatedRelationTagValue.indexOf("SHOW-INFORMATION-TEXT") >= 0;
  }

  manageBoardTypeRelation()
  {
    let title = "";
    this.translateService.get("KANBAN.MANAGE-TYPE-RELATION-BOARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanManageTypeRelationComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.boardId = this.board.id;
  }

  switchCreationDirection(isSource: boolean)
  {
    if (isSource)
    {
      this.ppIsSource = true;
      this.idSource = this.list.id;
      this.idTarget = null;
    }
    else 
    {
      this.ppIsSource = false;
      this.idTarget = this.list.id;
      this.idSource = null;
    }
  }

  updateDisplay(displayType: string)
  {
    switch (displayType)
    {
      case "base":
        this.idFilteredSource = null;
        this.idFilteredTarget = null;
        break;
      case "source":
        this.ppIsSource = true;
        this.idFilteredTarget = this.idFilteredSource;
        break;
      case "target":
        this.ppIsSource = false;
        this.idFilteredSource = this.idFilteredTarget;
        break;
    }
    this.initData();
  }

  change(param)
  {

    if (!param)
      this.updatedIdRelation = 0;

  }

  setUpdatedRelation(idrelation: string)
  {
    let id = parseInt(idrelation.slice(2));
    let relation = this.kbs.listRelationInfos.relationList.find(x => x.idRelation == id);
    this.updatedIdRelation = id;
    this.updatedRelationIdSource = relation.idSource;
    this.updatedRelationIdType = relation.idTypeRelation + "," + relation.direction;
    this.updatedRelationComment = relation.comment;
    this.updatedconfirmationText = "";
    this.updatedinformationText = "";
    this.idlistmoveactionupdate = 0;
    this.idlistcopyactionupdate = 0;
    this.updatedlistMembersAutoToAffect = [];
    this.updatedlistMembersToAffect = [];
    this.updatedlistTeamsAutoToAffect = [];
    this.updatedlistTeamsToAffect = [];
    this.updatedListLabelsAutoToAffect = [];
    this.updatedListLabelManualToAffect = [];
    this.updatedThirdPartyAutoToAffect = [];
    this.updatedThirdPartyManualToAffect = [];
    this.updatedListUserToNotify = [];
    this.updatedRelationTagValue = [];
    if (relation.actionsRelation)
    {
      this.updatedRelationTagValue = relation.actionsRelation.split(";");
      for (let i = 0; i < this.updatedRelationTagValue.length; i++)
      {
        if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-MEMBERS-AUTO"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedlistMembersAutoToAffect.push(params[y]);
          }

        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-TEAMS-AUTO"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedlistTeamsAutoToAffect.push(params[y]);
          }

        }
        else if (this.updatedRelationTagValue[i].startsWith("NOTIFY-USER-GENERAL-AUTO"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedListUserToNotify.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-MEMBERS-MANUAL"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedlistMembersToAffect.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-MEMBERS-MANUAL"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedlistMembersToAffect.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-TEAMS-MANUAL"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedlistTeamsToAffect.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-LABELS-AUTO"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedListLabelsAutoToAffect.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-LABELS-MANUAL"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedListLabelManualToAffect.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-THIRD-PARTY-MANUAL"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedThirdPartyManualToAffect.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-THIRD-PARTY-AUTO"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          for (var y = 1; y < params.length; y++)
          {
            this.updatedThirdPartyAutoToAffect.push(params[y]);
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("MOVE-CARD"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          this.idlistmoveactionupdate = parseInt(params[1]);
        }
        else if (this.updatedRelationTagValue[i].startsWith("COPY-CARD"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          this.idlistcopyactionupdate = parseInt(params[1]);
        }
        else if (this.updatedRelationTagValue[i].startsWith("ASK-CONFIRMATION"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          this.updatedconfirmationText = params[1].replace("%%alt666%%", ",");
        }
        else if (this.updatedRelationTagValue[i].startsWith("SHOW-INFORMATION-TEXT"))
        {
          let params = this.updatedRelationTagValue[i].split(",");
          this.updatedRelationTagValue[i] = params[0];
          this.updatedinformationText = params[1].replace("%%alt666%%", ",");
        }
      }
    }

    if (this.list && relation.idSource === this.list.id)
      this.updatedPPIsSource = true;
    else this.updatedPPIsSource = false;
    this.editRelationVisible = true;
    this.updatedRelationVisible = true;
    this.updatedRelationIdTarget = relation.idTarget;
  }

  saveUpdateRelation()
  {
    let error = "";
    this.translateService.get("THIRD-PARTY.MODIFICATION-ERROR").subscribe(x => error = x);
    let success = "";
    this.translateService.get("THIRD-PARTY.MODIFICATION-SAVED").subscribe(x => success = x);
    if (!this.updatedRelationIdType)
    {
      this.NzMessageService.error(error);
      return;
    }
    let relation = this.kbs.listRelationInfos.relationList.find(x => x.idRelation === this.updatedIdRelation)
    let setRelation = new SetRelationKanban();
    setRelation.idListSource = relation.idSource;
    setRelation.idListTarget = relation.idTarget
    let infoType = this.updatedRelationIdType.split(",");
    setRelation.idTypeRelation = parseInt(infoType[0]);
    setRelation.direction = parseInt(infoType[1]);
    setRelation.comment = this.updatedRelationComment;
    setRelation.idRelation = this.updatedIdRelation;
    if (this.updatedRelationTagValue)
    {
      for (let i = 0; i < this.updatedRelationTagValue.length; i++)
      {
        if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-MEMBERS-AUTO"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-CARD-MEMBERS-AUTO";
          for (let mem of this.updatedlistMembersAutoToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-TEAMS-AUTO"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-CARD-MEMBERS-AUTO";
          for (let mem of this.updatedlistTeamsAutoToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-MEMBERS-MANUAL"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-CARD-MEMBERS-MANUAL";
          for (let mem of this.updatedlistMembersToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-CARD-TEAMS-MANUAL"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-CARD-TEAMS-MANUAL";
          for (let mem of this.updatedlistTeamsToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("NOTIFY-USER-GENERAL-AUTO"))
        {
          this.updatedRelationTagValue[i] = "NOTIFY-USER-GENERAL-AUTO";
          for (let mem of this.updatedListUserToNotify)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-LABELS-MANUAL"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-LABELS-MANUAL";
          for (let mem of this.updatedListLabelManualToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-LABELS-AUTO"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-LABELS-AUTO";
          for (let mem of this.updatedListLabelsAutoToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-THIRD-PARTY-MANUAL"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-THIRD-PARTY-MANUAL";
          for (let mem of this.updatedThirdPartyManualToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("AFFECT-THIRD-PARTY-AUTO"))
        {
          this.updatedRelationTagValue[i] = "AFFECT-THIRD-PARTY-AUTO";
          for (let mem of this.updatedThirdPartyAutoToAffect)
          {
            if (this.updatedRelationTagValue[i].indexOf(mem) < 0)
              this.updatedRelationTagValue[i] += "," + mem;
          }
        }
        else if (this.updatedRelationTagValue[i].startsWith("ASK-CONFIRMATION") && this.updatedconfirmationText.length > 0)
        {
          this.updatedRelationTagValue[i] += "," + this.updatedconfirmationText.replace(",", "%%alt666%%");
        }
        else if (this.updatedRelationTagValue[i].startsWith("MOVE-CARD") && this.idlistmoveactionupdate > 0)
        {
          this.updatedRelationTagValue[i] += "," + this.idlistmoveactionupdate;
        }
        else if (this.updatedRelationTagValue[i].startsWith("COPY-CARD") && this.idlistcopyactionupdate > 0)
        {
          this.updatedRelationTagValue[i] += "," + this.idlistcopyactionupdate;
        }
        else if (this.updatedRelationTagValue[i].startsWith("SHOW-INFORMATION-TEXT") && this.updatedinformationText.length > 0)
        {
          this.updatedRelationTagValue[i] += "," + this.updatedinformationText.replace(",", "%%alt666%%");
        }
      }
      setRelation.actionsRelation = this.updatedRelationTagValue.join(";");
    }
    else setRelation.actionsRelation = "";

    this.kbs.updateListRelation(setRelation).subscribe(() =>
    {
      relation.idTypeRelation = setRelation.idTypeRelation;
      relation.comment = setRelation.comment;
      relation.actionsRelation = setRelation.actionsRelation;
      relation.idTypeRelation = setRelation.idTypeRelation;
      relation.direction = setRelation.direction;
      this.NzMessageService.success(success);
      let link = this.theLinks.find(x => x.id === "Id" + this.updatedIdRelation);
      let type = this.kbs.listRelationInfos.typeRelationList.find(x => x.id === relation.idTypeRelation);
      link.label = relation.direction == 0 ? type.nameSourceTarget : type.nameTargetSource;
      this.theLinks = [...this.theLinks];
      this.filteredKanbanBoardList = this.getFilteredKanbanBoards();
      let index = this.board.lists.findIndex(x => x.id == relation.idSource);
      if (index >= 0)
      {
        let rel = this.board.lists[index].allowedDeplacementListIds.find(x => x.targetListId == relation.idTarget);
        rel.actionsRelation = setRelation.actionsRelation;
      }
      this.updatedRelationIdTarget = 0;
      this.updatedIdRelation = 0;
      this.initData();

    })
  }

  getUpdatedTargetName()
  {
    if (!this.updatedIdRelation)
      return "";
    let relation = this.kbs.listRelationInfos.relationList.find(x => x.idRelation === this.updatedIdRelation)
    return relation.titleTarget;
  }

  getUpdatedSourceName()
  {
    if (!this.updatedIdRelation)
      return "";
    let relation = this.kbs.listRelationInfos.relationList.find(x => x.idRelation === this.updatedIdRelation)
    return relation.titleSource;
  }

  isBidirectional(idRelation)
  {
    let id = parseInt(idRelation.slice(2));
    let relation = this.kbs.listRelationInfos.relationList.find(x => x.idRelation == id);
    let type = this.kbs.listRelationInfos.typeRelationList.find(x => x.id === relation.idTypeRelation);
    if (type.nameSourceTarget === type.nameTargetSource)
      return true;
    return false;
  }

  getFilteredKanbanBoards()
  {
    let boards: KanbanBoard[] = [];
    this.kbs.listRelationInfos.boards.forEach(board =>
    {
      let list = board.lists.filter(x => this.kbs.listRelationInfos.relationList.findIndex(y => y.idSource == x.id || y.idTarget == x.id) >= 0);
      if (list.length > 0)
      {
        let f_board = new KanbanBoard();
        f_board.id = board.id;
        f_board.title = board.title;
        f_board.lists = list;
        boards.push(f_board);
      }
    })
    return boards;
  }

  typeRelation: TypeRelation[] = [];
  getFilteredTP()
  {
    let tps = [];
    this.typeRelation = [];
    for (let type of this.kbs.listRelationInfos.typeRelationList)
    {
      let typeToTarget = new TypeRelation();
      typeToTarget.id = type.id;
      typeToTarget.nameSourceTarget = type.nameSourceTarget;
      typeToTarget.nameTargetSource = type.nameTargetSource;
      typeToTarget.description = type.description;
      typeToTarget.actionsRelation = type.actionsRelation;
      typeToTarget.archived = type.archived;
      typeToTarget.boardId = type.boardId;
      typeToTarget.visible = type.visible;
      typeToTarget.direction = 0;
      tps.push(typeToTarget)

      if (type.nameSourceTarget == type.nameTargetSource)
        continue;

      if (type.nameTargetSource)
      {
        let typeToSource = new TypeRelation();
        typeToSource.id = type.id;
        typeToSource.nameSourceTarget = type.nameSourceTarget;
        typeToSource.nameTargetSource = type.nameTargetSource;
        typeToSource.description = type.description;
        typeToSource.actionsRelation = type.actionsRelation;
        typeToSource.archived = type.archived;
        typeToSource.boardId = type.boardId;
        typeToSource.visible = type.visible;
        typeToSource.direction = 1;
        tps.push(typeToSource);
      }

    }
    tps = tps.sort((a, b) => this.getTpName(a).toUpperCase() < this.getTpName(b).toUpperCase() ? -1 : 1);
    this.typeRelation = tps;
  }

  getTpName(type: TypeRelation)
  {
    return type.direction == 0 ? type.nameSourceTarget : type.nameTargetSource
  }

  getUserNameFromId(id: number)
  {
    let user = this.cds.userCollection.find(x => x.id == id);
    return user.name + " " + user.surname;
  }

  getNodeBoardName(idnode: string)
  {
    let id = parseInt(idnode.substring(2))
    let board = this.kbs.listRelationInfos.boards.find(y => y.lists.findIndex(x => x.id == id) >= 0);
    if (board)
      return board.title;
    return "";
  }


  addNodeInit(listnode: any[], idnode: number, titlenode: string)
  {
    if (listnode.findIndex(x => x.id === "Id" + idnode) < 0)
    {
      let color = "black";
      let board = this.kbs.listRelationInfos.boards.find(y => y.lists.findIndex(x => x.id == idnode) >= 0);
      if (board && board.id != this.board.id)
        color = board.bgColor;

      listnode.push({
        id: 'Id' + idnode.toString(),
        label: titlenode,
        color: color
      });
    }
  }

  initData()
  {
    let tempNode = [];
    let tempLinks = [];
    //this.filteredTypeRelations = [...this.kbs.listRelationInfos.typeRelationList];
    this.getFilteredTP();
    this.kbs.listRelationInfos.relationList.forEach(relation =>
    {
      let idsource = "";
      let idtarget = "";
      let typerelationtext = "";
      if ((this.idFilteredSource > 0 && relation.idSource != this.idFilteredSource)
        || (this.idFilteredTarget > 0 && relation.idTarget != this.idFilteredTarget)
        || (this.idFilteredType && (relation.idTypeRelation + "," + relation.direction) != this.idFilteredType))
        return;
      idsource = 'Id' + relation.idSource.toString();
      idtarget = "Id" + relation.idTarget.toString();
      typerelationtext = relation.direction == 0 ? relation.titleTypeRelationSourceTarget : relation.titleTypeRelationTargetSource;

      this.addNodeInit(tempNode, relation.idSource, relation.titleSource);
      this.addNodeInit(tempNode, relation.idTarget, relation.titleTarget);

      tempLinks.push({
        id: "Id" + relation.idRelation.toString(),
        source: idsource,
        target: idtarget,
        label: typerelationtext
      })
    });
    this.theNodes = tempNode;
    this.theLinks = tempLinks;

    if (this.theNodes.length === 0)
      this.noData = true;
    else this.noData = false;

    this.dataLoaded = true;
  }

  manageActionsAtCardCreation()
  {
    let relation = this.kbs.listRelationInfos.relationList.find(x => x.idRelation === this.updatedIdRelation)
    let list = new KanbanList();
    list.id = relation.idTarget;
    let title = "";
    this.translateService.get("KANBAN.MANAGE-LIST-ACTIONS-CARD-CREATION").subscribe(x => title = x);
    let titleConfirm = "";
    this.translateService.get("KANBAN.ACTION-CONFIRM-CLOSE-TITLE").subscribe(x => titleConfirm = x);
    let contentConfirm = "";
    this.translateService.get("KANBAN.ACTION-CONFIRM-CLOSE-CONTENT").subscribe(x => contentConfirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanListActionsEditorComponent,
      nzWidth: '90%',
      nzFooter: [{
        label: close,
        onClick: (result) =>
        {
          let confirmModal = this.modalService.confirm({
            nzTitle: titleConfirm,
            nzContent: contentConfirm,
            nzCancelText: cancel,
            nzOkText: close,
            nzOnOk: () =>
            {
              this.kbs.modalEdit.close();
              confirmModal.destroy();
            }

          });

        }
      }, {
        label: save,
        type: "primary",
        onClick: (result) =>
        {
          result.kas.updateListActionsForTrigger(list.id, result.actionsManager).subscribe(() =>
          {
            result.hasUpdated = false;
            let text = "";
            this.translateService.get("GENERIC-ACTIONS.MODIFICATIONS-SAVED").subscribe(x => text = x);
            this.NzMessageService.success(text);
          });
        }
      }]
    });
    this.kbs.modalEdit.componentInstance.board = this.board;
    this.kbs.modalEdit.componentInstance.list = list;
    this.kbs.modalEdit.componentInstance.trigger = "2";
    this.kbs.modalEdit.componentInstance.idtrigger = relation.idSource;
  }

  createRelation()
  {
    if (!this.idType || (this.ppIsSource && this.idTarget == 0) || (!this.ppIsSource && this.idSource == 0))
      return;
    let setRelation = new SetRelationKanban();
    setRelation.idListSource = this.ppIsSource && this.list ? this.list.id : this.idSource;
    setRelation.idListTarget = !this.ppIsSource && this.list ? this.list.id : this.idTarget;
    let infoType = this.idType.split(",");
    setRelation.idTypeRelation = parseInt(infoType[0]);
    setRelation.direction = parseInt(infoType[1]);
    setRelation.comment = this.comment;

    if (this.tagValue)
    {
      for (let i = 0; i < this.tagValue.length; i++)
      {
        if (this.tagValue[i].startsWith("AFFECT-CARD-MEMBERS-AUTO") && this.listMembersAutoToAffect.length > 0)
        {
          for (let mem of this.listMembersAutoToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("AFFECT-CARD-TEAMS-AUTO") && this.listTeamsAutoToAffect.length > 0)
        {
          for (let mem of this.listTeamsAutoToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("MOVE-CARD") && this.idlistmoveaction > 0)
        {
          this.tagValue[i] += "," + this.idlistmoveaction;
        }
        else if (this.tagValue[i].startsWith("COPY-CARD") && this.idlistcopyaction > 0)
        {
          this.tagValue[i] += "," + this.idlistcopyaction;
        }
        else if (this.tagValue[i].startsWith("AFFECT-CARD-MEMBERS-MANUAL") && this.listMembersToAffect.length > 0)
        {
          for (let mem of this.listMembersToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("AFFECT-CARD-TEAMS-MANUAL") && this.listTeamsToAffect.length > 0)
        {
          for (let mem of this.listTeamsToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("NOTIFY-USER-GENERAL-AUTO"))
        {
          for (let mem of this.updatedListUserToNotify)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("AFFECT-LABELS-MANUAL") && this.listLabelManualToAffect.length > 0)
        {
          for (let mem of this.listLabelManualToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("AFFECT-LABELS-AUTO") && this.listLabelsAutoToAffect.length > 0)
        {
          for (let mem of this.listLabelsAutoToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("AFFECT-THIRD-PARTY-MANUAL") && this.listThirdPartyManualToAffect.length > 0)
        {
          for (let mem of this.listThirdPartyManualToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("AFFECT-THIRD-PARTY-AUTO") && this.listThirdPartyAutoToAffect.length > 0)
        {
          for (let mem of this.listThirdPartyAutoToAffect)
          {
            this.tagValue[i] += "," + mem;
          }
        }
        else if (this.tagValue[i].startsWith("ASK-CONFIRMATION") && this.confirmationText.length > 0)
        {
          this.tagValue[i] += "," + this.confirmationText.replace(",", "%%alt666%%");
        }
        else if (this.tagValue[i].startsWith("SHOW-INFORMATION-TEXT") && this.informationText.length > 0)
        {
          this.tagValue[i] += "," + this.informationText.replace(",", "%%alt666%%");
        }
      }
      setRelation.actionsRelation = this.tagValue.join(";");
    }
    let titleSource = "";
    let titleTarget = "";
    this.kbs.createListRelation(setRelation).subscribe(x =>
    {
      if (this.theNodes.findIndex(y => y.id === "Id" + setRelation.idListSource) < 0)
      {
        let color = "black";
        let list = null;
        for (let board of this.kbs.listRelationInfos.boards)
        {
          list = board.lists.find(y => y.id === setRelation.idListSource);
          if (list)
          {
            if (board.id != this.board.id)
              color = board.bgColor;
            break;
          }

        }
        if (list)
        {
          titleSource = list.title;
          this.theNodes.push({
            id: 'Id' + list.id.toString(),
            label: list.title,
            color: color
          });
        }
      }
      else titleSource = this.theNodes.find(y => y.id === "Id" + setRelation.idListSource).label;

      if (this.theNodes.findIndex(y => y.id === "Id" + setRelation.idListTarget) < 0)
      {
        let list = null;
        let color = "black";
        for (let board of this.kbs.listRelationInfos.boards)
        {
          list = board.lists.find(y => y.id === setRelation.idListTarget);
          if (list)
          {
            if (board.id != this.board.id)
              color = board.bgColor;
            break;
          }
        }

        if (list)
        {
          titleTarget = list.title;
          this.theNodes.push({
            id: 'Id' + list.id.toString(),
            label: list.title,
            color: color
          });
        }
      }
      else titleTarget = this.theNodes.find(y => y.id === "Id" + setRelation.idListTarget).label;

      let TypeRelation = this.kbs.listRelationInfos.typeRelationList.find(y => y.id == setRelation.idTypeRelation);
      let idsource = "";
      let idtarget = "";
      let typerelationtext = "";

      idsource = 'Id' + setRelation.idListSource.toString();
      idtarget = "Id" + setRelation.idListTarget.toString();
      typerelationtext = TypeRelation.nameSourceTarget
      this.theLinks.push({
        id: "Id" + x.toString(),
        source: idsource,
        target: idtarget,
        label: typerelationtext
      })
      let relation = new GetRelation();
      relation.idRelation = x;
      relation.idSource = setRelation.idListSource;
      relation.idTarget = setRelation.idListTarget;
      relation.titleTypeRelationSourceTarget = TypeRelation.nameSourceTarget;
      relation.titleTypeRelationTargetSource = TypeRelation.nameTargetSource;
      relation.idTypeRelation = setRelation.idTypeRelation;
      relation.direction = setRelation.direction;
      relation.titleSource = titleSource;
      relation.titleTarget = titleTarget;
      relation.comment = setRelation.comment;
      relation.actionsRelation = this.tagValue.join(";");

      this.kbs.listRelationInfos.relationList.push(relation);
      let relForlist = new RelationForListDTO();
      relForlist.targetListId = relation.idTarget;
      relForlist.actionsRelation = relation.actionsRelation;
      if (this.list && relation.idSource == this.list.id)
        this.list.allowedDeplacementListIds.push(relForlist);
      else
      {
        let index = this.board.lists.findIndex(x => x.id == relation.idSource);
        if (index >= 0)
          this.board.lists[index].allowedDeplacementListIds.push(relForlist);
      }

      this.noData = false;
      this.theLinks = [...this.theLinks];
      this.theNodes = [...this.theNodes];
      this.idSource = null;
      this.idTarget = null;
      this.idType = null;
      this.tagValue = [];
      this.idlistmoveaction = 0;
      this.idlistcopyaction = 0;
      this.filteredKanbanBoardList = this.getFilteredKanbanBoards();
      this.initData();
    });
  }

  setActions(idtype: number)
  {
    let type = this.kbs.listRelationInfos.typeRelationList.find(x => x.id == idtype);
    if (type && type.actionsRelation)
      this.tagValue = type.actionsRelation.split(";");
  }

  resetFilter()
  {
    this.idFilteredSource = null;
    this.idFilteredTarget = null;
    this.idFilteredType = null;
  }
  deleteRelation()
  {
    let idRelation = "Id" + this.updatedIdRelation;
    let id = parseInt(idRelation.substring(2));
    this.kbs.deleteListRelation(id).subscribe(() =>
    {
      let index = this.theLinks.findIndex(x => x.id == idRelation);
      if (index >= 0)
      {
        this.theLinks.splice(index, 1);
        let relation = this.kbs.listRelationInfos.relationList.find(x => x.idRelation == id);
        let deleteSource = this.kbs.listRelationInfos.relationList.filter(x => x.idSource == relation.idSource || x.idTarget == relation.idSource).length === 1;
        let deleteTarget = this.kbs.listRelationInfos.relationList.filter(x => x.idSource == relation.idTarget || x.idTarget == relation.idTarget).length === 1;
        if (deleteSource)
        {
          let indexsource = this.theNodes.findIndex(x => x.id == "Id" + relation.idSource);
          this.theNodes.splice(indexsource, 1);
        }

        if (deleteTarget)
        {
          let indextarget = this.theNodes.findIndex(x => x.id == "Id" + relation.idTarget);
          this.theNodes.splice(indextarget, 1);
        }

        let indexRelationList = this.kbs.listRelationInfos.relationList.findIndex(x => x.idRelation == id);
        this.kbs.listRelationInfos.relationList.splice(indexRelationList, 1);
        this.theLinks = [...this.theLinks];
        this.theNodes = [...this.theNodes];
        if (this.list)
        {
          let idtodelete = this.list.allowedDeplacementListIds.findIndex(x => x.targetListId == relation.idTarget);
          if (idtodelete >= 0)
            this.list.allowedDeplacementListIds.splice(idtodelete, 1);
        }
        else
        {
          let index = this.board.lists.findIndex(x => x.id == relation.idSource);
          if (index >= 0)
          {
            let index2 = this.board.lists[index].allowedDeplacementListIds.findIndex(x => x.targetListId == relation.idTarget);
            if (index2 >= 0)
              this.board.lists[index].allowedDeplacementListIds.splice(index2, 1);
          }

        }
      }
      this.updatedRelationIdTarget = 0;
      this.updatedIdRelation = 0
      this.filteredKanbanBoardList = this.getFilteredKanbanBoards();
    })
  }

  searchTpTextAuto = "";
  onSearchAuto(text)
  {
    this.searchTpTextAuto = text;
  }

  getPpAuto()
  {
    return this.kbs.ppList.filter(x => this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, this.searchTpTextAuto))
  }

  getMpAuto()
  {
    return this.kbs.mpList.filter(x => this.tcs.contains(x.name, this.searchTpTextAuto))
  }


  searchTpTextManual = "";
  onSearchManual(text)
  {
    this.searchTpTextManual = text;
  }

  getPpManual()
  {
    return this.kbs.ppList.filter(x => this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, this.searchTpTextManual))
  }

  getMpManual()
  {
    return this.kbs.mpList.filter(x => this.tcs.contains(x.name, this.searchTpTextManual))
  }

  getColor(idarrow)
  {
    if ("Id" + this.updatedIdRelation == idarrow)
      return "red";
    return 'black';
  }

  updateSearchSourceOptions(id: number)
  {
  }

  updateSearchTargetOptions(id: number)
  {
  }

  filter()
  {
    this.initData();
  }
}
