import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KanbanComponent } from './kanban.component';

const appChildrenRoutes: Routes = [
  { path: '', component: KanbanComponent },
  { path: ':idBoard', component: KanbanComponent },
  { path: ':idBoard/:idCard', component: KanbanComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(
      appChildrenRoutes
    )
  ],

  exports: [
    RouterModule
  ],
  declarations: []
})
export class KanbanRoutingModule { }
