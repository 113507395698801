import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HubConnection } from '@microsoft/signalr';
import { AuthService } from '../auth.service';
import { GroupAccess } from 'src/app/entities/group-access';
import { UserDataService } from 'src/app/user/services/data/user-data.service';
import { UserRight } from 'src/app/user/model/user-right';
import { GroupUserWithRights } from 'src/app/group-user/entities/group-user-with-rights';
import { GroupUserDataService } from 'src/app/group-user/services/data/group-user-data.service';
import { GroupCredentialsDataService } from 'src/app/group-credentials/services/data/group-credentials-data.service';
import { GroupCredentialsWithRights } from 'src/app/group-credentials/entities/group-credentials-with-rights';
import { CredentialsDTOWithRights } from 'src/app/credentials/entities/credentials-dto-with-rights';
import { CredentialsDataService } from 'src/app/credentials/services/data/credentials-data.service';

@Injectable({
  providedIn: 'root'
})
export class AdminNotificationService
{

  constructor(private authService: AuthService,
    private userService: UserDataService,
    private groupuserService: GroupUserDataService,
    private groupcredService: GroupCredentialsDataService,
    private credService: CredentialsDataService) { }

  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('UpdateGroupAccess', (access: GroupAccess) =>
    {
      this.authService.Access = access;
    });

    _hubConnection.on('UpdateUserAdminRights', (userRights: UserRight[]) =>
    {
      this.userService.ListUserRights = userRights;
    });

    _hubConnection.on('UpdateAllGroupUser', (groupUserWithRights: GroupUserWithRights[]) =>
    {
      this.groupuserService.listGroupUserWithRights = groupUserWithRights;
      this.groupuserService.listGroupUserWithRightsChange.next(this.groupuserService.listGroupUserWithRights);
    });

    _hubConnection.on('UpdateAllGroupCredentials', (groupCredWithRights: GroupCredentialsWithRights[]) =>
    {
      this.groupcredService.listGroupCredentialsWithRights = groupCredWithRights;
      this.groupcredService.listGroupCredentialsWithRightsChange.next(this.groupcredService.listGroupCredentialsWithRights);
    });

    _hubConnection.on('UpdateAllCredentials', (credWithRights: CredentialsDTOWithRights[]) =>
    {
      this.credService.listCredentialsWithRights = credWithRights;
      this.credService.listCredentialsWithRightsChange.next(this.credService.listCredentialsWithRights);
    });

    _hubConnection.on('TestWebSocket', (osef: any) =>
    {
      console.log("WebSocket test successfull !")
    });
  }
}
