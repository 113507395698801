// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  max-width: 300px;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

[nz-row] {
  height: 100vh;
  background-color: rgb(190, 190, 190);
}`, "",{"version":3,"sources":["webpack://./src/app/components/login/login.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;;AAGM;EACE,YAAA;AAAR;;AAGM;EACE,WAAA;AAAR;;AAGM;EACE,aAAA;EACA,oCAAA;AAAR","sourcesContent":["\r\n    .login-form {\r\n        max-width: 300px;\r\n      }\r\n  \r\n      .login-form-forgot {\r\n        float: right;\r\n      }\r\n  \r\n      .login-form-button {\r\n        width: 100%;\r\n      }\r\n\r\n      [nz-row]{\r\n        height:100vh;\r\n        background-color: rgb(190, 190, 190);\r\n      }\r\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
