import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Route } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BlackListIP } from '../entities/BlackListIP';
import { History_BlackListIp } from '../entities/History_BlackListIp';
import { User } from 'src/app/user/model/user';
import { CacheDataService } from 'src/app/services/cache-data.service';
import { GroupNameIp } from '../entities/GroupNameIp';
import { AsIp } from '../entities/AsIp';
import { SettingsService } from 'src/app/settings/services/settings.service';
import { UrlApiService } from 'src/app/services/url-api.service';
import { WhiteListIP } from '../entities/WhiteListIP';
import { HistoryCountIp } from '../entities/HistoryCountIp';
import { AttackIp } from '../entities/AttackIp';
import { AttackParam } from '../entities/AttackParam';


@Injectable({
    providedIn: "root"
})
export class BlackListIpService
{
    BlackListIps: BlackListIP[];
    WhiteListIps: WhiteListIP[];
    HistoriesForAnIp: History_BlackListIp[];
    GroupNameIp: GroupNameIp[];
    AsIp: AsIp[];
    Attacks : AttackIp[];

    constructor(private http: HttpClient, private cacheDataService: CacheDataService, private translateService : TranslateService) { }

    getUser(creatorId: number)
    {
        let user: User;
        let string : string;
        this.translateService.get("BLACKLISTIP.SERVER").subscribe(x=> {
            string =x;
        })
        if (!creatorId)
            return string;
        else
        {
            user = this.cacheDataService.userCollection.find(usr => usr.id == creatorId);
            return user.name + " " + user.surname;
        }
    }

    getLogin(creatorId: number)
    {
        let user: User;
        let string : string;
        this.translateService.get("BLACKLISTIP.SERVER").subscribe(x=> {
            string =x;
        })
        if (!creatorId)
            return string;
        else
        {
            user = this.cacheDataService.userCollection.find(usr => usr.id == creatorId);
            return user.login;
        }
    }

    getAs(id: number): string
    {
        let astemp: AsIp;
        if (!id || this.AsIp == null)
            return;
        astemp = this.AsIp.find(x => x.id == id);
        if(!astemp)
            return;
        return astemp.name;
    }

    setAs(as: number): number
    {
        let astemp: AsIp;
        if (!as || this.AsIp == null)
            return;
        astemp = this.AsIp.find(x => x.as == as);
        return astemp.id;
    }

    setGroup(name: string): number
    {
        let grouptemp: GroupNameIp;
        if (!name || this.GroupNameIp == null)
            return;
        grouptemp = this.GroupNameIp.find(x => x.name == name);
        return grouptemp.id;

    }

    getGroup(id: number): string
    {
        let grouptemp: GroupNameIp;
        if (!id || this.GroupNameIp == null)
            return "";
        grouptemp = this.GroupNameIp.find(x => x.id == id);
        if(!grouptemp)
            return "";
        return grouptemp.name;
    }

    checkIPTab(tab: string[]): boolean
    {
      if (tab.length != 4)
        return false;
      else
      {
        for (let i = 0; i < tab.length; i++)
        {
          let nb = parseInt(tab[i]);
          if (isNaN(nb) || nb < 0 || nb > 255)
          {
            return false;
          }
        }
        return true
      }
    }

    getAllBlackListIPS()
    {
        return this.http.get<BlackListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/blackListIp/");
    }

    addBlackListIP(newIpToBlacklist: BlackListIP)
    {
        return this.http.post<BlackListIP>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/blackListIp/", newIpToBlacklist);
    }

    removeBlackListIP(ipRemoveFromBlackList: BlackListIP)
    {
        return this.http.put<void>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/blackListIp/remove/", ipRemoveFromBlackList);
    }

    getAllBlackListIPSHisto()
    {
        return this.http.get<BlackListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/blackListIp/history/");
    }

    restoreBlackListIp(ipRestoreFromBlackList: BlackListIP)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/blackListIp/restore/", ipRestoreFromBlackList);
    }

    getHistoryBanIP(ipHistory: BlackListIP)
    {
        return this.http.get<History_BlackListIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/history/" + ipHistory.id + "/");
    }

    getHistoryWhiteIP(ipHistory: WhiteListIP)
    {
        return this.http.get<History_BlackListIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/history/" + ipHistory.id + "/");
    }

    getCountHistoryBanIP(ipHistory: BlackListIP)
    {
        return this.http.get<HistoryCountIp>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/history/count/" + ipHistory.id + "/");
    }

    getcountHistoryWhiteIP(ipHistory: WhiteListIP)
    {
        return this.http.get<HistoryCountIp>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/history/count/" + ipHistory.id + "/");
    }

    editIpEntries(ipEdit: BlackListIP)
    {
        return this.http.put<BlackListIP>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/blackListIP/", ipEdit)
    }

    getAllGroupNameIp()
    {
        return this.http.get<GroupNameIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/");
    }

    getAllGroupNameIpHistory()
    {
        return this.http.get<GroupNameIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/history/");
    }

    addGroupNameIP(groupName: GroupNameIp)
    {
        return this.http.post<GroupNameIp>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/", groupName);
    }

    editGroupNameIp(groupName: GroupNameIp)
    {
        return this.http.put<GroupNameIp>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/", groupName);
    }

    removeGroupNameIp(groupName: GroupNameIp)
    {
        return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/" + groupName.id);
    }

    restoreGroupNameIp(groupName: GroupNameIp)
    {
        return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/history/" + groupName.id);
    }

    getAllAsIp()
    {
        return this.http.get<AsIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/");
    }

    getAllAsIpHistory()
    {
        return this.http.get<AsIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/history/");
    }

    addAsIP(as: AsIp)
    {
        return this.http.post<AsIp>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/", as);
    }

    editAsIp(as: AsIp)
    {
        return this.http.put<AsIp>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/", as);
    }

    removeAsIp(as: AsIp)
    {
        return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/" + as.id);
    }

    restoreAsIp(as: AsIp)
    {
        return this.http.delete<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/history/" + as.id);
    }

    getAllWhiteListIp()
    {
        return this.http.get<WhiteListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/whiteListIp/");
    }

    getAllWhiteListIpHistory()
    {
        return this.http.get<WhiteListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/whiteListIp/history/");
    }

    addwhiteListIP(newIpToWhiteList: WhiteListIP)
    {
        return this.http.post<WhiteListIP>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/whiteListIp/", newIpToWhiteList);
    }

    removeWhiteListIP(ipRemoveFromWhiteList: WhiteListIP)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/whiteListIp/remove/", ipRemoveFromWhiteList);
    }

    restoreWhiteListIp(ipRestoreFromBlackList: WhiteListIP)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/whiteListIp/restore/", ipRestoreFromBlackList);
    }

    editIpWhiteEntries(ipEdit: WhiteListIP)
    {
        return this.http.put<WhiteListIP>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/whiteListIp/", ipEdit)
    }

    getBlackListLinkGroup(group: GroupNameIp)
    {
        return this.http.get<BlackListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/link/blackListIp/" + group.id);
    }

    getWhiteListLinkGroup(group: GroupNameIp)
    {
        return this.http.get<WhiteListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/groupName/link/whiteListIP/" + group.id + "/");
    }

    getBlackListLinkAs(aS: AsIp)
    {
        return this.http.get<BlackListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/link/blackListIp/" + aS.id + "/");
    }

    getWhiteListLinkAs(aS: AsIp)
    {
        return this.http.get<WhiteListIP[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/as/link/whiteListIP/" + aS.id + "/");
    }

    getAllAttack(attackParam : AttackParam)
    {
        return this.http.post<AttackIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/",attackParam );
    }

    getAttacksFromIP(id : number)
    {
        return this.http.get<AttackIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/ip/"+id+"/");
    }

    getHistoriesFromAttack(id : number)
    {
        return this.http.get<History_BlackListIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/history/"+id+"/");
    }

    changeStateAttack(attack : AttackIp)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/state/", attack)
    }

    ipPassInWhiteList(attack : AttackIp)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/whiteList/", attack)
    }

    getAllAttackInvalid(attackParam : AttackParam)
    {
        return this.http.post<AttackIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/invalid",attackParam );
    }

    getAllAttackFromGroupName(attackParam : AttackParam)
    {
        return this.http.post<AttackIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/group",attackParam );
    }

    getAllAttackFromAS(attackParam : AttackParam)
    {
        return this.http.post<AttackIp[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/ipAddress/attack/as",attackParam );
    }
}
