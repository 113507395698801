import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagerieMailViewerComponent } from './messagerie-mail-viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AttachmentManagerModule } from '../../../../src/app/components/attachment-manager/attachment-manager.module';
import { HtmlSafeModule } from '../../../../src/app/pipes/html-safe/html-safe.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PipesModule } from '../../pipes/pipes/pipes.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { FormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SDICalendarModule } from '../../SDICalendar/SDICalendar.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzPopoverModule,
    NzPopconfirmModule,
    NzToolTipModule,
    NzModalModule,
    NzDropDownModule,
    NzSelectModule,
    NzButtonModule,
    NzBadgeModule,
    SDICalendarModule,
    PipesModule,
    AttachmentManagerModule,
    NzIconModule,
    HtmlSafeModule
  ],
  declarations: [MessagerieMailViewerComponent],
  exports: [MessagerieMailViewerComponent]
})
export class MessagerieMailViewerModule { }
