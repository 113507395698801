import { Injectable } from '@angular/core';
import { AccessTokenRequest } from '../entities/AccessTokenRequest';
import { SpotifyToken } from '../entities/SpotifyToken';
import { HttpClient } from '@angular/common/http';
import { SpotifyUser } from '../entities/SpotifyUser';
import { TokenRequestInfo } from '../entities/TokenRequestInfo';
import { UrlApiService } from 'src/app/services/url-api.service';
import { Playlist } from '../entities/Playlist';
import { Paging, GetPlaylists } from '../entities/Paging';
import { Track } from '../entities/Track';
import { PagingTracks } from '../entities/PagingTracks';
import { SearchResultContainer } from '../entities/SearchResultContainer';
import { PagingPlaylistTrack } from '../entities/PagingPlaylistTrack';
import { PlaylistTrack } from '../entities/PlaylistTrack';
import { PlaySong } from '../entities/PlaySong';
import { CurrentPlayingContext } from '../entities/CurrentPlayingContext';
import { PagingDevices } from '../entities/PagingDevices';
import { TransfertPlayback } from '../entities/TransfertPlayback';
import { ScriptService } from 'src/app/scripts/script.service';
import { TranslateService } from '@ngx-translate/core';
import { PagingCategory, GetCategories } from '../entities/Category';
import { PlaylistViewerParams } from '../entities/PlaylistVIsualisorParams';
import { PlayPlaylist } from '../entities/PlayPlaylist';
import { FeaturedPlaylist } from '../entities/FeaturedPlaylist';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
declare var spotifyPlayerID;
declare function CreateSpotifyPlayer();
@Injectable({
  providedIn: 'root'
})
export class SpotifyService
{
  private token: SpotifyToken = null;
  user: SpotifyUser = null;
  selectedCategory = null;
  selectedPlaylist: PlaylistViewerParams;
  selectedMode = "browse";
  userPlaylist: Playlist[] =[];
  runningTrack: Track = new Track();
  searchResult: SearchResultContainer = null;
  selectedPlaylistChange: Subject<Playlist> = new  Subject<Playlist>();
  constructor(private http: HttpClient, private script: ScriptService, private translateService: TranslateService,
    private router: Router) { }


  openLoginSpotify()
  {
    let scope = "user-read-birthdate%20streaming%20user-read-private%20user-read-email%20playlist-modify-public%20playlist-modify-private%20playlist-read-private%20playlist-read-collaborative%20user-read-currently-playing%20user-read-playback-state%20user-modify-playback-state";
    let url =  window.location.protocol + "//" + window.location.host + "/spotify";
    window.location.href = `https://accounts.spotify.com/authorize?client_id=782605d564c84d778032b6a957937975&response_type=code&redirect_uri=${encodeURIComponent(url)}&scope=${scope}&state=123456&show_dialog=true`;
  }

  getFeaturedPlaylist()
  {
    return this.http.get<FeaturedPlaylist>("https://api.spotify.com/v1/browse/featured-playlists");
  }

  checkIfPlaylistFollowed(idplaylist: string)
  {
    return this.http.get<boolean[]>(`https://api.spotify.com/v1/playlists/${idplaylist}/followers/contains?ids=` + this.user.id)
  }

  followPlaylist(idplaylist: string)
  {
    return this.http.put(`https://api.spotify.com/v1/playlists/${idplaylist}/followers`, null);
  }

  unfollowPlaylist(idplaylist: string)
  {
    return this.http.delete(`https://api.spotify.com/v1/playlists/${idplaylist}/followers`);
  }

  getSpotifyAccessToken(code: string)
  {
    if(this.token == null)
    {
      let url =  window.location.protocol + "//" + window.location.host + "/spotify";
      let requestInfo: TokenRequestInfo = new TokenRequestInfo();
      requestInfo.code = code;
      requestInfo.redirectUri = url;
      return this.http.post<SpotifyToken>(UrlApiService.settings.apiConfig.uriAPI + "/api/external/spotify/token", requestInfo);
    }
    else 
    {
      return this.http.post<SpotifyToken>(UrlApiService.settings.apiConfig.uriAPI + "/api/external/spotify/refreshtoken", this.Token);
    }    
  }

  public get Token()
  {
    if (!this.token)
      this.token = JSON.parse(localStorage.getItem("spotify_token"));
      return this.token;
  }

  public set Token(value: SpotifyToken)
  {
    if(this.token == null)
    {
      this.token = value;
      this.script.load('spotifyWebPlayer').then(data => {
        console.log('script loaded ', data);
        CreateSpotifyPlayer();
    }).catch(error => console.log(error));
    }
    else
    {
      this.token = value;
      CreateSpotifyPlayer();
    }
    localStorage.setItem("spotify_token", JSON.stringify(value));
    
  }

  disconnect()
  {
    this.Token = null;
    spotifyPlayerID = "";
    this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/external/spotify/logout", null).subscribe();
  }

  getSelectedAccount()
  {
    return this.http.get<SpotifyToken>(UrlApiService.settings.apiConfig.uriAPI + "/api/external/spotify/account");
  }

  setSelectedAccount(idaccount: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/external/spotify/selected-account/" + idaccount, null).subscribe()
  }

  getAllAccounts()
  {
    return this.http.get<SpotifyToken[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/external/spotify/accounts");
  }

  updateSelectedAccount(token: SpotifyToken)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + "/api/external/spotify/account", token)
  }

  getUserInfos()
  {
    return this.http.get<SpotifyUser>("https://api.spotify.com/v1/me");
  }

  getUserPlaylists()
  {
    return this.http.get<Paging>(`https://api.spotify.com/v1/me/playlists`);
  }

  createUserPlaylist(name: string, description: string)
  {
    return this.http.post<Playlist>(`https://api.spotify.com/v1/users/${this.user.id}/playlists`, 
    {
      "name": name,
      "description": description,
      "public": false
    });
  }

  getPlaylistTracks(playlist: Playlist)
  {
    return this.http.get<PagingPlaylistTrack>(`https://api.spotify.com/v1/playlists/${playlist.id}/tracks`);
  }

  addTrackToPlaylist(idplaylist: string, track: Track)
  {
    return this.http.post(`https://api.spotify.com/v1/playlists/${idplaylist}/tracks?uris=${encodeURIComponent(track.uri)}`, null);
  }

  removeTrackFromPlaylist(idplaylist: string, playlistTrack: PlaylistTrack)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/external/spotify/playlist/${idplaylist}/deletetrack?token=${this.Token.access_token}&uri=${encodeURIComponent(playlistTrack.track.uri)}`, null);
  }

  searchTracks(searchText: string)
  {
    return this.http.get<SearchResultContainer>(`https://api.spotify.com/v1/search?q=${searchText.replace(" ", "+")}&type=track&limit=50`);
  }

  searchTracksAndPlaylist(searchText: string)
  {
    return this.http.get<SearchResultContainer>(`https://api.spotify.com/v1/search?q=${searchText.replace(" ", "+")}&type=track,playlist&limit=50`);
  }

  playTrack(track: Track)
  {
    let tracks: PlaySong = new PlaySong();
    tracks.uris.push(track.uri);
return this.http.put("https://api.spotify.com/v1/me/player/play", tracks);
  }

  playPlaylist(playlist: Playlist)
  {
    let p = new PlayPlaylist();
    p.context_uri = playlist.uri
return this.http.put("https://api.spotify.com/v1/me/player/play", p);
  }

  resumePlay()
  {
    return this.http.put("https://api.spotify.com/v1/me/player/play", null);
  }

  pausePlay()
  {
    return this.http.put("https://api.spotify.com/v1/me/player/pause", null);
  }

  nextSong()
  {
    return this.http.post("https://api.spotify.com/v1/me/player/next", null);
  }

  previousSong()
  {
    return this.http.post("https://api.spotify.com/v1/me/player/previous", null);
  }

  getCurrentPlaybackInfo()
  {
    if(this.Token)
      return this.http.get<CurrentPlayingContext>("https://api.spotify.com/v1/me/player");
  }

  getUserDevices()
  {
    return this.http.get<PagingDevices>("https://api.spotify.com/v1/me/player/devices");
  }

  setDeviceAsActive(deviceid: string)
  {
    let trsfert : TransfertPlayback = new TransfertPlayback();
    trsfert.device_ids.push(deviceid);
    return this.http.put("https://api.spotify.com/v1/me/player", trsfert)
  }

  displayTime(millisec: number)
  {
    const normalizeTime = (time: string): string => (time.length === 1) ? time.padStart(2, '0') : time;

    let seconds: string = (millisec / 1000).toFixed(0);
    let minutes: string = Math.floor(parseInt(seconds) / 60).toString();
    let hours: string = '';

    if (parseInt(minutes) > 59)
    {
      hours = normalizeTime(Math.floor(parseInt(minutes) / 60).toString());
      minutes = normalizeTime((parseInt(minutes) - (parseInt(hours) * 60)).toString());
    }
    seconds = normalizeTime(Math.floor(parseInt(seconds) % 60).toString());

    if (hours !== '')
    {
      return `${hours}:${minutes}:${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  playSong(track: Track)
  {
    this.getCurrentPlaybackInfo().subscribe(x =>
    {
      if (x == null)
      {
        this.getUserDevices().subscribe(y =>
        {
          if (y.devices.length > 0)
          {
            this.setDeviceAsActive(spotifyPlayerID).subscribe(z =>
            {
              this.recursivePlayback(track);
            });
          }
        });
      }
      else
      {
        this.playOrResumeTrack(x, track);
      }

    });

  }

  setShuffle(state: boolean)
  {
    return this.http.put("https://api.spotify.com/v1/me/player/shuffle?state=" + state, null);
  }

  setRepeatState(state: string)
  {
    return this.http.put("https://api.spotify.com/v1/me/player/repeat?state=" + state, null);
  }

  recursivePlayback(track: Track)
  {
    setTimeout(() =>
    {
      this.getCurrentPlaybackInfo().subscribe(a =>
      {
        if(a == null)
          this.recursivePlayback(track);
        else this.playOrResumeTrack(a, track);
      });
    }, 200);
  }


  playOrResumeTrack(currenPlayingContext: CurrentPlayingContext, playlistTrack: Track)
  {
    this.runningTrack = playlistTrack;
    if (currenPlayingContext.item.id == playlistTrack.id)
      this.resumeSong(playlistTrack);
    else 
    {
      this.playTrack(playlistTrack).subscribe(x =>
      {
        playlistTrack.is_running = true;
      });
    }
  }

  pauseSong(track: Track)
  {
    this.pausePlay().subscribe(x => track.is_running = false);
  }

  resumeSong(track: Track)
  {
    this.resumePlay().subscribe(x => track.is_running = true);
  }

  changeCurrentDeviceVolume(volume: number)
  {
    return this.http.put("https://api.spotify.com/v1/me/player/volume?volume_percent=" + volume, null);
  }

  getCategories()
  {
    return this.http.get<GetCategories>("https://api.spotify.com/v1/browse/categories?locale=" + this.translateService.currentLang);
  }

  getPlaylistFromCategories(category_id: string)
  {
    return this.http.get<GetPlaylists>(`https://api.spotify.com/v1/browse/categories/${category_id}/playlists`);
  }
}
