import { Component, OnInit, Input } from '@angular/core';
import { KanbanList } from '../../entities/List';
import { DropEffect, DndDropEvent } from 'ngx-drag-drop';
import { KanbanCard } from '../../entities/Card';
import { KanbanService } from '../../services/kanban.service';
import { KanbanBoard } from '../../entities/Board';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLabel } from '../../entities/Label';
import { KanbanMoveCard } from '../../entities/CardMove';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { KanbanWorkflowSettingsComponent } from '../kanban-workflow-settings/kanban-workflow-settings.component';
import { KanbanActionsManagerComponent } from '../kanban-actions-manager/kanban-actions-manager.component';
import { KanbanToolsService } from '../../services/kanban-tools.service';
import { EditListStyleComponent } from '../edit-list-style/edit-list-style.component';
import { EditListSortComponent } from '../edit-list-sort/edit-list-sort.component';
import { ColorService } from '../../../../../src/app/services/color.service';
import { NotifyUserForAction } from '../../entities/notify-user';
import { CacheDataService } from '../../../../../src/app/services/cache-data.service';
import { KanbanMoveListComponent } from '../kanban-move-list/kanban-move-list.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { KanbanListActionsEditorComponent } from '../kanban-list-actions-editor/kanban-list-actions-editor.component';
import { KanbanActionsService } from '../../services/kanban-actions.service';
import { KanbanActionParameters } from '../../entities/kanban-action-parameters';
import { firstValueFrom } from 'rxjs';
import { KanbanCardTemplateInputManagerComponent } from '../kanban-card-template-input-manager/kanban-card-template-input-manager.component';
import { title } from 'process';
import { KanbanCardCreationFromTemplate } from '../../entities/kanban-card-creation-from-template';

@Component({
  selector: 'app-kanban-list',
  templateUrl: './kanban-list.component.html',
  styleUrls: ['./kanban-list.component.scss']
})
export class KanbanListComponent implements OnInit
{

  createdCard: KanbanCard = new KanbanCard();
  createdLabel: GlobalLabel = {
    id: 0,
    title: "",
    color: "#61bd4f",
    creatorId: 0,
    creationDate: new Date(), boardId: null, modules: 0, thirdPartyType: null
  }

  popoverArchiveAllCards = false;
  optionCreateCardOpen = false;
  addCardOption: number = 0;
  optionsTitle = "Options";
  editListComment = "";
  showEditCommentList = false;
  memberIdFilter = 0;
  labelIdFIlter = 0;
  filterbytext = "";
  restoreOnClose = true;
  connectedList = [];
  constructor(public kbs: KanbanService, private translateService: TranslateService,
    private modalService: NzModalService,
    private kts: KanbanToolsService,
    public cs: ColorService,
    private msg: NzMessageService,
    public cds: CacheDataService,
    public kas: KanbanActionsService,
    private authService: AuthService) { }
  @Input() list: KanbanList;
  @Input() board: KanbanBoard;
  @Input() showArchived: boolean;

  ngOnInit()
  {
    this.translateService.get("KANBAN.FILTER-BY").subscribe(x => this.filterbytext = x);
    for (let list of this.board.lists)
    {
      if (list.id != this.list.id)
      {
        this.connectedList.push("kanbanlist_" + list.id);
      }
    }
  }

  initListTitleEdit()
  {
    this.kbs.isEditionListName = this.list.id;
    this.kbs.setFocusToElement("kanbanlisttitleinput");
  }

  getSortText()
  {
    let text = "";
    switch (this.list.autofilterType)
    {
      case "title":
        this.translateService.get("KANBAN.SORT-TYPE.TYPE-TITLE").subscribe(x => text = this.filterbytext + " " + x);
        break;
      case "datecreation":
        this.translateService.get("KANBAN.SORT-TYPE.TYPE-DATECREATION").subscribe(x => text = this.filterbytext + " " + x);
        break;
      case "datestart":
        this.translateService.get("KANBAN.SORT-TYPE.TYPE-DATESTART").subscribe(x => text = this.filterbytext + " " + x);
        break;
      case "datedue":
        this.translateService.get("KANBAN.SORT-TYPE.TYPE-DUEDATE").subscribe(x => text = this.filterbytext + " " + x);
        break;
      case "dateend":
        this.translateService.get("KANBAN.SORT-TYPE.TYPE-DATEEND").subscribe(x => text = this.filterbytext + " " + x);
        break;
    }
    return text;
  }

  updateListVisibility()
  {
    this.list.prefVisible = !this.list.prefVisible;
    if (!this.list.title)
      return;
    this.kbs.updateListVisibility(this.list.id, this.list.prefVisible).subscribe(x =>
    {
    });
  }

  updateListTitle()
  {
    this.list.title = this.list.title.trim();
    if (!this.list.title)
      return;
    this.kbs.updateListName(this.list.id, this.list.title).subscribe(x =>
    {
      this.kbs.isEditionListName = 0;
    });
  }

  archiveList()
  {
    this.kbs.archiveList(this.list.id).subscribe(x =>
    {
      this.list.archived = true;
      this.kbs.allBoardList.find(x => x.id == this.board.id).lists.find(x => x.id == this.list.id).archived = true;
      this.popoverArchiveAllCards = false;
    })
  }

  archiveCardInList()
  {
    this.kbs.archiveCardInList(this.list.id).subscribe(x =>
    {
      this.list.cards.forEach(x =>
      {
        x.archived = true;
      });
      this.popoverArchiveAllCards = false;
    });
  }

  openCreateFromTemplateModal(idtemplate: number, openAfterCreation = false)
  {
    if (openAfterCreation)
      event.preventDefault();
    this.openAfterC = openAfterCreation;
    let title = "";
    this.translateService.get("KANBAN.CARD-TEMPLATE.APPLY").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanCardTemplateInputManagerComponent,
      nzWidth: '80%',
      nzFooter: [
        {
          label: close,
          onClick: () =>
          {
            modal.close();
          }
        },
        {
          label: confirm,
          type: "primary",
          onClick: async (result) =>
          {
            let card = new KanbanCard();// await firstValueFrom(this.kbs.getCardInfos(idtemplate));
            card.id = idtemplate;
            card.title = result.cardTitle;
            card.position = this.list.cards.length + 1;
            this.kas.applyActions(card, this.list.id, this.board, "0", this.addCreateCardFromTemplatePostProcessing, this.cancelAction, this);
            modal.close();
          }
        }
      ]
    });
  }

  addCreateCardFromTemplatePostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanListComponent)
  {
    let cardinfo = new KanbanCardCreationFromTemplate();
    cardinfo.cardTemplateId = card.id;
    cardinfo.cardTitle = card.title;
    cardinfo.listId = comp.list.id;
    comp.kbs.createCardFromTemplate(cardinfo).subscribe(async x =>
    {
      card.id = x.id;
      actionparam.cardId = card.id;
      comp.list.cards.push(card);
      card.visible = true;
      comp.kbs.createdCard = new KanbanCard();
      comp.kbs.isAddingCardToList = 0;
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
      var objDiv = document.getElementById("kanbanlist_" + comp.list.id);
      objDiv.scrollTop = objDiv.scrollHeight;
      if (comp.list.autofilter)
        comp.kbs.sortCartManually(comp.list).subscribe();
      if (comp.openAfterC)
        comp.kbs.editCard(card.id);
    });
  }

  moveListToAnotherBoard(action: string)
  {
    let title = "";
    if (action == 'move')
      this.translateService.get("KANBAN.MOVE-LIST.TITLE", { v: this.list.title }).subscribe(x => title = x);
    else this.translateService.get("KANBAN.MOVE-LIST.COPY-TITLE", { v: this.list.title }).subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalMove = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanMoveListComponent,
      nzWidth: '80%',
      nzFooter: null
    });
    this.kbs.modalMove.componentInstance.list = this.list;
    this.kbs.modalMove.componentInstance.action = action;
  }

  restoreList()
  {
    this.kbs.restoreList(this.list.id).subscribe(x =>
    {
      this.list.archived = false;
      let thelist = this.cds.selectedBoard.lists.find(x => x.id == this.list.id);
      if (thelist)
        thelist.archived = false;
      else this.cds.selectedBoard.lists.push(this.list);
      this.kbs.reloadBoardArchivesSub.next(null);
    })
  }

  drop(event: CdkDragDrop<KanbanCard[]>): void
  {
    if (event.previousContainer === event.container)
    {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else
    {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    setTimeout(() =>
    {
      for (let list of this.board.lists)
      {
        list.undroppable = false;
      }
      this.kas.applyActions(event.item.data, this.list.id, this.board, "2-" + this.kbs.draggedCardOriginList.id, this.addCardMovedPostProcessing, this.cancelMove, this);
      this.kbs.dragOngoing = false;
    }, 300);
  }

  cancelMove(card: KanbanCard, comp: KanbanListComponent) 
  {
    comp.kbs.draggedCardOriginList.cards.splice(comp.kbs.positionCardDrag, 0, card);
    let index = comp.list.cards.findIndex(x => x.id == card.id);
    if (index >= 0)
      comp.list.cards.splice(index, 1);
  }

  addCardMovedPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanListComponent)
  {
    let movecard = new KanbanMoveCard();
    movecard.idBoard = comp.board.id;
    movecard.idListFrom = comp.kbs.draggedCardOriginList.id;
    movecard.idListTo = comp.list.id;
    movecard.idCard = card.id;
    movecard.listCardDestination = comp.list.cards.map(({ id }) => id);
    movecard.listCardOrigin = comp.kbs.draggedCardOriginList.cards.filter(x => x.id != card.id).map(({ id }) => id);

    comp.kbs.moveCard(movecard, card.id).subscribe(async x =>
    {
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
      if (comp.list.autofilter)
        comp.kbs.sortCartManually(comp.list).subscribe();
      comp.restoreOnClose = false;
      comp.kbs.modalEdit.close();
    });
  }

  onDrop(card: any)
  {
    for (let list of this.board.lists)
    {
      list.undroppable = false;
    }

    let rel = this.kbs.draggedCardOriginList.allowedDeplacementListIds.find(x => x.targetListId == this.list.id);

    let filteredActionList = this.list.actionsAtCardCreation.filter(x => x.triggerAction == "2");
    let filteredActionCard = card.actions ? card.actions.filter(x => x.triggerAction == "2") : [];
    filteredActionList = filteredActionList.concat(filteredActionCard);

    if ((rel && rel.actionsRelation) || filteredActionList.length > 0)
    {
      if ((rel && (rel.actionsRelation.indexOf("SEND-MAIL-TEAM-MANUAL") >= 0 || rel.actionsRelation.indexOf("NOTIFY-USER-GENERAL-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-LABELS-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0 || rel.actionsRelation.indexOf("AFFECT-CARD-TEAMS-MANUAL") >= 0 || rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || rel.actionsRelation.indexOf("ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0 || rel.actionsRelation.indexOf("ASK-CONFIRMATION") >= 0))
        || (filteredActionList.findIndex(x => x.codeAction == "SEND-MAIL-TEAM-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "NOTIFY-USER-GENERAL-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-LABELS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-TEAMS-MANUAL") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0 || filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0 || filteredActionList.findIndex(x => x.codeAction == "ASK-CONFIRMATION") >= 0))
      {
        this.restoreOnClose = true;
        let title = "";
        this.translateService.get("KANBAN.WORKFLOW-MANAGE-TITLE").subscribe(x => title = x);
        let confirm = "";
        this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
        let cancel = "";
        this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
        this.kbs.modalEdit = this.modalService.create({
          nzTitle: title,
          nzContent: KanbanActionsManagerComponent,
          nzWidth: '90%',
          nzClosable: false,
          nzMaskClosable: false,
          nzFooter: [{
            label: confirm,
            type: 'primary',
            disabled: (result) =>
            {
              if (result.comment.length == 0 && rel && rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
                return true;
              if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
                return true;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return true;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return true;
              if (!result.dueDate && rel && rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0)
                return true;
              if (!result.dueDate && filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
                return true;
              if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return true;
              if (result.listThirdPartyToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return true;
              return false;
            },
            onClick: (result) =>
            {
              if (result.comment.length == 0 && rel && rel.actionsRelation.indexOf("ASK-COMMENT-BEFORE-DEPLACEMENT") >= 0)
                return;
              if (result.description.length == 0 && filteredActionList.findIndex(x => x.codeAction == "ASK-DESCRIPTION-BEFORE-DEPLACEMENT") >= 0)
                return;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return;
              if (result.users.length > 0 && result.listMembersToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-CARD-MEMBERS-MANUAL") >= 0)
                return;
              if (!result.dueDate && rel && rel.actionsRelation.indexOf("SET-DUE-DATE") >= 0)
                return;
              if (!result.dueDate && filteredActionList.findIndex(x => x.codeAction == "SET-DUE-DATE") >= 0)
                return;
              if (result.listThirdPartyToAffect.length == 0 && filteredActionList.findIndex(x => x.codeAction == "AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return;
              if (result.listThirdPartyToAffect.length == 0 && rel && rel.actionsRelation.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0)
                return;

              this.updateInterfaceAfterDrop(card).subscribe(x =>
              {
                this.kbs.applyWorkflowActions(card, this.kbs.draggedCardOriginList, rel, filteredActionList, result.comment, result.listMembersToAffect, result.listTeamsToAffect, result.dueDate, result.listLabelsToAffect, result.listThirdPartyToAffect, result.description, this.kbs.draggedCardOriginList.id == this.list.id);
                let notify = new NotifyUserForAction();
                notify.trigger = "2";
                notify.oldIdList = this.kbs.draggedCardOriginList.id;
                notify.userToNotify = result.listUsersToNotified;
                notify.teamToNotify = result.listTeamsToNotified;
                this.kbs.notifyUser(card.id, rel, filteredActionList, notify);
                if (this.list.autofilter)
                  this.kbs.sortCartManually(this.list).subscribe();
                this.restoreOnClose = false;
                this.kbs.modalEdit.close();
              })
            }
          },
          {
            label: cancel,
            onClick: () =>
            {
              this.restoreOnClose = false;
              this.kbs.draggedCardOriginList.cards.splice(this.kbs.positionCardDrag, 0, card);
              this.kbs.modalEdit.close();
            }
          }
          ]
        });

        this.kbs.modalEdit.componentInstance.board = this.board;
        this.kbs.modalEdit.componentInstance.card = card;
        this.kbs.modalEdit.componentInstance.listid = this.list.id;
        this.kbs.modalEdit.componentInstance.relation = rel;
        this.kbs.modalEdit.componentInstance.trigger = "2";

        this.kbs.modalEdit.afterClose.subscribe((result) =>
        {
          if (this.restoreOnClose)
            this.kbs.draggedCardOriginList.cards.splice(this.kbs.positionCardDrag, 0, card);
        });

      }
      else
      {
        this.updateInterfaceAfterDrop(card).subscribe(x =>
        {
          this.kbs.applyWorkflowActions(card, this.kbs.draggedCardOriginList, rel, filteredActionList, null, null, null, null, null, null, "", this.kbs.draggedCardOriginList.id == this.list.id);
          if (this.list.autofilter)
            this.kbs.sortCartManually(this.list).subscribe();
        })
      }
    }
    else
    {
      this.updateInterfaceAfterDrop(card).subscribe(x =>
      {
        if (this.list.autofilter)
          this.kbs.sortCartManually(this.list).subscribe();
      })
    }
    this.kbs.dragOngoing = false;
  }

  getCards()
  {
    //return this.list.cards;
    let partialCards: KanbanCard[] = [];
    let temp: KanbanCard[] = [];
    for (let card of this.list.cards)
    {
      if (card.visible)
        temp.push(card);
    }
    let upperEchelon = Math.min(temp.length, this.kbs.loadingPack * this.kbs.loadingNumber);

    for (let i = 0; i < upperEchelon; i++)
    {
      partialCards.push(temp[i]);
    }

    return partialCards;
  }

  updateInterfaceAfterDrop(card: KanbanCard)
  {
    let movecard = new KanbanMoveCard();
    movecard.idBoard = this.board.id;
    movecard.idListFrom = this.kbs.draggedCardOriginList.id;
    movecard.idListTo = this.list.id;
    movecard.idCard = card.id;
    movecard.listCardDestination = this.list.cards.map(({ id }) => id);
    movecard.listCardOrigin = this.kbs.draggedCardOriginList.cards.filter(x => x.id != card.id).map(({ id }) => id);

    return this.kbs.moveCard(movecard, card.id);
  }

  onDragStart(event: DragEvent, effect: DropEffect, add: boolean)
  {
    if (effect === "move")
    {
    }
  }

  nope()
  {
    event.stopImmediatePropagation();
  }

  getDropZoneType()
  {
    if (this.list.undroppable)
      return "motherfkinNothing"
    return "card";
  }

  cancelAction(card: KanbanCard, comp: KanbanListComponent) { }

  openAfterC = false;
  async addCardToList(openAfterCreation = false)
  {
    this.openAfterC = openAfterCreation;
    if (!this.createdCard.title.trim())
      return;
    let card = this.kbs.deepCopy(this.createdCard) as KanbanCard;
    card.position = this.list.cards.length + 1;
    this.kas.applyActions(card, this.list.id, this.board, "0", this.addCardPostProcessing, this.cancelAction, this);
    /*
    this.kts.createCardProcess(card, this.list, this.board);
    this.kbs.isAddingCardToList = 0;
    */
    this.optionCreateCardOpen = false;
  }

  addCardPostProcessing(actionparam: KanbanActionParameters, card: KanbanCard, comp: KanbanListComponent)
  {
    comp.kbs.addCardToList(comp.list.id, card).subscribe(async x =>
    {
      card.id = x.id;
      actionparam.cardId = card.id;
      comp.list.cards.push(card);
      card.visible = true;
      comp.kbs.createdCard = new KanbanCard();
      comp.kbs.isAddingCardToList = 0;
      await firstValueFrom(comp.kas.applyListActionsForTrigger(actionparam));
      var objDiv = document.getElementById("kanbanlist_" + comp.list.id);
      objDiv.scrollTop = objDiv.scrollHeight;
      if (comp.list.autofilter)
        comp.kbs.sortCartManually(comp.list).subscribe();
      if (comp.openAfterC)
        comp.kbs.editCard(card.id);
    });
  }

  addAndEditCard()
  {
    if (!this.createdCard.title)
      return;
    let card = this.kbs.deepCopy(this.createdCard);
    card.position = this.list.cards.length + 1;
    this.kts.createCardProcess(card, this.list, this.board, true);
    this.kbs.isAddingCardToList = 0;
    this.optionCreateCardOpen = false;
    return false;
  }

  getAllListAllowed()
  {
    if (this.list.allowedDeplacementListIds.length == 0)
      return this.board.lists.map(x => "kanbanlist_" + x.id);
    return this.list.allowedDeplacementListIds.map(x => "kanbanlist_" + x.targetListId);
    //list.push("kanbanlist_" + this.list.id)
  }

  manageListRelations()
  {
    let title = "";
    this.translateService.get("KANBAN.MANAGE-WORKFLOW").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanWorkflowSettingsComponent,
      nzWidth: '90%',
      nzBodyStyle: { height: '75vh' },
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalEdit.close(); }
      }
      ]
    });
    this.kbs.modalEdit.componentInstance.board = this.board;
    this.kbs.modalEdit.componentInstance.list = this.list;
  }
  manageActionsAtCardCreation()
  {
    let title = "";
    this.translateService.get("KANBAN.MANAGE-LIST-ACTIONS-CARD-CREATION").subscribe(x => title = x);
    let titleConfirm = "";
    this.translateService.get("KANBAN.ACTION-CONFIRM-CLOSE-TITLE").subscribe(x => titleConfirm = x);
    let contentConfirm = "";
    this.translateService.get("KANBAN.ACTION-CONFIRM-CLOSE-CONTENT").subscribe(x => contentConfirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzBodyStyle: { height: '85vh' },
      nzContent: KanbanListActionsEditorComponent,
      nzWidth: '90%',
      nzFooter: [{
        label: close,
        onClick: (result) =>
        {
          if (result.hasUpdated)
          {
            let confirmModal = this.modalService.confirm({
              nzTitle: titleConfirm,
              nzContent: contentConfirm,
              nzCancelText: cancel,
              nzOkText: close,
              nzOnOk: () =>
              {
                this.kbs.modalEdit.close();
                confirmModal.destroy();
              }

            });
          }
          else
          {
            this.kbs.modalEdit.close();
          }


        }
      }, {
        label: save,
        type: "primary",
        onClick: (result) =>
        {
          result.kas.updateListActionsForTrigger(this.list.id, result.actionsManager).subscribe(() =>
          {
            for (let i = 0; i < this.list.actions.length; i++)
            {
              if (this.list.actions[i].trigger == result.actionsManager.trigger)
                this.list.actions[i].actions = result.actionsManager.actions;
            }
            result.hasUpdated = false;
            let text = "";
            this.translateService.get("GENERIC-ACTIONS.MODIFICATIONS-SAVED").subscribe(x => text = x);
            this.msg.success(text);
          });
        }
      }]
    });
    this.kbs.modalEdit.componentInstance.board = this.board;
    this.kbs.modalEdit.componentInstance.list = this.list;
  }

  editListStyle()
  {
    let title = "";
    this.translateService.get("KANBAN.LIST-STYLE.TITLE").subscribe(x => title = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: EditListStyleComponent,
      nzWidth: '80%',
      nzFooter: [{
        label: save,
        type: 'primary',
        onClick: (result) =>
        {
          this.list.color = result.bgcolor;
          this.list.fontColor = result.fontcolor;
          this.kbs.updateListStyle(this.list).subscribe(() =>
          {
            modal.close();
          })
        }
      },
      {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.list = this.list;
  }

  editListSort()
  {
    let title = "";
    this.translateService.get("KANBAN.LIST-STYLE.TITLE").subscribe(x => title = x);
    let save = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => save = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: EditListSortComponent,
      nzWidth: '80%',
      nzFooter: [{
        label: save,
        type: 'primary',
        onClick: (result) =>
        {
          this.list.autofilterType = result.typesort;
          this.list.autofilterDirection = result.direction;
          this.kbs.updateListSort(this.list).subscribe(() =>
          {
            if (this.list.autofilter)
              this.kbs.sortCartManually(this.list).subscribe();
            modal.close();
          });
        }
      },
      {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.list = this.list;
  }

  toogleWhite(hex)
  {
    if (this.list.fontColor != "#000000")
      return this.list.fontColor;
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result)
    {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      if ((r + g + b) > 620)
        return "black";
    }
    return "white"
  }

  setOption(opt: number)
  {
    switch (opt)
    {
      case 0:
        this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.BASE").subscribe(c => this.optionsTitle = c);
        break;
      case 1:
        this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.MEMBERS").subscribe(c => this.optionsTitle = c);
        break;
      case 2:
        this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.LABELS").subscribe(c => this.optionsTitle = c);
        break;
      case 3:
        this.translateService.get("KANBAN.ADD-CARD-OPTIONS-TITLE.POSITION").subscribe(c => this.optionsTitle = c);
        break;
      case 4:
        this.translateService.get("KANBAN.ADD-CARD-OPTIONS-LABEL.BUTTON-SHOWER").subscribe(c => this.optionsTitle = c);
        this.kbs.setFocusToElement("create-label-input-list");
        break;
    }
    this.addCardOption = opt;
    this.optionCreateCardOpen = false;
    this.optionCreateCardOpen = true;
  }

  userIsSelected(user: number)
  {
    return this.createdCard.members.find(x => x == user) != null;
  }

  changeUserSelection(user: number)
  {
    let labelInListIndex = this.createdCard.members.findIndex(x => x == user);
    if (labelInListIndex == -1)
      this.createdCard.members.push(user);
    else this.createdCard.members.splice(labelInListIndex, 1);
  }

  editLabel(label: GlobalLabel)
  {
    this.createdLabel.id = label.id;
    this.createdLabel.title = label.title;
    this.createdLabel.color = label.color;
    this.addCardOption = 4;

  }

  labeIsSelected(label: GlobalLabel)
  {
    return this.createdCard.labels.find(x => x.title == label.title) != null;
  }

  changeLabelSelection(label)
  {
    let labelInListIndex = this.createdCard.labels.findIndex(x => x.id == label.id);
    if (labelInListIndex == -1)
      this.createdCard.labels.push(label);
    else this.createdCard.labels.splice(labelInListIndex, 1);
  }

  setCreatedLabelColor(colorValue)
  {
    this.createdLabel.color = colorValue;
  }

  createOrSaveLabel()
  {
    if (this.createdLabel.id == 0)
      this.createLabel();
    else this.saveLabel();
  }

  createLabel()
  {
    if (!this.createdLabel.title)
      return;
    let label = this.kbs.deepCopy(this.createdLabel);
    this.kbs.createLabel(label, this.board.id).subscribe(x =>
    {
      label.id = x;
      this.cds.labelList.push(label);
      this.cds.labelList = this.cds.labelList.sort((x, y) => (x.title > y.title) ? 1 : -1);
      this.createdLabel = new GlobalLabel();
      this.setOption(2);
    });
  }

  saveLabel()
  {
    if (!this.createdLabel.title)
      return;
    this.kbs.updateLabel(this.createdLabel).subscribe(x =>
    {
      let label = this.cds.labelList.find(x => x.id == this.createdLabel.id);
      label.title = this.createdLabel.title;
      label.color = this.createdLabel.color;
      this.addCardOption = 2;
      this.createdLabel = new GlobalLabel();
    });
  }

  deleteLabel()
  {
    this.kbs.deleteLabel(this.createdLabel.id).subscribe(x =>
    {
      let index = this.cds.labelList.findIndex(x => x.id == this.createdLabel.id);
      this.cds.labelList.splice(index, 1);
      this.addCardOption = 2;
      this.createdLabel = new GlobalLabel();
    });
  }

  initCardCreation()
  {
    if (this.kbs.showLoadingBoard)
    {
      this.translateService.get("KANBAN.ERROR.CANNOT-CREATE-CARD-BOARD-LOADING").subscribe(x => this.msg.info(x))
      return;
    }

    this.authService.checkLoginToken();
    this.createdCard = new KanbanCard();
    this.kbs.isAddingCardToList = this.list.id;
    this.kbs.setFocusToElement("kanbancardtextarea0");

  }

  cancel() { }

  cancelCardCreation()
  {
    this.createdCard = new KanbanCard();
    this.kbs.isAddingCardToList = 0;
    this.optionCreateCardOpen = false;
    this.addCardOption = 0;
  }

  initEditListComment()
  {
    this.editListComment = this.list.comment;
  }

  cancelEditListComment()
  {
    this.showEditCommentList = false;
  }
  updateListComment()
  {
    this.kbs.updateListComment(this.list.id, this.editListComment).subscribe(x =>
    {
      this.list.comment = this.editListComment;
      this.showEditCommentList = false;
    })
  }

  updateAutomaticSort()
  {
    this.list.autofilter = !this.list.autofilter;
    this.kbs.updateListAutofilter(this.list.id, this.list.autofilter).subscribe(() =>
    {
      if (this.list.autofilter)
        this.kbs.sortCartManually(this.list).subscribe();
    })

  }

  updateCardCreationAuthorization()
  {
    let bool = !this.list.canCreateCard;
    this.kbs.updateCardCreationAuthorization(this.list.id).subscribe(() =>
    {
      this.list.canCreateCard = bool;
    })
  }

  addMemberToFilter(id: number)
  {
    if (this.list.filterList.memberIds.findIndex(x => x == id) >= 0)
      return;
    this.list.filterList.memberIds.push(id);
    this.kbs.updateListFilters(this.list.id, this.list.filterList).subscribe(() =>
    {
      this.memberIdFilter = null;
      this.kbs.filterBoard(this.board);
    })
  }

  deleteFilterMember(id: number)
  {
    let index = this.list.filterList.memberIds.findIndex(x => x == id);
    if (index >= 0)
    {
      this.list.filterList.memberIds.splice(index, 1);
      this.kbs.updateListFilters(this.list.id, this.list.filterList).subscribe();
      this.kbs.filterBoard(this.board);
    }
  }

  addLabelToFilter(id: number)
  {
    if (this.list.filterList.labelIds.findIndex(x => x == id) >= 0)
      return;
    this.list.filterList.labelIds.push(id);
    this.kbs.updateListFilters(this.list.id, this.list.filterList).subscribe(() =>
    {
      this.labelIdFIlter = null;
      this.kbs.filterBoard(this.board);
    })
  }

  deleteFilterLabel(id: number)
  {
    let index = this.list.filterList.labelIds.findIndex(x => x == id);
    if (index >= 0)
    {
      this.list.filterList.labelIds.splice(index, 1);
      this.kbs.updateListFilters(this.list.id, this.list.filterList).subscribe();
      this.kbs.filterBoard(this.board);
    }
  }

  userIsIn(id: number)
  {
    if (this.list.filterList.memberIds.findIndex(x => x == id) >= 0)
      return true;
    return false;
  }

  labelIsIn(id: number)
  {
    if (this.list.filterList.labelIds.findIndex(x => x == id) >= 0)
      return true;
    return false;
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.avatar;
  }

  getNameForUSer(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (!user)
      return "";
    return user.name + ' ' + user.surname;
  }

  getNameForLabel(idlabel: number)
  {
    let label = this.cds.labelList.find(x => x.id == idlabel);
    if (!label)
    {
      label = this.cds.publicLabelList.find(x => x.id == idlabel);
      if (!label)
        return "Unknown label";
      return label.title
    }
    return label.title;
  }

  getColorForLabel(idlabel: number)
  {
    let label = this.cds.labelList.find(x => x.id == idlabel);
    if (!label)
    {
      label = this.cds.publicLabelList.find(x => x.id == idlabel);
      if (!label)
        return "black";
      return label.color
    }
    return label.color;
  }

}
