export class DateSchedulingParameter
{
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
}

export class PlannedActionFilters
{
    registerDateStart: Date;
    registerDateEnd: Date;
    executionDateStart: Date;
    executionDateEnd: Date;
    userId: number;
    codeAction: string;
    completionState: number;
}

export class PlannedAction
{
    id: number;
    registerDate: Date;
    userId: number;
    executionDate: Date;
    codeAction: string;
    parametersAction: string;
    completedDate: Date;
}