// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  border: 1px solid;
  border-left: 5px solid;
  padding: 13px 10px 10px 10px;
  margin: 5px;
  border-radius: 5px;
}

.info {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/SDICalendar/calendar/conflict-alert/conflict-alert.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,sBAAA;EACA,4BAAA;EACA,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".alert {\r\n    border: 1px solid;\r\n    border-left: 5px solid;\r\n    padding: 13px 10px 10px 10px;\r\n    margin: 5px;\r\n    border-radius: 5px;\r\n}\r\n\r\n.info {\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
