import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoomMeetingParticipantsComponent } from './zoom-meeting-participants.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NzTableModule,
    NzToolTipModule,
    TranslateModule,
    PipesModule,
    LoadingModule,
    NzCheckboxModule,
    FormsModule
  ],
  declarations: [ZoomMeetingParticipantsComponent],
  exports: [ZoomMeetingParticipantsComponent]
})
export class ZoomMeetingParticipantsModule { }
