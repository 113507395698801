import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoomMeetingComponent } from './zoom-meeting.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { InputFocusDirectiveModule } from 'src/app/directives/input-focus-directive/input-focus-directive.module';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  imports: [
    CommonModule,
    NzFormModule,
    TranslateModule,
    NzInputModule,
    NzIconModule,
    FormsModule,
    InputFocusDirectiveModule,
    NzButtonModule
  ],
  declarations: [ZoomMeetingComponent],
  exports: [ZoomMeetingComponent]
})
export class ZoomMeetingModule { }
