import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlSafePipe } from './html-safe.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HtmlSafePipe],
  exports: [HtmlSafePipe]
})
export class HtmlSafeModule { }
