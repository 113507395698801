import { Component, OnInit, Input } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';
import { Playlist } from '../../entities/Playlist';
import { PlaylistViewerParams } from '../../entities/PlaylistVIsualisorParams';

@Component({
  selector: 'app-playlist-spotify',
  templateUrl: './playlist-spotify.component.html',
  styleUrls: ['./playlist-spotify.component.scss']
})
export class PlaylistSpotifyComponent implements OnInit
{

  userPlaylist: Playlist[] = [];
  constructor(public spotifyService: SpotifyService) { }

  ngOnInit()
  {
    this.spotifyService.getPlaylistFromCategories(this.spotifyService.selectedCategory.id).subscribe(x => this.userPlaylist =  x.playlists.items);
  }
  setPlaylist(playlist: Playlist)
  {
    this.spotifyService.selectedPlaylist = new PlaylistViewerParams(playlist, false);
    this.spotifyService.selectedCategory = null;
    this.spotifyService.selectedMode = "playlist";
    this.spotifyService.selectedPlaylistChange.next(this.spotifyService.selectedPlaylist.playlist);
  }
}
