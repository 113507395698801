import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzSelectModule,
    FormsModule,
    NzFormModule
  ],
  declarations: [SelectComponent],
  exports: [SelectComponent]
})
export class SelectModule { }
