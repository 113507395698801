import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KanbanNotificationComponent } from './kanban-notification.component';

const appChildrenRoutes: Routes = [
  { path: '', component: KanbanNotificationComponent },
  ];

@NgModule({
    imports: [
        RouterModule.forChild(appChildrenRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class KanbanNotificationRoutingModule { }
