import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayEventsComponent } from './day-events.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { KanbanCardEditModalModule } from '../../../../src/app/kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.module';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  imports: [
    CommonModule,
    NzDividerModule,
    NzGridModule,
    NzButtonModule,
    KanbanCardEditModalModule,
    TranslateModule
  ],
  declarations: [DayEventsComponent],
  exports: [DayEventsComponent]
})
export class DayEventsModule { }
