// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mail-action-icons {
  margin-left: 5px;
}

.text-style {
  font-size: 15px;
  color: black;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  box-shadow: 1px 4px 10px 2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 15px;
  background-color: white;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/kanban/components/kanban-card-mails/kanban-card-mails.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEE;EACE,eAAA;EACA,YAAA;EACA,yBAAA;UAAA,iBAAA;EACA,kBAAA;EACA,+CAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACJ","sourcesContent":[".mail-action-icons{\r\n    margin-left: 5px;\r\n  }\r\n\r\n  .text-style {\r\n    font-size: 15px;\r\n    color: black;\r\n    user-select: none;\r\n    position: relative;\r\n    box-shadow: 1px 4px 10px 2px rgba(0, 0, 0, 0.4);\r\n    border-radius: 5px;\r\n    padding: 5px 10px;\r\n    margin-bottom: 15px;\r\n    background-color: white;\r\n    cursor: pointer;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
