// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checklist-template-selector {
  padding: 2px;
  margin: 3px;
  cursor: pointer;
  width: calc(100% - 100px);
  display: inline-block;
}

.checklist-template-selector:hover {
  background-color: lightgray;
}

.selected {
  background-color: gray;
}

.template-icons {
  font-size: 18px;
  cursor: pointer;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/kanban/components/checklist-template-manager/checklist-template-manager.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,eAAA;EACA,yBAAA;EACA,qBAAA;AACJ;;AACA;EACI,2BAAA;AAEJ;;AACA;EACI,sBAAA;AAEJ;;AACA;EACI,eAAA;EACA,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".checklist-template-selector{\r\n    padding: 2px;\r\n    margin: 3px;\r\n    cursor: pointer;\r\n    width: calc(100% - 100px);\r\n    display: inline-block;\r\n}\r\n.checklist-template-selector:hover{\r\n    background-color: lightgray;\r\n}\r\n\r\n.selected{\r\n    background-color: gray;\r\n}\r\n\r\n.template-icons{\r\n    font-size: 18px;\r\n    cursor: pointer;\r\n    margin-left: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
