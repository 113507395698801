import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Site } from '../../entities/site';
import { Subject } from 'rxjs';
import { UrlApiService } from 'src/app/services/url-api.service';
import { tap } from 'rxjs/operators';
import { GetSiteThirdParty } from '../entities/get-site-third-party';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient) { }

  isLoading = false;
  siteList: Site[] = [];
  siteListChange: Subject<Site[]> = new  Subject<Site[]>();
  
  getAllSites() {
    return this.http.get<Site[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site`).pipe(tap(x  => this.siteList = x))
  }
  
  createSite(site: Site) {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site`, site)
  }
  updateSite(site: Site) {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site/${site.id}`, site)
  }
  
  archiveSite(idsite: number) {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site/${idsite}/archive`, null)
  }
  restoreSite(idsite: number) {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site/${idsite}/restore`, null)
  }

  getThirdPartyForSite(idsite: number)
  {
    return this.http.get<GetSiteThirdParty>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site/${idsite}/third-party`)
  }

  addThirdPartyToSite(idsite: number, idthirdparty: number[])
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site/${idsite}/third-party/add-multiple`, idthirdparty)
  }

  removeThirdPartyFromSite(idsite: number, idthirdparty: number[])
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/site/${idsite}/third-party/remove-multiple`, idthirdparty)
  }
}
