// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sdk-select {
  height: 34px;
  border-radius: 4px;
}

.websdktest button {
  float: right;
  margin-left: 5px;
}

#nav-tool {
  margin-bottom: 0px;
}

#show-test-tool {
  position: absolute;
  top: 100px;
  left: 0;
  display: block;
  z-index: 99999;
}

#display_name {
  width: 250px;
}

#websdk-iframe {
  width: 700px;
  height: 500px;
  border: 1px;
  border-color: red;
  border-style: dashed;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin: 0;
}

#visio-black {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 9;
}`, "",{"version":3,"sources":["webpack://./src/app/zoom/zoom.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,OAAA;EACA,cAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,SAAA;EACA,SAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,uBAAA;EACA,UAAA;AAAF","sourcesContent":[".sdk-select {\r\n  height: 34px;\r\n  border-radius: 4px;\r\n}\r\n\r\n.websdktest button {\r\n  float: right;\r\n  margin-left: 5px;\r\n}\r\n\r\n#nav-tool {\r\n  margin-bottom: 0px;\r\n}\r\n\r\n#show-test-tool {\r\n  position: absolute;\r\n  top: 100px;\r\n  left: 0;\r\n  display: block;\r\n  z-index: 99999;\r\n}\r\n\r\n#display_name {\r\n  width: 250px;\r\n}\r\n\r\n\r\n#websdk-iframe {\r\n  width: 700px;\r\n  height: 500px;\r\n  border: 1px;\r\n  border-color: red;\r\n  border-style: dashed;\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  left: 50%;\r\n  margin: 0;\r\n}\r\n\r\n#visio-black {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  background-color: black;\r\n  z-index: 9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
