import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagerieImportProgressComponent } from './messagerie-import-progress.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzButtonModule,
    TranslateModule
  ],
  declarations: [MessagerieImportProgressComponent],
  exports: [MessagerieImportProgressComponent]
})
export class MessagerieImportProgressModule { }
