import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { KanbanService } from '../../../../src/app/kanban/services/kanban.service';
import { MenuItemsService } from '../../../../src/app/services/menu-items.service';
import { MenuItem } from '../../../../src/app/entities/menu-item';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalLabelService } from '../../../../src/app/global-label/global-label.service';
import { KanbanCard } from '../../kanban/entities/Card';
import { KanbanBoard, KanbanBoardType, KanbanBoardVisibility } from '../../kanban/entities/Board';
import { KanbanList } from '../../kanban/entities/List';
import { TodoService } from '../../kanban/services/todo.service'
import { UrlApiService } from '../../services/url-api.service';
import { HttpClient } from '@angular/common/http';
import { TodoSearchParams } from '../../../../src/app/entities/todo-search-params';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { KanbanCategory } from '../../../../src/app/kanban/entities/category';
import { SettingsService } from '../../../../src/app/settings/services/settings.service';
import { NotesService } from '../../../../src/app/notes/notes.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KanbanToolsService } from '../../../../src/app/kanban/services/kanban-tools.service';
import { ComponentType } from '@angular/cdk/portal';
import { THIRD_PARTY_SHEET } from '../../../../src/app/call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { InterModuleService } from '../../../../src/app/services/inter-module.service';
import { firstValueFrom, Observable } from 'rxjs';
import { ColorService } from '../../../../src/app/services/color.service';
import { TextCompareService } from '../../../../src/app/services/text-compare.service';
import { NameUserPipe } from '../../pipes/name-user-pipe/name-user.pipe';
import { interval } from 'rxjs';

import { Subscription } from 'rxjs';
import { KanbanBoardActivitiesComponent } from '../../../../src/app/kanban/components/kanban-board-activities/kanban-board-activities.component';
import { MessagerieFolderService } from '../../../../src/app/messagerie/messagerie-folder.service';
import { MessagerieService } from '../../../../src/app/messagerie/messagerie.service';
import { Receivedmail } from '../../../../src/app/messagerie/entities/receivedmail';
import { MessagerieFolder } from '../../../../src/app/messagerie/entities/messagerie-folder';
import { concatMap, filter } from 'rxjs/operators';

import { NzMessageService } from 'ng-zorro-antd/message';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy
{
  card: KanbanCard = new KanbanCard();
  board: KanbanBoard = new KanbanBoard();
  list: KanbanList = new KanbanList();
  number_task = 0;
  number_board = 0;
  number_card = 0;
  searchparams: TodoSearchParams = new TodoSearchParams();
  cardtwo: KanbanCard;
  cards = [];
  tmp_cards = [];
  boards = [];
  idcreateboardcategory = 0;
  createdBoard: KanbanBoard = new KanbanBoard();
  public enumType = KanbanBoardType;
  boardPublicIsCreating = false;
  boardStarredIsCreating = false;
  boardPersonalIsCreating = false;
  ErrorEmptyBoardName = false;
  boardListVisible = false;
  redirect = true;
  templateListVisible = false;
  createdCategory: KanbanCategory = new KanbanCategory();
  showTemplate = false;
  showAllPersonaBoards = false;

  tpfocus = 0;

  content_height: any;

  nb_barre = 100; // pourcentage de la barre d'actualisation

  actualWidget: any; // widget selectionné dans le changement de widget

  listOfLineToShow: Array<Array<any>> = [[]]; // liste représentant les lignes dans la modal 'MoreCard'

  screenWidth: any; // taille de l'écran

  progressWidth: any; // taille de la barre d'actualisation

  lineWidth: any; // taille d'une ligne dans la modal 'MoreCard' pour resize dynamiquement

  maxHeight: number; // taille max ddes cartes sur les lignes

  dateTosearch: Date; // date représentant celle pour le filtre 'Expected to finish before'

  alphabetique = true; // booléen représentant le type de sorting (par ordre alphabétique set a true des le départ pour avoir un sorting de base)

  debut = false; // booléen représentant le type de sorting (par date de début pour les cartes)

  fin = false; // booléen représentant le type de sorting (par date de fin pour les cartes)

  bycom = false; // booléen représentant le type de sorting (par date de création d'un commentaire pour les cartes)

  sortMethode = false; // booléen représentant le sorting en ascendant ou descendant (false = ascendant / true = descendant)

  remainingTime: number = 5 * 60; // timer de 5 minutes pour l'actualisation des widgets

  timer: Subscription;

  checked_card = false; // filtre 1 des cartes : To finish today
  checked2_card = false; // filtre 2 des cartes : Assign tasks
  checked3_card = false; // filtre 3 des cartes : Expected to finish before
  checked4_card = false; // filtre 4 des cartes : Overdue tasks
  checked5_card = false; // filtre 5 des cartes : Starting today
  checked6_card = false; // filtre 6 des cartes : Comment
  checked7_card = false; // filtre 7 des cartes : Cards per label
  checked8_card = false; // filtre 8 des cartes : Third-party cards
  checked9_card = false; // filtre 9 des cartes : Cards per member
  checked10_card = false; // filtre 10 des cartes : End date relative to current end date
  checked11_card = false;
  checked12_card = false;
  checked13_card = false;

  titleToSearch = "";
  descToSearch = "";

  checked_messagerie = false; // filtre 1 de la messagerie
  checked2_messagerie = false; // filtre 2 de la messagerie
  checked3_messagerie = false; // filtre 3 de la messagerie
  checked4_messagerie = false; // filtre 4 de la messagerie
  checked5_messagerie = false; // filtre 5 de la messagerie
  checked6_messagerie = false; // filtre 6 de la messagerie
  checked7_messagerie = false; // filtre 7 de la messagerie

  email_object = "";
  email_sender = "";
  email_recipient = "";
  email_content = "";
  email_account = "";
  showEmail = false;
  currentEmail: any;
  listOfOption: Array<{ value: string; label: string }> = [];
  listOfSelectedValue: string[] = [];

  modal_emailWidth = "";
  modal_emailHeight = "";

  canReload = true;

  errorInWidget = false;

  errorMeteo = false;

  errorVisitTab = false;

  errorInCardWidget = false;
  errorInCardWidgetFillField = false;

  errorInEmailWidget = false;
  errorInEmailWidgetFillField = false;

  showNameInput = true;

  minute_date = 0; // minute du filtre des cartes : End date relative to current end date
  hour_date = 0; // heure du filtre des cartes : End date relative to current end date
  day_date = 0; // jour du filtre des cartes : End date relative to current end date
  month_date = 0; // mois du filtre des cartes : End date relative to current end date
  year_date = 0; // année du filtre des cartes : End date relative to current end date

  showWidgets = false;

  complicate_filter = false; // booléen représentant si le combined filter mode est active ou pas

  commentary: string; // string représentant le commentaire à rechercher dans le filtre des cartes : Comment

  WeatherData: any; // variable pour stocker temporairement les datas pour le/les widget météo

  city: string = null; // string représentant la ville choisi par l'user pour le widget météo

  image_meteo: string = null; // string représentant le path de la photo à afficher pour le widget météo

  ChangeCardFilter = false; // booléen pour faire apparaître la modal de changement de filtre pour les cartes

  ChangeMailFilter = false; // booléen pour faire apparaître la modal de changement de filtre pour les mail

  moreCard = false; // booléen pour faire apparaître la modal afficahnt toute les cartes d'un widget filtre carte

  moreMail = false;

  tmps: any;

  allLabelsForUser: any[] = []; //Liste représentant tous les labels concernant le current user

  currentLabel: any[] = []; // liste représentant les labels selectionnés

  listLabelsIds: number[] = []; // liste représentant les id des labels selectionnés

  currentTiers: number[] = []; // liste représentant les id des tiers selectionnés

  tiersid: number[] = []; // liste représentant les id des tiers selectionnés

  memberid: number[] = []; // liste représentant les id des membres selectionnés

  currentmember: number[] = []; // liste représentant les id des membres selectionnés

  idCard: number = null; // id du widget filtre carte selectionné

  actualFiltre: boolean[] = null; // liste de booléen temporaire pour la liste des filtres selectionnés

  Filtre_9 = false; // booléen pour l'affichage de la modal de création du widget filtre carte

  MostVisitedTab = false; // booléen pour le widget des tableaux les plus visités

  FiltreMessagerie = false // booléen pour le widget de création du widget filtre messagerie

  widget_meteo = false; // booléen pour l'affichage de l'input de création du widget météo

  single_board: any;

  visible: boolean = false;

  allWidgetList: TmpClass[];

  CardFilterName: string = ""; // tampon du title d'un widget

  WidgetFilterCard: WidgetCarteFiltre[] = []; //liste des widgets

  typeOfWidgetToChange: number;

  filterValue: string = "";

  constructor(public authService: AuthService, public kbsService: KanbanService,
    public ims: InterModuleService,
    @Inject(THIRD_PARTY_SHEET) private component: ComponentType<any>,
    private translateService: TranslateService,
    private router: Router,
    public gls: GlobalLabelService,
    public sanitizer: DomSanitizer,
    public mis: MenuItemsService,
    private http: HttpClient,
    public cds: CacheDataService,
    private settingsService: SettingsService,
    public ns: NotesService,
    private modalService: NzModalService,
    public cs: ColorService,
    public tcs: TextCompareService,
    public mfs: MessagerieFolderService,
    public mss: MessagerieService,
    private message: NzMessageService
  ) { }

  readonly VAPID_PUBLIC_KEY = "BFyx9gTYjfUjBzhSSLdPfgfGMjAdjZz8Xb_o2ivLyuqcXRf0rLpG4XANZU8T5Z4q6r1jw_IRKbYEHxOAoOCaML8";
  // displayMail = true;
  showAlertMain = false;
  labelOption = 2;
  editedItem: MenuItem = new MenuItem();
  hueFontColor = "";
  hueBackgrounColor = "";
  editedBackgroundColor = "";
  editedFontColor = "";
  editedTitle = "";
  urlmeteo;

  ngOnInit()
  {
    this.authService.setLastVisitedPage();
    this.authService.setCamelineTitle("home");

    // fonction pour get la window size pour resize
    this.getScreenSize();

    // fonction pour get les boards du current user
    this.get_all_board();

    try
    {
      // 1er get des informations des widgets à l'arrivée sur la home page
      this.get_filter().subscribe(x =>
      {
        this.allWidgetList = x;
        console.log("get response : ", x);
        if (this.allWidgetList != null && this.allWidgetList.length >= 0)
        {
          this.startTimer();
          this.makeWidgets(); // fonctino de création des widgets avec les infos recupéré depuis la db
        }
      });
    }
    catch (error)
    {
      console.log('error occured : ', error);
    }

    this.getAllMailAccountCurrentUser();

    // get public labels
    if (this.cds.publicLabelList.length == 0)
    {
      this.kbsService.getPublicLabelList().subscribe();
    }

    // fonction pour get les labels ou le current user est identifié
    this.getCardsForLabels().subscribe(n =>
    {
      let idx = 0;

      //Verification des labels recu pour ne pas en avoir en double
      while (idx < n.length)
      {
        if (this.allLabelsForUser.length > 0)
        {
          let idx2 = 0;
          let x = 0;
          while (idx2 < this.allLabelsForUser.length)
          {
            if (this.allLabelsForUser[idx2].id == n[idx].id)
            {
              x = 1;
            }
            idx2 += 1;
          }
          if (x == 0)
          {
            this.allLabelsForUser.push(n[idx]);
          }
        }
        else
          this.allLabelsForUser.push(n[idx]);
        idx += 1;
      }
    });
  }

  ngOnDestroy(): void
  {
    if (this.timer != null)
      this.timer.unsubscribe();
  }

  getAllCardForConnectedUser()
  {
    return this.http.post<KanbanCard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/todo/affected`, this.searchparams)
  }

  // fonction pour get toute les cartes du current user
  get_all_card()
  {
    let nb = 0;
    this.getAllCardForConnectedUser().subscribe(x =>
    {
      this.cards = x;
      this.tmp_cards = x;
      for (let nb_card in this.cards)
      {
        nb += 1;
      }
      this.number_task = nb;
    })
  }

  // fonction pour get tous les boards du current user
  get_all_board()
  {
    this.kbsService.getAllBoardForConnectedUser().subscribe(x =>
    {
      this.boards = x;
      this.board = this.boards[0];
      this.postBoardsForLabels(x).subscribe(y =>
      {
      });
    })
  }

  getLabelContainerHeight()
  {
    return (window.innerHeight - 120) * 0.8 + "px";
  }

  // fonction get information pour widget météo
  getWeatherData(city, id)
  {
    if (city != "" && city != " ")
    {
      fetch('https://api.openweathermap.org/data/2.5/weather?q=' + city + '&appid=4136919db12991b76b2b3779c7ced4ad')
        .then(response => response.json())
        .then(data => { this.setWeatherData(data, id); })
    }
  }

  getWeatherDataTest(city): any
  {
    if (city != "" && city != " ")
    {
      fetch('https://api.openweathermap.org/data/2.5/weather?q=' + city + '&appid=4136919db12991b76b2b3779c7ced4ad')
        .then(response => response.json())
        .then(data => { this.tmps = data; return data });
    }
  }

  // fonction pour changer la ville du widget météo
  changeWidgetMeteoInDB(id, city): Observable<any>
  {
    return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/change/widget/meteo-${id}-${city}`, null);
  }

  // fonction pour get les informations de la nouvelle ville selectionné par l'user (appelé quand le bouton search du widget est cliqué)
  getWeatherDataWhenBtnPressed(id, city)
  {
    if (city != "" && city != " ")
    {
      fetch('https://api.openweathermap.org/data/2.5/weather?q=' + city + '&appid=4136919db12991b76b2b3779c7ced4ad')
        .then(response => response.json())
        .then(data => { this.setWeatherData(data, id); })
    }
  }

  // fonction pour trier et set les data récupérer depuis getWeatherDataWhenBtnPressed() ou depuis getWeatherData()
  setWeatherData(data, id)
  {
    let idx = 0;

    if (data.cod != 404)
    {

      while (this.WidgetFilterCard[idx].id != id)
      {
        idx += 1;
      }

      this.WeatherData = data;

      this.WeatherData.humidity = this.WeatherData.main.humidity;
      this.WidgetFilterCard[idx].humidity = this.WeatherData.main.humidity;

      this.WeatherData.speed = this.WeatherData.wind.speed;
      this.WidgetFilterCard[idx].wind = this.WeatherData.wind.speed;

      this.WeatherData.temp_celcius = (this.WeatherData.main.temp - 273.15).toFixed(0);
      this.WidgetFilterCard[idx].degres = this.WeatherData.temp_celcius;

      if (this.WeatherData.weather[0].main == "Clouds")
      {
        this.WidgetFilterCard[idx].image_src = "assets/weather-image/clouds.png";
      }
      else if (this.WeatherData.weather[0].main == "Rain")
      {
        this.WidgetFilterCard[idx].image_src = "assets/weather-image/rain.png";
      }
      else if (this.WeatherData.weather[0].main == "Clear")
      {
        this.WidgetFilterCard[idx].image_src = "assets/weather-image/clear.png";
      }
      else if (this.WeatherData.weather[0].main == "Drizzle")
      {
        this.WidgetFilterCard[idx].image_src = "assets/weather-image/drizzle.png";
      }
      else if (this.WeatherData.weather[0].main == "Mist")
      {
        this.WidgetFilterCard[idx].image_src = "assets/weather-image/mist.png";
      }
      else if (this.WeatherData.weather[0].main == "Snow")
      {
        this.WidgetFilterCard[idx].image_src = "assets/weather-image/snow";
      }

      this.WidgetFilterCard[idx].title = this.WidgetFilterCard[idx].city;

      this.changeWidgetMeteoInDB(id, this.WidgetFilterCard[idx].city).subscribe(x =>
      {
      });

      this.WeatherData = [];
    }
    else
    {
      while (this.WidgetFilterCard[idx].id != id)
      {
        idx += 1;
      }
      this.WidgetFilterCard[idx].city = this.WidgetFilterCard[idx].title;
    }
  }

  // fonction pour convert liste de booléen en une string
  boolListToStr(listBool: boolean[])
  {
    let stringBool = listBool.map((bool) => bool.toString()).join(' ');
    return stringBool;
  }

  testFunctionToSendWidget()
  {
    if (this.widget_meteo != false)
    {
      if (this.city == "" || this.city == null)
      {
        this.errorMeteo = true;
        return false;
      }
    }

    if (this.Filtre_9 != false)
    {
      let listeBooleen: boolean[] = [this.checked_card, this.checked2_card, this.checked3_card, this.checked4_card, this.checked5_card, this.checked6_card, this.checked7_card, this.checked8_card, this.checked9_card, this.checked10_card, this.checked11_card, this.checked12_card, this.checked13_card];

      if (this.CardFilterName == null || this.CardFilterName == '' || this.CardFilterName == "")
      {
        this.errorInCardWidgetFillField = true
        return false;
      }

      if (!listeBooleen.includes(true))
      {
        this.errorInCardWidgetFillField = true
        return false;
      }

      if (this.checked3_card)
      {
        if (this.dateTosearch == null || this.dateTosearch == undefined)
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked6_card)
      {
        if (this.commentary == null || this.commentary == undefined || this.commentary == "")
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked7_card)
      {
        if (this.currentLabel == null || this.currentLabel == undefined || this.currentLabel.length == 0)
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked8_card)
      {
        if (this.tiersid == null || this.tiersid == undefined || this.tiersid.length == 0)
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked9_card)
      {
        if (this.memberid == null || this.memberid == undefined || this.memberid.length == 0)
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked10_card)
      {
        if (this.minute_date == 0 && this.hour_date == 0 && this.day_date == 0 && this.month_date == 0 && this.year_date == 0)
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked11_card)
      {
        if (this.titleToSearch == null || this.titleToSearch == undefined || this.titleToSearch == "")
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked12_card)
      {
        if (this.descToSearch == null || this.descToSearch == undefined || this.descToSearch == "")
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }
    }

    if (this.MostVisitedTab != false)
    {
      if (this.CardFilterName == null || this.CardFilterName == '' || this.CardFilterName == "")
      {
        this.errorVisitTab = true;
        return false;
      }
    }

    if (this.FiltreMessagerie != false)
    {

      let listeBooleen: boolean[] = [this.checked_messagerie, this.checked2_messagerie, this.checked3_messagerie, this.checked4_messagerie, this.checked5_messagerie, this.checked6_messagerie, this.checked7_messagerie];

      if (this.listOfSelectedValue == null || this.listOfSelectedValue.length == 0)
      {
        this.errorInEmailWidgetFillField = true;

        return false;
      }

      if (this.listOfSelectedValue.length < 1)
      {
        this.errorInEmailWidgetFillField = true;

        return false;
      }

      if (this.CardFilterName == null || this.CardFilterName == '' || this.CardFilterName == "")
      {
        this.errorInEmailWidgetFillField = true;

        return false;
      }

      if (!listeBooleen.includes(true))
      {
        this.errorInEmailWidgetFillField = true;

        return false;
      }

      if (this.checked_messagerie)
      {
        if (this.email_object == "" || this.email_object == undefined || this.email_object == null)
        {
          this.errorInEmailWidgetFillField = true;

          return false;
        }
      }

      if (this.checked2_messagerie)
      {
        if (this.email_sender == "" || this.email_sender == undefined || this.email_sender == null)
        {
          this.errorInEmailWidgetFillField = true;

          return false;
        }
      }

      if (this.checked3_messagerie)
      {
        if (this.email_recipient == "" || this.email_recipient == undefined || this.email_recipient == null)
        {
          this.errorInEmailWidgetFillField = true;

          return false;
        }
      }

      if (this.checked4_messagerie)
      {
        if (this.email_content == "" || this.email_content == undefined || this.email_content == null)
        {
          this.errorInEmailWidgetFillField = true;

          return false;
        }
      }

      if (this.checked6_messagerie)
      {
        if (this.dateTosearch == undefined || this.dateTosearch == null)
        {
          this.errorInEmailWidgetFillField = true;

          return false;
        }
      }
    }

    if (this.ChangeMailFilter != false)
    {
      let listeBooleen: boolean[] = [this.checked_messagerie, this.checked2_messagerie, this.checked3_messagerie, this.checked4_messagerie, this.checked5_messagerie, this.checked6_messagerie, this.checked7_messagerie];

      if (this.listOfSelectedValue == null || this.listOfSelectedValue.length == 0)
      {
        this.errorInEmailWidgetFillField = true;
        this.errorInWidget = true;
        return false;
      }

      if (this.CardFilterName == null || this.CardFilterName == '' || this.CardFilterName == "")
      {
        this.errorInEmailWidgetFillField = true;
        this.errorInWidget = true;
        return false;
      }

      if (!listeBooleen.includes(true))
      {
        this.errorInEmailWidgetFillField = true;
        this.errorInWidget = true;
        return false;
      }

      if (this.checked_messagerie)
      {
        if (this.email_object == "" || this.email_object == undefined || this.email_object == null)
        {
          this.errorInEmailWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked2_messagerie)
      {
        if (this.email_sender == "" || this.email_sender == undefined || this.email_sender == null)
        {
          this.errorInEmailWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked3_messagerie)
      {
        if (this.email_recipient == "" || this.email_recipient == undefined || this.email_recipient == null)
        {
          this.errorInEmailWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked4_messagerie)
      {
        if (this.email_content == "" || this.email_content == undefined || this.email_content == null)
        {
          this.errorInEmailWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked6_messagerie)
      {
        if (this.dateTosearch == undefined || this.dateTosearch == null)
        {
          this.errorInEmailWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }
    }

    if (this.ChangeCardFilter != false)
    {
      let listeBooleen: boolean[] = [this.checked_card, this.checked2_card, this.checked3_card, this.checked4_card, this.checked5_card, this.checked6_card, this.checked7_card, this.checked8_card, this.checked9_card, this.checked10_card, this.checked11_card, this.checked12_card, this.checked13_card];

      if (this.CardFilterName == null || this.CardFilterName == '' || this.CardFilterName == "")
      {
        this.errorInCardWidgetFillField = true;
        this.errorInWidget = true;
        return false;
      }

      if (!listeBooleen.includes(true))
      {
        this.errorInCardWidgetFillField = true;
        this.errorInWidget = true;
        return false;
      }

      if (this.checked3_card)
      {
        if (this.dateTosearch == null || this.dateTosearch == undefined)
        {
          this.errorInCardWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked6_card)
      {
        if (this.commentary == null || this.commentary == undefined || this.commentary == "")
        {
          this.errorInCardWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked7_card)
      {
        if (this.currentLabel == null || this.currentLabel == undefined || this.currentLabel.length == 0)
        {
          this.errorInCardWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked8_card)
      {
        if (this.currentTiers == null || this.currentTiers == undefined || this.currentTiers.length == 0)
        {
          this.errorInCardWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked9_card)
      {
        if (this.currentmember == null || this.currentmember == undefined || this.currentmember.length == 0)
        {
          this.errorInCardWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked10_card)
      {
        if (this.minute_date == 0 && this.hour_date == 0 && this.day_date == 0 && this.month_date == 0 && this.year_date == 0)
        {
          this.errorInCardWidgetFillField = true;
          this.errorInWidget = true;
          return false;
        }
      }

      if (this.checked11_card)
      {
        if (this.titleToSearch == null || this.titleToSearch == undefined || this.titleToSearch == "")
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }

      if (this.checked12_card)
      {
        if (this.descToSearch == null || this.descToSearch == undefined || this.descToSearch == "")
        {
          this.errorInCardWidgetFillField = true;
          return false;
        }
      }
    }

    return true;
  }

  setOtherOptiontoFalse(idxToTrue)
  {
    switch (idxToTrue)
    {
      case 1:
        this.widget_meteo = false;
        this.MostVisitedTab = false;
        this.FiltreMessagerie = false;
        this.showNameInput = true;
        break;

      case 2:
        this.Filtre_9 = false;
        this.MostVisitedTab = false;
        this.FiltreMessagerie = false;
        this.showNameInput = false;
        break;

      case 3:
        this.Filtre_9 = false;
        this.widget_meteo = false;
        this.FiltreMessagerie = false;
        this.showNameInput = true;
        break;

      case 4:
        this.Filtre_9 = false;
        this.MostVisitedTab = false;
        this.widget_meteo = false;
        this.showNameInput = true;
        break;

      default:
        break;
    }
  }

  // fonction qui est call quand le choix du widget est fait (quand le bouton ok est cliqué à la création des widgets) pour envoyer les informations
  // rentrées par l'utilisateur à la db pour les stocker
  makeDB()
  {
    const listClass: TmpClass[] = []; // list envoyé au back

    // if pour ajouter à la liste les informations du widget météo
    if (this.widget_meteo != false)
    {

      let tmp: any; // valeur tampon qui va contenir la class puis être ajouter à la liste de class

      if (this.testFunctionToSendWidget() == false)
      {
        return
      }

      try
      {
        if (this.tmps.cod == 404)
        {
          this.errorMeteo = true;
          return;
        }
      }
      catch {
        this.errorMeteo = true;
        return;
      }

      tmp = new TmpClass(0, this.city, 1, undefined, undefined, undefined, [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      listClass.push(tmp);

      // remise à 0 de toute les variables
      this.resetInformations();
    }

    // if pour ajouter à la liste les informations du widget des filtres de carte
    if (this.Filtre_9 != false)
    {

      let tmp: any; // valeur tampon qui va contenir la class puis être ajouter à la liste de class

      // liste de booléen représentant les filtres
      let listeBooleen: boolean[] = [this.checked_card, this.checked2_card, this.checked3_card, this.checked4_card, this.checked5_card, this.checked6_card, this.checked7_card, this.checked8_card, this.checked9_card, this.checked10_card, this.checked11_card, this.checked12_card, this.checked13_card];
      let tmp_stringBool = this.boolListToStr(listeBooleen);

      // liste de booléen représentant le sorting choisi
      let listeBooleenFilterOrder: boolean[] = [this.alphabetique, this.debut, this.fin, this.bycom];
      let tmp_stringBoolOrder = this.boolListToStr(listeBooleenFilterOrder);

      // variable représentant les minutes pour le dernier filtre
      let current_date = 0;
      if (this.checked10_card)
        current_date = this.minute_date + (this.hour_date * 60) + (this.day_date * 1440) + (this.month_date * 43200) + (this.year_date * 518400);

      // tous les if qui suivent sont des gestions d'erreur pour les informations
      if (this.commentary == null)
        this.commentary = "";

      if (this.CardFilterName == null)
        this.CardFilterName = undefined;

      if (this.dateTosearch == null)
        this.dateTosearch = undefined;

      if (this.checked3_card == false)
        this.dateTosearch = undefined;

      if (this.checked6_card == false)
        this.commentary = "";

      if (this.checked7_card == false)
        this.listLabelsIds = [];

      if (this.checked9_card == false)
        this.memberid = [];

      if (this.memberid == null)
        this.memberid = [];

      if (this.checked8_card == false)
        this.tiersid = [];

      if (this.tiersid == null)
        this.tiersid = [];

      if (this.complicate_filter == null)
        this.complicate_filter = false;

      if (this.listLabelsIds == null)
        this.listLabelsIds = [];

      if (this.testFunctionToSendWidget() == false)
      {
        return
      }

      // création de l'objet représentant les informations du widget
      tmp = new TmpClass(0, this.CardFilterName, 0, tmp_stringBool, this.dateTosearch, this.commentary, [], this.listLabelsIds, this.tiersid, this.memberid, this.complicate_filter, tmp_stringBoolOrder, this.sortMethode, current_date, this.titleToSearch, this.descToSearch, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

      listClass.push(tmp);

      // remise à 0 de toute les variables
      this.resetInformations();
    }

    if (this.MostVisitedTab != false)
    {
      let tmp: any; // valeur tampon qui va contenir la class puis être ajouter à la liste de class

      if (this.testFunctionToSendWidget() == false)
      {
        return
      }

      tmp = new TmpClass(0, this.CardFilterName, 2, undefined, undefined, undefined, [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      listClass.push(tmp);

      // remise à 0 de toute les variables
      this.resetInformations();
    }

    if (this.FiltreMessagerie != false)
    {
      let tmp: any;

      // liste de booléen représentant les filtres
      let listeBooleen: boolean[] = [this.checked_messagerie, this.checked2_messagerie, this.checked3_messagerie, this.checked4_messagerie, this.checked5_messagerie, this.checked6_messagerie, this.checked7_messagerie];
      let tmp_stringBool = this.boolListToStr(listeBooleen);

      // liste de booléen représentant le sorting choisi
      let listeBooleenFilterOrder: boolean[] = [this.alphabetique, this.debut, this.fin, this.bycom];
      let tmp_stringBoolOrder = this.boolListToStr(listeBooleenFilterOrder);

      if (this.complicate_filter == null)
        this.complicate_filter = false;

      if (this.dateTosearch == null)
        this.dateTosearch = undefined;

      if (this.email_object == null || this.email_object == "")
        this.email_object = undefined;

      if (this.email_sender == null || this.email_sender == "")
        this.email_sender = undefined;

      if (this.email_recipient == null || this.email_recipient == "")
        this.email_recipient = undefined;

      if (this.email_content == null || this.email_content == "")
        this.email_content = undefined;

      if (this.testFunctionToSendWidget() == false)
      {
        return
      }

      tmp = new TmpClass(0, this.CardFilterName, 3, tmp_stringBool, this.dateTosearch, undefined, undefined, undefined, undefined, undefined, this.complicate_filter, tmp_stringBoolOrder, this.sortMethode, undefined, undefined, undefined, undefined, this.email_object, this.email_sender, this.email_recipient, this.email_content, this.listOfSelectedValue, undefined, undefined, undefined);

      listClass.push(tmp);

      // remise à 0 de toute les variables
      this.resetInformations();
    }

    this.errorInWidget = false;
    this.errorInEmailWidgetFillField = false;
    this.errorInCardWidgetFillField = false;
    this.showWidgets = false;
    this.errorMeteo = false;
    this.errorVisitTab = false;
    this.tmps = null;


    if (this.WidgetFilterCard.length == 0)
    {
      this.startTimer();
    }
    // fonction pour post la liste de class au back
    this.post_filter(listClass).subscribe(x =>
    {
      console.log("post response : ", x);
    });

    setTimeout(() =>
    {
      // fonction pour get toute les informations stockées dans la db
      this.get_filter().subscribe(x =>
      {
        this.allWidgetList = [];
        this.WidgetFilterCard = [];
        this.allWidgetList = x;
        console.log("get response : ", x);
        if (this.allWidgetList != null && this.allWidgetList.length >= 0)
          this.makeWidgets(); // fonction création des widgets
      });
    }, 20);
  }

  // fonction pour recréer une liste de booléen depuis une string représentant une liste de booléeen
  makeBoolListFromStr(boolStr: string)
  {
    let boolList: boolean[] = boolStr.split(" ").map(value => value === "true")
    return boolList;
  }

  // fonction de création des widgets pour les afficher
  makeWidgets()
  {
    this.Filtre_9 = false;
    this.widget_meteo = false;
    this.MostVisitedTab = false;
    this.FiltreMessagerie = false;
    this.errorInWidget = false;
    let idx = 0;

    while (idx < this.allWidgetList.length)
    {

      // création des widgets de filtre de carte
      if (this.allWidgetList[idx].type == 0)
      {

        let BooleanList: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filtre);

        let BooleanListOrder: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filterOrder);

        this.CardFilterName = this.allWidgetList[idx].title;

        this.dateTosearch = this.allWidgetList[idx].dateTosearch;

        this.commentary = this.allWidgetList[idx].commentary;

        this.listLabelsIds = this.allWidgetList[idx].labelIds;

        if (this.listLabelsIds == null)
          this.listLabelsIds = [];

        this.tiersid = this.allWidgetList[idx].tiersid;

        this.currentTiers = this.allWidgetList[idx].tiersid;

        this.memberid = this.allWidgetList[idx].memberid;

        this.currentmember = this.allWidgetList[idx].memberid;


        // tri des labels pour n'afficher que les labels où l'user est identifié
        if (this.listLabelsIds.length > 0)
        {
          let x = 0;
          while (x < this.allLabelsForUser.length)
          {
            let y = 0;
            while (y < this.listLabelsIds.length)
            {
              if (this.allLabelsForUser[x].id == this.listLabelsIds[y])
              {
                this.currentLabel.push(this.allLabelsForUser[x]);
              }
              y += 1;
            }
            x += 1;
          }
        }

        //ajout de l'objet 'widget carte filtre' à la liste général qui sera afficher
        this.WidgetFilterCard.push(new WidgetCarteFiltre(this.allWidgetList[idx].id, this.CardFilterName, this.allWidgetList[idx].type, this.allWidgetList[idx].userId, BooleanList, this.allWidgetList[idx].dateTosearch, this.allWidgetList[idx].commentary, 3, false, this.allWidgetList[idx].allCards, this.currentLabel, this.allWidgetList[idx].tiersid, this.allWidgetList[idx].memberid, this.allWidgetList[idx].complicate_filter, BooleanListOrder, this.allWidgetList[idx].sortMethode, this.allWidgetList[idx].current_date, this.allWidgetList[idx].titleToSearch, this.allWidgetList[idx].descToSearch, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false, this.allWidgetList[idx].boardList));

        // remise à 0 de toute les variables
        this.resetInformations();
      }

      // création des widgets météo
      if (this.allWidgetList[idx].type == 1)
      {
        //ajout de l'objet 'widget météo' à la liste général qui sera afficher
        this.WidgetFilterCard.push(new WidgetCarteFiltre(this.allWidgetList[idx].id, this.allWidgetList[idx].title, this.allWidgetList[idx].type, this.allWidgetList[idx].userId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false, null));

        if (this.allWidgetList[idx].title != "")
        {
          this.WidgetFilterCard[idx].city = this.allWidgetList[idx].title;
          //call fonction pour get les informations météo de la ville selectionnée
          this.getWeatherData(this.allWidgetList[idx].title, this.allWidgetList[idx].id);
        }
      }

      if (this.allWidgetList[idx].type == 2)
      {
        let idx2 = 0;
        let DateList: String[] = [];
        while (idx2 < this.allWidgetList[idx].dateListTab.length)
        {
          let date = this.allWidgetList[idx].dateListTab[idx2];
          let date_withoutHours = date.split('T')[0];
          let date_withHours = date.split('T')[1];
          let jour: string = date_withoutHours.split('-')[2];
          let mois: string = date_withoutHours.split('-')[1];
          let years: string = date_withoutHours.split('-')[0];
          let hours: string = date_withHours.split(':')[0];
          let minutes: string = date_withHours.split(':')[1];
          let finalDate = jour + '-' + mois + '-' + years + ' ' + hours + ':' + minutes;
          DateList.push(finalDate);
          idx2 += 1;
        }
        this.WidgetFilterCard.push(new WidgetCarteFiltre(this.allWidgetList[idx].id, this.allWidgetList[idx].title, this.allWidgetList[idx].type, this.allWidgetList[idx].userId, undefined, undefined, undefined, undefined, undefined, this.allWidgetList[idx].allCards, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.allWidgetList[idx].mostVisitedBoardIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, DateList, false, null));
      }

      if (this.allWidgetList[idx].type == 3)
      {
        let BooleanList: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filtre);

        let BooleanListOrder: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filterOrder);


        this.WidgetFilterCard.push(new WidgetCarteFiltre(this.allWidgetList[idx].id, this.allWidgetList[idx].title, this.allWidgetList[idx].type, this.allWidgetList[idx].userId, BooleanList, this.allWidgetList[idx].dateTosearch, this.allWidgetList[idx].commentary, 3, false, this.allWidgetList[idx].allCards, this.currentLabel, this.allWidgetList[idx].tiersid, this.allWidgetList[idx].memberid, this.allWidgetList[idx].complicate_filter, BooleanListOrder, this.allWidgetList[idx].sortMethode, this.allWidgetList[idx].current_date, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.allWidgetList[idx].emailObject, this.allWidgetList[idx].emailSender, this.allWidgetList[idx].emailRecipient, this.allWidgetList[idx].emailContent, this.allWidgetList[idx].emailAccount, this.allWidgetList[idx].listMail, undefined, undefined, false, null));

        let tabUserID: number[] = [];
        tabUserID.push(this.allWidgetList[idx].userId);
        this.WidgetFilterCard[idx].folderName = [];
        this.getAllFolderForConnectedUser(tabUserID, this.allWidgetList[idx].listMail, idx, false, this.allWidgetList[idx].emailAccount);
      }
      idx += 1;
    }
  }

  //get les labels des boards de l'user
  postBoardsForLabels(boards): Observable<any>
  {
    return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/get/board/labelList`, boards);
  }

  //get les labels des cartes de l'user
  getCardsForLabels(): Observable<any>
  {
    return this.http.get<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/get/card/labelList`);
  }

  // fonction pour post la list des widgets (informations à stockées) à la db
  post_filter(listClass: TmpClass[]): Observable<any>
  {
    return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/post/info`, listClass);
  }

  // fonction pour get touts les widgets
  get_filter(): Observable<TmpClass[]>
  {
    return this.http.get<TmpClass[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/get/info`);
  }

  // fonction tampon pour afficher une modal
  showModalFilter(): void
  {
    this.showWidgets = true;
    this.getAllMailAccountCurrentUser();
  }

  // fonction pour cacher la modal quand la création de widget est validé et va call la fonction pour envoyer les info à la db
  handleOk(): void
  {
    if (this.widget_meteo)
    {
      this.getWeatherDataTest(this.city);
      setTimeout(() =>
      {
        this.makeDB();
        this.errorInWidget = true;
      }, 100);
    }
    else
    {
      this.makeDB();
      this.errorInWidget = true;
    }

  }

  // fonction pour handle la fermeture d'une modal et reset toute les informations à 0
  handleCancel(): void
  {
    this.errorInWidget = false;
    this.errorInEmailWidget = false;
    this.errorInCardWidget = false;
    this.errorInEmailWidgetFillField = false;
    this.errorInCardWidgetFillField = false;
    this.showWidgets = false;
    this.resetInformations();
  }

  handleOkEmail(): void
  {
    this.showEmail = false;
    this.currentEmail = null;
  }

  handleCancelEmail(): void
  {
    this.mss.openedMail = null;
    this.showEmail = false;
    this.currentEmail = null;
  }

  // fonction de call API pour delete un widget dans la db
  deleteWidgetInDB(id: number): Observable<any>
  {
    return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/delete/widget`, id);
  }

  // fonction call pour delete un widget coté front et back
  deleteWidget(id: number)
  {
    this.deleteWidgetInDB(id).subscribe(x =>
    {
      console.log(x);
    });
    try
    {
      let idx = 0;
      while (this.WidgetFilterCard[idx].id != id)
      {
        idx += 1;
      }

      if (this.WidgetFilterCard[idx].id == id)
        this.WidgetFilterCard.splice(idx, 1);
    }
    catch {
      console.log("don't find widget")
    }
  }

  // fonction call API pour changer les informations d'un widget côté backend
  changeWidgetInDB(data: object[]): Observable<any>
  {
    return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/change/widget`, data);
  }

  //fonction pour reser les informations a 0 du widget a 0
  resetInformations()
  {
    this.titleToSearch = null;
    this.descToSearch = null;
    this.currentWidgetToChange = null;
    this.idCard = null;
    this.widget_meteo = false;
    this.FiltreMessagerie = false;
    this.MostVisitedTab = false;
    this.Filtre_9 = false;
    this.checked_card = false;
    this.checked2_card = false;
    this.checked3_card = false;
    this.checked4_card = false;
    this.checked5_card = false;
    this.checked6_card = false;
    this.checked7_card = false;
    this.checked8_card = false;
    this.checked9_card = false;
    this.checked10_card = false;
    this.checked11_card = false;
    this.checked12_card = false;
    this.checked13_card = false;
    this.commentary = null;
    this.dateTosearch = undefined;
    this.CardFilterName = "";
    this.listLabelsIds = [];
    this.currentLabel = [];
    this.tiersid = [];
    this.currentTiers = [];
    this.memberid = [];
    this.currentmember = [];
    this.complicate_filter = null;
    this.alphabetique = true;
    this.debut = false;
    this.fin = false;
    this.bycom = false;
    this.sortMethode = false;

    this.minute_date = 0;
    this.hour_date = 0;
    this.day_date = 0;
    this.month_date = 0;
    this.year_date = 0;

    this.checked_messagerie = false;
    this.checked2_messagerie = false;
    this.checked3_messagerie = false;
    this.checked4_messagerie = false;
    this.checked5_messagerie = false;
    this.checked6_messagerie = false;
    this.checked7_messagerie = false;
    this.ChangeCardFilter = false;
    this.ChangeMailFilter = false;

    this.email_content = "";
    this.email_object = "";
    this.email_recipient = "";
    this.email_sender = "";
    this.email_account = "";
    this.listOfSelectedValue = null;
    this.errorInWidget = false;
    this.errorInCardWidgetFillField = false;
    this.errorInEmailWidgetFillField = false;
    this.errorMeteo = false;
    this.errorVisitTab = false;

    this.city = "";
  }

  // fonction call pour update les informations du widget filtre des cartes selectionné par l'utilisateur
  changeCardWidget(id: number, type, filtre: boolean[], title: string, comment: string, dateTosearch: Date, label: any, tiers: any, member: any, complicate_filter: boolean, FilterOrder: boolean[], sortMethode, current_date, stringTitle, stringDesc, email_object, email_sender, email_recipient, email_content, email_account)
  {
    this.typeOfWidgetToChange = type;

    // reset les informations a 0 au cas ou ce serait les info d'un autres widgets
    this.resetInformations()

    if (type == 0)
    {
      // re set des informations du widgets
      this.idCard = id;
      this.actualFiltre = filtre;
      this.CardFilterName = title;

      this.commentary = comment;
      this.dateTosearch = dateTosearch;

      if (stringTitle != null)
        this.titleToSearch = stringTitle;
      else
        this.titleToSearch = undefined;

      if (stringDesc != null)
        this.descToSearch = stringDesc;
      else
        this.descToSearch = undefined;

      // les 3 if else sont des gestions d'erreurs
      if (label != null)
      {
        this.currentLabel = label;
        console.log('curr labl : ', this.currentLabel);
      }
      else
      {
        this.currentLabel = [];
      }

      if (tiers != null)
      {
        this.tiersid = tiers;
        this.currentTiers = tiers;
      }
      else
      {
        this.tiersid = [];
        this.currentTiers = [];
      }

      if (member != null)
      {
        this.memberid = member;
        this.currentmember = member;
      }
      else
      {
        this.memberid = [];
        this.currentmember = [];
      }

      this.complicate_filter = complicate_filter;

      this.alphabetique = FilterOrder[0];

      this.debut = FilterOrder[1];

      this.fin = FilterOrder[2];

      this.bycom = FilterOrder[3];

      this.sortMethode = sortMethode;

      let tmp_curr_date = current_date;

      // re structuration / parsing des minutes du 10eme filtre des cartes dans les tranches d'horaires adéquat
      if (tmp_curr_date < 60)
      {
        this.minute_date = tmp_curr_date;
      }
      else if (tmp_curr_date < 1440)
      {
        let hours = Math.floor(tmp_curr_date / 60);
        hours = Math.round(hours);
        this.hour_date = hours;
        let remainingMinutes = tmp_curr_date % 60;
        remainingMinutes = Math.round(remainingMinutes);
        this.minute_date = remainingMinutes;
      }
      else if (tmp_curr_date < 43200)
      {
        let days = Math.floor(tmp_curr_date / 1440);
        days = Math.round(days);
        this.day_date = days;

        let remainingHours = (tmp_curr_date % 1440) / 60;
        remainingHours = Math.round(remainingHours);
        this.hour_date = remainingHours;

        let remainingMinutes = (((tmp_curr_date % 1440) % 60) % 60);
        remainingMinutes = Math.round(remainingMinutes);
        this.minute_date = remainingMinutes;
      }

      else if (tmp_curr_date < 518400)
      {
        let months: number = Math.floor(tmp_curr_date / 43200);
        months = Math.round(months);
        this.month_date = months;

        let remainingDays = Math.floor(tmp_curr_date % 43200) / 1440;
        remainingDays = Math.round(remainingDays);
        this.day_date = remainingDays - 1;

        let remainingHours = Math.floor((this.day_date) % 24);
        remainingHours = Math.round(remainingHours);
        this.hour_date = remainingHours;

        let remainingMinutes = (this.hour_date % 60);
        remainingMinutes = Math.round(remainingMinutes);
        this.minute_date = remainingMinutes;
      }
      else
      {
        let years = Math.floor(tmp_curr_date / 518400);
        years = Math.round(years);
        this.year_date = years;

        let remainingMonths = this.year_date % 12;
        remainingMonths = Math.round(remainingMonths);
        this.month_date = remainingMonths;

        let remainingDays = this.month_date % 30;
        remainingDays = Math.round(remainingDays);
        this.day_date = remainingDays;

        let remainingHours = this.day_date % 24;
        remainingHours = Math.round(remainingHours);
        this.hour_date = remainingHours;

        let remainingMinutes = this.hour_date % 60;
        remainingMinutes = Math.round(remainingMinutes);
        this.minute_date = remainingMinutes;
      }

      // parsing de la string représentant les filtres en booléen
      let idx = 0;
      while (idx < filtre.length)
      {
        if (idx == 0)
        {
          if (filtre[idx] == true)
            this.checked_card = true;
          else
            this.checked_card = false;
        }
        if (idx == 1)
        {
          if (filtre[idx] == true)
            this.checked2_card = true;
          else
            this.checked2_card = false;
        }
        if (idx == 2)
        {
          if (filtre[idx] == true)
            this.checked3_card = true;
          else
            this.checked3_card = false;
        }
        if (idx == 3)
        {
          if (filtre[idx] == true)
            this.checked4_card = true;
          else
            this.checked4_card = false;
        }
        if (idx == 4)
        {
          if (filtre[idx] == true)
            this.checked5_card = true;
          else
            this.checked5_card = false;
        }
        if (idx == 5)
        {
          if (filtre[idx] == true)
            this.checked6_card = true;
          else
            this.checked6_card = false;
        }
        if (idx == 6)
        {
          if (filtre[idx] == true)
            this.checked7_card = true;
          else
            this.checked7_card = false;
        }
        if (idx == 7)
        {
          if (filtre[idx] == true)
            this.checked8_card = true;
          else
            this.checked8_card = false;
        }
        if (idx == 8)
        {
          if (filtre[idx] == true)
            this.checked9_card = true;
          else
            this.checked9_card = false;
        }
        if (idx == 9)
        {
          if (filtre[idx] == true)
            this.checked10_card = true;
          else
            this.checked10_card = false;
        }
        if (idx == 10)
        {
          if (filtre[idx] == true)
            this.checked11_card = true;
          else
            this.checked11_card = false;
        }
        if (idx == 11)
        {
          if (filtre[idx] == true)
            this.checked12_card = true;
          else
            this.checked12_card = false;
        }
        if (idx == 12)
        {
          if (filtre[idx] == true)
            this.checked13_card = true;
          else
            this.checked13_card = false;
        }
        idx += 1;
      }
      this.ChangeCardFilter = true;
    }
    else if (type == 3)
    {

      this.idCard = id;
      this.actualFiltre = filtre;
      this.CardFilterName = title;

      this.commentary = comment;
      this.dateTosearch = dateTosearch;

      this.complicate_filter = complicate_filter;

      this.alphabetique = FilterOrder[0];

      this.debut = FilterOrder[1];

      this.fin = FilterOrder[2];

      this.bycom = FilterOrder[3];

      this.sortMethode = sortMethode;

      this.email_object = email_object;

      this.email_sender = email_sender;

      this.email_recipient = email_recipient;

      this.email_content = email_content;

      // console.log('accounts : ', email_account);

      this.listOfSelectedValue = email_account;
      // parsing de la string représentant les filtres en booléen
      let idx = 0;
      while (idx < filtre.length)
      {
        if (idx == 0)
        {
          if (filtre[idx] == true)
            this.checked_messagerie = true;
          else
            this.checked_messagerie = false;
        }
        if (idx == 1)
        {
          if (filtre[idx] == true)
            this.checked2_messagerie = true;
          else
            this.checked2_messagerie = false;
        }
        if (idx == 2)
        {
          if (filtre[idx] == true)
            this.checked3_messagerie = true;
          else
            this.checked3_messagerie = false;
        }
        if (idx == 3)
        {
          if (filtre[idx] == true)
            this.checked4_messagerie = true;
          else
            this.checked4_messagerie = false;
        }
        if (idx == 4)
        {
          if (filtre[idx] == true)
            this.checked5_messagerie = true;
          else
            this.checked5_messagerie = false;
        }
        if (idx == 5)
        {
          if (filtre[idx] == true)
            this.checked6_messagerie = true;
          else
            this.checked6_messagerie = false;
        }
        if (idx == 6)
        {
          if (filtre[idx] == true)
            this.checked7_messagerie = true;
          else
            this.checked7_messagerie = false;
        }

        idx += 1;
      }

      this.ChangeMailFilter = true;
    }
  }

  // Call api pour get seulement les informations d'un seul widget
  getUniqueWidget(id): Observable<any>
  {
    return this.http.get<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/change/get-one-table-${id}`);
  }

  // fonction pour changer le sorting selectionné et ne pas avoir plusieurs sorting
  changeBooleanOrder(nbBool)
  {
    if (nbBool == 1)
    {
      this.alphabetique = true;
      this.debut = false;
      this.fin = false;
      this.bycom = false;
    }
    if (nbBool == 2)
    {
      this.alphabetique = false;
      this.debut = true;
      this.fin = false;
      this.bycom = false;
    }
    if (nbBool == 3)
    {
      this.alphabetique = false;
      this.debut = false;
      this.fin = true;
      this.bycom = false;
    }
    if (nbBool == 4)
    {
      this.alphabetique = false;
      this.debut = false;
      this.fin = false;
      this.bycom = true;
    }
  }

  // fonction pour add a une liste les éléments du widgets pour les changer dans la db
  // la fonction est call quand le bouton Ok du changement de widget est pressé
  handleOkCard(type)
  {
    let dataToSend: any[] = [];

    dataToSend.push(type);
    if (type == 0)
    {


      let tmp = 0;
      this.listLabelsIds = [];
      while (tmp < this.currentLabel.length)
      {
        this.listLabelsIds.push(this.currentLabel[tmp].id)
        tmp += 1;
      }

      let listeBooleenFilter: boolean[] = [this.checked_card, this.checked2_card, this.checked3_card, this.checked4_card, this.checked5_card, this.checked6_card, this.checked7_card, this.checked8_card, this.checked9_card, this.checked10_card, this.checked11_card, this.checked12_card, this.checked13_card];
      let tmp_stringBool = this.boolListToStr(listeBooleenFilter);

      let listeBooleenFilterOrder: boolean[] = [this.alphabetique, this.debut, this.fin, this.bycom];
      let tmp_stringBoolOrder = this.boolListToStr(listeBooleenFilterOrder);

      if (this.testFunctionToSendWidget() == false)
      {
        return
      }
      this.ChangeCardFilter = false;

      dataToSend.push(this.idCard);

      dataToSend.push(tmp_stringBool);

      // les if qui suivent sont des gestions d'erreurs
      if (this.checked3_card == false)
        this.dateTosearch = null;

      if (this.dateTosearch != null)
        dataToSend.push(this.dateTosearch);
      else
      {
        this.dateTosearch = undefined;
        dataToSend.push("undifined");
      }

      if (this.checked7_card == false)
      {
        this.currentLabel = [];
        this.listLabelsIds = [];
      }
      if (this.listLabelsIds != null)
        dataToSend.push(this.listLabelsIds.join(' '));
      else
      {
        this.currentLabel = [];
        this.listLabelsIds = [];
        dataToSend.push(0);
      }

      if (this.checked6_card == false)
        this.commentary = '';
      if (this.commentary != null && this.commentary != '')
        dataToSend.push(this.commentary);
      else
      {
        this.commentary = undefined;
        dataToSend.push("undefined");
      }

      if (this.checked8_card == false)
        this.currentTiers = [];
      if (this.currentTiers != null)
        dataToSend.push(this.currentTiers.join(' '));
      else
      {
        this.currentTiers = [];
        dataToSend.push(0);
      }

      if (this.checked9_card == false)
        this.currentmember = [];

      if (this.currentmember != null)
        dataToSend.push(this.currentmember.join(' '));
      else
      {
        this.currentmember = [];
        dataToSend.push(0);
      }

      dataToSend.push(this.CardFilterName);

      dataToSend.push(this.complicate_filter);

      dataToSend.push(tmp_stringBoolOrder);

      dataToSend.push(this.sortMethode);

      let curr_date = 0;
      if (this.checked10_card)
        curr_date = this.minute_date + (this.hour_date * 60) + (this.day_date * 1440) + (this.month_date * 43800) + (this.year_date * 525600);
      else
        curr_date = undefined;

      dataToSend.push(curr_date);

      if (this.checked11_card == false)
        this.titleToSearch = '';
      if (this.titleToSearch != null && this.titleToSearch != '')
        dataToSend.push(this.titleToSearch);
      else
      {
        this.titleToSearch = undefined;
        dataToSend.push("undefined");
      }

      if (this.checked12_card == false)
        this.descToSearch = '';
      if (this.descToSearch != null && this.descToSearch != '')
        dataToSend.push(this.descToSearch);
      else
      {
        this.descToSearch = undefined;
        dataToSend.push("undefined");
      }

      this.typeOfWidgetToChange = null;

    }

    else if (type == 3)
    {

      let listeBooleenFilter: boolean[] = [this.checked_messagerie, this.checked2_messagerie, this.checked3_messagerie, this.checked4_messagerie, this.checked5_messagerie, this.checked6_messagerie, this.checked7_messagerie];
      let tmp_stringBool = this.boolListToStr(listeBooleenFilter);

      let listeBooleenFilterOrder: boolean[] = [this.alphabetique, this.debut, this.fin, this.bycom];
      let tmp_stringBoolOrder = this.boolListToStr(listeBooleenFilterOrder);

      if (this.testFunctionToSendWidget() == false)
      {
        return
      }
      this.ChangeMailFilter = false;

      dataToSend.push(this.idCard);

      dataToSend.push(tmp_stringBool);

      if (this.checked_messagerie == false)
        this.email_object = '';
      if (this.email_object != null && this.email_object != '')
        dataToSend.push(this.email_object);
      else
      {
        this.email_object = undefined;
        dataToSend.push("undefined");
      }

      if (this.checked2_messagerie == false)
        this.email_sender = '';
      if (this.email_sender != null && this.email_sender != '')
        dataToSend.push(this.email_sender);
      else
      {
        this.email_sender = undefined;
        dataToSend.push("undefined");
      }

      if (this.checked3_messagerie == false)
        this.email_recipient = '';
      if (this.email_recipient != null && this.email_recipient != '')
        dataToSend.push(this.email_recipient);
      else
      {
        this.email_recipient = undefined;
        dataToSend.push("undefined");
      }

      if (this.checked4_messagerie == false)
        this.email_content = '';
      if (this.email_content != null && this.email_content != '')
        dataToSend.push(this.email_content);
      else
      {
        this.email_content = undefined;
        dataToSend.push("undefined");
      }

      if (this.checked6_messagerie == false)
        this.dateTosearch = null;

      if (this.dateTosearch != null)
        dataToSend.push(this.dateTosearch);
      else
      {
        this.dateTosearch = undefined;
        dataToSend.push("undifined");
      }
      if (this.listOfSelectedValue != null && this.listOfSelectedValue[0] != '')
      {
        let result: string = this.listOfSelectedValue.join(" ");
        dataToSend.push(result);
      }
      else
      {
        this.listOfSelectedValue = undefined;
        dataToSend.push("undefined");
      }

      dataToSend.push(this.CardFilterName);

      dataToSend.push(this.complicate_filter);

      dataToSend.push(tmp_stringBoolOrder);

      dataToSend.push(this.sortMethode);

      this.typeOfWidgetToChange = null;
    }

    // call api envoyant la liste pour effectuer les changements dans la db
    this.changeWidgetInDB(dataToSend).subscribe(x =>
    {
      console.log(x);
    })

    let i = 0;
    while (this.idCard != this.WidgetFilterCard[i].id)
    { // A reprendre ici pour spin
      i += 1;
    }

    this.WidgetFilterCard[i].isReloading = true;


    setTimeout(() =>
    {
      // Call API pour get seulement les informations changer du current widget (celui qui a été changé)
      this.getUniqueWidget(this.idCard).subscribe(x =>
      {
        // reset des informations à 0
        this.resetInformations();
        this.currentWidgetToChange = x;
        let idx = 0;
        while (this.currentWidgetToChange.id != this.WidgetFilterCard[idx].id)
        {
          idx += 1;
        }


        // suppression du widget avec les anciennes données
        if (this.WidgetFilterCard[idx].type != 3)
          this.WidgetFilterCard.splice(idx, 1);

        // recréation du widget avec les nouvelles informations
        this.makeWidget(idx); // fonction pour créer seulement 1 widget
        this.complicate_filter = false;
        this.currentWidgetToChange = null;
      });
    }, 100);
  }

  handleCancelCard()
  {
    // reset des informations à 0
    this.resetInformations();
  }

  // fonction call quand un label est selectionné à la création d'un widget de filtre carte ou à sa modif
  addLabelInDB(id, label)
  {
    let n = 0;
    let idx = 0;

    // verification de l'existance du label dans la liste
    while (idx < this.currentLabel.length)
    {
      if (this.currentLabel[idx].id == id)
        n = 1;
      idx += 1;
    }
    // s'il n'y est pas on l'ajoute
    if (n == 0)
    {
      this.listLabelsIds.push(id);
      this.currentLabel.push(label);
    }
    // sinon on le supprime car il a été cliqué et qu'il est déjà existant dans la liste
    else
    {
      let idx1 = 0;
      while (idx1 < this.currentLabel.length)
      {
        if (this.currentLabel[idx1].id == id)
        {
          this.listLabelsIds.splice(idx1, 1);
          this.listLabelsIds.join();
          this.currentLabel.splice(idx1, 1);
          this.currentLabel.join();
        }
        idx1 += 1;
      }
    }
  }

  deleteLabel(label)
  {
    let idx = 0;

    while (idx < this.currentLabel.length)
    {
      if (label == this.currentLabel[idx])
      {
        this.currentLabel.splice(idx, 1);
        this.listLabelsIds.splice(idx, 1);
      }
      idx += 1;
    }
  }

  // fonction pour changer la couleur du texte selon le background
  toogleWhite(hex)
  {
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(rgb[1], 16);
    const g = parseInt(rgb[2], 16);
    const b = parseInt(rgb[3], 16);

    // Calculate the relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose font color based on luminance threshold
    return luminance > 0.45 ? '#000000' : '#FFFFFF';
  }

  // Call API pour get toute les cartes d'un widget spécifique
  get_all_cards_for_table(id, nb, filterValue: string): Observable<any>
  {
    if (filterValue == "")
      return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/get/get-all-cards-one-table-${id}-${nb}-µ`, null);
    else
      return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/get/get-all-cards-one-table-${id}-${nb}-${filterValue}`, null);
  }

  // fonction appelée quand le bouton '+' d'un widget de filtre de carte est cliqué pour get toute les cartes de ce widget
  openModalMore(widget: any)
  {
    this.moreCard = true; // booléen pour ouvrir la modal
    this.get_all_cards_for_table(widget.id, 20, this.filterValue).subscribe(x =>
    {
      this.actualWidget = x;
      this.actualWidget.isReloading = true;
      this.makeLineForCard(); // fonction pour créer des lignes selon les cartes pour l'affichage
      this.actualWidget.isReloading = false;
      setTimeout(() =>
      {
        this.calculateTallestElementHeight(); // fonction pour recalculer la hauteur des cartes selon la hauteur de la plus haute carte de la ligne
      }, 50);
    });
  }

  timeoutCall;
  filterInModalMore()
  {
    if (this.timeoutCall)
      clearTimeout(this.timeoutCall);
    this.timeoutCall = setTimeout(() =>
    {
      console.log('call api');
      this.actualWidget.isReloading = true;
      this.actualWidget.allCards = [];
      this.get_all_cards_for_table(this.actualWidget.id, this.actualWidget.allCards.length + 20, this.filterValue).subscribe(x =>
      {
        this.actualWidget = x;
        this.makeLineForCard(); // fonction pour créer des lignes selon les cartes pour l'affichage
        this.actualWidget.isReloading = false;
        setTimeout(() =>
        {
          this.calculateTallestElementHeight(); // fonction pour recalculer la hauteur des cartes selon la hauteur de la plus haute carte de la ligne
        }, 50);
      });
    }, 1000);
  }

  getMoreCard(widget: any)
  {
    this.moreCard = true; // booléen pour ouvrir la modal
    let nbCard = widget.allCards.length;
    this.actualWidget.isReloading = true;
    this.get_all_cards_for_table(widget.id, nbCard + 20, this.filterValue).subscribe(x =>
    {
      this.actualWidget = x;
      this.makeLineForCard(); // fonction pour créer des lignes selon les cartes pour l'affichage
      this.actualWidget.isReloading = false;
      setTimeout(() =>
      {
        this.calculateTallestElementHeight(); // fonction pour recalculer la hauteur des cartes selon la hauteur de la plus haute carte de la ligne
      }, 50);
    });
  }

  openModalMoreMail(widget: any)
  {
    this.moreMail = true; // booléen pour ouvrir la modal
    this.get_all_cards_for_table(widget.id, 20, this.filterValue).subscribe(x =>
    {
      this.actualWidget = x;
      this.actualWidget.isReloading = true;
      this.actualWidget.folderName = [[]];
      this.getAllFolderForConnectedUser([this.actualWidget.userId], this.actualWidget.listMail, 0, true, this.actualWidget.emailAccount);
      this.makeLineForMail(); // fonction pour créer des lignes selon les cartes pour l'affichage
      this.actualWidget.isReloading = false;
      setTimeout(() =>
      {
        this.calculateTallestElementHeightMail(); // fonction pour recalculer la hauteur des cartes selon la hauteur de la plus haute carte de la ligne
      }, 50);
    });
  }

  getMoreMail(widget: any)
  {
    this.moreMail = true; // booléen pour ouvrir la modal
    this.actualWidget.isReloading = true;
    this.get_all_cards_for_table(widget.id, widget.listMail.length + 20, this.filterValue).subscribe(x =>
    {
      this.actualWidget = x;
      this.actualWidget.folderName = [[]];
      this.getAllFolderForConnectedUser([this.actualWidget.userId], this.actualWidget.listMail, 0, true, this.actualWidget.emailAccount);
      this.makeLineForMail(); // fonction pour créer des lignes selon les cartes pour l'affichage
      this.actualWidget.isReloading = false;
      setTimeout(() =>
      {
        this.calculateTallestElementHeightMail(); // fonction pour recalculer la hauteur des cartes selon la hauteur de la plus haute carte de la ligne
      }, 50);
    });
  }

  timeoutCallMail;
  filterInModalMoreMail()
  {
    if (this.timeoutCallMail)
      clearTimeout(this.timeoutCallMail);
    this.timeoutCallMail = setTimeout(() =>
    {
      console.log('call api');
      if (this.filterValue.includes("-"))
      {
        return;
      }
      this.actualWidget.isReloading = true;
      this.actualWidget.folderName = [[]];
      this.actualWidget.listMail = [];

      this.get_all_cards_for_table(this.actualWidget.id, this.actualWidget.listMail.length + 20, this.filterValue).subscribe(x =>
      {
        this.actualWidget = x;
        this.getAllFolderForConnectedUser([this.actualWidget.userId], this.actualWidget.listMail, 0, true, this.actualWidget.emailAccount);
        this.makeLineForMail(); // fonction pour créer des lignes selon les cartes pour l'affichage
        this.actualWidget.isReloading = false;
        setTimeout(() =>
        {
          this.calculateTallestElementHeightMail(); // fonction pour recalculer la hauteur des cartes selon la hauteur de la plus haute carte de la ligne
        }, 50);
      });
    }, 1000);
  }

  // fonction pour fermer la modal ou toute les cartes sont show
  handleOkMore()
  {
    this.moreCard = false;
    this.moreMail = false;
    this.actualWidget = null;
  }

  // fonction pour fermer la modal ou toute les cartes sont show
  handleCancelMore()
  {
    // this.getUniqueWidget(this.actualWidget.id).subscribe(x => {
    //   this.currentWidgetToChange = x;
    //   let idx = 0;
    //   while (this.currentWidgetToChange.id != this.WidgetFilterCard[idx].id) {
    //     idx += 1;
    //   }

    //   if (this.WidgetFilterCard[idx].type != 3)
    //       this.WidgetFilterCard.splice(idx, 1);

    //   // recréation du widget avec les nouvelles informations
    //   this.makeWidget(idx); // fonction pour créer seulement 1 widget
    //   this.currentWidgetToChange = null;
    // });
    let idex = 0;
    while (idex < this.WidgetFilterCard.length)
    {
      if (this.WidgetFilterCard[idex].type == 0)
        this.WidgetFilterCard[idex].isReloading = true;
      idex += 1;
    }
    this.getAllWidgetCards().subscribe(x =>
    {
      let idx = 0;
      let idx2 = 0;
      while (idx < this.WidgetFilterCard.length)
      {
        while (idx2 < x.length)
        {
          if (this.WidgetFilterCard[idx].type == 0)
          {
            if (this.WidgetFilterCard[idx].id == x[idx2].id)
            {
              let stringFiltre = x[idx2].filtre;
              let BooleanList: boolean[] = this.makeBoolListFromStr(stringFiltre);

              let stringFilterOrder = x[idx2].filterOrder;
              let BooleanListOrder: boolean[] = this.makeBoolListFromStr(stringFilterOrder);

              let i = 0;
              let label: any[] = [];
              if (this.listLabelsIds.length > 0)
              {

                while (i < this.allLabelsForUser.length)
                {
                  let y = 0;
                  while (y < this.listLabelsIds.length)
                  {
                    if (this.allLabelsForUser[i].id == this.listLabelsIds[y])
                    {
                      label.push(this.allLabelsForUser[i]);
                    }
                    y += 1;
                  }
                  i += 1;
                }
              }
              let tmp = new WidgetCarteFiltre(x[idx2].id, x[idx2].title, x[idx2].type, x[idx2].userId, BooleanList, x[idx2].dateTosearch, x[idx2].commentary, 3, false, x[idx2].allCards, label, x[idx2].tiersid, x[idx2].memberid, x[idx2].complicate_filter, BooleanListOrder, x[idx2].sortMethode, x[idx2].current_date, x[idx2].titleToSearch, x[idx2].descToSearch, undefined, undefined, undefined, undefined, undefined, x[idx2].mostVisitedBoardIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, x[idx2].dateListTab, true, x[idx2].boardList);
              this.WidgetFilterCard.splice(idx, 1);
              this.WidgetFilterCard.splice(idx, 0, tmp);
              this.WidgetFilterCard[idx].isReloading = false;
              idx += 1;
            }
          }
          else
          {
            idx += 1;
            idx2 -= 1;
          }
          idx2 += 1;
        }
        idx += 1;
      }
      this.WidgetFilterCard.join();
      this.WidgetFilterCard = [...this.WidgetFilterCard];
    });
    this.moreMail = false;
    this.moreCard = false;
    this.actualWidget = null;
    this.filterValue = "";

  }

  // fonction pour déterminer le nombre de carte à afficher par ligne selon la taille de l'écran et le nombre de carte
  getNBCardToShowPerLine()
  {
    let modalWidth = this.screenWidth - 200;
    let nbToShow = modalWidth / 300;
    nbToShow = Math.round(nbToShow);
    return nbToShow;
  }

  // fonction pour créer les lignes dans la modal ou toute les cartes sont afficher
  makeLineForCard()
  {
    this.listOfLineToShow = [[]];
    let nbCard = this.actualWidget.allCards.length;
    let nbCardLine = this.getNBCardToShowPerLine() - 1;

    this.listOfLineToShow.pop();

    let idx = 0;
    let i = 0;
    let idx2 = 0;
    let idx3 = 0;

    let nbCardPerLine = nbCard / nbCardLine;

    nbCardPerLine = Math.ceil(nbCardPerLine);

    while (idx2 < nbCardPerLine)
    {
      idx3 = 0;
      while (idx3 < nbCardLine)
      {
        idx3 += 1;
        idx += 1
      }
      let sousListe: [] = this.actualWidget.allCards.slice(i, idx); // création des sous liste représentant les lignes

      this.listOfLineToShow.push(sousListe); // ajout des lignes à la liste de liste qui sera afficher

      idx2 += 1;
      i = idx;
    }
  }

  // fonction pour créer les lignes dans la modal ou toute les cartes sont afficher
  makeLineForMail()
  {
    this.listOfLineToShow = [[]];
    let nbCard = this.actualWidget.listMail.length;
    let nbCardLine = this.getNBCardToShowPerLine() - 1;

    this.listOfLineToShow.pop();

    let idx = 0;
    let i = 0;
    let idx2 = 0;
    let idx3 = 0;

    let nbCardPerLine = nbCard / nbCardLine;

    nbCardPerLine = Math.ceil(nbCardPerLine);

    while (idx2 < nbCardPerLine)
    {
      idx3 = 0;
      while (idx3 < nbCardLine)
      {
        idx3 += 1;
        idx += 1
      }
      let sousListe: [] = this.actualWidget.listMail.slice(i, idx); // création des sous liste représentant les lignes
      this.listOfLineToShow.push(sousListe); // ajout des lignes à la liste de liste qui sera afficher
      idx2 += 1;
      i = idx;
    }
  }

  // fonction pour calculer la carte la plus haute de la page et donc set toute les cartes sur la meme ligne à la même hauteur (la plus grande)
  calculateTallestElementHeight()
  {

    let nbCardLine = this.getNBCardToShowPerLine() - 1;

    var lines = document.getElementsByClassName("row-kanban");

    let nbCard = this.actualWidget.allCards.length;

    for (let i = 0; i < lines.length; i++)
    {
      var line = lines[i];
      if (nbCard >= nbCardLine)
      {
        this.lineWidth = nbCardLine * 300 + 140;
      }
      else if (nbCard == 0)
      {
        this.lineWidth = 100;
      }
      else if (nbCard == 1)
      {
        this.lineWidth = 300 + 70;
      }
      else if (nbCard == 2)
      {
        this.lineWidth = 600 + 95;
      }
      else
      {
        this.lineWidth = nbCard * 300 + 110;
      }

      var cards = line.getElementsByClassName("kanban-card");
      var space = line.getElementsByClassName("space-for-page");

      let maxheight = 0;
      for (let j = 0; j < cards.length; j++)
      {
        let card = cards.item(j);
        if (card.clientHeight > maxheight)
        {
          maxheight = card.clientHeight;
        }
      }

      for (let k = 0; k < cards.length; k++)
      {
        if (cards[k].clientHeight < maxheight)
        {
          let spacetoadd = maxheight - cards[k].clientHeight;
          space[k].setAttribute("style", "height:" + spacetoadd + "px");
        }
      }
    }
  }

  calculateTallestElementHeightMail()
  {

    let nbCardLine = this.getNBCardToShowPerLine() - 1;

    var lines = document.getElementsByClassName("row-mail");

    let nbCard = this.actualWidget.listMail.length;

    for (let i = 0; i < lines.length; i++)
    {
      var line = lines[i];
      if (nbCard >= nbCardLine)
      {
        this.lineWidth = nbCardLine * 300 + 140;
      }
      else if (nbCard == 0)
      {
        this.lineWidth = 100;
      }
      else if (nbCard == 1)
      {
        this.lineWidth = 300 + 70;
      }
      else if (nbCard == 2)
      {
        this.lineWidth = 600 + 95;
      }
      else
      {
        this.lineWidth = nbCard * 300 + 110;
      }

      var mail = line.getElementsByClassName("ant-list-item mail-band select-item");

      for (let k = 0; k < mail.length; k++)
      {
        mail[k].setAttribute("style", "height: 87.5px");
      }
    }
  }

  // fonction pour get tous les tiers physiques
  filterPPString = "";
  getFilteredPhysicalPerson()
  {
    let ppincards = [];
    ppincards = this.cds.physicalPersonCollection.filter(x => !x.archived);

    ppincards = ppincards.filter(x => this.tcs.contains((x.firstName + " " + x.lastName), this.filterPPString)
      || (x.comment && this.tcs.contains(x.comment, this.filterPPString))
      || (x.siren && this.tcs.contains(x.siren, this.filterPPString))
      || x.phoneNumbers.findIndex(y => this.tcs.contains(y.number, this.filterPPString)) >= 0
      || x.websites.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.mails.findIndex(y => this.tcs.contains(y.mail, this.filterPPString)) >= 0
      || x.brands.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.secondaryNames.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || (x.siret && this.tcs.contains(x.siret, this.filterPPString)));

    return ppincards.sort((a, b) => (a.firstName + a.lastName) < (b.firstName + b.lastName) ? -1 : 1)
  }

  // fonction pour get tous les tiers moraux
  filterMPString = "";
  getFilteredMoralPerson()
  {
    let mpincards = [];

    mpincards = this.cds.moralPersonCollection.filter(x => !x.archived);

    mpincards = mpincards.filter(x => this.tcs.contains(x.name, this.filterPPString)
      || this.tcs.contains(x.comment, this.filterPPString)
      || this.tcs.contains(x.codeTVA, this.filterPPString)
      || this.tcs.contains(x.codeAPE, this.filterPPString)
      || (x.sign && this.tcs.contains(x.sign, this.filterPPString))
      || (x.initials && this.tcs.contains(x.initials, this.filterPPString))
      || x.phoneNumbers.findIndex(y => this.tcs.contains(y.number, this.filterPPString)) >= 0
      || x.websites.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.mails.findIndex(y => this.tcs.contains(y.mail, this.filterPPString)) >= 0
      || x.brands.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || x.secondaryNames.findIndex(y => this.tcs.contains(y, this.filterPPString)) >= 0
      || this.tcs.contains(x.mainCurrency, this.filterPPString)
      || this.tcs.contains(x.siren, this.filterPPString)
      || this.tcs.contains(x.siret, this.filterPPString))

    return mpincards.sort((a, b) => a.name < b.name ? -1 : 1);
  }

  // fonction call quand un tiers est selectionné à la création d'un widget de filtre carte ou à sa modif
  addTiersInDB(id)
  {
    let n = 0;
    let idx = 0;

    // verification de l'existance du tiers dans la liste
    while (idx < this.currentTiers.length)
    {

      if (this.currentTiers[idx] == id)
        n = 1;

      idx += 1;
    }
    // s'il n'y est pas on l'ajoute
    if (n == 0)
    {
      this.tiersid.push(id);
      this.currentTiers.push(id);
    }
    // sinon on le supprime car il a été cliqué et qu'il est déjà existant dans la liste
    else
    {
      let idx1 = 0;
      while (idx1 < this.currentTiers.length)
      {

        if (this.currentTiers[idx1] == id)
        {
          this.tiersid.splice(idx1, 1);
          this.tiersid.join();
          this.currentTiers.splice(idx1, 1);
          this.currentTiers.join();
        }

        idx1 += 1;
      }
    }
  }

  deleteTiers(tier)
  {
    let idx = 0;

    while (idx < this.tiersid.length)
    {
      if (tier == this.tiersid[idx])
      {
        this.tiersid.splice(idx, 1);
        this.currentTiers.splice(idx, 1);
      }
      idx += 1;
    }
  }

  // fonction pour get les membres
  getUserForFilter()
  {
    return this.cds.unarchivedUsers.map(x => x.id);
  }

  // fonction call quand un membre est selectionné à la création d'un widget de filtre carte ou à sa modif
  addUserToDB(id: number)
  {
    let n = 0;
    let idx = 0;

    // verification de l'existance du label dans la liste
    while (idx < this.currentmember.length)
    {

      if (this.currentmember[idx] == id)
        n = 1;

      idx += 1;
    }
    // s'il n'y est pas on l'ajoute
    if (n == 0)
    {
      this.memberid.push(id);
      this.currentmember.push(id);
    }
    // sinon on le supprime car il a été cliqué et qu'il est déjà existant dans la liste
    else
    {
      let idx1 = 0;
      while (idx1 < this.currentmember.length)
      {

        if (this.currentmember[idx1] == id)
        {
          this.memberid.splice(idx1, 1);
          this.memberid.join();
          this.currentmember.splice(idx1, 1);
          this.currentmember.join();
        }

        idx1 += 1;
      }
    }
  }

  deleteUser(id)
  {
    let idx = 0;

    while (idx < this.memberid.length)
    {
      if (id == this.memberid[idx])
      {
        this.memberid.splice(idx, 1);
        this.currentmember.splice(idx, 1);
      }
      idx += 1;
    }
  }

  getTeamsForFilter()
  {
    return this.cds.unarchivedTeams.map(x => x.id);
  }

  // fonction call quand une team est selectionné à la création d'un widget de filtre carte ou à sa modif
  addTeamToDB(id)
  {
    let n = 0;
    let idx = 0;

    // verification de l'existance du label dans la liste
    while (idx < this.currentmember.length)
    {

      if (this.currentmember[idx] == id)
        n = 1;

      idx += 1;
    }
    // s'il n'y est pas on l'ajoute
    if (n == 0)
    {
      this.memberid.push(id);
      this.currentmember.push(id);
    }
    // sinon on le supprime car il a été cliqué et qu'il est déjà existant dans la liste
    else
    {
      let idx1 = 0;
      while (idx1 < this.currentmember.length)
      {

        if (this.currentmember[idx1] == id)
        {
          this.memberid.splice(idx1, 1);
          this.memberid.join();
          this.currentmember.splice(idx1, 1);
          this.currentmember.join();
        }

        idx1 += 1;
      }
    }
  }

  // fonction pour créer seulement un widget
  makeWidget(idx)
  {
    this.Filtre_9 = false;

    if (this.currentWidgetToChange.type == 0)
    {

      let BooleanList: boolean[] = this.makeBoolListFromStr(this.currentWidgetToChange.filtre);

      let BooleanListOrder: boolean[] = this.makeBoolListFromStr(this.currentWidgetToChange.filterOrder);

      this.CardFilterName = this.currentWidgetToChange.title;

      this.dateTosearch = this.currentWidgetToChange.dateTosearch;

      this.commentary = this.currentWidgetToChange.commentary;

      this.listLabelsIds = this.currentWidgetToChange.labelIds;

      this.tiersid = this.currentWidgetToChange.tiersid;

      this.currentTiers = this.currentWidgetToChange.tiersid;

      this.memberid = this.currentWidgetToChange.memberid;

      this.currentmember = this.currentWidgetToChange.memberid;

      //parsing des labels qui nous interresse pour l'user
      let x = 0;
      if (this.listLabelsIds.length > 0)
      {

        while (x < this.allLabelsForUser.length)
        {
          let y = 0;
          while (y < this.listLabelsIds.length)
          {
            if (this.allLabelsForUser[x].id == this.listLabelsIds[y])
            {
              this.currentLabel.push(this.allLabelsForUser[x]);
            }
            y += 1;
          }
          x += 1;
        }
      }

      let card = new WidgetCarteFiltre(this.currentWidgetToChange.id, this.CardFilterName, this.currentWidgetToChange.type, this.currentWidgetToChange.userId, BooleanList, this.currentWidgetToChange.dateTosearch, this.currentWidgetToChange.commentary, 3, false, this.currentWidgetToChange.allCards, this.currentLabel, this.currentWidgetToChange.tiersid, this.currentWidgetToChange.memberid, this.currentWidgetToChange.complicate_filter, BooleanListOrder, this.currentWidgetToChange.sortMethode, this.currentWidgetToChange.current_date, this.currentWidgetToChange.titleToSearch, this.currentWidgetToChange.descToSearch, undefined, undefined, undefined, undefined, undefined, this.currentWidgetToChange.mostVisitedBoardIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.currentWidgetToChange.dateListTab, true, this.currentWidgetToChange.boardList);
      this.WidgetFilterCard.splice(idx, 0, card);
      this.WidgetFilterCard.join();
      this.WidgetFilterCard = [...this.WidgetFilterCard];
      this.WidgetFilterCard[idx].isReloading = false;
      this.resetInformations();
    }

    else if (this.currentWidgetToChange.type == 3)
    {
      let BooleanList: boolean[] = this.makeBoolListFromStr(this.currentWidgetToChange.filtre);

      let BooleanListOrder: boolean[] = this.makeBoolListFromStr(this.currentWidgetToChange.filterOrder);

      let card = new WidgetCarteFiltre(this.currentWidgetToChange.id, this.currentWidgetToChange.title, this.currentWidgetToChange.type, this.currentWidgetToChange.userId, BooleanList, this.currentWidgetToChange.dateTosearch, this.currentWidgetToChange.commentary, 3, false, this.currentWidgetToChange.allCards, undefined, this.currentWidgetToChange.tiersid, this.currentWidgetToChange.memberid, this.currentWidgetToChange.complicate_filter, BooleanListOrder, this.currentWidgetToChange.sortMethode, this.currentWidgetToChange.current_date, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.currentWidgetToChange.mostVisitedBoardIds, this.currentWidgetToChange.emailObject, this.currentWidgetToChange.emailSender, this.currentWidgetToChange.emailRecipient, this.currentWidgetToChange.emailContent, this.currentWidgetToChange.emailAccount, this.currentWidgetToChange.listMail, [], undefined, true, null);

      let tabUserID: number[] = [];
      tabUserID.push(this.currentWidgetToChange.userId);
      this.WidgetFilterCard[idx].folderName = [];
      console.log('folder : ', this.WidgetFilterCard[idx].folderName);
      this.WidgetFilterCard.splice(idx, 1, card);
      this.getAllFolderForConnectedUser(tabUserID, this.currentWidgetToChange.listMail, idx, false, this.currentWidgetToChange.emailAccount);


      this.WidgetFilterCard.join();
      this.WidgetFilterCard = [...this.WidgetFilterCard];
      this.WidgetFilterCard[idx].isReloading = false;
      this.resetInformations();
    }
  }

  getContainerHeight(padding: number)
  {
    return (window.innerHeight - padding) * 0.8 + "px";
  }

  currentWidgetToChange: TmpClass;

  // fonction pour resize la taille (width) des lignes quand la window est resize pour que les lignes soient dynamique
  resizeLineCards()
  {
    let nbCardLine = this.getNBCardToShowPerLine() - 1;

    var lines = document.getElementsByClassName("row-kanban");

    let nbCard = this.actualWidget.allCards.length;

    for (let i = 0; i < lines.length; i++)
    {
      var line = lines[i];
      if (nbCard >= nbCardLine)
      {
        this.makeLineForCard();
        setTimeout(() =>
        {
          this.calculateTallestElementHeight();
        }, 5);
      }
      else if (nbCard == 0)
      {
        this.lineWidth = 100;
      }
      else if (nbCard == 1)
      {
        this.lineWidth = 300 + 70;
      }
      else if (nbCard == 2)
      {
        this.makeLineForCard();
        setTimeout(() =>
        {
          this.calculateTallestElementHeight();
        }, 5);
      }
      else
      {
        this.makeLineForCard();
        setTimeout(() =>
        {
          this.calculateTallestElementHeight();
        }, 5);
      }
    }
  }

  // fonction pour get la taille de l'écran
  getScreenSize()
  {
    const width = window.innerWidth;

    const height = window.innerHeight;

    this.screenWidth = width;
    let tmp = this.screenWidth * 0.8;
    this.modal_emailWidth = tmp + "px";

    this.progressWidth = width - 700 + "px";
    setTimeout(() =>
    {
      let navbarHeader = document.getElementsByClassName('navbar-header')[0].clientHeight;
      // let navbarHeaderWidth = document.getElementsByClassName('navbar-header')[0].clientWidth;
      // let progressBar = document.getElementsByClassName('ensemble-progress-barre')[0].clientHeight;

      this.content_height = height - navbarHeader - 122 + "px";
      let div_content = document.getElementsByClassName('scrollable-container');
      div_content[0].setAttribute("style", "max-height:" + this.content_height + "; overflow-y: auto;");
    }, 300);

    if (this.moreCard == true)
    {
      this.resizeLineCards();
    }
  }

  // fonction pour get la taille de l'écran
  @HostListener('window:resize', ['$event'])
  onResizes(event)
  {
    this.getScreenSize();
  }

  // fonction pour changer la méthode de sorting quand elle est cliqué (ascendant / descendant)
  changeBooleanSort()
  {
    this.sortMethode = !this.sortMethode;
  }

  // fonction pour l'actualisation des widgets et lancer le timer de l'actualisation
  startTimer()
  {
    this.timer = interval(1000).subscribe(() =>
    {
      if (this.WidgetFilterCard.length == 0 && this.timer != null)
      {
        this.timer.unsubscribe();
        return;
      }

      if (this.remainingTime > 0)
      {
        this.remainingTime--;
        this.nb_barre -= 1 / 3;
      }
      else 
      {
        if (this.timer != null)
          this.timer.unsubscribe();
        console.log('Timer finished refreshing widgets');
        this.get_filter().subscribe(x =>
        {
          this.allWidgetList = [];
          // this.WidgetFilterCard = [];
          this.allWidgetList = x;
          console.log("get response : ", x);
          this.nb_barre = 100;
          this.remainingTime = 300;
          if (this.allWidgetList != null && this.allWidgetList.length >= 0)
            this.makeWidgetsAfterRealoadData(); // refaire une func qui ne suppr pas widgetfiltercard mais qui reset juste les data pour pas fermer la modal si ouverte
        });
      }
    });
  }

  makeWidgetsAfterRealoadData()
  {
    let idx = 0;

    while (idx < this.allWidgetList.length)
    {

      // création des widgets de filtre de carte
      if (this.allWidgetList[idx].type == 0)
      {

        let BooleanList: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filtre);

        let BooleanListOrder: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filterOrder);

        // tri des labels pour n'afficher que les labels où l'user est identifié
        if (this.listLabelsIds.length > 0)
        {
          let x = 0;
          while (x < this.allLabelsForUser.length)
          {
            let y = 0;
            while (y < this.listLabelsIds.length)
            {
              if (this.allLabelsForUser[x].id == this.listLabelsIds[y])
              {
                this.currentLabel.push(this.allLabelsForUser[x]);
              }
              y += 1;
            }
            x += 1;
          }
        }

        this.WidgetFilterCard[idx].title = this.allWidgetList[idx].title;
        this.WidgetFilterCard[idx].type = this.allWidgetList[idx].type;
        this.WidgetFilterCard[idx].userId = this.allWidgetList[idx].userId;
        this.WidgetFilterCard[idx].filtre = BooleanList;
        this.WidgetFilterCard[idx].dateTosearch = this.allWidgetList[idx].dateTosearch;
        this.WidgetFilterCard[idx].commentary = this.allWidgetList[idx].commentary;
        this.WidgetFilterCard[idx].nbShow = 3;
        this.WidgetFilterCard[idx].allCard = this.allWidgetList[idx].allCards;
        this.WidgetFilterCard[idx].LabelIds = this.currentLabel;
        this.WidgetFilterCard[idx].tiersid = this.allWidgetList[idx].tiersid;
        this.WidgetFilterCard[idx].memberid = this.allWidgetList[idx].memberid;
        this.WidgetFilterCard[idx].complicate_filter = this.allWidgetList[idx].complicate_filter;
        this.WidgetFilterCard[idx].FilterOrder = BooleanListOrder;
        this.WidgetFilterCard[idx].sortMethode = this.allWidgetList[idx].sortMethode;
        this.WidgetFilterCard[idx].current_date = this.allWidgetList[idx].current_date;
        this.WidgetFilterCard[idx].boardList = this.allWidgetList[idx].boardList;
        this.WidgetFilterCard[idx].titleToSearch = this.allWidgetList[idx].titleToSearch;
        this.WidgetFilterCard[idx].descToSearch = this.allWidgetList[idx].descToSearch;
      }

      if (this.allWidgetList[idx].type == 3)
      {
        let BooleanList: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filtre);

        let BooleanListOrder: boolean[] = this.makeBoolListFromStr(this.allWidgetList[idx].filterOrder);

        this.WidgetFilterCard[idx].title = this.allWidgetList[idx].title;
        this.WidgetFilterCard[idx].type = this.allWidgetList[idx].type;
        this.WidgetFilterCard[idx].dateTosearch = this.allWidgetList[idx].dateTosearch;
        this.WidgetFilterCard[idx].filtre = BooleanList;
        this.WidgetFilterCard[idx].commentary = this.allWidgetList[idx].commentary;
        this.WidgetFilterCard[idx].complicate_filter = this.allWidgetList[idx].complicate_filter;
        this.WidgetFilterCard[idx].FilterOrder = BooleanListOrder;
        this.WidgetFilterCard[idx].sortMethode = this.allWidgetList[idx].sortMethode;
        this.WidgetFilterCard[idx].emailObject = this.allWidgetList[idx].emailObject;
        this.WidgetFilterCard[idx].emailSender = this.allWidgetList[idx].emailSender;
        this.WidgetFilterCard[idx].emailRecipient = this.allWidgetList[idx].emailRecipient;
        this.WidgetFilterCard[idx].emailContent = this.allWidgetList[idx].emailContent;
        this.WidgetFilterCard[idx].emailAccount = this.allWidgetList[idx].emailAccount;
        this.WidgetFilterCard[idx].listMail = this.allWidgetList[idx].listMail;

        let tabUserID: number[] = [];
        tabUserID.push(this.allWidgetList[idx].userId);
        this.WidgetFilterCard[idx].folderName = [];
        this.getAllFolderForConnectedUser(tabUserID, this.allWidgetList[idx].listMail, idx, false, this.allWidgetList[idx].emailAccount);
      }

      if (this.allWidgetList[idx].type == 2)
      {
        let idx2 = 0;
        let DateList: String[] = [];
        while (idx2 < this.allWidgetList[idx].dateListTab.length)
        {
          let date = this.allWidgetList[idx].dateListTab[idx2];
          let date_withoutHours = date.split('T')[0];
          let date_withHours = date.split('T')[1];
          let jour: string = date_withoutHours.split('-')[2];
          let mois: string = date_withoutHours.split('-')[1];
          let years: string = date_withoutHours.split('-')[0];
          let hours: string = date_withHours.split(':')[0];
          let minutes: string = date_withHours.split(':')[1];
          let finalDate = jour + '-' + mois + '-' + years + ' ' + hours + ':' + minutes;
          DateList.push(finalDate);
          idx2 += 1;
        }
        this.WidgetFilterCard[idx].dateListTab = DateList;
        this.WidgetFilterCard[idx].mostVisitedBoardIds = this.allWidgetList[idx].mostVisitedBoardIds;
      }

      idx += 1;
    }
    this.startTimer();
  }

  startShowMessages(): void
  {
    this.canReload = false;
    this.refresh_page();
    let progressMessage = "";
    this.translateService.get("HOME.PROGRESS-MESS").subscribe(x => progressMessage = x);
    let finishedMessage = "";
    this.translateService.get("HOME.FINISHED-MESS").subscribe(x => finishedMessage = x);
    this.message
      .loading(progressMessage, { nzDuration: 1500 })
      .onClose!.pipe(
        concatMap(() => this.message.success(finishedMessage, { nzDuration: 2000 }).onClose!)
      ).subscribe(() =>
      {
        console.log('Refresh completed!');
        this.canReload = true;
      });
  }

  // fonction pour l'actualisation des widgets
  refresh_page()
  {
    setTimeout(() =>
    {
      this.get_filter().subscribe(x =>
      {
        this.allWidgetList = [];
        this.WidgetFilterCard = [];
        this.allWidgetList = x;
        this.nb_barre = 100;
        this.remainingTime = 300;
        console.log("get response : ", x);
        if (this.allWidgetList != null && this.allWidgetList.length >= 0)
          this.makeWidgets();
      });
    }, 20);
  }

  //Test pour widget board :

  getBoardTitle(id: number)
  {
    let board = this.boards.find(x => x.id == id);

    this.single_board = board;

    if (board)
      return board.title;
    return "No Name"
  }

  getBoard(idboard: number)
  {
    return this.boards.find(x => x.id == idboard);
  }

  StarredPredicate(item: CdkDrag<number>)
  {
    return true;
  }

  getStarredAllowedList()
  {
    let list = []
    for (let i = 0; i < this.kbsService.allPublicSegmentList.length; i++)
    {
      list.push("starred" + i);
    }

    return list;
  }

  getAllListAllowed()
  {
    let list = []
    for (let id of this.getStarredAllowedList())
    {
      list.push(id);
    }

    return list;
  }

  dropStarred(event: CdkDragDrop<number[]>): void
  {

    if (event.previousContainer === event.container)
    {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else
    {
      if (this.kbsService.allStarredBoardList.indexOf(event.item.data) >= 0 && !event.previousContainer.id.startsWith("starred"))
        return;
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    setTimeout(() =>
    {
      this.kbsService.allStarredBoardList = [];
      for (let segment of this.kbsService.allStarredSegmentList)
      {
        for (let boardid of segment)
        {
          this.kbsService.allStarredBoardList.push(boardid);
        }
      }
      this.kbsService.updateBoardStarredPosition(this.kbsService.allStarredBoardList).subscribe();
    }, 300);
  }

  getBoardBgColor(id: number)
  {
    let board = this.boards.find(x => x.id == id);

    if (board)
      return board.bgColor;
    return "black"
  }

  selectBoard(board: KanbanBoard, isTemplate: boolean)
  {
    this.boardListVisible = false;
    this.templateListVisible = false;
    board.lists = [];
    this.kbsService.doRedirectionGet = false;
    this.kbsService.initBoard(board);

    if (this.redirect)
    {
      if (isTemplate)
      {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/kanban/template/' + board.id]));
      }
      else
      {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/kanban/' + board.id]));
      }
    }
  }

  unstareBoard(starredboards, event)
  {
    let board = this.getBoard(starredboards);
    this.kbsService.updateBoardStarred(board.id, false).subscribe(x =>
    {
      board.starred = false;
    });
    event.stopPropagation();
  }

  stareBoard(starredboards, event)
  {
    let board = this.getBoard(starredboards);
    this.kbsService.updateBoardStarred(board.id, true).subscribe(x =>
    {
      board.starred = true;
    });
    event.stopPropagation();
  }

  seeBoardCardActvities(boardid, event)
  {
    let board = this.getBoard(boardid);
    let title = "";
    this.translateService.get("KANBAN.SHOW-BOARD-HISTORY").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.cds.selectedBoard = board;
    this.kbsService.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanBoardActivitiesComponent,
      nzWidth: '90%',
      nzBodyStyle: { height: '75vh' },
      nzFooter: [{
        label: close,
        onClick: () => { this.kbsService.modalEdit.close(); }
      }
      ]
    });
    this.kbsService.modalEdit.componentInstance.idboard = this.cds.selectedBoard.id;
    event.stopPropagation();

  }

  folder: any[] = [];
  getAllFolderForConnectedUser(id, listMail, idx5, more, allAccounts)
  {
    //get content of a mail : readMail(idmail)
    if (more == false)
    {
      this.mfs.getAllFolderPerAccountForConnectedUser().subscribe(x =>
      {
        let idx = 0;
        let idxaccounts = 0;
        let listToSearch: any[] = [];
        while (idxaccounts < x.length)
        {
          if (allAccounts.includes(x[idxaccounts].accountMail))
          {
            listToSearch.push(x[idxaccounts]);
          }
          idxaccounts += 1;
        }

        while (idx < listMail.length)
        {
          let idx2 = 0;
          let idx3 = 0;
          while (listToSearch[idx2].folders[idx3].id != listMail[idx].folderIds)
          {
            let lengthFolder = listToSearch[idx2].folders.length;
            if (idx3 == lengthFolder - 1)
            {
              idx2 += 1;
            }
            idx3 += 1;
          }
          let mid = "";
          this.translateService.get("HOME.WHOSE-NAME").subscribe(x => mid = x);
          let stringToPush: string = listToSearch[idx2].folders[idx3].name + mid + listToSearch[idx2].accountMail;
          this.WidgetFilterCard[idx5].folderName.push(stringToPush);
          this.folder.push(listToSearch[idx2].folders[idx3]);
          idx += 1;
        }
      });
    }
    else
    {
      this.mfs.getAllFolderPerAccountForConnectedUser().subscribe(x =>
      {
        let folderName = [];
        let idx = 0;
        let idxaccounts = 0;
        let listToSearch: any[] = [];
        while (idxaccounts < x.length)
        {
          if (allAccounts.includes(x[idxaccounts].accountMail))
          {
            listToSearch.push(x[idxaccounts]);
          }
          idxaccounts += 1;
        }

        while (idx < listMail.length)
        {
          let idx2 = 0;
          let idx3 = 0;
          while (listToSearch[idx2].folders[idx3].id != listMail[idx].folderIds)
          {
            let lengthFolder = listToSearch[idx2].folders.length;
            if (idx3 == lengthFolder - 1)
            {
              idx2 += 1;
            }
            idx3 += 1;
          }
          let mid = "";
          this.translateService.get("HOME.WHOSE-NAME").subscribe(x => mid = x)
          let stringToPush: string = listToSearch[idx2].folders[idx3].name + mid + listToSearch[idx2].accountMail;
          folderName.push(stringToPush);
          this.folder.push(listToSearch[idx2].folders[idx3]);
          idx += 1;
        }
        this.actualWidget.folderName = [[]];
        let nbCard = this.actualWidget.listMail.length;
        let nbCardLine = this.getNBCardToShowPerLine() - 1;
        let idx5 = 0;
        this.actualWidget.folderName.pop();
        let idx4 = 0;
        let nbCardPerLine = nbCard / nbCardLine;
        let idx6 = 0;
        let i = 0;

        nbCardPerLine = Math.ceil(nbCardPerLine);

        while (idx4 < nbCardPerLine)
        {
          idx5 = 0;
          while (idx5 < nbCardLine)
          {
            idx5 += 1;
            idx6 += 1
          }
          let sousListe: any[] = folderName.slice(i, idx6); // création des sous liste représentant les lignes
          this.actualWidget.folderName.push(sousListe); // ajout des lignes à la liste de liste qui sera afficher
          idx4 += 1;
          i = idx6;
        }
      });
    }
  }


  closeMail()
  {
    if (!this.mss.dontOpen)
    {
      this.mss.openMailInd = -1;
      this.mss.openMail = false;
      this.mss.selectMailList = [];
      this.mss.openedMail = null;
    }
    this.mss.dontOpen = false
  }

  displayMail(ind: number, mailToRead: Receivedmail, panel: MessagerieFolder, actualWidget)
  {
    if (!this.mss.dontOpen)
    {
      if (this.mss.openMailInd == mailToRead.id)
      {
        document.body.style.cursor = "default";
        this.closeMail();
        return;
      }

      this.mss.openedMail = null;
      this.mss.openedMailPanel = null;
      mailToRead.content = null;
      this.mss.openMailInd = null;
      this.mss.openMail = null;
      this.mss.readMail(mailToRead.id).subscribe(content =>
      {
        this.mss.openedMail = mailToRead;
        this.mss.openedMailPanel = panel;
        mailToRead.content = content
        this.mss.openMailInd = mailToRead.id;
        this.showEmail = true;
        this.mss.openMail = true;

        if (this.mss.markAsReadOnFocus)
        {
          if (!mailToRead.read)
          {
            if (this.mss.selectedFolder)
              this.mss.selectedFolder.unreadMailCount--;
            else
              this.mss.unreadInbox--;
            this.mfs.updateMenuInterface();
          }
          this.showEmail = true;
          mailToRead.read = true;
          document.body.style.cursor = "default";

        }



        let el = document.getElementById("mailcontainer_" + mailToRead.id);
        if (el)
          el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      });
    }
    this.currentEmail = mailToRead;
    this.showEmail = true;
    // var space = document.getElementsByClassName("modalmail-content");
    // console.log('mail modal : ', space);
    // space[1].setAttribute("style", "height: 800px;");
    document.body.style.cursor = "default";
    this.mss.dontOpen = false;
    if (actualWidget.filtre[4])
    {
      this.getUniqueWidget(actualWidget.id).subscribe(x =>
      {
        this.currentWidgetToChange = x;
        let idx = 0;
        while (this.currentWidgetToChange.id != this.WidgetFilterCard[idx].id)
        {
          idx += 1;
        }

        // recréation du widget avec les nouvelles informations
        this.makeWidget(idx); // fonction pour créer seulement 1 widget
        this.currentWidgetToChange = null;
      });
    }
  }

  currentSelectedInfos = null;
  currentDateFolder: MessagerieFolder = null;
  selectedType = 0;
  selectMail(param: { ind: number, mail: Receivedmail, panel: MessagerieFolder }, datePanel: MessagerieFolder, openedMail, actualWidget)
  {
    openedMail.read = true;

    this.currentSelectedInfos = param;
    this.currentDateFolder = datePanel;

    this.mss.selectMailList = [param.mail];
    this.displayMail(param.ind, param.mail, param.panel, actualWidget);

  }

  cancel()
  {
  }

  getMailsAccountCurrentUser(): Observable<string[]>
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/get/home/mails`);
  }

  getAllMailAccountCurrentUser()
  {
    this.getMailsAccountCurrentUser().subscribe(x =>
    {

      this.listOfOption = x.map(item => ({
        value: item,
        label: item
      }));

      this.listOfSelectedValue = x;
    });
  }

  getCardList(card: KanbanCard, i, boardlist)
  {
    boardlist[i].lists.cards.push(card);
    this.cds.selectedBoard = boardlist[i];
    let list = this.cds.selectedBoard.lists.find(x =>
      x.cards.findIndex(y => y.id == card.id) > -1
    );
    return list;
  }

  sendCardToRefresh(card)
  {
    let pos = 0;

    if (this.moreCard)
    {
      while (pos < this.actualWidget.allCards.length)
      {
        if (this.actualWidget.allCards[pos] == card)
          break;
        pos += 1;
      }

      let filterValue = this.filterValue;

      if (filterValue == "")
        filterValue = "µ";

      let nb = this.actualWidget.allCards.length;

      let id = this.actualWidget.id;

      console.log('id wid : ', id);
      return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/card-opened-w-pos-${pos}-${filterValue}-${nb}-${id}`, card);
    }
    else
    {
      return this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/card-opened`, card);
    }
  }

  getAllWidgetCards()
  {
    return this.http.get<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/get/card-widget`)
  }

  drop(event: CdkDragDrop<WidgetCarteFiltre[]>)
  {
    moveItemInArray(this.WidgetFilterCard, event.previousIndex, event.currentIndex);
  }
}

// class de base pour les widgets (class général)
class WidgetCarteFiltre
{
  title: string;
  type: number;
  userId: number;
  filtre: boolean[];
  allCard: KanbanCard[] = [];
  id: number;
  dateTosearch: Date;
  commentary: string;
  nbShow: number;
  showMore: boolean;
  LabelIds: number[];
  currentlabel: any[];
  tiersid: number[];
  memberid: number[];
  complicate_filter: boolean;
  FilterOrder: boolean[];
  sortMethode: boolean;
  current_date: number;
  titleToSearch: string;
  descToSearch: string;
  city: string;
  humidity: any;
  wind: any;
  degres: any;
  image_src: any;
  mostVisitedBoardIds: any[];
  emailObject: string;
  emailSender: string;
  emailRecipient: string;
  emailContent: string;
  emailAccount: string[];
  listMail: any[];
  folderName: string[];
  dateListTab: String[];
  isReloading: boolean;
  boardList: KanbanBoard[];

  public constructor(_ID: number, _title: string, _type: number, _userID: number, _filtre: boolean[], _dateTosearch: Date, _commentary: string, _nbShow: number, _showMore: boolean, _AllCard: [], _currentlabel: any[], _tiersid: number[], _memberid: number[], _complicate_filter: boolean, _FilterOrder, _sortMethode: boolean, _current_date: number, _titleToSearch: string, _descToSearch, _city, _humidity, _wind, _degres, _image_src, _mostVisitedBoardIds: any[], _emailObject: string, _emailSender: string, _emailRecipient: string, _emailContent: string, _emailAccount: string[], _listMail: any[], _folderName: string[], _dateListTab: String[], _isReloading: boolean, _boardList)
  {
    this.id = _ID;
    this.title = _title;
    this.type = _type;
    this.userId = _userID;
    this.filtre = _filtre;
    this.dateTosearch = _dateTosearch;
    this.commentary = _commentary;
    this.nbShow = _nbShow;
    this.showMore = _showMore;
    this.allCard = _AllCard;
    this.currentlabel = _currentlabel;
    this.tiersid = _tiersid;
    this.memberid = _memberid;
    this.complicate_filter = _complicate_filter;
    this.FilterOrder = _FilterOrder;
    this.sortMethode = _sortMethode;
    this.current_date = _current_date;
    this.titleToSearch = _titleToSearch;
    this.descToSearch = _descToSearch;
    this.city = _city;
    this.humidity = _humidity;
    this.wind = _wind;
    this.image_src = _image_src;
    this.mostVisitedBoardIds = _mostVisitedBoardIds;
    this.emailObject = _emailObject;
    this.emailSender = _emailSender;
    this.emailRecipient = _emailRecipient;
    this.emailContent = _emailContent;
    this.emailAccount = _emailAccount;
    this.listMail = _listMail;
    this.folderName = _folderName;
    this.dateListTab = _dateListTab;
    this.isReloading = _isReloading;
    this.boardList = _boardList;
  }
}

// class envoyé au serveur pour stocker les données
class TmpClass
{
  id: number;
  title: string;
  type: number;
  userId: number;
  filtre: string;
  dateTosearch: Date;
  commentary: string;
  allCards: [];
  labelIds: number[];
  tiersid: number[];
  memberid: number[];
  complicate_filter: boolean;
  filterOrder: string;
  sortMethode: boolean;
  current_date: number;
  titleToSearch: string;
  descToSearch: string;
  mostVisitedBoardIds: any[];
  emailObject: string;
  emailSender: string;
  emailRecipient: string;
  emailContent: string;
  emailAccount: string[];
  listMail: any[];
  dateListTab: String[];
  boardList: KanbanBoard[] = [];

  public constructor(_ID: number, _title: string, _type: number, _filtre: string, _dateTosearch: Date, _commentary: string, _AllCard: [], _AllLabels: number[], _tiersid: number[], _memberid: number[], _complicate_filter: boolean, _FilterOrder: string, _sortMethode: boolean, _current_date: number, _titleToSearch: string, _descToSearch: string, _mostVisitedBoardIds, _emailObject: string, _emailSender: string, _emailRecipient: string, _emailContent: string, _emailAccount: string[], _listMail: any[], _dateListTab: String[], _boardList)
  {
    this.id = _ID;
    this.title = _title;
    this.type = _type;
    this.filtre = _filtre;
    this.dateTosearch = _dateTosearch;
    this.commentary = _commentary;
    this.allCards = _AllCard;
    this.labelIds = _AllLabels;
    this.tiersid = _tiersid;
    this.memberid = _memberid;
    this.complicate_filter = _complicate_filter;
    this.filterOrder = _FilterOrder;
    this.sortMethode = _sortMethode;
    this.current_date = _current_date;
    this.titleToSearch = _titleToSearch;
    this.descToSearch = _descToSearch;
    this.mostVisitedBoardIds = _mostVisitedBoardIds;
    this.emailObject = _emailObject;
    this.emailSender = _emailSender;
    this.emailRecipient = _emailRecipient;
    this.emailContent = _emailContent;
    this.emailAccount = _emailAccount;
    this.listMail = _listMail;
    this.dateListTab = _dateListTab;
    this.boardList = _boardList;
  }
}