// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-alert {
  padding: 8px 15px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 4px;
  list-style: none;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  font-size: 14px;
  text-align: left;
  position: absolute;
  right: 10px;
  margin-top: 3px;
  z-index: 10;
  max-width: 500px;
}

.help-content {
  display: inline-block;
}

.help-icon {
  float: right;
  cursor: pointer;
  vertical-align: middle;
  line-height: 1.8;
}

.help-show-container {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/components/help/help.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EAEA,sBAAA;EACA,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;AAAJ;;AAEA;EAEI,qBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;;AAIA;EACG,qBAAA;AADH","sourcesContent":[".help-alert{\r\n    padding: 8px 15px;\r\n    border: 1px solid #91d5ff;\r\n    background-color: #e6f7ff;\r\n    border-radius: 4px;\r\n    list-style: none;\r\n    // margin: 0;\r\n    box-sizing: border-box;\r\n    color: rgba(0,0,0,.65);\r\n    line-height: 1.5;\r\n    font-size: 14px;\r\n    text-align: left;\r\n    position: absolute;\r\n    right: 10px;\r\n    margin-top: 3px;\r\n    z-index: 10;\r\n    max-width: 500px;\r\n}\r\n.help-content{\r\n    // margin-right: 25px;\r\n    display: inline-block;\r\n    // margin: 0 15px;\r\n}\r\n.help-icon{\r\n    float:right;\r\n    cursor:pointer;\r\n    vertical-align: middle;\r\n    line-height: 1.8;\r\n}\r\n\r\n\r\n.help-show-container{\r\n   display: inline-block;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
