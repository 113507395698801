import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlApiService } from '../../../services/url-api.service';
import { Observable } from 'rxjs';
import { UserWithRights } from '../../model/user-with-rights';
import { User } from '../../model/user';
import { GroupUser } from '../../../group-user/entities/group-user';
import { UserCredentialsWithOrigin } from '../../model/user-credentials-with-origin';
import { CredentialsForUserWithOrigin } from '../../model/credentials-for-user-with-origin';
import { UserSharedGroupDTO } from '../../model/user-shared-group';
import { ElementsOwned } from '../../model/elements-owned';

@Injectable({
  providedIn: 'root'
})
export class UserSourceService
{

  constructor(private http: HttpClient) { }

  public getAllUsers(): Observable<UserWithRights>
  {
    return this.http.get<UserWithRights>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getall');
  }

  public getPotentialOwners(): Observable<User[]>
  {
    return this.http.get<User[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/potentialowners');
  }

  public getAllUserWithAvatar(): Observable<User[]>
  {
    return this.http.get<User[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getallwithavatar');
  }

  public getAllArchivedUsers(): Observable<UserWithRights>
  {
    return this.http.get<UserWithRights>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getarchived');
  }

  public createUser(user: User, avatar: File): Observable<number>
  {
    let formData: FormData = new FormData();
    if(avatar)
    formData.append("Image", avatar, "avatar.png");
    formData.append("User", JSON.stringify(user));
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/create', formData);
  }

  public archiveUser(user: User)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/user/archive', user);
  }

  public restoreUser(user: User)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/user/restore', user);
  }

  public activateUser(iduser: number, token: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/user/activate-user?iduser=' + iduser + '&token=' + token, 'toto');
  }

  public manualUserActivation(iduser: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/user/activate-user/' + iduser, null);
  }

  public desactivateUser(iduser: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/user/desactivate-user/' + iduser, null);
  }

  public updateUser(user: User, avatar: File)
  {
    let formData: FormData = new FormData();
    if(avatar)
    formData.append("Image", avatar, "avatar.png");
    formData.append("User", JSON.stringify(user));
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/user/update', formData);
  }

  public getUsergroups(iduser: number): Observable<GroupUser[]>
  {
    return this.http.get<GroupUser[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getgroups/' + iduser);
  }

  public getAvatar(iduser: number): Observable<string>
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getavatarbase64/' + iduser);
  }

  public getCredentials(iduser: number): Observable<CredentialsForUserWithOrigin[]>
  {
    return this.http.get<CredentialsForUserWithOrigin[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getcredentials/' + iduser);
  }

  public getAllGroupsOfUser(iduser: number): Observable<UserSharedGroupDTO[]>
  {
    return this.http.get<UserSharedGroupDTO[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/getallgroups/' + iduser);
  }

  public updateGroupsOfUser(groups: UserSharedGroupDTO[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/user/updategroups', groups);
  }

  public sendActivationMail(iduser: number)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/user/send-activation-mail', iduser);
  }

  public sendPassword(mail: string, password: string)
  {
    let formData: FormData = new FormData();
    formData.append("Mail", mail);
    formData.append("Password", password);
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/user/send-password-mail', formData);
  }

  public getElementsOwned(iduser: number): Observable<ElementsOwned>
  {
    return this.http.get<ElementsOwned>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/elements-owned/' + iduser);

  }

  public GetColumnVisibilityPreference(): Observable<string[]>
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/user/preference');
  }
}
