import { Injectable } from '@angular/core';
import { SettingsStrategyPassword } from '../credentials/entities/settings-strategy-password';
import { SettingsService } from '../settings/services/settings.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PasswordStrategyService {
lengthSettings=8;
settingsStrategyPassword : SettingsStrategyPassword[]=[
    { settingName: "PASSWORD.LENGTH", activated: false, value: "(?=.{0,}).*" },
    { settingName: "PASSWORD.CASE", activated: false, value: "(?=.*[a-z])(?=.*[A-Z]).*" },
    { settingName: "PASSWORD.NUMBER", activated: false, value: ".*[0-9].*" },
    { settingName: "PASSWORD.SPECIAL", activated: false, value: "[!@#$%^&*(),.?\":{}|<>]" }

];
constructor(public settingsService : SettingsService, private translateService : TranslateService) { }
getParameter()
  {
    let length = "";
    this.translateService.get("PASSWORD.LENGTH").subscribe(x => length = x);
    let caze = "";
    this.translateService.get("PASSWORD.CASE").subscribe(x => caze = x);
    let number = "";
    this.translateService.get("PASSWORD.NUMBER").subscribe(x => number = x);
    let special = "";
    this.settingsService.getSystemSettingsByName("Password_strategy_settings").subscribe(x =>
    {
      if (!x)
        return;
        let tabSettings= x.split(",");
      this.lengthSettings = tabSettings[0] ? parseInt(tabSettings[0]) : 0;
      this.settingsStrategyPassword[0].value="(?=.{"+this.lengthSettings+",}).*"
      this.settingsStrategyPassword[0].activated= this.lengthSettings > 0 ? true : false; 
      this.settingsStrategyPassword[1].activated= tabSettings[1]=="true" ? true : false;
      this.settingsStrategyPassword[2].activated= tabSettings[2]=="true" ? true : false;
      this.settingsStrategyPassword[3].activated= tabSettings[3]=="true" ? true : false;
    });
  }

  //Renvoie true si mdp pas valide, false sinon
    checkSecurity(pass: string): boolean
    {
        let test: boolean = false;
        this.settingsStrategyPassword.forEach(x =>
        {
            if (x.activated && !(new RegExp(x.value).test(pass)))
            {
                test = true;
            }
        })
        if (test)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    checkOneParameter(i : number, pass : string): boolean
    {
        
        if(this.settingsStrategyPassword[i].activated && !(new RegExp(this.settingsStrategyPassword[i].value).test(pass)))
        {
            return false;
        }
        else
        {
            return true;
        }
    }
}
