import { Pipe, PipeTransform } from '@angular/core';
import { CacheDataService } from '../../services/cache-data.service';
@Pipe({
  name: 'nameuser',
  pure: false
})
export class NameUserPipe implements PipeTransform
{

  constructor(public cds: CacheDataService) { }

  transform(value: number): string
  {
    return this.cds.getUserName(value);
  }

}
