import { Injectable } from '@angular/core';
import { GroupUserSourceService } from '../source/group-user-source.service';
import { tap, catchError } from 'rxjs/operators';
import { GroupUserWithRights } from '../../entities/group-user-with-rights';
import { GroupUser } from '../../entities/group-user';
import { InfoUsers } from '../../entities/info-users';
import { GroupAndUser } from '../../entities/group-and-user';
import { InfoGroupCredentials } from '../../entities/info-group-credentials';
import { GroupUserAndGroupCredentials } from '../../entities/group-user-and-group-credentials';
import { Right } from '../../../entities/right';
import { User } from '../../../user/model/user';
import { UserUpdateRightCustomGroup } from '../../entities/user-update-right-custom-group';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupUserDataService
{
  listGroupUserWithRights: GroupUserWithRights[] = [];
  listGroupUserWithRightsChange: Subject<GroupUserWithRights[]> = new Subject<GroupUserWithRights[]>();
  listGroupUser: GroupUser[];
  listGroupInfoUsers: InfoUsers;
  infoGroupCredentials: InfoGroupCredentials;
  listRights: Right[];
  listManagers: User[];

  constructor(public groupUserService: GroupUserSourceService, private errorHandler: ErrorHandlerService) { }

  public getAllGroupUsers(adminMode: boolean)
  {
    return this.groupUserService.getAllGroupUsers(adminMode).pipe(tap(c => { this.listGroupUserWithRights = c; }));
  }

  public getAllArchivedGroupUsers()
  {
    return this.groupUserService.getAllArchivedGroupUsers().pipe(tap(c => { this.listGroupUserWithRights = c; }));
  }

  public getUsers(idgroup: number)
  {
    return this.groupUserService.getUsers(idgroup).pipe(tap(c => { this.listGroupInfoUsers = c; }));
  }

  public createGroupUser(grpUser: GroupUser, avatar: File)
  {
    return this.groupUserService.createGroupUser(grpUser, avatar);
  }

  public updateGroupUser(grpUser: GroupUser, avatar: File)
  {
    return this.groupUserService.updateGroupUser(grpUser, avatar);
  }

  public archiveGroupUser(grpUser: GroupUser)
  {
    return this.groupUserService.archiveGroupUser(grpUser);
  }

  public restoreGroupUser(grpUser: GroupUser)
  {
    return this.groupUserService.restoreGroupUser(grpUser);
  }

  public addUsersToGroup(grpAndUser: GroupAndUser)
  {
    return this.groupUserService.addUsersToGroup(grpAndUser);
  }

  public removeUsersFromGroup(grpAndUser: GroupAndUser)
  {
    return this.groupUserService.removeUsersFromGroup(grpAndUser);
  }

  public getGroupCredentialsOfGroupUser(idgroup: number)
  {
    return this.groupUserService.getGroupCredentialsOfGroupUser(idgroup).pipe(tap(c => this.infoGroupCredentials = c));

  }

  public addGroupCredentialsToGroupUser(grpCred: GroupUserAndGroupCredentials)
  {
    return this.groupUserService.addGroupCredentialsToGroupUser(grpCred).subscribe();
  }

  public removeGroupCredentialsToGroupUser(grpCred: GroupUserAndGroupCredentials)
  {
    return this.groupUserService.removeGroupCredentialsToGroupUser(grpCred).subscribe();
  }

  public getUserRightsInGroupUser(iduser: number, idgroup: number)
  {
    return this.groupUserService.getUserRightsInGroupUser(iduser, idgroup).pipe(tap(c => this.listRights = c));
  }

  public getManagers(idgroup: number)
  {
    return this.groupUserService.getManagers(idgroup).pipe(tap(c => this.listManagers = c));
  }

  public changeManagerRight(updateRight: UserUpdateRightCustomGroup)
  {
    return this.groupUserService.changeManagerRight(updateRight).subscribe();
  }

  public GetColumnVisibilityPreference()
  {
    return this.groupUserService.GetColumnVisibilityPreference();
  }
}
