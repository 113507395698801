import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartyNamePipe } from './third-party-name.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ThirdPartyNamePipe],
  exports: [ThirdPartyNamePipe],
  providers: [ThirdPartyNamePipe]
})
export class ThirdPartyNamePipeModule { }
