import { Pipe, PipeTransform } from '@angular/core';
import { CacheDataService } from '../../services/cache-data.service';
@Pipe({
  name: 'avataruser'
})
export class AvatarUserPipe implements PipeTransform
{
  constructor(public cds: CacheDataService) { }

  transform(value: number): string
  {
    return this.cds.getAvatarUser(value);
  }

}
