import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlApiService } from '../../../services/url-api.service';
import { GroupUserWithRights } from '../../entities/group-user-with-rights';
import { GroupUser } from '../../entities/group-user';
import { InfoUsers } from '../../entities/info-users';
import { GroupAndUser } from '../../entities/group-and-user';
import { InfoGroupCredentials } from '../../entities/info-group-credentials';
import { GroupUserAndGroupCredentials } from '../../entities/group-user-and-group-credentials';
import { Right } from '../../../entities/right';
import { User } from '../../../user/model/user';
import { UserUpdateRightCustomGroup } from '../../entities/user-update-right-custom-group';


@Injectable({
  providedIn: 'root'
})
export class GroupUserSourceService
{

  constructor(private http: HttpClient) { }

  public getAllGroupUsers(adminMode: boolean): Observable<GroupUserWithRights[]>
  {
    return this.http.get<GroupUserWithRights[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/getall/' + adminMode);
  }

  public getAllArchivedGroupUsers(): Observable<GroupUserWithRights[]>
  {
    return this.http.get<GroupUserWithRights[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/getarchived');
  }

  public getUsers(idgroup: number): Observable<InfoUsers>
  {
    return this.http.get<InfoUsers>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/getusers/' + idgroup);
  }

  public createGroupUser(grpUser: GroupUser, avatar: File): Observable<GroupUser>
  {
    let formData: FormData = new FormData();
    if (avatar)
      formData.append("Image", avatar, "avatar.png");
    formData.append("GroupUser", JSON.stringify(grpUser));
    return this.http.post<GroupUser>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/create', formData);
  }

  public updateGroupUser(grpUser: GroupUser, avatar: File)
  {
    let formData: FormData = new FormData();
    if (avatar)
      formData.append("Image", avatar, "avatar.png");
    formData.append("GroupUser", JSON.stringify(grpUser));
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/update', formData);
  }

  public archiveGroupUser(grpUser: GroupUser)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/archive', grpUser);
  }

  public restoreGroupUser(grpUser: GroupUser)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/restore', grpUser);
  }

  public addUsersToGroup(grpAndUser: GroupAndUser)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/adduser', grpAndUser);
  }

  public removeUsersFromGroup(grpAndUser: GroupAndUser)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/removeuser', grpAndUser);
  }

  public getGroupCredentialsOfGroupUser(idgroup: number): Observable<InfoGroupCredentials>
  {
    return this.http.get<InfoGroupCredentials>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/getfromgroupuser/' + idgroup);
  }

  public addGroupCredentialsToGroupUser(grpCred: GroupUserAndGroupCredentials)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/addtogroupuser', grpCred);
  }

  public removeGroupCredentialsToGroupUser(grpCred: GroupUserAndGroupCredentials)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/removefromgroupuser', grpCred);
  }

  public getUserRightsInGroupUser(iduser: number, idgroup: number): Observable<Right[]>
  {
    return this.http.get<Right[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/getuserrights?idgroupuser=' + idgroup + '&iduser=' + iduser)
  }

  public getManagers(idgroup: number)
  {
    return this.http.get<User[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/getmanagers/' + idgroup);
  }

  public getMemberIds(idgroup: number)
  {
    return this.http.get<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/groupuser/${idgroup}/memberids`);
  }

  public changeManagerRight(updateRight: UserUpdateRightCustomGroup)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/user/changeright', updateRight);
  }

  public GetColumnVisibilityPreference(): Observable<string[]>
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/groupuser/preference');
  }
}
