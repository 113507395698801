// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-playlist-header {
  height: 200px;
  width: 200px;
}

.title-spotify-header {
  font-size: 28px;
  font-weight: bold;
}

.container-playlist-header {
  display: inline-block;
  margin-right: 25px;
}

.playplaylist-button {
  font-size: 18px;
  background-color: #2fc944;
  margin-top: 10px;
  border-color: #2fc944;
  border-radius: 10px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/spotify/component/header-playlist-spotify/header-playlist-spotify.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AACA;EACI,qBAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AAGJ","sourcesContent":[".image-playlist-header{\r\n    height:200px;\r\n    width:200px;\r\n}\r\n\r\n.title-spotify-header{\r\n    font-size:28px;\r\n    font-weight: bold;\r\n}\r\n.container-playlist-header{\r\n    display: inline-block;\r\n    margin-right: 25px;\r\n}\r\n.playplaylist-button{\r\n    font-size:18px;\r\n    background-color:#2fc944;\r\n    margin-top:10px;\r\n    border-color: #2fc944;\r\n    border-radius: 10px;\r\n    padding-right:30px;\r\n    padding-left:30px;\r\n    margin-right: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
