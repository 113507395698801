import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpotifyPlayerComponent } from './spotify-player.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpotifyContentInterfaceModule } from '../spotify-content-interface/spotify-content-interface.module';
import { PlayerSoundAjusterModule } from '../player-sound-ajuster/player-sound-ajuster.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTableModule } from 'ng-zorro-antd/table';

@NgModule({
  imports: [
    CommonModule,
    NzPopoverModule,
    TranslateModule,
    NzProgressModule,
    NzListModule,
    NzTableModule,
    SpotifyContentInterfaceModule,
    PlayerSoundAjusterModule
  ],
  declarations: [SpotifyPlayerComponent],
  exports: [SpotifyPlayerComponent]
})
export class SpotifyPlayerModule { }
