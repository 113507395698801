import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallThirdPartyComponent } from './call-third-party.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CallThirdPartyComponent],
  exports: [CallThirdPartyComponent]
})
export class CallThirdPartyModule { }
