export class ChatDiscussion
{
    id: number
    public: boolean
    createdUtc: Date
    name: string
    access: number = 0
    ownerId: number
    avatar: string;
}

export class accessBool
{
    public: boolean = false
    lien: boolean = false
    invitation: boolean = true
}
