import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { UrlApiService } from '../../services/url-api.service';
import { KanbanActionsManagerComponent } from '../components/kanban-actions-manager/kanban-actions-manager.component';
import { KanbanBoard } from '../entities/Board';
import { KanbanCard } from '../entities/Card';
import { KanbanActionManager } from '../entities/kanban-action-manager';
import { KanbanActionParameterRequester } from '../entities/kanban-action-parameter-requester';
import { KanbanActionParameters } from '../entities/kanban-action-parameters';
import { KanbanList } from '../entities/List';
import { ListAction } from '../entities/list-actions-control';
import { NbActionPerTrigger } from '../entities/nb-action-per-triger';
import { TriggerActions } from '../entities/trigger-actions';

type PostActionProcessing = (n: KanbanActionParameters, c: KanbanCard, comp: any) => void;
type CancelProcessing = (c: KanbanCard, comp: any) => void;
@Injectable({
    providedIn: 'root'
})
export class KanbanActionsService
{
    workflowActionList: string[] = [
        "ASK-COMMENT-BEFORE-DEPLACEMENT",
        "ASK-DESCRIPTION-BEFORE-DEPLACEMENT",
        "MOVE-CARD",
        "COPY-CARD",
        "SET-START-DATE",
        "SET-DUE-DATE",
        "MARK-CARD-COMPLETE",
        "REMOVE-CARD-COMPLETE",
        "SEND-MAIL-TEAM-MEMBER",
        "NOTIFY-CARD-CREATOR",
        "NOTIFY-USER-GENERAL-AUTO",
        "NOTIFY-USER-GENERAL-MANUAL",
        "NOTIFY-CARD-MEMBERS",
        "SEND-MAIL-TEAM-AUTO",
        "SEND-MAIL-TEAM-MANUAL",
        "AFFECT-CARD-MEMBERS-MANUAL",
        "AFFECT-CARD-MEMBERS-AUTO",
        "AFFECT-CARD-TEAMS-MANUAL",
        "AFFECT-CARD-TEAMS-AUTO",
        "AFFECT-LABELS-MANUAL",
        "AFFECT-LABELS-AUTO",
        "AFFECT-THIRD-PARTY-MANUAL",
        "AFFECT-THIRD-PARTY-AUTO",
        "OPEN-EDIT-CARD",
        "SHOW-INFORMATION-TEXT",
        "ASK-CONFIRMATION",
        "PLAN-CARD-ARCHIVING-AUTO"
    ]
    triggerActions: TriggerActions[] = [];
    constructor(private http: HttpClient,
        private modalService: NzModalService,
        private translateService: TranslateService) { }

    getListActionsForTrigger(idlist: number, trigger: string)
    {
        return this.http.get<KanbanActionManager>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/actions/${trigger}`)
    }

    getNbActionsPerTrigger(idlist: number)
    {
        return this.http.get<NbActionPerTrigger[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/nbaction-per-trigger`)
    }

    updateListActionsForTrigger(idlist: number, actions: KanbanActionManager)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/actions/${actions.trigger}`, actions)
    }

    getParametersForApplyAction(idlist: number, trigger: string)
    {
        return this.http.get<KanbanActionParameterRequester>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/list/${idlist}/ask-parameters-actions/${trigger}`)
    }

    applyListActionsForTrigger(kanbanActionParameters: KanbanActionParameters)
    {
        return this.http.post(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/apply-actions`, kanbanActionParameters)
    }

    previewApplyListActionsForTrigger(kanbanActionParameters: KanbanActionParameters)
    {
        return this.http.post<string>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/preview-apply-actions`, kanbanActionParameters)
    }

    getAllBoardAndListForUser()
    {
        return this.http.get<KanbanBoard[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/kanban/board/list`)
    }

    needUserInput(parameterRequester: KanbanActionParameterRequester)
    {
        return parameterRequester.memberUser || parameterRequester.notifyUser || parameterRequester.memberTeam || parameterRequester.label || parameterRequester.thirdParty || parameterRequester.comment || parameterRequester.description || parameterRequester.endDate || parameterRequester.confirm.length > 0;
    }

    initActions(isList: boolean, actionsControls: ListAction[])
    {
        this.triggerActions = [];

        if (isList)
        {
            let str_0 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-0").subscribe(x => str_0 = x.toUpperCase());
            let str_1 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-1").subscribe(x => str_1 = x.toUpperCase());
            let str_2 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-2").subscribe(x => str_2 = x.toUpperCase());
            let str_3 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-3").subscribe(x => str_3 = x.toUpperCase());
            let str_4 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-4").subscribe(x => str_4 = x.toUpperCase());
            let str_5 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-5").subscribe(x => str_5 = x.toUpperCase());
            let str_6 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-6").subscribe(x => str_6 = x.toUpperCase());
            this.triggerActions.push({ trigger: "0", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_0, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "1", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_1, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "4", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_4, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_5, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "3", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_3, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "2", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_2, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "6", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_6, isVisible: true, nbActions: 0 });
        }
        else
        {
            let str_1 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-1-5").subscribe(x => str_1 = x.toUpperCase());
            let str_2 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-2-5").subscribe(x => str_2 = x.toUpperCase());
            let str_3 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-3-5").subscribe(x => str_3 = x.toUpperCase());
            let str_4 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-4-5").subscribe(x => str_4 = x.toUpperCase());
            let str_5 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-5-5").subscribe(x => str_5 = x.toUpperCase());
            let str_6 = "";
            this.translateService.get("KANBAN.TRIGGER.OPTION-6-5").subscribe(x => str_6 = x.toUpperCase());
            this.triggerActions.push({ trigger: "1-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_1, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "4-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_4, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "5-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_5, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "3-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_3, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "2-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_2, isVisible: true, nbActions: 0 });
            this.triggerActions.push({ trigger: "6-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_2, isVisible: true, nbActions: 0 });
        }

        for (let action of actionsControls)
        {
            action.isVisible = true;
            this.translateService.get("KANBAN.ACTION-WORKFLOW." + action.codeAction).subscribe(x => action.actionDisplayText = x.toUpperCase());
            let trigger = this.triggerActions.find(x => x.trigger == action.triggerAction);
            if (!trigger)
            {
                trigger = new TriggerActions();
                trigger.trigger = action.triggerAction;
                this.triggerActions.push(trigger);
            }
            trigger.actions.push(action);
        }

    }

    async applyActions(card: KanbanCard, listid: number, board: KanbanBoard, trigger: string, methodToExecute: PostActionProcessing, cancelMethod: CancelProcessing, component: any)
    {
        let paramaction = await firstValueFrom(this.getParametersForApplyAction(listid, trigger));
        if (this.needUserInput(paramaction))
        {
            let title = "";
            this.translateService.get("KANBAN.WORKFLOW-MANAGE-TITLE").subscribe(x => title = x);
            let confirm = "";
            this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
            let cancel = "";
            this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
            let restoreOnClose = true;
            let modal = this.modalService.create({
                nzTitle: title,
                nzContent: KanbanActionsManagerComponent,
                nzWidth: '90%',
                nzClosable: false,
                nzMaskClosable: false,
                nzFooter: [{
                    label: confirm,
                    type: 'primary',
                    disabled: (result) =>
                    {
                        if (result.comment.length == 0 && paramaction.comment)
                            return true;
                        if (result.description.length == 0 && paramaction.description)
                            return true;
                        if (result.users.length > 0 && result.listMembersToAffect.length == 0 && paramaction.memberTeam)
                            return true;
                        if (result.listUsersToNotified.length == 0 && paramaction.notifyUser)
                            return true;
                        if (result.listThirdPartyToAffect.length == 0 && paramaction.thirdParty)
                            return true;
                        return false;
                    },
                    onClick: (result) =>
                    {
                        restoreOnClose = false;
                        let actionparam = result.getKanbanActionParameter();
                        actionparam.trigger = trigger;
                        methodToExecute(actionparam, card, component);
                        modal.close();
                    }
                },
                {
                    label: cancel,
                    onClick: () =>
                    {
                        restoreOnClose = false;
                        cancelMethod(card, component);
                        modal.close();
                    }
                }
                ]
            });
            modal.componentInstance.board = board;
            modal.componentInstance.card = card;
            modal.componentInstance.relation = null;
            modal.componentInstance.listid = listid;
            modal.componentInstance.kanbanActionParameterRequester = paramaction;
            modal.componentInstance.trigger = trigger;

            modal.afterClose.subscribe((result) =>
            {
                if (restoreOnClose)
                    cancelMethod(card, component);
            });
        }
        else
        {
            let actionparam2 = new KanbanActionParameters();
            actionparam2.listId = listid;
            actionparam2.cardId = card.id;
            actionparam2.trigger = trigger;
            methodToExecute(actionparam2, card, component);
        }
    }
}
