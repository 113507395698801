import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { PhysicalPerson } from '../../entities/physical-person';
import { ThirdPartyService } from '../../../../../../src/app/third-party/services/third-party.service';
import { MoralPerson } from '../../../../../../src/app/third-party/moral-person/entities/moral-person';
import { TypeRelation } from '../../../../../../src/app/third-party/entities/type-relation';
import { Subject } from 'rxjs';
import { SetRelation } from '../../../../../../src/app/third-party/entities/set-relation';
import { GetRelation } from '../../../../../../src/app/third-party/entities/get-relation';
import { TranslateService } from '@ngx-translate/core';
import { FilterRelation } from '../../../../../../src/app/third-party/entities/filter-relation';
import { CacheDataService } from '../../../../../../src/app/services/cache-data.service';
import { ThirdPartySheetInfos } from '../../../../../../src/app/call-interface/entities/third-party-sheet-infos';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { RelationMemo } from '../../../entities/relation-memo';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HtmlEditorComponent } from '../../../../components/html-editor/html-editor.component';
import { SearchRelation } from '../../../entities/search-relation';
import * as d3 from 'd3';

@Component({
  selector: 'app-physical-person-relations',
  templateUrl: './physical-person-relations.component.html',
  styleUrls: ['./physical-person-relations.component.scss'],
})
export class PhysicalPersonRelationsComponent implements OnInit
{

  constructor(public thirdPartyService: ThirdPartyService, private NzMessageService: NzMessageService,
    private cds: CacheDataService,
    private modalService: NzModalService,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService) { }

  @Input() thirdPartyId: number;
  @Input() thirdPartyName: string;
  @Input() pageInfo: ThirdPartySheetInfos;
  objectName = null;

  ppIsSource = true;
  updatedPPIsSource = true;
  viewHeight = "400px"
  dataLoaded = false;
  noData = false;
  searchTextSave = "";
  colorScheme = {
    domain: ['#add8e6', '#90ee90', '#F08080']
  };
  update$: Subject<boolean> = new Subject();
  center$: Subject<boolean> = new Subject();
  zoomToFit: Subject<boolean> = new Subject();
  filteredPhysicalPerson: PhysicalPerson[] = [];
  filteredMoralPersons: MoralPerson[] = [];
  filteredTypeRelations: TypeRelation[] = [];
  style = {
    display: 'block',
    marginTop: '15px'
  };
  relationDirection = "base";
  editRelationVisible = false;
  idSource: number = null;
  idFilteredSource: number;
  idTarget: number = null;
  idFilteredTarget: number;
  idType = null;
  idFilteredType = "";
  filterText = "";
  comment = "";
  tabTitle = null;
  idtptype = 0;
  idfilterCreationType = null;
  visible = false;

  updatedIdRelation: number = 0;
  updatedRelationIdSource: number = null;
  updatedRelationIdTarget: number = null;
  updatedRelationIdType = null;
  updatedRelationComment = "";
  dataLoading = true;
  typeRelationForFilterCreation: TypeRelation[] = [];

  newTagColor = "#ff0000";
  hue = "";
  theLinks: any[] = [];
  theNodes: any[] = [];
  layout = "dagre";
  ngOnInit()
  {
    this.ppIsSource = true;
    this.idFilteredSource = this.thirdPartyId;
    if (this.thirdPartyId)
      this.relationDirection = "source";
    else this.relationDirection = "base";
    if (this.thirdPartyId)
    {
      this.objectName = { v: this.thirdPartyName };
      this.idSource = this.thirdPartyId;
      this.thirdPartyService.getPotentialsRelationsForThirdParty(this.thirdPartyId).subscribe(x =>
      {
        this.cds.physicalPersonCollection.sort((a, b) => (a.firstName.toUpperCase() + a.lastName.toUpperCase()) > (b.firstName.toUpperCase() + b.lastName.toUpperCase()) ? 1 : -1);
        this.cds.moralPersonCollection.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
        this.initData();
        this.dataLoading = false;
      });
    }
    else
    {
      this.viewHeight = "800px";
      this.thirdPartyService.getPotentialsRelationsForAllThirdParty().subscribe(x =>
      {
        this.cds.physicalPersonCollection.sort((a, b) => (a.firstName.toUpperCase() + a.lastName.toUpperCase()) > (b.firstName.toUpperCase() + b.lastName.toUpperCase()) ? 1 : -1);
        this.cds.moralPersonCollection.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
        this.initData();
        this.dataLoading = false;
      });
    }
    this.thirdPartyService.relationsInfosChange.subscribe(() =>
    {
      this.initData();
      this.dataLoading = false;
    })
  }

  getInnerText(html: string)
  {
    var span = document.createElement('span');
    span.innerHTML = html;

    return span.innerText || span.textContent;
  };

  generateFilterRelationTab()
  {
    if (!this.idfilterCreationType)
      return;
    let type = this.typeRelationForFilterCreation.find(x => x.filterId == this.idfilterCreationType);
    let direction = this.idfilterCreationType.endsWith("-0") ? "source" : "target";
    let filterCode = this.idFilteredSource + "," + this.idFilteredTarget + "," + type.id + "," + direction;
    let filterRelation = new FilterRelation();
    filterRelation.filterCode = filterCode;
    filterRelation.title = this.tabTitle;
    filterRelation.color = this.newTagColor;

    this.thirdPartyService.addFilteredRelationTab(this.thirdPartyId, filterRelation).subscribe(x =>
    {
      filterRelation.id = x;
      if (this.thirdPartyService.editedPhysicalPerson && this.thirdPartyService.editedPhysicalPerson.id == this.thirdPartyId)
      {
        this.thirdPartyService.editedPhysicalPerson.filteredRelations.push(filterRelation)
      }
      else if (this.thirdPartyService.editedMoralPerson && this.thirdPartyService.editedMoralPerson.id == this.thirdPartyId)
      {
        this.thirdPartyService.editedMoralPerson.filteredRelations.push(filterRelation)
      }
      else if (this.pageInfo && this.pageInfo.id == this.thirdPartyId)
      {
        this.pageInfo.filteredRelations.push(filterRelation)
      }
      this.tabTitle = "";
      this.idfilterCreationType = null;
      this.visible = false;
    })
  }

  setInputFilterCreation(idfilter: string)
  {
    let type = this.typeRelationForFilterCreation.find(x => x.filterId == idfilter);
    if (!this.tabTitle)
      this.tabTitle = this.getTpCreationName(type);
  }

  canGenerateFilterRelationTab()
  {
    if (!this.idfilterCreationType)
      return false;
    let filterCode = this.idFilteredSource + "," + this.idFilteredTarget + "," + this.idfilterCreationType + "," + this.relationDirection;
    if (!this.idFilteredSource && !this.idFilteredTarget && !this.idfilterCreationType)
      return false;

    if (this.thirdPartyService.editedPhysicalPerson && this.thirdPartyService.editedPhysicalPerson.id == this.thirdPartyId)
    {
      return this.thirdPartyService.editedPhysicalPerson.filteredRelations.findIndex(x => x.filterCode == filterCode) < 0;
    }
    else if (this.thirdPartyService.editedMoralPerson && this.thirdPartyService.editedMoralPerson.id == this.thirdPartyId)
    {
      return this.thirdPartyService.editedMoralPerson.filteredRelations.findIndex(x => x.filterCode == filterCode) < 0;
    }
    else if (this.pageInfo && this.pageInfo.id == this.thirdPartyId)
    {
      return this.pageInfo.filteredRelations.findIndex(x => x.filterCode == filterCode) < 0;
    }
  }

  openMemo(memo: RelationMemo)
  {
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);

    let del = "";
    this.translateService.get("GENERIC-ACTIONS.DELETE").subscribe(x => del = x);

    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);

    let modal = this.modalService.create({
      nzTitle: "",
      nzContent: HtmlEditorComponent,
      nzBodyStyle: { height: '100vh', backgroundColor: '#f0f2f5' },
      nzWidth: '100%',
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [{
        label: confirm,
        type: 'primary',
        onClick: (result) =>
        {
          if (memo)
          {
            memo.content = result.getEditorContent();
            memo.title = result.title;
            this.thirdPartyService.updateMemo(memo).subscribe();
          }
          else
          {
            memo = new RelationMemo();
            memo.title = result.title;
            memo.content = result.getEditorContent();
            this.thirdPartyService.addMemoToThirdPartyRelation(this.updatedIdRelation, memo).subscribe(id =>
            {
              memo.id = id;
              this.updatedRelationMemos.push(memo);
            })
          }
          modal.close()
        }
      },
      {
        label: cancel,
        onClick: (result) =>
        {
          modal.close()
        }
      },
      {
        label: del,
        danger: true,
        show: memo != null,
        onClick: (result) =>
        {
          this.thirdPartyService.removeMemoFromThirdPartyRelation(memo.id).subscribe(() =>
          {
            let index = this.updatedRelationMemos.findIndex(x => x.id == memo.id);
            if (index >= 0)
              this.updatedRelationMemos.splice(index, 1);
          })
          modal.close()
        }
      }]
    });
    modal.componentInstance.editContent = memo ? memo.content : null;
    modal.componentInstance.editTitle = memo ? memo.title : null;
  }

  cancel()
  {
    this.visible = false;
    this.tabTitle = null;
    this.idfilterCreationType = null;
  }

  switchCreationDirection(isSource: boolean)
  {
    if (isSource)
    {
      this.ppIsSource = true;
      this.idSource = this.thirdPartyId;
      this.idTarget = null;
    }
    else 
    {
      this.ppIsSource = false;
      this.idTarget = this.thirdPartyId;
      this.idSource = null;
    }
  }

  updateDisplay(displayType: string)
  {
    switch (displayType)
    {
      case "base":
        this.idFilteredSource = null;
        this.idFilteredTarget = null;
        break;
      case "source":
        this.ppIsSource = true;
        this.idFilteredTarget = this.idFilteredSource;
        break;
      case "target":
        this.ppIsSource = false;
        this.idFilteredSource = this.idFilteredTarget;
        break;
    }
    this.initData();
  }

  change(param)
  {
    if (!param)
      this.updatedIdRelation = 0;
  }

  getTypeRelationForEdition()
  {
    let targetIsPP = this.cds.physicalPersonCollection.findIndex(x => x.id == this.updatedRelationIdTarget) >= 0;
    let sourceIsPP = this.cds.physicalPersonCollection.findIndex(x => x.id == this.updatedRelationIdSource) >= 0;

    if (sourceIsPP && targetIsPP)
    {
      return this.typeRelationForFilterCreation.filter(x => x.typeSource != 2 && x.typeTarget != 2);
    }
    else if (sourceIsPP && !targetIsPP)
    {
      return this.typeRelationForFilterCreation.filter(x => (x.typeSource != 2 && x.typeTarget != 1 && x.filterId.endsWith("-0")) || (x.typeSource != 1 && x.typeTarget != 2 && x.filterId.endsWith("-1")));
    }
    else if (!sourceIsPP && targetIsPP)
    {
      return this.typeRelationForFilterCreation.filter(x => (x.typeSource != 1 && x.typeTarget != 2 && x.filterId.endsWith("-0")) || (x.typeSource != 2 && x.typeTarget != 1 && x.filterId.endsWith("-1")));
    }
    else 
    {
      return this.typeRelationForFilterCreation.filter(x => x.typeSource != 1 && x.typeTarget != 1);
    }
  }

  updateRelationCreatorId = 0;
  updateRelationCreationDate = null;
  updatedRelationMemos: RelationMemo[] = []
  selectedlink = null;
  setUpdatedRelation(link: any)
  {
    this.selectedlink = link;
    let id = parseInt(link.id.slice(2));
    let relation = this.thirdPartyService.potentialRelationsInfos.relationList.find(x => x.idRelation == id);
    this.updatedIdRelation = id;
    this.updatedRelationIdSource = this.thirdPartyId;
    this.updatedRelationIdTarget = relation.idSource == this.thirdPartyId ? relation.idTarget : relation.idSource;
    this.updatedRelationComment = relation.comment;
    this.updateRelationCreatorId = relation.creatorId;
    this.updateRelationCreationDate = relation.creationDate;
    let direction = relation.idSource == this.thirdPartyId ? "-0" : "-1";
    let type = this.typeRelationForFilterCreation.find(x => x.id == relation.idTypeRelation && x.filterId.endsWith(direction));
    this.updatedRelationIdType = type.filterId;
    if (relation.idSource === this.thirdPartyId)
      this.updatedPPIsSource = true;
    else this.updatedPPIsSource = false;
    this.editRelationVisible = true;

    this.thirdPartyService.getMemoFromThirdPartyRelation(id).subscribe(memos =>
    {
      this.updatedRelationMemos = memos;
      for (let memo of this.updatedRelationMemos)
      {
        memo.title = memo.title ? memo.title : this.getInnerText(memo.content);
      }
    })
  }

  saveUpdateRelation()
  {
    let error = "";
    this.translateService.get("THIRD-PARTY.MODIFICATION-ERROR").subscribe(x => error = x);
    let success = "";
    this.translateService.get("THIRD-PARTY.MODIFICATION-SAVED").subscribe(x => success = x);
    if (!this.updatedRelationIdType)
    {
      this.NzMessageService.error(error);
      return;
    }

    let relation = this.thirdPartyService.potentialRelationsInfos.relationList.find(x => x.idRelation === this.updatedIdRelation)

    let setRelation = new SetRelation();
    let type = this.typeRelationForFilterCreation.find(x => x.filterId == this.updatedRelationIdType);

    setRelation.idThirdPartySource = type.filterId.endsWith("-0") ? this.updatedRelationIdSource : this.updatedRelationIdTarget;
    setRelation.idThirdPartyTarget = type.filterId.endsWith("-1") ? this.updatedRelationIdSource : this.updatedRelationIdTarget;
    setRelation.idTypeRelation = type.id;
    setRelation.comment = this.updatedRelationComment;
    setRelation.idRelation = this.updatedIdRelation;
    this.thirdPartyService.updateRelation(setRelation).subscribe(() =>
    {
      relation.idTypeRelation = setRelation.idTypeRelation;
      relation.comment = setRelation.comment;
      relation.titleSource = relation.idSource == setRelation.idThirdPartySource ? relation.titleSource : relation.titleTarget;
      relation.titleTarget = relation.idTarget == setRelation.idThirdPartyTarget ? relation.titleTarget : relation.titleSource;
      relation.titleTypeRelationSourceTarget = type.nameSourceTarget;
      relation.titleTypeRelationTargetSource = type.nameTargetSource;
      relation.idSource = setRelation.idThirdPartySource;
      relation.idTarget = setRelation.idThirdPartyTarget;
      this.NzMessageService.success(success);
      this.initData();
      this.thirdPartyService.potentialRelationsInfosChange.next(null);
    })
  }

  getUpdatedTargetName()
  {
    if (!this.updatedRelationIdTarget)
      return "";
    let pp = this.cds.physicalPersonCollection.find(x => x.id == this.updatedRelationIdTarget);
    if (pp)
      return pp.firstName + " " + pp.lastName;
    let mp = this.cds.allmoralperson.find(x => x.id == this.updatedRelationIdTarget);
    return mp.name;
  }

  getUpdatedSourceName()
  {
    if (!this.updatedIdRelation)
      return "";
    let relation = this.thirdPartyService.potentialRelationsInfos.relationList.find(x => x.idRelation === this.updatedIdRelation)
    return relation.titleSource;
  }

  getFilteredPP()
  {
    return this.cds.physicalPersonCollection.filter(x => this.thirdPartyService.potentialRelationsInfos.relationList.findIndex(y => y.idSource == x.id || y.idTarget == x.id) >= 0);
  }

  getFilteredMP()
  {
    return this.cds.allmoralperson.filter(x => this.thirdPartyService.potentialRelationsInfos.relationList.findIndex(y => y.idSource == x.id || y.idTarget == x.id) >= 0);
  }

  filterTpList: TypeRelation[] = [];
  getFilteredTP()
  {
    let trList = [];
    for (let tr of this.thirdPartyService.potentialRelationsInfos.typeRelationList.filter(x => this.thirdPartyService.potentialRelationsInfos.relationList.findIndex(y => y.idTypeRelation == x.id) >= 0))
    {
      if (this.idtptype == 1)
      {
        if (tr.typeSource != 2 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "0"));
        if (tr.typeTarget != 2 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "1"));
      }

      if (this.idtptype == 2)
      {
        if (tr.typeSource != 1 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "0"));
        if (tr.typeTarget != 1 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "1"));
      }
    }
    this.filterTpList = trList.sort((a, b) => this.getTpCreationName(a).toUpperCase() < this.getTpCreationName(b).toUpperCase() ? -1 : 1)
  }

  getTpForFilterCreation()
  {
    let trList = [];
    for (let tr of this.thirdPartyService.potentialRelationsInfos.typeRelationList)
    {
      if (this.idtptype == 1)
      {
        if (tr.typeSource != 2 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "0"));
        if (tr.typeTarget != 2 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "1"));
      }

      if (this.idtptype == 2)
      {
        if (tr.typeSource != 1 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "0"));
        if (tr.typeTarget != 1 && !(tr.nameSourceTarget == tr.nameTargetSource && trList.findIndex(x => x.id == tr.id) >= 0))
          trList.push(this.copyType(tr, "1"));
      }
    }
    trList = trList.sort((a, b) => this.getTpCreationName(a).toUpperCase() < this.getTpCreationName(b).toUpperCase() ? -1 : 1)
    return trList;
  }

  relationIsFilteredByTp(tr: TypeRelation, relation: GetRelation)
  {
    if (!tr)
      return true;
    if (tr.id != relation.idTypeRelation)
      return false;
    if (tr.nameSourceTarget == tr.nameTargetSource)
      return true;
    if (tr.filterId.endsWith("-0") && relation.idSource == this.thirdPartyId)
      return true;
    else if (tr.filterId.endsWith("-1") && relation.idTarget == this.thirdPartyId)
      return true;
    return false;
  }

  getTpCreationName(type: TypeRelation)
  {
    if (type.filterId.endsWith("-1"))
      return type.nameTargetSource;
    return type.nameSourceTarget;
  }

  copyType(type: TypeRelation, direction: string)
  {
    let tp = new TypeRelation();
    tp.id = type.id;
    tp.typeSource = type.typeSource;
    tp.typeTarget = type.typeTarget;
    tp.nameSourceTarget = type.nameSourceTarget;
    tp.nameTargetSource = type.nameTargetSource;
    tp.filterId = type.id + "-";
    if (this.relationDirection == "source")
      tp.filterId += direction;
    else
      tp.filterId += direction == "1" ? "0" : "1";

    return tp;
  }

  isBidirectional(idRelation)
  {
    let id = parseInt(idRelation.slice(2));
    let relation = this.thirdPartyService.potentialRelationsInfos.relationList.find(x => x.idRelation == id);
    if (!relation)
      return false
    let type = this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(x => x.id === relation.idTypeRelation);
    if (type.nameSourceTarget === type.nameTargetSource)
      return true;
    return false;
  }

  updateTargetThirdParty(idtype: string, searchText: string)
  {
    let type = this.typeRelationForFilterCreation.find(x => x.filterId == idtype);
    if (!type)
    {
      this.filteredPhysicalPerson = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => x.id != this.thirdPartyId);
      this.filteredMoralPersons = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => x.id != this.thirdPartyId);
      return;
    }
    let direction = idtype.endsWith("-0") ? "source" : "target";

    if (direction == "source")
    {
      if (type.typeTarget == 1)
      {
        this.filteredPhysicalPerson = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => x.id != this.thirdPartyId);
        this.filteredMoralPersons = [];
      }
      else if (type.typeTarget == 2)
      {
        this.filteredPhysicalPerson = [];
        this.filteredMoralPersons = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => x.id != this.thirdPartyId);
      }
      else
      {
        this.filteredPhysicalPerson = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => x.id != this.thirdPartyId);
        this.filteredMoralPersons = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => x.id != this.thirdPartyId);
      }
    }
    else
    {
      if (type.typeSource == 1)
      {
        this.filteredPhysicalPerson = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => x.id != this.thirdPartyId);
        this.filteredMoralPersons = [];
      }
      else if (type.typeSource == 2)
      {
        this.filteredPhysicalPerson = [];
        this.filteredMoralPersons = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => x.id != this.thirdPartyId);
      }
      else
      {
        this.filteredPhysicalPerson = this.thirdPartyService.filterPhysicalPersons(this.cds.physicalPersonCollection, searchText).filter(x => x.id != this.thirdPartyId);
        this.filteredMoralPersons = this.thirdPartyService.filterMoralPerson(this.cds.allmoralperson, searchText).filter(x => x.id != this.thirdPartyId);
      }
    }

    if (this.relationDirection == "source" && this.filteredPhysicalPerson.findIndex(x => x.id == this.idTarget) < 0 && this.filteredMoralPersons.findIndex(x => x.id == this.idTarget) < 0)
      this.idTarget = null;
    else if (this.relationDirection == "target" && this.filteredPhysicalPerson.findIndex(x => x.id == this.idSource) < 0 && this.filteredMoralPersons.findIndex(x => x.id == this.idSource) < 0)
      this.idSource = null;
  }

  // getCreationTypeThirdPartyList()
  // {
  //   let typelist: TypeRelation[] = [];
  //   for (let type of this.thirdPartyService.potentialRelationsInfos.typeRelationList)
  //   {
  //     let normalType = new TypeRelation();
  //     normalType.id = type.id;
  //     normalType.actionsRelation = type.actionsRelation;
  //     if (this.cds.physicalPersonCollection.findIndex(x => x.id == this.thirdPartyId) >= 0)
  //     {
  //       if (type.typeSource != 2)
  //       {
  //         normalType.typeSource = type.typeSource;
  //         normalType.typeTarget = type.typeTarget;
  //         normalType.nameSourceTarget = type.nameSourceTarget;
  //         normalType.nameTargetSource = type.nameTargetSource;
  //       }
  //       else if (type.typeTarget != 2)
  //       {
  //         normalType.typeSource = type.typeTarget;
  //         normalType.typeTarget = type.typeSource;
  //         normalType.nameSourceTarget = type.nameTargetSource;
  //         normalType.nameTargetSource = type.nameSourceTarget;
  //       }
  //       else continue;
  //     }
  //     else 
  //     {
  //       if (type.typeSource != 1)
  //       {
  //         normalType.typeSource = type.typeSource;
  //         normalType.typeTarget = type.typeTarget;
  //         normalType.nameSourceTarget = type.nameSourceTarget;
  //         normalType.nameTargetSource = type.nameTargetSource;
  //       }
  //       else if (type.typeTarget != 1)
  //       {
  //         normalType.typeSource = type.typeTarget;
  //         normalType.typeTarget = type.typeSource;
  //         normalType.nameSourceTarget = type.nameTargetSource;
  //         normalType.nameTargetSource = type.nameSourceTarget;
  //       }
  //       else continue;
  //     }
  //     typelist.push(normalType);
  //   }
  //   return typelist;
  // }

  IsPPSourceShown()
  {
    if (this.idType > 0 && this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(x => x.id === this.idType).typeSource === 2)
      return false;
    return true;
  }

  IsMPSourceShown()
  {
    if (this.idType > 0 && this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(x => x.id === this.idType).typeSource === 1)
      return false;
    return true;
  }

  IsPPTargetShown()
  {
    if (this.filteredPhysicalPerson.length == 0)
      return false;
    if (this.idType > 0 && this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(x => x.id === this.idType).typeTarget === 2)
      return false;
    return true;
  }

  IsMPTargetShown()
  {
    if (this.filteredMoralPersons.length == 0)
      return false;
    if (this.idType > 0 && this.thirdPartyService.potentialRelationsInfos.typeRelationList.find(x => x.id === this.idType).typeTarget === 1)
      return false;
    return true;
  }

  initData()
  {
    let tempNode = [];
    let tempLinks = [];
    this.filteredPhysicalPerson = [...this.cds.physicalPersonCollection].filter(x => x.id != this.thirdPartyId);
    this.filteredMoralPersons = [...this.cds.allmoralperson].filter(x => x.id != this.thirdPartyId);
    this.filteredTypeRelations = [...this.thirdPartyService.potentialRelationsInfos.typeRelationList];
    let pp = this.cds.physicalPersonCollection.find(x => x.id == this.thirdPartyId);
    if (pp)
      this.idtptype = 1;
    else 
    {
      this.idtptype = 2;
    }

    this.typeRelationForFilterCreation = this.getTpForFilterCreation();
    this.getFilteredTP();
    console.log(this.thirdPartyService.potentialRelationsInfos.relationList);

    this.thirdPartyService.potentialRelationsInfos.relationList.forEach(relation =>
    {
      switch (this.relationDirection)
      {
        case "base":
          if ((this.idFilteredSource > 0 && relation.idSource != this.idFilteredSource)
            || (this.idFilteredTarget > 0 && relation.idTarget != this.idFilteredTarget))
            return;
          break;
        case "source":
          if (this.idFilteredTarget > 0 && (relation.idSource != this.idFilteredTarget && relation.idTarget != this.idFilteredTarget))
            return;
          break;
        case "target":
          if (this.idFilteredSource > 0 && (relation.idSource != this.idFilteredSource && relation.idTarget != this.idFilteredSource))
            return;
          break;
      }
      let tr = this.filterTpList.find(x => x.filterId == this.idFilteredType);
      if (!this.relationIsFilteredByTp(tr, relation))
        return;

      if (tempNode.findIndex(x => x.id === "Id" + relation.idSource) < 0)
      {
        let color = "#add8e6";
        if (relation.idSource == this.thirdPartyId)
          color = '#90ee90';
        else if (this.cds.physicalPersonCollection.findIndex(x => x.id === relation.idSource) < 0)
          color = "#F08080";

        tempNode.push({
          id: 'Id' + relation.idSource.toString(),
          label: relation.titleSource,
          color: color
        });
      }
      if (tempNode.findIndex(x => x.id === "Id" + relation.idTarget) < 0)
      {
        let color = "#add8e6";
        if (relation.idTarget == this.thirdPartyId)
          color = '#90ee90';
        else if (this.cds.physicalPersonCollection.findIndex(x => x.id === relation.idTarget) < 0)
          color = "#F08080";

        tempNode.push({
          id: 'Id' + relation.idTarget.toString(),
          label: relation.titleTarget,
          color: color
        });
      }
      let idsource = "";
      let idtarget = "";
      let typerelationtext = "";


      switch (this.relationDirection)
      {
        case "base":
          idsource = 'Id' + relation.idSource.toString();
          idtarget = "Id" + relation.idTarget.toString();
          typerelationtext = relation.titleTypeRelationSourceTarget
          break;
        case "source":
          idsource = "Id" + this.thirdPartyId.toString();
          this.ppIsSource = true;
          idtarget = relation.idSource == this.thirdPartyId ? "Id" + relation.idTarget.toString() : 'Id' + relation.idSource.toString();
          typerelationtext = relation.idSource == this.thirdPartyId ? relation.titleTypeRelationSourceTarget : relation.titleTypeRelationTargetSource;
          break;
        case "target":
          this.ppIsSource = false;
          if (this.idFilteredType)
          {
            idsource = "Id" + this.thirdPartyId.toString();
            idtarget = relation.idTarget == this.thirdPartyId ? "Id" + relation.idSource.toString() : 'Id' + relation.idTarget.toString();
            typerelationtext = relation.idSource == this.thirdPartyId ? relation.titleTypeRelationSourceTarget : relation.titleTypeRelationTargetSource;
          }
          else 
          {
            idsource = relation.idTarget == this.thirdPartyId ? "Id" + relation.idSource.toString() : 'Id' + relation.idTarget.toString();
            idtarget = "Id" + this.thirdPartyId.toString();
            typerelationtext = relation.idTarget == this.thirdPartyId ? relation.titleTypeRelationSourceTarget : relation.titleTypeRelationTargetSource;
          }
          break;
      }
      tempLinks.push({
        id: "Id" + relation.idRelation.toString(),
        popupvisible: false,
        source: idsource,
        target: idtarget,
        label: typerelationtext
      })
    });
    this.theNodes = tempNode;
    this.theLinks = tempLinks;


    if (this.theNodes.length === 0)
      this.noData = true;
    else this.noData = false;

    this.dataLoaded = true;

    setTimeout(() =>
    {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  createRelation()
  {
    if (!this.idType || (this.ppIsSource && this.idTarget == 0) || (!this.ppIsSource && this.idSource == 0))
      return;
    let type = this.typeRelationForFilterCreation.find(x => x.filterId == this.idType);
    let direction = this.idType.endsWith("-0") ? "source" : "target";
    let setRelation = new SetRelation();
    if (direction == "source")
    {
      setRelation.idThirdPartySource = this.thirdPartyId;
      setRelation.idThirdPartyTarget = this.idTarget;
    }
    else
    {
      setRelation.idThirdPartySource = this.idTarget;
      setRelation.idThirdPartyTarget = this.thirdPartyId;
    }

    setRelation.idTypeRelation = type.id;
    setRelation.comment = this.comment;
    let titleSource = "";
    let titleTarget = "";
    this.thirdPartyService.createRelation(setRelation).subscribe(x =>
    {
      if (this.theNodes.findIndex(y => y.id === "Id" + setRelation.idThirdPartySource) < 0)
      {
        let index = this.cds.physicalPersonCollection.findIndex(y => y.id === setRelation.idThirdPartySource);
        if (index >= 0)
        {
          let pp = this.cds.physicalPersonCollection[index];
          titleSource = pp.firstName + ' ' + pp.lastName;
          this.theNodes.push({
            id: 'Id' + pp.id.toString(),
            label: pp.firstName + ' ' + pp.lastName,
            color: '#add8e6'
          });
        }
        else
        {
          index = this.cds.allmoralperson.findIndex(y => y.id === setRelation.idThirdPartySource);
          let mp = this.cds.allmoralperson[index];
          titleSource = mp.name;
          this.theNodes.push({
            id: 'Id' + mp.id.toString(),
            label: mp.name,
            color: '#F08080'
          });
        }
      }
      else titleSource = this.theNodes.find(y => y.id === "Id" + setRelation.idThirdPartySource).label;

      if (this.theNodes.findIndex(y => y.id === "Id" + setRelation.idThirdPartyTarget) < 0)
      {
        let index = this.cds.physicalPersonCollection.findIndex(y => y.id === setRelation.idThirdPartyTarget);
        if (index >= 0)
        {
          let pp = this.cds.physicalPersonCollection[index];
          titleTarget = pp.firstName + ' ' + pp.lastName;
          this.theNodes.push({
            id: 'Id' + pp.id.toString(),
            label: pp.firstName + ' ' + pp.lastName,
            color: '#add8e6'
          });
        }
        else
        {
          index = this.cds.allmoralperson.findIndex(y => y.id === setRelation.idThirdPartyTarget);
          let mp = this.cds.allmoralperson[index];
          titleTarget = mp.name;
          this.theNodes.push({
            id: 'Id' + mp.id.toString(),
            label: mp.name,
            color: '#F08080'
          });
        }
      }
      else titleTarget = this.theNodes.find(y => y.id === "Id" + setRelation.idThirdPartyTarget).label;

      let TypeRelation = this.filteredTypeRelations.find(y => y.id == type.id);
      let idsource = "";
      let idtarget = "";
      let typerelationtext = "";

      switch (this.relationDirection)
      {
        case "base":
          idsource = 'Id' + setRelation.idThirdPartySource.toString();
          idtarget = "Id" + setRelation.idThirdPartyTarget.toString();
          typerelationtext = TypeRelation.nameSourceTarget
          break;
        case "source":
          idsource = "Id" + this.thirdPartyId.toString();
          idtarget = setRelation.idThirdPartySource == this.thirdPartyId ? "Id" + setRelation.idThirdPartyTarget.toString() : 'Id' + setRelation.idThirdPartySource.toString();
          typerelationtext = setRelation.idThirdPartySource == this.thirdPartyId ? TypeRelation.nameSourceTarget : TypeRelation.nameTargetSource;
          break;
        case "target":
          idsource = setRelation.idThirdPartyTarget == this.thirdPartyId ? "Id" + setRelation.idThirdPartySource.toString() : 'Id' + setRelation.idThirdPartyTarget.toString();
          idtarget = "Id" + this.thirdPartyId.toString();
          typerelationtext = setRelation.idThirdPartyTarget == this.thirdPartyId ? TypeRelation.nameSourceTarget : TypeRelation.nameTargetSource;
          break;
      }
      this.theLinks.push({
        id: "Id" + x.toString(),
        source: idsource,
        target: idtarget,
        label: typerelationtext
      })
      let relation = new GetRelation();
      relation.idRelation = x;
      relation.idSource = setRelation.idThirdPartySource;
      relation.idTarget = setRelation.idThirdPartyTarget;
      relation.titleTypeRelationSourceTarget = TypeRelation.nameSourceTarget;
      relation.titleTypeRelationTargetSource = TypeRelation.nameTargetSource;
      relation.idTypeRelation = setRelation.idTypeRelation;
      relation.titleSource = titleSource;
      relation.titleTarget = titleTarget;
      relation.comment = setRelation.comment;
      relation.creatorId = this.auth.connectedUser.id;
      relation.creationDate = new Date();
      this.thirdPartyService.potentialRelationsInfos.relationList.push(relation);
      this.thirdPartyService.potentialRelationsInfosChange.next(null);
      this.noData = false;
      this.theLinks = [...this.theLinks];
      this.theNodes = [...this.theNodes];
      this.idSource = null;
      this.idTarget = null;
      this.idType = null;
      this.getFilteredTP();
    });
  }



  searchThirdParty(searchText: string)
  {
    this.searchTextSave = searchText;
    this.updateTargetThirdParty(this.idType, searchText)
    return false;
  }

  resetFilter()
  {
    this.idFilteredSource = null;
    this.idFilteredTarget = null;
    this.idFilteredType = null;
    this.filterText = "";
  }
  deleteRelation()
  {
    let idRelation = "Id" + this.updatedIdRelation;
    let id = parseInt(idRelation.substring(2));
    this.thirdPartyService.deleteRelation(id).subscribe(() =>
    {
      let index = this.theLinks.findIndex(x => x.id == idRelation);
      if (index >= 0)
      {
        this.theLinks.splice(index, 1);
        let relation = this.thirdPartyService.potentialRelationsInfos.relationList.find(x => x.idRelation == id);
        let deleteSource = this.thirdPartyService.potentialRelationsInfos.relationList.filter(x => x.idSource == relation.idSource || x.idTarget == relation.idSource).length === 1;
        let deleteTarget = this.thirdPartyService.potentialRelationsInfos.relationList.filter(x => x.idSource == relation.idTarget || x.idTarget == relation.idTarget).length === 1;
        if (deleteSource)
        {
          let indexsource = this.theNodes.findIndex(x => x.id == "Id" + relation.idSource);
          this.theNodes.splice(indexsource, 1);
        }

        if (deleteTarget)
        {
          let indextarget = this.theNodes.findIndex(x => x.id == "Id" + relation.idTarget);
          this.theNodes.splice(indextarget, 1);
        }

        let indexRelationList = this.thirdPartyService.potentialRelationsInfos.relationList.findIndex(x => x.idRelation == id);
        this.thirdPartyService.potentialRelationsInfos.relationList.splice(indexRelationList, 1);
        this.theLinks = [...this.theLinks];
        this.theNodes = [...this.theNodes];
        this.getFilteredTP();
      }
    })
  }

  getColor(idarrow)
  {
    return "black";
    if ("Id" + this.updatedIdRelation == idarrow)
      return "red";
    return 'black';
  }

  updateSearchSourceOptions(id: number)
  {
  }

  updateSearchTargetOptions(id: number)
  {
  }

  filter()
  {
    let filter = new SearchRelation();
    filter.thirdpartyId = this.thirdPartyId ? this.thirdPartyId : this.idFilteredSource;
    filter.targetId = this.idFilteredTarget ? this.idFilteredTarget : 0;
    let reltyp = this.filterTpList.find(x => x.filterId == this.idFilteredType);
    if (reltyp)
      filter.typeId = reltyp.id;
    filter.text = this.filterText;
    this.thirdPartyService.searchTpRelations(filter).subscribe(rels =>
    {
      this.initData();
    })

  }
}
