import { Pipe, PipeTransform } from '@angular/core';
import { GlobalLabel } from 'src/app/kanban/entities/Label';

@Pipe({
  name: 'kanban_card_sorted_labels',
  pure: false
})
export class KanbanCardSortedLabelsPipe implements PipeTransform
{

  transform(value: GlobalLabel[], sort: boolean): any
  {
    if (sort)
      return [...value].sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1);
    else return [...value];
  }

}
