import { Component, OnInit, Input } from '@angular/core';
import { KanbanBoard } from '../../entities/Board';
import { KanbanList } from '../../entities/List';
import { ListAction } from '../../entities/list-actions-control';
import { KanbanService } from '../../services/kanban.service';
import { ColorService } from '../../../../../src/app/services/color.service';
import { KanbanCard } from '../../entities/Card';
import { TriggerActions } from '../../entities/trigger-actions';
import { TranslateService } from '@ngx-translate/core';
import { TextCompareService } from '../../../../../src/app/services/text-compare.service';
import { CacheDataService } from '../../../../../src/app/services/cache-data.service';
import { RelationForListDTO } from '../../entities/list-relation';
import { KanbanTreeSelectedInfos } from '../../entities/kanban-tree-selected-infos';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KanbanBoardTreeComponent } from '../kanban-board-tree/kanban-board-tree.component';

@Component({
  selector: 'app-kanban-actions-creation-card',
  templateUrl: './kanban-actions-creation-card.component.html',
  styleUrls: ['./kanban-actions-creation-card.component.scss']
})
export class KanbanActionsCreationCardComponent implements OnInit
{

  @Input() board: KanbanBoard;
  @Input() list: KanbanList;
  @Input() card: KanbanCard;

  constructor(public kbs: KanbanService, public tcs: TextCompareService, public cs: ColorService, private translateService: TranslateService,
    private modalService: NzModalService,
    public cds: CacheDataService) { }


  createdAction: ListAction = new ListAction();

  triggerActions: TriggerActions[] = [];
  filter = "";
  allowedListForMove: RelationForListDTO[] = [];
  initialBypass = false;
  boardForMove: KanbanBoard[] = [];
  dataHasLoaded = false;

  ngOnInit()
  {

    if (this.card)
      this.kbs.actionsControls = JSON.parse(JSON.stringify(this.card.actions));
    else this.kbs.actionsControls = JSON.parse(JSON.stringify(this.list.actionsAtCardCreation))
    this.initActions();
    this.kbs.getThirdPartyForCard(0).subscribe(x =>
    {
      this.kbs.ppList = [];
      this.kbs.mpList = [];
      for (let tp of x)
      {
        if (tp.thirdPartyType == 1)
          this.kbs.ppList.push(tp);
        else if (tp.thirdPartyType == 2)
          this.kbs.mpList.push(tp)
      }
      this.kbs.ppList = this.kbs.ppList.sort((a, b) => a.firstName.toUpperCase() + a.lastName.toUpperCase() < b.firstName.toUpperCase() + b.lastName.toUpperCase() ? -1 : 1)
      this.kbs.mpList = this.kbs.mpList.sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)
    });

    if (this.list)
      this.allowedListForMove = this.list.allowedDeplacementListIds;
    if (this.allowedListForMove.length == 0)
    {
      this.kbs.getListWorkflowInfos(this.list.id).subscribe(infos =>
      {
        this.allowedListForMove = infos;
        this.kbs.getAllBoardAndListForUser().subscribe(x =>
        {
          this.boardForMove = x;
          this.dataHasLoaded = true;
        });
      })
    }
    else
    {
      this.kbs.getAllBoardAndListForUser().subscribe(x =>
      {
        this.boardForMove = x;
        this.dataHasLoaded = true;
      });
    }
  }

  initActions()
  {
    if (this.list)
    {
      let str_0 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-0").subscribe(x => str_0 = x.toUpperCase());
      let str_1 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-1").subscribe(x => str_1 = x.toUpperCase());
      let str_2 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-2").subscribe(x => str_2 = x.toUpperCase());
      let str_3 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-3").subscribe(x => str_3 = x.toUpperCase());
      let str_4 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-4").subscribe(x => str_4 = x.toUpperCase());
      let str_5 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-5").subscribe(x => str_5 = x.toUpperCase());
      let str_6 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-6").subscribe(x => str_6 = x.toUpperCase());
      this.triggerActions.push({ trigger: "0", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_0, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "1", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_1, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "4", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_4, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_5, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "3", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_3, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "2", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_2, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "6", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_6, isVisible: true, nbActions: 0 });
    }
    else if (this.card)
    {
      let str_1 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-1-5").subscribe(x => str_1 = x.toUpperCase());
      let str_2 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-2-5").subscribe(x => str_2 = x.toUpperCase());
      let str_3 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-3-5").subscribe(x => str_3 = x.toUpperCase());
      let str_4 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-4-5").subscribe(x => str_4 = x.toUpperCase());
      let str_5 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-5-5").subscribe(x => str_5 = x.toUpperCase());
      let str_6 = "";
      this.translateService.get("KANBAN.TRIGGER.OPTION-6-5").subscribe(x => str_6 = x.toUpperCase());
      this.triggerActions.push({ trigger: "1-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_1, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "4-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_4, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "5-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_5, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "3-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_3, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "2-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_2, isVisible: true, nbActions: 0 });
      this.triggerActions.push({ trigger: "6-5", isCollapsed: false, actions: [], createdAction: new ListAction(), triggerDisplayText: str_2, isVisible: true, nbActions: 0 });
    }

    for (let action of this.kbs.actionsControls)
    {
      action.isVisible = true;
      this.translateService.get("KANBAN.ACTION-WORKFLOW." + action.codeAction).subscribe(x => action.actionDisplayText = x.toUpperCase());
      let trigger = this.triggerActions.find(x => x.trigger == action.triggerAction);
      if (!trigger)
      {
        trigger = new TriggerActions();
        trigger.trigger = action.triggerAction;
        this.triggerActions.push(trigger);
      }
      trigger.actions.push(action);
    }

  }

  filterActions(filterText: string)
  {
    let upperFilter = filterText.toUpperCase();
    for (let trig of this.triggerActions)
    {
      for (let act of trig.actions)
      {
        act.isVisible = this.tcs.contains(act.actionDisplayText, upperFilter);
      }
      trig.isVisible = this.tcs.contains(trig.triggerDisplayText, upperFilter) || trig.actions.find(x => x.isVisible) != null;
      trig.isCollapsed = trig.actions.find(x => x.isVisible) == null;
    }
  }

  canAddAction(trig: TriggerActions)
  {
    if (!trig.createdAction.codeAction)
      return false;
    if (trig.createdAction.codeAction == "ASK-CONFIRMATION" && !trig.createdAction.confirm)
      return false;
    if (trig.createdAction.codeAction == "SHOW-INFORMATION-TEXT" && !trig.createdAction.info)
      return false;
    if (trig.createdAction.codeAction == "AFFECT-CARD-MEMBERS-AUTO" && trig.createdAction.memberAutoParam.length == 0)
      return false;
    if (trig.createdAction.codeAction == "AFFECT-CARD-TEAMS-AUTO" && trig.createdAction.teamAutoParam.length == 0)
      return false;
    if (trig.createdAction.codeAction == "NOTIFY-USER-GENERAL-AUTO" && trig.createdAction.notifiedUsers.length == 0)
      return false;
    if (trig.createdAction.codeAction == "MOVE-CARD" && trig.createdAction.listIdMoveCard <= 0)
      return false;
    if (trig.createdAction.codeAction == "COPY-CARD" && trig.createdAction.listIdCopyCard <= 0)
      return false;
    if (trig.createdAction.codeAction == "PLAN-CARD-ARCHIVING-AUTO" && trig.createdAction.dateSchedulingParameter.years == 0
      && trig.createdAction.dateSchedulingParameter.months == 0
      && trig.createdAction.dateSchedulingParameter.days == 0
      && trig.createdAction.dateSchedulingParameter.hours == 0
      && trig.createdAction.dateSchedulingParameter.minutes == 0)
      return false;
    return true;
  }

  showActionToAddToTrigger(trig: TriggerActions, codeAction: string)
  {
    return trig.actions.findIndex(x => x.codeAction == codeAction) < 0;
  }

  addAction(trig: TriggerActions)
  {
    let action = new ListAction();
    action.triggerAction = trig.trigger;
    action.codeAction = trig.createdAction.codeAction;
    action.confirm = trig.createdAction.confirm;
    action.info = trig.createdAction.info;
    action.memberManualParam = trig.createdAction.memberManualParam;
    action.memberAutoParam = trig.createdAction.memberAutoParam;
    action.teamManualParam = trig.createdAction.teamManualParam;
    action.teamAutoParam = trig.createdAction.teamAutoParam;
    action.dueDate = trig.createdAction.dueDate;
    action.labelAutoParam = trig.createdAction.labelAutoParam;
    action.labelManualParam = trig.createdAction.labelManualParam;
    action.thirdPartyAutoParam = trig.createdAction.thirdPartyAutoParam;
    action.thirdPartyManualParam = trig.createdAction.thirdPartyManualParam;
    action.notifiedUsers = trig.createdAction.notifiedUsers;
    action.notifiedTeams = trig.createdAction.notifiedTeams;
    action.listIdMoveCard = trig.createdAction.listIdMoveCard;
    action.listIdCopyCard = trig.createdAction.listIdCopyCard;
    action.dateSchedulingParameter = trig.createdAction.dateSchedulingParameter;
    this.translateService.get("KANBAN.ACTION-WORKFLOW." + action.codeAction).subscribe(x => action.actionDisplayText = x.toUpperCase());
    this.kbs.actionsControls.push(action);
    trig.actions.push(action);
    trig.createdAction = new ListAction();

  }

  searchTpTextAuto = "";
  onSearchAuto(text)
  {
    this.searchTpTextAuto = text;
  }

  getPpAuto()
  {
    return this.kbs.ppList.filter(x => this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, this.searchTpTextAuto))
  }

  getMpAuto()
  {
    return this.kbs.mpList.filter(x => this.tcs.contains(x.name, this.searchTpTextAuto))
  }


  searchTpTextManual = "";
  onSearchManual(text)
  {
    this.searchTpTextManual = text;
  }

  getPpManual()
  {
    return this.kbs.ppList.filter(x => this.tcs.contains(`${x.firstName}${x.lastName}${x.lastName2}`, this.searchTpTextManual))
  }

  getMpManual()
  {
    return this.kbs.mpList.filter(x => this.tcs.contains(x.name, this.searchTpTextManual))
  }

  getBoardBGColor(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return board.bgColor;
      }
    }
    return "";
  }

  getBoardName(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return board.title;
      }
    }
    return "";
  }

  getListName(idlist)
  {
    for (let board of this.boardForMove)
    {
      let list = board.lists.find(x => x.id == idlist);
      if (list)
      {
        return list.title;
      }
    }
    return "";
  }

  selectList(action: ListAction)
  {
    let title = "";
    let confirm = "";
    let cancel = "";
    this.translateService.get("THIRD-PARTY.CALL.SETTINGS.CHOOSE-LIST").subscribe(x => title = x);
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let typeaction = action.codeAction == 'MOVE-CARD' ? 'move' : 'copy';
    this.initialBypass = this.allowedListForMove.length == 0 && (!this.list || (this.kbs.allowMoveForNonRestrictedList && typeaction == "move") || (this.kbs.allowCopyForNonRestrictedList && typeaction == "copy"));
    let modal = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanBoardTreeComponent,
      nzWidth: '90%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5' },
      nzOnOk: (mod) =>
      {
        if (!mod.selectedListForMove)
          return;
        let idlist = parseInt(mod.selectedListForMove.split("_")[1]);
        if (action.codeAction == 'MOVE-CARD')
          action.listIdMoveCard = idlist;
        else action.listIdCopyCard = idlist;
        modal.close();
      },
      nzOkText: confirm,
      nzOnCancel: () =>
      {
        modal.close();
      },
      nzCancelText: cancel,
    });
    modal.componentInstance.boards = this.boardForMove;
    modal.componentInstance.initialbypass = this.initialBypass;
    modal.componentInstance.allowedListForMove = this.allowedListForMove;
    modal.componentInstance.openedBoard = this.board.id;
    modal.componentInstance.loadcardonselect = false;
    modal.componentInstance.showListThatCannotCreateCard = true;
  }

  onSelection(data: KanbanTreeSelectedInfos, action: ListAction)
  {
    if (!data.list)
      return;
    if (action.codeAction == 'MOVE-CARD')
      action.listIdMoveCard = data.list.id;
    else if (action.codeAction == 'COPY-CARD')
      action.listIdCopyCard = data.list.id;
  }

  removeAction(trig: TriggerActions, action: ListAction)
  {
    let index = this.kbs.actionsControls.findIndex(x => x.triggerAction == action.triggerAction && x.codeAction == action.codeAction);
    if (index >= 0)
      this.kbs.actionsControls.splice(index, 1);
    index = trig.actions.findIndex(x => x.triggerAction == action.triggerAction && x.codeAction == action.codeAction);
    if (index >= 0)
      trig.actions.splice(index, 1);
  }

  showupdatedArchivingScheduling(action: ListAction)
  {
    return action.codeAction.indexOf("PLAN-CARD-ARCHIVING-AUTO") >= 0;
  }

  showupdatedMemberSelection(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-CARD-MEMBERS-MANUAL") >= 0;
  }

  showupdatedCopyCardSelection(action: ListAction)
  {
    return action.codeAction.indexOf("COPY-CARD") >= 0;
  }

  showupdatedMoveCardSelection(action: ListAction)
  {
    return action.codeAction.indexOf("MOVE-CARD") >= 0;
  }

  showupdatedTeamsSelectionManual(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-CARD-TEAMS-MANUAL") >= 0;
  }

  showupdatedMemberSelectionAuto(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-CARD-MEMBERS-AUTO") >= 0;
  }

  showupdatedNotifiedUserAuto(action: ListAction)
  {
    return action.codeAction.indexOf("NOTIFY-USER-GENERAL-AUTO") >= 0;
  }

  showupdatedSendMailTeamAuto(action: ListAction)
  {
    return action.codeAction.indexOf("SEND-MAIL-TEAM-AUTO") >= 0;
  }

  showupdatedAffectTeamsAuto(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-CARD-TEAMS-AUTO") >= 0;
  }

  showUpdatedLabelSelectorAuto(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-LABELS-AUTO") >= 0;
  }

  showUpdatedLabelSelectorManual(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-LABELS-MANUAL") >= 0;
  }

  showUpdatedThirdPartySelectorManual(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-THIRD-PARTY-MANUAL") >= 0;
  }

  showUpdatedThirdPartySelectorAuto(action: ListAction)
  {
    return action.codeAction.indexOf("AFFECT-THIRD-PARTY-AUTO") >= 0;
  }

  showupdatedDueDateSelection(action: ListAction)
  {
    return action.codeAction.indexOf("SET-DUE-DATE") >= 0;
  }

  showupdatedConfirmationText(action: ListAction)
  {
    return action.codeAction.indexOf("ASK-CONFIRMATION") >= 0;
  }

  showupdatedInformationText(action: ListAction)
  {
    return action.codeAction.indexOf("SHOW-INFORMATION-TEXT") >= 0;
  }

}
