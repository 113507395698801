import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheDataService } from '../services/cache-data.service';
import { UrlApiService } from '../services/url-api.service';
import { MeetingInfos } from './entities/meeting-infos';
import { MeetingJoinInfos } from './entities/meeting-join-infos';
import { MeetingMembers } from './entities/meeting-members';
import { MeetingJoinedInfos } from './entities/meetng-joined-infos';
import { MeetingSearchParameters } from './entities/search-meeting-parameters';

@Injectable({
    providedIn: 'root'
})
export class ZoomService
{

    constructor(private http: HttpClient, private cds: CacheDataService) { }
    activeMeeting: MeetingInfos = null;
    updateMeetingObs: Subject<string> = new Subject<string>();

    getMeetingInfos(meetingId: string)
    {
        return this.http.get<MeetingInfos>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/${meetingId}`)
    }

    getMeetingParticipants(meetingId: string)
    {
        return this.http.get<MeetingMembers>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/${meetingId}/participants`)
    }

    getMeetings()
    {
        return this.http.get<MeetingInfos[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings`)
    }

    searchMeetings(searchparameters: MeetingSearchParameters)
    {
        return this.http.post<MeetingInfos[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/search`, searchparameters)
    }

    getAllMeetings()
    {
        return this.http.get<MeetingInfos[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/all`)
    }

    getSignature(meetingid: string, role: string)
    {
        return this.http.post<MeetingJoinInfos>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/${meetingid}/role/${role}/signature`, null)
    }

    getScheduledMeetings()
    {
        return this.http.get<MeetingInfos[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/scheduled`).pipe(tap(x => this.cds.scheduledMeetingCollection = x))
    }

    createMeeting(meetingInfos: MeetingInfos)
    {
        return this.http.post<MeetingInfos>(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings`, meetingInfos)
    }

    deleteMeeting(meetingId: string)
    {
        return this.http.delete(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/${meetingId}`)
    }

    startMeeting(meetingId: string)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/${meetingId}/start`, null)
    }

    joinMeeting(meetingId: string, joininfos: MeetingJoinedInfos)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/${meetingId}/join`, joininfos)
    }

    stopMeeting(meetingId: string)
    {
        return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/zoom/meetings/${meetingId}/stop`, null)
    }
}
