import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from 'src/app/user/services/data/user-data.service';
import { HubConnection } from '@microsoft/signalr';
import { User } from 'src/app/user/model/user';
import { CacheDataService } from '../cache-data.service';
import { NavbarService } from 'src/app/components/navbar/navbar.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService
{

  constructor(private userService: UserDataService,
    private notification: NzNotificationService,
    private translateService: TranslateService,
    private navabarService: NavbarService,
    private cds: CacheDataService) { }


  initRoutes(_hubConnection: HubConnection)
  {

    _hubConnection.on('UserIsDisconnect', (userId: number) =>
    {
      let user = this.cds.userCollection.find(x => x.id == userId);
      if (user)
      {
        user.status = "$$-APPEAR-OFFLINE-5ive-$$"
      }

      this.navabarService.statusSub.next(null);

    });

    _hubConnection.on('UserIsConnected', (userId: number, status: string) =>
    {
      let user = this.cds.userCollection.find(x => x.id == userId);
      if (user)
      {
        user.status = status
      }

      this.navabarService.statusSub.next(null);
    });

    _hubConnection.on('CreateUser', (user: User) =>
    {
      this.cds.userCollection.push(user);
      this.userService.ListUserChange.next(null);
      let title = "";
      this.translateService.get("NOTIFICATION.USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.USER.CREATE", { v1: user.name, v2: user.surname }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('ArchiveUser', (user: User) =>
    {
      let index = this.cds.userCollection.findIndex(x => x.id == user.id);
      if (index > -1)
      {
        this.cds.userCollection[index] = user;
        this.userService.ListUserChange.next(null);
      }

      let title = "";
      this.translateService.get("NOTIFICATION.USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.USER.ARCHIVE", { v1: user.name, v2: user.surname }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('RestoreUser', (user: User) =>
    {
      let index = this.cds.userCollection.findIndex(x => x.id == user.id);
      if (index > -1)
      {
        this.cds.userCollection[index] = user;
        this.userService.ListUserChange.next(null);
      }

      let title = "";
      this.translateService.get("NOTIFICATION.USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.USER.RESTORE", { v1: user.name, v2: user.surname }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('ActivateUser', (user: User) =>
    {
      let index = this.cds.userCollection.findIndex(x => x.id == user.id);
      if (index > -1)
      {
        this.cds.userCollection[index] = user;
        this.userService.ListUserChange.next(null);
      }

      let title = "";
      this.translateService.get("NOTIFICATION.USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.USER.ACTIVATE", { v1: user.name, v2: user.surname }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('DesactivateUser', (user: User) =>
    {
      let index = this.cds.userCollection.findIndex(x => x.id == user.id);
      if (index > -1)
      {
        this.cds.userCollection[index] = user;
        this.userService.ListUserChange.next(null);
      }

      let title = "";
      this.translateService.get("NOTIFICATION.USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.USER.DESACTIVATE", { v1: user.name, v2: user.surname }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('UpdateUser', (user: User) =>
    {
      let index = this.cds.userCollection.findIndex(x => x.id == user.id);
      if (index > -1)
      {
        this.cds.userCollection[index] = user;
        this.userService.ListUserChange.next(null);
      }

      let title = "";
      this.translateService.get("NOTIFICATION.USER.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.USER.UPDATE", { v1: user.name, v2: user.surname }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });
  }

}
