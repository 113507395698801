import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from './url-api.service';
import { MenuItem } from '../entities/menu-item';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsService {

constructor(private http: HttpClient) { }

itemCollection: MenuItem[] = [];

getMenuItems()
{
  return this.http.get<MenuItem[]>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/menu-items").pipe(tap(x => this.itemCollection = x))
}

updateItem(item: MenuItem)
{
  return this.http.put<number>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/menu-items", item)
}

deleteItem(item: MenuItem)
{
  return this.http.delete<MenuItem>(UrlApiService.settings.apiConfig.uriAPI + "/api/user/menu-items/" + item.id)
}
}
