import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanbanCardShowDueDatePipe } from './kanban-card-show-due-date.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KanbanCardShowDueDatePipe],
  exports: [KanbanCardShowDueDatePipe]
})
export class KanbanCardShowDueDatePipeModule { }
