import { Injectable } from '@angular/core';
import { CredentialsWithRights } from '../../entities/credentials-with-rights';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlApiService } from '../../../services/url-api.service';
import { Credentials } from '../../entities/credentials';
import { InfoFilterCredentials } from '../../entities/info-filter-credentials';
import { GroupCredentials } from '../../../group-credentials/entities/group-credentials';
import { CredentialsSharedGroupsDTO } from '../../entities/credentials-shared-groups';
import { CredentialsUserWithOrigin } from '../../entities/credential-user-with-origin';
import { CredentialsManager } from '../../entities/credentials-manager';
import { ManagerCredentialsUpdateRight } from '../../entities/manager-credentials-update-right';
import { Right } from '../../../../../src/app/entities/right';
import { CredentialsRights } from '../../entities/credentials-rights';
import { UserForCredentialsWithOrigin } from '../../entities/user-for-credentials-with-origin';
import { CredentialsConsultation } from '../../entities/credentials-consultation';
import { CredentialWithGroupDTO } from '../../entities/credential-with-groups';

@Injectable({
  providedIn: 'root'
})
export class CredentialsSourceService
{

  constructor(private http: HttpClient) { }

  public getCredentials(adminMode: boolean, typefilter: number): Observable<CredentialsWithRights>
  {
    return this.http.get<CredentialsWithRights>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getcredentialsforuser/' + adminMode + "/visibility/" + typefilter);
  }

  public GetPassword(idCredentials: number): Observable<Credentials>
  {
    return this.http.get<Credentials>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getpassword/' + idCredentials);
  }

  public getManagerWithStatus(idCredentials: number): Observable<CredentialsManager[]>
  {
    return this.http.get<CredentialsManager[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getmanagerswithstatus/' + idCredentials);
  }

  public getManagersRights(manager: CredentialsManager): Observable<Right[]>
  {
    return this.http.post<Right[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getmanagersrights', manager);
  }

  public changeManagerRight(updateRight: ManagerCredentialsUpdateRight)
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/changeright', updateRight);
  }

  public addManagersToCredentials(cred: CredentialsManager[])
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/addmanager', cred);
  }

  public removeManagersFromCredentials(cred: CredentialsManager[])
  {
    return this.http.post(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/removemanager', cred);
  }
  public createCredentials(cred: Credentials): Observable<InfoFilterCredentials>
  {
    return this.http.post<InfoFilterCredentials>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/create', cred);
  }

  public createCredentialsWithGroups(cred: CredentialWithGroupDTO): Observable<InfoFilterCredentials>
  {
    return this.http.post<InfoFilterCredentials>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/create-with-groups', cred);
  }

  public updateCredentials(cred: Credentials): Observable<InfoFilterCredentials>
  {
    return this.http.post<InfoFilterCredentials>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/update', cred);
  }

  public getGroupsOfCredentials(idcred: number): Observable<CredentialsSharedGroupsDTO[]>
  {
    return this.http.get<CredentialsSharedGroupsDTO[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getgroups/' + idcred);
  }

  public updateGroupsOfCredentials(groups: CredentialsSharedGroupsDTO[])
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/updategroups', groups);
  }

  public getUsersAccessingCredential(idcred: number): Observable<UserForCredentialsWithOrigin[]>
  {
    return this.http.get<UserForCredentialsWithOrigin[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getusers/' + idcred);
  }

  public UpdateShowLoginPreferences(key: string, value: string)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/preference/' + key + '?showvalue=' + value, null)
  }

  public GetShowLoginPreference(): Observable<string>
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/preference/show-login');
  }

  public GetColumnVisibilityPreference(): Observable<string[]>
  {
    return this.http.get<string[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/preference');
  }

  public GetAdminModePreference(): Observable<string>
  {
    return this.http.get<string>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/preference/admin-mode');
  }

  public GetCredentialsConsultationHistory(idcred: number): Observable<CredentialsConsultation[]>
  {
    return this.http.get<CredentialsConsultation[]>(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/getconsultation/' + idcred);
  }

  public ArchiveCredentials(idcred: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/' + idcred + '/archive', null);
  }

  public RestoreCredentials(idcred: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + '/api/credentials/' + idcred + '/restore', null);
  }
}
