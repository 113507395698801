import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TypeRelation } from '../../entities/type-relation';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UrlApiService } from 'src/app/services/url-api.service';
import { TypeRelationSingleDirection } from 'src/app/call-interface/entities/type-relation-single-direction';
import { ThirdPartyRelationForTypeRelation } from '../entities/third-party-relation-for-type';
import { MigrateRelationParams } from '../entities/migrate-relation-param';

@Injectable({
  providedIn: 'root'
})
export class TypeRelationService
{

  constructor(private http: HttpClient) { }

  isLoading = false;
  typeRelationList: TypeRelation[] = [];
  typeRelationListChange: Subject<TypeRelation[]> = new Subject<TypeRelation[]>();

  getTpForFilterCreation()
  {
    let trList = [];
    for (let tr of this.typeRelationList)
    {
      trList.push(this.copyType(tr, "0"));
      trList.push(this.copyType(tr, "1"));
    }
    return trList;
  }

  copyType(type: TypeRelation, direction: string)
  {
    let tp = new TypeRelation();
    tp.id = type.id;
    tp.nameSourceTarget = type.nameSourceTarget;
    tp.nameTargetSource = type.nameTargetSource;
    tp.typeSource = type.typeSource;
    tp.typeTarget = type.typeTarget;
    tp.filterId = type.id + "-" + direction;
    return tp;
  }

  getTpCreationName(type: TypeRelation)
  {
    if (type.filterId.endsWith("-1"))
      return type.nameTargetSource;
    return type.nameSourceTarget;
  }

  getAllTypeRelations()
  {
    return this.http.get<TypeRelation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation`).pipe(tap(x => this.typeRelationList = x))
  }

  getAllTypeRelationsSingleDirection()
  {
    return this.http.get<TypeRelationSingleDirection[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation/single-direction`)
  }


  createTypeRelation(typerelation: TypeRelation)
  {
    return this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation`, typerelation)
  }

  getRelationForType(typerelation: TypeRelation)
  {
    return this.http.get<ThirdPartyRelationForTypeRelation[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation/${typerelation.id}/relations`)
  }

  migrateRelations(migrationParams: MigrateRelationParams)
  {
    return this.http.put<number[]>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation/migrate-relations`, migrationParams)
  }

  updateRelation(typerelation: TypeRelation)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation/${typerelation.id}`, typerelation)
  }

  archiveTypeRelation(idtyperelation: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation/${idtyperelation}/archive`, null)
  }

  restoreTypeRelation(idtyperelation: number)
  {
    return this.http.put(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/type-relation/${idtyperelation}/restore`, null)
  }

}
