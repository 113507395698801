import { Component, OnInit, Input } from '@angular/core';
import { KanbanCard } from '../../entities/Card';
import { CallThirdPartyInfos } from '../../entities/call-third-party-infos';

@Component({
  selector: 'app-call-third-party',
  templateUrl: './call-third-party.component.html',
  styleUrls: ['./call-third-party.component.scss']
})
export class CallThirdPartyComponent implements OnInit {

  constructor() { }

  @Input() card: KanbanCard;
  phoneNumbersInfos: CallThirdPartyInfos[] = [];

  ngOnInit() {
    for(let pp of this.card.physicalPersons)
    {
      for(let pn of pp.phoneNumbers)
      {
        this.phoneNumbersInfos.push({phoneNumber: pn.number, thirdPartyName: pp.firstName + ' ' + pp.lastName})
      }
    }

    for(let mp of this.card.moralPersons)
    {
      for(let pn of mp.phoneNumbers)
      {
        this.phoneNumbersInfos.push({phoneNumber: pn.number, thirdPartyName: mp.name})
      }
    }
  }

  startTheCall(number: string)
  {
    var link = document.createElement('a');
    link.href = "sip:" + number;
    link.click();
  }

}
