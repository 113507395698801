import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { GlossaryEntry } from 'src/app/glossary/entities/entry';
import { GlossaryEntryService } from 'src/app/glossary/services/glossary-entry.service';
import { GlossaryCategory } from 'src/app/glossary/entities/category';
import { GlossaryCategoryService } from 'src/app/glossary/services/glossary-category.service';

@Injectable({
  providedIn: 'root'
})
export class GlossaryNotificationService
{

  constructor(private ges: GlossaryEntryService, private gcs: GlossaryCategoryService) { }

  initRoutes(_hubConnection: HubConnection)
  {

    //---------------------------------------------------
    // ENTRY
    //---------------------------------------------------
    _hubConnection.on('GlossaryEntryCreation', (glo: GlossaryEntry) =>
    {
      this.ges.glossaryEntries.push(glo);
      this.ges.glossaryEntriesChange.next(null);
    });

    _hubConnection.on('GlossaryEntryUpdate', (glo: GlossaryEntry) =>
    {
      let index = this.ges.glossaryEntries.findIndex(x => x.id == glo.id);
      if (index == -1)
        return;
      this.ges.glossaryEntries[index] = glo;
      this.ges.glossaryEntriesChange.next(null);
    });

    _hubConnection.on('GlossaryEntryArchive', (glo: GlossaryEntry) =>
    {
      let index = this.ges.glossaryEntries.findIndex(x => x.id == glo.id);
      if (index == -1)
        return;
      this.ges.glossaryEntries[index].archived = true;
      this.ges.glossaryEntriesChange.next(null);
    });

    _hubConnection.on('GlossaryEntryRestore', (glo: GlossaryEntry) =>
    {
      let index = this.ges.glossaryEntries.findIndex(x => x.id == glo.id);
      if (index == -1)
        return;
      this.ges.glossaryEntries[index].archived = false;
      this.ges.glossaryEntriesChange.next(null);
    });
    //---------------------------------------------------
    // CATEGORY
    //---------------------------------------------------
    _hubConnection.on('GlossaryCategoryCreation', (glo: GlossaryCategory) =>
    {
      this.gcs.glossaryCategories.push(glo);
      this.gcs.glossaryCategoriesChange.next(null);
    });

    _hubConnection.on('GlossaryCategoryUpdate', (glo: GlossaryCategory) =>
    {

      let index = this.gcs.glossaryCategories.findIndex(x => x.id == glo.id);
      if (index == -1)
        return;
      this.gcs.glossaryCategories[index] = glo;
      this.gcs.glossaryCategoriesChange.next(null);
    });

    _hubConnection.on('GlossaryCategoryArchive', (glo: GlossaryCategory) =>
    {
      let index = this.gcs.glossaryCategories.findIndex(x => x.id == glo.id);
      if (index == -1)
        return;
      this.gcs.glossaryCategories[index].archived = true;
      this.gcs.glossaryCategoriesChange.next(null);
    });

    _hubConnection.on('GlossaryCategoryRestore', (glo: GlossaryCategory) =>
    {
      let index = this.gcs.glossaryCategories.findIndex(x => x.id == glo.id);
      if (index == -1)
        return;
      this.gcs.glossaryCategories[index].archived = false;
      this.gcs.glossaryCategoriesChange.next(null);
    });

  }

}
