import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { MoralPerson } from '../../entities/moral-person';
import { MoralPersonService } from '../../services/moral-person.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomField } from '../../../../third-party/entities/custom-field';
import { PhoneNumber } from '../../../../third-party/entities/phone-number';
import { CredentialsEdit } from '../../../../third-party/entities/credentials-edit';
import { ThirdPartyService } from '../../../../third-party/services/third-party.service';
import { Site } from '../../../../third-party/entities/site';
import { SiteEditComponent } from '../../../../third-party/site/components/site-edit/site-edit.component';
import { SiteService } from '../../../../third-party/site/services/site.service';
import { UrlApiService } from '../../../../services/url-api.service';
import { KanbanAttachment } from '../../../../kanban/entities/Attachment';
import { KanbanCard } from '../../../../kanban/entities/Card';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { TodoService } from '../../../../kanban/services/todo.service';
import { KanbanService } from '../../../../kanban/services/kanban.service';
import { FilterRelation } from '../../../../third-party/entities/filter-relation';
import { HttpClient } from '@angular/common/http';
import { Mail } from '../../../../third-party/entities/mail';
import { SearchClientInfoComponent } from '../search-client-info/search-client-info.component';
import { SearchClientResult } from '../../entities/search-client-result';
import { ColorService } from '../../../../services/color.service';
import { KanbanToolsService } from '../../../../kanban/services/kanban-tools.service';
import { AvatarService } from '../../../../services/avatar.service';
import { CacheDataService } from '../../../../services/cache-data.service';
import { FormManagerService } from '../../../../form-manager/services/form-manager.service';
import { Website, SecondaryName, Brand } from '../../../../third-party/entities/one-attribute-class';
import { MessagerieService } from '../../../../messagerie/messagerie.service';
import { TextCompareService } from '../../../../services/text-compare.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KanbanCardEditModalComponent } from '../../../../kanban/components/kanban-card-edit-modal/kanban-card-edit-modal.component';
import { GlobalLabel } from '../../../../kanban/entities/Label';
import { ThirdPartyCustomSystemField } from '../../../../call-interface/entities/third-party-custom-system-field';
import { ThirdPartyCustomSystemFieldService } from '../../../../third-party/services/third-party-custom-system-field.service';
import { firstValueFrom } from 'rxjs';
import { TextSearch } from '../../../../entities/search-text';

@Component({
  selector: 'app-moral-person-edit',
  templateUrl: './moral-person-edit.component.html',
  styleUrls: ['./moral-person-edit.component.scss']
})
export class MoralPersonEditComponent implements OnInit
{
  url = UrlApiService.settings.apiConfig.uriAPI + '/api/user/donothing';
  constructor(public mpService: MoralPersonService,
    private translateService: TranslateService,
    private siteService: SiteService,
    private modalService: NzModalService,
    private router: Router,
    private tdservice: TodoService,
    private msgs: NzMessageService,
    private authService: AuthService,
    public cs: ColorService,
    private kbs: KanbanService,
    private kts: KanbanToolsService,
    private csfs: ThirdPartyCustomSystemFieldService,
    private http: HttpClient,
    public cds: CacheDataService,
    private mss: MessagerieService,
    public tcs: TextCompareService,
    private fms: FormManagerService,
    public avatarService: AvatarService,
    public tpService: ThirdPartyService) { }

  @Input() moralPerson: MoralPerson;
  @Input() parent: MoralPerson;
  @Input() searchResult: SearchClientResult;
  @Input() loadData: boolean = false;

  name = "";
  nameIsInValid = false;
  mainCurrency = "Euro";
  codeTVA = "";
  codeAPE = "";
  anniversaryDate;
  siret = "";
  siretIsInvalid = false;
  siren = "";
  sirenIsInvalid = false;
  comment = "";
  nationality = "fr";
  tags = [];
  websites: Website[] = [];
  initials = "";
  secondaryNames: SecondaryName[] = [];
  brands: Brand[] = [];
  sign = "";
  image = "";
  defaultExpandedKeys = [];
  listOfOption: string[] = [];
  filteredOptions: string[] = [];
  filteredMailOptions: string[] = [];
  noFocusNumber = true;
  phoneNumbers: PhoneNumber[] = [];
  customFields: CustomField[] = [];
  customSystemFields: ThirdPartyCustomSystemField[] = [];
  credentialsAssociated: CredentialsEdit[] = [];
  credentialsNotAssociated: CredentialsEdit[] = [];
  credentialsLoaded = false;
  attachmentsPictures: KanbanAttachment[] = [];
  attachmentsNotPictures: KanbanAttachment[] = [];
  options = [];
  moveSearchValue = "";
  boardsForMove = [];
  boardsForMoveArchived = [];
  floatingCardsArchived = [];
  defaultExpandedKeysArchived = [];
  todoListForMoveArchived = [];
  todoExpandedKeysArchived = [];
  todoListForMove = [];
  filterTextForTree = "";
  selectedForMove = "";
  createdTask = "";
  todoExpandedKeys = [];
  floatingCards: KanbanCard[] = [];
  mails: Mail[] = [];
  labels: GlobalLabel[] = [];
  loadgraph = false;
  loadCustoms = false;
  secondarySites: MoralPerson[] = [];
  selectedSecondarySite: MoralPerson;
  filteredRelations: FilterRelation[] = [];
  index = 0;
  updateindex = 0;
  boolArchivedList = false;
  archivedCards: KanbanCard[] = []

  isDisabled(): boolean
  {
    if (this.parent)
      return true;
    if (this.moralPerson && this.moralPerson.parentSiteId)
      return true;
    return false;
  }

  setSelectedCustomTab(event: number)
  {
    this.updateindex = event;
  }

  async initLoadData()
  {
    if (this.loadData)
    {
      this.moralPerson = await firstValueFrom(this.mpService.getMoralPerson(this.moralPerson.id));
    }
  }

  ngOnInit()
  {
    this.initLoadData();
    let mpmodel = this.cds.moralPersonCollection ? this.cds.moralPersonCollection[0] : null;
    if (mpmodel)
    {
      this.filteredRelations = mpmodel.filteredRelations.filter(x => x.isGlobal || x.isSystem)
    }

    if (this.moralPerson)
      this.tpService.getThirdPartyActivities(this.moralPerson.id).subscribe();
    this.tpService.getAllLabels().subscribe();
    this.initBoardMove();

    this.csfs.getCustomSystemField().subscribe(x => this.customSystemFields = x.filter(cs => cs.thirdPartyType == 0 || cs.thirdPartyType == 2))

    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.MOBILE").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.HOME").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.WORK").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.MAIN").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.FAX-WORK").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.FAX-HOME").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.BEEPER").subscribe(x => this.options.push(x));
    this.translateService.get("THIRD-PARTY.PHONE-NUMBER.OTHER").subscribe(x => this.options.push(x));
    this.filteredOptions = this.options;
    this.filteredMailOptions = this.tpService.initMailFilteredOption();
    this.siteService.getAllSites().subscribe();

    if (this.searchResult)
    {
      this.name = this.searchResult.raisonSociale;
      this.siren = this.searchResult.numeroSIREN;
      this.siret = this.searchResult.numeroSIRET;
      this.codeAPE = this.searchResult.codeNAF;
      this.codeTVA = this.searchResult.numeroTVAIntracom;
      this.anniversaryDate = this.searchResult.creationDate;
      this.nationality = "fr";
      this.addSiteWithSearchResult(this.searchResult);
    }

    if (this.parent)
    {
      this.siret = this.parent.siret;
      this.siren = this.parent.siren;
    }
    this.RemoveProfileImage();
    if (this.moralPerson != null)
    {
      this.labels = [...this.moralPerson.labels];
      this.tpService.editedMoralPerson = this.moralPerson;
      this.name = this.moralPerson.name;
      this.mainCurrency = this.moralPerson.mainCurrency;
      this.codeTVA = this.moralPerson.codeTVA;
      this.codeAPE = this.moralPerson.codeAPE;
      this.nationality = this.moralPerson.nationality;
      this.anniversaryDate = this.moralPerson.anniversaryDate;
      this.siret = this.moralPerson.siret;
      this.siren = this.moralPerson.siren;
      this.comment = this.moralPerson.comment;
      this.sites = this.moralPerson.sites;
      this.phoneNumbers = this.moralPerson.phoneNumbers;
      this.customFields = this.moralPerson.customFields;
      this.tags = this.moralPerson.tags;
      this.websites = this.moralPerson.websites.map(x => ({ value: x }));
      this.initials = this.moralPerson.initials;
      this.brands = this.moralPerson.brands.map(x => ({ value: x }));
      this.secondaryNames = this.moralPerson.secondaryNames.map(x => ({ value: x }));
      this.sign = this.moralPerson.sign;
      this.mails = this.moralPerson.mails;
      setTimeout(() =>
      {
        this.avatarService.avatarUrl = this.moralPerson.image;
      }, 100)
      this.secondarySites = [...this.moralPerson.secondarySites];
      this.tpService.getCredentialsForThirdParty(this.moralPerson.id).subscribe(x =>
      {
        this.credentialsAssociated = x.credentialsAssociated;
        this.credentialsNotAssociated = x.credentialsNotAssociated;
        this.credentialsLoaded = true;
      });
      this.tpService.getPotentialsRelationsForThirdParty(this.moralPerson.id).subscribe(x =>
      {
        this.loadCustoms = true;
      });
    }
    else
    {
      this.tpService.getCredentialsForThirdParty(0).subscribe(x =>
      {
        this.credentialsAssociated = x.credentialsAssociated;
        this.credentialsNotAssociated = x.credentialsNotAssociated;
        this.credentialsLoaded = true;
      });
    }

  }

  showArchivedCard()
  {
    if (this.boolArchivedList)
    {
      this.tpService.getAllArchivedCardsFromAThirdParty(this.moralPerson.id).subscribe((x: KanbanCard[]) =>
      {
        this.archivedCards = x;
      })
      setTimeout(x => this.associateCard(), 100)
    }
  }

  associateCard()
  {
    if (!this.archivedCards)
      return;
    this.defaultExpandedKeys = [];
    let filterValueUpper = this.moveSearchValue.toUpperCase();
    this.boardsForMoveArchived = [];
    this.todoListForMoveArchived = [];
    this.floatingCardsArchived = [];
    this.archivedCards.forEach(card =>
    {
      if (!this.tcs.contains(card.title, filterValueUpper) && !card.listName)
        return;
      else if (!this.tcs.contains(card.title, filterValueUpper) && !this.tcs.contains(card.listName, filterValueUpper) && !this.tcs.contains(card.boardName, filterValueUpper))
        return;
      if (card.boardId != 0 && card.archived)
      {
        let board = this.boardsForMoveArchived.find(x => x.key == "board_" + card.boardId);
        if (board == null)
        {
          board = {
            title: card.boardName,
            key: "board_" + card.boardId,
            bgcolor: card.bgColor,
            expanded: true,
            idlist: 0,
            children: []
          };
          if (card.boardName != card.listName)
            this.boardsForMoveArchived.push(board);
        }
        this.defaultExpandedKeysArchived.push(board.key)

        let list = board.children.find(x => x.key == "list_" + card.listId);
        if (list == null)
        {
          list = {
            title: card.listName,
            key: "list_" + card.listId,
            expanded: true,
            children: []
          }
          if (card.boardName != card.listName)
            board.children.push(list);
          else
          {
            this.todoExpandedKeysArchived.push(list.key);
            this.todoListForMoveArchived.push(list);
          }

        }
        this.defaultExpandedKeys.push(list.key)

        let cardNode = {
          title: card.title,
          key: "card_" + card.id,
          isLeaf: true
        }
        list.children.push(cardNode);
      }
      else
      {
        this.floatingCardsArchived.push(card);
      }
    });
    this.boardsForMoveArchived = [...this.boardsForMoveArchived];
    this.todoListForMoveArchived = [...this.todoListForMoveArchived];

  }

  checkDuplicateName()
  {
    if (!this.name)
      return false;
    let upperfilter = this.name.toUpperCase();
    let isduplicate = this.cds.moralPersonCollection.findIndex(x => !x.archived && (!this.moralPerson || x.id != this.moralPerson.id) && x.name.toUpperCase() == upperfilter) >= 0;
    return isduplicate;
  }

  checkDuplicateSiren()
  {
    if (!this.siren)
      return false;

    for (let x of this.cds.moralPersonCollection)
    {
      if (!x.archived
        && (!this.moralPerson || x.id != this.moralPerson.id)
        && (x.siren.toUpperCase() == this.siren
          && ((this.moralPerson && !this.moralPerson.parentSiteId)
            || (this.moralPerson && this.moralPerson.parentSiteId && this.moralPerson.parentSiteId != x.id)
            || (!this.moralPerson && !this.parent)
            || (!this.moralPerson && this.parent && this.parent.id != x.id)
            || (this.secondarySites.length && this.secondarySites.findIndex(y => y.id == x.id) < 0)
          )
        )
      )
      {
        return true
      }
    }
    return false;
  }

  addSiteWithSearchResult(searchResult: SearchClientResult)
  {
    let titleCreateSite = "";
    this.translateService.get("THIRD-PARTY.MORAL-PERSON.SEARCH-CLIENT-CREATE-ADDRESS").subscribe(x => titleCreateSite = x);
    let modal = this.modalService.confirm({
      nzTitle: titleCreateSite,
      nzOnOk: () =>
      {
        let siteTemp = new Site();
        siteTemp.name = searchResult.raisonSociale;
        siteTemp.address = this.fms.initFieldWithSearch(searchResult);
        siteTemp.countryCode = "FR";
        siteTemp.origin = "auto";
        this.siteService.createSite(siteTemp).subscribe(x =>
        {
          siteTemp.id = x;
          this.sites.push(siteTemp)
          this.siteService.siteList.push(siteTemp);
          modal.close();
          if (this.kbs.modalEdit)
            this.kbs.modalEdit.close();
        });
      },
      nzOkText: "Yes",
      nzOnCancel: () => { modal.close(); },
      nzCancelText: "No"
    })
  }

  openClientSearch()
  {
    let prefilter = null;
    if (!this.parent)
    {
      if (this.siret)
        prefilter = this.siret;
      else if (this.siren)
        prefilter = this.siren;
      else if (this.name)
        prefilter = this.name;
    }
    else
    {
      if (this.siren)
        prefilter = this.siren;
      else if (this.siret)
        prefilter = this.siret;
      else if (this.name)
        prefilter = this.name;
    }
    let title = "";
    this.translateService.get("THIRD-PARTY.MORAL-PERSON.SEARCH-CLIENT-TITLE").subscribe(x => title = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: SearchClientInfoComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzFooter: [
        {
          label: close,
          onClick: () => { this.kbs.modalEdit.close(); }
        },
        {
          label: confirm,
          type: 'primary',
          disabled(result): boolean { return result.selectedResult == null && result.resultsList.length != 1 },
          onClick: (result) =>
          {
            if (result.selectedResult || result.resultsList.length == 1)
            {
              if (!result.selectedResult)
                result.selectedResult = result.resultsList[0];
              this.searchResult = result.selectedResult;

              if (!this.name)
                this.name = result.selectedResult.raisonSociale;
              this.siren = result.selectedResult.numeroSIREN;
              this.siret = result.selectedResult.numeroSIRET;
              this.codeAPE = result.selectedResult.codeNAF;
              this.codeTVA = result.selectedResult.numeroTVAIntracom;
              if (!this.anniversaryDate)
                this.anniversaryDate = result.selectedResult.creationDate;
              this.nationality = "fr";
              this.mainCurrency = "Euro";
              this.addSiteWithSearchResult(result.selectedResult);
            }
          }
        }
      ]
    });
    this.kbs.modalEdit.componentInstance.prefilter = prefilter;
  }

  cancel() { }

  showGraph()
  {
    setTimeout(() =>
    {
      this.loadgraph = true;
    }, 100);
  }

  getFilteredLabelCollection()
  {
    return this.tpService.labelCollection.filter(x => x.thirdPartyType != 1);
  }

  updateLabel(id: number)
  {
    let label = this.tpService.labelCollection.find(x => x.id == id)
    if (label)
      this.labels.push(label);
    this.tags = [];
  }

  addLabel(label: GlobalLabel)
  {
    this.labels.push(label);
    this.tags = [];
    this.labels.sort((a, b) => a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1)
  }

  removeLabel(label: GlobalLabel)
  {
    let index = this.labels.findIndex(x => x.id == label.id);
    this.labels.splice(index, 1);
  }

  labelIsIn(label: GlobalLabel)
  {
    return this.labels.findIndex(x => x.id == label.id) >= 0;
  }

  removeFilteredRelationTab(filter: FilterRelation)
  {
    this.tpService.removeSystemFilteredRelationTab(filter.id).subscribe(() =>
    {
      let index = this.moralPerson.filteredRelations.findIndex(x => x.id == filter.id);
      this.moralPerson.filteredRelations.splice(index, 1);
      for (let mp of this.cds.moralPersonCollection)
      {
        index = mp.filteredRelations.findIndex(x => x.id == filter.id);
        if (index >= 0)
          mp.filteredRelations.splice(index, 1)
      }
    })
  }

  initSiret()
  {
    if (!this.siret)
      this.siret = this.siren;
  }

  createTask()
  {
    if (!this.createdTask)
      return;
    let card: KanbanCard = new KanbanCard();
    card.title = this.createdTask;
    card.members.push(this.authService.connectedUser.id);

    this.tdservice.createTask(card).subscribe(x =>
    {
      card.id = x;
      this.kbs.addThirdPartyToCard(card.id, this.moralPerson.id).subscribe(() =>
      {
        card.moralPersons.push(this.moralPerson);
        this.moralPerson.cards.push(card);
        this.floatingCards.push(card);
        this.floatingCards = [...this.floatingCards];
        this.editTask(card);

        this.createdTask = null;
      })
    });

  }

  editTask(task: KanbanCard)
  {
    if (task.id == 0)
      return;
    this.kbs.editedCard = task;

    let title = "";
    this.translateService.get("KANBAN.UPDATE-CARD").subscribe(x => title = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    this.kbs.modalEdit = this.modalService.create({
      nzTitle: title,
      nzContent: KanbanCardEditModalComponent,
      nzWidth: '100%',
      nzMaskClosable: false,
      nzBodyStyle: { 'background-color': '#f0f2f5', height: '85vh', 'padding-right': '0', 'padding-top': '0', 'padding-bottom': '0' },
      nzFooter: [{
        label: close,
        onClick: () => { this.kbs.modalEdit.close(); }
      }
      ]
    });

    this.kbs.modalEdit.componentInstance.card = task;
    this.kbs.modalEdit.componentInstance.board = null;
    this.kbs.modalEdit.componentInstance.list = null;

    this.kbs.modalEdit.afterClose.subscribe((result) =>
    {
      this.kbs.editedCard = null;
      this.kts.resetEditModalField();
    });
  }

  filterLisForMove(filter: string)
  {
    this.initBoardMove();
    this.associateCard();
    this.filterTextForTree = filter;
  }

  extandTree(event: any)
  {
    if (!event.node.isLeaf)
      event.node.isExpanded = !event.node.isExpanded
  }

  gotoCardBoard(key: string)
  {
    if (!this.boolArchivedList)
    {
      let idcard = parseInt(key.split('_')[1]);
      let card = this.moralPerson.cards.find(x => x.id == idcard);
      this.mpService.modal.close();
      this.router.navigate(['/kanban/' + card.boardId + "/" + card.id]);
    }
    else
    {
      let idcard = parseInt(key.split('_')[1]);
      let card = this.archivedCards.find(x => x.id == idcard);
      this.mpService.modal.close();
      this.router.navigate(['/kanban/' + card.boardId + "/" + card.id]);
    }
  }

  goToDoList(key: string)
  {
    if (!this.boolArchivedList)
    {
      let idcard = parseInt(key.split('_')[1]);
      let card = this.moralPerson.cards.find(x => x.id == idcard);
      this.mpService.modal.close();
      this.router.navigate(['/kanban/todo/tasks/' + card.id]);
    }
    else
    {
      let idcard = parseInt(key.split('_')[1]);
      let card = this.archivedCards.find(x => x.id == idcard);
      this.mpService.modal.close();
      this.router.navigate(['/kanban/todo/tasks/' + card.id]);
    }
  }

  goToFloating(id: number)
  {
    this.mpService.modal.close();
    this.router.navigate(['/kanban/todo/tasks/' + id]);
  }

  askSavePP()
  {
    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    let confirm = "";
    this.translateService.get("GENERIC-ACTIONS.CONFIRM").subscribe(x => confirm = x);
    let title = "";
    this.translateService.get("THIRD-PARTY.SAVE-THIRD-PARTY-TITLE").subscribe(x => title = x);
    let content = "";
    this.translateService.get("THIRD-PARTY.SAVE-THIRD-PARTY-CONTENT").subscribe(x => content = x);
    this.modalService.confirm({
      nzTitle: title,
      nzContent: content,
      nzOkText: confirm,
      nzCancelText: cancel,
      nzOnOk: () => 
      {
        let mp = this.submitForm();
        if (mp == null)
          return;
        let thenewindex = this.updateindex;
        this.mpService.createMoralPerson(mp, this.avatarService.avatarFile).subscribe(x =>
        {
          mp.id = x;
          this.moralPerson = mp;
          for (let att of this.attachmentsPictures)
          {
            this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${mp.id}/attachment`, att).subscribe(x =>
            {
              att.id = x;
              mp.attachments.push(att);
            });
          }

          for (let att of this.attachmentsNotPictures)
          {
            this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${mp.id}/attachment`, att).subscribe(x =>
            {
              att.id = x;
              mp.attachments.push(att);
            });
          }
          this.tpService.updateThirdPartyCredential(mp.id, this.credentialsAssociated).subscribe();
          this.tpService.getPotentialsRelationsForThirdParty(mp.id).subscribe(x =>
          {
            let mpmodel = this.cds.moralPersonCollection ? this.cds.moralPersonCollection[0] : null;
            if (mpmodel)
            {
              mp.filteredRelations = mpmodel.filteredRelations.filter(x => x.isGlobal || x.isSystem)
              this.loadCustoms = true;
              this.index = thenewindex;
            }
          });
          if (this.parent)
          {
            let temp = this.cds.moralPersonCollection.find(t => t.id == this.parent.id);
            temp.secondarySites.push(mp);
          }
          else if (this.cds.moralPersonCollection.findIndex(t => t.id == mp.id) < 0)
          {
            this.cds.moralPersonCollection.push(mp);
            this.mpService.moralPersonListChange.next(null);
          }
        });
      }
    });

  }

  initBoardMove()
  {
    if (!this.moralPerson)
      return;
    this.defaultExpandedKeys = [];
    let filterValueUpper = this.moveSearchValue.toUpperCase();
    this.boardsForMove = [];
    this.todoListForMove = [];
    this.floatingCards = [];
    this.moralPerson.cards.forEach(card =>
    {
      if (!this.tcs.contains(card.title, filterValueUpper) && !card.listName)
        return;
      else if (!this.tcs.contains(card.title, filterValueUpper) && !this.tcs.contains(card.listName, filterValueUpper) && !this.tcs.contains(card.boardName, filterValueUpper))
        return;
      if (card.boardId != 0)
      {
        let board = this.boardsForMove.find(x => x.key == "board_" + card.boardId);
        if (board == null)
        {
          board = {
            title: card.boardName,
            key: "board_" + card.boardId,
            bgcolor: card.bgColor,
            expanded: true,
            idlist: 0,
            children: []
          };
          if (card.boardName != card.listName)
            this.boardsForMove.push(board);
        }
        this.defaultExpandedKeys.push(board.key)

        let list = board.children.find(x => x.key == "list_" + card.listId);
        if (list == null)
        {
          list = {
            title: card.listName,
            key: "list_" + card.listId,
            expanded: true,
            children: []
          }
          if (card.boardName != card.listName)
            board.children.push(list);
          else
          {
            this.todoExpandedKeys.push(list.key);
            this.todoListForMove.push(list);
          }
        }
        this.defaultExpandedKeys.push(list.key)

        let cardNode = {
          title: card.title,
          key: "card_" + card.id,
          isLeaf: true
        }
        list.children.push(cardNode);
      }
      else
      {
        this.floatingCards.push(card);
      }
    });
    this.boardsForMove = [...this.boardsForMove];
    this.todoListForMove = [...this.todoListForMove];
  }

  getBoardColor(boardkey: string)
  {
    if (!this.boolArchivedList)
    {
      let board = this.boardsForMove.find(x => x.key == boardkey);
      if (!board)
        board = this.todoListForMove.find(x => x.key == boardkey);
      return board.bgcolor;
    }
    else
    {
      let board = this.boardsForMoveArchived.find(x => x.key == boardkey);
      if (!board)
        board = this.todoListForMoveArchived.find(x => x.key == boardkey);
      return board.bgcolor;
    }
  }

  RemoveProfileImage()
  {
    this.avatarService.avatarUrl = "";
    this.avatarService.avatarFile = null;
  }

  sites: Site[] = [];
  selectedSite: Site;
  addSite(site: Site)
  {
    this.sites.push(site);
    if (this.moralPerson)
      this.siteService.addThirdPartyToSite(site.id, [this.moralPerson.id]).subscribe();
    setTimeout(() =>
    {
      this.selectedSite = null;
    }, 200);
  }

  getSecondarySite()
  {
    return this.secondarySites.filter(x => x && !x.archived).sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);
  }
  getPotentialSites()
  {
    return this.siteService.siteList.filter(x => !x.archived && this.sites.findIndex(y => x.id == y.id) < 0).sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);
  }

  editSecondarySite(mp: MoralPerson)
  {

  }

  addSecondarySites(mp: MoralPerson)
  {
    this.secondarySites.push(mp);
    this.selectedSecondarySite = null;
  }

  removeSecondarySite(mp: MoralPerson)
  {
    let index = this.secondarySites.findIndex(x => x.id == mp.id);
    this.secondarySites.splice(index, 1);
  }

  getPotentialSecondarySit()
  {
    return this.cds.moralPersonCollection.filter(x => !x.archived && !x.parentSiteId && x.secondarySites.length == 0 && this.secondarySites.findIndex(y => y && y.id == x.id) < 0).sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)
  }

  openSiteCreation()
  {
    let prefiler = "";
    if (this.siret)
      prefiler = this.siret;
    else if (this.siren)
      prefiler = this.siren;
    else if (this.name)
      prefiler = this.name;

    let title = "";
    this.translateService.get("THIRD-PARTY.SITE.ADD").subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.CREATE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CLOSE").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: SiteEditComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [
        {
          label: close,
          onClick: () => { modal.close(); }
        },
        {
          label: create,
          type: "primary",
          onClick: (result) =>
          {
            let site = result.submitForm();
            if (!site)
              return;
            this.siteService.createSite(site).subscribe(x =>
            {
              site.id = x;
              this.sites.push(site)
              this.siteService.siteList.push(site);
            });
            modal.close();
          }
        }
      ]
    });
    modal.componentInstance.site = null;
    modal.componentInstance.searchResult = this.searchResult;
    modal.componentInstance.prefilter = prefiler;
  }

  removeSite(site: Site)
  {
    let title = "";
    this.translateService.get("THIRD-PARTY.SITE.REMOVE-ARCHIVE", { v: site.name }).subscribe(x => title = x);
    let content = "";
    this.translateService.get("THIRD-PARTY.SITE.REMOVE-ARCHIVE-CONFIRM", { v: site.name }).subscribe(x => content = x);
    let archive = "";
    this.translateService.get("GENERIC-ACTIONS.ARCHIVE").subscribe(x => archive = x);
    let remove = "";
    this.translateService.get("GENERIC-ACTIONS.REMOVE").subscribe(x => remove = x);
    let cancel = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => cancel = x);
    const modal = this.modalService.create<any>({
      nzTitle: title,
      nzContent: content,
      nzWidth: '500px',
      nzMaskClosable: false,
      nzFooter: [{
        label: archive,
        type: "primary",
        onClick: () =>
        {
          this.siteService.archiveSite(site.id).subscribe(() =>
          {
            let index1 = this.sites.findIndex(x => x.id == site.id);
            this.sites.splice(index1, 1);
            let index2 = this.siteService.siteList.findIndex(x => x.id == site.id);
            this.siteService.siteList.splice(index2, 1);
          });
          if (this.moralPerson)
            this.siteService.removeThirdPartyFromSite(site.id, [this.moralPerson.id]).subscribe();
          modal.close();
        }
      },
      {
        label: remove,
        type: "primary",
        onClick: () => 
        {
          if (this.moralPerson)
            this.siteService.removeThirdPartyFromSite(site.id, [this.moralPerson.id]).subscribe();
          let index1 = this.sites.findIndex(x => x.id == site.id);
          this.sites.splice(index1, 1);
          modal.close();
        }
      },
      {
        label: cancel,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.site = null;
  }

  updateAttachmentsPictures(attachment: KanbanAttachment[])
  {
    this.attachmentsPictures = attachment;
  }

  updateAttachmentsNotPictures(attachment: KanbanAttachment[])
  {
    this.attachmentsNotPictures = attachment;
  }

  editSite(site: Site)
  {
    let prefiler = "";
    if (this.siret)
      prefiler = this.siret;
    else if (this.siren)
      prefiler = this.siren;
    else if (this.name)
      prefiler = this.name;

    let title = "";
    this.translateService.get("THIRD-PARTY.SITE.UPDATE", { v: site.name }).subscribe(x => title = x);
    let create = "";
    this.translateService.get("GENERIC-ACTIONS.SAVE").subscribe(x => create = x);
    let close = "";
    this.translateService.get("GENERIC-ACTIONS.CANCEL").subscribe(x => close = x);
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: SiteEditComponent,
      nzWidth: '1000px',
      nzMaskClosable: false,
      nzFooter: [{
        label: create,
        type: "primary",
        onClick: (result) =>
        {
          let res = result.submitForm()
          if (!res)
            return;
          site.name = res.name;
          site.address = res.address;
          site.countryCode = res.countryCode;
          this.siteService.updateSite(site).subscribe(x =>
          {
            let siteFromService = this.siteService.siteList.find(x => x.id == site.id);
            if (siteFromService != null)
            {
              siteFromService.name = res.name;
              siteFromService.address = res.address;
              siteFromService.countryCode = res.countryCode;
            }
            site.isUpdated = true;
            setTimeout(() =>
            {
              site.isUpdated = false;
            }, 1);
          });
          modal.close();
        }
      }, {
        label: close,
        onClick: () => { modal.close(); }
      }
      ]
    });
    modal.componentInstance.site = site;
    modal.componentInstance.prefilter = prefiler;
  }

  startTheCall(number: string)
  {
    var link = document.createElement('a');
    link.href = "sip:" + number;
    link.click();
  }

  sendTheMail(mail: string)
  {
    this.mss.listMailTo.push(mail);
    this.mss.editMail()
  }

  showErrorMessage(codeText: string)
  {
    let message = "";
    this.translateService.get(codeText).subscribe(x => message = x)
    this.msgs.error(message);
  }

  @ViewChild('logoHandler', { static: false }) logoHandler?: TemplateRef<{}>;
  isFetchinglogo = false;
  modalLogo;

  submitForm(force = false)
  {
    if (!this.name)
    {
      this.nameIsInValid = true;
      this.showErrorMessage("THIRD-PARTY.ERROR.MISSING-NAME");
      return null;
    }
    else this.nameIsInValid = false;
    let testsiren = this.siren.replace(/\s/g, "");
    if (testsiren.length != 9 && this.nationality == "fr")
    {
      this.sirenIsInvalid = true;
      this.showErrorMessage("THIRD-PARTY.ERROR.SIREN-INVALID-FORMAT");
      return null;
    }
    this.sirenIsInvalid = false;
    let testsiret = this.siret.replace(/\s/g, "");

    if ((!testsiret.startsWith(testsiren) || testsiret.length != 14) && this.nationality == "fr")
    {
      this.siretIsInvalid = true;
      this.showErrorMessage("THIRD-PARTY.ERROR.SIRET-INVALID-FORMAT");
      return null;
    }
    for (let cs of this.customSystemFields.filter(x => x.mandatory))
    {
      if (!cs.value || cs.value == "")
        return null;
    }
    this.siretIsInvalid = false;

    let mp = new MoralPerson();
    mp.name = this.name;
    mp.mainCurrency = this.mainCurrency;
    mp.codeTVA = this.codeTVA;
    mp.codeAPE = this.codeAPE;
    mp.nationality = this.nationality;
    mp.anniversaryDate = this.anniversaryDate;
    mp.siren = this.siren;
    mp.siret = this.siret;
    mp.sites = this.sites;
    mp.comment = this.comment;
    mp.phoneNumbers = this.phoneNumbers;
    mp.customFields = this.customFields;
    mp.customSystemFields = this.customSystemFields;
    mp.tags = this.tags;
    mp.secondaryNames = this.secondaryNames.map(x => x.value);
    mp.websites = this.websites.map(x => x.value);
    mp.sign = this.sign;
    mp.initials = this.initials;
    mp.brands = this.brands.map(x => x.value);
    mp.image = this.avatarService.avatarUrl;
    mp.mails = this.mails;
    mp.labels = this.labels;
    mp.secondarySites = this.secondarySites;
    if (this.parent)
    {
      mp.parentSiteId = this.parent.id;
    }

    if (mp.websites.length > 0 && !force)
    {
      this.isFetchinglogo = true;
      let close = "";
      this.translateService.get("THIRD-PARTY.LOGO.CONTINUE").subscribe(x => close = x);
      //window.open(window.location.origin + "/call-interface/third-party-sheet/" + idtp);
      this.modalLogo = this.modalService.create({
        nzTitle: null,
        nzContent: this.logoHandler,
        nzBodyStyle: { backgroundColor: '#f0f2f5' },
        nzWidth: '700px',
        nzMaskClosable: false,
        nzClosable: false,
        nzFooter: [{
          label: close,
          danger: true,
          onClick: () =>
          {
            mp.image = this.avatarService.avatarUrl;
            this.mpService.createMoralPerson(mp, this.avatarService.avatarFile).subscribe(x =>
            {
              mp.id = x;
              this.moralPerson = mp;
              mp.nbAttachments = this.attachmentsPictures.length + this.attachmentsNotPictures.length;
              for (let att of this.attachmentsPictures)
              {
                this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${mp.id}/attachment`, att).subscribe(x =>
                {
                  att.id = x;
                  mp.attachments.push(att);
                });
              }

              for (let att of this.attachmentsNotPictures)
              {
                this.http.post<number>(UrlApiService.settings.apiConfig.uriAPI + `/api/third-party/moral-person/${mp.id}/attachment`, att).subscribe(x =>
                {
                  att.id = x;
                  mp.attachments.push(att);
                });
              }
              this.tpService.updateThirdPartyCredential(mp.id, this.credentialsAssociated).subscribe();
              let mpmodel = this.cds.moralPersonCollection ? this.cds.moralPersonCollection[0] : null;
              if (mpmodel)
              {
                mp.filteredRelations = mpmodel.filteredRelations.filter(x => x.isGlobal || x.isSystem)
              }

              if (this.cds.allmoralperson.findIndex(m => m.id == mp.id) < 0)
              {
                if (this.parent)
                {
                  let temp = this.cds.moralPersonCollection.find(t => t.id == this.parent.id);
                  temp.secondarySites.push(mp);
                }
                else this.cds.moralPersonCollection.push(mp);
                this.mpService.moralPersonListChange.next(null);
              }
              this.modalLogo.close();
            });

          }
        }]
      });
      return mp;
    }

    return mp;
  }

  addWebsite()
  {
    this.websites.push({ value: "" });
  }

  removeWebSite(site: string)
  {
    let index = this.websites.findIndex(x => x.value == site);
    if (index >= 0)
      this.websites.splice(index, 1);
  }

  addSecondaryName()
  {
    this.secondaryNames.push({ value: "" });
  }

  removeSecondaryName(sec: string)
  {
    let index = this.secondaryNames.findIndex(x => x.value == sec);
    if (index >= 0)
      this.secondaryNames.splice(index, 1);
  }

  addBrand()
  {
    this.brands.push({ value: "" });
  }

  removeBrand(brand: string)
  {
    let index = this.brands.findIndex(x => x.value == brand);
    if (index >= 0)
      this.brands.splice(index, 1);
  }

  addMail()
  {
    let mail = new Mail();
    let idTemp = -1;
    while (this.mails.findIndex(x => x.id == idTemp) >= 0)
    {
      idTemp--;
    }
    mail.id = idTemp;
    this.mails.push(mail);
  }

  removeMail(mail: Mail)
  {
    let index = this.mails.findIndex(x => x.id == mail.id);
    this.mails.splice(index, 1);
  }

  gotoSite(ws: string)
  {
    if (!ws.startsWith("http"))
      window.open("https://" + ws, "_blank");
    else window.open(ws, "_blank");
    return false;
  }

  addPhoneNumber()
  {
    this.noFocusNumber = false;
    this.filteredOptions = this.options;
    let phoneNumber = new PhoneNumber();
    let idTemp = -1;
    while (this.phoneNumbers.findIndex(x => x.id == idTemp) >= 0)
    {
      idTemp--;
    }
    phoneNumber.id = idTemp;
    this.phoneNumbers.push(phoneNumber);
  }

  removePhoneNumber(pn: PhoneNumber)
  {
    let index = this.phoneNumbers.findIndex(x => x.id == pn.id);
    this.phoneNumbers.splice(index, 1);
  }

  onInputNumber(value: string): void
  {
    this.filteredOptions = this.options.filter(option => this.tcs.contains(option, value));
  }

  addCustomField()
  {
    this.noFocusNumber = false;
    let customField = new CustomField();
    let idTemp = -1;
    while (this.customFields.findIndex(x => x.id == idTemp) >= 0)
    {
      idTemp--;
    }
    customField.id = idTemp;
    this.customFields.push(customField);
  }

  removeCustomField(cf: CustomField)
  {
    let index = this.customFields.findIndex(x => x.id == cf.id);
    this.customFields.splice(index, 1);
  }

  getContactTabStar()
  {
    return this.phoneNumbers.length + this.mails.length + this.websites.length > 0;
  }

  getMarqueTabStar()
  {
    return this.brands.length + this.secondaryNames.length > 0;
  }

  getSitesTabNumber()
  {
    return this.sites.length;
  }

  getPhotosTabNumber()
  {
    return this.attachmentsPictures.length;
  }

  getAttachmentsTabNumber()
  {
    return this.attachmentsNotPictures.length;
  }

  getOtherTabStar()
  {
    return this.customFields.length > 0 || this.customSystemFields.length > 0;
  }

  getCredentialsTabNumber()
  {
    return this.credentialsAssociated.length;
  }

  getKanbanTabStars()
  {
    return this.todoListForMove.length + this.boardsForMove.length + this.floatingCards.length > 0;
  }

  getSecondarySiteTabNumber()
  {
    return this.secondarySites.length;
  }

  getAvatarForUser(iduser: number)
  {
    let user = this.cds.userCollection.find(x => x.id == iduser);
    if (user)
      return user.avatar;
    return "";
  }

  getCreatorName()
  {
    let user = this.cds.userCollection.find(x => x.id == this.moralPerson.creatorId);
    if (user)
      return user.name + " " + user.surname;
    return "";
  }

  async setLogoAsAvatar(site: string)
  {
    try
    {
      let logo = await this.getLogo(site);
      if (logo)
      {
        this.avatarService.avatarUrl = "data:image/png;base64, " + logo;
        this.msgs.create("success", this.translater("THIRD-PARTY.LOGO.DONE"))
      }
      else
      {
        this.msgs.error(this.translater("THIRD-PARTY.LOGO.NOT-FOUND"))
      }

    }
    catch (e) { }
  }

  async setLogoAsImage(ws: string)
  {
    let logo = await this.getLogo(ws);
    if (logo)
    {
      let att = new KanbanAttachment();
      att.name = "Logo.png";
      att.url = "data:image/png;base64, " + logo;
      att.thumbnail = att.url;
      att.id = 0;
      this.attachmentsPictures.push(att);
      this.msgs.create("success", this.translater("THIRD-PARTY.LOGO.DONE"))
    }
    else
    {
      this.msgs.error(this.translater("THIRD-PARTY.LOGO.NOT-FOUND"))
    }

  }

  async setLogoAsAvatarAndImage(ws: string)
  {
    let logo = await this.getLogo(ws);
    if (logo)
    {
      this.avatarService.avatarUrl = "data:image/png;base64, " + logo;
      let att = new KanbanAttachment();
      att.name = "Logo.png";
      att.url = this.avatarService.avatarUrl
      att.thumbnail = att.url;
      att.id = 0;
      this.attachmentsPictures.push(att);
      this.msgs.create("success", this.translater("THIRD-PARTY.LOGO.DONE"))
    }
    else
    {
      this.msgs.error(this.translater("THIRD-PARTY.LOGO.NOT-FOUND"))
    }

  }

  async getLogo(mail: string)
  {
    let ttemp = new TextSearch(this.getLogoDomain(mail));
    return await firstValueFrom(this.http.post<any>(UrlApiService.settings.apiConfig.uriAPI + `/api/external/logo-clearbit`, ttemp));
  }

  getLogoDomain(url: string)
  {
    let domain = (new URL(url));
    return "https://logo.clearbit.com/" + domain.hostname.replace('www.', '');
  }

  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }
}
