import { FilterBar } from "./FilterBar";

export class DataFilter
{
    constructor(s: string)
    {
        this.searchText = s;
    }
    searchText: string = ""; //text de search bar
    checkBoxP: boolean = true; //Physical person
    checkBoxM: boolean = true; // Moral person
    start: number = 0; //scroll dynamique start
    number: number = 50; // scroll dynamique max element to load
    isfinish: boolean = false; // scroll finish
    searchBarData = ["NAME-FILTER", "NB-ATT-FILTER"]; // ordre des filtres  
    searchBarDataA = ["NAME-FILTER", "DATE-FILTER"]; // ordre des filtres  pour attachments part 
    searchBarDataM = ["SUBJECT-FILTER", "NB-ATT-FILTER", "DATE-FILTER"]; // ordre des filtres  pour Mails part

    checkBoxSender: boolean = true; // display sender (mails part)
    checkBoxReceiver: boolean = true; // display receiver (mails part)

    selectedGroupCredIds: number[] = []; //list de grp id
    listBoardIds: number[] = [];// list board id

    FilterBar: FilterBar = new FilterBar;

}