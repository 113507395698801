import { Input, Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-file-type-icon',
  templateUrl: './file-type-icon.component.html',
  styleUrls: ['./file-type-icon.component.scss']
})
export class FileTypeIconComponent implements OnInit {

  @Input() fileName: string;
  @Input() fileURL: string;
  @Input() fileHeight: string;

  // ../../assets/filetypes/unknown.svg

  public _SRC = ""
  private imageTypes = ['png', 'jpg', 'jpeg', 'bmp', 'tiff', 'gif', 'svg']

  constructor() { }

  ngOnInit() {
    let extension = this.fileName.split('.').pop();
    this.imageTypes.forEach(element => {
      if (element == extension && this.fileURL) {
        this._SRC = this.fileURL
      }
    });
    if (this._SRC == "") {
      this._SRC = "../../assets/filetypes/" + (this.extensions[extension] || 'unknown') + ".svg"
    }
  }

  private extensions = {
    "": "unknown",
    "audio": "audio",
    "video": "video",
    "text": "text",
    "archive": "archive",

    "jpg": "jpg",
    "jpe": "jpg",
    "jpeg": "jpg",
    "jfif": "jpg",
    "png": "png",
    "gif": "gif",
    "tiff": "tiff",
    "svg": "svg",
    "psd": "psd",
    "ai": "ai",
    "dwg": "dwg",

    "iso": "iso",
    "mdf": "mdf",
    "nrg": "nrg",

    "zip": "zip",
    "7z": "7z",
    "7zip": "7z",
    "arj": "arj",
    "rar": "rar",
    "gz": "archive",
    "gzip": "archive",
    "bz2": "archive",
    "bzip2": "archive",
    "tar": "archive",

    "xls": "xls",
    "doc": "doc",
    "pdf": "pdf",
    "ppt": "ppt",
    "rtf": "rtf",
    "txt": "txt",
    "md": "text",
    "markdown": "text",

    "avi": "avi",
    "mp2": "mp2",
    "mp3": "mp3",
    "mp4": "mp4",
    "fla": "fla",
    "mxf": "mxf",
    "wav": "wav",
    "wma": "wma",
    "aac": "aac",
    "flac": "flac",

    "css": "css",
    "csv": "csv",
    "html": "html",
    "json": "json",
    "js": "js",
    "xml": "xml",

    "dbf": "dbf",
    "exe": "exe"
  }
}