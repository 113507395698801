import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpotifyContentInterfaceComponent } from './spotify-content-interface.component';
import { ContentModule } from 'src/app/components/content/content.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SearchSpotifyModule } from '../search-spotify/search-spotify.module';
import { ManagePlaylistTracksSpotifyModule } from '../manage-playlist-tracks-spotify/manage-playlist-tracks-spotify.module';
import { BrowseSpotifyModule } from '../browse-spotify/browse-spotify.module';
import { PlaylistSpotifyModule } from '../playlist-spotify/playlist-spotify.module';
import { CreatePlaylistSpotifyModule } from '../create-playlist-spotify/create-playlist-spotify.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [
    CommonModule,
    NzLayoutModule,
    NzMenuModule,
    NzToolTipModule,
    ContentModule,
    NzInputModule,
    TranslateModule,
    FormsModule,
    SearchSpotifyModule,
    ManagePlaylistTracksSpotifyModule,
    BrowseSpotifyModule,
    PlaylistSpotifyModule,
    CreatePlaylistSpotifyModule
  ],
  declarations: [SpotifyContentInterfaceComponent],
  exports: [SpotifyContentInterfaceComponent]
})
export class SpotifyContentInterfaceModule { }
