import { PhoneNumber } from "../../third-party/entities/phone-number";
import { Mail } from "../../third-party/entities/mail";

export class ThirdPartyForCredential
{
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    lastName2: string;
    thirdPartyType: number;
    phoneNumbers: PhoneNumber[] = [];
    mails: Mail[] = [];


    constructor(id?: number, name?: string, firstName?: string, lastName?: string, thirdPartyType?: number, phoneNumbers?: PhoneNumber[], mails?: Mail[])
    {
        this.id = id;
        this.name = name;
        this.firstName = firstName;
        this.lastName = lastName;
        this.thirdPartyType = thirdPartyType;
        this.phoneNumbers = phoneNumbers ? phoneNumbers : [];
        this.mails = mails ? mails : [];
    }
}