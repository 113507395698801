import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailSelectorInputComponent } from './mail-selector-input.component';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzInputModule,
    NzPopoverModule,
    NzButtonModule,
    TranslateModule,
    NzGridModule,
    NzIconModule,
    NzTabsModule
  ],
  declarations: [MailSelectorInputComponent],
  exports: [MailSelectorInputComponent]
})
export class MailSelectorInputModule { }
