import { Pipe, PipeTransform } from '@angular/core';
import { CacheDataService } from 'src/app/services/cache-data.service';
@Pipe({
  name: 'initialteam',
  pure: false
})
export class InitialTeamPipe implements PipeTransform
{

  constructor(public cds: CacheDataService) { }

  transform(value: number): string
  {
    return this.cds.getInitalTeam(value);
  }

}
