// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-palette:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/color-palette/color-palette.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".color-palette:hover {\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
