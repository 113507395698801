import { Component, Input, OnInit } from '@angular/core';
import { KanbanBoard } from '../../../../src/app/kanban/entities/Board';
import { KanbanCard } from '../../../../src/app/kanban/entities/Card';
import { KanbanList } from '../../../../src/app/kanban/entities/List';
import { KanbanService } from '../../../../src/app/kanban/services/kanban.service';
import { NameUserPipe } from '../../../../src/app/pipes/name-user-pipe/name-user.pipe';
import { ReservationResource } from '../../../../src/app/reservation/entities/resource';
import { CustomEvent } from '../../../../src/app/SDICalendar/calendar/entities/event';
import { ColorService } from '../../../../src/app/services/color.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-day-events',
  templateUrl: './day-events.component.html',
  styleUrls: ['./day-events.component.scss']
})
export class DayEventsComponent implements OnInit
{

  constructor(private cs: ColorService, private kbs: KanbanService, private nameuserpipe: NameUserPipe) { }

  @Input() day: Date;
  @Input() dayevents: CustomEvent[] = [];
  @Input() resources: ReservationResource[] = [];
  @Input() boards: KanbanBoard[] = [];
  @Input() agendaMode = false;
  @Input() canCreateAppointment = true;
  @Input() obs: Subject<{ date: Date, event: CustomEvent }>;
  previewedCard: KanbanCard = null;
  previewedList: KanbanList = null;
  previewedBoard: KanbanBoard = null;
  ngOnInit()
  {
  }

  openCreateEvent()
  {
    this.obs.next({ date: this.day, event: null })
  }

  selectEvent(event: CustomEvent)
  {
    this.previewedCard = null;
    this.previewedList = null;
    this.previewedBoard = null;
    this.kbs.getCardInfos(event.id).subscribe(task =>
    {
      this.kbs.editedEvent = event;
      task.listId = event.id_obj;
      if (task.id == 0)
        return;
      this.kbs.editedCard = task;

      let thelist: KanbanList = null;
      let theboard: KanbanBoard = null;
      boardloop:
      for (let board of this.boards)
      {
        for (let list of board.lists)
        {
          if (list.id == task.listId)
          {
            thelist = list;
            theboard = board;
            break boardloop;
          }
        }
      }

      if (theboard)
      {
        if (theboard.members.length == 0)
        {
          this.kbs.getMembersForBoard(theboard.id).subscribe(x => 
          {
            theboard.members = x.filter(t => t.userId).map(t => t.userId).sort((a, b) => this.nameuserpipe.transform(a).toUpperCase() < this.nameuserpipe.transform(b).toUpperCase() ? -1 : 1);
          })
        }
        this.kbs.getLabelList(theboard.id).subscribe();
      }
      this.previewedBoard = theboard;
      this.previewedList = thelist;
      this.previewedCard = task;
    })
  }

  getResourceBGColor(event: CustomEvent)
  {
    let res = this.resources.find(x => x.id == event.id_obj);
    if (res)
      return res.color;
    return "black";
  }

  getResourceTitle(event: CustomEvent)
  {
    let res = this.resources.find(x => x.id == event.id_obj);
    if (res)
      return res.name;
    return "";
  }

  getResourceFontColor(event: CustomEvent)
  {
    let res = this.resources.find(x => x.id == event.id_obj);
    if (res)
      return this.cs.getFontColor(res.color.hex.hex);
    return "white";
  }

}
