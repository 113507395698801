import { Component, OnInit, Input, Inject } from '@angular/core';
import { KanbanChecklist } from '../../entities/Checklist';
import { KanbanChecklistItem } from '../../entities/ChecklistItem';
import { KanbanService } from '../../services/kanban.service';
import { KanbanCard } from '../../entities/Card';
import { DropEffect, DndDropEvent } from 'ngx-drag-drop';
import { DateService } from '../../../services/date.service';
import { ChecklistDisplayInfos } from '../../entities/checklist-display-infos';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SettingsService } from '../../../settings/services/settings.service';
import { EventService } from '../../../SDICalendar/calendar/services/event.service';
import { PlanningService } from '../../../planning/planning.service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KANBAN_CARD_PLANNING } from '../../../call-interface/third-party-sheet/thirc-party-sheet-component-token';
import { ComponentType } from '@angular/cdk/portal';
import { KanbanEditCardService } from '../../services/kanban-edit-card.service';

@Component({
  selector: 'app-kanban-card-checklist',
  templateUrl: './kanban-card-checklist.component.html',
  styleUrls: ['./kanban-card-checklist.component.scss']
})
export class KanbanCardChecklistComponent implements OnInit
{

  @Input() checklist: KanbanChecklist = new KanbanChecklist();
  @Input() card: KanbanCard = new KanbanCard();
  showAddItem = false;
  addItemText = "";
  addItemBeginDate: Date = null;
  showEditChecklistTitle = false;
  showBeginDate = false;
  showEndDate = false;
  editChecklistTitle = "";

  isEditingItem = false;
  updatedItemText = "";
  updatedItemBeginDate: Date = null;
  loadedpref = false;
  focusedChecklistItem: KanbanChecklistItem = new KanbanChecklistItem();

  constructor(private kbs: KanbanService,
    @Inject(KANBAN_CARD_PLANNING) private cardPlanningComponent: ComponentType<any>,
    private settingsService: SettingsService,
    public planningService: PlanningService,
    public eventService: EventService,
    private translate: TranslateService,
    private modalService: NzModalService,
    public kecs: KanbanEditCardService,
    public ds: DateService) { }

  ngOnInit()
  {
    this.settingsService.getSettingsByName("ChecklistCollapsedState_" + this.checklist.id).subscribe(set =>
    {
      this.checklist.collapsed = set == "true";
      this.loadedpref = true;
    })
    this.showBeginDate = this.showDateBegin();
    this.showEndDate = this.showDateEnd();
  }

  changeCollapsedState()
  {
    this.checklist.collapsed = !this.checklist.collapsed;
    this.settingsService.setSettingsByName("ChecklistCollapsedState_" + this.checklist.id, this.checklist.collapsed ? "true" : "false");
  }

  doNothin() { }

  deleteChecklist()
  {
    this.kbs.removeChecklistFromCard(this.card.id, this.checklist.id).subscribe(x =>
    {
      this.card.checklists.splice(this.card.checklists.findIndex(x => x.id == this.checklist.id), 1);
      this.card.checklists = [...this.card.checklists];
    });
  }

  saveModification()
  {
    this.focusedChecklistItem.title = this.updatedItemText;
    let updatecalendar = this.updatedItemBeginDate != this.focusedChecklistItem.dateBegin;
    this.focusedChecklistItem.dateBegin = this.updatedItemBeginDate;

    this.kbs.updateChecklistItem(this.card.id, this.checklist.id, this.focusedChecklistItem).subscribe(x =>
    {
      this.isEditingItem = false;
      if (updatecalendar)
      {
        this.planningService.refreshCalendars.next(null);
      }
    });
  }

  openCardPlanning()
  {
    this.kecs.showValue = 2;
    this.kbs.editMode = "wide";
  }

  initRenameChecklist()
  {
    this.editChecklistTitle = this.checklist.title;
    this.showEditChecklistTitle = true;
    this.kbs.setFocusToElement("input-checklist-rename");
  }

  renameChecklist()
  {
    this.kbs.renameChecklist(this.card.id, this.checklist.id, this.editChecklistTitle).subscribe(x =>
    {
      this.checklist.title = this.editChecklistTitle;
      this.showEditChecklistTitle = false;
      this.editChecklistTitle = "";
    });
  }

  updateChecklistDisplayInfos()
  {
    let displayinfos = new ChecklistDisplayInfos();
    displayinfos.showDateBegin = this.showBeginDate;
    displayinfos.showDateEnd = this.showEndDate;
    displayinfos.showDateCreation = false;
    displayinfos.checklistId = this.checklist.id;
    let index = this.kbs.checklistsDisplayInfos.findIndex(x => x.checklistId == this.checklist.id);
    if (index < 0)
      this.kbs.checklistsDisplayInfos.push(displayinfos);
    else this.kbs.checklistsDisplayInfos[index] = displayinfos;
    this.kbs.updateCardChecklistDisplayInfos(this.card.id, displayinfos).subscribe();
  }

  doneItem(item: KanbanChecklistItem)
  {
    this.kbs.updateChecklistItemDone(this.card.id, this.checklist.id, item.id, item.done).subscribe(x =>
    {
      if (item.done)
        item.dateEnd = new Date();
      else item.dateEnd = null;
    });
    this.card.checklists = [...this.card.checklists];
  }

  drop(event: CdkDragDrop<KanbanChecklistItem[]>): void
  {
    moveItemInArray(this.checklist.items, event.previousIndex, event.currentIndex);
    setTimeout(() =>
    {
      this.kbs.moveChecklistItem(this.checklist.id, event.item.data.id, this.card.id, this.checklist.items.map(({ id }) => id)).subscribe(() =>
      {

      });
    }, 300);
  }

  cancelModification()
  {
    this.isEditingItem = false;
  }

  getChecklistProgress()
  {
    if (this.checklist.items.length == 0)
      return 0;
    let progress = (this.checklist.items.filter(x => x.done).length / this.checklist.items.length) * 100;
    return Math.round(progress);
  }

  checkItemIsEdited(item: KanbanChecklistItem)
  {
    return this.isEditingItem && this.focusedChecklistItem.id == item.id;
  }

  editChecklistitem()
  {
    this.updatedItemText = this.focusedChecklistItem.title;
    this.updatedItemBeginDate = this.focusedChecklistItem.dateBegin;
    this.isEditingItem = true;
  }

  deleteChecklistitem()
  {
    this.kbs.deleteChecklistItem(this.card.id, this.checklist.id, this.focusedChecklistItem.id).subscribe(x =>
    {
      this.checklist.items.splice(this.checklist.items.findIndex(x => x.id == this.focusedChecklistItem.id), 1);
    });
  }

  addItem()
  {
    let item: KanbanChecklistItem = new KanbanChecklistItem();
    item.title = this.addItemText;
    item.dateBegin = this.addItemBeginDate;
    item.done = false;
    this.kbs.addItemToChecklist(this.card.id, this.checklist.id, item).subscribe(x =>
    {
      item.id = x;
      this.checklist.items.push(item);
      this.addItemText = "";
      this.addItemText = null;
      this.addItemBeginDate = null;
      this.showAddItem = false;
    })
  }

  cancelAddItem()
  {
    this.addItemText = "";
    this.addItemText = null;
    this.addItemBeginDate = null;
    this.showAddItem = false;
  }

  stopPropagation()
  {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  initAddItem()
  {
    this.showAddItem = true;
    this.kbs.setFocusToElement("kanbanchecklistitem" + this.checklist.id);
  }

  showDateBegin()
  {

    return this.kbs.checklistsDisplayInfos.find(x => x.checklistId == this.checklist.id && x.showDateBegin) != null;
  }

  showDateEnd()
  {
    return this.kbs.checklistsDisplayInfos.find(x => x.checklistId == this.checklist.id && x.showDateEnd) != null;
  }

}
