export class User
{
    id: number;
    name: string;
    surname: string;
    company: number;
    mail: string;
    number: string;
    address: string;
    login: string;
    password: string;
    actived: boolean;
    archived: boolean;
    language: string;
    avatar: string;
    activationMailIsValid: boolean;
    blocked: boolean
    status: string
    favUsersId: string
    color: string;
    creatorId: number;
    creationDate: Date;
}