import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteEditComponent } from './site-edit.component';
import { ContentModule } from 'src/app/components/content/content.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormManagerModule } from 'src/app/form-manager/form-manager.module';
import { SearchClientInfoModule } from 'src/app/third-party/moral-person/components/search-client-info/search-client-info.module';
import { InputFocusDirectiveModule } from 'src/app/directives/input-focus-directive/input-focus-directive.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CreatorDisplayModule } from 'src/app/components/creator-display/creator-display.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NzSpinModule,
    ContentModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    FormsModule,
    InputFocusDirectiveModule,
    TranslateModule,
    FormManagerModule,
    SearchClientInfoModule,
    CreatorDisplayModule,
    NzIconModule,
    NzToolTipModule
  ],
  declarations: [SiteEditComponent],
  exports: [SiteEditComponent]
})
export class SiteEditModule { }
