// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-thirdparty {
  width: 300px;
}

.create-relation-button {
  margin-top: 10px;
  margin-right: 15px;
  display: inline-block;
}

.create-relation-title {
  font-weight: bold;
}

.icon-set-source {
  margin-left: 15px;
}

.no-data {
  text-align: center;
  border: 1px solid black;
  display: table;
  width: 100%;
}

.no-data > div {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  font-size: 18px;
}

.boardList-ticket-title {
  color: black;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  margin-left: 40px;
  padding: 9px 15px;
  padding-left: 15px;
  transition: padding-left 0.1s linear;
  word-break: break-all;
}

.boardList-ticket {
  width: 50%;
  border-radius: 3px;
  margin: 0px 0px 6px 0px;
  display: inline-block;
  position: relative;
  vertical-align: top !important;
}

.darken {
  width: 100%;
  border-radius: 3px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/app/kanban/components/kanban-workflow-settings/kanban-workflow-settings.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,uBAAA;EACA,cAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,sBAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAGA;EACE,YAAA;EACA,gBAAA;EACA,0CAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,oCAAA;EACA,qBAAA;AAAF;;AAGA;EACE,UAAA;EACA,kBAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,0CAAA;AAAF","sourcesContent":[".select-thirdparty {\r\n  width: 300px;\r\n}\r\n\r\n.create-relation-button {\r\n  margin-top: 10px;\r\n  margin-right: 15px;\r\n  display: inline-block;\r\n}\r\n\r\n.create-relation-title {\r\n  font-weight: bold;\r\n}\r\n\r\n.icon-set-source {\r\n  margin-left: 15px;\r\n}\r\n\r\n.no-data {\r\n  text-align: center;\r\n  border: 1px solid black;\r\n  display: table;\r\n  width: 100%;\r\n}\r\n\r\n.no-data>div {\r\n  display: table-cell;\r\n  vertical-align: middle;\r\n  font-weight: bold;\r\n  font-size: 18px;\r\n}\r\n\r\n\r\n.boardList-ticket-title {\r\n  color: black;\r\n  font-weight: 500;\r\n  background-color: rgba(255, 255, 255, 0.7);\r\n  height: 100%;\r\n  margin-left: 40px;\r\n  padding: 9px 15px;\r\n  padding-left: 15px;\r\n  transition: padding-left 0.1s linear;\r\n  word-break: break-all;\r\n}\r\n\r\n.boardList-ticket {\r\n  width: 50%;\r\n  border-radius: 3px;\r\n  margin: 0px 0px 6px 0px;\r\n  display: inline-block;\r\n  position: relative;\r\n  vertical-align: top !important;\r\n}\r\n\r\n.darken {\r\n  width: 100%;\r\n  border-radius: 3px;\r\n  height: 100%;\r\n  background-color: rgba(255, 255, 255, 0.3);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
