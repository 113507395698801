import { Component, OnInit } from '@angular/core';
import { EventService } from '../services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomEvent } from '../entities/event';
import RRule from 'rrule';

@Component({
  selector: 'app-overlap',
  templateUrl: './overlap.component.html',
  styleUrls: ['./overlap.component.scss']
})
export class OverlapComponent implements OnInit
{

  dragToSelectEvent: CustomEvent;
  collisionedEvents: CustomEvent[];

  deleteOld: boolean = true;
  keepOld: boolean = false;
  keepAll: boolean = false;
  deleteFrom: boolean = false;

  isSimpleConflict: boolean = false;
  manageSimple: boolean = false;

  manageKeepOld: boolean = false;
  manageKeepNew: boolean = true;
  manageFrom: boolean = false;
  manageKeepBoth: boolean = false;

  resultEvents: CustomEvent[] = [];

  service;

  constructor(public eventService: EventService, public translate: TranslateService)
  {
  }

  ngOnInit()
  {
    if (this.dragToSelectEvent.rrule)
    {
      this.resultEvents.push(this.dragToSelectEvent)   
      this.collisionedEvents.forEach(x => {
        this.resultEvents.push(x)      
      }) 
    }
    else     
    {
      this.eventService.service = this.service;
      if (this.collisionedEvents.length == 1 && ((this.dragToSelectEvent.start.getTime() < this.collisionedEvents[0].start.getTime() && this.dragToSelectEvent.end.getTime() <= this.collisionedEvents[0].end.getTime()) || (this.dragToSelectEvent.start.getTime() >= this.collisionedEvents[0].start.getTime() && this.dragToSelectEvent.end.getTime() > this.collisionedEvents[0].end.getTime())))
      {
        this.isSimpleConflict = true;
      };
      this.changeResultToDisplay(this.dragToSelectEvent);
    }
  }

  getDisplayConflict(event_: CustomEvent)
  {
    if(!event_.rrule)
      return event_;
    let untilDate = new Date(this.dragToSelectEvent.end);
    untilDate.setTime(untilDate.getTime() + 7 * this.eventService.DAYTIME);
    let rule: RRule = new RRule({
      ...this.dragToSelectEvent.rrule,
      dtstart: this.dragToSelectEvent.start,
      until: this.dragToSelectEvent.until != null ? this.dragToSelectEvent.until : untilDate
    });
    let displayEvent = new CustomEvent();
    rule.all().forEach(date =>
    {
      let startDate = date;
      let endDate = new Date(date.getTime() + (this.dragToSelectEvent.end.getTime() - this.dragToSelectEvent.start.getTime()))
      
        if (!((startDate.getTime() <= event_.start.getTime() && endDate.getTime() <= event_.start.getTime()) || (startDate.getTime() >= event_.end.getTime() && endDate.getTime() >= event_.end.getTime()))) // If there is no collisions we return true
        {
          
          displayEvent.id = event_.id;
          displayEvent.id_obj = event_.id_obj; 
          displayEvent.title = event_.title;
          displayEvent.start = event_.start;
          displayEvent.end = event_.end;
          return;
        }
    });
    return displayEvent;
  }

  onDeleteChoice()
  {
    this.deleteOld = true;
    this.keepOld = false;
    this.keepAll = false;
    this.manageSimple = false;
    this.changeResultToDisplay(this.dragToSelectEvent);
  }

  onKeepChoice()
  {
    this.deleteOld = false;
    this.keepOld = true;
    this.keepAll = false;
    this.manageSimple = false;
    this.changeResultArrayToDisplay(this.collisionedEvents);
  }

  onKeepAllChoice()
  {
    this.deleteOld = false;
    this.keepOld = false;
    this.keepAll = true;
    this.manageSimple = false;

    let array = [...this.collisionedEvents, this.dragToSelectEvent];

    this.changeResultArrayToDisplay(array);
  }

  onManageChoice()
  {
    this.deleteOld = false;
    this.keepOld = false;
    this.manageSimple = true;
    this.onManageKeepNew();
  }

  onManageKeepOld()
  {
    this.manageKeepOld = true;
    this.manageKeepNew = false;
    this.manageKeepBoth = false;

    let newEvent = new CustomEvent(this.service.getListForCalendar(), this.dragToSelectEvent)
    newEvent.title = this.dragToSelectEvent.title;
    newEvent.start = this.dragToSelectEvent.start;
    newEvent.end = this.dragToSelectEvent.end;

    if (this.dragToSelectEvent.start.getTime() < this.collisionedEvents[0].start.getTime() && this.dragToSelectEvent.end.getTime() <= this.collisionedEvents[0].end.getTime())
    {
      newEvent.end = this.collisionedEvents[0].start
    }
    if (this.dragToSelectEvent.start.getTime() >= this.collisionedEvents[0].start.getTime() && this.dragToSelectEvent.end.getTime() > this.collisionedEvents[0].end.getTime())
    {
      newEvent.start = this.collisionedEvents[0].end
    }
    this.changeResultToDisplay(newEvent, this.collisionedEvents[0]);
  }

  onManageKeepNew()
  {
    this.manageKeepOld = false;
    this.manageKeepNew = true;
    this.manageKeepBoth = false;

    let oldEvent = new CustomEvent(this.service.getListForCalendar(), this.collisionedEvents[0])
    oldEvent.title = this.collisionedEvents[0].title;
    oldEvent.start = this.collisionedEvents[0].start;
    oldEvent.end = this.collisionedEvents[0].end;

    if (this.dragToSelectEvent.start.getTime() < this.collisionedEvents[0].start.getTime() && this.dragToSelectEvent.end.getTime() <= this.collisionedEvents[0].end.getTime())
    {
      oldEvent.start = this.dragToSelectEvent.end
    }
    if (this.dragToSelectEvent.start.getTime() >= this.collisionedEvents[0].start.getTime() && this.dragToSelectEvent.end.getTime() > this.collisionedEvents[0].end.getTime())
    {
      oldEvent.end = this.dragToSelectEvent.start
    }
    this.changeResultToDisplay(this.dragToSelectEvent, oldEvent);
  }

  onManageKeepBoth()
  {
    this.manageKeepOld = false;
    this.manageKeepNew = false;
    this.manageKeepBoth = true;

    this.changeResultToDisplay(this.dragToSelectEvent, this.collisionedEvents[0]);
  }

  changeResultToDisplay(event1: CustomEvent, event2?: CustomEvent)
  {
    this.resultEvents = [];
    this.resultEvents.push(event1);
    if (event2)
    {
      this.resultEvents.push(event2);
    }
  }

  changeResultArrayToDisplay(events: CustomEvent[])
  {
    this.resultEvents = [];
    events.forEach(event =>
    {
      this.resultEvents.push(event);
    })
  }

}
