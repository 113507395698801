import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from '../../../../src/app/chat/chat.service';
import { AuthService } from '../../../../src/app/services/auth.service';
import { CacheDataService } from '../../../../src/app/services/cache-data.service';
import { FloatingChatService } from '../../../../src/app/services/floating-chat.service';
import { User } from '../../../../src/app/user/model/user';
import { accessBool, ChatDiscussion } from '../../../../src/app/chat/entities/chat-discussion';
import * as Quill from 'quill';
import { QuillEditorService } from '../../../../src/app/services/quill-editor.service';
import { Subscription, Subject } from 'rxjs';
import { ChatMessage } from '../../../../src/app/chat/entities/chat-message';
import { GlobalNotificationService } from '../../../../src/app/services/notification/global-notification.service';
import { ChatNotificationContainer } from '../../../../src/app/entities/chat-notification-container';
import { SettingsService } from '../../../../src/app/settings/services/settings.service';
import { NavbarService } from '../navbar/navbar.service';
import { NzMessageService } from 'ng-zorro-antd/message';

enum access
{
  forbidden = 0,
  public = 1 << 0, //Rejoindre le groupe comme on veut (1)
  lien = 1 << 1, //rejoindre le groupe grâce à un lien (2)
  invitation = 1 << 2 //rejoindre le groupe grâce à une invitation (4)
}

@Component({
  selector: 'app-floating-chat',
  templateUrl: './floating-chat.component.html',
  styleUrls: ['./floating-chat.component.scss']
})
export class FloatingChatComponent implements OnInit
{

  constructor(
    public fcs: FloatingChatService,
    public cs: ChatService,
    public cds: CacheDataService,
    public gbs: GlobalNotificationService,
    public authService: AuthService,
    private translateService: TranslateService,
    private quillService: QuillEditorService,
    private elementRef: ElementRef,
    public navbarService: NavbarService,
    private message: NzMessageService,
    private settingsService: SettingsService,
  ) { }

  you: User = new User()
  // param: ChatParam = new ChatParam()
  displayEditor = false
  quill = null
  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],         //class style
    [{ 'size': ['small', false, 'large'] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link', 'image', 'video'],
    ['emoji2'],
    ['send2']
  ];

  editorFSubscription: Subscription
  switchSubscription: Subscription
  reloadDiscussionSubscription: Subscription
  openNotif = false
  firstLoad = true

  createDiscussionIsVisible = false;
  newDiscussion: accessBool = new accessBool
  chatDisussion: ChatDiscussion = new ChatDiscussion

  userSelectedIds: number[] = []

  noComeBack = false

  cursorPosition = 0

  addMemberToIsVisible = false

  ngOnInit()
  {

    this.cs.messageToReply = new ChatMessage()
    this.editorFSubscription = this.fcs.ChatEditorFSub.subscribe(paramId =>
    {
      this.fcs.displayDiscussion = true
      this.displayEditor = true
      this.fcs.param.paramId = paramId
      this.quill = null
      setTimeout(() =>
      {
        this.initEditor()
      }, 100);
    })

    this.reloadDiscussionSubscription = this.cs.reloadDiscussionSub.subscribe(roomId =>
    {
      if (this.noComeBack)
      {
        this.noComeBack = false
        return
      }
      if (this.fcs.param.roomList.find(x => x.id == roomId))
      {
        if (this.fcs.param.paramId == roomId)
        {
          while (this.fcs.navigation != '/')
            this.fcs.navigateBack()
          this.displayEditor = false
          this.quill = null
        }
      }
      this.getDiscussions()
    })

    if (this.fcs.openDiscussionId > 0)
    {
      this.fcs.param.paramId = this.fcs.openDiscussionId
    }
    this.displayEditor = true
    this.you = this.cds.userCollection.find(usr => usr.id == this.authService.Access.idUser && !usr.archived)
    if (this.fcs.groupList.length == 0)
      this.getDiscussions()
    if (this.fcs.discussionList.length == 0 || this.fcs.discussionListAvailable.length == 0)
      this.getActivePrivateDiscussions()
    if (this.fcs.title.length < 1)
      this.translateService.get("CHAT.TITLE").subscribe(x => this.fcs.title = x);

    setTimeout(() =>
    {
      this.openLastPath()
    }, 100);

  }

  ngOnDestroy()
  {
    this.quillService.hasLoadedEmoji2 = false;
    this.editorFSubscription.unsubscribe()
  }

  ngOnChanges()
  {
    if (this.firstLoad)
      return

    if (this.fcs.chatTemplate == "ChatContentTemplate" && this.editorFSubscription && this.fcs.param.paramId && !this.openNotif && this.fcs.openDiscussionId != null)
    {
      setTimeout(() =>
      {
        this.fcs.ChatEditorFSub.next(this.fcs.param.paramId)
      }, 100);
    }

  }

  getDiscussions()
  {
    this.cs.getDiscussions().subscribe(x =>
    {
      this.fcs.groupList = x
      this.fcs.param.roomList = x
    })
  }

  openLastPath()
  {
    this.firstLoad = false
    this.settingsService.getSettingsByName("LastFloatingChatOpenDiscussion").subscribe(x =>
    {

      console.log(x)
      if (x)
      {
        if (x == this.fcs.navigation)
        {
          return
        }
        let opt = x.split('|')
        let urls = opt[0].split('/')
        if (opt[1] != "null")
        {
          let ida: any = opt[1]
          let id: number = ida
          let member = this.fcs.discussionList.find(x => x.id == id && !x.archived)
          let group = this.fcs.groupList.find(x => x.id == id)
          if (member && urls[1] == "Discussion")
          {
            this.fcs.navigation = "/Discussion"
            this.openDiscussion(member)
            this.fcs.title = member.name + " " + member.surname;
          }
          else if (group && urls[1] == "Room")
          {
            this.fcs.navigation = "/Room"
            this.openGroup(group)
            this.fcs.title = group.name;
          }
        }
        else
        {
          this.fcs.navigation = '/'
        }
      }
      else
        this.fcs.navigation = '/'
      if (this.fcs.navigation == '/')
      {
        this.translateService.get("CHAT.TITLE").subscribe(x => this.fcs.title = x);
      }
    })

  }

  initEditor()
  {
    this.openNotif = false
    this.quill = new Quill('#chatEditorF', {
      modules: {
        toolbar: {
          container: this.toolbarOptions,
        },
      },
      placeholder: this.translater('MESSAGERIE.EDITOR.PLACEHOLDER'),
      theme: 'snow',
    });
    var customButton = document.querySelector('.ql-send2');
    customButton.addEventListener('click', () =>
    {
      var message = this.quillService.getDatas(this.quill)
      this.quillService.sendMessage(message, this.fcs.param, "ScrollF");
    });
    var d1 = this.elementRef.nativeElement.querySelector('.ql-send2');
    d1.insertAdjacentHTML('beforeend', '<i _ngcontent-fsb-c450="" nz-icon="" nztype="send2" nztheme="outline" ng-reflect-nz-type="send2" ng-reflect-nz-theme="outline" class="anticon anticon-send2" style="color: #1890ff; font-size: large;"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="send2" aria-hidden="true"><defs><style></style></defs><path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2a15.99 15.99 0 00-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-.9 3.7-.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 .7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-.8 4.2-2.6 5-5 1.4-4.2-.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path></svg></i>');

    var customButton2 = document.querySelector('.ql-emoji2');
    customButton2.addEventListener('click', () =>
    {
      this.callEmojis()
    });
    var d2 = this.elementRef.nativeElement.querySelector(".ql-emoji2")
    d2.insertAdjacentHTML('beforeend', '<i _ngcontent-hjr-c501="" nz-icon="" nztype="smile" id="emoji-trigger2" nztheme="outline" ng-reflect-nz-type="smile" ng-reflect-nz-theme="outline" class="anticon anticon-smile" style="color: black;"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="smile" aria-hidden="true"><path d="M288 421a48 48 0 1096 0 48 48 0 10-96 0zm352 0a48 48 0 1096 0 48 48 0 10-96 0zM512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm263 711c-34.2 34.2-74 61-118.3 79.8C611 874.2 562.3 884 512 884c-50.3 0-99-9.8-144.8-29.2A370.4 370.4 0 01248.9 775c-34.2-34.2-61-74-79.8-118.3C149.8 611 140 562.3 140 512s9.8-99 29.2-144.8A370.4 370.4 0 01249 248.9c34.2-34.2 74-61 118.3-79.8C413 149.8 461.7 140 512 140c50.3 0 99 9.8 144.8 29.2A370.4 370.4 0 01775.1 249c34.2 34.2 61 74 79.8 118.3C874.2 413 884 461.7 884 512s-9.8 99-29.2 144.8A368.89 368.89 0 01775 775zM664 533h-48.1c-4.2 0-7.8 3.2-8.1 7.4C604 589.9 562.5 629 512 629s-92.1-39.1-95.8-88.6c-.3-4.2-3.9-7.4-8.1-7.4H360a8 8 0 00-8 8.4c4.4 84.3 74.5 151.6 160 151.6s155.6-67.3 160-151.6a8 8 0 00-8-8.4z"></path></svg></i>')
  }

  checkEnter(event)
  {
    event.preventDefault();
    var message = this.quillService.getDatas(this.quill)
    this.quillService.sendMessage(message, this.fcs.param, "ScrollF");
  }

  initPrivateParam(member: User)
  {
    this.fcs.param.paramId = member.id
    this.fcs.param.isPrivateDiscussion = true
    this.fcs.param.isFloatingMessage = true
  }

  initParam(group: ChatDiscussion)
  {
    this.fcs.param.paramId = group.id
    this.fcs.param.isPrivateDiscussion = false
    this.fcs.param.isFloatingMessage = true
  }

  getActivePrivateDiscussions()
  {
    this.fcs.discussionList = []
    this.fcs.discussionListAvailable = []
    this.cs.getActivePrivateDiscussions().subscribe(x =>
    {
      x.forEach(id =>
      {
        let user = this.cds.userCollection.find(y => y.id == id && !y.archived)
        if (user && !user.archived && !this.fcs.discussionList.includes(user))
          this.fcs.discussionList.push(user);
      })
      this.cds.userCollection.forEach(user =>
      {
        if (!user.archived && user.id != this.you.id && !this.fcs.discussionList.includes(user))
          this.fcs.discussionListAvailable.push(user)
      })
    })
  }



  callEmojis()
  {
    this.cursorPosition = this.quill.getSelection() ? this.quill.getSelection().index : 0

    let trigger = document.getElementById('#emoji-trigger2');
    this.quillService.picker2.togglePicker(trigger)

    if (this.quillService.hasLoadedEmoji2)
      return;
    this.quillService.hasLoadedEmoji2 = true;
    this.quillService.picker2.on('emoji', selection =>
    {
      this.quill.insertText(this.cursorPosition, selection.emoji)
    });
  }

  openDiscussion(member: User)
  {
    this.fcs.openDiscussionId = member.id
    this.fcs.param.isPrivateDiscussion = true
    this.fcs.param.isFloatingMessage = true
    this.initPrivateParam(member)
    this.fcs.navigate("/Discussion/" + member.id)
    this.fcs.title = member.name + " " + member.surname;
    setTimeout(() =>
    {
      this.fcs.displayDiscussion = true
      this.fcs.ChatEditorFSub.next(member.id)
    }, 100);
  }

  openGroup(group: ChatDiscussion)
  {
    this.fcs.param.roomList = this.fcs.groupList
    this.fcs.openDiscussionId = group.id
    this.fcs.param.isPrivateDiscussion = false
    this.fcs.param.isFloatingMessage = true
    this.initParam(group)
    this.fcs.navigate("/Room/" + group.id)
    setTimeout(() =>
    {
      this.fcs.displayDiscussion = true
      this.fcs.ChatEditorFSub.next(group.id)
      this.fcs.title = group.name
    }, 100);
  }

  openInNewTab(url)
  {
    var win = window.open(url, '_blank');
    win.focus();
  }

  openNotification(notif: ChatNotificationContainer)
  {

    if (notif.message.discussionId)
    {
      this.openNotif = true
      this.fcs.chatTemplate = "ChatContentTemplate"
      this.fcs.navigation = "/"
      this.fcs.navigate("Room")
      let group = this.fcs.groupList.find(x => x.id == notif.message.discussionId)
      this.openGroup(group)
    }
    else if (notif.message.creatorId)
    {
      this.openNotif = true
      this.fcs.chatTemplate = "ChatContentTemplate"
      this.fcs.navigation = "/"
      this.fcs.navigate("Discussion")
      let group = this.fcs.discussionList.find(x => x.id == notif.message.creatorId && !x.archived)
      this.openDiscussion(group)
    }

  }

  translater(path: string): string
  {
    let result = ""
    this.translateService.get(path).subscribe(x => result = x)
    return (result)
  }

  createMessage(type: string, message: string): void
  {
    this.message.create(type, message);
  }

  checkDiscussionParamIsOk(): boolean
  {
    if (!this.chatDisussion.name) return true
    if (this.newDiscussion.invitation == false && this.newDiscussion.lien == false && this.newDiscussion.public == false) return true
    return false
  }

  createDiscussionOk(): void
  {
    let discussionParam = this.newDiscussion
    this.newDiscussion = new accessBool
    let discussion = this.chatDisussion
    this.chatDisussion = new ChatDiscussion

    discussionParam.public ? discussion.access += access.public : 0
    discussionParam.lien ? discussion.access += access.lien : 0
    discussionParam.invitation ? discussion.access += access.invitation : 0

    this.cs.createDiscussion(discussion).subscribe(x =>
    {
      let message
      this.translateService.get("CHAT.CREATE-ROOM-SUCCSESS", { discussionName: discussion.name }).subscribe(mess => message = mess)
      this.createMessage('success', message)
      this.userSelectedIds.push(this.you.id);
      this.cs.addMember(this.userSelectedIds, x.id).subscribe(y => this.userSelectedIds = [])
      this.fcs.groupList.push(x);
      this.noComeBack = true
      this.openGroup(x)

    })
    this.createDiscussionIsVisible = false;
  }

  createDiscussionCancel(): void
  {
    this.userSelectedIds = []
    this.newDiscussion = new accessBool
    this.chatDisussion = new ChatDiscussion
    this.createDiscussionIsVisible = false;
  }

  getUserForRoomCreation()
  {
    return this.cds.userCollection.filter(val => val && this.you && val.id != this.you.id && !val.archived);
  }

  checkAddMembersParamIsOk()
  {
    if (this.userSelectedIds.length > 0)
      return false
    return true
  }

  cancelAddMembersTo()
  {
    this.addMemberToIsVisible = false
    this.userSelectedIds = []
  }

  addPrivateMembersOk()
  {
    this.cds.userCollection.forEach(user =>
    {
      if (this.userSelectedIds.find(id => id == user.id) && !this.fcs.discussionList.find(idd => idd.id == user.id && !user.archived))
      {
        this.fcs.discussionList.push(user)
        let start = this.fcs.discussionListAvailable.findIndex(x => x.id == user.id)
        this.fcs.discussionListAvailable.splice(start, 1)
        this.openDiscussion(user);
      }
    })
    this.userSelectedIds = []
    this.addMemberToIsVisible = false
  }

}
