import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HubConnection } from '@microsoft/signalr';
import { GroupCredentialsDataService } from 'src/app/group-credentials/services/data/group-credentials-data.service';
import { GroupCredentialsWithRights } from 'src/app/group-credentials/entities/group-credentials-with-rights';
import { NotifyGroupCredentialWithRights } from 'src/app/group-credentials/entities/notify-group-credential-with-right';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class GroupCredentialNotificationService
{

  constructor(private notification: NzNotificationService,
    private translateService: TranslateService,
    private groupCredentialService: GroupCredentialsDataService) { }


  initRoutes(_hubConnection: HubConnection)
  {
    _hubConnection.on('GroupCredentialCreation', (groupcredwithrights: GroupCredentialsWithRights) =>
    {
      this.groupCredentialService.listGroupCredentialsWithRights.push(groupcredwithrights);
      this.groupCredentialService.listGroupCredentialsWithRightsChange.next(this.groupCredentialService.listGroupCredentialsWithRights);
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.CREATE", { v: groupcredwithrights.grpCredentials.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('ChangeRightForGroupCredential', (groupcredwithrights: GroupCredentialsWithRights) =>
    {
      let index = this.groupCredentialService.listGroupCredentialsWithRights.findIndex(x => x.grpCredentials.id == groupcredwithrights.grpCredentials.id);
      if (index == -1)
        return;
      this.groupCredentialService.listGroupCredentialsWithRights[index] = groupcredwithrights;
      this.groupCredentialService.listGroupCredentialsWithRightsChange.next(this.groupCredentialService.listGroupCredentialsWithRights);
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.CHANGE-RIGHT", { v: groupcredwithrights.grpCredentials.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('AddManagerToGroupCredential', (groupcredwithrights: GroupCredentialsWithRights) =>
    {
      let index = this.groupCredentialService.listGroupCredentialsWithRights.findIndex(x => x.grpCredentials.id == groupcredwithrights.grpCredentials.id);
      if (index >= 0)
        this.groupCredentialService.listGroupCredentialsWithRights[index] = groupcredwithrights;
      else this.groupCredentialService.listGroupCredentialsWithRights.push(groupcredwithrights);
      this.groupCredentialService.listGroupCredentialsWithRightsChange.next(this.groupCredentialService.listGroupCredentialsWithRights);
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.ADD-MANAGER", { v: groupcredwithrights.grpCredentials.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('RemoveManagerFromGroupCredential', (groupcredwithrights: NotifyGroupCredentialWithRights) =>
    {
      let index = this.groupCredentialService.listGroupCredentialsWithRights.findIndex(x => x.grpCredentials.id == groupcredwithrights.groupCredentialWithRights.grpCredentials.id);
      if (index >= 0)
      {
        if (groupcredwithrights.delete)
          this.groupCredentialService.listGroupCredentialsWithRights.splice(index, 1);
        else this.groupCredentialService.listGroupCredentialsWithRights[index] = groupcredwithrights.groupCredentialWithRights;
        this.groupCredentialService.listGroupCredentialsWithRightsChange.next(this.groupCredentialService.listGroupCredentialsWithRights);
        let title = "";
        this.translateService.get("NOTIFICATION.CREDENTIALS.TITLE").subscribe(x => title = x);
        let content = "";
        this.translateService.get("NOTIFICATION.CREDENTIALS.REMOVE-MANAGER", { v: groupcredwithrights.groupCredentialWithRights.grpCredentials.name }).subscribe(x => content = x);
        this.notification.create(
          'info',
          title,
          content
        );
      }
    });

    _hubConnection.on('UpdateGroupCredential', (groupcredwithrights: GroupCredentialsWithRights) =>
    {
      let index = this.groupCredentialService.listGroupCredentialsWithRights.findIndex(x => x.grpCredentials.id == groupcredwithrights.grpCredentials.id);
      if (index == -1)
        return;
      this.groupCredentialService.listGroupCredentialsWithRights[index] = groupcredwithrights;
      this.groupCredentialService.listGroupCredentialsWithRightsChange.next(this.groupCredentialService.listGroupCredentialsWithRights);
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.UPDATE", { v: groupcredwithrights.grpCredentials.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('ArchiveGroupCredential', (groupcredwithrights: GroupCredentialsWithRights) =>
    {
      let index = this.groupCredentialService.listGroupCredentialsWithRights.findIndex(x => x.grpCredentials.id == groupcredwithrights.grpCredentials.id);
      if (index == -1)
        return;
      this.groupCredentialService.listGroupCredentialsWithRights[index] = groupcredwithrights;
      this.groupCredentialService.listGroupCredentialsWithRightsChange.next(this.groupCredentialService.listGroupCredentialsWithRights);
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.ARCHIVE", { v: groupcredwithrights.grpCredentials.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });

    _hubConnection.on('RestoreGroupCredential', (groupcredwithrights: GroupCredentialsWithRights) =>
    {
      let index = this.groupCredentialService.listGroupCredentialsWithRights.findIndex(x => x.grpCredentials.id == groupcredwithrights.grpCredentials.id);
      if (index == -1)
        return;
      this.groupCredentialService.listGroupCredentialsWithRights[index] = groupcredwithrights;
      this.groupCredentialService.listGroupCredentialsWithRightsChange.next(this.groupCredentialService.listGroupCredentialsWithRights);
      let title = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.TITLE").subscribe(x => title = x);
      let content = "";
      this.translateService.get("NOTIFICATION.GROUP-CREDENTIALS.RESTORE", { v: groupcredwithrights.grpCredentials.name }).subscribe(x => content = x);
      this.notification.create(
        'info',
        title,
        content
      );
    });
  }
}
