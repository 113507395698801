import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSpotifyComponent } from './search-spotify.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NzCardModule,
    TranslateModule,
    NzButtonModule,
    NzToolTipModule
  ],
  declarations: [SearchSpotifyComponent],
  exports: [SearchSpotifyComponent]
})
export class SearchSpotifyModule { }
