import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditReccurenceComponent } from './edit-reccurence.component';
import { FormsModule } from '@angular/forms';
import { DatePickerModule } from '../form/date-picker/date-picker.module';
import { TooglerModule } from '../form/toogler/toogler.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@NgModule({
  imports: [
    CommonModule,
    NzSelectModule,
    NzGridModule,
    FormsModule,
    NzInputNumberModule,
    DatePickerModule,
    TooglerModule,
    TranslateModule,
    NzCheckboxModule
  ],
  declarations: [EditReccurenceComponent],
  exports: [EditReccurenceComponent]
})
export class EditReccurenceModule { }
